import React from 'react';
import PropTypes from 'prop-types';
import PIText from 'components/ProductIdentity/Text.jsx';
import Text from './Text.jsx';

const Legend = props => {
    const { productIdentity, color } = props;
    const TextComponent = productIdentity ? PIText : Text;
    return <TextComponent {...props} color={productIdentity ? color : 'info'} />;
};

Legend.propTypes = {
    productIdentity: PropTypes.bool,
    color: PropTypes.string,
};
Legend.defaultProps = {
    productIdentity: false,
    color: 'info',
};

export default Legend;
