import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import connectToI18n from 'modules/i18n/connectToI18n';
import Utils from 'modules/utils';
const { linkTo } = Utils;
import AppChannel from 'modules/channels/App';
import { connect } from 'react-redux';
import { toggle as togglePanelAction } from 'modules/visiblePanel';
import HelpCenterContainer from './HelpCenter/HelpCenterContainer.jsx';
import { HELP_PANEL } from 'modules/visiblePanel.js';
import StructuredSidePanel from 'components/StructuredSidePanel.jsx';

const HelpDropdown = ({ visiblePanel, currentAssembly, farm, trans, togglePanel, currentUser }) => {
    const showHelpPanel = e => {
        e.preventDefault();
        togglePanel(HELP_PANEL);
        // @TODO (sinewyk): move this to analytics middleware path
        AppChannel.vent.trigger('analytics:newHelpCenter:openHelpPanel');
    };

    const isHelpPanelActive = visiblePanel === HELP_PANEL;

    return (
        <div className="topNavigation-panelContainer topNavigation-helpPanelContainer">
            <a
                onClick={showHelpPanel}
                href={linkTo('configuration/account/identity')}
                className={classnames('helpDropdown bypass ignore-react-onclickoutside', {
                    'is-active': isHelpPanelActive,
                })}
            >
                <span className="helpDropdown-icon icon icon-question-circle" />
                <span className="helpDropdown-text">{trans('menu.admin.title')}</span>
            </a>

            <StructuredSidePanel
                title={trans('menu.help.title')}
                isOpened={isHelpPanelActive}
                isNewNavigationLayout
            >
                <HelpCenterContainer
                    currentAssembly={currentAssembly || null}
                    user={currentUser}
                    farm={farm}
                />
            </StructuredSidePanel>
        </div>
    );
};

HelpDropdown.propTypes = {
    visiblePanel: PropTypes.string,
    currentAssembly: PropTypes.object,
    farm: PropTypes.object,
    trans: PropTypes.func.isRequired,
    togglePanel: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
};

export default connect(undefined, {
    togglePanel: togglePanelAction,
})(connectToI18n(HelpDropdown));
