import PropTypes from 'prop-types';
import React from 'react';
import Text from 'components/Text.jsx';
import classnames from 'classnames';
import FormGroup from './FormGroup.jsx';
import connectToI18n from 'modules/i18n/connectToI18n';
import InputErrors from './InputErrors.jsx';

export class TextareaGroup extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        disabled: PropTypes.bool,
        showErrors: PropTypes.bool,
        showServerErrors: PropTypes.bool,
        clientErrors: PropTypes.array,
        serverErrors: PropTypes.array,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
        rows: PropTypes.number,
        maxLength: PropTypes.number,
        placeholder: PropTypes.string,
        trans: PropTypes.func,
        variant: PropTypes.oneOf(['inverse']),
        isRequired: PropTypes.bool,
        label: PropTypes.string,
        block: PropTypes.bool,
        sublabel: PropTypes.node,
        noMargin: PropTypes.bool,
        inputRef: PropTypes.func,
        productIdentity: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        productIdentity: false,
    };

    onChange = e => {
        this.props.onChange(e.target.name, e.target.value);
    };

    render() {
        const {
            className,
            showErrors,
            showServerErrors,
            name,
            value,
            rows,
            maxLength,
            trans,
            variant,
            placeholder,
            disabled,
            block,
            clientErrors,
            onBlur,
            sublabel,
            serverErrors,
            noMargin,
            inputRef,
            productIdentity,
        } = this.props;

        const baseClassName = 'fa-input';
        const classNames = !productIdentity
            ? classnames(
                  'fa-input',
                  {
                      [`${baseClassName}--${variant}`]: variant,
                      [`${baseClassName}--disabled`]: disabled,
                      [`${baseClassName}--error`]:
                          (showErrors && clientErrors && clientErrors.length > 0) ||
                          (showServerErrors && serverErrors && serverErrors.length > 0),
                      [`${baseClassName}--block`]: block,
                      'u-mb-2': !!maxLength && !showErrors,
                  },
                  className
              )
            : 'nd-textarea';
        const labelClassNames = classnames({
            'fa-input--disabled': disabled,
            'nd-label': productIdentity,
        });
        return (
            <FormGroup noMargin={noMargin}>
                {this.props.label && (
                    <label htmlFor={name} className={labelClassNames}>
                        {this.props.label}
                        {this.props.isRequired && (
                            <span className="required-label">{trans('global.required')}</span>
                        )}
                    </label>
                )}
                {sublabel && sublabel}
                <textarea
                    className={classNames}
                    maxLength={maxLength}
                    id={name}
                    name={name}
                    onChange={this.onChange}
                    onBlur={onBlur}
                    rows={rows}
                    value={value}
                    placeholder={placeholder}
                    disabled={disabled}
                    ref={inputRef}
                />
                <InputErrors isVisible={showErrors} clientErrors={clientErrors} />
                <InputErrors isVisible={showServerErrors} serverErrors={serverErrors} />
                {maxLength && (
                    <Text className="u-right" color="info" size="small">
                        {trans('global.chars.maxLength', { '%maxLength%': maxLength })}
                    </Text>
                )}
            </FormGroup>
        );
    }
}

export default connectToI18n(TextareaGroup);
