/* eslint new-cap: "off"*/
import * as R from 'ramda';
import Type from 'union-type';

// @FIXME (sinewyk): not sure where to put this
export const NONE = 'none';
export const LOADING = 'loading';
export const LOADED = 'loaded';
export const FAILED = 'failed';

export const LOGOUT = 'LOGOUT';

export function createApiActionTypes(namespace, type) {
    return {
        REQUEST: `${namespace}/${type}_REQUEST`,
        SUCCESS: `${namespace}/${type}_SUCCESS`,
        FAILURE: `${namespace}/${type}_FAILURE`,
    };
}

export const createRemoteType = (success, error) =>
    Type({
        Init: [],
        Loading: [],
        Loaded: [success],
        Error: [error],
    });

// [k] => v => {k: v} => {k: v}
// strangely similar to evolve, I should be able to migrate this to using evolve ...
export const updateKeys = R.curry((keys, val, object) =>
    keys.reduce(
        (acc, path) => ({
            ...acc,
            [path]: val,
        }),
        object
    )
);

export const logout = () => ({ type: LOGOUT });
