import React from 'react';
import PropTypes from 'prop-types';
import useI18n from 'hooks/I18n/useI18n';
import {
    PRIMARY_FILTER_ALL,
    PRIMARY_FILTER_HOME_DELIVERY,
    PRIMARY_FILTER_REGULAR_DISTRIBUTION,
} from '../assembliesMapReducer';
import Switch from 'src/components/atoms/Switch/Switch.jsx';
import Tag, { THEME_SELECTED_GREY } from 'src/components/atoms/Tag/Tag.jsx';

export const AssembliesMapFilters = ({
    selectedPrimaryFilter,
    onChangePrimaryFilter,
    onToggleOnlyOpenSaleFilter,
    isOnlyOpenSaleFilterEnabled,
}) => {
    const { trans } = useI18n();

    return (
        <>
            <div className="map-filters">
                <Tag
                    label={trans('map.filters.all')}
                    theme={THEME_SELECTED_GREY}
                    onClick={() => onChangePrimaryFilter(PRIMARY_FILTER_ALL)}
                    selected={selectedPrimaryFilter === PRIMARY_FILTER_ALL}
                />
                <Tag
                    label={trans('map.filters.onlyAssemblies')}
                    theme={THEME_SELECTED_GREY}
                    onClick={() => onChangePrimaryFilter(PRIMARY_FILTER_REGULAR_DISTRIBUTION)}
                    selected={selectedPrimaryFilter === PRIMARY_FILTER_REGULAR_DISTRIBUTION}
                />
                <Tag
                    label={trans('map.filters.onlyDelivery')}
                    theme={THEME_SELECTED_GREY}
                    onClick={() => onChangePrimaryFilter(PRIMARY_FILTER_HOME_DELIVERY)}
                    selected={selectedPrimaryFilter === PRIMARY_FILTER_HOME_DELIVERY}
                />
            </div>
            <div className="map-onlyOpenSale-filter">
                <Switch
                    checked={isOnlyOpenSaleFilterEnabled}
                    label={trans('map.filters.openSalesOnly')}
                    onClick={onToggleOnlyOpenSaleFilter}
                />
            </div>
        </>
    );
};

AssembliesMapFilters.propTypes = {
    isOnlyOpenSaleFilterEnabled: PropTypes.bool.isRequired,
    selectedPrimaryFilter: PropTypes.oneOf(['all', 'regularDistribution', 'homeDelivery'])
        .isRequired,
    onChangePrimaryFilter: PropTypes.func.isRequired,
    onToggleOnlyOpenSaleFilter: PropTypes.func.isRequired,
};

export default AssembliesMapFilters;
