import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Badge from 'components/Badge.jsx';
import Utils from 'modules/utils';
const { linkTo } = Utils;

const icons = {
    assembly: {
        defaultImage: '/assets/images/navigation/icon-hpruche.svg',
        activeImage: '/assets/images/navigation/icon-hpruche-active.svg',
    },
    distribution: {
        defaultImage: '/assets/images/navigation/icon-distribution.svg',
        activeImage: '/assets/images/navigation/icon-distribution-active.svg',
    },
    distributions: {
        defaultImage: '/assets/images/navigation/icon-distributions.svg',
        activeImage: '/assets/images/navigation/icon-distributions-active.svg',
    },
    producers: {
        defaultImage: '/assets/images/navigation/icon-producers.svg',
        activeImage: '/assets/images/navigation/icon-producers-active.svg',
    },
    discussions: {
        defaultImage: '/assets/images/navigation/icon-discussions.svg',
        activeImage: '/assets/images/navigation/icon-discussions-active.svg',
    },
    catalog: {
        defaultImage: '/assets/images/navigation/icon-catalogue.svg',
        activeImage: '/assets/images/navigation/icon-catalogue-active.svg',
    },
    interregionalCatalog: {
        defaultImage: '/assets/images/navigation/icon-catalogue-guests.svg',
        activeImage: '/assets/images/navigation/icon-catalogue-guests-active.svg',
    },
    assemblies: {
        defaultImage: '/assets/images/navigation/icon-ruches.svg',
        activeImage: '/assets/images/navigation/icon-ruches-active.svg',
    },
    folder: {
        defaultImage: '/assets/images/navigation/icon-dossier.svg',
        activeImage: '/assets/images/navigation/icon-dossier-active.svg',
    },
    accounting: {
        defaultImage: '/assets/images/navigation/icon-compta.svg',
        activeImage: '/assets/images/navigation/icon-compta-active.svg',
    },
    members: {
        defaultImage: '/assets/images/navigation/icon-membres.svg',
        activeImage: '/assets/images/navigation/icon-membres-active.svg',
    },
    settings: {
        defaultImage: '/assets/images/navigation/icon-config.svg',
        activeImage: '/assets/images/navigation/icon-config-active.svg',
    },
    progress: {
        defaultImage: '/assets/images/navigation/icon-progress.svg',
        activeImage: '/assets/images/navigation/icon-progress-active.svg',
    },
    questionnaire: {
        defaultImage: '/assets/images/navigation/icon-questionnaire.svg',
        activeImage: '/assets/images/navigation/icon-questionnaire-active.svg',
    },
    newsfeed: {
        defaultImage: '/assets/images/navigation/icon-newsfeed.svg',
        activeImage: '/assets/images/navigation/icon-newsfeed-active.svg',
    },
    statistics: {
        defaultImage: '/assets/images/navigation/icon-statistics.svg',
        activeImage: '/assets/images/navigation/icon-statistics-active.svg',
    },
};

export class LeftNavigationLink extends React.Component {
    static propTypes = {
        href: PropTypes.string.isRequired,
        isActive: PropTypes.bool,
        isDisabled: PropTypes.bool,
        children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node])
            .isRequired,
        icon: PropTypes.string.isRequired,
        badgeValue: PropTypes.number,
    };

    getDefaultImage = () => {
        return icons[this.props.icon] && icons[this.props.icon].defaultImage;
    };

    getActiveImage = () => {
        return icons[this.props.icon] && icons[this.props.icon].activeImage;
    };

    renderDisabledTooltip = () => {
        if (React.Children.count(this.props.children) < 2) {
            return null;
        }
        return this.props.children[1];
    };

    render() {
        const classes = {
            'is-active': this.props.isActive,
            'is-disabled': this.props.isDisabled,
        };
        const url = this.props.isDisabled ? null : linkTo(this.props.href);
        const text = Array.isArray(this.props.children)
            ? this.props.children[0]
            : this.props.children;

        let badge;
        if (this.props.badgeValue && this.props.badgeValue > 0) {
            badge = <Badge className="leftNavigationLink-badge">{this.props.badgeValue}</Badge>;
        }

        // href is not removed on rerender without the key hack : https://github.com/facebook/react/issues/1448#issuecomment-119955717
        return (
            <a
                className={classNames('leftNavigationLink', classes)}
                href={url}
                key={url ? 'y' : 'n'}
            >
                <span className="leftNavigationLink-content">
                    <img src={this.getDefaultImage()} alt="" />
                    <img
                        className="leftNavigationLink-activeImage"
                        src={this.getActiveImage()}
                        alt=""
                    />
                    <span className="leftNavigationLink-text">{text}</span>
                </span>
                <span className="leftNavigationLink-activeIndicator">
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 11 200"
                    >
                        <polygon
                            fill="#1D1D1B"
                            points="0,0 0,100 0,200 11,200 11,111 0,100 11,89 11,0 "
                        />
                    </svg>
                </span>
                {badge}
                {this.renderDisabledTooltip()}
            </a>
        );
    }
}

export default LeftNavigationLink;
