import PropTypes from 'prop-types';
import React from 'react';
import AutocompleteItem from 'components/AutocompleteItem.jsx';
import classnames from 'classnames';

const topArrowKey = 38;
const bottomArrowKey = 40;
const enterKey = 13;

export class AutocompleteItemsList extends React.Component {
    static propTypes = {
        // use key prop to reset the highlighted position between different search values
        // Do not actually put it in the prop types because it's extracted from the props
        // key: PropTypes.string.isRequired,
        itemsData: PropTypes.array,
        ItemComponent: PropTypes.func,
        onItemSelected: PropTypes.func.isRequired,
        className: PropTypes.string,
        isVisible: PropTypes.bool,
        maxItems: PropTypes.number,
        showVat: PropTypes.bool,
        searchInput: PropTypes.string,
    };

    static defaultProps = {
        maxItems: Infinity,
    };

    state = {
        selectedItemIndex: null,
    };

    componentDidMount() {
        this.startListening();
    }

    componentWillUnmount() {
        this.stopListening();
    }

    onOptionClicked = option => {
        this.props.onItemSelected(option);
    };

    isListening = false;

    startListening = () => {
        if (!this.isListening) {
            document.addEventListener('keydown', this.handleKeydown);
            this.isListening = true;
        }
    };

    stopListening = () => {
        document.removeEventListener('keydown', this.handleKeydown);
        this.isListening = false;
    };

    handleKeydown = event => {
        if (!this.props.isVisible) {
            return;
        }

        switch (event.keyCode) {
            case topArrowKey:
                event.preventDefault();
                this.highLightPrevious();
                break;
            case bottomArrowKey:
                event.preventDefault();
                this.highLightNext();
                break;
            case enterKey:
                event.preventDefault();
                this.selectHighlighted();
                break;
            // no default
        }
    };

    selectHighlighted = () => {
        if (this.props.itemsData.length === 0) {
            return;
        }
        const index = Math.max(this.state.selectedItemIndex, 0);
        const item = this.props.itemsData[index];
        this.props.onItemSelected(item);
    };

    highLightPrevious = () => {
        const index = this.state.selectedItemIndex !== null ? this.state.selectedItemIndex - 1 : 0;
        const selectedItemIndex = Math.max(index, 0);
        this.setState({ selectedItemIndex });
    };

    highLightNext = () => {
        const max = Math.min(this.props.maxItems, this.props.itemsData.length);
        const index = this.state.selectedItemIndex !== null ? this.state.selectedItemIndex + 1 : 0;
        const selectedItemIndex = Math.min(index, max - 1);
        this.setState({ selectedItemIndex });
    };

    render() {
        const { itemsData, ItemComponent, isVisible, showVat, searchInput } = this.props;

        const onOptionClicked = this.onOptionClicked;
        const selectedItemIndex = this.state.selectedItemIndex;

        if (!itemsData || !itemsData.length || !isVisible) {
            return null;
        }

        return (
            <ul className={classnames(this.props.className, 'autocomplete-suggestions')}>
                {itemsData.slice(0, this.props.maxItems).map((option, index) => {
                    if (ItemComponent) {
                        return (
                            <AutocompleteItem
                                key={index}
                                onMouseDown={onOptionClicked}
                                data={option}
                            >
                                <div
                                    className={classnames('autocomplete-item', {
                                        'is-highlighted': selectedItemIndex === index,
                                    })}
                                >
                                    <ItemComponent
                                        data={option}
                                        isHighlighted={selectedItemIndex === index}
                                        showVat={showVat}
                                        searchInput={searchInput}
                                    />
                                </div>
                            </AutocompleteItem>
                        );
                    }
                    return (
                        <AutocompleteItem key={index} onMouseDown={onOptionClicked} data={option}>
                            <div
                                className={classnames('autocomplete-item', {
                                    'is-highlighted': selectedItemIndex === index,
                                })}
                            >
                                {option.name}
                            </div>
                        </AutocompleteItem>
                    );
                })}
            </ul>
        );
    }
}

export default AutocompleteItemsList;
