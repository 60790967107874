import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import connectToI18n from 'modules/i18n/connectToI18n';

import { getDistributions } from 'api/onsite-sales';

import AssemblyName from 'components/AssemblyName.jsx';
import Box from 'components/Box.jsx';
import BoxSection from 'components/BoxSection.jsx';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import PageHeader from 'components/PageHeader.jsx';
import Wrapper from 'components/Wrapper.jsx';

class DistributionSelection extends React.Component {
    static propTypes = {
        farmId: PropTypes.number.isRequired,
        trans: PropTypes.func.isRequired,
        i18nConfig: PropTypes.object.isRequired,
    };

    state = {
        loading: true,
        distributions: [],
    };

    componentDidMount() {
        getDistributions(this.props.farmId).then(distributions => {
            this.setState({
                loading: false,
                distributions,
            });
        });
    }

    renderContent() {
        const { loading, distributions } = this.state;

        if (loading === true) {
            return <LoadingAnimation />;
        }

        if (R.isEmpty(distributions)) {
            return (
                <Box>
                    {this.props.trans('onsite_sales.distribution_selection_page.no_distributions')}
                </Box>
            );
        }

        return (
            <Box>
                {distributions.map(distribution => (
                    <BoxSection key={distribution.id} bordered>
                        <Link to={`/amount/${distribution.id}`}>
                            <AssemblyName
                                city={distribution.hiveCity}
                                name={distribution.hiveName}
                            />
                        </Link>
                    </BoxSection>
                ))}
            </Box>
        );
    }

    render() {
        const faqArticle = this.props.i18nConfig.onSiteSaleArticle;

        return (
            <div>
                <PageHeader
                    title={this.props.trans('onsite_sales.distribution_selection_page.title')}
                    background="default"
                />
                <Wrapper size="medium">
                    <Box>
                        <BoxSection>
                            {this.props.trans('onsite_sales.distribution_selection_page.text1')}
                        </BoxSection>
                        <BoxSection>
                            {this.props.trans('onsite_sales.distribution_selection_page.text2')}
                        </BoxSection>
                        {faqArticle && (
                            <BoxSection>
                                <a
                                    target="_blank"
                                    href={faqArticle}
                                    className="button--link button--primary"
                                >
                                    {this.props.trans('onsite_sales.new_feature_banner.faq')}
                                </a>
                            </BoxSection>
                        )}
                    </Box>
                    {this.renderContent()}
                </Wrapper>
            </div>
        );
    }
}

export default connectToI18n(DistributionSelection);
