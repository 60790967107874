import christmasLogoWithoutText from 'app/assets/images/logos/christmas/logo-without-text.svg';
import christmasLogoWithoutTextWhite from 'app/assets/images/logos/christmas/white/logo-without-text.svg';
import logoWithoutText from 'app/assets/new-design/images/icons/logo-without-text.svg';

export const CHRISTMAS_ENABLED_COUNTRIES_LOCALES = [
    'ca-ES',
    'es-ES',
    'it-IT',
    'da-DK',
    'de-CH',
    'de-DE',
    'en-GB',
    'fr-BE',
    'fr-CH',
    'fr-FR',
    'nl-BE',
    'nl-NL',
    'xh-ZA',
];

export const getChristmasLogoForEnabledCountries = (baseLogo, locale, whiteMode) => {
    if (CHRISTMAS_ENABLED_COUNTRIES_LOCALES.includes(locale.code)) {
        return whiteMode ? locale.logos.whiteChristmas : locale.logos.christmas;
    }

    return baseLogo;
};

export const getLogoWithoutText = (locale, whiteMode) => {
    if (CHRISTMAS_ENABLED_COUNTRIES_LOCALES.includes(locale.code)) {
        return whiteMode ? christmasLogoWithoutTextWhite : christmasLogoWithoutText;
    }
    return logoWithoutText;
};
