import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import URI from 'URIjs';
import { withRouter } from 'react-router-dom';
import ContextService from 'app/scripts/entities/context/context.service';

import AppChannel from 'modules/channels/App';
import connectToI18n from 'modules/i18n/connectToI18n';
import {
    switchAssembly,
    switchDistribution,
    updateActiveLink,
    currentAssemblySelector,
    currentDistributionSelector,
    activeLinkSelector,
} from 'modules/navigation';
import { closeAll as closeAllPanels, getVisiblePanel } from 'modules/visiblePanel';
import { fetchBasket } from 'modules/orders';
import { userSelector } from 'modules/currentUser';
import ImpersonateModule from 'modules/impersonate';

import {
    isOnSalePage as isCurrentPageSalePage,
    isOnDeliveryPage as isCurrentPageDeliveryPage,
    isOnBasketPage as isCurrentPageBasketPage,
    isOnCheckoutAccountPage as isCurrentCheckoutAccountPage,
    isOnAssemblyHomepage as isCurrentPageAssemblyHomepage,
    isOnMyAccountPage as isCurrentOnMyAccountPage,
} from 'models/pageContext';
import { HIVE_RECIPIENT, FARM_RECIPIENT } from 'models/newsfeed.js';
import { getCurrentDistribution } from 'models/assembly.js';

import createResponsiveComponent from 'components/createResponsiveComponent';
import FarmInitNavigation from 'components/Navigation/Implementations/FarmInitNavigation.jsx';
import FarmPendingNavigation from 'components/Navigation/Implementations/FarmPendingNavigation.jsx';
import FarmOpenInterregionalNavigation from 'components/Navigation/Implementations/FarmOpenInterregionalNavigation.jsx';
import HostQuestionnaireNavigation from 'components/Navigation/Implementations/HostQuestionnaireNavigation.jsx';
import HostInitNavigation from 'components/Navigation/Implementations/HostInitNavigation.jsx';
import HostPreconstructNavigation from 'components/Navigation/Implementations/HostPreconstructNavigation.jsx';
import HostConstructNavigation from 'components/Navigation/Implementations/HostConstructNavigation.jsx';
import HostOpenNavigation from 'components/Navigation/Implementations/HostOpenNavigation.jsx';
import SimpleNavigation from 'components/Navigation/Implementations/SimpleNavigation.jsx';
import SimpleMemberNavigation from 'components/Navigation/Implementations/SimpleMemberNavigation.jsx';
import Impersonate from 'components/Impersonate.jsx';
import {
    deployOrRemoveProductIdentity,
    shouldDeployProductIdentity,
} from 'modules/productIdentity/themeDeploymentManager';

const FarmInitNavigationResponsive = createResponsiveComponent(FarmInitNavigation);
const FarmPendingNavigationResponsive = createResponsiveComponent(FarmPendingNavigation);
const FarmOpenInterregionalNavigationResponsive = createResponsiveComponent(
    FarmOpenInterregionalNavigation
);
const HostQuestionnaireNavigationResponsive = createResponsiveComponent(
    HostQuestionnaireNavigation
);
const HostInitNavigationResponsive = createResponsiveComponent(HostInitNavigation);
const HostPreconstructNavigationResponsive = createResponsiveComponent(HostPreconstructNavigation);
const HostConstructNavigationResponsive = createResponsiveComponent(HostConstructNavigation);
const HostOpenNavigationResponsive = createResponsiveComponent(HostOpenNavigation);
const SimpleNavigationResponsive = createResponsiveComponent(SimpleNavigation);
const SimpleMemberNavigationResponsive = createResponsiveComponent(SimpleMemberNavigation);

export class NavigationContainer extends React.Component {
    static propTypes = {
        visiblePanel: PropTypes.string,
        closeAllPanels: PropTypes.func.isRequired,
        currentAssembly: PropTypes.object,
        currentDistribution: PropTypes.object,
        currentUser: PropTypes.object.isRequired,
        fetchBasket: PropTypes.func.isRequired,
        switchAssembly: PropTypes.func.isRequired,
        switchDistribution: PropTypes.func.isRequired,
        updateActiveLink: PropTypes.func.isRequired,
        activeLink: PropTypes.string,
        trans: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
    };

    state = {
        context: ContextService.getCurrentContext().toJSON(),
    };

    componentDidMount() {
        AppChannel.vent.on('route', this.onPageChange);
    }

    componentWillUnmount() {
        AppChannel.vent.off('route', this.onPageChange);
        document.body.classList.remove('is-locked');
    }

    onPageChange = ({ activeLink, context }) => {
        this.props.updateActiveLink(activeLink);
        this.setState(
            {
                context: context.toJSON(),
            },
            () => {
                const assemblyId = this.state.context.data.hiveId;
                const { currentAssembly, currentUser } = this.props;

                if (currentAssembly && currentAssembly.id === assemblyId) {
                    return;
                }
                if (assemblyId) {
                    const findAssemblyById = R.find(R.propEq('id', parseInt(assemblyId, 10)));

                    const assembly =
                        findAssemblyById(R.propOr([], 'hivesAsLeader', currentUser)) ||
                        findAssemblyById(R.propOr([], 'hivesAsMember', currentUser)) ||
                        null;
                    this.onAssemblyChange(assembly);
                } else {
                    this.onAssemblyChange(null);
                }
            }
        );

        this.props.closeAllPanels();
    };

    onAssemblyChange = assembly => {
        if (!assembly) {
            this.props.switchAssembly(null);
            return;
        }
        const distrib = getCurrentDistribution(assembly);
        if (distrib) {
            this.props.fetchBasket(distrib.distributionId);
            this.props.switchDistribution(assembly, distrib);
        } else {
            this.props.switchAssembly(assembly);
        }
    };

    chooseNavigationComponent = isOnSalePage => {
        const contextType = isOnSalePage ? 'member' : this.state.context.type;
        switch (contextType) {
            case 'profile':
            case 'member':
                return SimpleMemberNavigationResponsive;
            case 'questionnaire':
                return HostQuestionnaireNavigationResponsive;
            case 'leaderHiveInit':
            case 'leaderPendingPlace':
            case 'leaderRefusedPlace':
                return HostInitNavigationResponsive;
            case 'leaderPreconstruct':
                return HostPreconstructNavigationResponsive;
            case 'leaderConstruct':
                return HostConstructNavigationResponsive;
            case 'leaderOpen':
                return HostOpenNavigationResponsive;
            case 'farmInit':
                return FarmInitNavigationResponsive;
            case 'farmPending':
                return FarmPendingNavigationResponsive;
            case 'farmOpenInterregional':
                return FarmOpenInterregionalNavigationResponsive;
            default:
                return SimpleNavigationResponsive;
        }
    };

    updateTheme() {
        const newBodyClasses = R.pipe(
            R.split(' '),
            R.reject(R.equals('theme')),
            R.reject(R.startsWith('theme-'))
        )(document.body.className);

        if (this.props.currentAssembly && this.props.currentAssembly.theme) {
            newBodyClasses.push('theme');
            newBodyClasses.push(`theme-${this.props.currentAssembly.theme.name}`);
        }

        // Side effect!
        document.body.className = newBodyClasses.join(' ');
    }

    applyImpersonate() {
        const impersonateEmail = ImpersonateModule.getImpersonated();

        if (!impersonateEmail) {
            document.body.classList.remove('with-impersonate-bar');

            return;
        }

        document.body.classList.add('with-impersonate-bar');
    }

    getRecipientType = isOnSalePage => {
        const contextType = isOnSalePage ? 'member' : this.state.context.type;

        switch (contextType) {
            case 'questionnaire':
            case 'leaderHiveInit':
            case 'leaderPendingPlace':
            case 'leaderRefusedPlace':
            case 'leaderPreconstruct':
            case 'leaderConstruct':
            case 'leaderOpen':
                return HIVE_RECIPIENT;
            case 'farmInit':
            case 'farmPending':
            case 'farmOpenInterregional':
                return FARM_RECIPIENT;
            default:
                return '';
        }
    };

    render() {
        this.updateTheme();
        this.applyImpersonate();

        const pathname = new URI().pathname();
        const isOnSalePage = isCurrentPageSalePage(pathname);
        const isOnDeliveryPage = isCurrentPageDeliveryPage(pathname);
        const isOnBasketPage = isCurrentPageBasketPage(pathname);
        const isOnCheckoutAccountPage = isCurrentCheckoutAccountPage(pathname);
        const isOnAssemblyHomepage = isCurrentPageAssemblyHomepage(pathname);
        const isOnMyAccountPage = isCurrentOnMyAccountPage(pathname);

        deployOrRemoveProductIdentity(this.props.currentUser, this.state.context.type);

        const ContextNavigationComponent = this.chooseNavigationComponent(
            isOnSalePage || isOnDeliveryPage || isOnBasketPage || isOnCheckoutAccountPage
        );

        const { currentUser, currentAssembly, currentDistribution } = this.props;

        const hostAssemblies = currentUser?.hivesAsLeader || [];
        const userAssemblies = R.reject(
            R.pathEq(['leader', 'id'], currentUser.id),
            currentUser?.hivesAsMember || []
        );
        const farm = R.path(['farm', 0], currentUser);

        return (
            <Fragment>
                <Impersonate />
                <ContextNavigationComponent
                    {...this.state}
                    activeLink={this.props.activeLink}
                    isOnDeliveryPage={isOnDeliveryPage}
                    isOnSalePage={isOnSalePage}
                    isOnBasketPage={isOnBasketPage}
                    isOnCheckoutAccountPage={isOnCheckoutAccountPage}
                    isOnAssemblyHomepage={isOnAssemblyHomepage}
                    isOnMyAccountPage={isOnMyAccountPage}
                    currentUser={currentUser}
                    currentAssembly={currentAssembly}
                    currentAssemblyId={currentAssembly && currentAssembly.id}
                    currentDistributionId={currentDistribution && currentDistribution.id}
                    currentDistributionDate={
                        currentDistribution && currentDistribution.distributionDate
                    }
                    interSaleProductPush={currentDistribution?.interSaleProductPush}
                    distributionTimeEnd={currentDistribution && currentDistribution.timeEnd}
                    distributionTimeStart={currentDistribution && currentDistribution.timeStart}
                    visiblePanel={this.props.visiblePanel}
                    hostAssemblies={hostAssemblies}
                    userAssemblies={userAssemblies}
                    farm={farm}
                    recipientType={this.getRecipientType(isOnSalePage)}
                    shouldShowProductIdentity={shouldDeployProductIdentity(
                        currentUser,
                        this.state.context.type
                    )}
                />
            </Fragment>
        );
    }
}

export default connect(
    state => ({
        visiblePanel: getVisiblePanel(state),
        currentAssembly: currentAssemblySelector(state),
        currentDistribution: currentDistributionSelector(state),
        currentUser: userSelector(state),
        activeLink: activeLinkSelector(state),
    }),
    {
        closeAllPanels,
        fetchBasket,
        updateActiveLink,
        switchAssembly,
        switchDistribution,
    }
)(withRouter(connectToI18n(NavigationContainer)));
