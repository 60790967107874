import React from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import InputErrors from 'components/InputErrors.jsx';
import classnames from 'classnames';

export class WysiwygEditor extends React.Component {
    static propTypes = {
        content: PropTypes.string,
        editContent: PropTypes.func,
        disabled: PropTypes.bool,
        showErrors: PropTypes.bool,
        removeLink: PropTypes.bool,
        errors: PropTypes.array,
        placeholder: PropTypes.string,
    };

    render() {
        const {
            content,
            editContent,
            disabled,
            showErrors,
            errors,
            placeholder,
            removeLink,
        } = this.props;

        return (
            <React.Fragment>
                <div
                    className={classnames({
                        'fa-input fa-input--error fa-input--block': showErrors,
                    })}
                    style={{ padding: 0 }}
                >
                    <CKEditor
                        editor={ClassicEditor}
                        data={content}
                        config={{
                            toolbar: [
                                'bold',
                                'italic',
                                removeLink ? '' : 'link',
                                'bulletedList',
                                'numberedList',
                                '|',
                                'undo',
                                'redo',
                            ],
                            placeholder,
                        }}
                        onChange={(_, editor) => {
                            editContent('content', editor.getData());
                        }}
                        disabled={disabled}
                    />
                </div>
                <InputErrors isVisible={showErrors} clientErrors={errors} />
            </React.Fragment>
        );
    }
}

export default WysiwygEditor;
