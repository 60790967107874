import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'ramda';
import InlineSVG from 'svg-inline-react';
import { useSelector } from 'react-redux';

import { currentAssemblySelector } from 'modules/navigation';

import { getOfferTag, ENGAGED_PRICE_KEY } from 'models/tag';
import { getFormattedQuantityEquivalent } from 'models/offers';
import FeaturesService, { FEATURES } from 'models/features';

import useI18n from 'hooks/I18n/useI18n';

import PIText from 'components/ProductIdentity/Text.jsx';
import PriceAndPromotion from 'components/Sale/PriceAndPromotion.jsx';
import Tooltip from 'components/Tooltip.jsx';

import Popover from 'src/components/atoms/Popover/Popover.jsx';
import IncrementButton from 'src/components/atoms/Button/IncrementButton.jsx';
import Tag from 'src/components/atoms/Tag/Tag.jsx';

import helpIcon from 'icons/pi/help-circle.svg';

export const ORIENTATION_HORIZONTAL = 'horizontal';
export const ORIENTATION_VERTICAL = 'vertical';
export const BUTTON_LARGE = 'large';

export const PRODUCT_TEMPLATE = 'product';

const ProductOfferTemplate = React.memo(
    ({
        buttonSize,
        offer,
        orientation,
        handleAddBasket,
        handleRemoveBasket,
        formattedQuantities,
        pricePerQuantity,
        quantityInBasket,
        showTag,
        isMultiOffer,
    }) => {
        const { i18n, trans, transChoice } = useI18n();
        const assembly = useSelector(currentAssemblySelector);
        const engagedPricePromotionEnabled = FeaturesService.isFeatureActive(
            FEATURES.ENGAGED_PRICE_PROMOTION,
            assembly
        );

        const tag = useMemo(() => (showTag ? getOfferTag(trans, transChoice)(offer) : {}), [
            trans,
            transChoice,
            offer,
            showTag,
        ]);

        const quantityEquivalent = getFormattedQuantityEquivalent(offer, {
            strategy: offer.quantitystrategy,
            locale: i18n,
            piEnabled: true,
        });

        const engagedPriceTooltip = (
            <Popover
                className="pi-product-card-tooltip"
                customOffset="-6px -14px"
                pointer="right"
                overlay={
                    <Tooltip pointerPosition={2}>
                        <PIText
                            family="inter"
                            weight={400}
                            size="12px"
                            lineHeight="20px"
                            color="grey8"
                            dangerouslySetInnerHTML={{
                                __html: trans('offer.engaged_price.tooltip'),
                            }}
                        />
                    </Tooltip>
                }
            >
                <InlineSVG src={helpIcon} raw className="svgIcon helpIcon" width="20" height="20" />
            </Popover>
        );

        return (
            <div className={classNames('pi-offer-container', 'product-template', orientation)}>
                <div>
                    <div className="pi-offer-price-per-quantity">
                        <PIText family="inter" size="14px" color="gray2" lineHeight="16px">
                            {formattedQuantities}
                            {quantityEquivalent && <>, {quantityEquivalent}</>}
                        </PIText>
                        <PIText family="inter" color="gray9" size="14px" lineHeight="16px">
                            {pricePerQuantity}
                        </PIText>
                    </div>
                    {!isEmpty(tag) && (
                        <div className="pi-product-card-tag-and-tooltip">
                            {engagedPricePromotionEnabled &&
                                tag.type === ENGAGED_PRICE_KEY &&
                                engagedPriceTooltip}
                            <Tag
                                readOnly
                                className={classNames('pi-product-card-tag', tag.type)}
                                label={
                                    <PIText family="inter" size="12px">
                                        {tag.label}
                                    </PIText>
                                }
                            ></Tag>
                        </div>
                    )}
                </div>
                <div>
                    <PIText className="pi-product-offer-price" family="inter" color="gray2" bold>
                        <PriceAndPromotion offer={offer} showDiscount isMultiOffer={isMultiOffer} />
                    </PIText>
                    <IncrementButton
                        large={buttonSize === BUTTON_LARGE}
                        onAdd={handleAddBasket}
                        onRemove={handleRemoveBasket}
                        count={quantityInBasket}
                        label={
                            orientation === ORIENTATION_VERTICAL && (
                                <PIText family="inter" size="16px" lineHeight="24px" bold>
                                    {trans('productPage.button.addToBasket')}
                                </PIText>
                            )
                        }
                    ></IncrementButton>
                </div>
            </div>
        );
    }
);

ProductOfferTemplate.propTypes = {
    orientation: PropTypes.oneOf([ORIENTATION_HORIZONTAL, ORIENTATION_VERTICAL]),
    offer: PropTypes.object.isRequired,
    buttonSize: PropTypes.oneOf([BUTTON_LARGE, '']),
    handleAddBasket: PropTypes.func,
    handleRemoveBasket: PropTypes.func,
    formattedQuantities: PropTypes.string,
    pricePerQuantity: PropTypes.string,
    quantityInBasket: PropTypes.string,
    showTag: PropTypes.bool,
    isMultiOffer: PropTypes.bool,
};

ProductOfferTemplate.defaultProps = {
    showProducer: true,
    mode: ORIENTATION_HORIZONTAL,
    buttonSize: '',
    tag: {},
};

export default ProductOfferTemplate;
