import React from 'react';
import PropTypes from 'prop-types';

import useI18n from 'hooks/I18n/useI18n.js';

import Text from 'components/ProductIdentity/Text.jsx';
import { BANNER_TRANSLATION_KEY } from './utils';

const CategoryBannerContent = ({ category }) => {
    const { trans } = useI18n();

    return (
        <>
            <Text
                className="category-banner-title"
                family="inter"
                size="20px"
                lineHeight="30px"
                bold
                color="grey8"
            >
                {trans(BANNER_TRANSLATION_KEY[category].title)}
            </Text>
            <Text
                className="category-banner-text"
                family="inter"
                color="gray2"
                size="14px"
                weight={400}
                lineHeight="20px"
                dangerouslySetInnerHTML={{
                    __html: trans(BANNER_TRANSLATION_KEY[category].text, {
                        '%average%': 10,
                    }),
                }}
            />
        </>
    );
};

CategoryBannerContent.propTypes = {
    category: PropTypes.string.isRequired,
};

export default CategoryBannerContent;
