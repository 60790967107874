import React, { useMemo, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
    getFormattedPricePerQuantity,
    getFormattedQuantities,
    isSameFormattedQuantities,
    STOCK_UNLIMITED,
} from 'models/offers';

import { currentAssemblySelector } from 'modules/navigation';
import { getQuantityInBasketByOffers } from 'modules/orders';
import Utils from 'modules/utils';
import { getLocalizedDate } from 'modules/utils/dateAndTime';

import { getProductTag } from 'models/tag';
import FeaturesService, { FEATURES } from 'models/features';

import useAnalytics from 'hooks/Analytics/useAnalytics';
import useI18n from 'hooks/I18n/useI18n';

import PIText from 'components/ProductIdentity/Text.jsx';
import MultiOffersModal from 'components/OffersModal/ProductIdentity/MultiOffersModal.jsx';

import StartingAt from 'components/Offers/StartingAt.jsx';
import PriceAndPromotion from 'components/Sale/PriceAndPromotion.jsx';
import IncrementButton from 'src/components/atoms/Button/IncrementButton.jsx';

import ProductCardImage from 'components/Sale/ProductIdentity/ProductCardImage.jsx';
import Tag from 'src/components/atoms/Tag/Tag.jsx';
import Favourite from 'src/components/atoms/Favourite/Favourite.jsx';

import Discount, { MODE_OUTLINE } from 'components/Discount.jsx';

const { filerPhoto } = Utils;

export const MODE_LARGE = 'large';
export const MODE_HORIZONTAL = 'horizontal';
export const MODE_SEARCH = 'search';

const ProductCard = React.memo(
    ({
        mode,
        addOfferToBasket,
        isLabelDescriptionAvailable,
        distributionId,
        product,
        actionOrigin,
        onClick,
        showProducer,
        distributionDate,
        hasGoodDealMode,
    }) => {
        const { offers, name, type, farm, heart_count: heartCount } = product;
        const incrementButtonRef = useRef();
        const [addToCartModalIsOpen, setAddToCartModalIsOpen] = useState(false);
        const history = useHistory();
        const location = useLocation();
        const { trans, transChoice, i18n } = useI18n();
        const { analytics } = useAnalytics();
        const quantityInBasketByOffers = useSelector(state =>
            getQuantityInBasketByOffers(state, {
                distributionId,
                offersId: R.pluck('id')(offers),
            })
        );
        const assembly = useSelector(currentAssemblySelector);

        const engagedPricePromotionEnabled = FeaturesService.isFeatureActive(
            FEATURES.ENGAGED_PRICE_PROMOTION,
            assembly
        );

        const tag = useMemo(
            () => getProductTag(trans, transChoice)(product, false, hasGoodDealMode),
            [product, trans, transChoice, hasGoodDealMode]
        );
        const sumOfferQuantity = useMemo(
            () => R.sum(R.pluck('quantity', quantityInBasketByOffers)),
            [quantityInBasketByOffers]
        );
        const [formattedQuantities, pricePerQuantity] = useMemo(() => {
            const localFormattedQuantities = getFormattedQuantities(offers, type, i18n, trans);
            let localPricePerQuantity = getFormattedPricePerQuantity(offers[0], {
                strategy: type.quantityStrategy,
                locale: i18n,
            });
            if (offers.length > 1) {
                localPricePerQuantity =
                    offers.length > 3 || isSameFormattedQuantities(offers, type, i18n)
                        ? transChoice('offers.count', offers.length, {
                              '%count%': offers.length,
                          })
                        : localFormattedQuantities;
            }

            return [localFormattedQuantities, localPricePerQuantity];
        }, [type, offers, trans, i18n, transChoice]);

        const [isPromoted, isMultiOffers] = useMemo(
            () => [offers[0].hasOwnProperty('promotion'), offers.length > 1],
            [offers]
        );

        const toggleAddToCartModal = useCallback(
            e => {
                e && e.stopPropagation();
                !addToCartModalIsOpen
                    ? history.push({ search: location.search, hash: '#add' })
                    : history.replace({ search: location.search });

                setAddToCartModalIsOpen(!addToCartModalIsOpen);
            },
            [history, addToCartModalIsOpen, location]
        );

        const handleAddBasket = useCallback(
            e => {
                e && e.stopPropagation();
                if (isMultiOffers) {
                    toggleAddToCartModal();
                } else {
                    addOfferToBasket(
                        offers[0].id,
                        quantityInBasketByOffers[0].quantity + 1,
                        actionOrigin
                    );

                    analytics.trackAddToBasket(product, offers[0], assembly, actionOrigin);
                }
            },
            [
                offers,
                quantityInBasketByOffers,
                actionOrigin,
                addOfferToBasket,
                toggleAddToCartModal,
                isMultiOffers,
                assembly,
                product,
                analytics,
            ]
        );
        const handleRemoveBasket = useCallback(
            e => {
                e && e.stopPropagation();
                if (isMultiOffers) {
                    const offersWithQuantityInBasket = R.filter(
                        R.propSatisfies(x => x > 0, 'quantity'),
                        quantityInBasketByOffers
                    );
                    offersWithQuantityInBasket.length === 1
                        ? addOfferToBasket(
                              offersWithQuantityInBasket[0].id,
                              offersWithQuantityInBasket[0].quantity - 1,
                              actionOrigin
                          )
                        : toggleAddToCartModal();
                } else {
                    addOfferToBasket(
                        offers[0].id,
                        quantityInBasketByOffers[0].quantity - 1,
                        actionOrigin
                    );
                }
            },
            [
                isMultiOffers,
                offers,
                quantityInBasketByOffers,
                actionOrigin,
                addOfferToBasket,
                toggleAddToCartModal,
            ]
        );

        const handleOnClick = useCallback(
            e => {
                if (onClick && !incrementButtonRef.current.contains(e.target)) {
                    onClick(e);
                }
            },
            [onClick]
        );

        const renderMultiOffersModal = () =>
            addToCartModalIsOpen && (
                <MultiOffersModal
                    addOfferToBasket={addOfferToBasket}
                    closeModal={toggleAddToCartModal}
                    distributionId={distributionId}
                    product={product}
                    actionOrigin={actionOrigin}
                />
            );
        const quantityMax = useMemo(() => {
            if (offers.some(offer => offer.availableStock === STOCK_UNLIMITED)) {
                return STOCK_UNLIMITED;
            }

            return offers.reduce((acc, offer) => acc + offer.availableStock, 0);
        }, [offers]);
        return (
            <>
                <div
                    onClick={handleOnClick}
                    className={classNames('pi-product-card', mode && mode)}
                >
                    <div className="pi-product-card-shadow">
                        <ProductCardImage
                            tag={tag}
                            product={product}
                            isLabelDescriptionAvailable={isLabelDescriptionAvailable}
                        ></ProductCardImage>
                    </div>
                    <div className="pi-product-card-title">
                        <div className="pi-product-card-title-container">
                            <PIText
                                className="product-name"
                                family="inter"
                                size="14px"
                                color="gray6"
                                bold
                            >
                                {name}
                            </PIText>
                            <div className="quantities-favourites-line">
                                <PIText
                                    className="product-quantities"
                                    family="inter"
                                    size="12px"
                                    lineHeight="14px"
                                >
                                    {formattedQuantities}
                                </PIText>
                                {heartCount ? <Favourite small label={heartCount} /> : null}
                            </div>
                        </div>
                        {tag.label && (
                            <Tag
                                readOnly
                                className={classNames('pi-product-card-tag', tag.type)}
                                label={tag.label}
                            ></Tag>
                        )}
                    </div>
                    {([MODE_LARGE, MODE_HORIZONTAL, MODE_SEARCH].includes(mode) ||
                        sumOfferQuantity === 0) && (
                        <>
                            {((offers[0].promotion &&
                                offers[0].promotion.originalOfferPrice.amount >
                                    offers[0].price.amount) ||
                                offers[0].bulkDiscount ||
                                (engagedPricePromotionEnabled &&
                                    offers[0].isengagedprice &&
                                    offers[0].engagedPriceDiscount > 0)) && (
                                <Discount
                                    className="pi-product-card-discount"
                                    offer={offers[0]}
                                    mode={MODE_OUTLINE}
                                />
                            )}
                        </>
                    )}
                    {addOfferToBasket && (
                        <IncrementButton
                            ref={incrementButtonRef}
                            className="pi-product-card-button"
                            large={!![MODE_LARGE, MODE_HORIZONTAL].includes(mode)}
                            onAdd={
                                (quantityMax === STOCK_UNLIMITED ||
                                    quantityMax > sumOfferQuantity) &&
                                handleAddBasket
                            }
                            onRemove={handleRemoveBasket}
                            count={sumOfferQuantity}
                        ></IncrementButton>
                    )}
                    <div className="pi-product-card-price">
                        {distributionDate && (
                            <PIText
                                className="pi-product-card-distribution-date"
                                family="inter"
                                size="12px"
                                lineHeight="12px"
                                weight="400"
                            >{`${trans('distribution.date.title')} ${getLocalizedDate(
                                i18n,
                                'dateWithoutYear',
                                distributionDate
                            )}`}</PIText>
                        )}
                        <div className="price-favourite-line">
                            <PIText
                                className="pi-product-card-price-quantity"
                                family="inter"
                                color="gray9"
                                size="12px"
                                lineHeight="14px"
                            >
                                {pricePerQuantity}
                            </PIText>
                            {heartCount ? <Favourite small label={heartCount} /> : null}
                        </div>

                        <div className="pi-product-card-price-value">
                            <PIText family="inter" color="gray2" size="16px" lineHeight="20px" bold>
                                {isMultiOffers && !isPromoted ? (
                                    <StartingAt price={offers[0].price} />
                                ) : (
                                    <PriceAndPromotion offer={offers[0]} />
                                )}
                            </PIText>
                        </div>
                    </div>
                    {showProducer && (
                        <div className="pi-product-card-producer">
                            <PIText
                                className="pi-product-card-producer-name"
                                family="inter"
                                color="gray2"
                                size="12px"
                                lineHeight="12px"
                            >
                                {farm.name}
                            </PIText>
                            <img
                                src={filerPhoto(farm.photoId, 'small', 'user')}
                                className="pi-product-card-producer-avatar"
                            ></img>
                        </div>
                    )}
                </div>
                {renderMultiOffersModal()}
            </>
        );
    }
);

ProductCard.propTypes = {
    mode: PropTypes.string,
    addOfferToBasket: PropTypes.func,
    isLabelDescriptionAvailable: PropTypes.bool,
    distributionId: PropTypes.number,
    product: PropTypes.shape({
        name: PropTypes.string,
        offers: PropTypes.array,
        type: PropTypes.object,
        farm: PropTypes.object,
    }),
    actionOrigin: PropTypes.string,
    onClick: PropTypes.func,
    showProducer: PropTypes.bool,
    distributionDate: PropTypes.string,
    hasGoodDealMode: PropTypes.bool,
};

ProductCard.defaultProps = {
    showProducer: true,
    distributionDate: null,
    hasGoodDealMode: false,
};

export default ProductCard;
