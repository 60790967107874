import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import FeaturedDistribution from './FeaturedDistribution.jsx';
import UpcomingDistribution from './UpcomingDistribution.jsx';
import { getCurrentDistributions } from 'models/distributions';

export class Distributions extends React.Component {
    static propTypes = {
        assembly: PropTypes.object.isRequired,
        hideSaleOpenMessage: PropTypes.bool,
    };

    render() {
        const { assembly, hideSaleOpenMessage } = this.props;

        const distributionIds = R.pluck('distributionId', getCurrentDistributions(assembly));

        if (R.isEmpty(distributionIds)) {
            return (
                <UpcomingDistribution
                    upcomingDistributionDate={assembly.distributionDateOfNextSaleInPreparation}
                />
            );
        }

        return (
            <div className="u-mb-8">
                {distributionIds.map(distributionId => (
                    <FeaturedDistribution
                        key={distributionId}
                        distributionId={distributionId}
                        onlyDistribution={distributionIds.length === 1}
                        hideSaleOpenMessage={hideSaleOpenMessage}
                    />
                ))}
            </div>
        );
    }
}

export default Distributions;
