import { get, post } from 'modules/api';
import { Translator } from 'modules/i18n';
import { compareAlpha } from 'modules/utils/string.js';
import Utils from 'modules/utils';
import { lowerCaseAndUnaccent } from 'modules/utils/string.js';
const { isPositiveFloat } = Utils;
import * as R from 'ramda';

export function formatStorageLife(storageLife, locale) {
    if (!storageLife || !storageLife.amount || !storageLife.unit) {
        return null;
    }
    const storageLifeUnit = Translator.trans(locale, `global.calendar.${storageLife.unit}`);
    return `${storageLife.amount} ${storageLifeUnit}`;
}

// Takes a flat array of products, ordered by farm,
// and returns pairs of farm/array_of_products
export function groupProductsByAlreadyOrderedFarms(products) {
    const productsByFarm = [];

    let previousFarmId = -1;

    products.forEach(product => {
        const farm = product.farm;

        if (farm.id === previousFarmId) {
            productsByFarm[productsByFarm.length - 1].products.push(product);
        } else {
            productsByFarm.push({
                farm,
                products: [product],
            });
            previousFarmId = farm.id;
        }
    });

    return productsByFarm;
}

// Takes a flat array of products, ordered by farm,
// and returns pairs of farm/array_of_products
export function groupProductsByCategories(categories, products, categoryLevel = 'root_type_id') {
    const productsByCategories = [];

    products.forEach(product => {
        const category = categories.find(categoryItem => {
            return categoryItem.id === product[categoryLevel];
        });

        if (productsByCategories[category.id] !== undefined) {
            productsByCategories[category.id].products.push(product);
        } else {
            productsByCategories[category.id] = {
                category,
                products: [product],
            };
        }
    });

    return productsByCategories;
}

const hasAtLeastOneOrganicLabel = labels => R.any(R.propEq('isOrganic', true))(labels || []);

export const addLabelsToProduct = (product, labels) => {
    if (product.labels) {
        const foundIdLabels = product.labels.filter(labelId => labels[labelId]);
        if (foundIdLabels.length > 0) {
            const productLabels = R.map(labelId => labels[labelId])(foundIdLabels);
            return {
                ...product,
                labels: productLabels,
                isOrganic: hasAtLeastOneOrganicLabel(productLabels),
            };
        }
    }
    return {
        ...product,
        isOrganic: false,
        labels: [],
    };
};

export function addFarmAndLabelsToProduct(product, farms, labels) {
    const result = {
        ...product,
        composition: product.composition || [],
        offer: product.offers,
    };

    if (product.farmId) {
        if (R.path(['sourceFarm', 'uuid'], product)) {
            result.farm = {
                ...farms[product.sourceFarm.uuid],
                authorFarmName: farms[product.farmId].name,
            };
        } else {
            result.farm = farms[product.farmId];
        }
    }

    return addLabelsToProduct(result, labels);
}

/**
 * Return a copy of the array sorted
 * @param  {Array} products
 * @return {Array}
 */
export function sortedByCategoryThenAlphanumeric(products) {
    return products.slice().sort((a, b) => {
        const categorySort = a.type.categoryId - b.type.categoryId;
        // if categorySort != 0
        if (categorySort) {
            return categorySort;
        }
        return compareAlpha(a.name, b.name);
    });
}

export function sortByPhotoAndId(products) {
    return products.slice().sort((a, b) => {
        if (!a.photoId && b.photoId) {
            return 1;
        }
        if (a.photoId && !b.photoId) {
            return -1;
        }
        return a.id > b.id ? 1 : -1;
    });
}

/**
 * Return a boolean, false if a product has all its offers unavailables
 * @param  {Object} product
 * @return {Object}
 */
export const isAvailable = product =>
    R.pipe(R.prop('offers'), R.any(R.propEq('isAvailable', true)))(product);

/**
 * Return a boolean, true if products come from guests catalog
 * @param  {Object} product
 * @return {Object}
 */
export const isInterregional = product => R.propEq('scope', 'interregional')(product);

/**
 * Return a boolean, true if products come from local catalog
 * @param  {Object} product
 * @return {Object}
 */
export const isLocal = product => R.propEq('scope', 'local')(product);

/**
 * Return a boolean, false if a product has all its offers unavailables
 * @param  {Object} product
 * @return {Object}
 */
export const isUnavailable = R.complement(isAvailable);

/**
 * Return an array of product sorted by availability
 * @param  {Array} products
 * @return {Array}
 */
export const sortProductsByAvailability = products => {
    return R.flatten(R.partition(isAvailable, products));
};

export const productNameMatch = R.curry((searchString, product) => {
    return lowerCaseAndUnaccent(product.name).indexOf(lowerCaseAndUnaccent(searchString)) >= 0;
});

export const sortByName = R.sort((a, b) => {
    return compareAlpha(a.name, b.name);
});

export const sortByStock = R.sort(
    R.ascend(
        R.pipe(
            R.prop('offers'),
            R.pluck('availableStock'),
            R.map(stock => (stock === 'unlimited' ? Infinity : stock)),
            R.reduce(R.min, Infinity)
        )
    )
);
const getCategoriesFromProduct = product =>
    `${product.type.category} > ${product.type.subCategory}`;

export const sortByCategory = products => {
    const productsByCategory = {};
    for (const product of products) {
        const category = getCategoriesFromProduct(product);
        if (!productsByCategory.hasOwnProperty(category)) {
            productsByCategory[category] = [product];
        } else {
            productsByCategory[category].push(product);
        }
    }

    return Object.keys(productsByCategory)
        .sort(compareAlpha)
        .reduce((obj, category) => {
            obj[category] = productsByCategory[category];
            return obj;
        }, {});
};

const renameOfferToOffers = product =>
    R.pipe(R.assoc('offers', product.offer || []), R.dissoc('offer'))(product);

export function getFarmProducts(farmId, { scope = 'local' } = {}) {
    return get(`farms/${farmId}/products`, { scope })
        .then(R.prop('products'))
        .then(R.defaultTo([]))
        .then(R.map(renameOfferToOffers));
}

export const separateAssembliesByAvailability = R.pipe(
    R.partition(R.equals(true)),
    R.map(R.keys),
    R.map(R.map(parseInt))
);

/**
 * Return an array of available products
 * @param  {Array} products
 * @return {Array}
 */
export const getAvailableProducts = products => {
    return R.filter(isAvailable, products);
};

/**
 * Return an array of unavailable products
 * @param  {Array} products
 * @return {Array}
 */
export const getUnavailableProducts = products => {
    return R.filter(isUnavailable, products);
};

/**
 * return the price of an offer if found in the products list
 * @param products
 * @param offerId
 */
export const getPriceOfOfferFromProducts = (products, offerId) => {
    return R.path(
        ['price', 'amount'],
        R.find(R.propEq('id', offerId), R.flatten(R.pluck('offers', products)))
    );
};

export const getReferenceName = currentProduct => {
    const words = currentProduct.name.split(' ');
    let reference = '';

    // Take the first letter of each word except for the last one
    for (let i = 0; i < words.length; i++) {
        const lgt = i === words.length - 1 ? 6 - words.length : 1;
        words[i] = words[i].slice(0, lgt);
    }

    const referenceBase = `${words.join('').toUpperCase()}.${currentProduct.id.toString(36)}`;
    let offerIndex = 0;

    if (!currentProduct.offer) {
        return `${referenceBase}.${offerIndex}`;
    }

    // since we cannot really rely on collection length (items can have been deleted), try to find the largest autogenerated offer index using the reference
    let largestOfferIndex = 0;
    currentProduct.offer.map(offer => {
        const _reference = offer.reference;
        const search = /\.(\d+)$/.exec(_reference);
        if (search && search.length > 1) {
            const index = search[1];
            if (isPositiveFloat(index)) {
                if (parseInt(index, 10) > largestOfferIndex) {
                    largestOfferIndex = parseInt(index, 10);
                }
            }
        }
    });

    // in case largest offer id could not be guessed from references, increment an id until the reference is unique
    if (largestOfferIndex === 0) {
        offerIndex = currentProduct.offer.length + 1;
        do {
            reference = `${referenceBase}.${offerIndex}`;
            offerIndex++;
        } while (R.find(R.propEq('reference', reference), currentProduct.offer));
    } else {
        offerIndex = largestOfferIndex + 1;
        reference = `${referenceBase}.${offerIndex}`;
    }

    return reference;
};

export const getTaxRatesByTerritoryId = territoryId =>
    get(`tax_rates/?taxTerritory=${territoryId}`);

export const getRecommendationPriceByProductId = productId =>
    get(`price_recommendation/${productId}`);

export const getFeedbacksByAssembly = (assemblyId, productUuid, period, memberOnly) =>
    get(
        `assemblies/${assemblyId}/products/feedbacks/${productUuid}/comments?from=${encodeURIComponent(
            `-${period}`
        )}&member-only=${memberOnly}`
    );

export const importProductsInInterregionalCatalog = (farmId, productIds) =>
    post(`farms/${farmId}/create_interregional_products`, { products: productIds });

const fetchProductsByFarmId = ({ farmId, scope = 'local', range = 100 }) =>
    get(`farms/${farmId}/products`, { scope, range })
        .then(data => R.assoc('products', R.propOr([], 'products')(data), data))
        .then(data => R.assoc('products', R.map(renameOfferToOffers, data.products))(data));

const constructProducts = data => {
    const nextHref = R.path(['_links', 'next', 'href'], data);

    const getNextProducts = nextProductsUrl =>
        get(nextProductsUrl)
            .then(nextData =>
                R.assoc('products', R.map(renameOfferToOffers, nextData.products || []))(nextData)
            )
            .then(constructProducts);

    if (!R.isNil(nextHref)) {
        return getNextProducts(nextHref.substring(1)).then(nextProductsData =>
            R.evolve({ products: R.concat(nextProductsData.products) })(data)
        );
    }

    return data;
};

export const fetchAllProducts = ({ farmId, scope }) => {
    return fetchProductsByFarmId({
        farmId,
        scope,
        range: 50,
    })
        .then(constructProducts)
        .then(R.propOr([], 'products'));
};

export const getUniqLabelsList = product => {
    const filterUniquePhotoId = R.pipe(R.groupBy(R.prop('photoId')), R.map(R.head), R.values);
    return product.labels ? filterUniquePhotoId(product.labels) : [];
};
