import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import Heading from 'components/Heading.jsx';
import Text from 'components/Text.jsx';
import Link from 'components/Link.jsx';
import Tag from './Sale/Tag.jsx';

const AssemblyName = ({ assembly, city, name, className, href, inline, showOpenSaleTag }) => {
    const assemblyName = assembly ? assembly.name : name;

    const assemblyCity = assembly ? assembly.place.address.city.name : city;

    if (inline) {
        return href ? (
            <Link href={href} className={className}>
                {assemblyCity} - {assemblyName}
            </Link>
        ) : (
            <Text className={className}>
                {assemblyCity} - {assemblyName}
            </Text>
        );
    }

    return (
        <div className={className}>
            <div className="titleAndTag">
                <Heading size={4}>
                    {href ? <Link href={href}> {assemblyCity} </Link> : assemblyCity}
                </Heading>
                {showOpenSaleTag && !R.isNil(assembly._links.currentSale) && (
                    <Tag openSale size="small" />
                )}
            </div>
            <Text variant="uiMedium">{assemblyName}</Text>
        </div>
    );
};

AssemblyName.propTypes = {
    assembly: PropTypes.shape({
        name: PropTypes.string.isRequired,
        place: PropTypes.object.isRequired,
    }),
    city: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    href: PropTypes.string,
    inline: PropTypes.bool,
    showOpenSaleTag: PropTypes.bool,
};

export default AssemblyName;
