import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import { useHistory, generatePath } from 'react-router-dom';
import PropTypes from 'prop-types';
import useI18n from 'hooks/I18n/useI18n';

import { applyTagTrans } from 'models/tag';
import { getUniqTagKeyList } from 'models/tag';

import { ALL_PRODUCTS_FILTER, ORGANIC_FILTER } from 'modules/utils/saleCategoriesAndFilters';
import {
    filterSubcategoriesOfCategory,
    filterProducts,
    ALL_TAGS_FILTER,
} from 'modules/utils/saleCategoriesAndFilters';

import Text from 'components/ProductIdentity/Text.jsx';

import Icon from 'src/components/atoms/Icons/Icon.jsx';
import Modal from 'src/components/organisms/Modal/Modal.jsx';
import Button, { SMALL_SIZE, SECONDARY_MODE } from 'src/components/atoms/Button/Button.jsx';
import Tag, { THEME_SELECTED_GREY } from 'src/components/atoms/Tag/Tag.jsx';
import Switch, {
    THEME_SELECTED_GREY as SWITCH_THEME_SELECTED_GREY,
} from 'src/components/atoms/Switch/Switch.jsx';

import FilterIcon from 'app/assets/new-design/images/icons/filter.svg';

const FilterButtonMobile = ({
    organicFilter,
    mainCategory,
    activeCategoryFilter,
    activeFilterTag,
    allProducts,
    hasGoodDealMode,
}) => {
    const history = useHistory();
    const { trans, transChoice } = useI18n();
    const [isOpen, setIsOpen] = useState(false);
    const [organicState, setOrganicState] = useState(organicFilter);
    const [currentTag, setCurrentTag] = useState(activeFilterTag);
    const [currentCategoryFilter, setCurrentCategoryFilter] = useState(activeCategoryFilter);

    const filterDatas = useMemo(() => {
        const productFiltered = filterProducts(mainCategory.id, null, organicState, allProducts);

        return {
            hasOrganic: !R.isEmpty(productFiltered),
            categories: filterSubcategoriesOfCategory(
                R.clone(mainCategory),
                allProducts,
                mainCategory.isTrueCategory ? 'parent_type_id' : 'root_type_id'
            ).subCategories,
            tags: getUniqTagKeyList(allProducts, false, hasGoodDealMode),
        };
    }, [allProducts, organicState, mainCategory, hasGoodDealMode]);

    const reset = useCallback(() => {
        setOrganicState(ALL_PRODUCTS_FILTER);
        setCurrentTag(ALL_TAGS_FILTER);
        setCurrentCategoryFilter(null);
    }, [setOrganicState, setCurrentTag, setCurrentCategoryFilter]);

    const getPath = useCallback(() => {
        history.replace(
            generatePath(
                '/category/:categoryId/:subCategoryId?/?filter=:filterBio&filterTag=:filterTag?',
                {
                    categoryId: mainCategory.id,
                    subCategoryId: currentCategoryFilter,
                    filterBio:
                        organicState === ORGANIC_FILTER ? ORGANIC_FILTER : ALL_PRODUCTS_FILTER,
                    filterTag: currentTag,
                }
            )
        );
        setIsOpen(false);
    }, [currentCategoryFilter, currentTag, organicState, history, mainCategory]);

    useEffect(() => {
        setCurrentTag(activeFilterTag);
    }, [activeFilterTag, setCurrentTag]);

    useEffect(() => {
        setCurrentCategoryFilter(activeCategoryFilter);
    }, [activeCategoryFilter, setCurrentCategoryFilter]);

    useEffect(() => {
        setOrganicState(organicFilter);
    }, [organicFilter, setOrganicState]);

    return (
        <>
            <button className="pi-filter-button" onClick={() => setIsOpen(true)}>
                <Icon size="xx-large" raw src={FilterIcon}></Icon>
            </button>
            <Modal
                className="pi-product-filter-modal"
                isMounted={isOpen}
                closeModal={() => setIsOpen(false)}
                title={
                    <div>
                        {trans('salePage.filter.tags')}
                        <Text size="12px" lineHeight="14px" weight={400} color="gray9">
                            {mainCategory.name}
                        </Text>
                    </div>
                }
                footer={
                    <div className="filter-modal-footer">
                        <Button
                            size={SMALL_SIZE}
                            mode={SECONDARY_MODE}
                            onClick={reset}
                            disabled={
                                organicState === ALL_PRODUCTS_FILTER &&
                                !currentCategoryFilter &&
                                currentTag === ALL_TAGS_FILTER
                            }
                        >
                            {trans('global.reset')}
                        </Button>
                        <Button
                            size={SMALL_SIZE}
                            onClick={getPath}
                            disabled={
                                organicState === organicFilter &&
                                currentCategoryFilter === activeCategoryFilter &&
                                currentTag === activeFilterTag
                            }
                        >
                            {trans('global.apply')}
                        </Button>
                    </div>
                }
            >
                <div>
                    <div className="pi-product-filter-modal-organic">
                        <Text
                            className="sale-content-info-filter-title"
                            bold
                            color="gray2"
                            size="16px"
                            lineHeight="24px"
                        >
                            {trans('salePage.filter.type')}
                        </Text>

                        <Switch
                            className="pi-product-filter-modal-organic-switch"
                            label={trans('salePage.organicProducts')}
                            onClick={() =>
                                setOrganicState(
                                    organicState === ORGANIC_FILTER
                                        ? ALL_PRODUCTS_FILTER
                                        : ORGANIC_FILTER
                                )
                            }
                            checked={organicState === ORGANIC_FILTER}
                            theme={SWITCH_THEME_SELECTED_GREY}
                        />
                    </div>
                    {filterDatas.categories.length > 0 && (
                        <div className="pi-product-filter-modal-categories">
                            <Text
                                className="sale-content-info-filter-title"
                                bold
                                color="gray2"
                                size="16px"
                                lineHeight="24px"
                            >
                                {mainCategory.isTrueCategory
                                    ? mainCategory.name
                                    : trans('products.categories')}
                            </Text>
                            {filterDatas.categories.map(categorie => (
                                <div key={categorie.id}>
                                    <Tag
                                        size="small"
                                        label={categorie.name}
                                        selected={currentCategoryFilter === categorie.id}
                                        onClick={() =>
                                            setCurrentCategoryFilter(
                                                currentCategoryFilter === categorie.id
                                                    ? null
                                                    : categorie.id
                                            )
                                        }
                                        theme={THEME_SELECTED_GREY}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    {filterDatas.tags.length > 0 && (
                        <div className="pi-product-filter-modal-tags">
                            <Text
                                className="sale-content-info-filter-title"
                                bold
                                color="gray2"
                                size="16px"
                                lineHeight="24px"
                            >
                                {trans('salePage.filter.tags')}
                            </Text>
                            {filterDatas.tags.map(tag => (
                                <div key={tag.name}>
                                    <Tag
                                        label={applyTagTrans(trans, transChoice)(tag)}
                                        selected={currentTag === tag}
                                        onClick={() =>
                                            setCurrentTag(
                                                currentTag === tag ? ALL_TAGS_FILTER : tag
                                            )
                                        }
                                        theme={THEME_SELECTED_GREY}
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Modal>
        </>
    );
};

FilterButtonMobile.propTypes = {
    organicFilter: PropTypes.string,
    mainCategory: PropTypes.shape({}),
    activeCategoryFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    activeFilterTag: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    allProducts: PropTypes.array,
    hasGoodDealMode: PropTypes.bool,
};

FilterButtonMobile.defaultProps = {
    activeFilterTag: ALL_TAGS_FILTER,
    hasGoodDealMode: false,
};
export default FilterButtonMobile;
