import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'components/Modal.jsx';
import RadioButton from 'components/RadioButton.jsx';
import Legend from 'components/Legend.jsx';
import Heading from 'components/Heading.jsx';

const propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string,
            options: PropTypes.array,
        })
    ).isRequired,
    closeModal: PropTypes.func.isRequired,
    isOpened: PropTypes.bool,
};

export class OptionsModal extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.renderOption = this.renderOption.bind(this);
    }

    handleChange(value) {
        this.props.onChange(this.props.name, value);
        setTimeout(this.props.closeModal, 200);
    }

    renderOptionGroup(optionGroup) {
        return (
            <div key={optionGroup.label} label={optionGroup.label} className="u-mb-4">
                <Legend className="u-mb-1">{optionGroup.label}</Legend>
                {optionGroup.options.map(this.renderOption)}
            </div>
        );
    }

    renderOption(option) {
        return (
            <RadioButton
                key={option.value}
                className="optionsModal-options"
                inputId={`${this.props.name}-${option.value}`}
                name={this.props.name}
                value={option.value}
                isSelected={option.value === this.props.value}
                onChange={this.handleChange}
                variant="circle"
            >
                {option.label}
            </RadioButton>
        );
    }

    render() {
        return (
            <Modal closeModal={this.props.closeModal} isOpened={this.props.isOpened}>
                <div>
                    <Heading className="u-mb-3" size={4}>
                        {this.props.title}
                    </Heading>
                    {this.props.options.map(option => {
                        if (option.options) {
                            return this.renderOptionGroup(option);
                        }
                        return this.renderOption(option);
                    })}
                </div>
            </Modal>
        );
    }
}

OptionsModal.propTypes = propTypes;

export default OptionsModal;
