import { createSelector } from 'reselect';
import * as R from 'ramda';
import { openCountriesList, sortByName } from 'models/countries';

export const countriesSelector = state => R.pathOr([], ['countries', 'data'], state);

export const getAllCountries = createSelector(countriesSelector, countries => countries);

export const getCountriesForSelect = (valueKey = 'id') =>
    createSelector(countriesSelector, countries =>
        R.pipe(
            R.map(country => ({
                ...country,
                label: country.name,
                value: country[valueKey].toString(),
            })),
            sortByName,
            R.partition(country => R.contains(country.code, openCountriesList)),
            R.flatten
        )(countries)
    );
