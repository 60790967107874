import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Text, { SMALL } from 'src/components/atoms/Text/Text.jsx';
import ValidateIcon from 'app/assets/new-design/images/icons/validate-icon.svg';
import CrossIcon from 'app/assets/new-design/images/icons/close.svg';
import Icon from 'src/components/atoms/Icons/Icon.jsx';
export const TYPE = {
    VALID: 'valid',
};
const Snackbar = ({ type, message, delay, noCross }) => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        setTimeout(() => setShow(true), 200);
        delay && setTimeout(() => setShow(false), delay + 200);
    }, [delay]);
    return (
        <div className={classNames('nd-snackbar', type, { open: show })}>
            <Icon className="snackbar-type-icon" src={ValidateIcon} size="x-large"></Icon>
            <Text className="snackbar-text" size={SMALL}>
                {message}
            </Text>
            {!noCross && (
                <Icon
                    className="cross-icon"
                    onClick={() => setShow(false)}
                    src={CrossIcon}
                    size="s-small"
                ></Icon>
            )}
        </div>
    );
};

Snackbar.propTypes = {
    type: PropTypes.string,
    message: PropTypes.string,
    delay: PropTypes.number,
    noCross: PropTypes.bool,
};
Snackbar.defaultProps = {
    type: TYPE.VALID,
    message: '',
    delay: 0,
    noCross: false,
};
export default Snackbar;
