import PropTypes from 'prop-types';
import React from 'react';
import ResponsiveNavigation from '../ResponsiveNavigation.jsx';
import NavigationRegion from '../NavigationRegion.jsx';
import ContextDropdown from '../ContextDropdown.jsx';
import UserDropdown from '../UserDropdown.jsx';
import HelpDropdown from '../HelpDropdown.jsx';
import HelpBottomDropdown from '../HelpBottomDropdown.jsx';
import UserBottomDropdown from '../UserBottomDropdown.jsx';
import LeftNavigationLink from '../LeftNavigationLink.jsx';
import MessagesLink from '../MessagesLink.jsx';
import BottomNavigationLink from '../BottomNavigationLink.jsx';
import connectToI18n from 'modules/i18n/connectToI18n';
import NewsfeedDropdown from 'components/Navigation/NewsfeedDropdown.jsx';
import NewsfeedBottomDropdown from 'components/Navigation/NewsfeedBottomDropdown.jsx';

export class FarmPendingNavigation extends React.Component {
    static propTypes = {
        isSmallWidth: PropTypes.bool,
        activeLink: PropTypes.string,
        trans: PropTypes.func.isRequired,
    };

    render() {
        const t = this.props.trans;

        const { isSmallWidth, activeLink } = this.props;

        return (
            <ResponsiveNavigation isSmallWidth={isSmallWidth}>
                <NavigationRegion position="top" legacy>
                    <div className="topNavigation-left">
                        <ContextDropdown {...this.props} />
                    </div>
                    <div className="topNavigation-right">
                        <NewsfeedDropdown {...this.props} />
                        <MessagesLink {...this.props} />
                        <HelpDropdown {...this.props} />
                        <UserDropdown {...this.props} />
                    </div>
                </NavigationRegion>
                <NavigationRegion position="left">
                    <LeftNavigationLink
                        href="producers/me/products/stocks"
                        icon="catalog"
                        isActive={activeLink === 'menu.catalog'}
                    >
                        {t('navigation.left.farm.products')}
                    </LeftNavigationLink>
                    <LeftNavigationLink
                        href="producers/folder"
                        icon="folder"
                        isActive={activeLink === 'menu.farm.farmFolder'}
                    >
                        {t('navigation.left.producerFolder')}
                    </LeftNavigationLink>
                </NavigationRegion>
                <NavigationRegion position="bottom">
                    <BottomNavigationLink icon="catalog" href="producers/me/products/stocks">
                        {t('navigation.bottom.products')}
                    </BottomNavigationLink>
                    <BottomNavigationLink icon="producer-folder" href="producers/folder">
                        {t('navigation.bottom.producerFolder')}
                    </BottomNavigationLink>
                    <BottomNavigationLink />
                    <UserBottomDropdown {...this.props} />
                    <HelpBottomDropdown {...this.props} />
                    <NewsfeedBottomDropdown {...this.props} />
                </NavigationRegion>
            </ResponsiveNavigation>
        );
    }
}

export default connectToI18n(FarmPendingNavigation);
