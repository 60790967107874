import React from 'react';
import PropTypes from 'prop-types';
import { getAddress } from 'app/scripts/components/geo/placeHelpers.js';
import Modal from 'components/Modal.jsx';
import Heading from 'components/Heading.jsx';
import Address from 'components/Address.jsx';
import SimpleMap from './SimpleMap.jsx';

export class PlaceMapModal extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        entity: PropTypes.object.isRequired,
        placeType: PropTypes.oneOf(['assembly', 'farm', 'pickup']).isRequired,
        isOpen: PropTypes.bool.isRequired,
        closeModal: PropTypes.func,
        defaultZoom: PropTypes.number,
        children: PropTypes.node,
    };

    render() {
        const { title, entity, placeType } = this.props;

        return (
            <Modal
                isOpened={this.props.isOpen}
                closeModal={this.props.closeModal}
                className="mapModal"
            >
                <div className="mapModal-wrapper">
                    <div className="u-mb-4">
                        {title && (
                            <Heading className="u-mb-1" size={1}>
                                {title}
                            </Heading>
                        )}
                        {this.props.children || <Address address={getAddress(placeType, entity)} />}
                    </div>
                    <SimpleMap
                        places={[{ entity, placeType }]}
                        className="mapModal-map"
                        defaultZoom={this.props.defaultZoom}
                    />
                </div>
            </Modal>
        );
    }
}

export default PlaceMapModal;
