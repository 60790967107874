import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import useI18n from 'hooks/I18n/useI18n';
import useResponsive from 'hooks/Navigation/useResponsive';

import Utils from 'modules/utils';

import { formatStorageLife } from 'models/products';

import PIText from 'components/ProductIdentity/Text.jsx';
import Button, { LINK_MODE } from 'src/components/atoms/Button/Button.jsx';
import ProductLabel, { BIG_SIZE } from 'components/Product/ProductLabel.jsx';
import PlaceMapModal from 'components/Maps/ProductIdentity/PlaceMapModal.jsx';
import Tag from 'src/components/atoms/Tag/Tag.jsx';
import Icon from 'components/Icon.jsx';
import Tabs from 'src/components/organisms/Tabs/Tabs.jsx';
import fridgeIcon from 'icons/fridge.svg';

const { getLocalizedDistance } = Utils;

const OtherProductInformations = props => {
    const {
        product,
        assembly: {
            place: { address },
        },
    } = props;
    const { allergens, farm, labels, storageLife, composition } = product;
    const { trans, i18n } = useI18n();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const isTabletOrMobile = useResponsive(991);
    const coordinates = useMemo(() => ({ latitude: farm.latitude, longitude: farm.longitude }), [
        farm,
    ]);

    const farmAddress = useMemo(
        () => ({
            address: { street: farm.street, city: { name: farm.city }, coordinates },
        }),
        [farm, coordinates]
    );

    const distanceToAssembly = useMemo(
        () => getLocalizedDistance(coordinates, address.coordinates, address.country.code),
        [address, coordinates]
    );
    const allergensList = useMemo(
        () =>
            allergens.length > 0
                ? R.pipe(
                      R.pluck('translationKey'),
                      R.map(w => <Tag key={w} label={trans(w)} readOnly />)
                  )(allergens)
                : null,
        [allergens, trans]
    );

    const ingredientsList = useMemo(() => {
        return composition.length > 0
            ? composition.map(({ name, origin }) => {
                  const nameHtml = (
                      <PIText className="pi-origin-tag" weight={500} size="14px" lineHeight="16px">
                          {name}
                      </PIText>
                  );
                  const label = !!origin ? (
                      <>
                          {nameHtml}
                          &nbsp;-&nbsp;
                          {origin}
                      </>
                  ) : (
                      nameHtml
                  );
                  return <Tag className="ingredient-tag" key={name} label={label} readOnly />;
              })
            : null;
    }, [composition]);

    const openMapModal = useCallback(() => setModalIsOpen(true), []);

    const closeMapModal = useCallback(() => setModalIsOpen(false), []);

    const items = [
        {
            key: '0',
            label: (
                <PIText
                    className="pi-product-other-informations-title"
                    size="18px"
                    lineHeight="26px"
                    family="inter"
                    weight={700}
                    color="grey8"
                >
                    {trans('products.productPage.productOriginTitle')}
                </PIText>
            ),
            children: (
                <>
                    <PIText size="14px" lineHeight="20px" family="inter" color="gray2">
                        {trans('products.productPage.productOrigin', {
                            '%location%': farm.city,
                            '%distance%': distanceToAssembly,
                        })}
                    </PIText>
                    {R.path(['farm', 'authorFarmName'], product) && (
                        <PIText
                            size="14px"
                            lineHeight="20px"
                            family="inter"
                            color="gray2"
                            dangerouslySetInnerHTML={{
                                __html: trans('salepage.prodSourceInfo', {
                                    '%sellerName%': R.path(['farm', 'authorFarmName'], product),
                                }),
                            }}
                        />
                    )}
                    <div>
                        <Button mode={LINK_MODE} onClick={openMapModal}>
                            {trans('global.seeOnMap')}
                        </Button>
                    </div>
                </>
            ),
        },
        {
            key: '1',
            label: (
                <PIText
                    className="pi-product-other-informations-title"
                    size="18px"
                    lineHeight="26px"
                    family="inter"
                    color="grey8"
                    weight={700}
                >
                    {labels
                        ? trans('products.productPage.preservationAndLabels')
                        : trans('global.preservation')}
                </PIText>
            ),
            children: (
                <>
                    <PIText size="14px" lineHeight="20px" family="inter" color="gray2">
                        <Icon className="productPage-icon" size="xxx-large" src={fridgeIcon} />
                        {trans('products.productPage.storageGarantee')}{' '}
                        {formatStorageLife(storageLife, i18n)}
                    </PIText>
                    {labels &&
                        labels.map(label => (
                            <PIText
                                size="14px"
                                lineHeight="20px"
                                family="inter"
                                color="gray2"
                                key={label.id}
                                className="product-label-with-text"
                            >
                                <ProductLabel
                                    size={BIG_SIZE}
                                    name={label.name}
                                    photoId={label.photoId}
                                />
                                <span>{label.name}</span>
                            </PIText>
                        ))}
                </>
            ),
        },
    ];
    ingredientsList &&
        items.push({
            key: '2',
            label: (
                <PIText
                    className="pi-product-other-informations-title"
                    size="18px"
                    lineHeight="26px"
                    family="inter"
                    weight={700}
                    color="grey8"
                >
                    {trans('global.ingredients')}
                </PIText>
            ),
            children: <div className="pi-product-tag-list">{ingredientsList}</div>,
        });
    allergensList &&
        items.push({
            key: '3',
            label: (
                <PIText
                    className="pi-product-other-informations-title"
                    size="18px"
                    lineHeight="26px"
                    family="inter"
                    weight={700}
                    color="grey8"
                >
                    {trans('global.allergens')}
                </PIText>
            ),
            children: <div className="pi-product-tag-list">{allergensList}</div>,
        });
    return (
        <div className="pi-product-other-informations">
            {isTabletOrMobile ? (
                items.map(item => (
                    <div className="pi-product-other-informations-item" key={item.key}>
                        {item.label}
                        {item.children}
                    </div>
                ))
            ) : (
                <Tabs items={items} />
            )}
            {modalIsOpen && (
                <PlaceMapModal
                    title={farm.name}
                    placeType="farm"
                    isOpen
                    entity={farmAddress}
                    closeModal={closeMapModal}
                    defaultZoom={6}
                >
                    <p>
                        {trans('products.productPage.productOrigin', {
                            '%location%': farm.city,
                            '%distance%': distanceToAssembly,
                        })}
                    </p>
                </PlaceMapModal>
            )}
        </div>
    );
};

OtherProductInformations.propTypes = {
    product: PropTypes.shape({
        allergens: PropTypes.array,
        labels: PropTypes.array,
        storageLife: PropTypes.string,
        farm: PropTypes.shape({
            city: PropTypes.string,
            name: PropTypes.string,
            street: PropTypes.string,
            latitude: PropTypes.string,
            longitude: PropTypes.string,
        }),
    }).isRequired,
    assembly: PropTypes.shape({
        place: PropTypes.shape({
            address: PropTypes.object,
        }),
    }).isRequired,
};
export default OtherProductInformations;
