import React, { useState } from 'react';
import onsiteIcon from 'icons/onsite-sale-logo.svg';
import { Box, Button, Heading, Icon, Paragraph, Text } from 'components/ui';
import useI18n from 'hooks/I18n/useI18n.js';
import angleUpIcon from 'icons/angle-up.svg';
import angleDownIcon from 'icons/angle-down.svg';

const PlainTextOnSiteNotice = () => {
    const { trans } = useI18n();
    const [isDetailsOpen, setDetailsOpen] = useState(false);

    return (
        <Box className="u-mb-3" responsive="plain">
            <div className="u-flexible-row u-mb-2">
                <Icon className="u-mr-2" size="xxx-large" src={onsiteIcon} />
                <Heading size={3}>{trans('onsite_sales_notice_title')}</Heading>
            </div>
            <Paragraph>{trans('onsite_sales_notice_text')}</Paragraph>
            <div className="u-mb-3">
                <Button
                    icon={isDetailsOpen ? angleUpIcon : angleDownIcon}
                    iconPosition="outside"
                    iconSide="right"
                    onClick={() => {
                        setDetailsOpen(!isDetailsOpen);
                    }}
                    variant="primary"
                    weight="link"
                >
                    {trans('header.how')}
                </Button>
            </div>
            {isDetailsOpen && (
                <div>
                    {[1, 2, 3, 4].map(step => {
                        return (
                            <div className="u-flexible-row u-mb-3" key={step}>
                                <img
                                    className="u-mr-3"
                                    src={`/assets/images/onsite-sales/notice-step-${step}.svg`}
                                    width="72px"
                                />
                                <Text>{trans(`onsite_sales_notice_details_step${step}`)}</Text>
                            </div>
                        );
                    })}
                </div>
            )}
        </Box>
    );
};

export default PlainTextOnSiteNotice;
