import { computeFees } from 'modules/orders/fees';
import { getDeliveryOptionInfo } from 'models/delivery';
import * as localstorage from 'modules/localstorage';
import * as R from 'ramda';

const getPickupFees = (pickup, totalPrice) => {
    if (pickup.fee && pickup.fee?.rate > 0 && totalPrice) {
        return computeFees(totalPrice, pickup.fee);
    }

    if (pickup.fee?.price) {
        return pickup.fee.price;
    }

    if (pickup.price && pickup.price.amount > 0) {
        return pickup.price;
    }

    if (totalPrice) {
        return {
            amount: 0,
            currency: totalPrice.currency,
        };
    }

    return null;
};

const getTotalPrice = (shippingFees, totalPriceWithoutShipping, interSalePrice) => {
    const interSaleAmount = interSalePrice ? interSalePrice.amount : 0;
    return {
        amount: shippingFees.amount + totalPriceWithoutShipping.amount + interSaleAmount,
        currency: totalPriceWithoutShipping.currency,
    };
};

export const computeBasketPrices = (
    selectedOption,
    purchasedPrice,
    deliveryOptions,
    interSalePrice
) => {
    const { distributionId, ref } = getDeliveryOptionInfo(selectedOption);
    let selectedPickup = R.find(
        R.propSatisfies(p => p === ref || p === parseInt(ref, 10), 'ref'),
        deliveryOptions
    );

    /**
     * If the selectedPickup is empty and we have options in deliveryOptions,
     * it's possible that the selectedOption was from the localStorage.
     * If the selectedOption came from localStorage, he isn't consistent with the deliveryOptions.
     * Why we want the deliveryOptions not empty?
     * Because we need to filter every case for this bug. We want the sentry report when
     * deliveryOptions is empty.
     */
    if (R.isNil(selectedPickup) && !R.isNil(deliveryOptions)) {
        // Get the default assembly delivery option
        // ("assembly" type for assemblies with on site picking,
        // or "deliveryOffer" for Home delivery only assemblies).
        selectedPickup =
            R.find(R.propEq('type', 'assembly'))(deliveryOptions) ||
            R.find(R.propEq('type', 'deliveryOffer'))(deliveryOptions);
        if (localstorage.getItem(`delivery.option_${distributionId}`) === selectedOption) {
            localstorage.removeItem(`delivery.option_${distributionId}`);
        }
    }

    if (R.isNil(selectedPickup)) {
        /**
         * I am trying to see if this is a localStorage related issue.
         */
        const myLocalStorage = [];
        try {
            // eslint-disable-next-line no-restricted-globals
            Object.keys(localStorage).forEach(local => {
                if (local.match(/delivery/)) {
                    // eslint-disable-next-line no-restricted-globals
                    myLocalStorage.push(localStorage.getItem(local));
                }
            });
        } catch (e) {
            // localStorage not supported
        }
        throw new Error(
            `Could not find the selected option "${selectedOption}" in options "${R.pipe(
                R.pluck('type'),
                R.join(',')
            )(deliveryOptions)}" with localStorage "${R.join(',', myLocalStorage)}"`
        );
    }
    const shippingFees = getPickupFees(selectedPickup, purchasedPrice);
    const totalPrice = getTotalPrice(shippingFees, purchasedPrice, interSalePrice);
    return { purchasedPrice, shippingFees, totalPrice };
};

export const hasShippingOptions = R.any(pickup =>
    R.includes(R.prop('type', pickup), ['deliveryOffer', 'pickup'])
);

export const isOrderHasCharity = order =>
    R.has('totalCharity', order) && R.gt(R.path(['totalCharity', 'amount'], order), 0);

export const getSelectedDeliveryOption = ({ selectedOption, deliveryOptions }) => {
    if (R.isNil(selectedOption)) {
        return undefined;
    }
    const { ref } = getDeliveryOptionInfo(selectedOption);
    return R.find(
        R.propSatisfies(p => p === parseInt(ref, 10) || p === ref, 'ref'),
        deliveryOptions
    );
};
