import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import { useHistory } from 'react-router-dom';

import useAnalytics from 'hooks/Analytics/useAnalytics.js';
import useI18n from 'hooks/I18n/useI18n';

import { isEligibleForPro, isMemberOnly } from 'models/users';
import { createFarm } from 'api/farm';
import { createLeaderProfile } from 'api/users';

import { userSelector, refreshCurrentUser, imperativeEditUser } from 'modules/currentUser';

import Heading from 'components/Heading.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import HiveFarmSectionPanel from 'components/Navigation/ProductIdentity/HivePanel/HiveFarmSectionPanel.jsx';
import HiveCard from 'components/Navigation/ProductIdentity/HivePanel/HiveCard.jsx';

import Link from 'src/components/atoms/Link/Link.jsx';
import Modal from 'src/components/organisms/Modal/Modal.jsx';
import Button, { SECONDARY_MODE } from 'src/components/atoms/Button/Button.jsx';

const HivesPanel = ({
    shouldDisplayProviderFolder,
    shouldDisplayAccounting,
    shouldDisplayAssemblies,
    badgeValue,
    hasPendingLeaderProfile,
    onClose,
}) => {
    const { analytics } = useAnalytics();
    const { trans, i18nConfig } = useI18n();
    const history = useHistory();
    const dispatch = useDispatch();
    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
    const currentUser = useSelector(userSelector);

    const userAssemblies = useMemo(
        () => R.reject(R.pathEq(['leader', 'id'], currentUser.id), currentUser.hivesAsMember || []),
        [currentUser]
    );

    const trackMiniRucheLink = useCallback(() => {
        analytics.track('clickMiniRucheCreationShortcut', {
            category: 'Pickups',
        });
    }, [analytics]);

    const handleCreateLeaderProfile = useCallback(() => {
        const allowCreation = isEligibleForPro(currentUser);
        if (!allowCreation) {
            onClose();
            return history.push('/validate/scu/host');
        }
        createLeaderProfile(currentUser.id).then(() => {
            dispatch(
                imperativeEditUser(R.mergeRight({ leaderProfile: { blob: '{}', status: 'init' } }))
            );
            onClose();
            history.push('/progress');
        });
        return null;
    }, [currentUser, dispatch, history, onClose]);

    const handleOpenFarmSpace = useCallback(() => {
        if (!isEligibleForPro(currentUser)) {
            setConfirmModalOpen(false);
            onClose();
            history.push('/validate/scu/producer');
        } else {
            createFarm().then(() => {
                dispatch(refreshCurrentUser()).then(() => {
                    setConfirmModalOpen(false);
                    onClose();
                    history.push('/producers/folder');
                });
            });
        }
    }, [currentUser, dispatch, history, onClose]);

    return (
        <div className="hives-panel">
            <div className="hives-panel-section">
                {!isMemberOnly({ user: currentUser }) && (
                    <Heading className="hives-panel-section-title" productIdentity size={1} bold>
                        {trans('navigation.context.assemblies_as_member_only')}
                    </Heading>
                )}
                <div className="hives-panel-section-links">
                    <Link onClick={onClose} to={'/configuration/account/assemblies'}>
                        {trans('member.panel.manageHives')}
                    </Link>
                    <Link onClick={onClose} to={'/assemblies'}>
                        {trans('menu.joinHive')}
                    </Link>
                </div>
                {currentUser.isMember && (
                    <div className="hives-panel-list">
                        {userAssemblies.map(assembly => (
                            <HiveCard key={assembly.id} onClick={onClose} assembly={assembly} />
                        ))}
                    </div>
                )}
            </div>
            {currentUser.isLeader && (
                <div className="hives-panel-section">
                    <Heading className="hives-panel-section-title" productIdentity size={1} bold>
                        {trans('navigation.context.assemblies_as_host_new')}
                    </Heading>
                    <div className="hives-panel-section-links">
                        <Link
                            bypassRouter
                            href={`https://miniruche.com/${R.propOr('fr-FR', 'code', i18nConfig)}`}
                            onClick={trackMiniRucheLink}
                        >
                            {trans('navigation.context.createMiniRuche')}
                        </Link>
                    </div>
                    <div className="hives-panel-list">
                        {(currentUser.hivesAsLeader || []).map(assembly => (
                            <HiveCard key={assembly.id} onClick={onClose} assembly={assembly} />
                        ))}
                    </div>
                </div>
            )}
            {currentUser.isFarmer && (
                <HiveFarmSectionPanel
                    shouldDisplayProviderFolder={shouldDisplayProviderFolder}
                    shouldDisplayAccounting={shouldDisplayAccounting}
                    shouldDisplayAssemblies={shouldDisplayAssemblies}
                    badgeValue={badgeValue}
                />
            )}
            <div className="hives-panel-footer">
                {!currentUser.leaderProfile && !hasPendingLeaderProfile && (
                    <>
                        {currentUser?.locale === 'fr_FR' ? (
                            <Link
                                bypassRouter
                                href="https://ouvrir-une-ruche.laruchequiditoui.fr/"
                                target="_blank"
                            >
                                {trans('hives.create.title')}
                            </Link>
                        ) : (
                            <Link bypassRouter onClick={handleCreateLeaderProfile}>
                                {trans('hives.create.title')}
                            </Link>
                        )}
                    </>
                )}
                <Link onClick={onClose} to={'/accomodate'} target="_blank">
                    {trans('local.propose')}
                </Link>
                {hasPendingLeaderProfile && (
                    <Link onClick={onClose} to={'/progress'}>
                        {trans('initLeaderProfile.actionTextRestart')}
                    </Link>
                )}
                {!currentUser.isFarmer && (
                    <>
                        <Link
                            className="test-open-farm-button"
                            bypassRouter
                            onClick={() => setConfirmModalOpen(true)}
                        >
                            {trans('menu.openProviderFolder')}
                        </Link>
                        <Modal
                            isMounted={isConfirmModalOpen}
                            closeModal={() => setConfirmModalOpen(false)}
                            title={
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: trans('modals.openFarm.title'),
                                    }}
                                />
                            }
                            footer={
                                <div className="modal-open-provider-folder-footer">
                                    <Button
                                        mode={SECONDARY_MODE}
                                        onClick={() => setConfirmModalOpen(false)}
                                    >
                                        {trans('global.cancel')}
                                    </Button>
                                    <Button
                                        className="confirmOpenFarm-button"
                                        onClick={handleOpenFarmSpace}
                                    >
                                        {trans('modals.openFarm.cta')}
                                    </Button>
                                </div>
                            }
                        >
                            <Text
                                size="16px"
                                lineHeight="24px"
                                weight={400}
                                color="gray2"
                                dangerouslySetInnerHTML={{
                                    __html: trans('modals.openFarm.text'),
                                }}
                            />
                        </Modal>
                    </>
                )}
            </div>
        </div>
    );
};
HivesPanel.propTypes = {
    shouldDisplayProviderFolder: PropTypes.bool,
    shouldDisplayAccounting: PropTypes.bool,
    shouldDisplayAssemblies: PropTypes.bool,
    badgeValue: PropTypes.number,
    hasPendingLeaderProfile: PropTypes.bool,
    onClose: PropTypes.func,
};
HivesPanel.defaultProps = {
    shouldDisplayProviderFolder: false,
    shouldDisplayAccounting: false,
    shouldDisplayAssemblies: false,
    badgeValue: 0,
    hasPendingLeaderProfile: false,
    onClose: () => null,
};
export default HivesPanel;
