import React, { Component } from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import PropTypes from 'prop-types';
import Button from 'components/Button.jsx';
import InlineSVG from 'svg-inline-react';

const propTypes = {
    offerId: PropTypes.number.isRequired,
    addOfferToBasket: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSmallWidth: PropTypes.bool,
    isStockExhausted: PropTypes.bool.isRequired,
    quantityInBasket: PropTypes.number.isRequired,
    trans: PropTypes.func.isRequired,
};

export class PurchaseButton extends Component {
    handleClick = () => {
        this.props.addOfferToBasket(this.props.offerId, this.props.quantityInBasket + 1);
    };

    renderCounter = () => {
        if (this.props.quantityInBasket === 0) {
            if (this.props.isSmallWidth) {
                return (
                    <div>
                        <InlineSVG
                            className="highlightedProducts-purchaseButtonBasketIcon"
                            src={require('icons/basket.svg')}
                        />
                        <InlineSVG
                            className="highlightedProducts-purchaseButtonPlusIcon"
                            src={require('icons/plus-sign.svg')}
                        />
                    </div>
                );
            }
            return this.props.trans('products.salesBox.offers.addToBasket');
        }
        return (
            <div>
                <InlineSVG
                    className="highlightedProducts-purchaseButtonBasketIcon"
                    src={require('icons/basket.svg')}
                />
                <span className="highlightedProducts-purchaseButtonQuantityInBasket">
                    {this.props.quantityInBasket}
                </span>
            </div>
        );
    };

    render() {
        const { isLoading, trans } = this.props;

        if (this.props.isStockExhausted) {
            return (
                <span className="highlightedProducts-purchaseButtonNoPurchase">
                    {trans('offer.unavailable')}
                </span>
            );
        }

        return (
            <Button
                className="highlightedProducts-purchaseButton ignore-react-onclickoutside"
                loading={isLoading}
                onClick={this.handleClick}
                variant="success"
            >
                {this.renderCounter()}
            </Button>
        );
    }
}

PurchaseButton.propTypes = propTypes;

export default connectToI18n(PurchaseButton);
