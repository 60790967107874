import React, { forwardRef, useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as R from 'ramda';
import animatedScrollTo from 'animated-scrollto';
import { List, WindowScroller } from 'react-virtualized';
import { useHistory } from 'react-router-dom';
import useI18n from 'hooks/I18n/useI18n';

import { displayableCity } from 'models/address';

import Utils from 'modules/utils';
import { hasAvailableStock } from 'modules/stockUtils.js';
import { PRODUCTS_LIST } from 'modules/originUtils.js';
import { memoizeProductsOfFarm } from 'modules/utils/saleCategoriesAndFilters';

import { sortProductsByPopularityDesc } from 'components/Sale/sortUtils.js';
import Heading from 'components/Heading.jsx';

import FarmHeader from 'components/Sale/ProductIdentity/FarmHeader.jsx';
import ProductCard from 'components/Sale/ProductIdentity/ProductCard.jsx';
import PopularProducts from 'components/Sale/PopularProducts.jsx';

import {
    getScreenConfig,
    getLineConfig,
    getRowHeight,
    TITLE_ROW_TYPE,
    FARM_ROW_TYPE,
    POPULAR_PRODUCTS_ROW_TYPE,
} from './utils';

// *********************
// ***   COMPONENT   ***
// *********************
export const ProductsList = forwardRef((props, ref) => {
    const {
        addOfferToBasket,
        assembly,
        distribution,
        farms,
        products,
        productTypes,
        onClickOnProduct,
        setCurrentFarm,
    } = props;
    const { trans } = useI18n();
    const listRef = useRef();
    const [screenConfig, setScreenConfig] = useState(getScreenConfig());
    const farmsAndProductsComponents = [];
    const history = useHistory();

    const updateCurrentFarm = useCallback(() => {
        const offset = window.scrollY;
        const headerHeight = getScreenConfig().headerHeight;
        const isFirstVisible = component => {
            return (
                offset + headerHeight <= component.scrollY &&
                component.scrollY <= offset + 300 + headerHeight
            );
        };
        const firstVisibleComponent = R.find(isFirstVisible, farmsAndProductsComponents);
        if (firstVisibleComponent) {
            const farm = R.find(R.propEq('id', firstVisibleComponent.farmId), farms);
            setCurrentFarm(farm.id);
        }
    }, [farms, farmsAndProductsComponents, setCurrentFarm]);

    const getFarmPopularProductsRows = useCallback(
        (currentFarmId, productsOfFarm) => {
            const popularProducts = R.pipe(
                R.filter(hasAvailableStock),
                sortProductsByPopularityDesc('globalPopularity'),
                R.take(5)
            )(productsOfFarm);
            const headerPopularProductsRow = {
                component: (
                    <Heading
                        className="pi-products-list-section-title"
                        rank={3}
                        size={4}
                        bold
                        serif
                        productIdentity
                    >
                        {trans('salePage.popularProducts')}
                    </Heading>
                ),
                farmId: currentFarmId,
                type: TITLE_ROW_TYPE,
            };

            const popularProductsRow = {
                component: (
                    <PopularProducts
                        addOfferToBasket={addOfferToBasket}
                        productTypes={productTypes}
                        assemblyId={assembly.id}
                        distributionId={distribution.id}
                        products={popularProducts}
                        onClickOnProduct={onClickOnProduct}
                    />
                ),
                itemCount: popularProducts.length,
                farmId: currentFarmId,
                type: POPULAR_PRODUCTS_ROW_TYPE,
            };
            return [headerPopularProductsRow, popularProductsRow];
        },
        [addOfferToBasket, assembly, distribution, onClickOnProduct, productTypes, trans]
    );

    farms.forEach(farm => {
        const currentFarmId = farm.id;
        const productsOfFarm = memoizeProductsOfFarm(currentFarmId, products);
        const farmAddress = displayableCity(farm);
        let subtitle = Utils.getLocalizedDistance(
            farm,
            assembly.place.address.coordinates,
            assembly.place.address.country.code
        );
        subtitle = subtitle ? `${farmAddress} - ${subtitle} • ` : null;

        farmsAndProductsComponents.push({
            component: (
                <FarmHeader
                    title={farm.name}
                    subtitle={subtitle}
                    image={farm.farmerPhotoId}
                    description={farm.description}
                />
            ),
            farmId: currentFarmId,
            type: FARM_ROW_TYPE,
        });

        if (productsOfFarm.length >= 9) {
            farmsAndProductsComponents.push(
                ...getFarmPopularProductsRows(currentFarmId, productsOfFarm)
            );
        }

        farmsAndProductsComponents.push({
            component: (
                <Heading
                    className="pi-products-list-section-title"
                    rank={3}
                    size={4}
                    bold
                    serif
                    productIdentity
                >
                    {trans('salePage.allItsProducts')}
                </Heading>
            ),
            farmId: currentFarmId,
            type: TITLE_ROW_TYPE,
        });

        for (const { mode, productsLine, isLastRow } of getLineConfig(productsOfFarm)) {
            if (productsLine.length === 0) {
                continue;
            }
            const component = (
                <div className={classnames('pi-products-row')}>
                    {productsLine.map(product => (
                        <ProductCard
                            mode={mode}
                            showProducer={false}
                            addOfferToBasket={addOfferToBasket}
                            allProductTypesIndexedById={productTypes}
                            assemblyId={assembly.id}
                            distributionId={distribution.id}
                            farm={product.farm}
                            product={product}
                            isLabelDescriptionAvailable={false}
                            actionOrigin={PRODUCTS_LIST}
                            onClick={() => onClickOnProduct(product.id)}
                        />
                    ))}
                </div>
            );

            farmsAndProductsComponents.push({
                component,
                itemCount: productsLine.length,
                farmId: currentFarmId,
                type: mode,
                isLastRow,
            });
        }
    });
    const updateDimensions = useCallback(() => {
        setScreenConfig(getScreenConfig());
        listRef.current && listRef.current.recomputeRowHeights();
    }, []);
    const rowRenderer = useCallback(
        ({ key, index, style }) => {
            return (
                <div key={key} style={style}>
                    {farmsAndProductsComponents[index].component}
                </div>
            );
        },
        [farmsAndProductsComponents]
    );

    const rowHeight = useCallback(
        ({ index }) => {
            return getRowHeight(farmsAndProductsComponents[index]);
        },
        [farmsAndProductsComponents]
    );

    const scrollToFarm = useCallback(
        farmId => {
            const addRowHeight = (acc, component) => {
                return acc + getRowHeight(component);
            };
            const isNotFarm = (acc, component) => {
                return (
                    !R.propEq('type', FARM_ROW_TYPE)(component) ||
                    !R.propEq('farmId', farmId)(component)
                );
            };
            const heightToScroll = R.reduceWhile(
                isNotFarm,
                addRowHeight,
                0,
                farmsAndProductsComponents
            );

            animatedScrollTo(document.body, heightToScroll, 500);
            animatedScrollTo(document.documentElement, heightToScroll, 500);
        },
        [farmsAndProductsComponents]
    );
    useEffect(() => {
        ref.current = { scrollToFarm };
    }, [scrollToFarm, ref]);

    useEffect(() => {
        let scrollY = 0;
        farmsAndProductsComponents.forEach(component => {
            scrollY += getRowHeight(component);
            component.scrollY = scrollY;
        });
    }, [farmsAndProductsComponents]);

    useEffect(() => {
        listRef.current?.measureAllRows && listRef.current.measureAllRows();
        window.addEventListener('resize', updateDimensions);
        document.addEventListener('scroll', updateCurrentFarm);
        return () => {
            document.removeEventListener('scroll', updateCurrentFarm);
            window.removeEventListener('resize', updateDimensions);
        };
    }, [updateDimensions, updateCurrentFarm]);

    useEffect(() => {
        history.location?.state?.toFarm && scrollToFarm(history.location.state.toFarm);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (products.length === 0) {
        return <div />;
    }

    return (
        <WindowScroller>
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
                <List
                    style={{ overflow: 'visible' }}
                    className="pi-products-list"
                    autoHeight
                    height={height}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                    ref={listRef}
                    rowCount={farmsAndProductsComponents.length}
                    rowHeight={rowHeight}
                    rowRenderer={rowRenderer}
                    scrollToAlignment="center"
                    scrollTop={scrollTop}
                    width={screenConfig.containerWidth}
                    scrollToFarm={scrollToFarm}
                />
            )}
        </WindowScroller>
    );
});
ProductsList.propTypes = {
    addOfferToBasket: PropTypes.func.isRequired,
    assembly: PropTypes.object.isRequired,
    distribution: PropTypes.object.isRequired,
    farms: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    productTypes: PropTypes.object.isRequired,
    onClickOnProduct: PropTypes.func.isRequired,
    setCurrentFarm: PropTypes.func.isRequired,
};
export default ProductsList;
