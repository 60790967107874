import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Input from 'src/components/atoms/Input/Input.jsx';
import Label from 'src/components/atoms/Label/Label.jsx';

const Checkbox = forwardRef(({ id, className, error, children, ...props }, ref) => {
    const errorComponent = error && <div className="nd-input-error-msg">{error}</div>;

    return (
        <>
            <div className={classNames('nd-checkbox', className)}>
                <Input {...props} ref={ref} id={id} type="checkbox" />
                <Label htmlFor={id}>{children}</Label>
                {errorComponent}
            </div>
        </>
    );
});

Checkbox.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    error: PropTypes.string,
};

Checkbox.defaultProps = {
    id: null,
    className: '',
    children: null,
    error: '',
};

export default Checkbox;
