import PropTypes from 'prop-types';
import React from 'react';
import Utils from 'modules/utils';
const { linkTo } = Utils;
import classnames from 'classnames';
import Badge from 'components/Badge.jsx';
import PillButton from 'components/PillButton.jsx';
import Text from 'components/Text.jsx';

export class PageHeaderNavigationLink extends React.Component {
    static propTypes = {
        link: PropTypes.shape({
            label: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired,
            badgeValue: PropTypes.number,
        }).isRequired,
        isActive: PropTypes.bool,
    };

    render() {
        const { link, isActive } = this.props;

        const hasBadge = !!link.badgeValue;

        return (
            <PillButton href={linkTo(link.path)} isActive={isActive}>
                <Text className={classnames({ 'u-mr-2': hasBadge })}>{link.label}</Text>
                {hasBadge && <Badge>{link.badgeValue}</Badge>}
            </PillButton>
        );
    }
}

export default PageHeaderNavigationLink;
