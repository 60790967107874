import PropTypes from 'prop-types';
import React from 'react';
import { WHITE } from 'modules/utils/colors';
import classNames from 'classnames';
import * as R from 'ramda';
import PageHeaderNavigation from 'components/Navigation/PageHeaderNavigation.jsx';
import TinyTimeline from 'components/Navigation/TinyTimeline.jsx';
import Wrapper from 'components/Wrapper.jsx';
import BackButton from 'components/BackButton.jsx';

export class PageHeader extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        title: PropTypes.node.isRequired,
        size: PropTypes.oneOf(['large']),
        width: PropTypes.oneOf(['large', 'medium', 'small']),
        background: PropTypes.oneOf([
            'default',
            'ochre2',
            'ochre3',
            'turquoise2',
            'turquoise3',
            'lavander2',
            'lavander3',
            'mauve2',
            'mauve3',
            'apple2',
            'apple3',
            'green2',
            'green3',
        ]),
        overlap: PropTypes.oneOf(['small']),
        hideOnMobile: PropTypes.bool,
        backHref: PropTypes.string,
        backLink: PropTypes.string,
        backButton: PropTypes.node,
        alignLeft: PropTypes.bool,
        navigationLinks: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                path: PropTypes.string,
                slug: PropTypes.string,
            })
        ),
        navigationActiveSlug: PropTypes.string,
        steps: PropTypes.shape({
            stepLabels: PropTypes.array.isRequired,
            currentStepIndex: PropTypes.number.isRequired,
        }),
        backButtonIconOnly: PropTypes.bool,
    };

    static defaultProps = {
        alignLeft: false,
        background: null,
        backLink: null,
        backHref: null,
        overlap: null,
        hideOnMobile: false,
        width: 'large',
    };

    render() {
        const {
            alignLeft,
            background,
            overlap,
            title,
            size,
            width,
            backHref,
            backLink,
            hideOnMobile,
            children,
            navigationLinks,
            navigationActiveSlug,
            className,
            steps,
            backButtonIconOnly,
        } = this.props;

        const shouldAlignLeft = alignLeft || !!navigationLinks;

        const classes = {
            [`pageHeader--color-${this.props.background}`]: background,
            [`pageHeader--${overlap}Overlap`]: overlap,
            [`pageHeader--${size}`]: size,
            'pageHeader--left': shouldAlignLeft,
            'hidden-xs': hideOnMobile,
        };

        let backButtonRendering = null;

        if (backHref) {
            let href = backHref;
            if (backHref === 'back') {
                href = null;
            }

            if (backButtonIconOnly) {
                backButtonRendering = (
                    <BackButton
                        href={href}
                        className="pageHeader-backLink pageHeader-backLink--iconOnly"
                        iconOnly
                    />
                );
            } else {
                backButtonRendering = (
                    <BackButton href={href} className="pageHeader-backLink">
                        {backLink}
                    </BackButton>
                );
            }
        }

        let renderedNavigationLinks;
        if (this.props.navigationLinks) {
            renderedNavigationLinks = (
                <div className="pageHeader-actions">
                    <PageHeaderNavigation
                        activeSlug={navigationActiveSlug}
                        links={navigationLinks}
                    />
                </div>
            );
        }

        let renderedTinyTimeLine;
        if (R.path(['stepLabels'], this.props.steps)) {
            renderedTinyTimeLine = <TinyTimeline iconColor={WHITE} steps={steps} />;
        }

        return (
            <div className={classNames('pageHeader', classes, className)}>
                <Wrapper size={width} margin="none" xsPadding>
                    <div className="pageHeader-content">
                        {this.props.backButton}
                        {backButtonRendering}
                        <h1 className="pageHeader-title">{title}</h1>
                        {children && <div className="pageHeader-subtitle">{children}</div>}
                        {renderedNavigationLinks}
                        {renderedTinyTimeLine}
                    </div>
                </Wrapper>
            </div>
        );
    }
}

export default PageHeader;
