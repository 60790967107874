import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import RadioButton from 'components/RadioButton.jsx';

export class ButtonGroup extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        className: PropTypes.string,
        width: PropTypes.string,
        flex: PropTypes.bool,
        buttonClassName: PropTypes.string,
        // possibilities
        buttons: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                disabled: PropTypes.bool,
            })
        ).isRequired,
    };

    onChange = value => {
        this.props.onChange(this.props.name, value);
    };

    render() {
        const { name, value, buttons, className, flex, buttonClassName, width } = this.props;

        const classNames = classnames('buttonGroup', { 'buttonGroup--flex': flex }, className);

        return (
            <div className={classNames} style={{ width }}>
                {buttons.map(button => {
                    return (
                        <RadioButton
                            className={buttonClassName}
                            key={button.value}
                            value={button.value}
                            name={name}
                            inputId={`${name}-${button.value}`}
                            variant="button"
                            onChange={this.onChange}
                            isSelected={button.value === value}
                            disabled={button.disabled}
                        >
                            {button.label}
                        </RadioButton>
                    );
                })}
            </div>
        );
    }
}

export default ButtonGroup;
