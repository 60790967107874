import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { patternsToBreakpoints } from 'modules/utils/grid';

const renderChildFromBreakpoints = (breakpoints, ignoreEmptyChild = false) => (child, index) => {
    if (ignoreEmptyChild && child === null) {
        return null;
    }

    const classes = [];

    breakpoints.forEach(breakpoint => {
        const column = breakpoint.repeatedColumn || breakpoint.columns[index];
        if (column) {
            if (column.width) {
                classes.push(`col-${breakpoint.size}-${column.width}`);
            }
            if (column.offset) {
                classes.push(`col-${breakpoint.size}-offset-${column.offset}`);
            }
        }
    });

    return (
        <div className={classnames(classes)} key={index}>
            {child}
        </div>
    );
};

export class Grid extends React.Component {
    static propTypes = {
        lg: PropTypes.string,
        md: PropTypes.string,
        sm: PropTypes.string,
        xs: PropTypes.string,
        children: PropTypes.node,
        className: PropTypes.string,
        align: PropTypes.oneOf(['middle']),
        ignoreEmptyChild: PropTypes.bool,
    };

    render() {
        const { lg, md, sm, xs, children, className, align, ignoreEmptyChild } = this.props;

        const breakpoints = patternsToBreakpoints(lg, md, sm, xs);
        const renderChild = renderChildFromBreakpoints(breakpoints, ignoreEmptyChild);

        return (
            <div
                className={classnames('row', { 'row--alignMiddle': align === 'middle' }, className)}
            >
                {React.Children.map(children, renderChild)}
            </div>
        );
    }
}

export default Grid;
