/* eslint-disable react/prop-types */
import React, { lazy, Suspense } from 'react';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import AppChannel from 'modules/channels/App';

const UserRegistrationContainer = lazy(() =>
    import('components/UserRegistration/UserRegistrationContainer.jsx')
);

const handleOnSuccess = (email, password, assemblyId) => {
    if (assemblyId) {
        AppChannel.vent.trigger('assembly:join:submit', assemblyId);
        AppChannel.vent.trigger('analytics:assembly:join', assemblyId);
    }
    AppChannel.vent.trigger('register');
    AppChannel.commands.execute('login', {
        username: email,
        password,
        // auto remember the user when registering
        remember: true,
        hasRegistered: true,
    });
};

export const unauthenticatedRoutes = [
    {
        definition: '/join/as-producer',
        conditions: ['isNotAuthenticated'],
        exact: true,
        render: () => {
            return (
                <Suspense fallback={<LoadingAnimation type="spinner" />}>
                    <UserRegistrationContainer role="farm" onSuccess={handleOnSuccess} />
                </Suspense>
            );
        },
    },
    {
        definition: '/join/as-host',
        conditions: ['isNotAuthenticated'],
        exact: true,
        render: () => {
            return (
                <Suspense fallback={<LoadingAnimation type="spinner" />}>
                    <UserRegistrationContainer role="host" onSuccess={handleOnSuccess} />
                </Suspense>
            );
        },
    },
];
