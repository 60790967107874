import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Grid } from 'components/ui';
import useI18n from 'hooks/I18n/useI18n.js';

const ConfirmModalContent = ({
    closeModal,
    children,
    cancelLabel,
    acceptAction,
    isSuccessAction,
    acceptLabel,
}) => {
    const { trans } = useI18n();
    return (
        <div>
            <div className="u-mb-4">{children}</div>
            <Grid md="6|6">
                <Button block onClick={closeModal} weight="link">
                    {cancelLabel ? cancelLabel : trans('global.cancel')}
                </Button>
                <Button
                    block
                    onClick={e => {
                        if (acceptAction) {
                            acceptAction(e);
                        }
                    }}
                    variant={isSuccessAction ? 'success' : 'danger'}
                >
                    {acceptLabel ? acceptLabel : trans('global.accept')}
                </Button>
            </Grid>
        </div>
    );
};

ConfirmModalContent.propTypes = {
    isSuccessAction: PropTypes.bool,
    children: PropTypes.node,
    closeModal: PropTypes.func,
    acceptAction: PropTypes.func,
    cancelLabel: PropTypes.string,
    acceptLabel: PropTypes.string,
};

const ConfirmModal = props => {
    return (
        <Modal
            isBlocking
            isOpened={props.isOpened}
            closeModal={props.cancelAction}
            title={props.title}
            size={props.size}
        >
            <ConfirmModalContent {...props}>{props.children}</ConfirmModalContent>
        </Modal>
    );
};

ConfirmModal.propTypes = {
    title: PropTypes.node,
    isSuccessAction: PropTypes.bool,
    isOpened: PropTypes.bool.isRequired,
    children: PropTypes.node,
    cancelAction: PropTypes.func.isRequired,
    acceptAction: PropTypes.func,
    cancelLabel: PropTypes.string,
    acceptLabel: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default ConfirmModal;
