import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import { debounce, isEmpty } from 'underscore';

import useI18n from 'hooks/I18n/useI18n';
import { reactFormValidators } from 'modules/validation';
import { getCountriesForSelect } from 'modules/countries/selectors';
import { validateOrder, revalidateOrder } from 'modules/orders';
import { fetchCountriesIfNeeded } from 'modules/countries';
import { userSelector } from 'modules/currentUser';

import { updateUserKyc } from 'api/users';
import { fetchCities } from 'api/cities';

import LayoutCheckoutPayment from 'components/Checkout/Anonymous/LayoutCheckoutPayment.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import { STEP_INFORMATIONS_NEW_ACCOUNT } from 'components/Checkout/ProductIdentity/CheckoutTimeline.jsx';

import Input, { ERROR_MODE } from 'src/components/atoms/Input/Input.jsx';
import Label from 'src/components/atoms/Label/Label.jsx';
import Link from 'src/components/atoms/Link/Link.jsx';
import Select from 'src/components/atoms/Select/Select.jsx';
import Checkbox from 'src/components/molecules/Checkbox/Checkbox.jsx';
import Button, { SMALL_SIZE } from 'src/components/atoms/Button/Button.jsx';
import PhoneInput from 'src/components/atoms/PhoneInput/PhoneInput.jsx';

const PaymentProfileForm = ({ orderId, shouldRevalidate, hasDeliveryOptions }) => {
    const methods = useForm();
    const { trans, i18n } = useI18n();
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const [cities, setCities] = useState([]);

    const {
        register,
        resetField,
        setValue,
        setError,
        formState: { errors },
        handleSubmit,
    } = methods;
    const { assertNotBlank, assertChecked, assertValidBirthdate, assertValidPhone } = useMemo(
        () => reactFormValidators(trans),
        [trans]
    );
    const countries = useSelector(getCountriesForSelect('code'));

    const fetchcities = useCallback(
        zip => {
            // don't search if one is "empty"
            const countryId = user.address.country.id;
            if (!countryId || zip === '') {
                cities.length > 0 && setCities([]);
                return;
            }
            fetchCities({ zipCode: zip, country: countryId }).then(data =>
                setCities(
                    data.cities.map(city => ({
                        label: city.name,
                        value: city.id.toString(),
                    }))
                )
            );
        },
        [cities, user]
    );
    const refreshCitySelect = debounce(fetchcities, 300);

    const phoneProps = {
        error: R.path(['phone', 'message'], errors),
        ...register('phone', {
            validate: assertValidPhone('phone'),
            value: user.phone || '',
        }),
    };
    const nationalityProps = {
        mode: errors.nationality && ERROR_MODE,
        error: R.path(['nationality', 'message'], errors),
        ...register('nationality', {
            validate: assertNotBlank('nationality'),
            value: user.nationality || user.address.country.code,
        }),
    };
    const zipCodeProps = register('zipCode', {
        onChange: e => {
            resetField('city');
            resetField('cityId');
            refreshCitySelect(e.target.value);
        },
        validate: assertNotBlank('zipCode'),
        value: user.address.city.zipCode,
    });
    const cityProps = {
        mode: (errors.city || errors.address?.city) && ERROR_MODE,
        error: R.path(['city', 'message'], errors),
        ...register('city', { validate: assertNotBlank('city'), value: user.address.city.id }),
    };
    const streetProps = {
        mode: (errors.street || errors.address?.street) && ERROR_MODE,
        error: R.path(['street', 'message'], errors),
        ...register('street', { validate: assertNotBlank('street') }),
    };
    const birthdayDateProps = {
        mode: errors.birthday && ERROR_MODE,
        error: R.path(['birthday', 'message'], errors),
        ...register('birthday', {
            validate: assertValidBirthdate('birthday'),
            value: user.birthday || '',
        }),
    };
    const acceptedTOSProps = {
        error: R.path(['hasAcceptedTOS', 'message'], errors),
        ...register('hasAcceptedTOS', { validate: assertChecked('hasAcceptedTOS') }),
    };

    const onSubmit = data => {
        const dataToSend = {
            phone: data.phone,
            address: { street: data.street, city: data.cityId },
            birthday: data.birthday,
            nationality: data.nationality,
            hasAcceptedTOS: data.hasAcceptedTOS,
        };

        return updateUserKyc(user.id, dataToSend)
            .then(() => {
                if (shouldRevalidate) {
                    dispatch(revalidateOrder(orderId));
                } else {
                    dispatch(validateOrder(orderId, hasDeliveryOptions));
                }
            })
            .catch(message => {
                if (message?.responseJSON?.detail?.errors) {
                    for (const [key, value] of Object.entries(
                        message?.responseJSON?.detail?.errors
                    )) {
                        setError(key, {
                            type: 'error',
                            message: value,
                        });
                    }
                }
            });
    };

    useEffect(() => {
        const zipCode = R.path(['address', 'city', 'zipCode'], user);
        dispatch(fetchCountriesIfNeeded());
        zipCode && fetchcities(zipCode);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const citiesIsEmpty = isEmpty(cities);
        setValue('city', !citiesIsEmpty ? cities[0].label : '');
        setValue('cityId', !citiesIsEmpty ? cities[0].value : '');
    }, [cities, setValue]);

    const currentI18nCountry = i18n.slice(-2).toLowerCase();

    return (
        <LayoutCheckoutPayment
            orderId={orderId}
            showSummary={false}
            currentStep={STEP_INFORMATIONS_NEW_ACCOUNT}
        >
            <FormProvider {...methods}>
                <form
                    className="nd-checkout-payment-informations-form"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Text
                        className="nd-checkout-create-account-form-heading"
                        size="18px"
                        lineHeight="26px"
                        color="gray2"
                        weight={700}
                        family="mr"
                    >
                        {trans('checkout.account.payment_information.title')}
                    </Text>
                    <div className="nd-checkout-create-account-form-legend">
                        <Text
                            size="16px"
                            lineHeight="24px"
                            color="gray2"
                            weight={400}
                            dangerouslySetInnerHTML={{
                                __html: trans('checkout.account.payment_information.legend'),
                            }}
                        />
                        <Link to="/support/kyc-information" target="_blank" data-bypass>
                            {trans('global.knowMore')}
                        </Link>
                    </div>
                    <div className="nd-checkout-create-account-form-section">
                        <Label htmlFor="phone">{trans('address.phoneNumber')}*</Label>
                        <PhoneInput
                            {...phoneProps}
                            onChange={value => setValue('phone', value)}
                            defaultCountry={currentI18nCountry}
                            id="phone"
                            fullWidth
                        />
                    </div>
                    <div className="nd-checkout-create-account-form-section">
                        <Label htmlFor="street">{trans('address.numberWay')}*</Label>
                        <Input {...streetProps} id="street" />
                    </div>
                    <div className="nd-checkout-create-account-form-section">
                        <Label htmlFor="zipCode">{trans('address.zip')}*</Label>
                        <Input {...zipCodeProps} id="zipCode" />
                    </div>
                    <div className="nd-checkout-create-account-form-section">
                        <Label htmlFor="city">{trans('address.city')}*</Label>
                        <Input {...cityProps} id="city" readOnly disabled />
                    </div>
                    <div className="nd-checkout-create-account-form-section">
                        <Label htmlFor="birthday">{trans('global.dateOfBirth')}*</Label>
                        <Input {...birthdayDateProps} id="birthday" type="date" />
                    </div>
                    <div className="nd-checkout-create-account-form-section">
                        <Label htmlFor="countries">{trans('global.nationality')}*</Label>
                        <Select
                            {...nationalityProps}
                            placeholder={trans('address.countryChoose')}
                            id="nationality"
                            options={countries}
                            defaultValue=""
                        />
                    </div>
                    <div className="nd-checkout-create-account-form-section">
                        <Checkbox {...acceptedTOSProps} id="hasAcceptedTOS">
                            <div
                                className="nd-text nd-link"
                                dangerouslySetInnerHTML={{
                                    __html: trans('user.tos', { '%url%': '/p/terms' }),
                                }}
                            ></div>
                        </Checkbox>
                    </div>
                    <Button size={SMALL_SIZE} type="submit">
                        {trans('global.continue')}
                    </Button>
                </form>
            </FormProvider>
        </LayoutCheckoutPayment>
    );
};

PaymentProfileForm.propTypes = {
    orderId: PropTypes.number.isRequired,
    shouldRevalidate: PropTypes.bool.isRequired,
    hasDeliveryOptions: PropTypes.bool.isRequired,
};

export default PaymentProfileForm;
