import PropTypes from 'prop-types';
import React from 'react';
import { propType as colorPropType } from 'modules/utils/colors';
import OverlayTrigger from 'components/OverlayTrigger.jsx';
import Tooltip from 'components/Tooltip.jsx';
import Icon from 'components/Icon.jsx';
import classnames from 'classnames';

const propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    color: colorPropType,
    space: PropTypes.oneOf(['small', 'medium']),
    size: PropTypes.oneOf([
        'xxxx-large',
        'xxx-large',
        'xx-large',
        'x-large',
        'large',
        'medium',
        'small',
    ]),
};

const defaultProps = {
    space: 'small',
    size: 'medium',
};

export const IconTooltip = props => (
    <OverlayTrigger overlay={<Tooltip>{props.children}</Tooltip>}>
        <span className={classnames(props.className, 'u-mr-2', `iconTooltip--${props.space}`)}>
            <Icon src={props.icon} color={props.color} size={props.size} />
        </span>
    </OverlayTrigger>
);

IconTooltip.propTypes = propTypes;
IconTooltip.defaultProps = defaultProps;

export default IconTooltip;
