import PropTypes from 'prop-types';
import React from 'react';
import closeIcon from 'icons/close-thin.svg';
import angleLeftIcon from 'icons/angle-left.svg';
import Icon from 'components/Icon.jsx';
import classnames from 'classnames';
import connectToI18n from 'modules/i18n/connectToI18n';

export class CloseButton extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        onClick: PropTypes.func.isRequired,
        trans: PropTypes.func.isRequired,
        size: PropTypes.oneOf([
            'xxxx-large',
            'xxx-large',
            'xx-large',
            'x-large',
            'large',
            'medium',
            'small',
        ]),
        icon: PropTypes.oneOf(['close', 'angleLeft']),
    };

    static defaultProps = {
        size: 'xx-large',
        icon: 'close',
    };

    render() {
        const { className, trans, size, icon } = this.props;

        return (
            <button
                aria-label={trans('global.close')}
                onClick={this.props.onClick}
                className={classnames('closeButton', className)}
            >
                <Icon src={icon === 'close' ? closeIcon : angleLeftIcon} size={size} />
            </button>
        );
    }
}

export default connectToI18n(CloseButton);
