import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InlineSVG from 'svg-inline-react';

import useI18n from 'hooks/I18n/useI18n';

import Link from 'src/components/atoms/Link/Link.jsx';
import Text from 'components/ProductIdentity/Text.jsx';

import Caret from 'app/assets/new-design/images/icons/caret.svg';
import AssemblyIcon from 'app/assets/images/icons/icon-assembly.svg';

const HiveCard = ({ assembly }) => {
    const { trans, i18n } = useI18n();
    return (
        <Link
            key={assembly.id}
            bypassRouter
            href={`/${i18n}/assemblies/${assembly.id}`}
            className={`hives-panel-hive`}
        >
            <div className={classNames('hives-panel-hive-logo', assembly.theme.name)}>
                <InlineSVG src={AssemblyIcon} />
            </div>
            <div className="hives-panel-hive-content">
                <div className="hives-panel-hive-content-title">
                    <Text size="16px" lineHeight="20px" weight={600} color="gray2">
                        {assembly.place.address.city.name}
                    </Text>
                    {assembly?._links?.currentSale && (
                        <Text
                            size="14px"
                            lineHeight="20px"
                            color="green6"
                            className="hives-panel-hive-content-status"
                        >
                            {trans('hive.openSale')}
                        </Text>
                    )}
                </div>
                <Text
                    className="hives-panel-hive-content-name"
                    size="12px"
                    lineHeight="20px"
                    color="gray2"
                    weight={400}
                >
                    {assembly.name}
                </Text>
            </div>
            <InlineSVG raw className="hives-panel-hive-caret" src={Caret} />
        </Link>
    );
};

HiveCard.propTypes = {
    assembly: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        theme: PropTypes.shape({ name: PropTypes.string }),
        _links: PropTypes.shape({ currentSale: PropTypes.object }),
        place: PropTypes.shape({
            address: PropTypes.shape({ city: PropTypes.shape({ name: PropTypes.string }) }),
        }),
    }),
};

export default HiveCard;
