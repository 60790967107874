import geodist from 'geodist';
import * as R from 'ramda';

const MAX_METERS_LIST_VIEW = 8000;

function getTimeSlots(assembly) {
    const timeslots = [];

    if (assembly.deliveryTimeSlot) {
        timeslots.push(assembly.deliveryTimeSlot);
    }

    if (assembly.extraDeliveryTimeSlot) {
        timeslots.push(assembly.extraDeliveryTimeSlot);
    }

    return timeslots;
}

export function timeslotsSortedByDayOfWeek(assembly) {
    // This method works only when Sunday is the last day of the week.
    // It wouldn't work with Date.prototype.getDay() for example.
    const timeslots = getTimeSlots(assembly);
    return timeslots.sort((a, b) => a.day - b.day);
}

const socialNetworksFormatting = {
    blog: url => ({
        name: 'Blog',
        url,
        icon: 'icon-edit',
    }),
    facebook: url => ({
        name: 'Facebook',
        url,
        icon: 'icon-facebook',
    }),
    instagram: handle => ({
        name: 'Instagram',
        url: `https://instagram.com/${handle}`,
        icon: 'icon-camera-retro',
    }),
    twitter: handle => ({
        name: 'Twitter',
        url: `https://twitter.com/${handle}`,
        icon: 'icon-twitter',
    }),
};

const productIdentitySocialNetworksFormatting = {
    ...socialNetworksFormatting,
    whatsapp: phone => ({
        name: 'Whatsapp',
        url: `https://wa.me/${phone}`,
        icon: 'icon-whatsapp',
    }),
};

export function getSocialNetworks(assembly, productIdentity = false) {
    const socialNetworksFormatter = productIdentity
        ? productIdentitySocialNetworksFormatting
        : socialNetworksFormatting;

    return R.pipe(
        R.pick(R.keys(socialNetworksFormatter)),
        R.pickBy(R.complement(R.isNil)),
        R.evolve(socialNetworksFormatter),
        R.values()
    )(assembly);
}

export const hasExtraDeliveryTimeSlot = assembly =>
    R.has('extraDeliveryTimeSlot', assembly) &&
    !R.isEmpty(R.prop('extraDeliveryTimeSlot', assembly)) &&
    !R.isNil(R.prop('extraDeliveryTimeSlot', assembly));

export const getCurrentDistributions = assembly => {
    if (!assembly) {
        return [];
    }
    const currentSaleLink = assembly._links.currentSale;
    if (!currentSaleLink) {
        return [];
    }
    if (currentSaleLink.distributionId) {
        return [currentSaleLink];
    }
    return R.sortBy(distribution => new Date(distribution.distributionDate), currentSaleLink);
};

export const getCurrentDistribution = assembly => {
    if (!assembly) {
        return false;
    }
    const distributions = getCurrentDistributions(assembly);
    if (!distributions.length) {
        return false;
    }
    return distributions[0];
};

export const filterMaxRange = lookupCoords => assembly =>
    lookupCoords &&
    lookupCoords.latitude &&
    lookupCoords.longitude &&
    geodist(
        { lat: lookupCoords.latitude, long: lookupCoords.longitude },
        assembly.place.address.coordinates,
        { unit: 'meters' }
    ) < MAX_METERS_LIST_VIEW;

export const filterWithHomeDelivery = assembly =>
    R.prop('hasHomeDelivery', assembly) ||
    R.any(R.prop('hasHomeDelivery'))(R.propOr([], 'openedDistributions', assembly));
