import PropTypes from 'prop-types';

const MEMBER = 'member';
const USER = 'user';
const FARMER = 'farmer';
const HOST = 'host';

const propType = PropTypes.oneOf([MEMBER, USER, FARMER, HOST]).isRequired;

export { propType, MEMBER, USER, FARMER, HOST };
