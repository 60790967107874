import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import angleRightIcon from 'icons/angle-right.svg';
import Text from 'components/Text.jsx';

const TinyTimeline = ({ className, iconColor, steps }) => {
    return (
        <ul className={classnames('tinyTimeline', className)}>
            {steps.stepLabels.map((label, index) => (
                <li
                    key={label}
                    className={classnames('tinyTimeline-item', {
                        'is-current': index === steps.currentStepIndex,
                    })}
                >
                    {index === 0 ? (
                        <Text className="tinyTimeline-text">{label}</Text>
                    ) : (
                        <Text
                            className="tinyTimeline-text"
                            icon={angleRightIcon}
                            iconColor={iconColor}
                            iconSize="small"
                        >
                            {label}
                        </Text>
                    )}
                </li>
            ))}
        </ul>
    );
};

TinyTimeline.propTypes = {
    className: PropTypes.string,
    iconColor: PropTypes.string,
    steps: PropTypes.shape({
        stepLabels: PropTypes.array.isRequired,
        currentStepIndex: PropTypes.number.isRequired,
    }),
};

export default TinyTimeline;
