import PropTypes from 'prop-types';
import React from 'react';
import { Portal } from 'react-portal';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import * as Backdrop from 'modules/utils/backdrop';
import CloseButton from 'components/CloseButton.jsx';
import Heading from 'components/Heading.jsx';

const KEYCODES = {
    ESCAPE: 27,
};

function emptyFunction() {}

/**
 * This component is and uses the "stateless" style of the Portal library
 * Which means the closeModal prop MUST switch isOpened to false
 */
export class Modal extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        title: PropTypes.node,
        className: PropTypes.string,
        closeModal: PropTypes.func.isRequired,
        transparentBackdrop: PropTypes.bool,
        isBlocking: PropTypes.bool,
        isOpened: PropTypes.bool.isRequired,
        size: PropTypes.oneOf(['small', 'medium', 'large', 'x-large']),
        noPadding: PropTypes.bool,
        noMargin: PropTypes.bool,
        footer: PropTypes.element,
    };

    static defaultProps = {
        transparentBackdrop: false,
        size: 'medium',
    };

    componentDidMount() {
        if (this.props.isOpened) {
            Backdrop.show();
        }
        if (!this.props.isBlocking) {
            document.addEventListener('keydown', this.handleKeydown);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpened === prevProps.isOpened) {
            return;
        }
        if (this.props.isOpened) {
            Backdrop.show();
        } else {
            Backdrop.hide();
        }
    }

    componentWillUnmount() {
        Backdrop.hide();
        if (!this.props.isBlocking) {
            document.removeEventListener('keydown', this.handleKeydown);
        }
    }

    onClickInside = e => {
        e.stopPropagation();
    };

    handleKeydown = e => {
        if (e.keyCode === KEYCODES.ESCAPE && this.props.isOpened) {
            this.props.closeModal();
        }
    };

    closeModal = e => {
        e.stopPropagation();
        this.props.closeModal(e);
    };

    render() {
        const isDismissible = !this.props.isBlocking;
        const title = this.props.title;
        const a11yProps = {
            role: 'dialog',
            ['aria-modal']: true,
        };
        if (title) {
            a11yProps['aria-labelledby'] = 'modal-title';
        }

        return (
            (this.props.isOpened && (
                <Portal>
                    <div
                        onClick={isDismissible ? this.closeModal : emptyFunction}
                        className={classnames('modal', 'show', {
                            'modal--withBackdrop': !this.props.transparentBackdrop,
                        })}
                    >
                        <FocusTrap
                            focusTrapOptions={{
                                clickOutsideDeactivates: isDismissible,
                                fallbackFocus: '.modal-container',
                            }}
                        >
                            <div
                                className={classnames(
                                    'modal-container',
                                    {
                                        'modal-lg': this.props.size === 'large',
                                        'modal-sm': this.props.size === 'small',
                                        'modal-xl': this.props.size === 'x-large',
                                        'modal-dialog--noMargin': this.props.noMargin,
                                    },
                                    this.props.className
                                )}
                            >
                                {isDismissible && <CloseButton onClick={this.closeModal} />}

                                <div {...a11yProps} className={classnames('modal-dialog')}>
                                    <div
                                        onClick={this.onClickInside}
                                        className={classnames('modal-content', {
                                            'modal-content--noPadding': this.props.noPadding,
                                        })}
                                    >
                                        {title && (
                                            <Heading
                                                size={2}
                                                rank={1}
                                                className="u-mb-4"
                                                id="modal-title"
                                            >
                                                {title}
                                            </Heading>
                                        )}
                                        {React.cloneElement(this.props.children, {
                                            closeModal: this.closeModal,
                                        })}
                                    </div>
                                </div>
                                {this.props.footer && (
                                    <div onClick={this.onClickInside} className="modal-footer">
                                        {this.props.footer}
                                    </div>
                                )}
                            </div>
                        </FocusTrap>
                    </div>
                </Portal>
            )) ||
            null
        );
    }
}

export default Modal;
