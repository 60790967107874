import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from 'components/Icon.jsx';
import HelpPanel from 'components/Navigation/ProductIdentity/HelpPanel.jsx';
import helpCircleIcon from 'app/assets/new-design/images/icons/help.svg';

const HelpButton = props => {
    const { currentAssembly, farm, currentUser, openHelpMenu, open } = props;
    return (
        <>
            <button
                className={classnames(
                    'simpleMemberTopRightMenu-panel simpleMemberTopNavigation-button help-button',

                    'without-end-border'
                )}
                onClick={openHelpMenu}
            >
                <Icon src={helpCircleIcon} color="white" size="xx-large" />
            </button>

            {open && (
                <HelpPanel
                    user={currentUser}
                    currentAssembly={currentAssembly || null}
                    farm={farm}
                />
            )}
        </>
    );
};
HelpButton.propTypes = {
    currentAssembly: PropTypes.object,
    farm: PropTypes.object,
    currentUser: PropTypes.object,
    openHelpMenu: PropTypes.func,
    open: PropTypes.func,
};
export default HelpButton;
