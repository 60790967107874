import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import PIText from 'components/ProductIdentity/Text.jsx';

export const THEME_SELECTED_GREEN = 'selectedGreen';
export const THEME_SELECTED_GREY = 'selectedGrey';

const Switch = ({ onClick, className, checked, label, disabled, theme, onChange }) => {
    return (
        <div className={classNames('nd-switch-container', className, { [theme]: theme })}>
            <PIText color="grey8" size="14px" lineHeight="20px">
                {label}
            </PIText>
            <label className="nd-switch">
                <input
                    onChange={onChange}
                    onClick={!disabled && onClick}
                    type="checkbox"
                    checked={checked}
                    readOnly
                    disabled={disabled}
                />
                <span className="nd-slider round"></span>
            </label>
        </div>
    );
};
Switch.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    checked: PropTypes.bool,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    theme: PropTypes.string,
    onChange: PropTypes.func,
};
Switch.defaultProps = {
    disabled: false,
    theme: THEME_SELECTED_GREEN,
    onChange: () => null,
};
export default Switch;
