import PropTypes from 'prop-types';
import React from 'react';
import Utils from 'modules/utils';
const { linkTo } = Utils;
import connectToI18n from 'modules/i18n/connectToI18n';
import Heading from 'components/Heading.jsx';
import Paragraph from 'components/Paragraph.jsx';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import Split from 'miniruche/ui/layout/Split.jsx';
import ProducerCard from 'components/ProducerCard.jsx';
import { INITIAL_LOADING } from 'modules/utils/ajaxStatuses';

export class FeedbackSuggestions extends React.Component {
    static propTypes = {
        feedbackSuggestions: PropTypes.array.isRequired,
        assemblyId: PropTypes.number.isRequired,
        trans: PropTypes.func.isRequired,
        title: PropTypes.string,
        status: PropTypes.string,
    };

    render() {
        const { trans, feedbackSuggestions, assemblyId, title, status } = this.props;

        return (
            <div className="js-feedbackOnProducer-suggestions">
                <Heading size={2} className="u-mb-4 feedbackSuggestions-text">
                    {title ? title : trans('farm.feedback.suggestions.push.title')}
                </Heading>
                <Paragraph className="u-mb-2 feedbackSuggestions-text">
                    {trans('farm.feedback.suggestions.push.text')}
                </Paragraph>
                {status === INITIAL_LOADING ? (
                    <LoadingAnimation type="spinner" />
                ) : (
                    <Split className="userCard-sideContainer" gutter="medium">
                        {feedbackSuggestions.map(farmWithoutFeedback => (
                            <ProducerCard
                                className="userCard--side feedbackSuggestions-card"
                                key={farmWithoutFeedback.id}
                                farm={farmWithoutFeedback}
                                href={linkTo(
                                    `assemblies/${assemblyId}/producers/${farmWithoutFeedback.id}/feedback`
                                )}
                                actionTitle={trans('farm.feedback.suggestions.action')}
                            />
                        ))}
                    </Split>
                )}
            </div>
        );
    }
}

export default connectToI18n(FeedbackSuggestions);
