// Inspired from https://github.com/kriskowal/q/wiki/Coming-from-jQuery#converting-jquery-promises-to-q
// AND
// https://github.com/visionmedia/superagent/blob/2429a1eabdf35e2eb8b142b35e9c5bbb2cb697dd/lib/client.js#L327
// we can easily read the body and migrate to superagent later because fuck jquery
function jqueryToPromise(jqueryRequest) {
    return new Promise((resolve, reject) => {
        jqueryRequest.then(
            (data, textStatus, jqXHR) => {
                delete jqXHR.then;
                jqXHR.body = jqXHR.responseJSON || jqXHR.responseText;
                resolve(jqXHR);
            },
            (jqXHR) /* , statusText, errorThrown */ => {
                delete jqXHR.then;
                jqXHR.body = jqXHR.responseJSON || jqXHR.responseText;
                reject(jqXHR);
            }
        );
    });
}

export { jqueryToPromise as default, jqueryToPromise };
