import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import geodist from 'geodist';
import classnames from 'classnames';

import { hasAvailableStock } from 'modules/stockUtils.js';
import {
    LOADING,
    LOADING_MORE,
    SAVED,
    propType as statusPropType,
} from 'modules/utils/ajaxStatuses';
import {
    POPULAR_PRODUCTS_BY_MEMBER,
    POPULAR_PRODUCTS_BY_ASSEMBLY,
    PRODUCTS_SELECTION,
    NEW_ASSEMBLY_PRODUCTS,
    GOOD_DEALS_ON_HIGHLIGHT,
} from 'modules/originUtils.js';
import {
    ALREADY_BOUGHT_CATEGORY,
    GOOD_DEAL_CATEGORY,
    filterProducts,
} from 'modules/utils/saleCategoriesAndFilters';

import useAnalytics from 'hooks/Analytics/useAnalytics.js';
import useI18n from 'hooks/I18n/useI18n.js';

import { getProductCategoriesForSalePage } from 'models/productTypes';

import { Heading, Button } from 'components/ui';
import { sortProductsByPopularityDesc } from 'components/Sale/sortUtils.js';
import PIProductCard, { MODE_LARGE } from 'components/Sale/ProductIdentity/ProductCard.jsx';
import PIInterSaleProductPushContainer from 'components/Sale/ProductIdentity/InterSaleProductPushContainer.jsx';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import HiveProductsHeader from 'components/Sale/ProductIdentity/HiveProductsHeader.jsx';
import PIProductsCarousel from 'components/Sale/ProductIdentity/ProductsCarousel.jsx';
import AverageDistanceTraveled from 'components/Sale/ProductIdentity/AverageDistanceTraveled/AverageDistanceTraveled.jsx';
import PIKeepInTouch from 'components/Sale/ProductIdentity/KeepInTouch.jsx';

const mergeProductsArraysIf = (products, productsToMerge, condition = true) => {
    if (!condition) {
        return products;
    }

    return [...products, ...productsToMerge];
};

export const HighlightedProducts = ({
    addOfferToBasket,
    isSmallWidth,
    productsByFarm,
    status,
    assembly,
    distributionId,
    loadMoreProducts,
    hasNextPage,
    saleOpenMessage,
    productTypes,
    allProducts,
    interSaleProductPush,
    farms,
    goToOtherSaleProduct,
    goToSameSaleProduct,
    isMemberOfAssembly,
    userId,
    joinAssembly,
    joinAssemblyStatus,
    withTracking = false,
}) => {
    const { trans, i18n } = useI18n();
    const { analytics } = useAnalytics();

    const moveProductsWithoutPhotoAtTheEnd = products =>
        R.flatten(R.partition(R.prop('photoId'), products));

    const getAverageProductDistance = (assemblyData, farmsData, products) => {
        const farmDistances = R.map(farm =>
            geodist(
                {
                    lat: farm.latitude,
                    long: farm.longitude,
                },
                assemblyData.place.address.coordinates,
                {
                    unit: 'kilometers',
                }
            )
        )(farmsData);

        const getFarmId = product => {
            const sourceFarmUuid = R.path(['sourceFarm', 'uuid'], product);
            return sourceFarmUuid !== null ? sourceFarmUuid : R.prop('farmId', product);
        };

        return R.pipe(R.map(getFarmId), R.map(R.prop(R.__, farmDistances)), R.mean)(products);
    };

    const highlightedProducts = R.flatten(R.pluck('products', productsByFarm)).sort(function(a, b) {
        const aAvailableStock = a.offers.reduce(
            (partialSum, offer) => partialSum + offer.availableStock,
            0
        );
        const bAvailableStock = b.offers.reduce(
            (partialSum, offer) => partialSum + offer.availableStock,
            0
        );

        return (
            (aAvailableStock === 0 ? 999 : 0) +
            a.sortingPriority -
            ((bAvailableStock === 0 ? 999 : 0) + b.sortingPriority)
        );
    });

    const productTypeCategories = getProductCategoriesForSalePage(productTypes, i18n, {
        hasHighlightedProducts: false,
        hasAlreadyBoughtProducts: false,
        hasAllProducts: false,
        hasGoodDealProducts: false,
    });
    const availableProducts = R.filter(hasAvailableStock, allProducts);

    const assemblyPopularProducts = R.flatten(
        productTypeCategories.map(category => {
            return R.pipe(
                R.filter(R.propEq('root_type_id', category.id)),
                sortProductsByPopularityDesc('assemblyPopularity'),
                R.take(3)
            )(availableProducts);
        })
    );

    const alreadyBoughtProducts = R.pipe(
        filterProducts(ALREADY_BOUGHT_CATEGORY, null, null),
        sortProductsByPopularityDesc('alreadyBoughtCount'),
        R.take(8)
    )(availableProducts);

    const newAssemblyProducts = R.pipe(
        R.filter(R.propEq('isNewInAssembly', true)),
        R.take(30),
        moveProductsWithoutPhotoAtTheEnd
    )(availableProducts);

    const goodDealProducts = R.pipe(
        filterProducts(GOOD_DEAL_CATEGORY, null, null),
        R.take(30)
    )(availableProducts);

    useEffect(() => {
        if (withTracking) {
            analytics.trackAssemblyVisit(assembly);

            let items = highlightedProducts;
            items = mergeProductsArraysIf(
                items,
                assemblyPopularProducts,
                assemblyPopularProducts.length >= 5
            );
            items = mergeProductsArraysIf(items, goodDealProducts, goodDealProducts.length >= 5);
            items = mergeProductsArraysIf(
                items,
                alreadyBoughtProducts,
                alreadyBoughtProducts.length >= 5
            );
            items = mergeProductsArraysIf(
                items,
                newAssemblyProducts,
                newAssemblyProducts.length >= 5
            );
            items = R.uniq(items);

            analytics.trackViewItemList('selection', 'Selection', assembly, items);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (status === LOADING) {
        return <LoadingAnimation type="pi-spinner" />;
    }

    return (
        <div
            className={classnames(
                'col-md-12',
                'productsListContainer',
                'productsListContainer--newNavigation',
                { 'is-loading': status === LOADING }
            )}
        >
            {alreadyBoughtProducts.length >= 5 && (
                <div className="highlightedProducts-alreadyBoughtProducts pi-highlighted-products-section">
                    <Heading
                        productIdentity
                        size={3}
                        rank={2}
                        className="highlightedProducts-title"
                    >
                        {trans('salePage.yourLastPurchases')}
                    </Heading>
                    <div className="alreadyBoughtProducts-cards">
                        {alreadyBoughtProducts.map(product => {
                            return (
                                <PIProductCard
                                    addOfferToBasket={addOfferToBasket}
                                    distributionId={distributionId}
                                    product={product}
                                    key={product.id}
                                    actionOrigin={POPULAR_PRODUCTS_BY_MEMBER}
                                    onClick={() => goToSameSaleProduct(product.id)}
                                />
                            );
                        })}
                    </div>
                </div>
            )}
            <div className="pi-highlighted-products-section">
                <HiveProductsHeader
                    className="col-md-10"
                    saleOpenMessage={saleOpenMessage}
                    isSmallWidth={isSmallWidth}
                    assembly={assembly}
                />
            </div>
            {highlightedProducts.length > 0 && (
                <>
                    <div className="pi-highlighted-products-section">
                        <Heading size={2} className="highlightedProducts-title" productIdentity>
                            {trans('products.weeklySelection')}
                        </Heading>
                    </div>
                    <div className="pi-highlighted-products-section">
                        <div className={classnames('highlightedProducts-productsSelection')}>
                            {highlightedProducts.map(product => {
                                return (
                                    <PIProductCard
                                        mode={MODE_LARGE}
                                        key={product.id}
                                        addOfferToBasket={addOfferToBasket}
                                        distributionId={distributionId}
                                        product={product}
                                        actionOrigin={PRODUCTS_SELECTION}
                                        onClick={() => goToSameSaleProduct(product.id)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </>
            )}
            {goodDealProducts.length >= 5 && (
                <div className="pi-highlighted-products-section">
                    <Heading size={2} className="highlightedProducts-title" productIdentity>
                        {trans('theGoodDeals')}
                    </Heading>
                    <PIProductsCarousel
                        isSmallWidth={isSmallWidth}
                        products={goodDealProducts}
                        addOfferToBasket={addOfferToBasket}
                        distributionId={distributionId}
                        i18n={i18n}
                        productsTypes={productTypes}
                        actionOrigin={GOOD_DEALS_ON_HIGHLIGHT}
                        onClickOnProduct={goToSameSaleProduct}
                    />
                </div>
            )}
            {assemblyPopularProducts.length >= 5 && (
                <div className="pi-highlighted-products-section">
                    <Heading size={2} className="highlightedProducts-title" productIdentity>
                        {trans('salePage.assemblyPopularProducts')}
                    </Heading>
                    <PIProductsCarousel
                        products={assemblyPopularProducts}
                        addOfferToBasket={addOfferToBasket}
                        distributionId={distributionId}
                        i18n={i18n}
                        productsTypes={productTypes}
                        actionOrigin={POPULAR_PRODUCTS_BY_ASSEMBLY}
                        onClickOnProduct={goToSameSaleProduct}
                        isSmallWidth={isSmallWidth}
                    />
                </div>
            )}
            {newAssemblyProducts.length >= 5 && (
                <div className="pi-highlighted-products-section">
                    <Heading size={2} className="highlightedProducts-title" productIdentity>
                        {trans('salePage.newAssemblyProducts')}
                    </Heading>
                    <PIProductsCarousel
                        products={newAssemblyProducts}
                        addOfferToBasket={addOfferToBasket}
                        distributionId={distributionId}
                        i18n={i18n}
                        productsTypes={productTypes}
                        actionOrigin={NEW_ASSEMBLY_PRODUCTS}
                        onClickOnProduct={goToSameSaleProduct}
                        isSmallWidth={isSmallWidth}
                    />
                </div>
            )}
            {hasNextPage && (
                <div className="u-center u-mb-4">
                    <Button
                        onClick={loadMoreProducts}
                        loading={status === LOADING_MORE}
                        size="extraLarge"
                    >
                        {trans('products.highlighted.loadMore')}
                    </Button>
                </div>
            )}
            {interSaleProductPush && (
                <PIInterSaleProductPushContainer
                    sourceDistributionId={interSaleProductPush.sourceSale.id}
                    sourceDistributionDate={interSaleProductPush.sourceSale.distributionDate}
                    productsTypes={productTypes}
                    assemblyId={assembly.id}
                    onClickOnProduct={goToOtherSaleProduct(
                        assembly.id,
                        interSaleProductPush.sourceSale.id
                    )}
                />
            )}
            <div className="pi-highlighted-products-section">
                <AverageDistanceTraveled
                    distance={R.pipe(getAverageProductDistance, Math.round)(
                        assembly,
                        farms,
                        allProducts
                    )}
                />
            </div>
            {(!isMemberOfAssembly || joinAssemblyStatus === SAVED) && (
                <PIKeepInTouch
                    className="pi-highlighted-products-section"
                    assemblyId={assembly.id}
                    userId={userId}
                    joinAssembly={joinAssembly}
                    joinAssemblyStatus={joinAssemblyStatus}
                />
            )}
        </div>
    );
};

HighlightedProducts.propTypes = {
    addOfferToBasket: PropTypes.func.isRequired,
    isSmallWidth: PropTypes.bool.isRequired,
    productsByFarm: PropTypes.array.isRequired,
    status: statusPropType,
    assembly: PropTypes.object.isRequired,
    distributionId: PropTypes.number.isRequired,
    loadMoreProducts: PropTypes.func,
    hasNextPage: PropTypes.bool,
    saleOpenMessage: PropTypes.string,
    productTypes: PropTypes.object.isRequired,
    allProducts: PropTypes.array.isRequired,
    interSaleProductPush: PropTypes.object,
    farms: PropTypes.object.isRequired,
    goToOtherSaleProduct: PropTypes.func.isRequired,
    goToSameSaleProduct: PropTypes.func.isRequired,
    isMemberOfAssembly: PropTypes.bool,
    userId: PropTypes.number,
    joinAssembly: PropTypes.func.isRequired,
    joinAssemblyStatus: statusPropType,
    withTracking: PropTypes.bool,
};

HighlightedProducts.defaultProps = {
    loadMoreProducts: () => {},
};

export default HighlightedProducts;
