/**
 * This is NOT server ready, singleton pattern sucks
 * and jquery.cookie sucks even more (document.cookie, yes please)
 * should probably be reworked as a reactor module maybe ?
 */

import Backbone from 'backbone';
import _ from 'underscore';
import Cookies from 'js-cookie';
import $ from 'jquery';
import Utils from 'modules/utils';
import { claimAnonymousBasket } from 'api/baskets';

const _url = `${process.env.API_ROOT}/oauth/v2/token`;
const _clientId = process.env.OAUTH_ID;
const now = Utils.getTimeInSeconds;
let _accessToken = null;
let isRefreshing = false;
let _refreshToken = Cookies.get('auth');
let _expires = 0;
let _options = {};
const _defaultExpireTimeInDays = 7;
let currentRefresh = null;
let _passwordGrantFailedStatus = null;
let _passwordGrantFailedAttempts = 0;

/**
 * Internal helper in case of grant success
 */
function _success(data) {
    const cookieOptions = {};
    _accessToken = data.access_token;
    _refreshToken = data.refresh_token;
    _expires = now() + parseInt(data.expires_in * 0.95, 10);
    // careful, chrome does not clear session cookies apparently
    if (_options.remember === true) {
        Cookies.set('expiry', _defaultExpireTimeInDays, { expires: _defaultExpireTimeInDays });
        cookieOptions.expires = _defaultExpireTimeInDays;
    } else if (_options.remember === false) {
        Cookies.remove('expiry');
    } else if (Cookies.get('expiry')) {
        cookieOptions.expires = parseInt(Cookies.get('expiry'), 10);
    }
    Cookies.set('auth', _refreshToken, cookieOptions);
    Cookies.set('refresh_token', _refreshToken, cookieOptions);
    Cookies.set('access_token', _accessToken, cookieOptions);
    _options = {};
    _passwordGrantFailedStatus = null;
    _passwordGrantFailedAttempts = 0;
}

/**
 * Internal helper in case of grant failure for password type only
 */
function _keepTrackOfPasswordGrantFailure(jqXHR) {
    _passwordGrantFailedStatus = jqXHR.status;
    const responseHeader = jqXHR.getResponseHeader('X-Password-Grant-Failed-Attempts');
    if (responseHeader) {
        _passwordGrantFailedAttempts = parseInt(responseHeader, 10);
    }
}

/**
 * Manually expires the token
 */
export function _expiresToken() {
    _expires = 0;
}

/**
 * Clear any and all OAuth information
 * remove:
 * - the token
 * - the refresh token
 * - the timestamp
 * - the cookie
 */
export function clear() {
    _accessToken = null;
    _refreshToken = null;
    _expiresToken();
    Cookies.remove('expiry');
    Cookies.remove('auth');
    Cookies.remove('refresh_token');
    Cookies.remove('access_token');
    _options = {};
}

/**
 * Get the HTTP Authorization header pre-filled
 * @return {object} The object containing the Authorization header
 */
export function getHeader() {
    if (!_accessToken) {
        return null;
    }
    return {
        Authorization: `Bearer ${_accessToken}`,
    };
}

/**
 * Get the URL ready access_token
 * @return {object} The object containing the access_token for URL authorization
 */
export function getParameter() {
    if (!_accessToken) {
        return null;
    }
    return {
        access_token: _accessToken,
    };
}

/**
 * @return {string} The access token currently used
 */
export function getAccessToken() {
    return _accessToken;
}

/**
 * @return {string} The refresh token currently stored
 */
export function getRefreshToken() {
    return _refreshToken;
}

export function isPasswordGrantSuspended() {
    return _passwordGrantFailedStatus === 429 || _passwordGrantFailedAttempts > 9;
}

export const passwordGrantSuspensionDelayInMilliseconds = 900000; // 15 minutes

export function hasPasswordGrantFailedTwice() {
    return _passwordGrantFailedAttempts > 1;
}

/**
 * Grant an authorization via OAuth2
 * @param  {string} type        The type of grant, password or refresh(_accessToken)
 * @param  {object} options     The options for the grant
 * @return {$.Deferred, false}  Jquery deferred with server request, false if wrong grant type
 */
export function grant(type, options) {
    _options = options || {};
    switch (type) {
        case 'password':
            return $.ajax({
                type: 'POST',
                url: _url,
                dataType: 'json',
                data: {
                    grant_type: 'password',
                    client_id: _clientId,
                    username: _options.username,
                    password: _options.password,
                },
            })
                .done(_success)
                .fail(_keepTrackOfPasswordGrantFailure)
                .fail(clear);
        case 'refresh':
        case 'refresh_token':
            if (!_refreshToken) {
                return null;
            }
            return $.ajax({
                type: 'POST',
                url: _url,
                dataType: 'json',
                data: {
                    grant_type: 'refresh_token',
                    client_id: _clientId,
                    refresh_token: _refreshToken,
                },
            })
                .done(_success)
                .fail(clear);
        default:
            return null;
    }
}

// Store a copy of the original Backbone.sync
const originalSync = Backbone.sync;

function attachToRefresh($deferred, method, model, options) {
    currentRefresh
        .done(function() {
            isRefreshing = false;
            // redo purpose of first call of sync here ..., then use $deferred to send it ...
            Backbone.sync(method, model, options)
                .done($deferred.resolve)
                .fail($deferred.reject);
        })
        .fail($deferred.reject);
}

Backbone.sync = function(method, model, options = {}) {
    const noAuthorizationHeader =
        model.noAuthorizationHeader || options.noAuthorizationHeader || false;

    // no token
    if (_accessToken === null || noAuthorizationHeader) {
        return originalSync.call(model, method, model, options);
    }

    // Not expired
    if (now() < _expires) {
        options.headers = options.headers || {};
        _.extend(options.headers, getHeader());
    } else {
        // expired, try to refresh it internally
        const $deferred = new $.Deferred();

        // attach other calls to the current refreshing request
        if (isRefreshing) {
            attachToRefresh($deferred, method, model, options);
        } else {
            isRefreshing = true;
            // start refreshing
            currentRefresh = grant('refresh');
            attachToRefresh($deferred, method, model, options);
        }

        return $deferred;
    }

    // Perform the sync
    return originalSync.call(model, method, model, options);
};

export const grantAndClaimAnonymousBasket = (type, options = {}) => {
    const success = anonymousBasketUuid => {
        if (anonymousBasketUuid) {
            return claimAnonymousBasket(anonymousBasketUuid);
        }
        return true;
    };
    switch (type) {
        case 'password':
            return grant('password', {
                username: options.username,
                password: options.password,
                remember: options.remember,
            }).done(() => success(options.anonymousBasketUuid));
        case 'refresh_token':
            return grant('refresh_token').done(() => success(options.anonymousBasketUuid));
        default:
            return new Error(`Wrong type ${type}`);
    }
};
