import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { debounce } from 'underscore';
import useI18n from 'hooks/I18n/useI18n.js';
import { useFormContext } from 'react-hook-form';

import { getAddressSuggestions, splitAddressComponents } from 'models/mapbox.js';
import Autocomplete from 'src/components/molecules/Autocomplete/Autocomplete.jsx';
import Label from 'src/components/atoms/Label/Label.jsx';

const isStreetAddressValid = R.where({
    street: R.complement(R.isNil),
    city: R.complement(R.isNil),
    zipCode: R.complement(R.isNil),
});

const AddressAutocomplete = React.forwardRef((props, ref) => {
    const {
        placeholder,
        label,
        name,
        addressSelectionTracker,
        suggestAllTypes,
        onChange,
        onBlur,
    } = props;
    const { setValue } = useFormContext();
    const { i18n } = useI18n();
    const [suggestions, setSuggestions] = useState([]);

    const _searchPlace = debounce(async query => {
        const params = {
            query,
            hitsPerPage: 5,
            language: i18n.split('-')[0],
        };
        if (!suggestAllTypes) {
            params.type = 'address';
        }
        try {
            const hits = await getAddressSuggestions(params);
            setSuggestions(
                hits.map(hit => ({
                    ...hit,
                    name: hit.place_name,
                }))
            );
        } catch (e) {
            setSuggestions([]);
        }
    }, 300);

    const onAddressSelected = useCallback(
        suggestion => {
            const splittedAddress = splitAddressComponents(suggestion);
            const hasCompleteAddress = isStreetAddressValid(splittedAddress);
            setValue(name, suggestion.name);
            setValue('country', suggestion.country);
            setValue('country_code', suggestion.country_code);
            setValue('hasCompleteAddress', hasCompleteAddress);
            setValue('city', splittedAddress.city);
            setValue('street', splittedAddress.street);
            setValue('zipCode', splittedAddress.zipCode);
            setValue('coordinates', {
                latitude: suggestion._geoloc.lat,
                longitude: suggestion._geoloc.lng,
            });
            setSuggestions([]);

            const traker = addressSelectionTracker;

            if (typeof traker === 'function') {
                traker(suggestion.name);
            }
        },
        [setValue, addressSelectionTracker, name]
    );

    const handleOnBlur = useCallback(
        e => {
            onBlur && onBlur(e);
            setSuggestions([]);
        },
        [onBlur]
    );

    const onInput = useCallback(
        e => {
            onChange && onChange(e);
            setValue('coordinates', null);
            setValue('streetAddress', null);

            if (!e.target.value) {
                return setSuggestions([]);
            }

            return _searchPlace(e.target.value);
        },
        [setValue, _searchPlace, onChange]
    );

    return (
        <>
            <Label>{label}*</Label>
            <Autocomplete
                ref={ref}
                name={name}
                fieldValue={ref.current?.value}
                placeholder={placeholder}
                onChange={onInput}
                onBlur={handleOnBlur}
                suggestions={suggestions.map(suggestion => ({
                    ...suggestion,
                    text: suggestion.name,
                    metadata: { id: suggestion.id },
                }))}
                onItemClick={onAddressSelected}
            />
        </>
    );
});

AddressAutocomplete.propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    suggestAllTypes: PropTypes.bool,
    name: PropTypes.string,
    addressSelectionTracker: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

AddressAutocomplete.defaultProps = {
    placeholder: null,
    name: 'address',
    noMargin: false,
    inputVariant: null,
};
export default AddressAutocomplete;
