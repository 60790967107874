import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { fetchAssemblyPickups } from 'api/pickups';

import connectToI18n from 'modules/i18n/connectToI18n';
import Utils from 'modules/utils';
import { switchAssembly } from 'modules/navigation';
import { getIsoWeekday } from 'modules/utils/dateAndTime';

import { formatFullName } from 'models/users';
import { getSocialNetworks } from 'models/assembly';

import Avatar from 'components/Avatar.jsx';
import Link from 'components/Link.jsx';
import Heading from 'components/Heading.jsx';
import MediaObject from 'components/MediaObject.jsx';
import PickupPointList from './PickupPointList.jsx';

const { linkTo } = Utils;

const getOffsetInDays = (distributionTimeSlot, pickupTimeSlot) => {
    const week = 7;
    const pickupTimeSlotWeekDay = getIsoWeekday(pickupTimeSlot.dayOfWeek);
    const distributionDay = distributionTimeSlot.day;

    // when distribution is before pick up opening in the same week
    // for example : distribution is on tuesday and pick up is open on wednesday, offset is 1.
    if (pickupTimeSlotWeekDay > distributionDay) {
        return pickupTimeSlotWeekDay - distributionDay;
    }
    // when distribution day is after pick up's day,
    // for example : distribution is on tuesday and pick up is open on monday, offset is 6 (reported to next week)
    if (pickupTimeSlotWeekDay < distributionDay) {
        return pickupTimeSlotWeekDay + (week - distributionDay);
    }

    // when distribution and pick up are the same day and distribution  begins after the end of the pick up
    // for example distribution and pickup are on monday, pickups ending at 10:00, and distribution starts at 12:00
    // offset is 7 (reported to next week)
    if (distributionTimeSlot.starting >= pickupTimeSlot.timeEnd) {
        return week;
    }

    // when distribution and pick up are the same day and distribution  begins before the end of the pick up
    // for example distribution and pickup are on monday, distribution starts at 12:00 and pickups ending at 16:00
    // the offset is 0
    return 0;
};

const comparePickupsByFirstTimeSlot = (pickup1, pickup2) => {
    const day1 = getIsoWeekday(pickup1.timeSlots[0].dayOfWeek);
    const day2 = getIsoWeekday(pickup2.timeSlots[0].dayOfWeek);

    if (day1 !== day2) {
        return day1 - day2;
    }

    const time1 = pickup1.timeSlots[0].timeStart;
    const time2 = pickup2.timeSlots[0].timeStart;

    return time1.localeCompare(time2);
};

const sortTimeSlots = distributionTimeSlot =>
    R.evolve({
        timeSlots: R.sort(
            R.comparator(
                (a, b) =>
                    getOffsetInDays(distributionTimeSlot, a) <
                    getOffsetInDays(distributionTimeSlot, b)
            )
        ),
    });

export class AssemblyDetails extends React.Component {
    static propTypes = {
        assembly: PropTypes.object.isRequired,
        userIsHost: PropTypes.bool,
        switchAssembly: PropTypes.func.isRequired,
        trans: PropTypes.func.isRequired,
        transChoice: PropTypes.func.isRequired,
    };

    state = {
        pickups: [],
    };

    componentDidMount() {
        this.props.switchAssembly(this.props.assembly);

        fetchAssemblyPickups(this.props.assembly.uuid).then(pickups => {
            this.setState({
                pickups: R.pipe(
                    R.map(sortTimeSlots(this.props.assembly.deliveryTimeSlot)),
                    R.sortWith([comparePickupsByFirstTimeSlot])
                )(pickups),
            });
        });
    }

    render() {
        const {
            assembly: { id: assemblyId, leader: host, countMembers },
            userIsHost,
            trans,
            transChoice,
        } = this.props;

        const pickupPoints = [
            {
                pickup: this.props.assembly,
                type: 'assembly',
            },
        ].concat(
            this.state.pickups.map(pickup => ({
                pickup,
                type: 'pickup',
            }))
        );

        const socialNetworks = getSocialNetworks(this.props.assembly);

        return (
            <div>
                <div className="u-mb-6">
                    <Heading size={4} className="u-mb-1">
                        {trans('assemblyPage.products.pickUp')}
                    </Heading>
                    <PickupPointList pickupPoints={pickupPoints} className="u-mb-4" />
                </div>
                <div className="u-mb-6">
                    <Heading size={4} className="u-mb-1">
                        {trans('hives.page.leaderRole')}
                    </Heading>
                    <MediaObject
                        media={<Avatar photoId={host.photo && host.photo.id} size="medium" />}
                        size="small"
                    >
                        <div>{formatFullName(host)}</div>
                        {host.phone && <div>{host.phone}</div>}
                        <Link variant="primary" href={linkTo(`messages/contact/${host.id}`)}>
                            {trans('hives.page.contactHost')}
                        </Link>
                    </MediaObject>
                </div>
                {countMembers > 0 && (
                    <div className="u-mb-6">
                        <Heading size={4} className="u-mb-1">
                            {trans('hives.members.invite.members')}
                        </Heading>
                        <MediaObject
                            media={
                                <span className="editorialPageSection-sectionPicture">
                                    <span className="icon icon-group" />
                                </span>
                            }
                            size="small"
                        >
                            {transChoice('hives.members.list.asLeader.title', countMembers)}
                            {userIsHost && (
                                <div>
                                    <Link
                                        variant="primary"
                                        href={linkTo(`assemblies/${assemblyId}/members`)}
                                    >
                                        {trans('hives.members.list.asLeader.goToList')}
                                    </Link>
                                </div>
                            )}
                            <div>
                                <Link
                                    variant="primary"
                                    href={trans('hives.pageHeaderAsMember.inviteMembers.link', {
                                        '%assemblyId%': assemblyId,
                                    })}
                                    target="_blank"
                                >
                                    {trans('hives.members.list.asLeader.btnInviteMembers')}
                                </Link>
                            </div>
                        </MediaObject>
                    </div>
                )}
                {socialNetworks.length > 0 && (
                    <div className="u-mb-6">
                        <Heading size={4} className="u-mb-1">
                            {trans('hives.presentation.socialNetworks')}
                        </Heading>
                        {socialNetworks.map(({ name, url, icon }) => (
                            <a
                                key={name}
                                target="_blank"
                                className="button button--icon editorialPageSection-iconButton"
                                href={url}
                                title={name}
                                data-bypass="true"
                            >
                                <span className={`icon ${icon}`} />
                            </a>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default connect(undefined, {
    switchAssembly,
})(connectToI18n(AssemblyDetails));
