import React from 'react';
import PropTypes from 'prop-types';

const Badge = ({ children, count, hide }) => {
    return (
        <div className="nd-badge">
            {!hide && <div className="nd-badge-content">{count}</div>}
            {children}
        </div>
    );
};

Badge.propTypes = {
    children: PropTypes.element.isRequired,
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element]),
    hide: PropTypes.bool,
};

Badge.defaultProps = {
    hide: false,
};

export default Badge;
