import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Portal } from 'react-portal';

import useI18n from 'hooks/I18n/useI18n';

import Icon from 'components/Icon.jsx';
import homeIcon from 'app/assets/new-design/images/icons/home_transparent.svg';
import searchNavIcon from 'app/assets/new-design/images/icons/glass.svg';
import BasketButton from './BasketButton.jsx';
import { TOP_TOOLTIP } from 'src/components/atoms/Tooltip/Tooltip.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import AccountButton from 'components/Navigation/ProductIdentity/AccountButton.jsx';
import { CONTEXT_PANEL, SEARCH_PANEL } from '../../../modules/visiblePanel';

const SaleSmallScreenNavigation = ({
    onSearchBtnClick,
    onBasketBtnClick,
    onHomeBtnClick,
    basketRef,
    activePanel,
    basket = {},
    isOnAssemblyHomepage,
}) => {
    const { trans } = useI18n();
    const { items = [], purchasedPrice = {} } = basket;
    const totalQuantity = items.reduce((acc, { quantity }) => acc + quantity, 0);

    return (
        <Portal>
            <div className="pi-saleSmallScreenNavigation ignore-react-onclickoutside">
                <button
                    onClick={onHomeBtnClick}
                    className={classnames({
                        'pi-saleSmallScreenNavigation-button--enabled':
                            activePanel === CONTEXT_PANEL,
                    })}
                >
                    <Icon src={homeIcon} size="xx-large" />
                    <Text size="12px" lineHeight="14px">
                        {trans('menu.home')}
                    </Text>
                </button>
                <button
                    onClick={onSearchBtnClick}
                    className={classnames({
                        'pi-saleSmallScreenNavigation-button--enabled':
                            activePanel === SEARCH_PANEL,
                    })}
                >
                    <Icon src={searchNavIcon} size="xx-large" />
                    <Text size="12px" lineHeight="14px">
                        {trans('global.search')}
                    </Text>
                </button>
                <AccountButton
                    isSmallWidth
                    isOnHeader={isOnAssemblyHomepage}
                    shouldShowProductIdentity
                />
                <BasketButton
                    ref={basketRef}
                    onClick={onBasketBtnClick}
                    quantity={totalQuantity}
                    price={purchasedPrice}
                    tooltipOrientation={TOP_TOOLTIP}
                />
            </div>
        </Portal>
    );
};

SaleSmallScreenNavigation.propTypes = {
    onSearchBtnClick: PropTypes.func.isRequired,
    onHomeBtnClick: PropTypes.func.isRequired,
    onBasketBtnClick: PropTypes.func.isRequired,
    basketRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    basket: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                quantity: PropTypes.number,
            })
        ),
        purchasedPrice: PropTypes.shape({
            amount: PropTypes.number,
            currency: PropTypes.string,
        }),
    }),
    activePanel: PropTypes.string,
    isOnAssemblyHomepage: PropTypes.bool,
};

export default SaleSmallScreenNavigation;
