import {
    setCurrentUser,
    userSelector,
    anonymousUser,
    getAssemblyByIdSelector,
    loadCurrentUser,
} from 'modules/currentUser';
import { currentAssemblySelector } from 'modules/navigation';
import { logout } from '../../actions';

/**
 * Create "service" with window.reduxStore usage to replace:
 * - UsersService.getCurrentUser()
 * It could be use in service that doesn't "live" in react.
 * inspiration : https://github.com/lrqdo/front-web/pull/9403
 */

const getDispatch = () => {
    if (window && window.reduxStore && typeof window.reduxStore.dispatch === 'function') {
        return window.reduxStore.dispatch;
    }

    return undefined;
};

const getState = () => {
    if (window && window.reduxStore && typeof window.reduxStore.getState === 'function') {
        return window.reduxStore.getState();
    }

    return undefined;
};

export const getCurrentUser = () => {
    const state = getState();
    if (state !== undefined) {
        return userSelector(state);
    }

    return anonymousUser;
};

export const getCurrentAssembly = () => {
    const state = getState();
    if (state !== undefined) {
        return currentAssemblySelector(state);
    }

    return undefined;
};

export const dispatchCurrentUser = user => {
    const dispatch = getDispatch();
    if (dispatch !== undefined) {
        dispatch(setCurrentUser(user));
    }
};

export const getAssemblyById = assemblyId => {
    const state = getState();
    if (state !== undefined) {
        return getAssemblyByIdSelector(assemblyId)(state);
    }

    return undefined;
};

export const dispatchLoadCurrentUser = args => {
    const dispatch = getDispatch();
    if (dispatch !== undefined) {
        return dispatch(loadCurrentUser(args));
    }

    return undefined;
};

export const dispatchLogout = () => {
    const dispatch = getDispatch();
    if (dispatch !== undefined) {
        dispatch(logout());
    }
};
