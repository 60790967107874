import * as localstorage from 'modules/localstorage';

export function persistCampaign(campaign) {
    return localstorage.setItem('marketingCampaign', campaign);
}

export function getPersistedCampaign() {
    return localstorage.getItem('marketingCampaign');
}

export function forgetCampaign() {
    return localstorage.removeItem('marketingCampaign');
}
