import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import Heading from 'components/Heading.jsx';
import MediaObject from 'components/MediaObject.jsx';
import Text from 'components/Text.jsx';
import { LOADING_UPDATE, propType as statusPropType } from 'modules/utils/ajaxStatuses';
import AppChannel from 'modules/channels/App';
import useI18n from 'hooks/I18n/useI18n.js';
import { FARMER, HOST } from 'modules/utils/userTypes';

export const HelpCenterSearch = ({
    searchValue,
    onSearch,
    loadingArticles,
    articles,
    promotedArticles,
    userType,
    status,
}) => {
    const { trans, i18nConfig } = useI18n();

    const handleSearch = e => {
        onSearch(e.target.value);
        loadingArticles(e.target.value);
        AppChannel.vent.trigger('analytics:newHelpCenter:searchOnHelpCenter');
    };

    const handleClick = () => {
        AppChannel.vent.trigger('analytics:newHelpCenter:visitArticle');
    };

    let result = null;
    const title =
        searchValue.length > 0
            ? trans('helpCenter.search.results', {
                  '%searchValue%': searchValue,
              })
            : trans('helpCenter.search.promoted');
    if (status === LOADING_UPDATE) {
        result = <LoadingAnimation type="spinner" />;
    } else {
        if (articles.length > 0) {
            result = (
                <div className="helpCenter-list">
                    {articles.map(article => (
                        <a
                            className="helpCenter-article"
                            key={article.id}
                            target="_blank"
                            href={article.html_url}
                            onClick={handleClick}
                        >
                            {article.name}
                        </a>
                    ))}
                </div>
            );
        } else if (searchValue.length > 0) {
            result = (
                <div className="helpCenter-list">
                    {trans('helpCenter.search.no.results', {
                        '%searchValue%': searchValue,
                    })}
                </div>
            );
        }
    }

    return (
        <div className="helpCenter-search">
            <input
                className="fa-input fa-input--block fa-input--inverse u-mb-6"
                onChange={handleSearch}
                placeholder={trans('helpCenter.search.label')}
                value={searchValue}
            />
            <Heading size={4} className="u-mb-4">
                {title}
            </Heading>
            {result}
            {!result && (
                <div className="helpCenter-list">
                    {promotedArticles.map(article => (
                        <a
                            className="helpCenter-article"
                            key={article.id}
                            target="_blank"
                            href={article.html_url}
                            onClick={handleClick}
                        >
                            {article.name}
                        </a>
                    ))}
                </div>
            )}
            <a
                className="helpCenter-link u-mb-10"
                target="_blank"
                href={i18nConfig.supportSite[userType]}
            >
                {trans('helpCenter.go.to.helpCenter')}
            </a>

            {userType === HOST && i18nConfig.hostEssential && (
                <Fragment>
                    <Heading size={4} className="u-mb-2">
                        {trans('helpCenter.title.hostEssential')}
                    </Heading>
                    <MediaObject
                        media={
                            <img
                                className="helpCenter-hostIcon"
                                src="/assets/images/Host-tiny.svg"
                            />
                        }
                        size="small"
                        valignCenter
                    >
                        <div>
                            <Text size="large">{trans('helpCenter.hostEssential.intro')}</Text>
                            <a
                                className="helpCenter-link u-mr-1"
                                target="_blank"
                                href={i18nConfig.hostEssential.url}
                            >
                                {trans('helpCenter.go.to.hostEssential')}
                            </a>
                        </div>
                    </MediaObject>
                </Fragment>
            )}

            {userType === FARMER && i18nConfig.prodEssential && (
                <Fragment>
                    <Heading size={4} className="u-mb-2">
                        {trans('helpCenter.prodEssential.title')}
                    </Heading>
                    <MediaObject
                        media={
                            <img
                                className="helpCenter-producerIcon"
                                src="/assets/images/Producer-tiny.svg"
                            />
                        }
                        size="small"
                        valignCenter
                    >
                        <div>
                            <Text size="large">{trans('helpCenter.prodEssential.intro')}</Text>
                            <a
                                className="helpCenter-link u-mr-1"
                                target="_blank"
                                href={i18nConfig.prodEssential.url}
                            >
                                {trans('helpCenter.prodEssential.goTo')}
                            </a>
                        </div>
                    </MediaObject>
                </Fragment>
            )}

            {userType === HOST && i18nConfig.trainingSite && !i18nConfig.hostEssential && (
                <Fragment>
                    <Heading size={4} className="u-mb-4">
                        {trans('helpCenter.title.hostEssential')}
                    </Heading>
                    <p>
                        <a
                            className="helpCenter-link u-mr-1"
                            target="_blank"
                            href={i18nConfig.trainingSite.url}
                        >
                            {trans('helpCenter.go.to.trainingSite')}
                        </a>
                        {i18nConfig.trainingSite.email && (
                            <div>{i18nConfig.trainingSite.email}</div>
                        )}
                        <span>
                            (
                            {trans('navigation.help.trainingSitePassword', {
                                '%password%': i18nConfig.trainingSite.password,
                            })}
                            )
                        </span>
                    </p>
                </Fragment>
            )}
        </div>
    );
};

HelpCenterSearch.propTypes = {
    searchValue: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    loadingArticles: PropTypes.func.isRequired,
    articles: PropTypes.array.isRequired,
    promotedArticles: PropTypes.array.isRequired,
    userType: PropTypes.string.isRequired,
    status: statusPropType,
};

export default HelpCenterSearch;
