import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useI18n from 'hooks/I18n/useI18n';
import Button from 'src/components/atoms/Button/Button.jsx';
import Modal from 'src/components/organisms/Modal/Modal.jsx';
import AssembliesMapFilters from './AssembliesMapFilters.jsx';

export const MobileFiltersModal = ({
    isOnlyOpenSaleFilterEnabled,
    selectedPrimaryFilter,
    onFiltersChange,
    closeModal,
    opened = false,
}) => {
    const { trans } = useI18n();
    const [localSelectedPrimaryFilter, setLocalSelectedPrimaryFilter] = useState(
        selectedPrimaryFilter
    );
    const [localIsOnlyOpenSaleFilterEnabled, setLocalIsOnlyOpenSaleFilterEnabled] = useState(
        isOnlyOpenSaleFilterEnabled
    );

    const resetFiltersAndCloseModal = () => {
        setLocalSelectedPrimaryFilter(selectedPrimaryFilter);
        setLocalIsOnlyOpenSaleFilterEnabled(isOnlyOpenSaleFilterEnabled);
        closeModal();
    };

    const isApplyBtnDisabled =
        localSelectedPrimaryFilter === selectedPrimaryFilter &&
        localIsOnlyOpenSaleFilterEnabled === isOnlyOpenSaleFilterEnabled;

    return (
        <Modal
            isMounted={opened}
            title={trans('assembliesMap.filtersModal.title')}
            closeModal={resetFiltersAndCloseModal}
            className="map-mobile-filters-modal"
            withTransitions
            delayTime={300}
        >
            <>
                <AssembliesMapFilters
                    isOnlyOpenSaleFilterEnabled={localIsOnlyOpenSaleFilterEnabled}
                    onChangePrimaryFilter={setLocalSelectedPrimaryFilter}
                    selectedPrimaryFilter={localSelectedPrimaryFilter}
                    onToggleOnlyOpenSaleFilter={() => {
                        setLocalIsOnlyOpenSaleFilterEnabled(!localIsOnlyOpenSaleFilterEnabled);
                    }}
                />
                <Button
                    onClick={() => {
                        onFiltersChange(
                            localIsOnlyOpenSaleFilterEnabled,
                            localSelectedPrimaryFilter
                        );
                    }}
                    disabled={isApplyBtnDisabled}
                >
                    {trans('global.apply')}
                </Button>
            </>
        </Modal>
    );
};

MobileFiltersModal.propTypes = {
    opened: PropTypes.bool,
    isOnlyOpenSaleFilterEnabled: PropTypes.bool.isRequired,
    selectedPrimaryFilter: PropTypes.oneOf(['all', 'regularDistribution', 'homeDelivery'])
        .isRequired,
    onFiltersChange: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default MobileFiltersModal;
