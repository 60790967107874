import * as R from 'ramda';
import reducerRegistry from '../../reducers/ReducerRegistry';
import { NONE, LOADING, LOADED, FAILED, createApiActionTypes } from '../../actions';
import { fetchCountries } from 'api/countries';

export const GLOBAL_STATE_KEY = 'countries';

function getCountries() {
    return fetchCountries().then(R.prop('countries'));
}

export const getCountriesState = R.prop(GLOBAL_STATE_KEY);

const actionTypes = {
    FETCH_COUNTRIES: createApiActionTypes('countries', 'FETCH_COUNTRIES'),
};

export const fetchCountriesIfNeeded = () => (dispatch, getState) => {
    const state = getCountriesState(getState());
    if (state.status !== NONE) {
        return undefined;
    }
    dispatch({ type: actionTypes.FETCH_COUNTRIES.REQUEST });

    return getCountries().then(
        data => dispatch({ type: actionTypes.FETCH_COUNTRIES.SUCCESS, payload: data }),
        err => dispatch({ type: actionTypes.FETCH_COUNTRIES.ERROR, payload: err })
    );
};

function countriesReducer(
    state = {
        data: [],
        status: NONE,
    },
    { type, payload }
) {
    switch (type) {
        case actionTypes.FETCH_COUNTRIES.REQUEST:
            return {
                ...state,
                status: LOADING,
            };
        case actionTypes.FETCH_COUNTRIES.SUCCESS:
            return {
                data: payload,
                status: LOADED,
            };
        case actionTypes.FETCH_COUNTRIES.FAILURE:
            return {
                ...state,
                status: FAILED,
            };
        default:
            return state;
    }
}

reducerRegistry.register({
    [GLOBAL_STATE_KEY]: countriesReducer,
});
