import PropTypes from 'prop-types';
import React from 'react';
import FarmSection from './FarmSection.jsx';
import AssembliesSection from './AssembliesSection.jsx';
import UserPanel from './UserPanel.jsx';
import { isEligibleForPro } from 'models/users';
import { createLeaderProfile } from 'api/users';
import { imperativeEditUser } from 'modules/currentUser';
import * as R from 'ramda';
import AppChannel from 'modules/channels/App';
import { useDispatch } from 'react-redux';

export const UserBottomPanel = ({
    currentUser,
    userAssemblies,
    hostAssemblies,
    farm,
    hasPendingLeaderProfile,
    newsfeedUnreadCount,
    invitationsCount,
}) => {
    const dispatch = useDispatch();

    const isUserFrench = currentUser?.locale === 'fr_FR';

    const handleCreateLeaderProfile = () => {
        const allowCreation = isEligibleForPro(currentUser);

        if (allowCreation) {
            createLeaderProfile(currentUser.id)
                .then(() => {
                    dispatch(
                        imperativeEditUser(
                            R.mergeRight({
                                leaderProfile: {
                                    blob: '{}',
                                    status: 'init',
                                },
                            })
                        )
                    );
                    AppChannel.commands.execute('navigate', 'progress', true);
                })
                .catch(() => {});
        } else {
            AppChannel.commands.execute('navigate', 'validate/scu/host', true);
        }
    };

    return (
        <UserPanel currentUser={currentUser} newsfeedUnreadCount={newsfeedUnreadCount}>
            <AssembliesSection
                userAssemblies={userAssemblies}
                hostAssemblies={hostAssemblies}
                hasPendingLeaderProfile={hasPendingLeaderProfile}
                canCreateLeaderProfile={!currentUser.leaderProfile}
                createLeaderProfile={handleCreateLeaderProfile}
                isUserFrench={isUserFrench}
            />

            <div className="contextPanel-farmWrapper">
                <FarmSection
                    farm={farm}
                    shouldDisplayProviderFolder
                    shouldDisplayAccounting
                    shouldDisplayAssemblies
                    badgeValue={invitationsCount}
                />
            </div>
        </UserPanel>
    );
};

UserBottomPanel.propTypes = {
    currentUser: PropTypes.object,
    userAssemblies: PropTypes.array,
    hostAssemblies: PropTypes.array,
    farm: PropTypes.object,
    hasPendingLeaderProfile: PropTypes.bool,
    newsfeedUnreadCount: PropTypes.number,
    invitationsCount: PropTypes.number,
};

export default UserBottomPanel;
