import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { get } from 'modules/api';
import connectToI18n from 'modules/i18n/connectToI18n';
import { displayableCity } from 'models/address';
import { PRODUCTS_SELECTION } from 'modules/originUtils.js';
import { editBasketOfferQuantity } from 'modules/orders';
import { fetchBasket } from 'modules/orders';
import { getLocalizedDate } from 'modules/utils/dateAndTime';
import { LOADING, READY } from 'modules/utils/ajaxStatuses';
import { addFarmAndLabelsToProduct } from 'models/products';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import { Heading } from 'components/ui';
import Text from 'components/ProductIdentity/Text.jsx';

import PIProductsCarousel from 'components/Sale/ProductIdentity/ProductsCarousel.jsx';

const InterSaleProductPushContainer = props => {
    const {
        sourceDistributionId,
        sourceDistributionDate,
        productsTypes,
        trans,
        i18n,
        onClickOnProduct,
    } = props;
    const [pushedProducts, setPushedProducts] = useState([]);
    const [status, setStatus] = useState(LOADING);
    const dispatch = useDispatch();
    const addOfferToBasket = (offerId, quantity = 1, actionOrigin) => {
        dispatch(editBasketOfferQuantity(sourceDistributionId, offerId, quantity, actionOrigin));
    };

    const localizedDistributionDate = getLocalizedDate(i18n, 'i', sourceDistributionDate);

    useEffect(() => {
        dispatch(fetchBasket(sourceDistributionId));

        get(`distributions/${sourceDistributionId}/products?onlyHighlighted=true`).then(payload => {
            const farmsWithDisplayableCity = R.map(farm =>
                R.assoc('city', displayableCity(farm), farm)
            )(payload.farms);
            const sourceFarmsWithDisplayableCity = R.map(farm =>
                R.assoc('city', displayableCity(farm), farm)
            )(payload.sourceFarms);
            const farmsAndSourcesFarms = {
                ...farmsWithDisplayableCity,
                ...sourceFarmsWithDisplayableCity,
            };

            const allProductsWithFarmsAndLabelsInfo = payload.products.map(product =>
                addFarmAndLabelsToProduct(product, farmsAndSourcesFarms, payload.labels)
            );
            setPushedProducts(allProductsWithFarmsAndLabelsInfo);
            setStatus(READY);
        });
    }, [dispatch, sourceDistributionId]);

    if (status === LOADING) {
        return <LoadingAnimation />;
    }

    return (
        <div className="pi-inter-sale-products">
            <div>
                <Heading size={2} productIdentity>
                    {trans('sale.interSaleProductPush.title', {
                        '%date%': localizedDistributionDate,
                    })}
                </Heading>
                <Text size="16px" lineHeight="24px" family="inter" weight="400" color="gray2">
                    {trans('sale.interSaleProductPush.subtitle', {
                        '%date%': localizedDistributionDate,
                    })}
                </Text>
                <PIProductsCarousel
                    products={pushedProducts}
                    addOfferToBasket={addOfferToBasket}
                    distributionId={sourceDistributionId}
                    productsTypes={productsTypes}
                    actionOrigin={PRODUCTS_SELECTION}
                    distributionDate={sourceDistributionDate}
                    onClickOnProduct={onClickOnProduct}
                />
            </div>
        </div>
    );
};

InterSaleProductPushContainer.propTypes = {
    sourceDistributionId: PropTypes.number.isRequired,
    sourceDistributionDate: PropTypes.string.isRequired,
    productsTypes: PropTypes.object.isRequired,
    trans: PropTypes.func.isRequired,
    i18n: PropTypes.string.isRequired,
    onClickOnProduct: PropTypes.func.isRequired,
};
export default connectToI18n(InterSaleProductPushContainer);
