import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import useI18n from 'hooks/I18n/useI18n.js';
import useAnalytics from 'hooks/Analytics/useAnalytics';

import { LOADING, propType as statusPropType } from 'modules/utils/ajaxStatuses';
import { PRODUCTS_SELECTION } from 'modules/originUtils.js';
import { ORGANIC_FILTER } from 'modules/utils/saleCategoriesAndFilters';

import ProductCard from 'components/Sale/ProductIdentity/ProductCard.jsx';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import Heading from 'components/Heading.jsx';

import { getProductsListWidth } from 'components/Sale/designUtils';
import CategoryBanner from 'components/Sale/banners/CategoryBanner.jsx';

export const ProductsByCategories = ({
    addOfferToBasket,
    productsByCategories,
    status,
    distributionId,
    allProductTypesIndexedById,
    goToSameSaleProduct,
    filter,
    assembly,
    hasGoodDealMode,
    filterTag,
    category,
}) => {
    const { trans, i18n } = useI18n();
    const { analytics } = useAnalytics();

    useEffect(() => {
        const products = Object.values(productsByCategories).reduce((accumulator, currentValue) => {
            return [...accumulator, ...currentValue.products];
        }, []);
        let listId = 'good_deals';
        let listName = 'Good Deals';

        if (category === 'christmas') {
            listId = 'christmas';
            listName = 'Christmas';
        }

        analytics.trackViewItemList(listId, listName, assembly, products);
    }, [category]); // eslint-disable-line react-hooks/exhaustive-deps

    if (status === LOADING) {
        return <LoadingAnimation type={'pi-spinner'} />;
    }

    return (
        <div
            className={classnames(
                'saleContent-productsList',
                'productByCategories-saleContent-productsList',
                { 'is-loading': status === LOADING }
            )}
        >
            <CategoryBanner
                category={category === 'christmas' ? category : category + '--' + filterTag}
            />
            {productsByCategories.length === 0 && filter === ORGANIC_FILTER && (
                <div
                    className="productByCategories-products-empty"
                    dangerouslySetInnerHTML={{
                        __html: trans('products.filters.onlyBioWarning'),
                    }}
                    style={{ width: getProductsListWidth() }}
                />
            )}
            {productsByCategories.map(productsByCategory => {
                return (
                    <Fragment key={productsByCategory.category.id}>
                        <Heading
                            className="pi-products-list-section-title"
                            rank={3}
                            size={4}
                            productIdentity
                            bold
                            serif
                        >
                            {productsByCategory.category.name}
                        </Heading>
                        <div className="pi-productByCategories-products">
                            {productsByCategory.products.map(product => {
                                return (
                                    <ProductCard
                                        mode="large"
                                        addOfferToBasket={addOfferToBasket}
                                        allProductTypesIndexedById={allProductTypesIndexedById}
                                        distributionId={distributionId}
                                        i18n={i18n}
                                        product={product}
                                        key={product.id}
                                        variant="vertical"
                                        displayProducer
                                        actionOrigin={PRODUCTS_SELECTION}
                                        onClick={() => goToSameSaleProduct(product.id)}
                                        hasGoodDealMode={hasGoodDealMode}
                                    />
                                );
                            })}
                        </div>
                    </Fragment>
                );
            })}
        </div>
    );
};

ProductsByCategories.propTypes = {
    addOfferToBasket: PropTypes.func.isRequired,
    productsByCategories: PropTypes.array.isRequired,
    status: statusPropType,
    distributionId: PropTypes.number.isRequired,
    allProductTypesIndexedById: PropTypes.object.isRequired,
    goToSameSaleProduct: PropTypes.func.isRequired,
    filter: PropTypes.string,
    assembly: PropTypes.object.isRequired,
    hasGoodDealMode: PropTypes.bool,
    filterTag: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
};

ProductsByCategories.defaultProps = {
    hasGoodDealMode: false,
};
export default ProductsByCategories;
