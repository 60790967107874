import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useI18n from 'hooks/I18n/useI18n';
import { asMultiRole } from 'models/users';
import { userSelector } from 'modules/currentUser';

import ResponsiveSidePanel, {
    POSITION_LEFT,
} from 'components/Navigation/ProductIdentity/ResponsiveSidePanel.jsx';
import AnonymousHivePanel from 'components/Navigation/ProductIdentity/HivePanel/AnonymousHivePanel.jsx';
import HivesPanel from 'components/Navigation/ProductIdentity/HivePanel/HivesPanel.jsx';

const ContextPanel = props => {
    const { assembly, onClose, hasPendingLeaderProfile } = props;
    const { trans, transChoice } = useI18n();
    const user = useSelector(userSelector);

    return (
        <ResponsiveSidePanel
            isOpened
            position={POSITION_LEFT}
            title={
                asMultiRole({ user })
                    ? trans('menu.hives.title')
                    : transChoice('hives.memberPanel.title', user.hivesAsMember?.length)
            }
            onClose={onClose}
            className="pi-hive-panel"
            outsideClickIgnoreClass={'responsiveSidePanel'}
        >
            {user.anonymous && <AnonymousHivePanel assembly={assembly} onClose={onClose} />}
            {!user.anonymous && (
                <HivesPanel
                    hasPendingLeaderProfile={hasPendingLeaderProfile}
                    assembly={assembly}
                    onClose={onClose}
                />
            )}
        </ResponsiveSidePanel>
    );
};

ContextPanel.propTypes = {
    assembly: PropTypes.object,
    onClose: PropTypes.func,
    hasPendingLeaderProfile: PropTypes.bool,
};
export default ContextPanel;
