import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InlineSVG from 'svg-inline-react';

import { filerPhoto } from 'modules/utils/filer';
import Text, { SMALL } from 'src/components/atoms/Text/Text.jsx';

import Caret from 'app/assets/new-design/images/icons/caret.svg';

export const HostCard = ({ url, photoUuid, cityName, pointOfSaleName, street, hostFirstname }) => {
    const CardWrapperComponent = url ? 'a' : 'div';

    return (
        <div className="map-host-card">
            <CardWrapperComponent href={url} className="map-host-card-wrapper">
                <div className="map-host-card-content">
                    <img src={filerPhoto(photoUuid, 'small', 'user')} />
                    <div className="map-host-card-text pi-typo">
                        {!!cityName && <Text strong>{cityName}</Text>}
                        <Text className="assembly-name">{pointOfSaleName}</Text>
                        <Text size={SMALL} className={classnames({ twoRow: !cityName })}>
                            {street}
                        </Text>
                        <Text size={SMALL} className="assembly-hostname">
                            {hostFirstname}
                        </Text>
                    </div>
                    {url && (
                        <div className="map-host-card-arrow">
                            <InlineSVG raw src={Caret} />
                        </div>
                    )}
                </div>
            </CardWrapperComponent>
        </div>
    );
};

HostCard.propTypes = {
    url: PropTypes.string,
    photoUuid: PropTypes.string,
    cityName: PropTypes.string,
    pointOfSaleName: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    hostFirstname: PropTypes.string.isRequired,
};

HostCard.defaultProps = {
    url: null,
};

export default HostCard;
