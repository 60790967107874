const POSITIVE_INFINITY = 'Inf';
const NEGATIVE_INFINITY = '-Inf';
const NUMBER_PATTERN = /\d+/;

function assertNumber(string) {
    if (!NUMBER_PATTERN.test(string)) {
        throw new Error(`${string} is not a number`);
    }
}

function parseNumber(string) {
    if (string === POSITIVE_INFINITY) {
        return Infinity;
    }
    if (string === NEGATIVE_INFINITY) {
        return -Infinity;
    }
    assertNumber(string);
    return parseFloat(string);
}

function Interval(leftSymbol, leftNumberString, rightNumberString, rightSymbol) {
    this.leftSymbol = leftSymbol;
    this.rightSymbol = rightSymbol;
    this.leftNumber = parseNumber(leftNumberString);
    this.rightNumber = parseNumber(rightNumberString);

    if (this.leftNumber > this.rightNumber) {
        throw new Error('Left number must be lower or equal than right one');
    }
}

Interval.prototype.contains = function(number) {
    return !this.numberDoesNotFitLeftAndRightConstraints(number);
};

Interval.prototype.numberDoesNotFitLeftAndRightConstraints = function(number) {
    return (
        this.numberDoesNotFitLeftOpenConstraint(number) ||
        this.numberDoesNotFitLeftCloseConstraint(number) ||
        this.numberDoesNotFitRightOpenConstraint(number) ||
        this.numberDoesNotFitRightCloseConstraint(number)
    );
};

Interval.prototype.numberDoesNotFitLeftOpenConstraint = function(number) {
    return this.leftSymbol.isLeftOpen() && number <= this.leftNumber;
};

Interval.prototype.numberDoesNotFitLeftCloseConstraint = function(number) {
    return this.leftSymbol.isLeftClose() && number < this.leftNumber;
};

Interval.prototype.numberDoesNotFitRightOpenConstraint = function(number) {
    return this.rightSymbol.isRightOpen() && number >= this.rightNumber;
};

Interval.prototype.numberDoesNotFitRightCloseConstraint = function(number) {
    return this.rightSymbol.isRightClose() && number > this.rightNumber;
};

export default Interval;
