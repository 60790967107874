import $ from 'jquery';
import jqueryToPromise from 'modules/utils/jqueryToPromise';

export const send = request => {
    return jqueryToPromise(
        $.ajax({
            ...request,
            global: false,
        })
    ).then(response => response.responseJSON);
};
