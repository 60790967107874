import React from 'react';
import ImpersonateModule from 'modules/impersonate';
import environment from 'modules/utils/environment';

const Impersonate = () => {
    const impersonateEmail = ImpersonateModule.getImpersonated();

    if (!impersonateEmail || environment !== 'production') {
        return null;
    }

    return (
        <div className="impersonate">
            <div className="impersonate--email">
                <span className="icon icon-user u-mr-0 u-mr-sm-2"></span>
                <span className="hidden-xs">{impersonateEmail}</span>
            </div>
            <div className="impersonate--env hidden-xs">{environment}</div>
        </div>
    );
};

Impersonate.propTypes = {};

export default Impersonate;
