import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InlineSVG from 'svg-inline-react';
import infoCircle from 'app/assets/new-design/images/icons/information.svg';
import errorIcon from 'app/assets/new-design/images/icons/error.svg';
import validIcon from 'app/assets/new-design/images/icons/validate-icon.svg';
import Text, { SMALL } from 'src/components/atoms/Text/Text.jsx';

export const ERROR_TYPE = 'error';
export const INFO_TYPE = 'info';
export const VALID_TYPE = 'valid';

const SVGs = {
    [INFO_TYPE]: infoCircle,
    [ERROR_TYPE]: errorIcon,
    [VALID_TYPE]: validIcon,
};
const Alert = ({ className, message, type, visible }) => {
    return (
        <div className={classNames('nd-alert', className, { [type]: type, visible })}>
            <InlineSVG raw src={SVGs[type]} />
            <Text size={SMALL} dangerouslySetInnerHTML={{ __html: message }} />
        </div>
    );
};

Alert.propTypes = {
    type: PropTypes.oneOf([ERROR_TYPE, INFO_TYPE]),
    className: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    visible: PropTypes.bool,
};

Alert.defaultProps = {
    type: INFO_TYPE,
    className: '',
    visible: false,
    message: '',
};

export default Alert;
