import 'backbone.routefilter';
import Backbone from 'backbone';
import _ from 'underscore';
import AppChannel from 'modules/channels/App';
import ContextService from '../entities/context/context.service';

const MainRouter = Backbone.Router.extend({
    reactContext: {},

    execute(callback, args) {
        if (callback) {
            callback.apply(this, args);
        }
    },

    changeReactContext(activeLink, context, assemblyId = null) {
        this.reactContext = {
            activeLink,
            context,
            assemblyId,
        };
    },

    addRoutes(routes, beforeConditionORApi) {
        return this._addRoutesWithApi(routes, beforeConditionORApi);
    },

    _addRoutesWithApi(routes, API) {
        _.each(
            routes,
            function(routeData) {
                this._addRoute(routeData, API[routeData.methodName].bind(API));
            },
            this
        );

        // cleanup previous routes ... number of handlers should stay constant
        const nbOfRoutesAdded = routes.length;
        let routesProcessed = 0;

        while (routesProcessed < nbOfRoutesAdded) {
            // Last routes are in first places
            const tempRegexString = Backbone.history.handlers[routesProcessed].route.toString();
            let previousRouteIndex;

            // start from just up of the temp
            for (
                let i = routesProcessed + 1, length = Backbone.history.handlers.length;
                i < length;
                ++i
            ) {
                if (Backbone.history.handlers[i].route.toString() === tempRegexString) {
                    previousRouteIndex = i;
                    Backbone.history.handlers.splice(previousRouteIndex, 1);
                    break;
                }
            }

            ++routesProcessed;
        }
    },

    _addRoute(routeData, fn) {
        const route = routeData.definition;
        const methodName = routeData.methodName;

        this.route(route, methodName, fn);
    },

    after() {
        this._updateNavigation();
    },

    _updateNavigation() {
        const routeContext = this.reactContext.context;
        const activeLink = this.reactContext.activeLink;

        if (routeContext) {
            ContextService.change(routeContext, this.reactContext.assemblyId);
        } else {
            ContextService.resetToDefaultContext();
        }

        AppChannel.vent.trigger('route', {
            activeLink,
            context: ContextService.getCurrentContext(),
        });
    },
});

const mainRouterSingleton = new MainRouter();

export default mainRouterSingleton;
