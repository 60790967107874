import * as R from 'ramda';

export const compareAlpha = R.curry((a, b) => R.toLower(a).localeCompare(R.toLower(b)));

export function removeSpacesFromString(str) {
    if (!R.is(String, str)) {
        return str;
    }
    return str.replace(/\s/g, '');
}

const countPatternOccurences = pattern => str => {
    const result = str.match(pattern);
    return result ? result.length : 0;
};

export const countDigits = countPatternOccurences(/\d/g);
export const countNonDigits = countPatternOccurences(/\D/g);

const test = regexp => str => regexp.test(str);

export const isAlphaNumeric = test(/^[A-Za-z0-9]+$/);

// transformations

// string to lower case
export function toLowerCase(word) {
    return word ? word.toLowerCase() : word;
}

const accentsCharmap = {
    a: /[àâäÀÂÄ]/g,
    c: /[çÇ]/g,
    e: /[éèêëÉÈÊË]/g,
    i: /[îïÎÏ]/g,
    o: /[öôÖÔ]/g,
    ss: /[ß]/g,
    u: /[ûùüÛÙÜ]/g,
};

const unaccentLegacy = str => {
    let unaccentedStr = str;
    Object.keys(accentsCharmap).forEach(chr => {
        unaccentedStr = unaccentedStr.replace(accentsCharmap[chr], chr);
    });

    return unaccentedStr;
};

export function matchingStringToBold(str, find) {
    const regEx = new RegExp(find, 'gi');
    return str.replace(regEx, `<b>${find}</b>`);
}

// unaccent string
const unaccent = str => {
    if (!str.normalize) {
        return unaccentLegacy(str);
    }

    // Ref: http://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463#37511463
    return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/ß/g, 'ss');
};

// capitalize first letter of a string
export function capitalizeFirstLetter(str) {
    if (!str || !str.length) {
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function initialWithDot(word) {
    if (!word || !word.length) {
        return '';
    }
    return `${word.charAt(0)}.`;
}

// compositions

// Return a string with the accented chars replaced by the logical ASCII equivalents
export const lowerCaseAndUnaccent = R.compose(toLowerCase, unaccent);

export const uppercaseInitalWithDot = R.compose(capitalizeFirstLetter, initialWithDot);

export const capitalizeFirstLetterOnly = R.compose(capitalizeFirstLetter, toLowerCase);

// every word of the string will start with an uppercase letter  ex : 'jean dupond'-> 'Jean Dupond'
export function titleCase(str) {
    if (!str) {
        return '';
    }
    return str
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
}

export function join(arr, { separator, lastSeparator } = {}) {
    if (!Array.isArray(arr)) {
        return '';
    }
    if (!lastSeparator) {
        return arr.join(separator);
    }
    return arr.reduce((prev, str, index, { length }) => {
        if (index === 0) {
            return str;
        }
        if (index === length - 1) {
            return `${prev}${lastSeparator}${str}`;
        }
        return `${prev}${separator}${str}`;
    }, '');
}

export function snakeToCamelCase(str) {
    return str.replace(/_\D/g, match => match.charAt(1).toUpperCase());
}

export function upperCaseRatio(str) {
    return 1 - str.replace(/[A-Z ]/g, '').length / str.length;
}

const simplifyString = R.pipe(R.trim, R.replace('-', ' '), lowerCaseAndUnaccent);

export const fuzzyMatch = R.curry(
    (form1, form2) => simplifyString(form1) === simplifyString(form2)
);

export const slugify = str => {
    return R.pipe(unaccent, toLowerCase, R.replace(/ /g, '-'))(str);
};

export const isEmailValid = R.test(/^[^@]+@[^@]+\.[^@]+$/);
