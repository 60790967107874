import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import classnames from 'classnames';
import ProductImage from 'components/Sale/ProductImage.jsx';
import { Heading, Text } from 'components/ui';
import Separator from 'components/Separator.jsx';
import Legend from 'components/Legend.jsx';
import Avatar from 'components/Avatar.jsx';
import StartingAt from 'components/Offers/StartingAt.jsx';
import PriceAndPromotion from './PriceAndPromotion.jsx';
import connectToI18n from 'modules/i18n/connectToI18n';

export class VerticalProductCard extends React.Component {
    static propTypes = {
        transChoice: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        product: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string,
            offers: PropTypes.array,
            photoId: PropTypes.string,
            type: PropTypes.shape({
                id: PropTypes.number.isRequired,
                quantityStrategy: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        productName: PropTypes.string.isRequired,
        size: PropTypes.string,
        allProductTypesIndexedById: PropTypes.object.isRequired,
        addToCartButton: PropTypes.node.isRequired,
        formattedQuantities: PropTypes.string.isRequired,
        farmName: PropTypes.string.isRequired,
        displayProducer: PropTypes.bool,
        isMultiOffers: PropTypes.bool,
        isPromoted: PropTypes.bool,
        distributionDate: PropTypes.string,
    };

    render() {
        const {
            transChoice,
            product,
            allProductTypesIndexedById,
            displayProducer,
            productName,
            addToCartButton,
            formattedQuantities,
            size,
            farmName,
            onClick,
            isMultiOffers,
            isPromoted,
            distributionDate,
        } = this.props;

        return (
            <div
                className={classnames(
                    'productCard',
                    'verticalProductCard',
                    `verticalProductCard--${size}`
                )}
                onClick={onClick}
            >
                <ProductImage
                    className="verticalProductCard-image"
                    productCategoryId={R.prop(
                        'categoryId',
                        allProductTypesIndexedById[product.type.id]
                    )}
                    photoId={product.photoId}
                    labels={product.labels}
                    productTypeId={product.type.id}
                    resolution="medium"
                    size="medium"
                    productName={product.name}
                    distributionDate={distributionDate}
                />
                <div className="verticalProductCard-container">
                    <div className="verticalProductCard-nameContainer u-mb-1">
                        <Heading rank={5} className="productCard-name">
                            {productName}
                        </Heading>
                    </div>

                    <div className="verticalProductCard-body">
                        <div className="verticalProductCard-price-container">
                            {isMultiOffers && !isPromoted ? (
                                <StartingAt
                                    className="productCard-price"
                                    price={product.offers[0].price}
                                />
                            ) : (
                                <PriceAndPromotion
                                    className="compactProductCard-price"
                                    offer={product.offers[0]}
                                    reverseOnMobile
                                />
                            )}

                            {isMultiOffers ? (
                                <Legend className="productCard-offerCount">
                                    {transChoice('offers.count', product.offers.length, {
                                        '%count%': product.offers.length,
                                    })}
                                </Legend>
                            ) : (
                                <Legend className="productCard-quantity">
                                    {formattedQuantities}
                                </Legend>
                            )}
                        </div>
                        <div className="verticalProductCard-addToCart">{addToCartButton}</div>
                    </div>
                    {displayProducer && (
                        <div>
                            <Separator spacing="small" />
                            <div className="verticalProductCard-farm">
                                <Text className="verticalProductCard-farmName">{farmName}</Text>
                                <Avatar photoId={product.farm.photoId} rounded size="xsmall" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connectToI18n(VerticalProductCard);
