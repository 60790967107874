import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import useI18n from 'hooks/I18n/useI18n.js';
import { PatternFormat } from 'react-number-format';
import {
    dateObjectToDateString,
    dateObjectOneFieldToDateString,
    dateStringOneFieldToDateObject,
    dateStringToDateObject,
} from 'models/date';

const DateInputOneField = ({
    className,
    disabled,
    onChange,
    name,
    value,
    inputVariant,
    hasError,
}) => {
    const initialDate = dateObjectOneFieldToDateString(dateStringToDateObject(value));
    const { trans } = useI18n();

    const handleChange = e => {
        const newValue = e.target.value;

        onChange(dateObjectToDateString(dateStringOneFieldToDateObject(newValue)));
    };

    const inputClasses =
        inputVariant !== 'productIdentity'
            ? classnames('fa-input', {
                  'fa-input--error': hasError,
                  'fa-input--inverse': inputVariant === 'inverse',
              })
            : 'nd-input';

    return (
        <div className={classnames(className, 'dateInput')}>
            <PatternFormat
                format="## / ## / ####"
                placeholder={`${trans('placeholder.day')} / ${trans('placeholder.month')} / ${trans(
                    'placeholder.year'
                )}`}
                className={inputClasses}
                id={name}
                name={name}
                disabled={disabled}
                onChange={handleChange}
                value={initialDate}
            />
        </div>
    );
};

DateInputOneField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputVariant: PropTypes.oneOf(['inverse']),
    hasError: PropTypes.bool,
};

export default DateInputOneField;
