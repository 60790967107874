import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { getAddress } from 'app/scripts/components/geo/placeHelpers.js';
import { timeslotsSortedByDayOfWeek } from 'models/assembly';
import Address from 'components/Address.jsx';
import DeliveryTimeSlot, { normalizePickupTimeSlot } from 'components/DeliveryTimeSlot.jsx';

export class PickupPointSummary extends React.Component {
    static propTypes = {
        pickupPoint: PropTypes.object.isRequired,
        isAssembly: PropTypes.bool,
        className: PropTypes.string,
        trans: PropTypes.func.isRequired,
    };

    render() {
        const { pickupPoint, isAssembly, className, trans } = this.props;

        let timeSlots;
        let name;
        if (isAssembly) {
            timeSlots = timeslotsSortedByDayOfWeek(pickupPoint).filter(timeSlot => !!timeSlot.day);
            name = pickupPoint.place.name;
        } else {
            timeSlots = pickupPoint.timeSlots.map(normalizePickupTimeSlot);
            name = `${trans('pickups.miniAssembly')} - ${pickupPoint.name}`;
        }

        return (
            <div className={className}>
                {timeSlots.map(timeSlot => (
                    <div key={`${timeSlot.day}-${timeSlot.starting}`}>
                        <b className="u-text-capitalize">
                            <DeliveryTimeSlot timeSlot={timeSlot} />
                        </b>
                    </div>
                ))}
                <Address
                    name={name}
                    address={getAddress(isAssembly ? 'assembly' : 'pickup', pickupPoint)}
                />
            </div>
        );
    }
}

export default connectToI18n(PickupPointSummary);
