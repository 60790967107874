import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Text, Button } from 'components/ui';
import { Portal } from 'react-portal';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { toastsSelector, deleteToast, clearToasts } from 'modules/toasts';
import * as R from 'ramda';

const ToastsContainer = ({ className, toastClassName }) => {
    const toasts = useSelector(toastsSelector);
    const dispatch = useDispatch();

    const times = useRef({});

    useEffect(() => {
        R.map(toast => {
            if (!times.current[toast.id]) {
                times.current[toast.id] = setTimeout(() => {
                    dispatch(deleteToast(toast.id));
                }, toast.timeOut || 5000);
            }
        }, toasts);
    }, [toasts, dispatch, times]);

    useEffect(() => {
        const timesCurrent = times.current;
        return () => {
            R.map(time => {
                clearTimeout(time);
            }, timesCurrent);
            dispatch(clearToasts());
        };
    }, [dispatch]);

    const handleClick = toast => e => {
        e.preventDefault();
        if (toast.onClick && typeof toast.onClick === 'function') {
            toast.onClick();
        }
        dispatch(deleteToast(toast.id));
    };

    if (toasts.length < 0) {
        return null;
    }

    return (
        <Portal>
            <div className={classnames('toast-container', className)}>
                {toasts.map(toast => {
                    return (
                        <div className={classnames('toast', toastClassName)} key={toast.id}>
                            <Text className={classnames({ 'u-mr-2': toast.action })}>
                                {toast.content}
                            </Text>
                            {toast.action && (
                                <Button
                                    onClick={handleClick(toast)}
                                    weight="link"
                                    noPadding
                                    variant="primary"
                                >
                                    {toast.action}
                                </Button>
                            )}
                        </div>
                    );
                })}
            </div>
        </Portal>
    );
};

ToastsContainer.propTypes = {
    className: PropTypes.string,
    toastClassName: PropTypes.string,
};

export default ToastsContainer;
