import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const Tabs = ({ items }) => {
    const [currentKey, setCurrentKey] = useState(items.length > 0 ? items[0].key : null);
    return R.isEmpty(items) ? null : (
        <div className="tabs-wrapper">
            <div className="tabs-nav">
                {items.map(({ label, key }) => (
                    <div
                        key={key}
                        className={key === currentKey ? 'tab-nav-item-selected' : ''}
                        onClick={() => setCurrentKey(key)}
                    >
                        {label}
                    </div>
                ))}
            </div>
            <div className="tabs-content">
                {R.find(R.propEq('key', currentKey))(items).children}
            </div>
        </div>
    );
};

Tabs.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
            children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        })
    ),
};
Tabs.defaultProps = {
    items: [],
};
export default Tabs;
