import { get, post } from 'modules/api';
import reducerRegistry from '../../reducers/ReducerRegistry';
import * as R from 'ramda';

// actions
export const fetchUnreadMessages = () => (dispatch, getState) => {
    if (getState().privateConversations.unreadMessagesStatus === 'INIT') {
        dispatch({
            type: 'FETCH_UNREAD_MESSAGES_REQUEST',
        });
        get('private_conversations/unread')
            .then(R.when(R.isEmpty, R.always({})))
            .then(response => {
                dispatch({
                    type: 'FETCH_UNREAD_MESSAGES_SUCCESS',
                    payload: {
                        unreadMessages: response,
                    },
                });
            });
    }
};

export const markAllThreadsAsRead = () => dispatch => {
    dispatch({
        type: 'MARK_ALL_THREADS_AS_READ',
    });
};

export const markThreadAsRead = threadUuid => dispatch => {
    dispatch({
        type: 'MARK_THREAD_AS_READ',
        payload: {
            threadUuid,
        },
    });
    // we ignore success and error, as the UI will already have been updated,
    // and the UX would be awkward if the unread messages counters moved after that.
    post('private_conversations/mark_as_read', { threadUuid });
};

// reducers
const privateConversationsReducer = (
    state = {
        unreadMessages: {},
        allThreadsRead: false,
        unreadMessagesStatus: 'INIT',
    },
    action
) => {
    switch (action.type) {
        case 'FETCH_UNREAD_MESSAGES_REQUEST': {
            return {
                ...state,
                unreadMessagesStatus: 'FETCHING',
            };
        }
        case 'FETCH_UNREAD_MESSAGES_SUCCESS': {
            return {
                ...state,
                unreadMessages: action.payload.unreadMessages,
                unreadMessagesStatus: 'FETCHED',
            };
        }
        case 'MARK_ALL_THREADS_AS_READ': {
            return {
                ...state,
                allThreadsRead: true,
            };
        }
        case 'MARK_THREAD_AS_READ': {
            return R.evolve(
                {
                    unreadMessages: R.dissoc(action.payload.threadUuid),
                },
                state
            );
        }
        default:
            return state;
    }
};

// store
reducerRegistry.register({
    privateConversations: privateConversationsReducer,
});

export { unreadMessagesSelector, allThreadsReadSelector } from './selectors';
