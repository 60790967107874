import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const SMALL = 'small';
export const MEDIUM = 'medium';

const Text = ({ children, className, strong, warning, size, ...props }) => {
    const cls = classnames('nd-text', className, { strong, warning, [size]: size });
    return (
        <span {...props} className={cls}>
            {children}
        </span>
    );
};

Text.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    className: PropTypes.string,
    strong: PropTypes.bool,
    warning: PropTypes.bool,
    size: PropTypes.oneOf([SMALL, MEDIUM]),
};

Text.defaultProps = {
    className: '',
    strong: false,
    warning: false,
    size: MEDIUM,
};
export default Text;
