import PropTypes from 'prop-types';
import React from 'react';
import { toggle as togglePanel, closeAll as closeAllPanels } from 'modules/visiblePanel';
import Utils from 'modules/utils';
const { linkTo } = Utils;
import { Icon, List } from 'components/ui';
import giftIcon from 'icons/gift.svg';
import envelopeIcon from 'icons/envelope.svg';
import clockIcon from 'icons/clock.svg';
import profileIcon from 'icons/profile.svg';
import helpIcon from 'icons/help-circle.svg';
import speech from 'icons/speech.svg';
import newsfeed from 'icons/newsfeed.svg';
import { INFO } from 'modules/utils/colors';
import * as R from 'ramda';
import { HELP_PANEL, NEWSFEED_PANEL } from 'modules/visiblePanel.js';
import Badge from 'components/Badge.jsx';
import { Link } from 'react-router-dom';
import useI18n from 'hooks/I18n/useI18n.js';
import { useDispatch } from 'react-redux';
import { updateActiveLink } from 'modules/navigation';
import Backbone from 'backbone';

const LinkItem = ({ url, onClick, children, activeLink }) => {
    const dispatch = useDispatch();
    const handleClickLink = () => {
        dispatch(closeAllPanels());
        dispatch(updateActiveLink(activeLink));
        Backbone.history.navigate(url.substring(1), { trigger: false, replace: false });
    };

    if (R.isNil(url) && !R.isNil(onClick)) {
        return (
            <button className="userPanel-link bypass button--light" onClick={onClick}>
                {children}
            </button>
        );
    }
    return (
        <Link className="userPanel-link" to={url} onClick={handleClickLink}>
            {children}
        </Link>
    );
};

LinkItem.propTypes = {
    url: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
    activeLink: PropTypes.string,
};

const MenuItem = ({ url, icon, text, onClick, badgeValue, ...rest }) => (
    <li>
        <LinkItem url={url} onClick={onClick} {...rest}>
            <Icon src={icon} size="large" color={INFO} /> {text}
            {badgeValue > 0 && <Badge className="userPanel-link-badge">{badgeValue}</Badge>}
        </LinkItem>
    </li>
);

MenuItem.propTypes = {
    url: PropTypes.string,
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    badgeValue: PropTypes.number,
};

MenuItem.defaultProps = {
    badgeValue: 0,
};

const UserPanel = ({ currentUser, children, newsfeedUnreadCount = 0 }) => {
    const { trans } = useI18n();
    const dispatch = useDispatch();
    const handleClickSupport = () => dispatch(togglePanel(HELP_PANEL));
    const handleClickNewsfeed = () => dispatch(togglePanel(NEWSFEED_PANEL));

    const isFarmerOrLeader = currentUser.isFarmer || currentUser.isLeader;

    return (
        <div className="userPanel u-p-0">
            <div className={children && 'u-mb-6'}>
                <List className="u-mb-2">
                    <MenuItem
                        activeLink="menu.configuration"
                        url={'/configuration/account/identity'}
                        text={trans('user.account.title')}
                        icon={profileIcon}
                    />
                    <MenuItem
                        activeLink="menu.configuration"
                        url={'/configuration/account/orders'}
                        text={trans('navigation.user.orders')}
                        icon={clockIcon}
                    />
                    <MenuItem
                        activeLink="menu.messages"
                        url={'/messages'}
                        text={trans('menu.messages')}
                        icon={envelopeIcon}
                    />
                    <MenuItem
                        activeLink="feedback"
                        url={'/feedback'}
                        text={trans('navigation.user.feedbacks')}
                        icon={speech}
                    />
                    <MenuItem
                        activeLink="giftcards"
                        url={'/giftcards'}
                        text={trans('buyGiftCard.banner.userLink')}
                        icon={giftIcon}
                    />
                    <MenuItem
                        onClick={handleClickSupport}
                        icon={helpIcon}
                        text={trans('menu.admin.title')}
                    />

                    {isFarmerOrLeader && (
                        <MenuItem
                            text={trans('navigation.left.newsfeed')}
                            icon={newsfeed}
                            onClick={handleClickNewsfeed}
                            badgeValue={newsfeedUnreadCount}
                        />
                    )}
                </List>
                <a
                    className="button button--link button--noPadding button--primary userPanel-logoutButton"
                    href={linkTo('logout')}
                >
                    {trans('navigation.user.logout')}
                </a>
            </div>
            {children}
        </div>
    );
};

UserPanel.propTypes = {
    currentUser: PropTypes.object,
    children: PropTypes.node,
    newsfeedUnreadCount: PropTypes.number,
};

export default UserPanel;
