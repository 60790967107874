const config = require('modules/config');
require('modules/config/i18n');

// registration
config.addConfig(require('./registration'));

// map stuff
config.addConfig(require('modules/maps/config'));

// Overload basic sync for 'beforeSend' and 'complete' triggers + _fetch property on read
require('modules/base/sync').default(require('backbone'));

// jquery
window.$ = window.jQuery = require('jquery');
