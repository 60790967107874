// @sentry/browser
require('core-js/actual/object/assign');

// Array.from polyfill for es6.spread
require('core-js/actual/array/from');
// Map and Set for React
require('core-js/actual/map');
require('core-js/actual/set');
// Symbol for union-types for IE11 AND @babel/plugin-transform-react-inline-elements transform
require('core-js/actual/symbol');
// Array.find for react-sortable-hoc, and probably lots of other things
require('core-js/actual/array/find');
// Promise polyfill for @sentry/browser
require('core-js/actual/promise');
// isNaN polyfill for @sentry/browser
require('core-js/actual/number/is-nan');
// string.includes polyfill for @sentry/browser
require('core-js/actual/string/includes');
// string.at polyfill for our PaymentForm
require('core-js/actual/string/at');

// Create Element.remove() function if not exist
// polyfill for svg-inline-react, may be fixable via css
if (___BROWSER___ && !('remove' in Element.prototype)) {
    Element.prototype.remove = function() {
        if (this.parentNode) {
            this.parentNode.removeChild(this);
        }
    };
}

require('url-search-params-polyfill');

require('core-js/actual/string/pad-start');
