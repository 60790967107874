import * as R from 'ramda';

const addressAccessors = {
    assembly: R.path(['place', 'address']),
    farm: R.prop('address'),
    pickup: R.path(['meetingPoint', 'fullAddress']),
};

const lrqdoCoordinatesToLatLng = ({ latitude, longitude }) => ({
    lat: latitude,
    lng: longitude,
});

export const getAddress = R.curry((entityType, entity) => addressAccessors[entityType](entity));

export const getLatLng = R.curry((entityType, entity) => {
    if (entityType === 'pickup') {
        return lrqdoCoordinatesToLatLng(entity.meetingPoint.coordinates);
    }
    return R.pipe(getAddress(entityType), R.prop('coordinates'), lrqdoCoordinatesToLatLng)(entity);
});
