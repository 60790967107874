import React, { useCallback, useEffect, useMemo, useReducer, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { getAddressSuggestions } from 'models/mapbox';
import {
    FEATURE_STATUS_CONSTRUCT,
    FEATURE_STATUS_OPEN,
    FEATURE_TYPE_ASSEMBLY,
    FEATURE_TYPE_PICKUP,
} from 'modules/maps/mapboxGL/clusteredMap';
import useI18n from 'hooks/I18n/useI18n';
import useDebounce from 'hooks/Utils/useDebounce';
import useResponsive from 'hooks/Navigation/useResponsive';
import { AssembliesMapModalNavContext } from 'components/Maps/ProductIdentity/AssembliesMap/AssembliesMapModalNavigactionContext.jsx';
import assembliesMapReducer, {
    ASSEMBLIES_AND_PICKUPS_LOADED,
    CHANGE_PRIMARY_FILTER,
    MARKER_SELECTED,
    OPEN_MOBILE_FILTERS_BUTTON_CLICKED,
    FILTER_MOBILE_MODAL_CLOSE_BUTTON_CLICKED,
    TOGGLE_ONLY_OPEN_SALE_FILTER,
    APPLY_FILTERS_BUTTON_CLICKED,
    ASSEMBLY_INFORMATIONS_MOBILE_MODAL_CLOSE_BUTTON_CLICKED,
    MAP_CLICKED,
    SUGGESTIONS_UPDATED,
    SUGGESTION_CLICKED,
    AUTOCOMPLETE_FIELD_VALUE_CHANGED,
    AUTOCOMPLETE_BLUR,
    PRIMARY_FILTER_ALL,
    PRIMARY_FILTER_HOME_DELIVERY,
    getInitialState,
} from './assembliesMapReducer.js';
import AssembliesMap from './AssembliesMap.jsx';
import AssembliesMapModal from './Modal/AssembliesMapModal.jsx';
import AssemblyWithOpenDistributions from './Modal/AssemblyWithOpenDistributions.jsx';
import AssemblyWithoutAnyOpenDistributions from './Modal/AssemblyWithoutAnyOpenDistribution.jsx';
import ConstructAssembly from './Modal/ConstructAssembly.jsx';
import MapFooter from './MapFooter.jsx';
import MobileTopBar from './Modal/MobileTopBar.jsx';
import MobileFiltersModal from './Modal/MobileFiltersModal.jsx';
import MobileAssemblyInformationsModal from './Modal/MobileAssemblyInformationsModal.jsx';
import Pickup from './Modal/Pickup.jsx';

export const AssembliesMapContainer = ({ assemblies, pickups, prefilledFullAddress }) => {
    const { i18n } = useI18n();
    const autocompleteRef = useRef(null);
    const isSmallWidth = useResponsive();
    const [state, dispatch] = useReducer(
        assembliesMapReducer,
        getInitialState(prefilledFullAddress)
    );
    const { goNext, goBack, resetNavigation, getCurrentItem } = useContext(
        AssembliesMapModalNavContext
    );
    const currentItem = useMemo(() => getCurrentItem(), [getCurrentItem]);

    useEffect(() => {
        if (!assemblies.length && !pickups.length) {
            return;
        }

        dispatch({
            type: ASSEMBLIES_AND_PICKUPS_LOADED,
            payload: { assemblies, pickups },
        });
    }, [assemblies, pickups]);

    useEffect(() => {
        autocompleteRef.current.value = state.autocompleteFieldValue;
    }, [isSmallWidth, state.autocompleteFieldValue]);

    const filteredPickups = useMemo(() => {
        return state.primaryFilter === PRIMARY_FILTER_ALL ? state.pickups : [];
    }, [state.pickups, state.primaryFilter]);

    const filteredAssemblies = useMemo(() => {
        return state.assemblies.filter(assembly => {
            const onlyOpenSaleBool = state.onlyOpenSaleFilter
                ? !!assembly.openedDistributions
                : true;
            let primaryFilterBool = true;

            if (state.primaryFilter === PRIMARY_FILTER_HOME_DELIVERY) {
                primaryFilterBool = assembly.hasHomeDelivery;
            }

            return onlyOpenSaleBool && primaryFilterBool;
        });
    }, [state.assemblies, state.onlyOpenSaleFilter, state.primaryFilter]);

    const toggleOnlyOpenSaleFilter = useCallback(() => {
        dispatch({ type: TOGGLE_ONLY_OPEN_SALE_FILTER });
    }, []);

    const changePrimaryFilter = useCallback(filter => {
        dispatch({ type: CHANGE_PRIMARY_FILTER, payload: filter });
    }, []);

    const openMobileFilterModal = useCallback(() => {
        dispatch({ type: OPEN_MOBILE_FILTERS_BUTTON_CLICKED });
    }, []);

    const closeMobileFilterModal = useCallback(() => {
        dispatch({ type: FILTER_MOBILE_MODAL_CLOSE_BUTTON_CLICKED });
    }, []);

    const closeMobileAssemblyInformationsModal = useCallback(() => {
        dispatch({ type: ASSEMBLY_INFORMATIONS_MOBILE_MODAL_CLOSE_BUTTON_CLICKED });
    }, []);

    const handleFiltersChanges = useCallback((onlyOpenSaleFilter, primaryFilter) => {
        dispatch({
            type: APPLY_FILTERS_BUTTON_CLICKED,
            payload: { onlyOpenSaleFilter, primaryFilter },
        });
    }, []);

    const handleMapClick = useCallback(() => {
        resetNavigation();
        dispatch({ type: MAP_CLICKED });
    }, [resetNavigation]);

    const handleMarkerSelect = useCallback(
        markerProperties => {
            let properties;

            if (markerProperties.type === FEATURE_TYPE_PICKUP) {
                properties = state.pickups.find(pickup => pickup.uuid === markerProperties.id);
            } else {
                properties = state.assemblies.find(assembly => assembly.id === markerProperties.id);
            }
            resetNavigation({
                type: markerProperties.type,
                properties,
            });
            dispatch({
                type: MARKER_SELECTED,
                payload: {
                    type: markerProperties.type,
                    properties,
                },
            });
        },
        [state.pickups, state.assemblies, resetNavigation]
    );

    const panToCoordinates = useMemo(() => {
        return state.panToCoordinates;
    }, [state.panToCoordinates]);

    const debouncedSearchRequest = useDebounce(() => {
        const params = {
            query: state.autocompleteFieldValue,
            hitsPerPage: 5,
            language: i18n.split('-')[0],
        };

        getAddressSuggestions(params)
            .then(hits => {
                dispatch({
                    type: SUGGESTIONS_UPDATED,
                    payload: hits.map(hit => ({
                        text: hit.place_name,
                        metadata: {
                            id: hit.id,
                            geoloc: {
                                lat: hit._geoloc.lat,
                                lng: hit._geoloc.lng,
                            },
                        },
                    })),
                });
            })
            .catch(() => {
                dispatch({ type: SUGGESTIONS_UPDATED, payload: [] });
            });
    });

    const handleAutocompleteChange = useCallback(
        e => {
            dispatch({ type: AUTOCOMPLETE_FIELD_VALUE_CHANGED, payload: e.target.value });

            debouncedSearchRequest();
        },
        [debouncedSearchRequest]
    );

    const handleAutocompleteBlur = useCallback(() => {
        dispatch({ type: AUTOCOMPLETE_BLUR });
    }, []);

    const handleSuggestionClick = useCallback(suggestion => {
        dispatch({
            type: SUGGESTION_CLICKED,
            payload: {
                coordinates: [suggestion.metadata.geoloc.lng, suggestion.metadata.geoloc.lat],
                fieldValue: suggestion.text,
            },
        });
    }, []);

    const enabledFilterCount = useMemo(() => {
        let count = 0;

        if (state.onlyOpenSaleFilter) {
            count++;
        }

        if (state.primaryFilter !== PRIMARY_FILTER_ALL) {
            count++;
        }

        return count;
    }, [state.onlyOpenSaleFilter, state.primaryFilter]);

    const selectedItemIsAnOpenedAssembly =
        currentItem?.type === FEATURE_TYPE_ASSEMBLY &&
        currentItem.properties.status === FEATURE_STATUS_OPEN;
    const selectedItemIsAConstructAssembly =
        currentItem?.type === FEATURE_TYPE_ASSEMBLY &&
        currentItem.properties.status === FEATURE_STATUS_CONSTRUCT;
    const selectedItemIsAPickup = currentItem?.type === FEATURE_TYPE_PICKUP;

    const onClickNearbyAssembly = useCallback(
        nextAssembly => {
            const isAssemblyType = !!state.assemblies.find(({ id }) => id === nextAssembly.id);
            const payload = {
                properties: nextAssembly,
                type: isAssemblyType ? 'assembly' : 'pickup',
            };
            goNext(payload);
            currentItem &&
                nextAssembly.id !== currentItem.properties.id &&
                dispatch({
                    type: SUGGESTION_CLICKED,
                    payload: {
                        coordinates: [
                            nextAssembly.place.address.coordinates.longitude,
                            nextAssembly.place.address.coordinates.latitude,
                        ],
                        fieldValue: '',
                    },
                });
            dispatch({
                type: MARKER_SELECTED,
                payload,
            });
        },
        [goNext, state.assemblies, currentItem]
    );

    const modalGoBack = useCallback(() => {
        const nextAssembly = goBack();
        currentItem &&
            nextAssembly.properties.id !== currentItem.properties.id &&
            dispatch({
                type: SUGGESTION_CLICKED,
                payload: {
                    coordinates: [
                        nextAssembly.properties.place.address.coordinates.longitude,
                        nextAssembly.properties.place.address.coordinates.latitude,
                    ],
                    fieldValue: '',
                },
            });
        dispatch({
            type: MARKER_SELECTED,
            payload: nextAssembly,
        });
    }, [goBack, currentItem]);

    return (
        <div className="pi-map-container">
            {isSmallWidth && (
                <>
                    <MobileTopBar
                        filterEnabledCount={enabledFilterCount}
                        onFilterClick={openMobileFilterModal}
                        autoCompleteRef={autocompleteRef}
                        autocompleteSuggestions={state.autocompleteSuggestions}
                        onAutocompleteChange={handleAutocompleteChange}
                        onAutocompleteBlur={handleAutocompleteBlur}
                        onSuggestionClick={handleSuggestionClick}
                    />
                    <MobileFiltersModal
                        isOnlyOpenSaleFilterEnabled={state.onlyOpenSaleFilter}
                        onFiltersChange={handleFiltersChanges}
                        selectedPrimaryFilter={state.primaryFilter}
                        opened={state.isMobileFilterModalOpened}
                        closeModal={closeMobileFilterModal}
                    />
                    <MobileAssemblyInformationsModal
                        opened={state.isMobileAssemblyInformationsModalOpened}
                        selectedItem={currentItem}
                        closeModal={closeMobileAssemblyInformationsModal}
                        assemblies={state.assemblies}
                        onClickNearbyAssembly={onClickNearbyAssembly}
                        goBack={modalGoBack}
                    />
                </>
            )}
            {!isSmallWidth && (
                <AssembliesMapModal
                    isOnlyOpenSaleFilterEnabled={state.onlyOpenSaleFilter}
                    onToggleOnlyOpenSaleFilter={toggleOnlyOpenSaleFilter}
                    onChangePrimaryFilter={changePrimaryFilter}
                    selectedPrimaryFilter={state.primaryFilter}
                    opened={!!currentItem}
                    autocompleteRef={autocompleteRef}
                    autocompleteSuggestions={state.autocompleteSuggestions}
                    onAutocompleteChange={handleAutocompleteChange}
                    onAutocompleteBlur={handleAutocompleteBlur}
                    onSuggestionClick={handleSuggestionClick}
                    goBack={modalGoBack}
                >
                    {selectedItemIsAnOpenedAssembly &&
                        !!currentItem.properties?.openedDistributions && (
                            <AssemblyWithOpenDistributions assembly={currentItem.properties} />
                        )}
                    {selectedItemIsAnOpenedAssembly &&
                        !currentItem.properties?.openedDistributions && (
                            <AssemblyWithoutAnyOpenDistributions
                                assembly={currentItem.properties}
                                assemblies={state.assemblies}
                                onClickNearbyAssembly={onClickNearbyAssembly}
                            />
                        )}
                    {selectedItemIsAConstructAssembly && (
                        <ConstructAssembly assembly={currentItem.properties} />
                    )}
                    {selectedItemIsAPickup && <Pickup pickup={currentItem.properties} />}
                </AssembliesMapModal>
            )}
            <AssembliesMap
                panToCoordinates={panToCoordinates}
                assemblies={filteredAssemblies}
                pickups={filteredPickups}
                onMarkerSelect={handleMarkerSelect}
                onMapClick={handleMapClick}
                isSelectedMarkerLayerVisible={state.isSelectedMarkerLayerVisible}
                currentAssembly={currentItem}
            />
            <MapFooter />
        </div>
    );
};

AssembliesMapContainer.propTypes = {
    assemblies: PropTypes.array.isRequired,
    pickups: PropTypes.array.isRequired,
    prefilledFullAddress: PropTypes.string,
};

export default AssembliesMapContainer;
