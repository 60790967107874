import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import PropTypes from 'prop-types';
import { getLocaleShortform, getLocaleByCode } from 'modules/utils/locales';
import { Button, Wrapper, Grid } from 'components/ui';
import PageHeader from 'components/PageHeader.jsx';
import { TextInputGroup, CheckboxGroup } from 'components/ui/form';

export class ValidateSCU extends React.Component {
    static propTypes = {
        trans: PropTypes.func.isRequired,
        type: PropTypes.string.isRequired,
        i18n: PropTypes.string.isRequired,
        isAccepted: PropTypes.bool,
        onAccept: PropTypes.func.isRequired,
        onPhoneChange: PropTypes.func.isRequired,
        onSubscribe: PropTypes.func.isRequired,
        phoneNumber: PropTypes.string.isRequired,
    };

    getTexts = () => {
        const trans = this.props.trans;
        switch (this.props.type) {
            case 'host':
                return {
                    text: trans('user.becomeLeaderText'),
                    action: trans('user.becomeLeader'),
                };
            case 'producer':
                return {
                    text: trans('user.becomeProviderText'),
                    action: trans('user.becomeProvider'),
                };
            default:
                return {
                    text: trans('user.validateSCUText'),
                    action: trans('user.validateSCU'),
                };
        }
    };

    render() {
        const { trans, isAccepted, onAccept, onPhoneChange, onSubscribe, phoneNumber } = this.props;
        const localeCode = getLocaleShortform(getLocaleByCode(this.props.i18n));
        const url = `/${localeCode}/p/terms`;
        const { text, action } = this.getTexts();
        const canSubmit = phoneNumber && isAccepted;

        return (
            <Wrapper>
                <PageHeader title={action} />
                <Grid xs=".10" sm="...6">
                    <div className="validateSCU">
                        <p>{text}</p>
                        <TextInputGroup
                            name="phone"
                            inputVariant="inverse"
                            label={trans('global.phone')}
                            isRequired
                            onChange={onPhoneChange}
                            value={phoneNumber}
                        />
                        <CheckboxGroup
                            id="hasAcceptedSCU"
                            isChecked={isAccepted}
                            onChange={onAccept}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: trans('user.scu', { '%url%': url }),
                                }}
                            />
                        </CheckboxGroup>
                        <Button variant="primary" block onClick={onSubscribe} disabled={!canSubmit}>
                            {action}
                        </Button>
                    </div>
                </Grid>
            </Wrapper>
        );
    }
}

export default connectToI18n(ValidateSCU);
