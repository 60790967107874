function isColumnPattern(pattern) {
    return /^\.*\d+$/.test(pattern);
}

function isListPattern(pattern) {
    return /\|/.test(pattern);
}

function isRepeatPattern(pattern) {
    return /^\.*\d+\+$/.test(pattern);
}

function parseColumnPattern(pattern) {
    if (!isColumnPattern(pattern)) {
        return null;
    }
    return {
        offset: (pattern.match(/\./g) && pattern.match(/\./g).length) || 0,
        width: parseInt(pattern.match(/\d+/), 10),
    };
}

export function parsePattern(pattern) {
    if (isRepeatPattern(pattern)) {
        return {
            repeatedColumn: parseColumnPattern(pattern.replace('+', '')),
        };
    }

    if (isListPattern(pattern)) {
        return {
            columns: pattern.split('|').map(parseColumnPattern),
        };
    }

    return {
        columns: [parseColumnPattern(pattern)],
    };
}

export const patternsToBreakpoints = function(lg, md, sm, xs) {
    const result = [];

    if (lg) {
        result.push({
            size: 'lg',
            ...parsePattern(lg),
        });
    }

    if (md) {
        result.push({
            size: 'md',
            ...parsePattern(md),
        });
    }

    if (sm) {
        result.push({
            size: 'sm',
            ...parsePattern(sm),
        });
    }

    if (xs) {
        result.push({
            size: 'xs',
            ...parsePattern(xs),
        });
    }

    return result;
};
