import PropTypes from 'prop-types';
import React from 'react';
import AssemblyName from 'components/AssemblyName.jsx';
import Address from 'components/Address.jsx';
import DeliveryTimeSlot from 'components/DeliveryTimeSlot.jsx';
import { Grid, Legend, Button } from 'components/ui';
import { timeslotsSortedByDayOfWeek } from 'models/assembly';
import connectToI18n from 'modules/i18n/connectToI18n';

export class AssemblySummary extends React.PureComponent {
    static propTypes = {
        assembly: PropTypes.object.isRequired,
        i18n: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
    };

    render() {
        const { assembly, i18n, trans } = this.props;

        return (
            <Grid xs="12|6|6|12" md="3+" className="assemblySummary">
                <AssemblyName assembly={assembly} className="u-mb-2 u-mb-md-0" />
                <div className="u-mb-2 u-mb-md-0">
                    <Legend>{trans('global.address')}</Legend>
                    <Address address={assembly.place.address} />
                </div>
                <div className="u-mb-2 u-mb-md-0">
                    <Legend>{trans('producerPage.salespoints.timeSlots')}</Legend>
                    {timeslotsSortedByDayOfWeek(assembly).map((timeSlot, index) => (
                        /* eslint-disable react/no-array-index-key */
                        <div className="u-text-capitalize" key={index}>
                            <DeliveryTimeSlot timeSlot={timeSlot} />
                        </div>
                    ))}
                </div>
                <div className="u-mb-2 u-mb-md-0">
                    <Button
                        variant="primary"
                        weight="link"
                        href={`/${i18n}/assemblies/${assembly.id}`}
                        block
                    >
                        {trans('global.seeAssembly')}
                    </Button>
                </div>
            </Grid>
        );
    }
}

export default connectToI18n(AssemblySummary);
