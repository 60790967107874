import React from 'react';
import PropTypes from 'prop-types';
import PIText from 'components/ProductIdentity/Text.jsx';
import Text from './Text.jsx';

const Paragraph = props => {
    const { productIdentity } = props;
    const TextComponent = productIdentity ? PIText : Text;
    return <TextComponent {...props} tagName="p" />;
};

Paragraph.propTypes = {
    productIdentity: PropTypes.bool,
};
Paragraph.defaultProps = {
    productIdentity: false,
};
export default Paragraph;
