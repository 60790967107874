import React from 'react';
import AppChannel from 'modules/channels/App';
import { clearUserAndToken } from 'models/users.js';
import RedirectToLogin from 'components/Routes/RedirectToLogin.jsx';

const Logout = () => {
    clearUserAndToken();
    AppChannel.vent.trigger('logout');
    return <RedirectToLogin />;
};

export default Logout;
