import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import Utils from 'modules/utils';
const { apiLinkTo, getPrice } = Utils;
import { getLocalizedDate } from 'modules/utils/dateAndTime';
import Box from 'components/Box.jsx';
import Grid from 'components/Grid.jsx';
import Heading from 'components/Heading.jsx';
import Legend from 'components/Legend.jsx';
import Text from 'components/Text.jsx';
import Button from 'components/Button.jsx';

export class DistributionInformation extends React.Component {
    static propTypes = {
        distribution: PropTypes.shape({
            distributionDate: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            invoicesGenerated: PropTypes.bool.isRequired,
            orderTotal: PropTypes.shape({
                amount: PropTypes.number.isRequired,
                currency: PropTypes.string.isRequired,
            }),
            withdrawalAmount: PropTypes.shape({
                amount: PropTypes.number.isRequired,
                currency: PropTypes.string.isRequired,
            }),
            withdrawalSendingDate: PropTypes.string.isRequired,
        }),
        i18n: PropTypes.string.isRequired,
        queryParameters: PropTypes.object.isRequired,
        trans: PropTypes.func.isRequired,
    };

    render() {
        const { distribution, i18n, queryParameters, trans } = this.props;

        return (
            <Box className="u-mb-4">
                <Grid xs="12|6|6|12" sm="4|2|2|4">
                    <Heading className="u-mb-4 u-mb-sm-0" size={4}>
                        {trans('assembly.archives.distributionDate', {
                            '%date%': getLocalizedDate(i18n, 'i', distribution.distributionDate),
                        })}
                    </Heading>
                    <div>
                        <Text size="large">{getPrice(distribution.orderTotal)}</Text>
                        <Legend>{trans('assembly.archives.orderTotal')}</Legend>
                    </div>
                    <div className="u-mb-4 u-mb-sm-0">
                        <Text size="large">{getPrice(distribution.withdrawalAmount)}</Text>
                        <Legend>
                            {trans('distributions.transfer.sentOn', {
                                '%date%': getLocalizedDate(
                                    i18n,
                                    'd',
                                    distribution.withdrawalSendingDate
                                ),
                            })}
                        </Legend>
                    </div>

                    {distribution.invoicesGenerated ? (
                        <div className="u-center">
                            <Button
                                block
                                bypassRouter
                                href={apiLinkTo(
                                    `distributions/${distribution.id}/invoice.pdf{?q*}`,
                                    {
                                        hash: {
                                            q: queryParameters,
                                        },
                                    }
                                )}
                            >
                                {trans('distribution.actions.downloadInvoices')}
                            </Button>
                        </div>
                    ) : (
                        <Text color="info" italic>
                            {trans('assembly.archives.invoiceNotReadyYet')}
                        </Text>
                    )}
                </Grid>
            </Box>
        );
    }
}

export default connectToI18n(DistributionInformation);
