import { createSelector } from 'reselect';
import * as R from 'ramda';

export const farmSelector = state => state.currentUser.user.farm;
export const userSelector = state => state.currentUser.user;
export const hivesAsLeaderSelector = state =>
    R.pathOr([], ['currentUser', 'user', 'hivesAsLeader'], state);
export const hivesAsMemberSelector = state =>
    R.pathOr([], ['currentUser', 'user', 'hivesAsMember'], state);
export const hivesAsFarmerSelector = state =>
    R.pathOr([], ['currentUser', 'user', 'hivesAsFarmer'], state);

export const firstFarmSelector = createSelector(farmSelector, farms =>
    farms && !R.isEmpty(farms) ? R.head(farms) : {}
);

export const farmIdSelector = createSelector(farmSelector, farms =>
    farms && !R.isEmpty(farms) ? R.head(farms).id : 0
);

export const getAssemblyByIdSelector = hiveIdData =>
    createSelector(hivesAsLeaderSelector, hivesAsMemberSelector, (hiveAsLeader, hiveAsMember) => {
        const findHiveById = R.find(R.propEq('id', parseInt(hiveIdData, 10)));
        return findHiveById(hiveAsLeader) || findHiveById(hiveAsMember) || null;
    });

export const getAssemblyAsFarmerById = assemblyId =>
    createSelector(hivesAsFarmerSelector, assemblies =>
        R.find(R.propEq('id', assemblyId))(assemblies)
    );

export const farmCompanyIdSelector = createSelector(firstFarmSelector, farm => {
    if (!farm) {
        return null;
    }

    if (farm.company) {
        return farm.company.id;
    }

    if (farm._links && farm._links.company) {
        return farm._links.company.id;
    }

    return null;
});
