import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { addSearchResultToBasket, getSearchString, searchProducts } from 'modules/productsSearch';
import { getProducts, isFetchingSearchResults } from 'modules/productsSearch';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import ProductCard from 'components/Sale/ProductCard.jsx';
import { getCurrentSearchId } from 'modules/productsSearch';
import { editBasketOfferQuantity } from 'modules/orders';
import { getPriceOfOfferFromProducts } from 'models/products';
import { SEARCH_RESULTS } from 'modules/originUtils.js';

const propTypes = {
    addSearchResultToBasket: PropTypes.func.isRequired,
    allProductTypesIndexedById: PropTypes.object.isRequired,
    assemblyId: PropTypes.number.isRequired,
    currentSearchId: PropTypes.string,
    distributionId: PropTypes.number.isRequired,
    editBasketOfferQuantity: PropTypes.func.isRequired,
    isFetchingSearchResults: PropTypes.bool.isRequired,
    searchedProducts: PropTypes.array,
    searchString: PropTypes.string.isRequired,
    trans: PropTypes.func.isRequired,
    isSmallWidth: PropTypes.bool,
    onClickOnProduct: PropTypes.func.isRequired,
};

export class SearchResults extends React.Component {
    addSearchedProductOfferToBasket = (offerId, quantity = 1, actionOrigin) => {
        const searchQueryId = this.props.currentSearchId;
        const orderItemId = undefined;
        this.props.editBasketOfferQuantity(
            this.props.distributionId,
            offerId,
            quantity,
            actionOrigin,
            orderItemId,
            searchQueryId
        );
        const offerPriceAmount = getPriceOfOfferFromProducts(this.props.searchedProducts, offerId);
        this.props.addSearchResultToBasket(this.props.distributionId, offerId, offerPriceAmount);
    };

    render() {
        const {
            assemblyId,
            allProductTypesIndexedById,
            distributionId,
            searchedProducts,
            searchString,
            trans,
            isSmallWidth,
            onClickOnProduct,
        } = this.props;
        if (this.props.isFetchingSearchResults) {
            return (
                <div className="saleSearchResults">
                    <LoadingAnimation type="spinner" />
                </div>
            );
        }
        if (searchedProducts.length === 0 && searchString.length > 0) {
            return (
                <div className="saleSearchResults">
                    <p>{trans('salePage.searchNoResults', { '%searchString%': searchString })}</p>
                    <p>{trans('salePage.searchTryNew')}</p>
                </div>
            );
        }
        return (
            <div className="saleSearchResults">
                {searchedProducts.map(product => (
                    <ProductCard
                        addOfferToBasket={this.addSearchedProductOfferToBasket}
                        assemblyId={assemblyId}
                        allProductTypesIndexedById={allProductTypesIndexedById}
                        className="u-mb-4"
                        disableLinksToFarms
                        distributionId={distributionId}
                        farm={product.farm}
                        isLabelDescriptionAvailable={false}
                        isSmallWidth={isSmallWidth}
                        key={product.id}
                        noImageBackgroundColor
                        product={product}
                        displayProducer
                        actionOrigin={SEARCH_RESULTS}
                        onClick={() => onClickOnProduct(product.id)}
                    />
                ))}
            </div>
        );
    }
}

SearchResults.propTypes = propTypes;

function mapStateToProps(state, { distributionId }) {
    return {
        currentSearchId: getCurrentSearchId(state, { distributionId }),
        isFetchingSearchResults: isFetchingSearchResults(state, { distributionId }),
        searchedProducts: getProducts(state, { distributionId }),
        searchString: getSearchString(state, { distributionId }),
    };
}

export default connect(mapStateToProps, {
    addSearchResultToBasket,
    editBasketOfferQuantity,
    searchProducts,
})(connectToI18n(SearchResults));
