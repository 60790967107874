import React from 'react';
import PropTypes from 'prop-types';

import useI18n from 'hooks/I18n/useI18n';

import Heading from 'components/Heading.jsx';
import MediaObject from 'components/MediaObject.jsx';
import Avatar from 'components/Avatar.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import Link from 'src/components/atoms/Link/Link.jsx';
import Utils from 'modules/utils';
const { linkTo } = Utils;
import { MEMBER } from 'modules/utils/userTypes';

const HelpCenterContactCard = props => {
    const { onMessage, header, label, phone, email, photo, userType, hostId } = props;
    const { trans } = useI18n();
    return (
        <div className="help-center-contact-card">
            {header && (
                <Heading productIdentity size={4} family="inter">
                    {header}
                </Heading>
            )}
            <MediaObject media={<Avatar size="xmedium" photoId={photo ? photo.id : null} />}>
                <Text size="14px" lineHeight="20px" color="grey8">
                    {label}
                </Text>
                <Text size="14px" lineHeight="20px" color="grey8">
                    {phone}
                </Text>
                <span className="helpCenter-messageLink">
                    {userType === MEMBER ? (
                        <Link
                            className="helpCenter-messageLink-text"
                            onClick={onMessage}
                            href={linkTo(`messages/contact/${hostId}`)}
                            bypassRouter
                        >
                            <Text size="14px" lineHeight="20px">
                                {trans('helpCenter.contact.send.message')}
                            </Text>
                        </Link>
                    ) : (
                        <Link
                            className="helpCenter-messageLink-text"
                            href={`mailto:${email}`}
                            data-bypass="true"
                            bypassRouter
                        >
                            <Text size="14px" lineHeight="20px">
                                {email}
                            </Text>
                        </Link>
                    )}
                </span>
            </MediaObject>
        </div>
    );
};
HelpCenterContactCard.propTypes = {
    onMessage: PropTypes.func,
    header: PropTypes.string,
    label: PropTypes.string,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string,
    photo: PropTypes.object,
    userType: PropTypes.string.isRequired,
    hostId: PropTypes.number,
};
export default HelpCenterContactCard;
