import * as R from 'ramda';
import { userSelector } from 'modules/currentUser';
import { useSelector } from 'react-redux';

const CompanyIdProvider = ({ userType, assemblyId, children }) => {
    const user = useSelector(userSelector);
    let companyId;
    let assembly;
    if (userType === 'producer') {
        companyId = R.path(['farm', '0', '_links', 'company', 'id'], user);
    } else if (userType === 'host') {
        assembly = R.find(R.propEq('id', assemblyId), user.hivesAsLeader);
        companyId = assembly.company.id;
    }
    if (companyId) {
        return children({ companyId, assembly, user });
    }
    throw new Error('Wrong user type or did not find the company');
};

export default CompanyIdProvider;
