import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InlineSVG from 'svg-inline-react';
import { useSelector } from 'react-redux';

import { filerPhoto } from 'modules/utils/filer';
import { userSelector } from 'modules/currentUser';
import { capitalizeFirstLetter } from 'modules/utils/string';
import { getIsoWeekday, getLocalizedDay, getLocalizedTime } from 'modules/utils/dateAndTime';

import { getSocialNetworks } from 'models/assembly';
import { getAddressParts } from 'models/pickup';
import useI18n from 'hooks/I18n/useI18n';

import Text from 'components/ProductIdentity/Text.jsx';
import { SECONDARY_MODE, XXSMALL_SIZE } from 'src/components/atoms/Button/Button.jsx';
import Link, { DISPLAY_MODES } from 'src/components/atoms/Link/Link.jsx';

import InfoBox from './InfoBox.jsx';

import checklistIcon from 'icons/pi/checklist.svg';
import heartIcon from 'icons/pi/heart.svg';
import markerIcon from 'icons/pi/marker.svg';
import personIcon from 'icons/pi/person.svg';
import facebookIcon from 'icons/pi/socialNetworks/facebook.svg';
import blogIcon from 'icons/pi/socialNetworks/blog.svg';
import instagramIcon from 'icons/pi/socialNetworks/instagram.svg';
import whatsappIcon from 'icons/pi/socialNetworks/whatsapp.svg';
import xIcon from 'icons/pi/socialNetworks/x.svg';

const ICONS_NAME_TO_SVG = {
    'icon-edit': blogIcon,
    'icon-facebook': facebookIcon,
    'icon-camera-retro': instagramIcon,
    'icon-twitter': xIcon,
    'icon-whatsapp': whatsappIcon,
};

const ASSEMBLY_ENTITY_TYPE = 'assembly';
const PICKUP_ENTITY_TYPE = 'pickup';

const SideInfos = ({ assembly, pickups, onShowPlaceInMapBtnClick, nearbyAssemblies }) => {
    const { i18n, trans, transChoice } = useI18n();
    const currentUser = useSelector(userSelector);
    const assemblyAddress = assembly.place.address;
    const assemblyCity = assemblyAddress.city;
    const socialNetworks = getSocialNetworks(assembly, true);
    const { deliveryTimeSlot, extraDeliveryTimeSlot } = assembly;
    const hasExtraDeliveryTimeSlot = !!extraDeliveryTimeSlot;

    return (
        <div className="pi-assembly-side-infos">
            {currentUser.anonymous && assembly.status === 'open' && (
                <InfoBox
                    className="pi-assembly-side-infos-stay-in-touch"
                    headingText={trans('anonymous.registration.push.title')}
                >
                    <Text size="14px" lineHeight="20px">
                        {trans('anonymous.registration.push.text')}
                    </Text>
                    <Link
                        href={`/${i18n}/join`}
                        className={classNames(SECONDARY_MODE, XXSMALL_SIZE)}
                        bypassRouter
                        displayMode={DISPLAY_MODES.BUTTON}
                    >
                        {trans('assembly.follow')}
                    </Link>
                </InfoBox>
            )}
            {deliveryTimeSlot && (
                <InfoBox
                    headingText={trans('assemblyPage.products.pickUp')}
                    headingIcon={markerIcon}
                    footerLinkText={trans('global.seeOnMap')}
                    footerLinkOnClick={() => {
                        onShowPlaceInMapBtnClick(assembly, ASSEMBLY_ENTITY_TYPE);
                    }}
                >
                    <>
                        <Text size="14px" lineHeight="20px" className="assembly-name">
                            {assembly.place.name}
                        </Text>
                        <Text size="14px" lineHeight="20px">
                            {assemblyAddress.street}
                        </Text>
                        <Text size="14px" lineHeight="20px">
                            {assemblyCity.zipCode} {assemblyCity.name}
                        </Text>
                        <Text size="14px" lineHeight="20px" className="distribution-slot">
                            {trans('assembly.distributionTimeWithDay', {
                                '%weekday%': capitalizeFirstLetter(
                                    getLocalizedDay(i18n, deliveryTimeSlot.day)
                                ),
                                '%startHour%': getLocalizedTime(i18n, deliveryTimeSlot.starting),
                                '%endHour%': getLocalizedTime(i18n, deliveryTimeSlot.ending),
                            })}
                        </Text>
                    </>
                </InfoBox>
            )}
            {hasExtraDeliveryTimeSlot && extraDeliveryTimeSlot && (
                <InfoBox
                    headingText={trans('assemblyPage.products.pickUp')}
                    headingIcon={markerIcon}
                    footerLinkText={trans('global.seeOnMap')}
                    footerLinkOnClick={() => {
                        onShowPlaceInMapBtnClick(assembly, ASSEMBLY_ENTITY_TYPE);
                    }}
                >
                    <>
                        <Text size="14px" lineHeight="20px" className="assembly-name">
                            {assembly.place.name}
                        </Text>
                        <Text size="14px" lineHeight="20px">
                            {assemblyAddress.street}
                        </Text>
                        <Text size="14px" lineHeight="20px">
                            {assemblyCity.zipCode} {assemblyCity.name}
                        </Text>
                        <Text size="14px" lineHeight="20px" className="distribution-slot">
                            {trans('assembly.distributionTimeWithDay', {
                                '%weekday%': capitalizeFirstLetter(
                                    getLocalizedDay(i18n, extraDeliveryTimeSlot.day)
                                ),
                                '%startHour%': getLocalizedTime(
                                    i18n,
                                    extraDeliveryTimeSlot.starting
                                ),
                                '%endHour%': getLocalizedTime(i18n, extraDeliveryTimeSlot.ending),
                            })}
                        </Text>
                    </>
                </InfoBox>
            )}
            {pickups.map(pickup => {
                const { uuid, name, meetingPoint, timeSlots } = pickup;
                const { streetAndNumber, zipcodeAndCity } = getAddressParts(
                    meetingPoint.fullAddress
                );

                return (
                    <InfoBox
                        key={uuid}
                        headingText={trans('assemblyPage.products.pickUp')}
                        headingIcon={markerIcon}
                        footerLinkText={trans('global.seeOnMap')}
                        footerLinkOnClick={() => {
                            onShowPlaceInMapBtnClick(pickup, PICKUP_ENTITY_TYPE);
                        }}
                    >
                        <>
                            <Text size="14px" lineHeight="20px" className="assembly-name">
                                {name}
                            </Text>
                            <Text size="14px" lineHeight="20px">
                                {streetAndNumber}
                            </Text>
                            <Text size="14px" lineHeight="20px">
                                {zipcodeAndCity}
                            </Text>
                            {timeSlots.map(timeSlot => (
                                <Text
                                    key={`${timeSlot.dayOfWeek}_${timeSlot.timeStart}_${timeSlot.timeEnd}`}
                                    size="14px"
                                    lineHeight="20px"
                                    className="distribution-slot"
                                >
                                    {trans('assembly.distributionTimeWithDay', {
                                        '%weekday%': capitalizeFirstLetter(
                                            getLocalizedDay(i18n, getIsoWeekday(timeSlot.dayOfWeek))
                                        ),
                                        '%startHour%': getLocalizedTime(i18n, timeSlot.timeStart),
                                        '%endHour%': getLocalizedTime(i18n, timeSlot.timeEnd),
                                    })}
                                </Text>
                            ))}
                        </>
                    </InfoBox>
                );
            })}
            <InfoBox
                headingText={trans('user.roles.host')}
                headingIcon={checklistIcon}
                footerLinkText={!currentUser.anonymous && trans('global.contacting')}
                footerLinkHref={
                    !currentUser.anonymous && `/${i18n}/messages/contact/${assembly.leader.id}`
                }
                bypassRouter
                className="assembly-host-block"
            >
                <>
                    <img src={filerPhoto(assembly.leader.photo?.id, 'small', 'user')} />
                    <div className="assembly-host-infos">
                        <Text size="14px" lineHeight="20px">
                            {assembly.leader.firstName}
                        </Text>
                        {!currentUser.anonymous && (
                            <Text size="14px" lineHeight="20px">
                                {assembly.leader.phone}
                            </Text>
                        )}
                    </div>
                </>
            </InfoBox>
            {!!assembly.countMembers && (
                <InfoBox
                    headingText={trans('hiveHome.sideInfos.community.title')}
                    headingIcon={personIcon}
                    footerLinkText={trans('hives.pageHeaderAsMember.inviteMembers')}
                    footerLinkHref={trans('hives.pageHeaderAsMember.inviteMembers.link', {
                        '%assemblyId%': assembly.id,
                    })}
                >
                    <Text size="14px" lineHeight="20px">
                        {transChoice(
                            'hives.members.list.asLeader.title',
                            parseInt(assembly.countMembers, 10).toLocaleString()
                        )}
                    </Text>
                </InfoBox>
            )}
            {currentUser.anonymous && nearbyAssemblies?.length > 0 && (
                <InfoBox
                    headingText={trans('public.assembly.header.link.assemblies')}
                    headingIcon={markerIcon}
                    footerLinkText={trans('public.assembly.seeAllAssemblies')}
                    footerLinkHref={
                        currentUser.anonymous ? `/assemblies` : `${assembly.id}/assemblies`
                    }
                >
                    {transChoice('farm.profile.assemblies.title', nearbyAssemblies.length)}
                </InfoBox>
            )}
            {socialNetworks.length > 0 && (
                <InfoBox
                    headingText={trans('hives.presentation.socialNetworks')}
                    headingIcon={heartIcon}
                    className="assembly-networks-block"
                >
                    <>
                        {socialNetworks.map(({ name, url, icon }) => (
                            <a
                                key={name}
                                target="_blank"
                                href={url}
                                title={name}
                                data-bypass="true"
                            >
                                <InlineSVG src={ICONS_NAME_TO_SVG[icon]} />
                            </a>
                        ))}
                    </>
                </InfoBox>
            )}
        </div>
    );
};

SideInfos.propTypes = {
    assembly: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        leader: PropTypes.shape({
            id: PropTypes.number.isRequired,
            firstName: PropTypes.string.isRequired,
            phone: PropTypes.string,
            photo: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }),
        }).isRequired,
        countMembers: PropTypes.number,
        place: PropTypes.shape({
            address: PropTypes.shape({
                city: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    zipCode: PropTypes.string.isRequired,
                }).isRequired,
                street: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    pickups: PropTypes.arrayOf(
        PropTypes.shape({
            uuid: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            meetingPoint: PropTypes.shape({
                fullAddress: PropTypes.string.isRequired,
                coordinates: PropTypes.shape({
                    latitude: PropTypes.number.isRequired,
                    longitude: PropTypes.number.isRequired,
                }).isRequired,
            }).isRequired,
            timeSlots: PropTypes.arrayOf(
                PropTypes.shape({
                    dayOfWeek: PropTypes.string.isRequired,
                    timeStart: PropTypes.string.isRequired,
                    timeEnd: PropTypes.string.isRequired,
                })
            ),
        })
    ).isRequired,
    onShowPlaceInMapBtnClick: PropTypes.func.isRequired,
    nearbyAssemblies: PropTypes.array,
};
SideInfos.defaultProps = { nearbyAssemblies: [] };
export default SideInfos;
