import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import InlineSVG from 'svg-inline-react';

export class Tooltip extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        pointer: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
        pointerPosition: PropTypes.oneOf([1, 2, 3]),
        variant: PropTypes.oneOf(['tutorial']),
        animation: PropTypes.oneOf(['slide', 'fadeIn']),
        closeButton: PropTypes.bool,
        show: PropTypes.func,
        hide: PropTypes.func,
    };

    static defaultProps = {
        pointer: 'bottom',
        pointerPosition: 2,
        animation: 'fadeIn',
        variant: null,
        closeButton: false,
    };

    render() {
        const { pointer, pointerPosition, className, variant, animation, closeButton } = this.props;

        const popoverClasses = {
            [`faTooltip--${pointer}`]: pointer,
            [`faTooltip--pointer${pointerPosition}`]: pointerPosition,
            [`faTooltip--${variant}`]: variant,
            [`faTooltip--${animation}`]: animation,
        };

        return (
            <div
                className={classNames(
                    'faTooltip',
                    'ignore-react-onclickoutside',
                    'productIdentity',
                    popoverClasses,
                    className
                )}
                onMouseEnter={this.props.show}
                onMouseLeave={this.props.hide}
            >
                {closeButton && (
                    <InlineSVG
                        src={require('icons/close-thin.svg')}
                        className="svgIcon faTooltip-close"
                        width="16"
                        height="16"
                    />
                )}
                <div className="faTooltip-inner">{this.props.children}</div>
            </div>
        );
    }
}

export default Tooltip;
