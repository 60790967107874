import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { getSaleEndDateInfo } from 'models/distributions';
import { getLocalizedDate } from 'modules/utils/dateAndTime';

export class DistributionDate extends React.Component {
    static propTypes = {
        showLastDayWarning: PropTypes.bool,
        isMember: PropTypes.bool,
        distribution: PropTypes.object.isRequired,
        trans: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
    };

    static defaultProps = {
        showLastDayWarning: true,
        isMember: false,
    };

    render() {
        const { showLastDayWarning, isMember, distribution, trans, i18n } = this.props;

        const dateInfo = getSaleEndDateInfo(distribution);

        if (!dateInfo.isLastTwoDays) {
            const openedUntil = getLocalizedDate(i18n, 'I', dateInfo.saleEndDay);
            return (
                <span>
                    {trans('distributions.openedUntil', {
                        '%date%': openedUntil,
                    })}
                </span>
            );
        }

        let closingIn;

        if (dateInfo.isLastDay) {
            closingIn = isMember ? dateInfo.closingInMember : dateInfo.closingInReal;
        } else {
            closingIn = trans('global.tomorrow');
        }

        const closingInStr = trans('distributions.closingAt', {
            '%date%': closingIn,
        });
        return (
            <span>
                {closingInStr}{' '}
                {dateInfo.isLastDay && showLastDayWarning === true && (
                    <span className="lastSaleDayWarning">{trans('global.lastDay')}</span>
                )}
            </span>
        );
    }
}

export default connectToI18n(DistributionDate);
