import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useI18n from 'hooks/I18n/useI18n';
import Link, { DISPLAY_MODES } from 'src/components/atoms/Link/Link.jsx';
import Text from 'src/components/atoms/Text/Text.jsx';
import DistributionSlot from './DistributionSlot.jsx';
import HostCard from './HostCard.jsx';
import Handhover from './Handover.jsx';

export const AssemblyWithOpenDistributions = ({ assembly }) => {
    const { i18n, trans } = useI18n();
    const [selectedDistributionId, setSelectedDistributionId] = useState(null);

    useEffect(() => {
        assembly.openedDistributions.sort((distribA, distribB) => {
            return distribA.timeStart > distribB.timeStart ? 1 : -1;
        });

        setSelectedDistributionId(assembly.openedDistributions[0].id);
    }, [assembly]);

    const assemblyAddress = assembly.place.address;

    return (
        <>
            <div className="map-assembly-and-distribution-block">
                <HostCard
                    url={`/${i18n}/assemblies/${assembly.id}`}
                    photoUuid={assembly.host.photoUuid}
                    cityName={assemblyAddress.city.name}
                    pointOfSaleName={assembly.name}
                    street={assemblyAddress.street}
                    hostFirstname={assembly.host.firstname}
                />
                <div className="map-available-distribution-slot-container pi-typo">
                    {assembly.openedDistributions.map(distribution => (
                        <DistributionSlot
                            key={distribution.id}
                            distribution={distribution}
                            selected={selectedDistributionId === distribution.id}
                            onSlotClick={setSelectedDistributionId}
                        />
                    ))}
                    {assembly.isHandover && <Handhover hiveId={assembly.id} />}
                </div>
            </div>
            <div className="go-to-sale-btn-container">
                <Link
                    href={`/${i18n}/assemblies/${assembly.id}/collections/${selectedDistributionId}/products`}
                    className={classnames({ disabled: !selectedDistributionId })}
                    displayMode={DISPLAY_MODES.BUTTON}
                    bypassRouter
                >
                    <Text>{trans('assembliesMap.modal.goToSaleBtn')}</Text>
                </Link>
            </div>
        </>
    );
};

AssemblyWithOpenDistributions.propTypes = {
    assembly: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        host: PropTypes.shape({
            photoUuid: PropTypes.string,
            firstname: PropTypes.string.isRequired,
        }).isRequired,
        place: PropTypes.shape({
            address: PropTypes.shape({
                street: PropTypes.string.isRequired,
                city: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
        }).isRequired,
        openedDistributions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                timeStart: PropTypes.string.isRequired,
                timeEnd: PropTypes.string.isRequired,
                hasHomeDelivery: PropTypes.bool.isRequired,
            })
        ),
    }),
};

export default AssemblyWithOpenDistributions;
