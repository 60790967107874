function memoizeLast(keyFn, fnToMemoize) {
    let key;
    let cache;

    return function(...args) {
        const newKey = keyFn(...args);

        if (key !== newKey) {
            key = newKey;
            cache = fnToMemoize(...args);
        }

        return cache;
    };
}

export { memoizeLast as default, memoizeLast };
