export default function getClientId() {
    const getClientIdPromise = new Promise(resolve => {
        if (window.ga) {
            window.ga(function(tracker) {
                resolve(tracker.get('clientId'));
            });
        } else {
            resolve('');
        }
    });
    // If the browser uses an extension to desactivate Google Analytics, then
    // the getClientIdPromise is never resolved.
    // Promise.race and the timeout will then be resolved first, and then it will
    // go further with an empty string as clientId.
    const timeOutPromise = new Promise(resolve => {
        setTimeout(resolve, 100, '');
    });
    return Promise.race([getClientIdPromise, timeOutPromise]);
}
