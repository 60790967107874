import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { getLocalizedDateWithMonthDayWithoutYear } from 'modules/utils/dateAndTime';
import { capitalizeFirstLetter } from 'modules/utils/string';
import useI18n from 'hooks/I18n/useI18n';
import Text, { SMALL } from 'src/components/atoms/Text/Text.jsx';

export const PickupSlot = ({
    assemblyCity,
    assemblyName,
    timeStart,
    timeEnd,
    onSlotClick,
    selected = false,
}) => {
    const { i18n, trans } = useI18n();

    return (
        <div
            className={classnames('assembly-distribution-slot', { selected })}
            onClick={() => onSlotClick()}
        >
            <Text className="pickup-slot-date">
                {trans('assembly.distributionTimeWithDay', {
                    '%weekday%': capitalizeFirstLetter(
                        getLocalizedDateWithMonthDayWithoutYear(i18n, moment(timeStart).toDate())
                    ),
                    '%startHour%': moment(timeStart).format('LT'),
                    '%endHour%': moment(timeEnd).format('LT'),
                })}
            </Text>
            <Text size={SMALL}>
                {trans('assembliesMap.pickupSlot.productFromAssembly', {
                    '%assemblyCity%': assemblyCity,
                    '%assemblyName%': assemblyName,
                })}
            </Text>
        </div>
    );
};

PickupSlot.propTypes = {
    assemblyCity: PropTypes.string.isRequired,
    assemblyName: PropTypes.string.isRequired,
    timeStart: PropTypes.string.isRequired,
    timeEnd: PropTypes.string.isRequired,
    onSlotClick: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
};

export default PickupSlot;
