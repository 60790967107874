import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useI18n from 'hooks/I18n/useI18n.js';

import connectToI18n from 'modules/i18n/connectToI18n';
import { linkTo } from 'modules/utils';
import { DANGER, MAROON, SUCCESS } from 'modules/utils/colors';
import { formatPrice } from 'modules/utils/currency';

import { getDistributionById } from 'models/distributions';

import { getPaymentsHistory } from 'api/onsite-sales';

import { Box, Button, Grid, Heading, LoadingAnimation, Text, Wrapper } from 'components/ui';
import PageHeader from 'components/PageHeader.jsx';

const History = ({ farmId, match }) => {
    const { i18n, trans } = useI18n();
    const [loading, setLoading] = useState(true);
    const [currentDistribution, setCurrentDistribution] = useState(null);
    const [history, setHistory] = useState(null);

    useEffect(() => {
        Promise.all([
            getDistributionById(match.params.distributionId),
            getPaymentsHistory({ distributionId: match.params.distributionId, farmId }),
        ]).then(([distribution, payments]) => {
            setLoading(false);
            setCurrentDistribution(distribution);
            setHistory(payments);
        });
    }, [match.params.distributionId, farmId]);

    const getPaymentStatusLabelColor = status => {
        if (status === 'failed') {
            return DANGER;
        }

        if (status === 'pending') {
            return MAROON;
        }

        return SUCCESS;
    };

    const getPaymentStatusLabelText = status => {
        if (status === 'failed') {
            return trans('global.paymentFailed');
        }
        if (status === 'pending') {
            return trans('global.pending');
        }
        return trans('global.paymentSuccess');
    };

    const getPaymentStatusLabelWeight = status => {
        if (status === 'failed') {
            return 'bold';
        }
        if (status === 'pending') {
            return 'normal';
        }
        return 'bold';
    };

    return (
        <div>
            <PageHeader
                backButtonIconOnly
                backHref={linkTo('/producers/onsite-sales')}
                background="default"
                title={
                    currentDistribution
                        ? `${currentDistribution.assemblyCity} - ${currentDistribution.assemblyName}`
                        : ''
                }
            />
            <Wrapper xsPadding>
                <Button
                    block
                    className="u-mb-4"
                    href={`/producers/onsite-sales/amount/${match.params.distributionId}`}
                    variant="success"
                >
                    {trans('onsite_sales.newSale')}
                </Button>
                <Heading className="u-mb-2" size={2}>
                    {trans('onsite_sales.paymentsHistory')}
                </Heading>
                {loading && <LoadingAnimation type="spinner" />}
                {!loading &&
                    history &&
                    history.length > 0 &&
                    history.map(payment => (
                        <Box className="u-mb-1" key={payment.uuid} padding="small">
                            <Grid xs="9|3|9|3|6|6">
                                <Text color="info" size="small">
                                    {trans('global.order')} {payment.order_number}
                                </Text>
                                <Text className="u-right" color="info" size="small">
                                    {new Intl.DateTimeFormat(i18n, {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                    }).format(new Date(payment.created_at))}
                                </Text>
                                <Text weight="bold">{payment.buyer_name}</Text>
                                <Text className="u-right" weight="bold">
                                    {formatPrice(i18n, payment.amount / 100, payment.currency)}
                                </Text>
                                {payment.payment_link_channel === 'SMS' ? (
                                    <div>{payment.phone}</div>
                                ) : (
                                    <div>{payment.email}</div>
                                )}
                                <div className="u-flexible-row u-flexible-row--pullRight u-right">
                                    <Text
                                        color={getPaymentStatusLabelColor(payment.status)}
                                        weight={getPaymentStatusLabelWeight(payment.status)}
                                    >
                                        {getPaymentStatusLabelText(payment.status)}
                                    </Text>
                                </div>
                            </Grid>
                        </Box>
                    ))}
                {!loading && history && history.length === 0 && (
                    <Text>{trans('onsite_sales.noSales')}</Text>
                )}
            </Wrapper>
        </div>
    );
};

History.propTypes = {
    farmId: PropTypes.number.isRequired,
    match: PropTypes.object.isRequired,
};

export default connectToI18n(History);
