import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export class Banner extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        variant: PropTypes.oneOf(['success', 'error']),
        rounded: PropTypes.bool,
        fixed: PropTypes.bool,
    };

    render() {
        const { className, variant, rounded, fixed } = this.props;

        const classes = classnames('banner', className, {
            [`banner--${variant}`]: variant,
            'banner--rounded': rounded,
            'banner--fixed': fixed,
        });

        return <div className={classes}>{this.props.children}</div>;
    }
}

export default Banner;
