import PropTypes from 'prop-types';
import React from 'react';
import { propType as colorPropType } from 'modules/utils/colors';
import Icon from 'components/Icon.jsx';
import classnames from 'classnames';

const propTypes = {
    className: PropTypes.string,
    color: colorPropType,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    size: PropTypes.oneOf([
        'xxxx-large',
        'xxx-large',
        'xx-large',
        'x-large',
        'large',
        'medium',
        'small',
    ]),
};

const defaultProps = {
    size: 'medium',
};

export const IconButton = props => (
    <button className={classnames('iconButton', props.className)} onClick={props.onClick}>
        <Icon src={props.icon} color={props.color} size={props.size} />
    </button>
);

IconButton.propTypes = propTypes;
IconButton.defaultProps = defaultProps;

export default IconButton;
