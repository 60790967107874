import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'modules/api';
import connectToI18n from 'modules/i18n/connectToI18n';
import Utils from 'modules/utils';
const { linkTo } = Utils;
import { READY, INITIAL_LOADING } from 'modules/utils/ajaxStatuses';
import { LoadingAnimation, Grid, Button } from 'components/ui';
import { convertRawTextToHtml, asWysiwygHtml } from 'modules/utils/text';
import { getLocalizedDate } from 'modules/utils/dateAndTime';
import LimitOverflowingContent from 'components/LimitOverflowingContent.jsx';
import DistributionDate from 'components/DistributionDate.jsx';
import { addBypassRouterToLink } from 'modules/utils/text.js';
import * as R from 'ramda';

export class FeaturedDistribution extends React.Component {
    static propTypes = {
        distributionId: PropTypes.number.isRequired,
        onlyDistribution: PropTypes.bool,
        trans: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
        hideSaleOpenMessage: PropTypes.bool,
    };

    state = {
        distribution: null,
        status: INITIAL_LOADING,
    };

    componentDidMount() {
        get(`distributions/${this.props.distributionId}?view=flat`).then(distribution => {
            this.setState({ distribution, status: READY });
        });
    }

    render() {
        if (this.state.status === INITIAL_LOADING) {
            return <LoadingAnimation type="spinner" />;
        }

        const { trans, i18n, onlyDistribution, hideSaleOpenMessage } = this.props;
        const { distribution } = this.state;

        return (
            <div className="box box-distribution-message">
                <div
                    className="box-header"
                    style={{ backgroundImage: `url(${distribution.illustrationImage})` }}
                >
                    <Grid sm="7|5">
                        <p
                            className="title"
                            dangerouslySetInnerHTML={{
                                __html: trans('distributions.ofDay', {
                                    '%date%': getLocalizedDate(
                                        i18n,
                                        'I',
                                        distribution.distributionDate
                                    ),
                                }),
                            }}
                        />
                        <Button
                            variant="success"
                            href={linkTo(
                                `assemblies/${distribution.hiveId}/collections/${distribution.id}/products`
                            )}
                            className="pull-right btn-chooseProducts"
                            extended
                        >
                            {trans('distributions.chooseProducts')}
                        </Button>
                    </Grid>
                    <Grid sm="12">
                        <p className="date">
                            <DistributionDate distribution={distribution} showLastDayWarning />
                        </p>
                    </Grid>
                </div>
                {!hideSaleOpenMessage && !R.isNil(distribution.saleOpenMessage) && (
                    <LimitOverflowingContent
                        className="box-content"
                        background="white"
                        collapsible
                        maxHeight={onlyDistribution ? '15em' : '4.8em'}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: asWysiwygHtml(distribution.saleOpenMessage)
                                    ? addBypassRouterToLink(distribution.saleOpenMessage)
                                    : convertRawTextToHtml(distribution.saleOpenMessage),
                            }}
                        />
                    </LimitOverflowingContent>
                )}
            </div>
        );
    }
}

export default connectToI18n(FeaturedDistribution);
