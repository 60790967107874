import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

import useI18n from 'hooks/I18n/useI18n.js';
import useResponsive from 'hooks/Navigation/useResponsive';

import { userSelector } from 'modules/currentUser';
import { changeDeliveryOption } from 'modules/checkout/actions.pickup';
import { changeSelectedPickup } from 'modules/delivery';
import { getLocalizedDate } from 'modules/utils/dateAndTime';
import { DELIVERY_OFFER } from 'modules/utils/deliveryOptions';

import { getDeliveryOptionInfo } from 'models/delivery';

import { Heading } from 'components/ui';
import Text from 'components/ProductIdentity/Text.jsx';
import SelectDelivery from 'components/Checkout/ProductIdentity/SelectDelivery.jsx';
import LayoutCheckoutPayment from 'components/Checkout/Anonymous/LayoutCheckoutPayment.jsx';
import { STEP_PICKUP } from 'components/Checkout/ProductIdentity/CheckoutTimeline.jsx';
import TermsFooter from 'components/ProductIdentity/TermsFooter.jsx';

const SelectDeliveryContainer = ({
    deliveryOptions,
    selectedOption,
    onDeliveryFormSubmit,
    basket,
    basketPrices,
}) => {
    const user = useSelector(userSelector);
    const isSmallWidth = useResponsive();
    const isFetchingBasket = useSelector(state => state.checkout.isFetchingBasket);
    const methods = useForm({
        defaultValues: {
            firstName: user?.firstName,
            lastName: user?.lastName,
            street: user?.address?.street,
            city: user?.address?.city.name,
            country: user?.address?.country.name,
            phoneNumber: user?.phone,
            zipCode: user?.address?.city.zipCode,
            additionalInformation: user?.address?.additionalInformation,
        },
    });

    const {
        handleSubmit,
        formState: { isValid },
    } = methods;
    const { trans, i18n } = useI18n();
    const dispatch = useDispatch();

    const handleDeliveryOptionSelected = useCallback(
        deliveryOption => {
            dispatch(changeDeliveryOption(basket.distribution.id, deliveryOption));
            dispatch(changeSelectedPickup(basket.distribution.id, deliveryOption));
        },
        [dispatch, basket]
    );
    const disabledSummaryButton = useMemo(() => {
        const { type } = getDeliveryOptionInfo(selectedOption);
        return type === DELIVERY_OFFER && !isValid;
    }, [isValid, selectedOption]);

    const onSubmit = useCallback(
        formData => {
            const { type, ref } = getDeliveryOptionInfo(selectedOption);
            onDeliveryFormSubmit({
                type,
                ref,
                address: formData,
            });
        },
        [onDeliveryFormSubmit, selectedOption]
    );

    return isFetchingBasket ? null : (
        <div className="pi-delivery-page">
            <FormProvider {...methods}>
                <LayoutCheckoutPayment
                    deliveryAmount={basketPrices.shippingFees.amount}
                    finalAmount={basketPrices.totalPrice.amount}
                    amount={basket?.totalPriceWithoutShipping?.amount || 0}
                    onClick={handleSubmit(onSubmit)}
                    disabled={disabledSummaryButton}
                    currency={basket.totalPrice.currency}
                    buttonLabel={trans('deliveryPage.summary.continue')}
                    currentStep={STEP_PICKUP}
                    distributionId={basket.distribution.id}
                    assemblyId={basket.distribution.hiveId}
                >
                    <div className="pi-delivery-container">
                        <Heading className="pi-delivery-title" rank={2} size={4} productIdentity>
                            {trans('selectDelivery.title')}
                        </Heading>
                        <Text
                            className="pi-delivery-date"
                            bold
                            color="gray2"
                            size="16px"
                            lineHeight="24px"
                        >
                            {getLocalizedDate(
                                i18n,
                                'ddd Do MMM',
                                basket.distribution.distributionDate
                            )}
                        </Text>
                        <SelectDelivery
                            order={basket}
                            pickups={deliveryOptions}
                            selectedOption={selectedOption}
                            onDeliveryOptionSelected={handleDeliveryOptionSelected}
                            distributionDate={basket.distribution.distributionDate}
                        />
                        {isSmallWidth && <TermsFooter />}
                    </div>
                </LayoutCheckoutPayment>
            </FormProvider>
            {!isSmallWidth && <TermsFooter />}
        </div>
    );
};

SelectDeliveryContainer.propTypes = {
    basket: PropTypes.object.isRequired,
    deliveryOptions: PropTypes.array.isRequired,
    selectedOption: PropTypes.string.isRequired,
    basketPrices: PropTypes.object,
    onDeliveryFormSubmit: PropTypes.func.isRequired,
};

export default SelectDeliveryContainer;
