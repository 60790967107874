import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import useI18n from 'hooks/I18n/useI18n';
import useResponsive from 'hooks/Navigation/useResponsive';

import { cancellationReasons } from 'models/orders';

import { getOrderEntity, cancelOrder, isOrderFailed as isCancelOrderFailed } from 'modules/orders';

import Text from 'components/ProductIdentity/Text.jsx';
import Select from 'src/components/atoms/Select/Select.jsx';
import Button, { SMALL_SIZE } from 'src/components/atoms/Button/Button.jsx';
import Modal from 'src/components/organisms/Modal/Modal.jsx';

const CancelContainerModal = ({ orderId, onClose, show }) => {
    const {
        register,
        watch,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    const { trans } = useI18n();
    const history = useHistory();
    const isSmallWidth = useResponsive();
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const order = useSelector(state => getOrderEntity(state, { orderId }));
    const isFailed = useSelector(state => isCancelOrderFailed(state, { orderId }));

    const selectProps = register('cancellationReason');
    const cancellationReason = watch('cancellationReason');

    const goToStep2 = useCallback(() => {
        if (cancellationReason === '') {
            setError('cancellationReason', { msg: 'order.cancellation.reason.error.missing' });
            return;
        }
        dispatch(cancelOrder(order, cancellationReason));
        setStep(2);
    }, [cancellationReason, order, setError, dispatch]);

    const backToBasket = useCallback(
        () =>
            history.push(
                `/assemblies/${order.distribution.hiveId}/collections/${order.distribution.id}/products`
            ),
        [order.distribution, history]
    );

    return (
        <Modal
            withFixedHeight={isSmallWidth}
            isMounted={show}
            closeModal={onClose}
            title={trans('order.cancellation.title')}
        >
            <div className="basket-modal-cancel-order-container">
                {step === 1 && (
                    <form onSubmit={handleSubmit(goToStep2)}>
                        <div className="basket-modal-content">
                            <Text size="16px" lineHeight="24px" color="gray2">
                                {trans('order.cancellation.confirm.message1')}
                            </Text>
                            <Text size="16px" lineHeight="24px" color="gray2">
                                {trans('order.cancellation.confirm.message2')}
                            </Text>
                            <Text size="16px" lineHeight="24px" color="gray2">
                                {trans('order.cancellation.reason.question')}
                            </Text>
                            <div>
                                <Select
                                    id="cancellationReason"
                                    {...selectProps}
                                    options={cancellationReasons.map(reason => {
                                        return {
                                            value: reason,
                                            label: trans(reason),
                                        };
                                    })}
                                    placeholder={trans('order.cancellation.reason.instruction')}
                                    defaultValue=""
                                />
                                {errors?.cancellationReason && (
                                    <div className="nd-input-error-msg">
                                        {trans(errors.cancellationReason.msg)}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="basket-modal-footer">
                            <Button type="submit" onClick={goToStep2} size={SMALL_SIZE}>
                                {trans('global.continue')}
                            </Button>
                        </div>
                    </form>
                )}
                {step === 2 && !isFailed && (
                    <>
                        <div className="basket-modal-content">
                            <Text size="16px" lineHeight="24px" color="gray2">
                                {trans('basket.modal.cancelOrder.confirmMessage1')}
                            </Text>
                            <Text size="16px" lineHeight="24px" color="gray2">
                                {trans('basket.modal.cancelOrder.confirmMessage2')}
                            </Text>
                        </div>
                        <div className="basket-modal-footer">
                            <Button onClick={backToBasket} size={SMALL_SIZE}>
                                {trans('basket.backToSale')}
                            </Button>
                        </div>
                    </>
                )}
                {step === 2 && isFailed && (
                    <>
                        <div className="basket-modal-content">
                            <Text size="16px" lineHeight="24px" color="gray2">
                                {trans('global.error.retryMessage')}
                            </Text>
                        </div>
                        <div className="basket-modal-footer">
                            <Button onClick={onClose} size={SMALL_SIZE}>
                                {trans('global.close')}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};
CancelContainerModal.propTypes = {
    orderId: PropTypes.number,
    show: PropTypes.bool,
    onClose: PropTypes.func, // Propagate by Modal component
};
export default CancelContainerModal;
