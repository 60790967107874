import React from 'react';
import useI18n from 'hooks/I18n/useI18n';
import Text, { SMALL } from 'src/components/atoms/Text/Text.jsx';

export const HiveJoinedConfirmation = () => {
    const { trans } = useI18n();

    return (
        <div className="hive-was-joined-block">
            <img src="/assets/images/assembly_greenflag.svg" />
            <Text className="hive-was-joined-text" size={SMALL}>
                {trans('assembliesMap.modal.hiveWasJoined.itHasBeenDone')}
            </Text>
            <Text size={SMALL}>{trans('assembliesMap.modal.hiveWasJoined.nextStep')}</Text>
        </div>
    );
};

export default HiveJoinedConfirmation;
