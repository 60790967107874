import React from 'react';
import SaleBanner from './SaleBanner.jsx';
import useI18n from 'hooks/I18n/useI18n.js';

const CoronavirusBanner = ({ isSmallWidth }) => {
    const { trans } = useI18n();
    const text = {
        smartphone: {
            text: 'coronavirus.banner.text.and.link',
        },
        desktop: {
            title: 'coronavirus.banner.title',
            text: 'coronavirus.banner.text',
        },
    };

    return (
        <SaleBanner
            url={trans('coronavirus.banner.text.link')}
            isSmallWidth={isSmallWidth}
            illustration="/assets/images/illustrations/bird.svg"
            backgroundColor="#afe1e1"
            color="#17465d"
            text={text}
        />
    );
};

export default CoronavirusBanner;
