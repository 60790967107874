import React, { useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import InlineSVG from 'svg-inline-react';
import classNames from 'classnames';

import { getPriceOfOfferFromProducts } from 'models/products';

import { SEARCH_RESULTS } from 'modules/originUtils.js';
import {
    getSearchString as getSearchStringSelector,
    getProducts as getProductsSelector,
    isFetchingSearchResults as isFetchingSearchResultsSelector,
    getCurrentSearchId as getCurrentSearchIdSelector,
    addSearchResultToBasket as addSearchResultToBasketSelector,
    clearSearch as clearSearchSelector,
} from 'modules/productsSearch';
import { editBasketOfferQuantity as editBasketOfferQuantitySelector } from 'modules/orders';

import useI18n from 'hooks/I18n/useI18n';
import useResponsive from 'hooks/Navigation/useResponsive';

import ProductCard, { MODE_SEARCH } from 'components/Sale/ProductIdentity/ProductCard.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import Icon from 'components/Icon.jsx';
import Input, { SMALL_SIZE } from 'src/components/atoms/Input/Input.jsx';

import searchNavIcon from 'app/assets/new-design/images/icons/search.svg';
import cross from 'src/components/atoms/Icons/svg/cross.svg';

const SearchModal = ({
    onBlur,
    onChange,
    distributionId,
    searchInputContainerRef,
    simpleMemberTopLeftMenuRef,
    simpleMemberTopRightMenuRef,
    deliveryInfosRef,
    categoryId,
}) => {
    const { trans } = useI18n();
    const isSmallWidth = useResponsive();

    const history = useSelector(state => state.routerHistory.sale);
    const dispatch = useDispatch();
    const isFetchingSearchResults = useSelector(state =>
        isFetchingSearchResultsSelector(state, { distributionId })
    );
    const getCurrentSearchId = useSelector(state =>
        getCurrentSearchIdSelector(state, { distributionId })
    );
    const getProducts = useSelector(state => getProductsSelector(state, { distributionId }));
    const getSearchString = useSelector(state =>
        getSearchStringSelector(state, { distributionId })
    );
    const searchModalRef = useRef();
    const resultRef = useRef();
    const inputSearchMobileRef = useRef();
    const [isResultScroll, setIsResultScroll] = useState(false);
    const [isSearchString, setIsSearchString] = useState(getSearchString.length > 0);
    const lockScrollClass = 'lockScroll';
    const extendedSearchInputClass = 'extendedSearchInput';

    const editBasketOfferQuantity = useCallback(
        (offerId, quantity, actionOrigin, orderItemId) =>
            dispatch(
                editBasketOfferQuantitySelector(
                    distributionId,
                    offerId,
                    quantity,
                    actionOrigin,
                    orderItemId,
                    getCurrentSearchId
                )
            ),
        [getCurrentSearchId, distributionId, dispatch]
    );
    const addToBasket = useCallback(
        (offerId, offerPriceAmount) =>
            dispatch(addSearchResultToBasketSelector(distributionId, offerId, offerPriceAmount)),
        [distributionId, dispatch]
    );

    const clearSearch = useCallback(() => dispatch(clearSearchSelector(distributionId)), [
        dispatch,
        distributionId,
    ]);

    const resizeTopMenu = useCallback(
        expendSearchInput => {
            if (
                !deliveryInfosRef ||
                !deliveryInfosRef.current ||
                !searchInputContainerRef ||
                !searchInputContainerRef.current ||
                !simpleMemberTopRightMenuRef ||
                !simpleMemberTopRightMenuRef.current ||
                !simpleMemberTopLeftMenuRef ||
                !simpleMemberTopLeftMenuRef.current
            ) {
                return;
            }

            if (!expendSearchInput) {
                deliveryInfosRef.current.classList.remove(extendedSearchInputClass);

                searchInputContainerRef.current.classList.remove(extendedSearchInputClass);
                searchInputContainerRef.current.classList.remove('col-sm-9');
                searchInputContainerRef.current.classList.add('col-sm-8');
                searchInputContainerRef.current.style.maxWidth = 'unset';

                simpleMemberTopRightMenuRef.current.classList.remove('col-sm-8');
                simpleMemberTopRightMenuRef.current.classList.add('col-sm-6');

                simpleMemberTopLeftMenuRef.current.classList.remove('col-sm-4');
                simpleMemberTopLeftMenuRef.current.classList.add('col-sm-6');

                return;
            }

            deliveryInfosRef.current.classList.add(extendedSearchInputClass);

            searchInputContainerRef.current.classList.add(extendedSearchInputClass);
            searchInputContainerRef.current.classList.remove('col-sm-8');
            searchInputContainerRef.current.classList.add('col-sm-9');
            searchInputContainerRef.current.style.maxWidth = `${searchModalRef?.current.clientWidth}px`;

            simpleMemberTopRightMenuRef.current.classList.remove('col-sm-6');
            simpleMemberTopRightMenuRef.current.classList.add('col-sm-8');

            simpleMemberTopLeftMenuRef.current.classList.remove('col-sm-6');
            simpleMemberTopLeftMenuRef.current.classList.add('col-sm-4');
        },
        [
            deliveryInfosRef,
            searchInputContainerRef,
            simpleMemberTopLeftMenuRef,
            simpleMemberTopRightMenuRef,
        ]
    );

    const close = useCallback(() => {
        onBlur();
        clearSearch();
    }, [onBlur, clearSearch]);

    const clearSearchAndFocusInput = () => {
        clearSearch();
        inputSearchMobileRef.current.focus();
    };

    useEffect(() => {
        const eventClickCheck = e => {
            if (isSmallWidth === false) {
                if (
                    !searchModalRef.current?.contains(e.target) &&
                    !searchInputContainerRef.current?.contains(e.target)
                ) {
                    close();
                }
            }
        };

        document.addEventListener('click', eventClickCheck);
        document.body.classList.add(lockScrollClass);

        if (isSmallWidth === false) {
            resizeTopMenu(true);
        }

        if (resultRef && resultRef.current) {
            resultRef.current.addEventListener('scroll', () => {
                setIsResultScroll(resultRef.current.scrollTop > 8);
            });
        }

        setIsSearchString(getSearchString.length > 0);

        return () => {
            document.removeEventListener('click', eventClickCheck);
            document.body.classList.remove(lockScrollClass);
            if (isSmallWidth === false) {
                resizeTopMenu(false);
            }
        };
    }, [
        deliveryInfosRef,
        searchInputContainerRef,
        searchModalRef,
        inputSearchMobileRef,
        isSmallWidth,
        getProducts,
        close,
        getSearchString,
        resizeTopMenu,
    ]);

    const goToProduct = useCallback(
        productId => {
            history.push(`/${productId}`, { categoryId });
            close();
        },
        [history, categoryId, close]
    );

    window.addEventListener('resize', () => {
        if (
            searchModalRef &&
            searchModalRef.current &&
            searchInputContainerRef &&
            searchInputContainerRef.current &&
            searchModalRef.current.clientWidth > 0
        ) {
            searchInputContainerRef.current.style.maxWidth = `${searchModalRef.current.clientWidth}px`;
        }
    });

    const inputOnChange = e => {
        onChange(e);
        setIsSearchString(e.target.value.length > 0);
    };

    const addSearchedProductOfferToBasket = (offerId, quantity = 1, actionOrigin) => {
        const orderItemId = undefined;
        editBasketOfferQuantity(offerId, quantity, actionOrigin, orderItemId);
        const offerPriceAmount = getPriceOfOfferFromProducts(getProducts, offerId);

        addToBasket(offerId, offerPriceAmount);
    };

    const startingSearch = (
        <div className="search-modal--status">
            <div className="search-modal--status-image starting"></div>
            <div className="search-modal--status-text">
                <Text size="16px" lineHeight="24px" color="grey8">
                    {trans('salePage.search.start')}
                </Text>
            </div>
        </div>
    );

    const searching = (
        <div className="search-modal--status">
            <LoadingAnimation type="pi-spinner" />
        </div>
    );

    const emptySearch = (
        <div className="search-modal--status">
            <div className="search-modal--status-image empty"></div>
            <div className="search-modal--status-text">
                <Text size="16px" lineHeight="24px" color="grey8">
                    {trans('salePage.search.noFound', { '%searchString%': getSearchString })}
                </Text>
            </div>
        </div>
    );

    return (
        <div id="search-module" className="productIdentity search-container">
            <div className="search-modal-container">
                <div ref={searchModalRef} className="search-modal">
                    {isSmallWidth && (
                        <div className="search-modal-input-container">
                            <div className="search-modal-input-search">
                                <Input
                                    id="input-search-mobile"
                                    ref={inputSearchMobileRef}
                                    onChange={inputOnChange}
                                    size={SMALL_SIZE}
                                    prefix={
                                        <Icon src={searchNavIcon} color="pi-gray6" size="large" />
                                    }
                                    suffix={
                                        <InlineSVG
                                            className={classNames('search-cross', {
                                                'search-cross-show': isSearchString,
                                            })}
                                            onClick={() => clearSearchAndFocusInput()}
                                            src={cross}
                                        />
                                    }
                                    placeholder={trans('salePage.search.placeholder')}
                                    value={getSearchString}
                                    autocomplete={false}
                                    autoFocus
                                />
                            </div>
                            <a onClick={close}>
                                <Text
                                    className="search-modal-input-cancel"
                                    size="16px"
                                    lineHeight="24px"
                                    color="grey8"
                                >
                                    {trans('global.cancel')}
                                </Text>
                            </a>
                        </div>
                    )}
                    {getProducts.length === 0 && getSearchString.length < 3 && startingSearch}
                    {isFetchingSearchResults && getSearchString.length >= 3 && searching}
                    {!isFetchingSearchResults &&
                        getProducts.length === 0 &&
                        getSearchString.length >= 3 &&
                        emptySearch}
                    {getProducts.length > 0 && (
                        <div
                            ref={resultRef}
                            className={classNames('search-result', {
                                'search-result-border': isResultScroll,
                            })}
                        >
                            {getProducts.map(product => (
                                <ProductCard
                                    mode={MODE_SEARCH}
                                    addOfferToBasket={addSearchedProductOfferToBasket}
                                    isLabelDescriptionAvailable={false}
                                    distributionId={distributionId}
                                    product={product}
                                    actionOrigin={SEARCH_RESULTS}
                                    onClick={() => goToProduct(product.id)}
                                    showProducer={false}
                                    key={product.id}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

SearchModal.propTypes = {
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    distributionId: PropTypes.number.isRequired,
    searchInputContainerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]).isRequired,
    simpleMemberTopLeftMenuRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]).isRequired,
    simpleMemberTopRightMenuRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]).isRequired,
    deliveryInfosRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]).isRequired,
    categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SearchModal;
