import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';
import { useDispatch, useSelector } from 'react-redux';

import { userSelector } from 'modules/currentUser';
import { HELP_PANEL, showImmediately } from 'modules/visiblePanel';

import useI18n from 'hooks/I18n/useI18n';

import ResponsiveSidePanel, {
    POSITION_RIGHT,
} from 'components/Navigation/ProductIdentity/ResponsiveSidePanel.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import { List } from 'components/ui';
import LinkItem from './LinkItem.jsx';
import Button, { LINK_MODE } from 'src/components/atoms/Button/Button.jsx';

import userIcon from 'app/assets/new-design/images/icons/user.svg';
import ordersIcon from 'app/assets/new-design/images/icons/orders.svg';
import messageIcon from 'app/assets/new-design/images/icons/message.svg';
import feedbackIcon from 'app/assets/new-design/images/icons/feedback.svg';
import giftIcon from 'app/assets/new-design/images/icons/gift.svg';
import questionIcon from 'app/assets/new-design/images/icons/question.svg';

const ProfilePanel = ({ onClose }) => {
    const user = useSelector(userSelector);
    const dispatch = useDispatch();
    const { trans, i18n } = useI18n();

    const handleClickSupport = () => {
        dispatch(showImmediately(HELP_PANEL));
    };

    return (
        <ResponsiveSidePanel
            isOpened
            position={POSITION_RIGHT}
            title={`${user.firstName} ${user.lastName}`}
            onClose={onClose}
            className="pi-profile-panel"
        >
            <List>
                <LinkItem
                    icon={userIcon}
                    text={trans('user.account.title')}
                    url={`/${i18n}/configuration/account/identity`}
                />
                <LinkItem
                    icon={ordersIcon}
                    text={trans('navigation.user.orders')}
                    url={`/${i18n}/configuration/account/orders`}
                />
                <LinkItem
                    icon={messageIcon}
                    text={trans('menu.messages')}
                    url={`/${i18n}/messages`}
                />
                <LinkItem
                    icon={feedbackIcon}
                    text={trans('navigation.user.feedbacks')}
                    url={`/${i18n}/feedback`}
                />
                <LinkItem
                    icon={giftIcon}
                    text={trans('buyGiftCard.banner.userLink')}
                    url={`/${i18n}/giftcards`}
                />
                <li>
                    <InlineSVG src={questionIcon} />
                    <Button mode={LINK_MODE} onClick={handleClickSupport}>
                        <Text size="16px" lineHeight="24px">
                            {trans('menu.admin.title')}
                        </Text>
                    </Button>
                </li>
            </List>
            <a href={`/${i18n}/logout`} className="pi-profile-panel-logout">
                <Text size="16px" lineHeight="24px">
                    {trans('navigation.user.logout')}
                </Text>
            </a>
        </ResponsiveSidePanel>
    );
};

ProfilePanel.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ProfilePanel;
