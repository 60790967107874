export const FEATURES = {
    ONSITE_SALES: 'onsite_sales',
    ONSITE_SALES_COUNTRY: 'onsite_sales_country',
    CHRISTMAS_THEME: 'christmas_theme',
    GIFTCARD_BANNER: 'gift_card_banner',
    CORONAVIRUS_BANNER: 'coronavirus_banner',
    ANTI_WASTE_PROMOTION_REASON: 'antiwaste_promotion_reason',
    CUSTOM_PROMOTION_REASON: 'custom_promotion_reason',
    PARTIAL_REFUND: 'partial_refund',
    GOODDEAL_MINIMUM_PROMOTION_FARM: 'gooddeal_minimum_promotion_farm',
    GOODDEAL_MINIMUM_PROMOTION_HIVE: 'gooddeal_minimum_promotion_hive',
    ENGAGED_PRICE_PROMOTION: 'engaged_price_promotion',
    HOSTDASHBOARD_RECOMMENDATIONS_CROWDIN: 'host_dashboard_recommendations_crowdin',
    HOST_FOLLOWUP_SEE_PENDING_BASKET: 'host_followup_see_pending_basket',
    FARM_HAS_CUSTOM_EVENT_TAG: 'custom_event_tag_farm',
    HIVE_HAS_CUSTOM_EVENT_TAG: 'custom_event_tag_hive',
    HIVE_MEMBER_HAS_CUSTOM_EVENT_TAG: 'custom_event_tag_hive_member',
};

export default {
    isFeatureActive(featureName, entity) {
        if (entity && entity.get && entity.get('enabledFeatures')) {
            return entity.get('enabledFeatures').indexOf(featureName) !== -1;
        }
        if (entity && entity.enabledFeatures) {
            return entity.enabledFeatures.indexOf(featureName) !== -1;
        }
        return false;
    },
};
