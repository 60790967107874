import { get } from 'modules/api';
import _ from 'underscore';
import { Translator } from 'modules/i18n';
import { deliveryProductTypeId } from 'models/delivery';
import * as R from 'ramda';

export function getAllProductTypes(data) {
    const publicRequest = true;
    return get('product_types/', data ? data : null, null, publicRequest).then(
        response => response.ProductTypes
    );
}

export function fetchProductTypes(distributionId) {
    return get(`distributions/${distributionId}/product_types`).then(
        response => response.ProductTypes
    );
}

export function bySortedCategories(productTypes) {
    let results = [];
    // map
    _.each(productTypes, function(productType) {
        let mainCategoryTemp;
        let subCategoryTemp;

        mainCategoryTemp = R.find(R.whereEq({ id: productType.categoryId }), results);
        if (!mainCategoryTemp) {
            // This order them by order of main category in huge list
            // Thus the initial sort
            mainCategoryTemp = {
                name: productType.categoryName || productType.categoryKey,
                id: productType.categoryId,
                categoryKey: productType.categoryKey.replace('product_type.', ''),
                subCategories: [],
                isTrueCategory: true,
                rank: productType.categoryRank,
            };
            results.push(mainCategoryTemp);
        }
        subCategoryTemp = R.find(
            R.whereEq({ id: productType.subCategoryId }),
            mainCategoryTemp.subCategories
        );
        if (!subCategoryTemp) {
            subCategoryTemp = {
                name:
                    productType.subCategoryName ||
                    productType.subCategoryKey ||
                    productType.subCategory,
                // compute unique id for the DOM
                id: productType.subCategoryId,
                categories: [],
            };
            mainCategoryTemp.subCategories.push(subCategoryTemp);
        }
        subCategoryTemp.categories.push(productType);
    });

    // sort by name when inside major categories
    _.each(results, function(mainCat) {
        _.each(mainCat.subCategories, function(subCategory) {
            subCategory.categories = _.sortBy(subCategory.categories, function(category) {
                return category.name;
            });
        });
        mainCat.subCategories = _.sortBy(mainCat.subCategories, function(subCategory) {
            return subCategory.name;
        });
    });

    results = _.sortBy(results, 'rank');

    return results;
}

function getAlreadyBoughtCategory(categories, locale) {
    return {
        categoryKey: 'alreadybought',
        id: 'alreadybought',
        name: Translator.trans(locale, 'products.filters.alreadybought'),
        englishName: 'Already bought',
        subCategories: categories,
        hasCategoriesAsSubCategories: true,
        isTrueCategory: false,
    };
}

function getAllProductsCategory(locale) {
    return {
        categoryKey: '_all',
        id: '_all',
        name: Translator.trans(locale, 'products.all'),
        englishName: 'All products',
        subCategories: [],
        isTrueCategory: false,
    };
}

function getGoodDealsProducts(categories, locale) {
    return {
        categoryKey: 'goodDeals',
        id: 'goodDeals',
        name: Translator.trans(locale, 'goodDeals'),
        englishName: 'Good deals',
        subCategories: categories,
        isTrueCategory: false,
    };
}

function getCustomEventTagProducts(categories, locale) {
    return {
        categoryKey: 'customEventTag',
        id: 'customEventTag',
        name: Translator.trans(locale, 'products.filters.custom-event-tag'),
        englishName: 'Christmas',
        subCategories: categories,
        isTrueCategory: false,
    };
}

function getHighlightedProductsCategory(locale) {
    return {
        categoryKey: 'highlighted',
        id: 'highlighted',
        name: Translator.trans(locale, 'products.filters.categoryHighlighted'),
        englishName: 'Highlighted products',
        subCategories: [],
        isTrueCategory: false,
    };
}

export function getProductCategoriesForSalePage(
    productTypes,
    locale,
    {
        hasHighlightedCategory,
        hasAlreadyBoughtProducts,
        hasAllProducts,
        hasGoodDealProducts,
        hasCustomEventTagProducts,
    }
) {
    // filter out 'delivery' product types, as they are still returned by the API
    // but we have a special delivery option workflow so we don't want them on the sale pages
    const sortedProductTypes = bySortedCategories(productTypes).filter(
        productType => productType.id !== deliveryProductTypeId
    );

    const categories =
        hasGoodDealProducts || hasAlreadyBoughtProducts
            ? sortedProductTypes.map(category => ({
                  categoryKey: category.categoryKey,
                  name: category.name,
                  id: category.id,
              }))
            : [];

    const sortedCategories = [
        hasHighlightedCategory ? getHighlightedProductsCategory(locale) : null,
        hasGoodDealProducts ? getGoodDealsProducts(categories, locale) : null,
        hasCustomEventTagProducts ? getCustomEventTagProducts(categories, locale) : null,
        hasAllProducts ? getAllProductsCategory(locale) : null,
        hasAlreadyBoughtProducts ? getAlreadyBoughtCategory(categories, locale) : null,
        ...sortedProductTypes,
    ];

    return sortedCategories.filter(category => !!category);
}
