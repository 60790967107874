import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useI18n from 'hooks/I18n/useI18n';
import Button, { LINK_MODE, SECONDARY_MODE } from 'src/components/atoms/Button/Button.jsx';
import Input from 'src/components/atoms/Input/Input.jsx';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
const WysiwygEditor = React.lazy(() => import('components/WysiwygEditor.jsx'));

const NewsMessageForm = ({
    onSave,
    onCancel,
    saveButtonLabel,
    placeholder = '',
    withTitle = false,
}) => {
    const { trans } = useI18n();
    const [wysiwygContent, setWysiwygContent] = useState('');
    const titleInputRef = useRef(null);

    const handleSave = () => {
        if (withTitle) {
            onSave(titleInputRef.current.value, wysiwygContent);
            return;
        }

        onSave(wysiwygContent);
    };

    return (
        <div className="pi-assembly-news-form">
            <Button mode={LINK_MODE} onClick={onCancel}>
                {trans('global.cancel')}
            </Button>
            {withTitle && (
                <Input
                    ref={titleInputRef}
                    placeholder={trans('hiveHome.newsSection.form.title.placeholder')}
                    maxLength={70}
                />
            )}
            <React.Suspense fallback={<LoadingAnimation type="spinner" />}>
                <WysiwygEditor
                    content={wysiwygContent}
                    editContent={(name, value) => {
                        setWysiwygContent(value);
                    }}
                    placeholder={placeholder}
                />
            </React.Suspense>
            <Button mode={SECONDARY_MODE} disabled={wysiwygContent === ''} onClick={handleSave}>
                {saveButtonLabel}
            </Button>
        </div>
    );
};

NewsMessageForm.propTypes = {
    placeholder: PropTypes.string,
    saveButtonLabel: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    withTitle: PropTypes.bool,
};

export default NewsMessageForm;
