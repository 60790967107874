import { get, post, del, patch, put } from 'modules/api';
import moment from 'moment';
import { momentNow } from 'models/date';
import { isReadyForSale } from 'models/offers';
import { INTERREGIONAL, LOCAL } from 'models/salesScopes';
import {
    formQuestionsToPostData,
    transformBooleansAndNumbersValuesToStrings,
} from 'models/formQuestions';
import * as R from 'ramda';
import { compareAlpha } from 'modules/utils/string.js';
import geodist from 'geodist';

export const DELIVERY_TYPE_SHIPPING_CARRIER = 'shipping_carrier';
export const DELIVERY_TYPE_ONESELF = 'oneself';
export const DELIVERY_TYPE_OTHER_FARMER = 'other_farmer';
export const DELIVERY_TYPE_INDEPENDENT_LOGISTICIAN = 'independent_logistician';

export const isInterregional = assembly => R.propEq('scope', INTERREGIONAL)(assembly);

export const isLocal = assembly => R.propEq('scope', LOCAL)(assembly);

function excludeOffersNotReadyForSale(farm) {
    if (!farm.products) {
        farm.products = [];
    }
    farm.products.forEach(product => {
        if (!product.offers) {
            product.offers = [];
        }
        product.offers = product.offers.filter(offer => {
            return isReadyForSale(offer, product.type.quantityStrategy);
        });
    });
    return farm;
}

// Some farms are not opened but are in relation with an assembly
// We will consider that they started business when they registered on the website
// See https://app.asana.com/0/158948086650638/183823780373494
function normalizeOpeningDateForFarmsNotYetOpened(farm) {
    if (!farm.openedAt) {
        farm.openedAt = farm.createdAt;
    }
    return farm;
}

export function getGuestsStats(farmId) {
    return get(`farms/${farmId}/sales/guest/stats`);
}

export function getLocalStats(farmId) {
    return get(`farms/${farmId}/sales/local/stats`);
}

export function getFarmProfileWithAssemblies({ forFarm, fromAssembly }) {
    let url = `farms/${forFarm}/with_hives`;
    if (fromAssembly) {
        url = `farms/${forFarm}/hives/${fromAssembly}`;
    }

    return get(url)
        .then(excludeOffersNotReadyForSale)
        .then(normalizeOpeningDateForFarmsNotYetOpened);
}

export function getLocalAssemblies(assemblyList) {
    return assemblyList.filter(assembly => isLocal(assembly));
}

export function getInterregionalAssemblies(assemblyList) {
    return assemblyList.filter(assembly => isInterregional(assembly));
}

export const setVisitedOnDefaultValue = feedback => {
    feedback.sections.forEach(section => {
        section.questions.forEach(question => {
            if (question.key === 'visitedOn' && !question.value) {
                question.value = momentNow().format('YYYY-MM');
            }
        });
    });

    return feedback;
};

export function getFarmProfessions({ farmId }) {
    return get(`practices/farms/${farmId}`).then(professions => {
        professions.forEach(profession => {
            profession.questions.forEach(transformBooleansAndNumbersValuesToStrings);
        });
        return professions;
    });
}

export function addFarmProfession({ farmId, professionId }) {
    return post(`practices/farms/${farmId}/professions/${professionId}`);
}

export function removeFarmProfession({ farmId, professionId }) {
    return del(`practices/farms/${farmId}/professions/${professionId}`);
}

export function sendProfessionsPractices({ farmId, professions }) {
    const postData = [];
    professions.forEach(profession => {
        postData.push({
            profession: profession.key,
            practices: formQuestionsToPostData({ sections: [profession] }),
        });
    });

    return post(`practices/farms/${farmId}`, postData);
}

function log1p(n) {
    return Math.log(n + 1);
}

function scoreAssemblyByHostRelevance(assembly, farmFirstDistributionDate) {
    const now = momentNow();
    const returnFloat = true;

    // Number of months host and farm have been connected
    // The longer the duration, the higher the weight (bonus)
    const numberOfMonthsConnected = now.diff(
        moment(assembly.connectionDate),
        'months',
        returnFloat
    );

    // Number of months elapsed since the producer's last distribution date
    // The longer the duration, the lower the weight (malus)
    const numberOfMonthsSinceLastDistribution = now.diff(
        moment(assembly.lastDistributionDate),
        'months',
        returnFloat
    );

    const hostProbablySourcedTheProducer = moment(farmFirstDistributionDate).isSame(
        assembly.firstDistributionDate,
        'day'
    );

    // Bonus "long activity + long relationship":
    // [0.6 - 7]
    let longActivityScore = log1p(assembly.distributionsCount + numberOfMonthsConnected);

    if (hostProbablySourcedTheProducer) {
        longActivityScore *= 1.2;
    }

    if (assembly.feedback || assembly.visitedOn) {
        longActivityScore *= 10.0;
    }

    // Malus "fresh news":
    // [0.1 - 1,2]
    const recentDistributionScore = -numberOfMonthsSinceLastDistribution * 0.1;

    return longActivityScore + recentDistributionScore;
}

function getAssembliesScoresByHostRelevance(assemblies, farmFirstDistributionDate) {
    const scores = {};
    assemblies.forEach(assembly => {
        scores[assembly.id] = scoreAssemblyByHostRelevance(assembly, farmFirstDistributionDate);
    });
    return scores;
}

/**
 * Sort collection of farm's assemblies by the most relevance hosts.
 *
 * @param {Array} assemblies - The list fo assemblies.
 * @param {String} farmFirstDistributionDate - The farm's first distribution date.
 * @return {Array} The sorted collection.
 */
export function sortedAssembliesByHostRelevance(assemblies, farmFirstDistributionDate) {
    const scores = getAssembliesScoresByHostRelevance(assemblies, farmFirstDistributionDate);

    return assemblies.slice().sort((a, b) => {
        return scores[b.id] - scores[a.id];
    });
}

export function getValidatedLabelTypes(farm) {
    if (!farm.labels) {
        return [];
    }
    return farm.labels.filter(l => l.status === 'validated').map(l => l.labelType);
}

export function addProductTypesToFarmsIfNeeded(farms, indexedProductTypes) {
    if (R.isEmpty(farms) || R.isNil(farms)) {
        return [];
    }
    return farms.map(farm => {
        return {
            ...farm,
            products: farm.products.map(product => {
                if (product.productType) {
                    return product;
                }
                return {
                    ...product,
                    productType: indexedProductTypes[product.productTypeId],
                };
            }),
        };
    });
}

export function getFarmProducerPhotoId(farm) {
    return (
        (farm.user && farm.user.photoId) ||
        R.path(['photos', 0, 'id'], farm) ||
        farm.photoId ||
        null
    );
}

export function getFarmsWithSoldVolume(distributionId) {
    return get(`distributions/${distributionId}/followup/farms-with-sold-volume`);
}

export function patchFarm(farmId, data) {
    return patch(`farms/${farmId}`, data);
}
export const getSourceFarms = farmId => get(`farms/${farmId}/source-farms`);

export const addSourceFarm = (farmId, newPartner) =>
    post(`farms/${farmId}/source-farms`, newPartner);

export const editSourceFarm = (farmId, sourceFarmId, data) =>
    patch(`farms/${farmId}/source-farms/${sourceFarmId}`, data);

export const deleteSourceFarm = (farmId, sourceFarmId) =>
    del(`farms/${farmId}/source-farms/${sourceFarmId}`);

export const sortByFarmName = farms =>
    R.sort((a, b) => compareAlpha(a.farmName, b.farmName), farms);

export const setDistributionMinimumOrder = (farmId, distributionId, amount, currency) =>
    put(`farms/${farmId}/distributions/${distributionId}`, {
        orderMinimum: {
            amount,
            currency,
        },
    });

export const setDistributionMinimumOrderForAssembly = (farmId, hiveId, amount, currency) =>
    put(`farms/${farmId}/hives/${hiveId}`, {
        orderMinimum: {
            amount,
            currency,
        },
    });

export const requestPending = farmId =>
    patch(`farms/${farmId}/pending`, {
        action: 'pending_action',
    });

export const getDistanceFromPoint = (farm, point) => {
    if (!farm || !farm.address || !farm.address.coordinates) {
        return 0;
    }
    return geodist(farm.address.coordinates, point, { unit: 'meters' });
};

export const sortByDistanceFromPoint = (farms, point) => {
    return R.sortBy(farm => getDistanceFromPoint(farm, point))(farms);
};

export const fetchFarm = farmId => get(`farms/${farmId}`);

export const setCompanyId = ({ farmId, companyId }) =>
    put(`farms/${farmId}/companies/${companyId}`);

export const getVATRates = farmId => get(`farms/${farmId}/vat_rates`);
