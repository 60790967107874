import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Split extends React.Component {
    render() {
        const { children, vertical, align, gutter, className, ...rest } = this.props;

        const classes = [className, 'split'];
        if (vertical) {
            classes.push('split--vertical');
        } else {
            classes.push('split--horizontal');
        }
        if (align) {
            classes.push(`split--align${align}`);
        }
        if (gutter) {
            classes.push(`split--gutter${gutter}`);
        }
        return (
            <div className={classnames(classes)} {...rest}>
                {children}
            </div>
        );
    }
}

Split.propTypes = {
    children: PropTypes.node,
    vertical: PropTypes.bool,
    align: PropTypes.string,
    gutter: PropTypes.string,
    className: PropTypes.string,
};

export default Split;
