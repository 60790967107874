import React, { createContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

export const AssembliesMapModalNavContext = createContext();

export const AssembliesMapModalNavProvider = ({ children }) => {
    const [historyAssemblies, setHistoryAssemblies] = useState([]);
    const [showNearbyAssemblies, setShowNearbyAssemblies] = useState(false);

    const resetNavigation = useCallback(assembly => {
        setHistoryAssemblies(assembly ? [assembly] : []);
        setShowNearbyAssemblies(false);
    }, []);
    const goNext = useCallback(assembly => {
        setHistoryAssemblies(prev => [...prev, assembly]);
        setShowNearbyAssemblies(false);
    }, []);

    const goBack = useCallback(() => {
        if (showNearbyAssemblies) {
            setShowNearbyAssemblies(false);
        } else {
            setHistoryAssemblies(prev => prev.slice(0, -1));
            setShowNearbyAssemblies(true);
        }
        return historyAssemblies[historyAssemblies.length - (showNearbyAssemblies ? 1 : 2)];
    }, [showNearbyAssemblies, historyAssemblies]);

    const getCurrentItem = useCallback(() => {
        const sliced = historyAssemblies.slice(-1);
        return sliced.length > 0 ? historyAssemblies.slice(-1)[0] : null;
    }, [historyAssemblies]);

    return (
        <AssembliesMapModalNavContext.Provider
            value={{
                historyAssemblies,
                goNext,
                showNearbyAssemblies,
                goBack,
                setShowNearbyAssemblies,
                resetNavigation,
                getCurrentItem,
            }}
        >
            {children}
        </AssembliesMapModalNavContext.Provider>
    );
};
AssembliesMapModalNavProvider.propTypes = {
    children: PropTypes.node,
};
