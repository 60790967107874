import React, { Fragment } from 'react';
import { Icon, Paragraph, Link } from 'components/ui';
import useI18n from 'hooks/I18n/useI18n.js';
import PropTypes from 'prop-types';
import useResponsive from 'hooks/Navigation/useResponsive';

const SaleBanner = ({ url, illustration, backgroundImg, backgroundColor, color, text }) => {
    const { trans } = useI18n();
    const isSmallWidth = useResponsive();

    return (
        <Link
            className="saleBanner"
            href={url}
            style={{
                backgroundImage: `url(${backgroundImg})` || 'none',
                color: color || '#000',
                backgroundColor: backgroundColor || '#fff',
            }}
        >
            <div className="saleBannerContainer">
                {isSmallWidth ? (
                    <>
                        {illustration && (
                            <div className="saleBanner-imgBox">
                                <img className="saleBanner-img" src={illustration} />
                            </div>
                        )}
                        <div>
                            <Paragraph>{trans(text?.smartphone?.title)}</Paragraph>
                            <Paragraph
                                dangerouslySetInnerHTML={{
                                    __html: trans(text?.smartphone?.text),
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="saleBanner-text-img-container">
                            {illustration && (
                                <div className="saleBanner-imgBox">
                                    <img className="saleBanner-img" src={illustration} />
                                </div>
                            )}
                            <div className="saleBanner-text">
                                <Paragraph className="fa-text--bold">
                                    {trans(text?.desktop?.title)}
                                </Paragraph>
                                <Paragraph
                                    dangerouslySetInnerHTML={{
                                        __html: trans(text?.desktop?.text),
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <Icon src="angle-right" size="xx-large" />
                        </div>
                    </>
                )}
            </div>
        </Link>
    );
};

SaleBanner.propTypes = {
    url: PropTypes.string.isRequired,
    illustration: PropTypes.string,
    backgroundImg: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.shape({
        smartphone: PropTypes.shape({
            title: PropTypes.string,
            text: PropTypes.string,
        }),
        desktop: PropTypes.shape({
            title: PropTypes.string,
            text: PropTypes.string,
        }),
    }).isRequired,
};

export default SaleBanner;
