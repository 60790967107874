import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { mergeDeepRight } from 'ramda';
import useI18n from 'hooks/I18n/useI18n';

import {
    getFormattedPricePerQuantity,
    getFormattedQuantities,
    STOCK_UNLIMITED,
} from 'models/offers';

import useAnalytics from 'hooks/Analytics/useAnalytics';

import { currentAssemblySelector } from 'modules/navigation';

import { getQuantityInBasket } from 'modules/orders';

import ProductOfferTemplate, {
    PRODUCT_TEMPLATE,
} from 'components/Product/ProductIdentity/OfferTemplates/ProductOfferTemplate.jsx';
import BasketOfferTemplate, {
    BASKET_TEMPLATE,
} from 'components/Product/ProductIdentity/OfferTemplates/BasketOfferTemplate.jsx';

export const ORIENTATION_HORIZONTAL = 'horizontal';
export const ORIENTATION_VERTICAL = 'vertical';
export const BUTTON_LARGE = 'large';

export { PRODUCT_TEMPLATE, BASKET_TEMPLATE };

export const PRICE_FORMAT_UNIT = 'unit';
export const PRICE_FORMAT_TOTAL = 'total';
const TEMPLATES = {
    [PRODUCT_TEMPLATE]: ProductOfferTemplate,
    [BASKET_TEMPLATE]: BasketOfferTemplate,
};

const Offer = React.memo(
    ({
        buttonSize,
        offer,
        orientation,
        addOfferToBasket,
        distributionId,
        product,
        actionOrigin,
        template,
        disabled,
        priceFormat,
        showTag,
        isMultiOffer,
        onClick,
        orderStatus,
    }) => {
        const { type, quantity = 0 } = product;
        const { trans, i18n } = useI18n();
        const quantityInBasket = useSelector(state =>
            getQuantityInBasket(state, {
                distributionId,
                offerId: offer.id,
                orderStatus,
            })
        );
        const { analytics } = useAnalytics();

        const assembly = useSelector(currentAssemblySelector);

        const offerFormatedPrice = useMemo(() => {
            if (priceFormat === PRICE_FORMAT_UNIT) {
                return offer;
            }
            const promotion = offer.promotion
                ? mergeDeepRight(offer.promotion, {
                      originalOfferPrice: {
                          amount: offer.promotion.originalOfferPrice.amount * quantityInBasket,
                      },
                  })
                : null;
            return mergeDeepRight(offer, {
                price: { amount: offer.price.amount * (quantityInBasket || quantity) },
                promotion,
            });
        }, [offer, quantityInBasket, priceFormat, quantity]);

        const [formattedQuantities, pricePerQuantity] = useMemo(() => {
            const localFormattedQuantities = getFormattedQuantities([offer], type, i18n, trans);
            const localPricePerQuantity = getFormattedPricePerQuantity(offer, {
                strategy: type.quantityStrategy,
                locale: i18n,
            });

            return [localFormattedQuantities, localPricePerQuantity];
        }, [type, offer, trans, i18n]);

        const stockAvailable = useMemo(
            () =>
                offer.availableStock > quantityInBasket ||
                offer.availableStock === STOCK_UNLIMITED ||
                offer.availableStock === undefined,
            [offer.availableStock, quantityInBasket]
        );
        const handleAddBasket = useCallback(
            e => {
                e && e.stopPropagation();
                addOfferToBasket(offer.id, quantityInBasket + 1, actionOrigin);

                analytics.trackAddToBasket(product, offer, assembly, actionOrigin);
            },
            [offer, quantityInBasket, actionOrigin, addOfferToBasket, product, assembly, analytics]
        );
        const handleRemoveBasket = useCallback(
            e => {
                e && e.stopPropagation();

                addOfferToBasket(offer.id, quantityInBasket - 1, actionOrigin);
            },
            [offer, quantityInBasket, actionOrigin, addOfferToBasket]
        );
        const Component = useMemo(() => TEMPLATES[template], [template]);

        return (
            <Component
                onClick={onClick}
                buttonSize={buttonSize}
                offer={offerFormatedPrice}
                orientation={orientation}
                handleAddBasket={disabled ? null : stockAvailable && handleAddBasket}
                handleRemoveBasket={disabled ? null : handleRemoveBasket}
                formattedQuantities={formattedQuantities}
                pricePerQuantity={pricePerQuantity}
                quantityInBasket={quantityInBasket || quantity}
                photo={product.photo}
                productName={product.name}
                showTag={showTag}
                isMultiOffer={isMultiOffer}
            />
        );
    }
);

Offer.propTypes = {
    orientation: PropTypes.oneOf([ORIENTATION_HORIZONTAL, ORIENTATION_VERTICAL]),
    addOfferToBasket: PropTypes.func,
    offer: PropTypes.object.isRequired,
    buttonSize: PropTypes.oneOf([BUTTON_LARGE, '']),
    distributionId: PropTypes.number,
    product: PropTypes.shape({
        name: PropTypes.string,
        offers: PropTypes.array,
        type: PropTypes.object,
        farm: PropTypes.object,
        quantity: PropTypes.number, // Use when the offer comes from an order
    }),
    actionOrigin: PropTypes.string,
    template: PropTypes.string,
    disabled: PropTypes.bool,
    priceFormat: PropTypes.oneOf([PRICE_FORMAT_UNIT, PRICE_FORMAT_TOTAL]),
    showTag: PropTypes.bool,
    isMultiOffer: PropTypes.bool,
    onClick: PropTypes.func,
    orderStatus: PropTypes.string,
};

Offer.defaultProps = {
    showProducer: true,
    orientation: ORIENTATION_HORIZONTAL,
    buttonSize: '',
    template: PRODUCT_TEMPLATE,
    disabled: false,
    priceFormat: PRICE_FORMAT_UNIT,
    showTag: true,
    onClick: null,
    orderStatus: 'default',
};

export default Offer;
