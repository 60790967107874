import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { post } from 'modules/api';
import connectToI18n from 'modules/i18n/connectToI18n';
import { revalidateOrder } from 'modules/orders';
import { initDeliveryOption } from 'modules/checkout/actions.pickup';
import { fetchBasketIfNeeded } from 'modules/checkout/actions.basket';

import PISelectDeliveryContainer from 'components/Checkout/ProductIdentity/SelectDeliveryContainer.jsx';
import RoutePageView from 'components/RoutePageView.jsx';
import LoadingAnimation from 'components/LoadingAnimation.jsx';

/*
Delivery option is a string of the following form:
 - assembly
 - pickup_[pickupUuid]
 - deliveryOffer_[offerId]
*/

export class CheckoutRouter extends React.Component {
    static propTypes = {
        basket: PropTypes.object,
        orderId: PropTypes.number.isRequired,
        revalidateOrder: PropTypes.func.isRequired,
        basketPrices: PropTypes.object,
        selectedOption: PropTypes.string,
        deliveryOptions: PropTypes.array.isRequired,
        fetchBasketIfNeeded: PropTypes.func.isRequired,
        isFetchingBasket: PropTypes.bool,
    };

    componentDidMount() {
        this.props.fetchBasketIfNeeded(this.props.orderId);
    }

    onDeliveryFormSubmit = dataToSend => {
        post(`orders/${this.props.basket.id}/delivery`, dataToSend).then(order => {
            this.props.revalidateOrder(order.id, order.distribution.id);
        });
    };

    render() {
        const {
            basket,
            deliveryOptions,
            basketPrices,
            selectedOption,
            isFetchingBasket,
        } = this.props;

        if (isFetchingBasket || !basketPrices || !basket) {
            return <LoadingAnimation classname="loading-anim-box-margin-top" type="pi-spinner" />;
        }

        return (
            <Router>
                <RoutePageView />
                <Switch>
                    <Route
                        exact
                        path="/:locale/orders/:orderId/pickup"
                        render={props => {
                            const orderId = Number(props.match.params.orderId);
                            const basketId = Number(basket.id);
                            orderId !== basketId && this.props.fetchBasketIfNeeded(orderId);
                            return (
                                <div>
                                    <PISelectDeliveryContainer
                                        basket={basket}
                                        basketPrices={basketPrices}
                                        deliveryOptions={deliveryOptions}
                                        selectedOption={selectedOption}
                                        onDeliveryFormSubmit={this.onDeliveryFormSubmit}
                                    />
                                </div>
                            );
                        }}
                    />
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = state => ({
    basket: state.checkout.basket,
    basketPrices: state.checkout.basketPrices,
    selectedOption: state.checkout.selectedOption,
    deliveryOptions: state.checkout.deliveryOptions,
    isFetchingBasket: state.checkout.isFetchingBasket,
});

export default connect(mapStateToProps, {
    revalidateOrder,
    initDeliveryOption,
    fetchBasketIfNeeded,
})(connectToI18n(CheckoutRouter));
