import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Heading, LoadingAnimation, Text, Wrapper } from 'components/ui';
import connectToI18n from 'modules/i18n/connectToI18n';
import { READY } from 'modules/utils/ajaxStatuses';
import ProductsByDistribution from './ProductsByDistribution.jsx';
import Utils from 'modules/utils';
const { linkTo } = Utils;
import { connect } from 'react-redux';
import TermsPublicFooter from 'components/TermsPublicFooter.jsx';
import { currentAssemblySelector } from 'modules/navigation';
import { hivesAsMemberSelector } from 'modules/currentUser';

export class ProductFeedbackPage extends React.Component {
    static propTypes = {
        onRateProduct: PropTypes.func.isRequired,
        productsByDistribution: PropTypes.object.isRequired,
        status: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
        userPhotoId: PropTypes.string.isRequired,
        currentAssembly: PropTypes.object,
        assembliesAsMember: PropTypes.array,
    };

    render() {
        const {
            onRateProduct,
            productsByDistribution,
            status,
            trans,
            userPhotoId,
            currentAssembly,
            assembliesAsMember,
        } = this.props;

        if (status !== READY) {
            return <LoadingAnimation />;
        }

        const sortedDates = R.sort(R.descend(R.identity), R.keys(productsByDistribution));

        const assemblyId = currentAssembly
            ? currentAssembly.id
            : R.compose(R.prop('id'), R.head)(assembliesAsMember);

        return (
            <Wrapper size="small" xsPadding className="productFeedback">
                <br />
                {R.isEmpty(productsByDistribution) ? (
                    <div className="u-center">
                        <img
                            className="productFeedback-image"
                            src="/assets/images/illustrations/assembly-searching.png"
                        />
                        <Heading size={2} className="u-mb-2">
                            {trans('products.feedback.empty.title')}
                        </Heading>

                        <Text
                            dangerouslySetInnerHTML={{
                                __html: trans('products.feedback.empty.text', {
                                    '%url%': assemblyId
                                        ? linkTo(`assemblies/${assemblyId}/products`)
                                        : linkTo('assemblies'),
                                    '%linkClass%': 'fa-link--primary',
                                }),
                            }}
                        />
                    </div>
                ) : (
                    <Fragment>
                        <Heading size={2} className="u-mb-4">
                            {trans('products.feedback.title')}
                        </Heading>
                        <Text
                            size="large"
                            className="u-mb-10"
                            dangerouslySetInnerHTML={{
                                __html: trans('products.feedback.description'),
                            }}
                        />

                        {sortedDates.map(date => (
                            <ProductsByDistribution
                                key={date}
                                products={productsByDistribution[date]}
                                distributionDate={date}
                                onRateProduct={onRateProduct}
                                userPhotoId={userPhotoId}
                            />
                        ))}
                    </Fragment>
                )}
                <TermsPublicFooter />
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    userPhotoId: state.currentUser.user.photoId,
    currentAssembly: currentAssemblySelector(state),
    assembliesAsMember: hivesAsMemberSelector(state),
});

export default connect(mapStateToProps)(connectToI18n(ProductFeedbackPage));
