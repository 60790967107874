import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useI18n from 'hooks/I18n/useI18n';

import { convertRawTextToHtml, asWysiwygHtml, addBypassRouterToLink } from 'modules/utils/text';

import Text from 'components/ProductIdentity/Text.jsx';
import Modal from 'src/components/organisms/Modal/Modal.jsx';
import Button, { LINK_MODE } from 'src/components/atoms/Button/Button.jsx';

const TroncatedDescription = ({ description, title, maxLine }) => {
    const descriptionRef = useRef();
    const containerRef = useRef();
    const [isMounted, setIsMounted] = useState();
    const [showButton, setShowButton] = useState();
    const [lineClamp, setLineClamp] = useState(3);
    const { trans } = useI18n();
    const [isOpen, setIsOpen] = useState(false);

    const onResize = useCallback(() => {
        const shouldShowbutton =
            descriptionRef.current?.scrollHeight > containerRef.current?.offsetHeight;
        setShowButton(shouldShowbutton);
        const lineCount = containerRef.current
            ? Math.floor(
                  (containerRef.current.getBoundingClientRect().height -
                      (shouldShowbutton ? 36 : 0)) /
                      20
              )
            : 3;
        maxLine === 'auto' && setLineClamp(lineCount);
    }, [maxLine]);

    useEffect(() => {
        if (descriptionRef.current) {
            window && window.addEventListener('resize', onResize);
            setShowButton(
                descriptionRef.current?.scrollHeight > descriptionRef.current?.offsetHeight
            );
        }
        return () => {
            window && window.removeEventListener('resize', onResize);
        };
    }, [onResize]);

    useEffect(() => {
        if (!isMounted) {
            setIsMounted(true);
            onResize();
        }
    }, [isMounted, onResize]);
    return (
        <>
            <div ref={containerRef} className={classNames('troncated-description', { showButton })}>
                <Text
                    style={{
                        WebkitLineClamp: lineClamp,
                        lineClamp,
                    }}
                    className="troncated-text-wrapper"
                    ref={descriptionRef}
                    size="14px"
                    lineHeight="20px"
                    family="inter"
                    color="gray2"
                    dangerouslySetInnerHTML={{
                        __html: asWysiwygHtml(description)
                            ? addBypassRouterToLink(description)
                            : convertRawTextToHtml(description),
                    }}
                ></Text>
                {
                    <div>
                        <Button mode={LINK_MODE} onClick={() => setIsOpen(true)}>
                            <Text size="14px" lineHeight="20px" family="inter" color="turquoise2">
                                {trans('global.readMore')}
                            </Text>
                        </Button>
                    </div>
                }
            </div>
            {showButton && (
                <Modal
                    className="troncated-description-modal"
                    isMounted={isOpen}
                    closeModal={() => setIsOpen(false)}
                    title={title}
                >
                    <Text
                        size="16px"
                        lineHeight="24px"
                        family="inter"
                        color="gray2"
                        dangerouslySetInnerHTML={{
                            __html: asWysiwygHtml(description)
                                ? addBypassRouterToLink(description)
                                : convertRawTextToHtml(description),
                        }}
                    ></Text>
                </Modal>
            )}
        </>
    );
};
TroncatedDescription.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
    maxLine: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
};
TroncatedDescription.defaultProps = {
    maxLine: 3,
};
export default TroncatedDescription;
