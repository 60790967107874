import React from 'react';
import { getLocaleConfig } from 'modules/config/i18n';
import useI18n from 'hooks/I18n/useI18n';
import Text from './Text.jsx';

const TermsFooter = () => {
    const { i18n, i18nConfig, trans } = useI18n();

    const localeConf = getLocaleConfig(i18n);
    const localeForLink = localeConf.shortcut ? localeConf.shortcut : localeConf.code;

    return (
        <div className="pi-termsFooter">
            <a
                href={`/${localeForLink}/p/legal-mentions`}
                target="_blank"
                title={trans('publicFooter.links.legal')}
                aria-label={trans('publicFooter.links.legal')}
            >
                <Text size="12px" lineHeight="20px" color="grey8">
                    {trans('publicFooter.links.legal')}
                </Text>
            </a>
            <a
                href={`/${localeForLink}/p/terms`}
                target="_blank"
                title={trans('publicFooter.links.terms')}
                aria-label={trans('publicFooter.links.terms')}
            >
                <Text size="12px" lineHeight="20px" color="grey8">
                    {trans('publicFooter.links.terms')}
                </Text>
            </a>
            <a
                href={trans('privacy_policy_link')}
                target="_blank"
                title={trans('footer_privacy_policy')}
                aria-label={trans('footer_privacy_policy')}
            >
                <Text size="12px" lineHeight="20px" color="grey8">
                    {trans('footer_privacy_policy')}
                </Text>
            </a>
            {i18nConfig?.links?.ourDatas && (
                <a
                    href={i18nConfig.links.ourDatas}
                    target="_blank"
                    title={trans('publicFooter.links.data')}
                    aria-label={trans('publicFooter.links.data')}
                >
                    <Text size="12px" lineHeight="20px" color="grey8">
                        {trans('publicFooter.links.data')}
                    </Text>
                </a>
            )}
        </div>
    );
};

export default TermsFooter;
