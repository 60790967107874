import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const RIGHT_TOOLTIP = 'right';
export const LEFT_TOOLTIP = 'left';
export const TOP_TOOLTIP = 'top';
export const BOTTOM_TOOLTIP = 'bottom';
export const TRIGGER_CLICK_TOOLTIP = 'click';
export const TRIGGER_HOVER_TOOLTIP = 'hover';
export const TRIGGER_VISIBLE_TOOLTIP = 'visible';

const Tooltip = ({ content, children, orientation, trigger }) => {
    const [isShowing, setIsShowing] = useState(trigger === TRIGGER_VISIBLE_TOOLTIP);

    const onClick = useCallback(
        () => trigger === TRIGGER_CLICK_TOOLTIP && setIsShowing(!isShowing),
        [trigger, isShowing]
    );
    const onMouseLeave = useCallback(
        () => trigger !== TRIGGER_VISIBLE_TOOLTIP && setIsShowing(false),
        [trigger]
    );
    const onMouseEnter = useCallback(
        () => trigger === TRIGGER_HOVER_TOOLTIP && setIsShowing(true),
        [trigger]
    );
    return (
        <div
            className="nd-tooltip-container"
            onClick={onClick}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
        >
            {children}
            {content && (
                <div
                    className={classNames('nd-tooltip', {
                        [orientation]: orientation,
                        show: isShowing,
                    })}
                >
                    {content}
                </div>
            )}
        </div>
    );
};

Tooltip.propTypes = {
    orientation: PropTypes.oneOf([BOTTOM_TOOLTIP, TOP_TOOLTIP, LEFT_TOOLTIP, RIGHT_TOOLTIP]),
    trigger: PropTypes.oneOf([
        TRIGGER_CLICK_TOOLTIP,
        TRIGGER_HOVER_TOOLTIP,
        TRIGGER_VISIBLE_TOOLTIP,
    ]),
    content: PropTypes.element.isRequired,
    children: PropTypes.element.isRequired,
};
Tooltip.defaultProps = {
    orientation: LEFT_TOOLTIP,
    trigger: TRIGGER_HOVER_TOOLTIP,
};
export default Tooltip;
