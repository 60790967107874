import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { imperativeEditUser, userSelector } from 'modules/currentUser';
import { redirectTo } from 'modules/utils';
import { userJoinAssembly } from 'api/hives';
import { getLocalizedWeekdayFromDayAsNumber, removeSeconds } from 'models/date';
import { isMemberOfAssembly } from 'models/users';
import useAnalytics from 'hooks/Analytics/useAnalytics';
import useI18n from 'hooks/I18n/useI18n';
import { AssembliesMapModalNavContext } from 'components/Maps/ProductIdentity/AssembliesMap/AssembliesMapModalNavigactionContext.jsx';
import Button, { LINK_BLUE_MODE } from 'src/components/atoms/Button/Button.jsx';
import Text, { SMALL } from 'src/components/atoms/Text/Text.jsx';
import HiveJoinedConfirmation from './HiveJoinedConfirmation.jsx';
import HostCard from './HostCard.jsx';
import Handhover from './Handover.jsx';
import NearbyAssemblies from './NearbyAssemblies.jsx';

export const AssemblyWithoutAnyOpenDistributions = ({
    assembly,
    assemblies,
    onClickNearbyAssembly,
}) => {
    const { i18n, trans } = useI18n();
    const { analytics } = useAnalytics();
    const { setShowNearbyAssemblies, showNearbyAssemblies } = useContext(
        AssembliesMapModalNavContext
    );
    const dispatch = useDispatch();
    const [hiveWasJoined, setHiveWasJoined] = useState(false);
    const user = useSelector(userSelector);

    const { day, starting, ending } = assembly.deliveryTimeSlot;
    const weekday = getLocalizedWeekdayFromDayAsNumber(day, i18n).toLowerCase();
    const startHour = removeSeconds(starting);
    const endHour = removeSeconds(ending);
    const handleNearbyAssemblyClick = nextAssembly => {
        onClickNearbyAssembly(nextAssembly);
    };

    useEffect(() => {
        setHiveWasJoined(false);
    }, [assembly]);

    const assemblyAddress = assembly.place.address;
    const userIsAlreadyMemberOfHive = isMemberOfAssembly(assembly.id, user);
    return (
        <>
            {showNearbyAssemblies ? (
                <NearbyAssemblies
                    assembly={assembly}
                    assemblies={assemblies}
                    onClick={handleNearbyAssemblyClick}
                />
            ) : (
                <div className="map-assembly-and-distribution-block">
                    <HostCard
                        url={`/${i18n}/assemblies/${assembly.id}`}
                        photoUuid={assembly.host.photoUuid}
                        cityName={assemblyAddress.city.name}
                        pointOfSaleName={assembly.name}
                        street={assemblyAddress.street}
                        hostFirstname={assembly.host.firstname}
                    />
                    {assembly.isHandover && (
                        <>
                            <Handhover hiveId={assembly.id} />
                            <div className="pi-hives-nearby">
                                <Text size={SMALL}>{trans('map.hives.nearby.description')}</Text>
                                <div>
                                    <Button
                                        mode={LINK_BLUE_MODE}
                                        onClick={() => setShowNearbyAssemblies(true)}
                                    >
                                        <Text size={SMALL}>
                                            {trans('public.assembly.seeAllAssemblies')}
                                        </Text>
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                    {!hiveWasJoined && (
                        <div className="no-sale-block">
                            <img src="/assets/images/assembly_redflag.svg" />
                            <Text className="no-sale-text" size={SMALL}>
                                {trans('assembly.noOpenSale')}
                            </Text>
                            <Text size={SMALL}>
                                {trans('assembliesMap.assembly.pickupWeekdayAndTimeslot', {
                                    '%weekday%': weekday,
                                    '%startHour%': startHour,
                                    '%endHour%': endHour,
                                })}
                            </Text>
                        </div>
                    )}
                    {hiveWasJoined && <HiveJoinedConfirmation />}
                </div>
            )}
            {!hiveWasJoined && !userIsAlreadyMemberOfHive && (
                <div className="join-this-hive-container">
                    <Text size={SMALL} className="helper-text">
                        {trans('assembliesMap.modal.joinThisHive.helper')}
                    </Text>
                    <Button
                        onClick={() => {
                            if (!!user.anonymous) {
                                redirectTo(`join/assembly/${assembly.id}`);
                            } else {
                                userJoinAssembly(assembly.id, user.id).then(assemblyResponse => {
                                    dispatch(
                                        imperativeEditUser(
                                            R.evolve({
                                                hivesAsMember: R.append(assemblyResponse),
                                            })
                                        )
                                    );

                                    analytics.trackAssemblyJoin(
                                        assemblyResponse.id,
                                        assemblyResponse.name
                                    );
                                });

                                setHiveWasJoined(true);
                            }
                        }}
                    >
                        <Text>{trans('assembliesMap.modal.joinThisHive.btn')}</Text>
                    </Button>
                </div>
            )}
        </>
    );
};

const assemblyPropTypes = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    host: PropTypes.shape({
        photoUuid: PropTypes.string,
        firstname: PropTypes.string.isRequired,
    }).isRequired,
    place: PropTypes.shape({
        address: PropTypes.shape({
            street: PropTypes.string.isRequired,
            city: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    openedDistributions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            timeStart: PropTypes.string.isRequired,
            timeEnd: PropTypes.string.isRequired,
            hasHomeDelivery: PropTypes.bool.isRequired,
        })
    ),
});
AssemblyWithoutAnyOpenDistributions.propTypes = {
    assembly: assemblyPropTypes,
    assemblies: PropTypes.arrayOf(assemblyPropTypes),
    onClickNearbyAssembly: PropTypes.func,
};

export default AssemblyWithoutAnyOpenDistributions;
