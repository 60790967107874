import reducerRegistry from '../../reducers/ReducerRegistry';
import * as localstorage from 'modules/localstorage';
import * as R from 'ramda';

// actions
export const changeSelectedPickup = (saleId, pickup) => (dispatch, getState) => {
    localstorage.setItem(`delivery.option_${saleId}`, pickup);
    dispatch({
        type: 'SET_PICKUP_SELECTED_SUCCESS',
        payload: {
            selectedPickups: R.assoc(saleId, pickup, getState().delivery.selectedPickups),
        },
    });
};

// reducers
export const deliveryReducer = (
    state = {
        selectedPickups: {},
    },
    action
) => {
    switch (action.type) {
        case 'SET_PICKUP_SELECTED_SUCCESS': {
            return {
                ...state,
                selectedPickups: action.payload.selectedPickups,
            };
        }
        default:
            return state;
    }
};

// store
reducerRegistry.register({
    delivery: deliveryReducer,
});
