import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { getOptionValue } from 'models/delivery';
import {
    getLocalizedDate,
    getIsoWeekday,
    getLocalizedTime,
    getLocalizedDay,
} from 'modules/utils/dateAndTime';
import { getDeliveryOptionInfo } from 'models/delivery';

import Text from 'components/ProductIdentity/Text.jsx';

import Address from 'components/Address.jsx';
import { DELIVERY_OFFER, PICKUP, ASSEMBLY } from 'modules/utils/deliveryOptions';
import useI18n from 'hooks/I18n/useI18n.js';
import LimitOverflowingContentWithEllipsis from 'components/ProductIdentity/LimitOverflowingContentWithEllipsis.jsx';
import DeliveryOptionPrice from 'components/DeliveryOptionPrice.jsx';
import DeliveryAddressForm from 'components/Checkout/ProductIdentity/DeliveryAddressForm.jsx';

const SelectDelivery = ({
    onDeliveryOptionSelected,
    order,
    pickups,
    selectedOption,
    serverError,
}) => {
    const { i18n, trans } = useI18n();
    const { type } = getDeliveryOptionInfo(selectedOption);

    const sortByType = R.sortWith([R.ascend(R.prop('type'))]);
    const sortedPickups = sortByType(pickups);

    return (
        <div>
            <div className="pi-select-delivery-list">
                {sortedPickups.map(pickup => {
                    const optionValue = getOptionValue(pickup, order.distribution.id);
                    return (
                        <React.Fragment key={pickup.name}>
                            <div
                                className={classNames('pi-select-delivery-container', {
                                    selected: selectedOption === optionValue,
                                })}
                                key={optionValue}
                                onClick={() => onDeliveryOptionSelected(optionValue)}
                            >
                                <div className="pi-select-delivery-header">
                                    <Text bold color="gray2" size="16px" lineHeight="24px">
                                        {pickup.name}
                                    </Text>
                                    <Text bold color="gray2" size="16px" lineHeight="24px">
                                        <DeliveryOptionPrice
                                            totalPrice={order.totalPriceWithoutShipping}
                                            deliveryOption={pickup}
                                        ></DeliveryOptionPrice>
                                    </Text>
                                </div>
                                {pickup.meetingPoint && (
                                    <Text color="gray2" size="14px" lineHeight="20px">
                                        <Address address={pickup.meetingPoint.fullAddress} />
                                    </Text>
                                )}

                                {pickup.type === DELIVERY_OFFER && (
                                    <Text color="gray2" size="14px" lineHeight="20px">
                                        <LimitOverflowingContentWithEllipsis expandKey="global.moreInformations">
                                            {pickup.description}
                                        </LimitOverflowingContentWithEllipsis>
                                    </Text>
                                )}

                                {pickup.type === PICKUP && (
                                    <Text
                                        className="pi-select-delivery-date"
                                        color="gray2"
                                        size="14px"
                                        lineHeight="20px"
                                    >
                                        {trans('selectDelivery.pickup')}
                                        <ul>
                                            {R.pipe(
                                                R.sortBy(timeSlot =>
                                                    getIsoWeekday(timeSlot.dayOfWeek)
                                                ),
                                                R.map(timeSlot => (
                                                    <li key={timeSlot.dayOfWeek}>
                                                        {trans('selectDelivery.timeSlot', {
                                                            '%day%': getLocalizedDay(
                                                                i18n,
                                                                getIsoWeekday(timeSlot.dayOfWeek)
                                                            ),
                                                            '%start%': getLocalizedTime(
                                                                i18n,
                                                                timeSlot.timeStart
                                                            ),
                                                            '%end%': getLocalizedTime(
                                                                i18n,
                                                                timeSlot.timeEnd
                                                            ),
                                                        })}
                                                    </li>
                                                ))
                                            )(pickup.timeSlots)}
                                        </ul>
                                    </Text>
                                )}

                                {pickup.type === ASSEMBLY && (
                                    <Text
                                        className="pi-select-delivery-date"
                                        color="gray2"
                                        size="14px"
                                        lineHeight="20px"
                                    >
                                        {trans('selectDelivery.timeSlot', {
                                            '%day%': getLocalizedDate(
                                                i18n,
                                                'I',
                                                order.distribution.distributionDate
                                            ),
                                            '%start%': getLocalizedTime(
                                                i18n,
                                                order.distribution.timeStart
                                            ),
                                            '%end%': getLocalizedTime(
                                                i18n,
                                                order.distribution.timeEnd
                                            ),
                                        })}
                                    </Text>
                                )}
                            </div>
                            {type === DELIVERY_OFFER && selectedOption === optionValue && (
                                <DeliveryAddressForm serverError={serverError} />
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
            <div>{!!serverError && <Text color="danger">{serverError}</Text>}</div>
        </div>
    );
};

SelectDelivery.propTypes = {
    onDeliveryOptionSelected: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
    pickups: PropTypes.array.isRequired,
    selectedOption: PropTypes.string,
    serverError: PropTypes.string,
};

export default SelectDelivery;
