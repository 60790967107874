import React from 'react';
import classnames from 'classnames';
import Icon from 'components/Icon.jsx';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { propType as colorPropType } from 'modules/utils/colors';

export class Text extends React.Component {
    static propTypes = {
        inline: PropTypes.bool,
        children: PropTypes.node,
        dangerouslySetInnerHTML: PropTypes.object,
        className: PropTypes.string,
        color: colorPropType,
        icon: PropTypes.string,
        iconColor: colorPropType,
        iconSize: PropTypes.oneOf(['large', 'medium', 'small']),
        italic: PropTypes.bool,
        onClick: PropTypes.func,
        serif: PropTypes.bool,
        size: PropTypes.oneOf([
            'ignore', // Ignore size/variant stuff
            'x-large',
            'xx-large',
            'large',
            'medium',
            'small',
        ]),
        weight: PropTypes.oneOf(['bold', 'normal']),
        variant: PropTypes.oneOf(['uiMedium', 'uiLarge', 'userMedium', 'userLarge']),
        tagName: PropTypes.string,
        withTag: PropTypes.bool,
    };

    static defaultProps = {
        color: null,
        inline: false,
        serif: false,
    };

    render() {
        const {
            inline,
            children,
            className,
            color,
            dangerouslySetInnerHTML,
            icon,
            italic,
            serif,
            weight,
            tagName,
            withTag,
        } = this.props;

        let { size, variant } = this.props;

        // If the text style is customized, don't default to a variant
        if (!size) {
            if (italic || serif || weight) {
                size = 'medium';
            } else if (!variant) {
                variant = 'uiMedium';
            }
        }

        const classNames = classnames(
            {
                'fa-text': true,
                'fa-text--italic': !variant && italic,
                'fa-text--withIcon': !!icon,
                'fa-text--serif': !variant && serif,
                [`fa-text--${color}`]: color,
                [`fa-text--${size}`]: !variant && size,
                [`fa-text--${weight}`]: !variant && weight,
                [`fa-text--${variant}`]: variant,
                'fa-text--tag': withTag,
            },
            className
        );

        let Tag;

        if (tagName) {
            Tag = tagName;
        } else if (inline) {
            Tag = 'span';
        } else {
            Tag = 'div';
        }

        if (dangerouslySetInnerHTML) {
            return (
                <Tag
                    className={classNames}
                    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                    onClick={this.props.onClick}
                />
            );
        }

        const renderedChildren = [];
        if (icon) {
            renderedChildren.push(
                <Icon
                    src={icon}
                    className="fa-text-icon"
                    color={this.props.iconColor ? this.props.iconColor : this.props.color}
                    size={this.props.iconSize ? this.props.iconSize : this.props.size}
                    key="icon"
                />
            );
        }
        if (!isNil(children)) {
            renderedChildren.push(children);
        }

        return (
            <Tag className={classNames} onClick={this.props.onClick}>
                {renderedChildren}
            </Tag>
        );
    }
}

export default Text;
