import { get, post } from 'modules/api';

/**
 *
 * @param {number} farmId
 * @returns {Promise}
 */
export const fetchOnSiteSalesHistory = farmId => get(`onsite-sales/history/${farmId}`);

/**
 *
 * @param {number} assemblyId
 * @returns {Promise}
 */
export const fetchOnSiteSalesAssemblyHistory = assemblyId =>
    get(`onsite-sales/assembly-history/${assemblyId}`);

/**
 *
 * @param {number} farmId
 * @returns {Promise}
 */
export const getDistributions = farmId => {
    return get(`onsite-sales/${farmId}/distributions`);
};

/**
 *
 * @param {number} _.distributionId
 * @param {number} _.farmId
 * @returns {Promise}
 */
export const getPaymentsHistory = ({ distributionId, farmId }) => {
    return get(`onsite-sales/farm/${farmId}/distribution/${distributionId}/payments`);
};

/**
 *
 * @param {string} _.phoneNumber
 * @param {number} _.farmId
 * @param {number} _.amount
 * @param {number} _.distributionId
 * @param {number} _.memberId
 * @param {string} _.sendBy
 * @param {array} _.vatDetails
 * @returns {Promise}
 */
export const postPayment = payload => {
    return post('onsite-sales/pay', payload);
};
