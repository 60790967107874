/* eslint callback-return: "off" */
import AnalyticsSingleton from 'modules/analytics';

export default function analyticsMiddleware() {
    const GA = AnalyticsSingleton;

    return ({ dispatch, getState }) => next => action => {
        const result = next(action);

        if (typeof action.analytics === 'function') {
            action.analytics({ dispatch, getState, GA });
            return result;
        }

        return result;
    };
}
