import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'components/ui';
import connectToI18n from 'modules/i18n/connectToI18n';
import { getLocalizedDate } from 'modules/utils/dateAndTime';
import ProductCard from './ProductCard.jsx';

export class ProductsByDistribution extends React.Component {
    static propTypes = {
        distributionDate: PropTypes.string.isRequired,
        i18n: PropTypes.string.isRequired,
        products: PropTypes.array.isRequired,
        onRateProduct: PropTypes.func.isRequired,
        trans: PropTypes.func.isRequired,
        userPhotoId: PropTypes.string,
    };

    render() {
        const { distributionDate, i18n, trans, userPhotoId } = this.props;

        return (
            <div>
                <Heading size={4} className="u-mb-3">
                    {trans('products.feedback.order_title', {
                        '%date%': getLocalizedDate(i18n, 'I', distributionDate),
                    })}
                </Heading>
                <Box className="u-mb-10" padding="large" hasSections>
                    {this.props.products.map(product => (
                        <ProductCard
                            key={product.identifier}
                            product={product}
                            onRate={this.props.onRateProduct}
                            userPhotoId={userPhotoId}
                        />
                    ))}
                </Box>
            </div>
        );
    }
}

export default connectToI18n(ProductsByDistribution);
