import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { MediaObject, Text } from 'components/ui';
import LimitOverflowingContentWithEllipsis from 'components/LimitOverflowingContentWithEllipsis.jsx';
import DeliveryOptionInfo from 'components/DeliveryOptionInfo.jsx';
import { DELIVERY_OFFER } from 'modules/utils/deliveryOptions';

const Pickup = ({ pickup }) => (
    <MediaObject
        media={<img alt="" src="/assets/images/marker-pickup.svg" width="36" />}
        size="xsmall"
        className="u-mb-2"
    >
        {pickup.meetingPoint && (
            <Text>
                <DeliveryOptionInfo
                    timeSlotsEmphasis
                    timeSlots={pickup.timeSlots}
                    address={pickup.meetingPoint.fullAddress}
                />
            </Text>
        )}

        {pickup.type === DELIVERY_OFFER && (
            <Fragment>
                <Text weight="bold">{pickup.name}</Text>
                <LimitOverflowingContentWithEllipsis background="white" maxChars={100}>
                    {pickup.description}
                </LimitOverflowingContentWithEllipsis>
            </Fragment>
        )}
    </MediaObject>
);

Pickup.propTypes = {
    pickup: PropTypes.object.isRequired,
};

export default Pickup;
