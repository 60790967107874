import React, { forwardRef, useCallback, useState } from 'react';
import InlineSVG from 'svg-inline-react';
import eyeOpen from 'app/assets/new-design/images/icons/icon-eye-open.svg';
import eyeClosed from 'app/assets/new-design/images/icons/icon-eye-closed.svg';
import Input from 'src/components/atoms/Input/Input.jsx';

const PASSWORD_TYPE = 'password';
const TEXT_TYPE = 'text';

const ICONS = {
    [PASSWORD_TYPE]: eyeOpen,
    [TEXT_TYPE]: eyeClosed,
};
const Password = forwardRef((props, ref) => {
    const [type, setType] = useState(PASSWORD_TYPE);
    const onClick = useCallback(() => setType(type === PASSWORD_TYPE ? TEXT_TYPE : PASSWORD_TYPE), [
        type,
    ]);

    const suffix = <InlineSVG className="nd-password-icon" src={ICONS[type]} onClick={onClick} />;

    return <Input ref={ref} {...props} type={type} suffix={suffix} />;
});

export default Password;
