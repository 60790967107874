import React from 'react';
import PropTypes from 'prop-types';

import useI18n from 'hooks/I18n/useI18n.js';
import Impersonate from 'modules/impersonate';

import Link, { DISPLAY_MODES } from 'src/components/atoms/Link/Link.jsx';
import Text, { SMALL } from 'src/components/atoms/Text/Text.jsx';

const LoginLayout = ({ children }) => {
    const { trans, i18nConfig, i18n } = useI18n();
    const logo = <img src={i18nConfig.logos.blue} />;
    const impersonatedEmail = Impersonate.getImpersonated();

    return (
        <div className="nd-login-template">
            <header className="header">
                {/** TODO: remove data-bypass when backbone was remove (actually is necessary because the action was transformaing in modules/utils/index.js) */}
                <a href={`/${i18n}`} data-bypass>
                    {logo}
                </a>
            </header>
            <main>
                <div className="blocks">
                    {impersonatedEmail && <div>Impersonating: {Impersonate.getImpersonated()}</div>}
                    {children}
                </div>
            </main>
            <footer className="footer">
                <div>
                    <Text size={SMALL}>
                        <Link
                            displayMode={DISPLAY_MODES.BLACK_LINK}
                            href={`/${i18n}/p/legal-mentions`}
                            target="_blank"
                            bypassRouter
                        >
                            {trans('publicFooter.links.legal')}
                        </Link>
                    </Text>

                    <Text size={SMALL}>
                        <Link
                            displayMode={DISPLAY_MODES.BLACK_LINK}
                            href={`/${i18n}/p/terms`}
                            target="_blank"
                            bypassRouter
                        >
                            {trans('publicFooter.links.terms')}
                        </Link>
                    </Text>
                </div>
                <div>
                    <Text size={SMALL}>
                        <a
                            href={trans('privacy_policy_link')}
                            target="_blank"
                            className="nd-link nd-link--black"
                        >
                            {trans('footer_privacy_policy')}
                        </a>
                    </Text>
                    {i18nConfig?.links?.ourDatas && (
                        <React.Fragment>
                            <Text size={SMALL}>
                                <a
                                    href={i18nConfig.links.ourDatas}
                                    target="_blank"
                                    className="nd-link nd-link--black"
                                >
                                    {trans('publicFooter.links.data')}
                                </a>
                            </Text>
                        </React.Fragment>
                    )}
                </div>
            </footer>
        </div>
    );
};

LoginLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

LoginLayout.defaultProps = {
    children: '',
};

export default LoginLayout;
