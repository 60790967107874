import { FETCH_BASKET_REQUEST_SENT, FETCH_BASKET_SUCCEEDED } from './actions.basket';
import { CHANGE_DELIVERY_OPTION, SUBMIT_DELIVERY_OPTION_SUCCEEDED } from './actions.pickup';
import { computeBasketPrices } from 'modules/basketUtils.js';

export const checkoutReducer = (
    state = {
        basket: null,
        deliveryOptions: [],
        isFetchingBasket: false,
        basketPrices: null,
        selectedOption: null,
    },
    action
) => {
    switch (action.type) {
        case FETCH_BASKET_REQUEST_SENT:
            return {
                ...state,
                isFetchingBasket: true,
            };
        case FETCH_BASKET_SUCCEEDED:
            return {
                ...state,
                basket: action.basket,
                deliveryOptions: action.deliveryOptions,
                isFetchingBasket: false,
            };
        case SUBMIT_DELIVERY_OPTION_SUCCEEDED:
            return {
                ...state,
                basket: action.basket,
            };
        case CHANGE_DELIVERY_OPTION:
            const basketPrices = computeBasketPrices(
                action.selectedOption,
                state.basket.totalPriceWithoutShipping,
                state.deliveryOptions
            );
            return {
                ...state,
                selectedOption: action.selectedOption,
                basketPrices,
            };
        default:
            return state;
    }
};
