import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import InlineSVG from 'svg-inline-react';

import { filerPhoto } from 'modules/utils/filer';

import useI18n from 'hooks/I18n/useI18n';

import { getLatLng } from 'app/scripts/components/geo/placeHelpers.js';

import Text from 'components/ProductIdentity/Text.jsx';
import Heading from 'components/Heading.jsx';
import LimitOverflowingContentWithEllipsis from 'components/ProductIdentity/LimitOverflowingContentWithEllipsis.jsx';
import Link from 'src/components/atoms/Link/Link.jsx';

import LocationSVG from 'app/assets/new-design/images/icons/location.svg';
import NotificationSVG from 'app/assets/new-design/images/icons/notification.svg';

const splitAddressString = address =>
    address.split(', ').filter(addressPart => addressPart !== 'France');

const AnonymousHivePanel = ({ assembly }) => {
    const { trans, i18n } = useI18n();
    const assemblyCoordinates = getLatLng('assembly', assembly);

    return (
        <>
            <Heading size={4} rank={2} productIdentity>
                {`${assembly.place.address.city.name} - ${assembly.name}`}
            </Heading>
            <div className="pi-hive-panel-leader">
                <img src={filerPhoto(R.path(['leader', 'photo', 'id'], assembly), 'small')}></img>
                <div>
                    <Text size="16px" lineHeight="24px" weight={600} color="gray2">
                        {assembly.leader.firstName}
                    </Text>
                    <Text size="14px" lineHeight="20px" color="gray2">
                        {trans('hives.page.leaderRole')}
                    </Text>
                </div>
            </div>
            <Text size="16px" lineHeight="24px" color="gray2">
                <LimitOverflowingContentWithEllipsis
                    reduceKey="global.show.less"
                    expandKey="global.show.more"
                >
                    {assembly.description}
                </LimitOverflowingContentWithEllipsis>
            </Text>
            <div className="pi-hive-panel-sections">
                {/* Address */}
                <div className="pi-hive-panel-section">
                    <div>
                        <Text
                            className="pi-hive-panel-section-title"
                            size="16px"
                            lineHeight="24px"
                            weight={600}
                            color="gray2"
                        >
                            <InlineSVG raw src={LocationSVG} />
                            {trans('global.address')}
                        </Text>
                        {typeof assembly.place.address === 'string' ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: splitAddressString(assembly.place.address).join(
                                        '<br/>'
                                    ),
                                }}
                            />
                        ) : (
                            <>
                                <Text size="14px" lineHeight="20px" weight={400} color="gray2">
                                    {assembly.place.name}
                                    <br />
                                    {assembly.place.address.street}
                                    <br />
                                    {`${assembly.place.address.city.zipCode} ${assembly.place.address.city.name}`}
                                </Text>
                            </>
                        )}
                    </div>
                    <Link
                        bypassRouter
                        href={`/${i18n}/assemblies#13/${assemblyCoordinates.lat}/${assemblyCoordinates.lng}`}
                    >
                        {trans('farms.distributions.address.action')}
                    </Link>
                </div>
                {/* Stay inform */}
                <div className="pi-hive-panel-section">
                    <div>
                        <Text
                            className="pi-hive-panel-section-title"
                            size="16px"
                            lineHeight="24px"
                            weight={600}
                            color="gray2"
                        >
                            <InlineSVG raw src={NotificationSVG} />
                            {trans('anonymous.context.information.title')}
                        </Text>
                        <Text size="14px" lineHeight="20px" weight={400} color="gray2">
                            {trans('anonymous.context.information.text')}
                        </Text>
                    </div>
                    <Link bypassRouter href={`/${i18n}/join/assembly/${assembly.id}`}>
                        {trans('anonymous.context.information.link')}
                    </Link>
                </div>
                {/* hives find */}
                <div className="pi-hive-panel-section">
                    <div>
                        <Text
                            className="pi-hive-panel-section-title"
                            size="16px"
                            lineHeight="24px"
                            weight={600}
                            color="gray2"
                        >
                            <InlineSVG raw src={LocationSVG} />
                            {trans('anonymous.context.hive.title')}
                        </Text>
                        <Text size="14px" lineHeight="20px" weight={400} color="gray2">
                            {trans('brand.name')} {trans('anonymous.context.hive.text')}
                        </Text>
                    </div>
                    <Link bypassRouter href={`/${i18n}/assemblies`}>
                        {trans('hives.find.title')}
                    </Link>
                </div>
            </div>
        </>
    );
};

AnonymousHivePanel.propTypes = {
    assembly: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        theme: PropTypes.shape({ name: PropTypes.string }),
        _links: PropTypes.shape({ currentSale: PropTypes.object }),
        place: PropTypes.shape({
            address: PropTypes.shape({ city: PropTypes.shape({ name: PropTypes.string }) }),
        }),
    }),
};
export default AnonymousHivePanel;
