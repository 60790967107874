import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import Tag, { THEME_SELECTED_GREY } from 'src/components/atoms/Tag/Tag.jsx';

export const CategoryFilter = ({ categoryId, tag, filter, isActive }) => {
    return (
        <NavLink
            to={`/category/${categoryId}/${tag.id}?filter=${filter}`}
            data-bypass="true"
            isActive={isActive}
        >
            <Tag selected={isActive()} label={tag.name} theme={THEME_SELECTED_GREY} />
        </NavLink>
    );
};

CategoryFilter.propTypes = {
    categoryId: PropTypes.string.isRequired,
    tag: PropTypes.object.isRequired,
    filter: PropTypes.string,
    isActive: PropTypes.func.isRequired,
};

export default CategoryFilter;
