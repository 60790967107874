import React from 'react';
import PropTypes from 'prop-types';
import { BoxSection, Button, Heading, Text } from 'components/ui';
import { TextareaGroup } from 'components/ui/form';
import connectToI18n from 'modules/i18n/connectToI18n';
import { filerPhoto } from 'modules/utils/filer';
import { RATING_TRANS } from './ratingValues.js';
import Avatar from 'components/Avatar.jsx';

export class RatingCommentSection extends React.Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        onSendingComment: PropTypes.func.isRequired,
        trans: PropTypes.func.isRequired,
    };

    state = {
        comment: '',
    };

    onClick = () => {
        this.props.onSendingComment(this.state.comment);
    };

    onWriting = (name, value) => {
        this.setState({
            comment: value,
        });
    };

    render() {
        const rating = Number(this.props.product.rating);
        const farmName = this.props.product.farm;
        const farmerName = this.props.product.farmer;
        const farmPhotoUrl = filerPhoto(this.props.product.farm_photo, 'small', 'user');
        const { trans } = this.props;

        return (
            <BoxSection alternate>
                <p>{trans(RATING_TRANS[rating])}</p>
                <div className="u-flexible-row u-mb-2">
                    <div className="u-flexible-item">
                        <Heading size={4}>
                            {trans('products.feedback.comment.send_to', {
                                '%farmerName%': farmerName,
                            })}
                        </Heading>
                        <Text color="info">{farmName}</Text>
                    </div>
                    <Avatar src={farmPhotoUrl} rounded size="small" />
                </div>
                <TextareaGroup
                    variant="inverse"
                    className="formQuestions-textarea u-mb-4"
                    rows={3}
                    onChange={this.onWriting}
                    value={this.state.comment}
                    name="comment"
                />
                <Button
                    variant="success"
                    onClick={this.onClick}
                    disabled={this.state.comment.trim() === ''}
                >
                    {trans('global.send')}
                </Button>
            </BoxSection>
        );
    }
}

export default connectToI18n(RatingCommentSection);
