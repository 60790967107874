import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { isStockIntoAlertZone } from 'modules/stockUtils';
import classnames from 'classnames';

class Tag extends React.Component {
    static propTypes = {
        isMultiOffers: PropTypes.bool.isRequired,
        availableStock: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        trans: PropTypes.func.isRequired,
        transChoice: PropTypes.func.isRequired,
        isNewInAssembly: PropTypes.bool.isRequired,
        alreadyBought: PropTypes.bool,
        isAlreadyBoughtCategory: PropTypes.bool,
        promotionType: PropTypes.string,
        className: PropTypes.string,
        size: PropTypes.oneOf(['small', 'medium']),
        openSale: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        size: 'small',
    };
    renderTagContent = () => {
        const {
            availableStock,
            isMultiOffers,
            trans,
            transChoice,
            alreadyBought,
            isNewInAssembly,
            isAlreadyBoughtCategory,
            promotionType,
            openSale,
        } = this.props;

        if (isStockIntoAlertZone(availableStock) && !isMultiOffers) {
            return transChoice('salePage.stockAlert', availableStock, {
                '%stockAvailable%': availableStock,
            });
        }
        if (promotionType === 'custom_reason') {
            return trans('offer.promotion.reason.custom');
        }
        if (promotionType) {
            return trans(`offer.promotion.reason.${promotionType}`);
        }
        if (isNewInAssembly) {
            return trans('salePage.product.new');
        }
        if (alreadyBought && !isAlreadyBoughtCategory) {
            return trans('salePage.alreadyBought');
        }

        if (openSale) {
            return trans('hive.openSale');
        }
        return null;
    };

    render() {
        const tagContent = this.renderTagContent();

        if (tagContent) {
            return (
                <div
                    className={classnames(
                        'productCard-tag',
                        `productCard-tag--${this.props.size}`,
                        this.props.className
                    )}
                >
                    {tagContent}
                </div>
            );
        }
        return null;
    }
}

export default connectToI18n(Tag);
