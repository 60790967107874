import PropTypes from 'prop-types';
import React from 'react';
import { getHighlightedProducers } from 'api/hives';
import { INITIAL_LOADING, READY } from 'modules/utils/ajaxStatuses';
import HighlightCluster from './HighlightCluster.jsx';

class HighlightClustersContainer extends React.Component {
    static propTypes = {
        assembly: PropTypes.object.isRequired,
    };

    state = {
        clusters: [],
        status: INITIAL_LOADING,
    };

    componentDidMount() {
        Promise.all([getHighlightedProducers(this.props.assembly?.id)]).then(([clusters]) => {
            this.setState({ clusters, status: READY });
        });
    }

    render() {
        return <HighlightCluster {...this.props} {...this.state} />;
    }
}

export default HighlightClustersContainer;
