import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NavLink, generatePath } from 'react-router-dom';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import useI18n from 'hooks/I18n/useI18n';

import {
    ALL_PRODUCTS_CATEGORY,
    ALREADY_BOUGHT_CATEGORY,
    ALL_PRODUCTS_FILTER,
    ALL_TAGS_FILTER,
    ORGANIC_FILTER,
} from 'modules/utils/saleCategoriesAndFilters';
import { titleCase, toLowerCase } from 'modules/utils/string';
import { applyTagTrans } from 'models/tag';

import PIText from 'components/ProductIdentity/Text.jsx';
import Button from 'components/Button.jsx';
import CategoryFilter from 'components/Sale/ProductFilter/CategoryFilter.jsx';
import Switch, {
    THEME_SELECTED_GREY as SWITCH_THEME_SELECTED_GREY,
} from 'src/components/atoms/Switch/Switch.jsx';
import Tag, { THEME_SELECTED_GREY } from 'src/components/atoms/Tag/Tag.jsx';

export const CurrentCategoryInformation = props => {
    const {
        categoryHasOrganicProducts,
        currentCategory,
        currentFarmId,
        currentFarms,
        currentFilter,
        currentSubCategories,
        currentSubCategoryId,
        isFilterMobilePanelOpen,
        onClickFunction,
        toggleFilterMobilePanel,
        isSmallWidth,
        filterList,
        activeFilter,
        showFarmAnchor,
        tagList,
        currentTag,
        productsCount,
    } = props;
    const { trans, transChoice } = useI18n();
    const history = useHistory();

    const getPath = useCallback(
        (tagKey, subCategoryId) =>
            generatePath(
                '/category/:categoryId/:subCategoryId?/?filter=:filterBio&filterTag=:filterTag',
                {
                    categoryId: currentCategory.id,
                    subCategoryId,
                    filterBio: currentFilter,
                    filterTag: tagKey,
                }
            ),
        [currentCategory, currentFilter]
    );

    const renderFarmAnchor = useCallback(
        farm => (
            <div
                className={classnames('sale-content-infoFarm', {
                    'sale-content-infoFarm--active': currentFarmId && currentFarmId === farm.id,
                })}
                key={farm.id}
                onClick={() => onClickFunction(farm.id)}
            >
                {titleCase(toLowerCase(farm.name))}
            </div>
        ),
        [currentFarmId, onClickFunction]
    );
    return (
        <div>
            <div className="sale-content-info">
                <div className="sale-content-subCategoriesPanel">
                    {isSmallWidth && categoryHasOrganicProducts && (
                        <Button
                            weight="link"
                            onClick={toggleFilterMobilePanel}
                            className={classnames('saleContent-displaySubCategoriesButton', {
                                active: isFilterMobilePanelOpen,
                            })}
                        >
                            {trans('filters.filterProducts')}
                        </Button>
                    )}
                    <div
                        className={classnames('saleContent-subCategories', {
                            active: isFilterMobilePanelOpen,
                        })}
                    >
                        {categoryHasOrganicProducts && (
                            <>
                                {!isSmallWidth && (
                                    <PIText
                                        className="sale-content-info-filter-title"
                                        bold
                                        color="gray2"
                                        size="16px"
                                        lineHeight="24px"
                                    >
                                        {trans('salePage.filter.type')}
                                    </PIText>
                                )}
                                <Switch
                                    className="sale-content-info-organic-switch"
                                    label={trans('salePage.organicProducts')}
                                    onClick={() =>
                                        history.replace(
                                            `?filter=${
                                                currentFilter === ORGANIC_FILTER
                                                    ? ALL_PRODUCTS_FILTER
                                                    : ORGANIC_FILTER
                                            }`
                                        )
                                    }
                                    checked={currentFilter === ORGANIC_FILTER}
                                    theme={SWITCH_THEME_SELECTED_GREY}
                                />
                            </>
                        )}
                        {!isSmallWidth &&
                            !R.contains(currentCategory.id, [
                                ALL_PRODUCTS_CATEGORY,
                                ALREADY_BOUGHT_CATEGORY,
                            ]) && (
                                <div>
                                    {!isSmallWidth && (
                                        <PIText
                                            className="sale-content-info-filter-title"
                                            bold
                                            color="gray2"
                                            size="16px"
                                            lineHeight="24px"
                                        >
                                            {trans('products.categories')}
                                        </PIText>
                                    )}
                                    <div className="sale-content-info-filter-tags">
                                        <NavLink
                                            to={`/category/${currentCategory.id}?filter=${currentFilter}`}
                                            data-bypass="true"
                                            isActive={() =>
                                                currentSubCategoryId === null &&
                                                activeFilter === null
                                            }
                                        >
                                            <Tag
                                                label={trans('salePage.all')}
                                                selected={
                                                    currentSubCategoryId === null &&
                                                    activeFilter === null
                                                }
                                                theme={THEME_SELECTED_GREY}
                                            />
                                        </NavLink>
                                        {currentSubCategories.map(subCategory => {
                                            return (
                                                <CategoryFilter
                                                    key={subCategory.subCategoryKey}
                                                    categoryId={currentCategory.id}
                                                    tag={{
                                                        id: subCategory.subCategoryId,
                                                        name: subCategory.subCategoryName,
                                                    }}
                                                    filter={currentFilter}
                                                    isActive={() =>
                                                        subCategory.subCategoryId ===
                                                        currentSubCategoryId
                                                    }
                                                />
                                            );
                                        })}
                                        {filterList.map(filter => {
                                            return (
                                                <CategoryFilter
                                                    key={filter.key}
                                                    categoryId={currentCategory.id}
                                                    tag={{
                                                        id: filter.id,
                                                        name: filter.name,
                                                    }}
                                                    filter={currentFilter}
                                                    isActive={() => filter.id === activeFilter}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
                {!isSmallWidth && tagList.length > 0 && (
                    <>
                        <PIText
                            className="sale-content-info-filter-title"
                            bold
                            color="gray2"
                            size="16px"
                            lineHeight="24px"
                        >
                            {trans('salePage.filter.tags')}
                        </PIText>
                        <div className="sale-content-info-filter-tags">
                            <NavLink
                                to={getPath(ALL_TAGS_FILTER, activeFilter || currentSubCategoryId)}
                                data-bypass="true"
                                isActive={() => currentTag === ALL_TAGS_FILTER}
                            >
                                <Tag
                                    label={trans('salePage.all')}
                                    selected={currentTag === ALL_TAGS_FILTER}
                                    theme={THEME_SELECTED_GREY}
                                />
                            </NavLink>
                            {tagList.map(tag => (
                                <NavLink
                                    to={getPath(tag, activeFilter || currentSubCategoryId)}
                                    data-bypass="true"
                                    isActive={() => currentTag === tag}
                                >
                                    <Tag
                                        label={applyTagTrans(trans, transChoice)(tag)}
                                        selected={currentTag === tag}
                                        theme={THEME_SELECTED_GREY}
                                    />
                                </NavLink>
                            ))}
                        </div>
                    </>
                )}

                {showFarmAnchor ? (
                    <>
                        <PIText
                            className="sale-content-info-filter-title"
                            bold
                            color="gray2"
                            size="16px"
                            lineHeight="24px"
                        >
                            {trans('navigation.left.producers')}
                        </PIText>
                        {R.map(renderFarmAnchor)(currentFarms)}
                    </>
                ) : (
                    <PIText color="gray2" size="12px" lineHeight="20px">
                        {`${transChoice('salePage.producerCount', currentFarms.length, {
                            '%producerCount%': currentFarms.length,
                        })} • ${transChoice('salePage.productCount', productsCount, {
                            '%productsCount%': productsCount,
                        })}`}
                    </PIText>
                )}
            </div>
        </div>
    );
};

CurrentCategoryInformation.propTypes = {
    categoryHasOrganicProducts: PropTypes.bool,
    currentCategory: PropTypes.object.isRequired,
    currentFarmId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currentFarms: PropTypes.array.isRequired,
    currentFilter: PropTypes.string,
    currentSubCategories: PropTypes.array,
    currentSubCategoryId: PropTypes.number,
    isFilterMobilePanelOpen: PropTypes.bool,
    onClickFunction: PropTypes.func.isRequired,
    toggleFilterMobilePanel: PropTypes.func,
    isSmallWidth: PropTypes.bool.isRequired,
    filterList: PropTypes.array,
    activeFilter: PropTypes.number,
    showFarmAnchor: PropTypes.bool,
    tagList: PropTypes.array,
    currentTag: PropTypes.string,
    productsCount: PropTypes.number,
};
CurrentCategoryInformation.defaultProps = {
    currentSubCategories: [],
    currentSubCategoryId: null,
    filterList: [],
    activeFilter: null,
    showFarmAnchor: true,
    tagList: [],
    currentTag: ALL_TAGS_FILTER,
    productsCount: 0,
};
export default CurrentCategoryInformation;
