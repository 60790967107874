import { Grid } from 'components/ui';
import React from 'react';

const EmptyLastDiscussions = () => {
    return (
        <Grid sm="6|6">
            <div>
                <img src="/assets/images/illustrations/community-left.png" className="u-w-100" />
            </div>
            <div className="hidden-xs">
                <img src="/assets/images/illustrations/community-right.png" className="u-w-100" />
            </div>
        </Grid>
    );
};

export default EmptyLastDiscussions;
