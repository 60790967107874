// Used to detect the origin when I edit quantity to basket

export const PRODUCTS_LIST = 'PRODUCTS LIST PAGE > horizontal products card';
export const POPULAR_PRODUCTS_BY_FARM = 'PRODUCTS LIST PAGE > popular products by farm';
export const PRODUCTS_SELECTION = 'HIGHLIGHTED PAGE > products selection';
export const POPULAR_PRODUCTS_BY_ASSEMBLY = 'HIGHLIGHTED PAGE > popular products by assembly';
export const POPULAR_PRODUCTS_BY_MEMBER = 'HIGHLIGHTED PAGE > popular prooducts by member';
export const GOOD_DEALS_ON_HIGHLIGHT = 'HIGHLIGHTED PAGE > good deals';
export const NEW_ASSEMBLY_PRODUCTS = 'HIGHLIGHTED PAGE > new assembly products';
export const BASKET_PAGE = 'BASKET PAGE';
export const BASKET_RESUME = 'SALES PAGE > basket panel';
export const SEARCH_RESULTS = 'SEARCH RESULTS PAGE';
export const PRODUCT_PAGE = 'PRODUCT PAGE > detailed product card';
export const OTHER_PRODUCTS = 'PRODUCT PAGE > other products';
