import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { imperativeEditUser, userSelector } from 'modules/currentUser';
import { redirectTo } from 'modules/utils';
import { userJoinAssembly } from 'api/hives';
import { isMemberOfAssembly } from 'models/users';
import useAnalytics from 'hooks/Analytics/useAnalytics';
import useI18n from 'hooks/I18n/useI18n';
import Button from 'src/components/atoms/Button/Button.jsx';
import Text, { SMALL } from 'src/components/atoms/Text/Text.jsx';
import HiveJoinedConfirmation from './HiveJoinedConfirmation.jsx';
import HostCard from './HostCard.jsx';

export const ConstructAssembly = ({ assembly }) => {
    const { i18n, trans } = useI18n();
    const { analytics } = useAnalytics();
    const dispatch = useDispatch();
    const [hiveWasJoined, setHiveWasJoined] = useState(false);
    const user = useSelector(userSelector);

    useEffect(() => {
        setHiveWasJoined(false);
    }, [assembly]);

    const assemblyAddress = assembly.place.address;
    const userIsAlreadyMemberOfHive = isMemberOfAssembly(assembly.id, user);

    return (
        <>
            <div className="map-assembly-and-distribution-block">
                <HostCard
                    url={`/${i18n}/assemblies/${assembly.id}`}
                    photoUuid={assembly.host.photoUuid}
                    cityName={assemblyAddress.city.name}
                    pointOfSaleName={assembly.name}
                    street={assemblyAddress.street}
                    hostFirstname={assembly.host.firstname}
                />
                {!hiveWasJoined && (
                    <div className="construct-assembly-block">
                        <img src="/assets/images/wheelbarrow_construct_assembly.svg" />
                        <Text className="construct-assembly-text" size={SMALL}>
                            {trans('assembliesMap.modal.constructAssembly.text')}
                        </Text>
                    </div>
                )}
                {!hiveWasJoined && !userIsAlreadyMemberOfHive && (
                    <div className="join-this-hive-container">
                        <Text size={SMALL} className="helper-text">
                            {trans('assembliesMap.modal.joinThisHive.helper')}
                        </Text>
                        <Button
                            onClick={() => {
                                if (!!user.anonymous) {
                                    redirectTo(`join/assembly/${assembly.id}`);
                                } else {
                                    userJoinAssembly(assembly.id, user.id).then(
                                        assemblyResponse => {
                                            dispatch(
                                                imperativeEditUser(
                                                    R.evolve({
                                                        hivesAsMember: R.append(assemblyResponse),
                                                    })
                                                )
                                            );

                                            analytics.trackAssemblyJoin(
                                                assemblyResponse.id,
                                                assemblyResponse.name
                                            );
                                        }
                                    );

                                    setHiveWasJoined(true);
                                }
                            }}
                        >
                            <Text>{trans('assembliesMap.modal.joinThisHive.btn')}</Text>
                        </Button>
                    </div>
                )}
                {hiveWasJoined && <HiveJoinedConfirmation />}
            </div>
        </>
    );
};

ConstructAssembly.propTypes = {
    assembly: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        host: PropTypes.shape({
            photoUuid: PropTypes.string,
            firstname: PropTypes.string.isRequired,
        }).isRequired,
        place: PropTypes.shape({
            address: PropTypes.shape({
                street: PropTypes.string.isRequired,
                city: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                }).isRequired,
            }).isRequired,
        }).isRequired,
    }),
};

export default ConstructAssembly;
