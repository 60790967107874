import TranslationSet from './TranslationSet';
import IntervalSymbol from './IntervalSymbol';
import Interval from './Interval';

const FIRST_CHARACTER_POSITION = 0;
const SECOND_CHARACTER_POSITION = 1;
const SET_PATTERN = /^\{-?\d+(,-?\d+)*\}/;
const INTERVAL_PATTERN = /^[\[\]]-?(\d+|Inf),-?(\d+|Inf)[\[\]]/;
const SEPARATOR_CHARACTER = ',';

function stringIsLikeASet(string) {
    return SET_PATTERN.test(string);
}

function stringIsLikeAnInterval(string) {
    return INTERVAL_PATTERN.test(string);
}

function IntervalParser() {}

IntervalParser.prototype.parse = function(string) {
    if (stringIsLikeASet(string)) {
        return this.createSetFromString(string);
    }
    if (stringIsLikeAnInterval(string)) {
        return this.createIntervalFromString(string);
    }
    throw new Error(`Parse error in string "${string}"`);
};

IntervalParser.prototype.createSetFromString = function(string = '') {
    const interval = string.substr(1, string.length - 2);
    const data = interval.split(SEPARATOR_CHARACTER);
    return new TranslationSet(data);
};

IntervalParser.prototype.createIntervalFromString = function(string) {
    const parts = string.split(SEPARATOR_CHARACTER);
    const leftPart = parts[0];
    const rightPart = parts[1];
    const leftNumberText = leftPart.substr(SECOND_CHARACTER_POSITION);
    const rightNumberText = rightPart.substr(FIRST_CHARACTER_POSITION, rightPart.length - 1);
    const rightSymbol = new IntervalSymbol(rightPart[rightPart.length - 1]);
    const leftSymbol = new IntervalSymbol(leftPart[FIRST_CHARACTER_POSITION]);

    return new Interval(leftSymbol, leftNumberText, rightNumberText, rightSymbol);
};

export default IntervalParser;
