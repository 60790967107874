import React from 'react';
import * as R from 'ramda';
import { get, put } from 'modules/api';
import { INITIAL_LOADING, READY } from 'modules/utils/ajaxStatuses';
import ProductFeedbackPage from './ProductFeedbackPage.jsx';

export class ProductFeedbackContainer extends React.Component {
    state = {
        products: [],
        status: INITIAL_LOADING,
    };

    componentDidMount() {
        get('feedbacks/products').then(products => {
            this.setState({
                products,
                status: READY,
            });
        });
    }

    onRateProduct = (productIdentifer, rating, comment) => {
        const [distribution, product] = productIdentifer.split('_');

        put('feedbacks/products', { distribution, product, rating, comment });

        this.setState(
            R.evolve({
                products: R.map(
                    R.when(
                        R.propEq('identifier', productIdentifer),
                        R.mergeDeepLeft({ rating, comment })
                    )
                ),
            })
        );
    };

    render() {
        const productsByDistribution = R.groupBy(R.prop('distributiondate'), this.state.products);

        return (
            <ProductFeedbackPage
                onRateProduct={this.onRateProduct}
                productsByDistribution={productsByDistribution}
                status={this.state.status}
            />
        );
    }
}

export default ProductFeedbackContainer;
