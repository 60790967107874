import PropTypes from 'prop-types';
import React from 'react';
import Heading from 'components/Heading.jsx';
import Box from 'components/Box.jsx';
import BoxSection from 'components/BoxSection.jsx';
import connectToI18n from 'modules/i18n/connectToI18n';
import Utils from 'modules/utils';
const { linkTo } = Utils;

export class ConstructionBox extends React.Component {
    static propTypes = {
        trans: PropTypes.func.isRequired,
        assemblyId: PropTypes.number.isRequired,
        hostId: PropTypes.number.isRequired,
        userIsHost: PropTypes.bool,
        userIsMember: PropTypes.bool,
    };

    render() {
        const { trans, assemblyId, hostId, userIsHost, userIsMember } = this.props;

        return (
            <Box className="constructionBox u-mb-8">
                <BoxSection className="constructionBox-header">
                    {trans('hives.constructionBox.title')}
                </BoxSection>
                <BoxSection bordered className="constructionBox-content">
                    <Heading size={4} className="u-mb-2">
                        {trans('hives.constructionBox.subTitle')}
                    </Heading>
                    <img
                        src="/assets/images/illustrations/Assembly.png"
                        className="u-scaleMedia constructionBox-illustration visible-xs"
                    />
                    <div className="row u-mb-2">
                        <div className="col-sm-8">
                            <p>{trans('hives.constructionBox.paragraph1')}</p>
                            <p>{trans('hives.constructionBox.paragraph2')}</p>
                        </div>
                        <div className="col-sm-4">
                            <img
                                src="/assets/images/illustrations/Assembly.png"
                                className="u-scaleMedia constructionBox-illustration hidden-xs"
                            />
                        </div>
                    </div>
                    <div className="constructionBox-actions">
                        {!userIsHost && (
                            <a href={linkTo(`messages/contact/${hostId}`)} className="button">
                                {trans('hives.pageHeaderAsMember.contactHost')}
                            </a>
                        )}
                        {userIsMember && (
                            <a
                                href={linkTo(`assemblies/${assemblyId}/members/invite`)}
                                className="button"
                            >
                                {trans('hives.pageHeaderAsMember.inviteMembers')}
                            </a>
                        )}
                    </div>
                </BoxSection>
            </Box>
        );
    }
}

export default connectToI18n(ConstructionBox);
