/* eslint-disable react/prop-types */
import React, { lazy, Suspense } from 'react';
import LoadingAnimation from 'components/LoadingAnimation.jsx';

const InboxContainer = lazy(() => import('components/Messages/InboxContainer.jsx'));
export const messagingRoutes = [
    {
        definition: '/messages',
        conditions: ['isAuthenticated'],
        activeLink: 'menu.messages',
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <InboxContainer
                    quotedText={new URLSearchParams(window.location.search).get('quote')}
                />
            </Suspense>
        ),
    },
];
