import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import connectToAnalytics from 'modules/analytics/connectToAnalytics';
import PlaceMapModal from 'components/Maps/PlaceMapModal.jsx';
import Button from 'components/Button.jsx';
import PickupPointSummary from './PickupPointSummary.jsx';

export class PickupPointList extends React.Component {
    static propTypes = {
        analytics: PropTypes.object.isRequired,
        pickupPoints: PropTypes.array.isRequired,
        trans: PropTypes.func.isRequired,
    };

    state = {
        pickups: [],
        openedPickupPointMap: null,
    };

    openMapModal = pickupPoint => {
        this.props.analytics.sendEvent('maps', 'clickOnShowAssemblyInMap');
        this.setState({ openedPickupPointMap: pickupPoint });
    };

    closeMapModal = () => {
        this.setState({ openedPickupPointMap: null });
    };

    render() {
        const { pickupPoints, trans } = this.props;

        const mapPickup = this.state.openedPickupPointMap;

        return (
            <div>
                {pickupPoints.map(pickup => (
                    <div key={pickup.pickup.uuid} className="u-mb-4">
                        <PickupPointSummary
                            pickupPoint={pickup.pickup}
                            isAssembly={pickup.type === 'assembly'}
                        />
                        <Button
                            weight="link"
                            variant="primary"
                            className="button--noPadding"
                            onClick={() => this.openMapModal(pickup)}
                        >
                            {trans('global.seeOnMap')}
                        </Button>
                    </div>
                ))}
                {mapPickup && (
                    <PlaceMapModal
                        title={mapPickup.pickup.name}
                        entity={mapPickup.pickup}
                        placeType={mapPickup.type}
                        isOpen
                        closeModal={this.closeMapModal}
                    />
                )}
            </div>
        );
    }
}

export default connectToI18n(connectToAnalytics(PickupPointList));
