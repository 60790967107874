import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import classnames from 'classnames';
import connectToI18n from 'modules/i18n/connectToI18n';
import StartingAt from 'components/Offers/StartingAt.jsx';
import Legend from 'components/Legend.jsx';
import PricePerQuantity from 'components/Offers/PricePerQuantity.jsx';
import PriceAndPromotion from './PriceAndPromotion.jsx';

export class PriceAndQuantity extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        product: PropTypes.object.isRequired,
        formattedQuantities: PropTypes.string.isRequired,
        transChoice: PropTypes.func.isRequired,
        hasAtLeastOneOfferInBasket: PropTypes.bool,
    };

    render() {
        const {
            transChoice,
            product,
            formattedQuantities,
            className,
            hasAtLeastOneOfferInBasket,
        } = this.props;

        const cheapestOffer = R.sortBy(R.path(['price', 'amount']))(product.offers)[0];
        const isCheapestOfferPromotion = cheapestOffer.hasOwnProperty('promotion');

        if (product.offers.length > 1) {
            return (
                <div className={classnames('productCard-prices', className)}>
                    {isCheapestOfferPromotion ? (
                        <PriceAndPromotion offer={cheapestOffer} reverseOnMobile />
                    ) : (
                        <StartingAt
                            className="productCard-priceStartingAt"
                            price={cheapestOffer.price}
                        />
                    )}
                    <Legend className="productCard-quantity">
                        {product.offers.length > 3 || hasAtLeastOneOfferInBasket
                            ? transChoice('offers.count', product.offers.length, {
                                  '%count%': product.offers.length,
                              })
                            : formattedQuantities}
                    </Legend>
                </div>
            );
        }

        return (
            <div className={classnames(className, 'productCard-pricesAndLegend')}>
                <PriceAndPromotion offer={product.offers[0]} reverseOnMobile />
                <Legend className="productCard-unit">{formattedQuantities}</Legend>
                <PricePerQuantity
                    className="productCard-pricePerQuantity fa-text--info"
                    offer={product.offers[0]}
                    strategy={product.type.quantityStrategy}
                />
            </div>
        );
    }
}

export default connectToI18n(PriceAndQuantity);
