import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { INITIAL_LOADING, READY } from 'modules/utils/ajaxStatuses';
import { getFeedbackSuggestions } from 'api/feedbacks';
import Box from 'components/Box.jsx';
import FeedbackSuggestions from 'components/FeedbackOnProducer/FeedbackSuggestions.jsx';

export class FeedbackSuggestionsContainer extends React.Component {
    static propTypes = {
        assemblyId: PropTypes.number.isRequired,
    };

    state = {
        status: INITIAL_LOADING,
        feedbackSuggestions: [],
        feedbackClientErrors: {},
    };

    componentDidMount() {
        return getFeedbackSuggestions()
            .then(feedbackSuggestions => {
                this.setState({
                    status: READY,
                    feedbackSuggestions,
                });
            })
            .catch(() => {
                this.setState({
                    status: READY,
                });
            });
    }

    render() {
        return (
            this.state.feedbackSuggestions.length > 0 && (
                <Box className="u-mb-6 feedbackSuggestions-container">
                    <FeedbackSuggestions {...this.state} assemblyId={this.props.assemblyId} />
                </Box>
            )
        );
    }
}

export default connectToI18n(FeedbackSuggestionsContainer);
