import PropTypes from 'prop-types';
import React from 'react';
import { getLocalizedDate } from 'modules/utils/dateAndTime';
import MediaObject from 'components/MediaObject.jsx';
import Avatar from 'components/Avatar.jsx';
import Heading from 'components/Heading.jsx';
import Text from 'components/Text.jsx';
import Button from 'components/Button.jsx';
import Box from 'components/Box.jsx';
import connectToI18n from 'modules/i18n/connectToI18n';
import { INFO } from 'modules/utils/colors';
import classnames from 'classnames';

export class ProducerCard extends React.Component {
    static propTypes = {
        farm: PropTypes.object.isRequired,
        trans: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
        className: PropTypes.string,
        actionTitle: PropTypes.string.isRequired,
        action: PropTypes.func,
        href: PropTypes.string,
    };

    render() {
        const { farm, trans, i18n, actionTitle, action, href, className } = this.props;

        const workSinceText = trans('farms.hives.collectionsCountSince', {
            '%count%': farm.distributionsCount,
            '%date%': getLocalizedDate(i18n, 'Y', farm.firstDistributionDate),
        });

        return (
            <Box className={classnames('userCard', className)}>
                <MediaObject
                    media={<Avatar photoId={farm.user.photoId} size="small" />}
                    className="userCard-details"
                    size="xsmall"
                >
                    <Heading className="u-mb-1" size={4}>
                        {farm.name}
                    </Heading>
                </MediaObject>
                <div className="userCard-stats userCard-stats--wrapped u-mb-1">
                    <Text
                        className="userCard-stat"
                        icon={require('icons/info-circle.svg')}
                        iconColor={INFO}
                    >
                        {workSinceText}
                    </Text>

                    {!!farm.distributionsCount && (
                        <Text
                            className="userCard-stat"
                            icon={require('icons/truck.svg')}
                            iconColor={INFO}
                        >
                            {trans('farms.hives.lastCollectionDate', {
                                '%date%': getLocalizedDate(i18n, 'I', farm.lastDistributionDate),
                            })}
                        </Text>
                    )}
                </div>
                <Button block onClick={action} href={href} className="userCard-action">
                    {actionTitle}
                </Button>
            </Box>
        );
    }
}

export default connectToI18n(ProducerCard);
