import { isDateIntheFuture } from 'modules/utils/dateAndTime';
import { Translator } from 'modules/i18n';
import * as R from 'ramda';

/**
 * The formQuestion object uses the following schema:
 *
 * ```
 * {
 *     sections: [{
 *         title: 'Section 1',
 *         questions: [{
 *             key: 'question-1',
 *             title: 'Question 1',
 *             type: 'boolean|input|month|numeric|ranking|radio|tag|text|opinion',
 *             help: 'Some help text',
 *             required: true|false,
 *             value: null|[],
 *         }, {
 *            // ...
 *         }]
 *     }]
 * }
 * ```
 */
function getAllQuestions(formQuestions) {
    return formQuestions.sections.reduce((allQuestions, section) => {
        return allQuestions.concat(section.questions);
    }, []);
}

export function edit(formQuestions, key, value) {
    const sections = formQuestions.sections.map(section => {
        const questions = section.questions.map(question => {
            let newValue = question.value;
            if (question.key === key) {
                newValue = value;
            }

            return {
                ...question,
                value: newValue,
            };
        });

        return {
            ...section,
            questions,
        };
    });

    return {
        ...formQuestions,
        sections,
    };
}

export function getErrors(formQuestions, locale) {
    return getAllQuestions(formQuestions).reduce((errors, question) => {
        if (question.type === 'month' && question.value && isDateIntheFuture(question.value)) {
            errors[question.key] = [Translator.trans(locale, 'formValidation.type.date')];
        }
        if (question.required && !question.value) {
            errors[question.key] = [Translator.trans(locale, 'formValidation.required')];
        }
        return errors;
    }, {});
}

/**
 * Return all questions from the formQuestions object as a map of key => value
 * pairs.
 *
 * Example:
 *
 * ```
 * {
 *    comment: "My comment",
 *    commitment: "1",
 *    community: null,
 *    misc: [],
 *    punctuality: "2",
 *    quality: null,
 *    strengths: ["accountable"],
 *    visited: true,
 *    visitedOn: "2016-05",
 * }
 * ```
 */
export function formQuestionsToPostData(formQuestions) {
    return getAllQuestions(formQuestions).reduce((postData, question) => {
        switch (question.type) {
            case 'boolean':
                postData[question.key] = question.value !== null ? question.value === 'true' : null;
                break;

            case 'number':
            case 'opinion':
                postData[question.key] = question.value !== null ? Number(question.value) : null;
                break;

            default:
                postData[question.key] = question.value;
                break;
        }
        return postData;
    }, {});
}

export function transformBooleansAndNumbersValuesToStrings(question) {
    const transformValueToString = a => (a.value = a.value.toString());

    const type = typeof question.value;
    if (type === 'number' || type === 'opinion' || type === 'boolean') {
        transformValueToString(question);
    }

    if (question.possibilities) {
        question.possibilities.forEach(transformValueToString);
    }
}

export function setVisibility(formQuestions, key, { hidden = false } = {}) {
    const sections = formQuestions.sections.map(section => {
        const questions = section.questions.map(question => {
            if (question.key === key) {
                return hidden ? R.assoc('hidden', true, question) : R.dissoc('hidden', question);
            }

            return question;
        });

        return {
            ...section,
            questions,
        };
    });

    return {
        ...formQuestions,
        sections,
    };
}

export function getQuestionValue(formQuestions, key) {
    return R.pipe(getAllQuestions, R.find(R.propEq('key', key)), R.prop('value'))(formQuestions);
}
