import React from 'react';
import Button from 'components/Button.jsx';
import Heading from 'components/Heading.jsx';
import Paragraph from 'components/Paragraph.jsx';
import Utils from 'modules/utils';
const { linkTo } = Utils;
import useI18n from 'hooks/I18n/useI18n.js';
import Cookies from 'js-cookie';
import AppChannel from 'modules/channels/App';

const MobileOnboardingPage = () => {
    const { trans } = useI18n();

    if (Cookies.get('auth')) {
        // if there is an 'auth' cookie, it means that the user
        // already used the webapp, so it's ok to redirect to the
        // homepage that will check the user session.
        // If the cookie does not correspond to a valid
        // session, then the user will be redirected to the login
        // page by the standard authentication process.
        AppChannel.commands.execute('navigate', AppChannel.reqres.request('homepage:route'), true);
        return null;
    }

    return (
        <div className="mobileOnboarding u-center">
            <Heading className="u-mb-2" size={2}>
                {trans('mobile.onboarding.title')}
            </Heading>
            <Paragraph>{trans('mobile.onboarding.description')}</Paragraph>
            <img alt="" className="u-mb-2" src={'/assets/images/illustrations/providers.png'} />
            <Button block className="u-mb-2" href={linkTo('join')} variant="success">
                {trans('login.createAccount')}
            </Button>
            <Button block href={linkTo('login')}>
                {trans('login.login')}
            </Button>
        </div>
    );
};

export default MobileOnboardingPage;
