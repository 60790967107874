import React from 'react';
import { Provider } from 'react-redux';
import BaseController from '../../../config/controllers';
import ReactLayoutView from '../../../views/_base/reactLayoutView';
import CoreRouter from 'components/Routes/CoreRouter.jsx';
import installI18nContext from 'modules/i18n/installI18nContext';
const CoreRouterWithI18n = installI18nContext(CoreRouter);

export default BaseController.extend({
    initialize(options) {
        const reactView = new ReactLayoutView({
            makeContainer: () => (
                <Provider store={window.reduxStore}>
                    <CoreRouterWithI18n i18n={window.language} />
                </Provider>
            ),
        });
        this.show(reactView);

        return BaseController.prototype.initialize.call(this, options);
    },
});
