import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';

import useI18n from 'hooks/I18n/useI18n';
import useResponsive from 'hooks/Navigation/useResponsive';

import { SAVED, PROCESSING, propType as statusPropType } from 'modules/utils/ajaxStatuses';
import { linkTo } from 'modules/utils';

import Text from 'components/ProductIdentity/Text.jsx';
import Button, { SMALL_SIZE } from 'src/components/atoms/Button/Button.jsx';
import Link, { DISPLAY_MODES } from 'src/components/atoms/Link/Link.jsx';
import Snackbar, { TYPE } from 'src/components/molecules/Snackbar/Snackbar.jsx';

import illustationSvg from 'app/assets/new-design/images/background/hive-success-victim-desktop.svg';

const HiveSuccessVictimPage = props => {
    const { assemblyId, isNotAMember, isAnonymous, joinAssembly, joinAssemblyStatus } = props;
    const { trans, i18n } = useI18n();
    const isSmallWidth = useResponsive();

    return (
        <>
            <div className="hiveSuccess-container">
                <div className="hiveSuccess-illustration">
                    <InlineSVG raw src={illustationSvg} />
                </div>
                <div className="hiveSuccess-message">
                    <Text
                        className="hiveSuccess-message-title"
                        size="20px"
                        lineHeight="28px"
                        weight="700"
                        family="mr"
                        color="grey8"
                    >
                        {trans('distribution.empty.title')}
                    </Text>
                    <Text
                        className="hiveSuccess-message-description"
                        size="16px"
                        lineHeight="24px"
                        weight="400"
                        family="inter"
                        color="gray5"
                    >
                        {trans('distribution.empty.description1')}
                    </Text>

                    {!isNotAMember && !isAnonymous ? (
                        <Text
                            className="hiveSuccess-message-description"
                            size="16px"
                            lineHeight="24px"
                            weight="400"
                            family="inter"
                            color="gray5"
                        >
                            {trans('distribution.empty.notify')}
                        </Text>
                    ) : (
                        <Text
                            className="hiveSuccess-message-description"
                            size="16px"
                            lineHeight="24px"
                            weight="400"
                            family="inter"
                            color="gray5"
                        >
                            {trans('distribution.empty.description2')}
                        </Text>
                    )}
                    <div className="hiveSuccess-message-cta">
                        {isAnonymous && (
                            <Link
                                displayMode={DISPLAY_MODES.BUTTON}
                                href={linkTo(`join/assembly/${assemblyId}`)}
                                bypassRouter
                            >
                                {trans('assembly.follow')}
                            </Link>
                        )}
                        {!isAnonymous && isNotAMember && joinAssemblyStatus !== SAVED && (
                            <Button onClick={joinAssembly} block={isSmallWidth} size={SMALL_SIZE}>
                                {joinAssemblyStatus === PROCESSING ? (
                                    <div>
                                        <img
                                            width="auto"
                                            height="16px"
                                            src="/assets/images/loaderanims/loader-pi-white.gif"
                                        />
                                    </div>
                                ) : (
                                    trans('assembly.follow')
                                )}
                            </Button>
                        )}
                        {(!isAnonymous && isNotAMember) ||
                            (joinAssemblyStatus === SAVED && (
                                <Snackbar
                                    type={TYPE.VALID}
                                    message={trans('assembly.following')}
                                    delay={4000}
                                />
                            ))}
                        {(!isNotAMember || joinAssemblyStatus === SAVED) && (
                            <Link
                                bypassRouter
                                displayMode={DISPLAY_MODES.BUTTON}
                                href={`/${i18n}/assemblies`}
                            >
                                {trans('hives.find.title')}
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

HiveSuccessVictimPage.propTypes = {
    assemblyId: PropTypes.number.isRequired,
    isNotAMember: PropTypes.bool.isRequired,
    isAnonymous: PropTypes.bool.isRequired,
    joinAssembly: PropTypes.func.isRequired,
    joinAssemblyStatus: statusPropType,
};

export default HiveSuccessVictimPage;
