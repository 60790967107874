import { post, put } from 'modules/api';
import { VALIDATED_ACTION_PAYLOAD, REFUSED_ACTION_PAYLOAD } from 'models/demand';

export const acceptProposal = proposalId =>
    put(`proposals/${proposalId}`, VALIDATED_ACTION_PAYLOAD);

export const declineProposal = proposalId => put(`proposals/${proposalId}`, REFUSED_ACTION_PAYLOAD);

export const sendProposal = ({
    assemblyId,
    farmId,
    message,
    deliveryMean = null,
    addAllCatalog = null,
}) => {
    const payload = {
        hive: assemblyId,
        farm: farmId,
        message,
        addAllCatalog,
    };

    if (deliveryMean) {
        payload.deliveryMean = deliveryMean;
    }

    return post('proposals/', payload);
};
