import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { Box, BoxSection, Grid, Text, Button, Separator, Heading } from 'components/ui';
import { getLocalizedDate } from 'modules/utils/dateAndTime';

export class UpcomingDistribution extends React.Component {
    static propTypes = {
        upcomingDistributionDate: PropTypes.string,
        trans: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
        i18nConfig: PropTypes.object.isRequired,
    };

    state = {
        isExpanded: false,
    };

    expandHowItWorks = () => {
        this.setState({ isExpanded: true });
    };

    render() {
        const { upcomingDistributionDate, trans, i18n, i18nConfig } = this.props;
        const supportUrl = i18nConfig.supportSite && i18nConfig.supportSite.member;

        return (
            <Box className="u-mb-8">
                <BoxSection bordered className="u-mb-4">
                    <Grid sm="3|9">
                        <img
                            src="/assets/images/open-assembly-no-distribution.png"
                            className="u-scaleMedia u-mb-1"
                            width="150"
                        />
                        <div>
                            <Text color="info" className="u-mb-1">
                                {trans('distributions.noOpenSale.text')}
                            </Text>
                            <Text>
                                {upcomingDistributionDate
                                    ? trans('hives.member.alert.upcomingSale', {
                                          '%date%': getLocalizedDate(
                                              i18n,
                                              'I',
                                              upcomingDistributionDate
                                          ),
                                      })
                                    : trans('hives.member.alert.upcomingSaleNoDate')}
                            </Text>
                        </div>
                    </Grid>
                </BoxSection>
                <BoxSection>
                    {!this.state.isExpanded && (
                        <div className="u-center">
                            <Button
                                className="button--noPadding"
                                weight="link"
                                onClick={this.expandHowItWorks}
                            >
                                {trans('howItWorksAssembly.title')}
                            </Button>
                        </div>
                    )}
                    {this.state.isExpanded && (
                        <Fragment>
                            <div className="howItWorks">
                                <div className="howItWorks-title">
                                    {trans('howItWorksAssembly.title')}
                                </div>
                                <div className="howItWorks-steps">
                                    <div className="howItWorksStep is-active">
                                        <div className="howItWorksStep-image">
                                            <img
                                                className="u-scaleMedia hidden-xs"
                                                src="/assets/images/illustrations/assemblyPage-how-it-works-1.png"
                                            />
                                            <img
                                                className="u-scaleMedia visible-xs"
                                                src="/assets/images/illustrations/assemblyPage-how-it-works-1-mobile.png"
                                            />
                                        </div>
                                        <Text serif className="howItWorksStep-text">
                                            {trans('howItWorksAssembly.preparation.content')}
                                        </Text>
                                    </div>
                                    <div className="howItWorksStep">
                                        <div className="howItWorksStep-image">
                                            <img
                                                className="u-scaleMedia hidden-xs"
                                                src="/assets/images/illustrations/assemblyPage-how-it-works-2.png"
                                            />
                                            <img
                                                className="u-scaleMedia visible-xs"
                                                src="/assets/images/illustrations/assemblyPage-how-it-works-2-mobile.png"
                                            />
                                        </div>
                                        <Text serif className="howItWorksStep-text">
                                            {trans('howItWorksAssembly.sale.content')}
                                        </Text>
                                    </div>
                                    <div className="howItWorksStep">
                                        <div className="howItWorksStep-image">
                                            <img
                                                className="u-scaleMedia hidden-xs"
                                                src="/assets/images/illustrations/assemblyPage-how-it-works-3.png"
                                            />
                                            <img
                                                className="u-scaleMedia visible-xs"
                                                src="/assets/images/illustrations/assemblyPage-how-it-works-3-mobile.png"
                                            />
                                        </div>
                                        <Text serif className="howItWorksStep-text">
                                            {trans('howItWorksAssembly.distribution.content')}
                                        </Text>
                                    </div>
                                </div>
                            </div>
                            {supportUrl && (
                                <Fragment>
                                    <Separator />
                                    <Heading size={4} className="u-mb-2">
                                        {trans('howItWorksAssembly.questions.title')}
                                    </Heading>
                                    <Text
                                        dangerouslySetInnerHTML={{
                                            __html: trans('howItWorksAssembly.questions.content', {
                                                '%link%': supportUrl,
                                            }),
                                        }}
                                    />
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </BoxSection>
            </Box>
        );
    }
}

export default connectToI18n(UpcomingDistribution);
