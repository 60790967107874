import * as R from 'ramda';
import { post } from 'modules/api';
import moment from 'moment';

export function setSaleCancellationStatus(sale, assemblyId, { canceled = false } = {}) {
    const url = `hives/${assemblyId}/calendar`;
    const payload = {
        date: moment(sale.distributionDate).format('YYYY-MM-DD'),
        canceled,
    };
    return post(url, payload);
}

export function createSale(sale, assemblyId) {
    const url = `hives/${assemblyId}/distributions`;
    const payload = {
        distributionDate: sale.distributionDate,
        timeInterval: {
            timeStart: sale.timeStart,
            timeEnd: sale.timeEnd,
        },
    };
    return post(url, payload);
}

export function mergeDistributionLists(distributions, otherDistributions) {
    return R.pipe(
        R.defaultTo([]),
        R.concat(R.defaultTo([], otherDistributions)),
        R.uniqBy(d => moment(d.distributionDate).format('YYYY-MM-DD')),
        R.sortBy(R.prop('distributionDate'))
    )(distributions);
}

export function isDistributionDateBetween(sale, startDate, endDate) {
    return moment(sale.distributionDate).isBetween(moment(startDate), moment(endDate), 'day', '[]');
}
