/* eslint-disable react/prop-types */
import React, { Suspense } from 'react';

import CoreApp from 'app/scripts/modules/core/coreApp';

import LoadingAnimation from 'components/LoadingAnimation.jsx';
import PaymentProfileForm from 'components/Checkout/ProductIdentity/PaymentProfileForm.jsx';

const MemberInvitationsContainer = React.lazy(() =>
    import('components/MemberInvitations/MemberInvitationsContainer.jsx')
);
const ListFarms = React.lazy(() => import('components/ListFarms/ListFarmsContainer.jsx'));
const FarmProfileContainer = React.lazy(() =>
    import('components/FarmProfile/FarmProfileContainer.jsx')
);
const UserFarmIdProvider = React.lazy(() => import('components/UserFarmIdProvider.jsx'));
const FarmProfileMapContainer = React.lazy(() =>
    import('components/FarmProfile/FarmProfileMapContainer.jsx')
);
const FarmProfileProductsContainer = React.lazy(() =>
    import('components/FarmProfile/FarmProfileProductsContainer.jsx')
);
const BuyGiftCardContainer = React.lazy(() =>
    import('components/BuyGiftCard/BuyGiftCardContainer.jsx')
);
const AccountTabs = React.lazy(() => import('components/Account/AccountTabs.jsx'));

export const memberRoutes = [
    {
        definition: '/assemblies/:assemblyId/members/invite',
        conditions: ['isAuthenticated', 'isMemberOfHive'],
        activeLink: 'menu.members',
        context: 'hive',
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="pi-spinner" />}>
                <MemberInvitationsContainer assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/farms',
        conditions: ['isAuthenticated'],
        activeLink: 'menu.farms',
        context: 'hive',
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="pi-spinner" />}>
                <ListFarms assemblyId={parseInt(match.params.assemblyId, 10)} />,
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/producers/:farmId([0-9]+):searchQuery?',
        conditions: ['isAuthenticated'],
        activeLink: 'menu.farms',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="pi-spinner" />}>
                <UserFarmIdProvider>
                    {({ farmId }) => (
                        <FarmProfileContainer
                            assemblyId={parseInt(match.params.assemblyId, 10)}
                            farmId={parseInt(match.params.farmId, 10)}
                            userIsOwnerOfFarm={farmId === parseInt(match.params.farmId, 10)}
                        />
                    )}
                </UserFarmIdProvider>
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/producers/:farmId/map:searchQuery?',
        conditions: ['isAuthenticated'],
        activeLink: 'menu.farms',
        context: 'hive',
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="pi-spinner" />}>
                <FarmProfileMapContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                    farmId={parseInt(match.params.farmId, 10)}
                />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/producers/:farmId/products:searchQuery?',
        conditions: ['isAuthenticated'],
        activeLink: 'menu.farms',
        context: 'hive',
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="pi-spinner" />}>
                <FarmProfileProductsContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                    farmId={parseInt(match.params.farmId, 10)}
                />
            </Suspense>
        ),
    },
    {
        definition: '/configuration/account/:tab',
        methodName: 'account',
        conditions: ['isAuthenticated'],
        activeLink: 'menu.configuration',
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="pi-spinner" />}>
                <AccountTabs tab={match.params.tab || 'identity'} />,
            </Suspense>
        ),
    },
    {
        definition: '/giftcards',
        activeLink: 'none',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="pi-spinner" />}>
                <BuyGiftCardContainer homeLink={CoreApp._getHomepage()} />
            </Suspense>
        ),
    },
    {
        definition: '/giftcards/return/:paymentUuid',
        activeLink: 'none',
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="pi-spinner" />}>
                <BuyGiftCardContainer
                    homeLink={CoreApp._getHomepage()}
                    paymentUuid={match.params.paymentUuid}
                />
            </Suspense>
        ),
    },
    {
        definition: '/complete-profile',
        exact: true,
        conditions: ['isAuthenticated'],
        activeLink: 'menu.basket',
        render: ({ location }) => {
            const queryParameters = new URLSearchParams(location.search);

            return (
                <Suspense fallback={<LoadingAnimation type="pi-spinner" />}>
                    <PaymentProfileForm
                        orderId={parseInt(queryParameters.get('orderId'), 10)}
                        shouldRevalidate={queryParameters.get('revalidate') === 'true'}
                        hasDeliveryOptions={queryParameters.get('delivery') === 'true'}
                    />
                </Suspense>
            );
        },
    },
];
