// Minimal Footer for public (not connected) pages.
// Just the terms and legal links centered

import React, { Fragment } from 'react';
import useI18n from 'hooks/I18n/useI18n.js';
import { getLocaleConfig } from 'modules/config/i18n';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export default function TermsPublicFooter({ isBlue, isWhite }) {
    const { i18n, i18nConfig, trans } = useI18n();

    const localeConf = getLocaleConfig(i18n);

    const localeForLink = localeConf.shortcut ? localeConf.shortcut : localeConf.code;

    return (
        <footer
            className={classnames('termsFooter', {
                'termsFooter-blue': isBlue,
                'termsFooter-white': isWhite,
            })}
        >
            <a href={`/${localeForLink}/p/legal-mentions`} data-bypass="true">
                {trans('publicFooter.links.legal')}
            </a>
            &nbsp;-&nbsp;
            <a href={`/${localeForLink}/p/terms`} data-bypass="true">
                {trans('publicFooter.links.terms')}
            </a>
            &nbsp;-&nbsp;
            <a href={trans('privacy_policy_link')} data-bypass="true" target="_blank">
                {trans('footer_privacy_policy')}
            </a>
            {i18nConfig?.links?.ourDatas && (
                <Fragment>
                    &nbsp;-&nbsp;
                    <a href={i18nConfig.links.ourDatas} data-bypass="true" className="color-ochre2">
                        {trans('publicFooter.links.data')}
                    </a>
                </Fragment>
            )}
        </footer>
    );
}

TermsPublicFooter.propTypes = {
    isBlue: PropTypes.bool,
    isWhite: PropTypes.bool,
};
