import { captureException } from 'modules/error-reporting';

export const TOGGLE_ONLY_OPEN_SALE_FILTER = 'TOGGLE_ONLY_OPEN_SALE_FILTER';
export const CHANGE_PRIMARY_FILTER = 'CHANGE_PRIMARY_FILTER';
export const ASSEMBLIES_AND_PICKUPS_LOADED = 'ASSEMBLIES_AND_PICKUPS_LOADED';
export const MARKER_SELECTED = 'MARKER_SELECTED';
export const OPEN_MOBILE_FILTERS_BUTTON_CLICKED = 'OPEN_MOBILE_FILTERS_BUTTON_CLICKED';
export const FILTER_MOBILE_MODAL_CLOSE_BUTTON_CLICKED = 'FILTER_MOBILE_MODAL_CLOSE_BUTTON_CLICKED';
export const APPLY_FILTERS_BUTTON_CLICKED = 'APPLY_FILTERS_BUTTON_CLICKED';
export const ASSEMBLY_INFORMATIONS_MOBILE_MODAL_CLOSE_BUTTON_CLICKED =
    'ASSEMBLY_INFORMATIONS_MOBILE_MODAL_CLOSE_BUTTON_CLICKED';
export const MAP_CLICKED = 'MAP_CLICKED';
export const SUGGESTIONS_UPDATED = 'SUGGESTIONS_UPDATED';
export const SUGGESTION_CLICKED = 'SUGGESTION_CLICKED';
export const AUTOCOMPLETE_FIELD_VALUE_CHANGED = 'AUTOCOMPLETE_FIELD_VALUE_CHANGED';
export const AUTOCOMPLETE_BLUR = 'AUTOCOMPLETE_BLUR';

export const PRIMARY_FILTER_ALL = 'all';
export const PRIMARY_FILTER_REGULAR_DISTRIBUTION = 'regularDistribution';
export const PRIMARY_FILTER_HOME_DELIVERY = 'homeDelivery';
export const PRIMARY_FILTERS = [
    PRIMARY_FILTER_ALL,
    PRIMARY_FILTER_REGULAR_DISTRIBUTION,
    PRIMARY_FILTER_HOME_DELIVERY,
];

export const getInitialState = prefilledFullAddress => {
    return {
        pickups: [],
        assemblies: [],
        selectedItem: null,
        onlyOpenSaleFilter: false,
        primaryFilter: PRIMARY_FILTER_ALL,
        isMobileFilterModalOpened: false,
        isMobileAssemblyInformationsModalOpened: false,
        isSelectedMarkerLayerVisible: true,
        autocompleteFieldValue: prefilledFullAddress || '',
        autocompleteSuggestions: [],
        panToCoordinates: null,
    };
};

const assembliesMapReducer = (state, { type, payload }) => {
    switch (type) {
        case TOGGLE_ONLY_OPEN_SALE_FILTER:
            return {
                ...state,
                onlyOpenSaleFilter: !state.onlyOpenSaleFilter,
                selectedItem: null,
                selectedDistributionId: null,
            };
        case CHANGE_PRIMARY_FILTER:
            return {
                ...state,
                primaryFilter: payload,
                selectedItem: null,
                selectedDistributionId: null,
            };
        case ASSEMBLIES_AND_PICKUPS_LOADED:
            return {
                ...state,
                assemblies: payload.assemblies,
                pickups: payload.pickups,
            };
        case MARKER_SELECTED:
            return {
                ...state,
                selectedItem: {
                    type: payload.type,
                    properties: payload.properties,
                },
                selectedDistributionId: null,
                isMobileAssemblyInformationsModalOpened: true,
                isSelectedMarkerLayerVisible: true,
            };
        case OPEN_MOBILE_FILTERS_BUTTON_CLICKED:
            return {
                ...state,
                isMobileFilterModalOpened: true,
            };
        case FILTER_MOBILE_MODAL_CLOSE_BUTTON_CLICKED:
            return {
                ...state,
                isMobileFilterModalOpened: false,
            };
        case ASSEMBLY_INFORMATIONS_MOBILE_MODAL_CLOSE_BUTTON_CLICKED:
            return {
                ...state,
                isSelectedMarkerLayerVisible: false,
                isMobileAssemblyInformationsModalOpened: false,
            };
        case APPLY_FILTERS_BUTTON_CLICKED:
            return {
                ...state,
                isMobileFilterModalOpened: false,
                primaryFilter: payload.primaryFilter,
                onlyOpenSaleFilter: payload.onlyOpenSaleFilter,
            };
        case MAP_CLICKED:
            return {
                ...state,
                isMobileAssemblyInformationsModalOpened: false,
                isSelectedMarkerLayerVisible: false,
                selectedDistributionId: null,
                selectedItem: null,
            };
        case SUGGESTIONS_UPDATED:
            return {
                ...state,
                autocompleteSuggestions: payload,
            };
        case AUTOCOMPLETE_FIELD_VALUE_CHANGED:
            return {
                ...state,
                autocompleteFieldValue: payload,
            };
        case SUGGESTION_CLICKED:
            return {
                ...state,
                autocompleteSuggestions: [],
                panToCoordinates: payload.coordinates,
                autocompleteFieldValue: payload.fieldValue,
            };
        case AUTOCOMPLETE_BLUR:
            return {
                ...state,
                autocompleteSuggestions: [],
            };
        default:
            captureException(new Error(`${type} action not found in MapReducer.`));

            return state;
    }
};

export default assembliesMapReducer;
