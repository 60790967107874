import PropTypes from 'prop-types';
import React from 'react';
import ResponsiveNavigation from '../ResponsiveNavigation.jsx';
import NavigationRegion from '../NavigationRegion.jsx';
import ContextDropdown from '../ContextDropdown.jsx';
import UserDropdown from '../UserDropdown.jsx';
import HelpDropdown from '../HelpDropdown.jsx';
import UserBottomDropdown from '../UserBottomDropdown.jsx';
import HelpBottomDropdown from '../HelpBottomDropdown.jsx';
import LeftNavigationLink from '../LeftNavigationLink.jsx';
import MessagesLink from '../MessagesLink.jsx';
import BottomNavigationLink from '../BottomNavigationLink.jsx';
import connectToI18n from 'modules/i18n/connectToI18n';
import NewsfeedDropdown from 'components/Navigation/NewsfeedDropdown.jsx';
import NewsfeedBottomDropdown from 'components/Navigation/NewsfeedBottomDropdown.jsx';
import { fetchUnreadNewsfeedCount, unreadSelector } from 'modules/newsfeed';
import { connect } from 'react-redux';
import { HIVE_RECIPIENT } from 'models/newsfeed.js';

export class HostOpenNavigation extends React.Component {
    static propTypes = {
        isSmallWidth: PropTypes.bool,
        currentAssembly: PropTypes.object,
        activeLink: PropTypes.string,
        trans: PropTypes.func.isRequired,
        newsfeedUnreadCount: PropTypes.number,
        fetchUnreadNewsfeedCount: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.fetchUnreadNewsfeedCount(HIVE_RECIPIENT);
    }

    renderDistributionLink = () => {
        const t = this.props.trans;
        const { currentAssembly } = this.props;

        return (
            <BottomNavigationLink
                icon="distributions"
                href={`assemblies/${currentAssembly.id}/collections`}
            >
                {t('navigation.bottom.collections')}
            </BottomNavigationLink>
        );
    };

    render() {
        const t = this.props.trans;

        const { isSmallWidth, currentAssembly, activeLink, newsfeedUnreadCount } = this.props;

        if (!currentAssembly) {
            return null;
        }

        return (
            <ResponsiveNavigation isSmallWidth={isSmallWidth}>
                <NavigationRegion position="top" legacy>
                    <div className="topNavigation-left">
                        <ContextDropdown {...this.props} />
                    </div>
                    <div className="topNavigation-right">
                        <NewsfeedDropdown {...this.props} />
                        <MessagesLink {...this.props} />
                        <HelpDropdown {...this.props} />
                        <UserDropdown {...this.props} />
                    </div>
                </NavigationRegion>
                <NavigationRegion position="left">
                    <LeftNavigationLink
                        href={`assemblies/${currentAssembly.id}`}
                        icon="assembly"
                        isActive={activeLink === 'menu.home'}
                    >
                        {t('navigation.left.home')}
                    </LeftNavigationLink>
                    <LeftNavigationLink
                        href={`assemblies/${currentAssembly.id}/collections`}
                        icon="distributions"
                        isActive={activeLink === 'menu.distributions'}
                    >
                        {t('navigation.left.collections')}
                    </LeftNavigationLink>
                    <LeftNavigationLink
                        href={`assemblies/${currentAssembly.id}/dashboard/stats`}
                        icon="statistics"
                        isActive={activeLink === 'menu.statistics'}
                    >
                        {t('navigation.left.statistics')}
                    </LeftNavigationLink>
                    <LeftNavigationLink
                        href={`assemblies/${currentAssembly.id}/producers/find`}
                        icon="producers"
                        badgeValue={currentAssembly.proposalsCount}
                        isActive={activeLink === 'menu.farms'}
                    >
                        {t('navigation.left.producers')}
                    </LeftNavigationLink>
                    <LeftNavigationLink
                        href={`assemblies/${currentAssembly.id}/members`}
                        icon="members"
                        isActive={activeLink === 'menu.members'}
                    >
                        {t('community.title')}
                    </LeftNavigationLink>
                    <LeftNavigationLink
                        href={`assemblies/${currentAssembly.id}/configuration`}
                        icon="settings"
                        isActive={activeLink === 'menu.leader.configuration'}
                    >
                        {t('navigation.left.settings')}
                    </LeftNavigationLink>
                </NavigationRegion>
                <NavigationRegion position="bottom">
                    <BottomNavigationLink icon="home" href={`assemblies/${currentAssembly.id}`}>
                        {t('navigation.host.assembly')}
                    </BottomNavigationLink>
                    {this.renderDistributionLink()}
                    <BottomNavigationLink
                        href={`/assemblies/${currentAssembly.id}/dashboard/stats`}
                        icon="statistics"
                    >
                        {t('navigation.left.statistics')}
                    </BottomNavigationLink>
                    <UserBottomDropdown {...this.props} newsfeedUnreadCount={newsfeedUnreadCount} />
                    <HelpBottomDropdown {...this.props} />
                    <NewsfeedBottomDropdown {...this.props} />
                </NavigationRegion>
            </ResponsiveNavigation>
        );
    }
}

const mapStateToProps = state => ({
    newsfeedUnreadCount: unreadSelector(state),
});

export default connect(mapStateToProps, {
    fetchUnreadNewsfeedCount,
})(connectToI18n(HostOpenNavigation));
