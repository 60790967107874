import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import classnames from 'classnames';
import Heading from 'components/Heading.jsx';
import { connect } from 'react-redux';
import { toggle as togglePanel } from 'modules/visiblePanel';
import PanelWrapper from './PanelWrapper.jsx';

export const PANEL_NAME = 'bottom_catalogs';

export class BottomCatalogsNavigationLinks extends React.Component {
    static propTypes = {
        isActive: PropTypes.bool.isRequired,
        togglePanel: PropTypes.func.isRequired,
        trans: PropTypes.func.isRequired,
    };

    showCatalogsLinksPanel = e => {
        e.preventDefault();
        this.props.togglePanel(PANEL_NAME);
    };

    renderCatalogsLinksPanel = () => {
        const { trans } = this.props;

        return (
            this.props.isActive && (
                <PanelWrapper className="panelWrapper panelWrapper--bottomAutoHeight">
                    <div className="bottomDistributionsPanel">
                        <a
                            className="bottomDistributionsPanel-link"
                            href="producers/me/products/stocks"
                            icon="catalog"
                        >
                            <Heading size={4}>
                                {trans('navigation.bottom.products')}
                                <span className="icon icon-angle-right" />
                            </Heading>
                        </a>
                        <a
                            className="bottomDistributionsPanel-link"
                            href="producers/me/interregional-products/stocks"
                            icon="catalog"
                        >
                            <Heading size={4}>
                                {trans('navigation.left.interregionalCatalog')}
                                <span className="icon icon-angle-right" />
                            </Heading>
                        </a>
                    </div>
                </PanelWrapper>
            )
        );
    };

    render() {
        const { trans } = this.props;

        return (
            <div>
                <a
                    href="#"
                    data-bypass="true"
                    className={classnames('bottomNavigation-link ignore-react-onclickoutside', {
                        'is-active': this.props.isActive,
                    })}
                    onClick={this.showCatalogsLinksPanel}
                >
                    <img
                        src="/assets/images/navigation/mobile/icon-mobile-catalog.svg"
                        className="bottomNavigation-image"
                        alt=""
                    />
                    <div>{trans('navigation.bottom.products')}</div>
                </a>
                {this.renderCatalogsLinksPanel()}
            </div>
        );
    }
}

export default connect(undefined, {
    togglePanel,
})(connectToI18n(BottomCatalogsNavigationLinks));
