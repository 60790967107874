import Globalize from 'globalize';
import './globalize.culture.ca-ES';
Globalize.addCultureInfo('ca', 'ca-ES');

import moment from 'moment';
import 'moment/locale/ca';
moment.defineLocale('ca-ES', { parentLocale: 'ca' });

export default {
    code: 'ca-ES',

    // bcode is here for the "backCode" ... the code as seen/sent by the back :O
    bCode: 'ca_ES',

    name: 'Català',
    shortcut: 'ca',
    supportEmail: 'asistencia@lcqds.es',
    privacyEmail: 'privacy@lacolmenaquedicesi.es',

    trainingSite: {
        url: 'http://formacion.lacolmenaquedicesi.es',
        password: '100abejasfelices',
    },

    appStores: {
        apple: 'https://apps.apple.com/es/app/la-colmena-que-dice-s%C3%AD/id1052198033?l=ca',
        google: 'https://play.google.com/store/apps/details?id=com.lrqdo&hl=ca',
    },

    supportSite: {
        widgetDomain: 'lacolmenaquedicesi.zendesk.com',
        homepage: 'https://lacolmenaquedicesi.zendesk.com/hc/es',
        host:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/categories/200129422-RESPONSABLES-DE-COLMENA',
        farmer:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/categories/200129442-PRODUCTORES-Y-ELABORADORES',
        member:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/categories/200127541-CONSUMIDORES-DE-LAS-COLMENAS',
        user:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/categories/200127541-CONSUMIDORES-DE-LAS-COLMENAS',
        kycInformation:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/articles/208514805-Informaci%C3%B3n-complementaria-requerida-para-el-pago',
        proImprovementsHost:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/articles/208588405-Nuevas-mejoras-en-tu-espacio-profesional',
        proImprovementsProd:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/articles/207858469-Nuevas-mejoras-en-tu-carpeta-de-productor',
        farmerInterregionalCatalog:
            'https://lacolmenaquedicesi.zendesk.com/hc/es/articles/115002220225-Com-funciona-el-cat%C3%A0leg-de-convidat-especial-',
    },

    logos: {
        brown: '/assets/images/logos/brown/logo-ca.svg',
        turquoise: '/assets/images/logos/turquoise/logo-ca.svg',
        white: '/assets/images/logos/white/logo-ca.svg',
        whiteOutlined: '/assets/images/logos/whiteOutlined/logo-ca.svg',
        blue: '/assets/images/logos/blue/logo-ca.svg',
        christmas: '/assets/images/logos/christmas/logo-ca.svg',
        whiteChristmas: '/assets/images/logos/christmas/white/logo-ca.svg',
    },

    social: {
        facebook: 'https://www.facebook.com/lacolmenaquedicesi',
        twitter: 'https://twitter.com/ColmenaQdiceSi',
        blog: 'http://blog.lacolmenaquedicesi.es/',
        instagram: 'https://instagram.com/lacolmenaquedicesi',
        youtube: 'https://www.youtube.com/channel/UCdFX4fozPU3ZRbC2nAsSD_w',
        linkedin: 'https://www.linkedin.com/company/%C2%A1la-colmena-que-dice-s%C3%AD-/',
    },

    links: {
        about: 'https://nosaltres.lacolmenaquedicesi.cat/',
        press: 'https://nosaltres.lacolmenaquedicesi.cat/premsa/',
        joinUs: 'https://nosaltres.lacolmenaquedicesi.cat/equipo/',
        order: 'https://lacolmenaquedicesi.es/ca/assemblies',
        giftCards: 'https://lacolmenaquedicesi.es/ca/giftcards',
        openRuche: 'https://lacolmenaquedicesi.es/ca/p/open',
        provideRuche: 'https://lacolmenaquedicesi.es/ca/p/provide',
        proposeLocal: '',
    },

    proposeLocalForm:
        'https://docs.google.com/forms/d/e/1FAIpQLSfHYgO_Ulh0JqhglI53oSWe1GHiHbDV0bo6DYmHh1QInbNpSA/viewform',

    dateFormats: {
        d: 'DD/MM/YYYY',
        D: 'dddd DD [de] MMMM [de] YYYY',
        I: 'dddd DD [de] MMMM',
        i: 'DD [de] MMMM [de] YYYY',
        t: 'HH:mm',
        T: 'HH:mm:ss',
        f: 'dddd, DD / [de] MMMM / YYYY HH:mm',
        F: 'dddd, DD / [de] MMMM / YYYY HH:mm:ss',
        M: 'DD [de] MMMM',
        Y: 'MMMM / YYYY',
        shortDayDate: 'ddd DD [de] MMMM',
        dateWithoutYear: 'DD/MM',
    },

    inlineLogo: require('./logo.svg'),
    zendeskLocale: 'es',
    zendeskSearch: 'https://lacolmenaquedicesi.zendesk.com/api/v2/help_center/',
    zendeskCategories: {
        farmer: '200129442',
        member: '200127541',
        user: '200127541',
        host: '200129422',
    },

    cguPage: 'https://lacolmenaquedicesi.es/ca/p/terms',
    homepage: 'https://lacolmenaquedicesi.es',
    minirucheHelpCenter:
        'https://lacolmenaquedicesi.zendesk.com/hc/es/categories/360002312937-MINI-COLMENAS',
};
