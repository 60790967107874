import * as R from 'ramda';

export const impersonate = R.curry((userImpersonated, req) => {
    if (!userImpersonated) {
        return req;
    }
    return R.evolve(
        {
            headers: R.assoc('rest-impersonate-user', encodeURIComponent(userImpersonated)),
        },
        req
    );
});

export const authenticate = R.curry((accessToken, req) => {
    if (!accessToken) {
        return req;
    }
    return R.evolve(
        {
            headers: R.assoc('Authorization', `Bearer ${accessToken}`),
        },
        req
    );
});

export const jsonRequest = R.evolve({
    headers: R.assoc('Content-Type', 'application/json'),
});

export const prefixUrl = R.curry((prefix, req) => {
    if (!prefix) {
        return req;
    }
    return R.evolve(
        {
            url: url => `${prefix}/${url}`,
        },
        req
    );
});

export const setLocale = R.curry((locale, req) => {
    if (!locale) {
        return req;
    }
    return R.evolve(
        {
            headers: R.assoc('Accept-Language', locale),
        },
        req
    );
});
