import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import flaskIcon from 'icons/flask.svg';
import lightbulbIcon from 'icons/lightbulb.svg';
import infoIcon from 'icons/info-circle.svg';
import errorIcon from 'icons/warning-circle.svg';
import successIcon from 'icons/ok-circle.svg';
import createResponsiveComponent from 'components/createResponsiveComponent';
import Icon from 'components/Icon.jsx';
import Heading from 'components/Heading.jsx';
import Paragraph from 'components/Paragraph.jsx';
import Text from 'components/Text.jsx';
import * as colors from 'modules/utils/colors';

const NOTICE_TYPE_TO_ICON_SVG = {
    error: errorIcon,
    hint: lightbulbIcon,
    information: infoIcon,
    pilotFeature: flaskIcon,
    success: successIcon,
    warning: null,
};

const NOTICE_TYPE_TO_ICON_COLOR = {
    error: colors.DANGER,
    warning: null,
    hint: colors.INFO,
    information: colors.INFO,
    pilotFeature: colors.INFO,
    success: colors.SUCCESS,
};

const NOTICE_TYPE_TO_TEXT_COLOR = {
    error: colors.DANGER,
    warning: colors.DANGER,
    hint: null,
    information: null,
    pilotFeature: null,
    success: colors.SUCCESS,
};

const propTypes = {
    title: PropTypes.node,
    type: PropTypes.oneOf(['error', 'hint', 'information', 'pilotFeature', 'success', 'warning']),
    children: PropTypes.node.isRequired,
    isSmallWidth: PropTypes.bool.isRequired,
    action: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    className: PropTypes.string,
    onAnimationEnd: PropTypes.func,
    onClick: PropTypes.func,
    footerActions: PropTypes.node,
};

const defaultProps = {
    className: null,
    action: null,
    type: 'information',
};

export class Notice extends PureComponent {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        if (!this.props.onClick) {
            return;
        }

        const ignoreEvent = ['A', 'BUTTON'].indexOf(e.target.nodeName) !== -1;
        if (!ignoreEvent) {
            this.props.onClick();
        }
    }

    render() {
        const { type, footerActions, isSmallWidth } = this.props;

        const classNames = classnames(
            'notice',
            {
                'notice--withMultiLines': !!this.props.title || Array.isArray(this.props.children),
                'notice--warning': type === 'warning',
            },
            this.props.className
        );

        const actionElement = this.props.action ? (
            <div className="notice-action">{this.props.action}</div>
        ) : null;

        return (
            <div
                className={classNames}
                onAnimationEnd={this.props.onAnimationEnd}
                onClick={this.onClick}
            >
                <div className="notice-body">
                    {NOTICE_TYPE_TO_ICON_SVG[type] && (
                        <Icon
                            className="notice-icon"
                            size="xxx-large"
                            color={NOTICE_TYPE_TO_ICON_COLOR[type]}
                            src={NOTICE_TYPE_TO_ICON_SVG[type]}
                        />
                    )}
                    <div
                        className={classnames({
                            'u-mb-md-2': footerActions,
                        })}
                    >
                        {this.props.title && (
                            <Heading className="u-mb-1" size={3}>
                                {this.props.title}
                            </Heading>
                        )}
                        {(Array.isArray(this.props.children) && (
                            <Text color={NOTICE_TYPE_TO_TEXT_COLOR[type]}>
                                {this.props.children}
                            </Text>
                        )) || (
                            <Paragraph
                                color={NOTICE_TYPE_TO_TEXT_COLOR[type]}
                                dangerouslySetInnerHTML={{ __html: this.props.children }}
                            />
                        )}
                    </div>
                    {!isSmallWidth && actionElement}
                </div>
                {!isSmallWidth && footerActions && <div>{footerActions}</div>}

                {isSmallWidth && actionElement}
                {isSmallWidth && footerActions && <div>{footerActions}</div>}
            </div>
        );
    }
}

Notice.propTypes = propTypes;
Notice.defaultProps = defaultProps;

export default createResponsiveComponent(Notice);
