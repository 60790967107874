import { useSelector } from 'react-redux';
import { trans, transChoice } from 'modules/i18n/Translator';
import { getLocaleConfig } from 'modules/config/i18n';
import * as R from 'ramda';

const createHookReturn = R.memoizeWith(R.identity, i18n => {
    const ourTrans = (translationKey, parameters) => {
        return trans(i18n, translationKey, parameters);
    };

    const ourTransChoice = (translationKey, number, parameters) => {
        const extendedParameters = {
            '%count%': number,
            ...parameters,
        };
        return transChoice(i18n, translationKey, number, extendedParameters);
    };

    return {
        trans: ourTrans,
        transChoice: ourTransChoice,
        i18n,
        i18nConfig: getLocaleConfig(i18n),
    };
});

export default function useI18n() {
    return createHookReturn(useSelector(state => state.locale));
}
