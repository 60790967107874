import React, { useCallback, useEffect, useRef, useState, Fragment } from 'react';
import useI18n from 'hooks/I18n/useI18n';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { get } from 'modules/api';
import { userSelector } from 'modules/currentUser';

import { Heading } from 'components/ui';
import Text from 'components/ProductIdentity/Text.jsx';
import ProductFoodFeedbackCard, {
    TYPE_REVIEW,
    TYPE_RESPONSE,
} from 'components/Product/ProductIdentity/FoodFeedback/ProductFoodFeedbackCard.jsx';
import Button, { LINK_MODE } from 'src/components/atoms/Button/Button.jsx';
import Modal from 'src/components/organisms/Modal/Modal.jsx';
import Link from 'src/components/atoms/Link/Link.jsx';

export const ProductFoodFeedback = ({ productId }) => {
    const { trans, transChoice, i18n } = useI18n();
    const user = useSelector(userSelector);
    const containerRef = useRef();
    const [feedbacks, setFeedbacks] = useState([]);
    const [disabledLeftArrow, setDisabledLeftArrow] = useState(true);
    const [disabledRightArrow, setDisabledRightArrow] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const getFullWidth = useCallback(() => {
        if (feedbacks.length === 0 || containerRef.current?.children?.length < 2) {
            return 0;
        }
        const items = containerRef.current.children;
        const itemWidth = items.item(0).clientWidth;
        const spaceBetween =
            items.item(1)?.getBoundingClientRect()?.left -
            items.item(0)?.getBoundingClientRect()?.right;

        return (
            itemWidth * items.length +
            spaceBetween * (items.length - 1) -
            containerRef.current.clientWidth
        );
    }, [feedbacks]);

    const onScroll = useCallback(() => {
        if (feedbacks.length === 0) {
            return;
        }

        setDisabledLeftArrow(containerRef.current.scrollLeft === 0);
        setDisabledRightArrow(getFullWidth() <= containerRef.current.scrollLeft);
    }, [getFullWidth, containerRef, feedbacks]);

    const onArrowClick = useCallback(
        direction => () => {
            const containerStyle = window.getComputedStyle(containerRef.current);
            const columnGap = parseInt(containerStyle.gridColumnGap, 10);
            const margin =
                parseInt(containerStyle.marginLeft, 10) + parseInt(containerStyle.marginRight, 10);

            const moveTo =
                direction === 'left'
                    ? -(containerRef.current.clientWidth + columnGap + margin)
                    : containerRef.current.clientWidth + columnGap + margin;
            const actualPos = containerRef.current.scrollLeft;
            containerRef.current.scroll({ left: actualPos + moveTo, behavior: 'smooth' });
        },
        []
    );

    useEffect(() => {
        get(`feedbacks/products/${productId}`)
            .then(data => {
                setFeedbacks(data.feedbacks);
            })
            .catch(() => {});
    }, [productId]);

    useEffect(() => {
        onScroll();
    }, [getFullWidth, feedbacks, onScroll]);

    if (feedbacks.length === 0) {
        return <></>;
    }

    return (
        <div className="pi-product-food-feedback-container">
            <div className="pi-product-food-feedback">
                <div className="pi-product-food-feedback-header">
                    <Heading size={3} rank={4} productIdentity>
                        {transChoice('products.productPage.feedback.title', feedbacks.length)}
                    </Heading>
                    {!user.anonymous && user.hasPurchasedRecently && (
                        <Link href={`/${i18n}/feedback`} bypassRouter target="_blank">
                            <Text
                                className="pi-product-food-feedback-leave-review"
                                size="14px"
                                lineHeight="20px"
                                family="inter"
                                color="turquoise2"
                            >
                                {trans('products.productPage.feedback.leaveReview')}
                            </Text>
                        </Link>
                    )}
                </div>
                <div
                    ref={containerRef}
                    className={classnames('pi-product-food-feedback-carousel-container', {
                        'on-last-child': disabledRightArrow && feedbacks.length > 1,
                    })}
                    onScroll={onScroll}
                >
                    {feedbacks.slice(0, 9).map(feedback => {
                        return (
                            <ProductFoodFeedbackCard
                                key={feedback.id}
                                feedback={feedback}
                                typeFeedback={TYPE_REVIEW}
                            />
                        );
                    })}
                </div>
                {feedbacks.length > 3 && (
                    <div className="pi-product-food-feedback-footer">
                        {feedbacks.length > 9 && (
                            <Button
                                className="pi-product-food-feedback-footer-see-all"
                                mode={LINK_MODE}
                                onClick={() => setIsOpen(true)}
                            >
                                <Text
                                    size="14px"
                                    lineHeight="20px"
                                    family="inter"
                                    color="turquoise2"
                                >
                                    {trans('products.productPage.feedback.seeAll')}
                                </Text>
                            </Button>
                        )}
                        <div className="pi-product-food-feedback-footer-arrow-container">
                            <div
                                className={classnames(
                                    'pi-product-food-feedback-carousel-arrow-left',
                                    {
                                        disabled: disabledLeftArrow,
                                    }
                                )}
                                onClick={onArrowClick('left')}
                            />
                            <div
                                className={classnames(
                                    'pi-product-food-feedback-carousel-arrow-right',
                                    {
                                        disabled: disabledRightArrow,
                                    }
                                )}
                                onClick={onArrowClick('right')}
                            />
                        </div>
                    </div>
                )}
                <Modal
                    className="food-feedback-modal"
                    isMounted={isOpen}
                    closeModal={() => setIsOpen(false)}
                    title={trans('products.productPage.feedback.allReviews')}
                >
                    <>
                        {feedbacks.map(feedback => {
                            return (
                                <Fragment key={feedback.id}>
                                    <ProductFoodFeedbackCard
                                        classNames="all-review"
                                        feedback={feedback}
                                        typeFeedback={TYPE_REVIEW}
                                    />
                                    {feedback.response && (
                                        <ProductFoodFeedbackCard
                                            classNames="all-review"
                                            feedback={feedback}
                                            typeFeedback={TYPE_RESPONSE}
                                        />
                                    )}
                                </Fragment>
                            );
                        })}
                    </>
                </Modal>
            </div>
        </div>
    );
};

ProductFoodFeedback.propTypes = {
    productId: PropTypes.number.isRequired,
};

export default ProductFoodFeedback;
