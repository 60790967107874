import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export class TextInput extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        type: PropTypes.oneOf(['text', 'password', 'tel', 'search']),
        id: PropTypes.string,
        inputVariant: PropTypes.oneOf(['inverse']),
        block: PropTypes.bool,
        error: PropTypes.bool,
        isDisabled: PropTypes.bool,
        className: PropTypes.string,
    };

    static defaultProps = {
        type: 'text',
    };

    handleChange = event => {
        const input = event.target;
        this.props.onChange(this.props.name, input.value);
    };

    render() {
        const {
            name,
            value,
            type,
            id,
            inputVariant,
            block,
            error,
            isDisabled,
            className,
            ...rest
        } = this.props;

        return (
            <input
                {...rest}
                type={type}
                id={id || name}
                name={name}
                value={value}
                onChange={this.handleChange}
                className={classnames(
                    'fa-input',
                    {
                        'fa-input--block': block,
                        'fa-input--error': error,
                        'fa-input--inverse': inputVariant === 'inverse',
                    },
                    className
                )}
                disabled={isDisabled}
            />
        );
    }
}

export default TextInput;
