import PropTypes from 'prop-types';
import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';
import { closeAll as closeAllPanels } from 'modules/visiblePanel';
import FocusTrap from 'focus-trap-react';

export class PanelWrapper extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        closeAllPanels: PropTypes.func.isRequired,
    };

    handleClickOutside = () => {
        this.props.closeAllPanels();
    };

    render() {
        return (
            <FocusTrap
                focusTrapOptions={{
                    fallbackFocus: this.props.className && `.${this.props.className}`,
                }}
            >
                <div className={this.props.className}>{this.props.children}</div>
            </FocusTrap>
        );
    }
}

export default connect(undefined, {
    closeAllPanels,
})(onClickOutside(PanelWrapper));
