import React from 'react';
import PropTypes from 'prop-types';
import { getAddress } from 'app/scripts/components/geo/placeHelpers.js';
import Modal from 'src/components/organisms/Modal/Modal.jsx';
import Heading from 'components/Heading.jsx';
import Address from 'components/Address.jsx';
import SimpleMap from 'components/Maps/SimpleMap.jsx';

export const PlaceMapModal = props => {
    const {
        modalTitle,
        title,
        entity,
        placeType,
        isOpen,
        closeModal,
        children,
        defaultZoom,
    } = props;
    return (
        <Modal isMounted={isOpen} closeModal={closeModal} title={modalTitle} withFixedHeight>
            <div className="mapModal-wrapper">
                <div>
                    {title && (
                        <Heading productIdentity size={1}>
                            {title}
                        </Heading>
                    )}
                    {children || <Address address={getAddress(placeType, entity)} />}
                </div>
                <SimpleMap
                    places={[{ entity, placeType }]}
                    className="mapModal-map"
                    defaultZoom={defaultZoom}
                />
            </div>
        </Modal>
    );
};

PlaceMapModal.modalTitle = 'Carte';

PlaceMapModal.propTypes = {
    modalTitle: PropTypes.string,
    title: PropTypes.string,
    entity: PropTypes.object.isRequired,
    placeType: PropTypes.oneOf(['assembly', 'farm', 'pickup']).isRequired,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func,
    defaultZoom: PropTypes.number,
    children: PropTypes.node,
};
export default PlaceMapModal;
