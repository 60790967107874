import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import Utils from 'modules/utils';
import { put } from 'modules/api';
import { userSelector } from 'modules/currentUser';
import { firstFarmSelector } from 'modules/currentUser';

import useI18n from 'hooks/I18n/useI18n.js';

import Button from 'components/Button.jsx';
import { Text } from 'components/ui';
import MediaObject from 'components/MediaObject.jsx';
import Avatar from 'components/Avatar.jsx';
import Date from 'components/I18n/Date.jsx';
import WysiwygEditor from 'components/WysiwygEditor.jsx';
import { useSelector } from 'react-redux';
import { ProducerDashboardFeedbacksContext } from 'components/ProducerDashboard/ProducerDashboardFeedbacksContext.jsx';

const { filerPhoto } = Utils;

export const FeedbackMessageResponse = ({ productUuid, feedbackId, cancel, feedbackResponse }) => {
    const { trans } = useI18n();
    const [response, setResponse] = useState();
    const [wysiwygContent, setWysiwygContent] = useState('');
    const user = useSelector(userSelector);
    const farm = useSelector(firstFarmSelector);
    const farmPhoto = farm.photos && farm.photos[0].id;
    const { products, setProducts } = useContext(ProducerDashboardFeedbacksContext);

    const updateCommentInContext = useCallback(() => {
        const updatedProducts = products.map(product => {
            if (product.uuid !== productUuid) {
                return product;
            }

            const comments = product.comments.map(feedback => {
                if (feedback.id !== feedbackId) {
                    return feedback;
                }

                feedback.response = response;

                return feedback;
            });

            return { ...product, comments };
        });
        setProducts(updatedProducts);
    }, [response, productUuid, feedbackId, products, setProducts]);

    useEffect(() => {
        if (feedbackResponse.response !== null) {
            setResponse(feedbackResponse);
        }
    }, [feedbackResponse]);

    useEffect(() => {
        if (response !== undefined) {
            updateCommentInContext();
        }
    }, [response]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = () => {
        put(`feedbacks/${feedbackId}/respond`, { response: wysiwygContent }).then(() => {
            setResponse({
                farm_name: farm.name,
                photo: farmPhoto,
                firstname: user.firstName,
                lastname: user.lastName,
                response: wysiwygContent,
                responded_at: moment().format('YYYY-MM-DD'),
            });
        });
    };

    if (response !== undefined) {
        return (
            <div className="feedbackMessage-response">
                <MediaObject
                    valignCenter
                    media={<Avatar photoId={response.photo} size="small" rounded bordered />}
                >
                    <Text
                        className={classnames(
                            'feedbackMessage-content',
                            'feedbackMessage-content--smallPadding'
                        )}
                        dangerouslySetInnerHTML={{
                            __html: response.response,
                        }}
                    />
                </MediaObject>
                <Text className="feedbackMessage-informations" color="info">
                    {response.firstname} {response.lastname}
                    {' • '}
                    {response.farm_name}
                    {' • '}
                    <Date date={response.responded_at} format="dddd DD MMMM" />
                </Text>
            </div>
        );
    }

    if (feedbackResponse.response) {
        return null;
    }

    return (
        <div className="feedbackMessage-response messageForm">
            <img className="messageForm-userPhoto" src={filerPhoto(farmPhoto, 'small', 'user')} />
            <WysiwygEditor
                editContent={(name, value) => {
                    setWysiwygContent(value);
                }}
                placeholder={trans('producer.dashboard.feedbacks.response.placeholder')}
                removeLink
            />
            <div className="messageForm-actions">
                <Button type="button" weight="link" onClick={cancel}>
                    {trans('producer.dashboard.feedbacks.response.cancel')}
                </Button>
                <Button variant="success" onClick={onSubmit}>
                    {trans('producer.dashboard.feedbacks.response.send')}
                </Button>
            </div>
        </div>
    );
};

FeedbackMessageResponse.propTypes = {
    productUuid: PropTypes.string.isRequired,
    feedbackId: PropTypes.number.isRequired,
    cancel: PropTypes.func.isRequired,
    feedbackResponse: PropTypes.shape({
        farm_name: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        photo: PropTypes.string,
        responded_at: PropTypes.string,
        response: PropTypes.string,
    }),
};

export default FeedbackMessageResponse;
