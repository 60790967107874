import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import useI18n from 'hooks/I18n/useI18n';
import { convertRawTextToHtml, asWysiwygHtml, addBypassRouterToLink } from 'modules/utils/text';

import PIText from 'components/ProductIdentity/Text.jsx';
import Button from 'components/Button.jsx';

const baseClassName = 'newOverflowing';

export const LimitOverflowingContentWithEllipsis = props => {
    const { children, background, className, expandKey, reduceKey, onReduce } = props;
    const { trans } = useI18n();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(true);
    const [showButton, setShowButton] = useState(false);
    const ref = useRef();

    const classNames = useMemo(
        () =>
            classnames(
                baseClassName,
                {
                    [`${baseClassName}--expanded`]: isExpanded,
                    [`${baseClassName}--${background}`]: background,
                },
                className
            ),
        [isExpanded, background, className]
    );

    const expandOrReduce = useCallback(
        e => {
            e.stopPropagation();
            e.preventDefault();

            const currentIsExpended = isExpanded;

            setIsExpanded(!isExpanded);
            setIsOverflowing(false);

            if (currentIsExpended) {
                onReduce(ref.current);
            }
        },
        [isExpanded, ref, onReduce]
    );

    useEffect(() => {
        const currentIsOverflowing = asWysiwygHtml(children);
        const currentIsExpanded = ref.current.clientHeight === ref.current.scrollHeight;
        currentIsExpanded !== isExpanded && setIsExpanded(currentIsExpanded);
        currentIsOverflowing !== isOverflowing && setIsOverflowing(currentIsOverflowing);
    }, [isOverflowing, children, isExpanded]);

    useEffect(() => {
        setShowButton(ref.current.clientHeight !== ref.current.scrollHeight);
    }, []);

    return (
        <div className={classnames('pi-limit-overflowing', classNames)}>
            {
                <PIText size="14px" lineHeight="20px" family="inter" color="gray2">
                    <div
                        ref={ref}
                        className={classnames({ 'pi-limit-overflowing-truncated': !isExpanded })}
                        dangerouslySetInnerHTML={{
                            __html: asWysiwygHtml(children)
                                ? addBypassRouterToLink(children)
                                : convertRawTextToHtml(children),
                        }}
                    />
                    {showButton && (
                        <Button
                            className={classnames(
                                `${baseClassName}-button`,
                                `${baseClassName}-button--expand`,
                                'button--noPadding',
                                'button--link'
                            )}
                            type="button"
                            weight="link"
                            onClick={expandOrReduce}
                        >
                            {!isExpanded ? trans(expandKey) : reduceKey && trans(reduceKey)}
                        </Button>
                    )}
                </PIText>
            }
        </div>
    );
};

LimitOverflowingContentWithEllipsis.propTypes = {
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
    expandKey: PropTypes.string,
    background: PropTypes.oneOf(['white', 'bgLight70']),
    reduceKey: PropTypes.string,
    onReduce: PropTypes.func,
};
LimitOverflowingContentWithEllipsis.defaultProps = {
    collapsible: false,
    expandKey: 'global.readMore',
    collapseKey: 'global.collapse',
    background: 'bgLight70',
    reduceKey: '',
    onReduce: () => {},
};
export default LimitOverflowingContentWithEllipsis;
