import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import Box from 'components/Box.jsx';
import BoxSection from 'components/BoxSection.jsx';
import Button from 'components/Button.jsx';
import Legend from 'components/Legend.jsx';
import PageHeader from 'components/PageHeader.jsx';
import Paragraph from 'components/Paragraph.jsx';

class PaymentRequestStatus extends React.Component {
    static propTypes = {
        trans: PropTypes.func.isRequired,
    };

    back = () => {
        window.history.back();
    };

    render() {
        return (
            <div>
                <PageHeader
                    title={this.props.trans('onsite_sales.payment_request_status_page.title')}
                    background="default"
                />
                <Box>
                    <BoxSection>
                        <Paragraph size="x-large">
                            {this.props.trans('onsite_sales.payment_request_status_page.success')}
                        </Paragraph>
                    </BoxSection>
                    <BoxSection>
                        <Legend>
                            {this.props.trans('onsite_sales.payment_request_status_page.legend')}
                        </Legend>
                    </BoxSection>
                    <BoxSection>
                        <Button onClick={this.back} block variant="primary">
                            {this.props.trans('onsite_sales.payment_request_status_page.cta')}
                        </Button>
                    </BoxSection>
                </Box>
            </div>
        );
    }
}

export default connectToI18n(PaymentRequestStatus);
