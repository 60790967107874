import * as R from 'ramda';

import { hasAvailableStock } from 'modules/stockUtils';

import { MODE_LARGE, MODE_HORIZONTAL } from 'components/Sale/ProductIdentity/ProductCard.jsx';

export const POPULAR_PRODUCTS_ROW_TYPE = 'popular-products';
export const FARM_ROW_TYPE = 'farm';
export const TITLE_ROW_TYPE = 'title';

/* Définition des différents screens afin de calculer toutes les tailles
 * pour react-virtualized afin que le contenu soit responsive
 *
 * SCREEN_XXS < 516px
 * 516px <= SCREEN_XS < 688px
 * 688px <= SCREEN_S < 768px
 * 768px <= SCREEN_M < 1040px
 * 1040px <= SCREEN_L < 1360px
 * 1360px <= SCREEN_XL
 */
const SCREEN_XXS = {
    breakpoint: 0, // défini la width max pour la configuration (0 fait référence à la résolution la plus petite)
    containerWidth: 336, // taille du container contenant la liste des produits
    itemPerRow: 2, // Nombre de card vertical à afficher par row de react-virtualized
    horizontalItemPerRow: 1, // Nombre de card vertical à afficher par row de react-virtualized
    gap: 12, // Espace entre chaque card dans une row
    cardHeight: 244, // Hauteur d'une card vertical
    cardWidth: 156, // Largeur d'une card vertical
    horizontalCardWidth: 343, // Largeur card vertical
    horizontalCardHeight: 100, // Hauteur card vertical
    titleHeight: 44, // Hauteur des titres
    headerFarmMarginBottom: 40, // Marge inférieur du bloc producteur
    headerFarmHeight: 176, // Hauteur du bloc producteur
    productRowMarginBottom: 40, // Marge inférieur entre les row de produits
    popularProductRowMarginBottom: 40, // Marge inférieur du bloque popular
    lastProductRowMarginBottom: 40, // Marge inférieur de la derniere row du producteur
    headerHeight: 162, // Hauteur du header de la page (avec carousel categorie)
};
const SCREEN_XS = {
    breakpoint: 516,
    containerWidth: 516,
    itemPerRow: 3,
    horizontalItemPerRow: 1,
    gap: 12,
    cardHeight: 244,
    cardWidth: 156,
    horizontalCardWidth: 343,
    horizontalCardHeight: 100,
    titleHeight: 44,
    headerFarmMarginBottom: 40,
    headerFarmHeight: 176,
    productRowMarginBottom: 40,
    popularProductRowMarginBottom: 40,
    lastProductRowMarginBottom: 40,
    headerHeight: 162,
};
const SCREEN_S = {
    breakpoint: 688,
    containerWidth: 688,
    itemPerRow: 4,
    horizontalItemPerRow: 2,
    gap: 12,
    cardHeight: 244,
    cardWidth: 156,
    horizontalCardWidth: 343,
    horizontalCardHeight: 100,
    titleHeight: 44,
    headerFarmMarginBottom: 40,
    headerFarmHeight: 176,
    productRowMarginBottom: 40,
    popularProductRowMarginBottom: 40,
    lastProductRowMarginBottom: 40,
    headerHeight: 162,
};
const SCREEN_M = {
    breakpoint: 768,
    containerWidth: 620,
    itemPerRow: 3,
    horizontalItemPerRow: 1,
    gap: 8,
    cardHeight: 284,
    cardWidth: 196,
    horizontalCardWidth: 384,
    horizontalCardHeight: 166,
    titleHeight: 48,
    headerFarmMarginBottom: 40,
    headerFarmHeight: 180,
    productRowMarginBottom: 40,
    popularProductRowMarginBottom: 56,
    lastProductRowMarginBottom: 88,
    headerHeight: 194,
};
const SCREEN_L = {
    breakpoint: 1040,
    containerWidth: 824,
    itemPerRow: 4,
    horizontalItemPerRow: 2,
    gap: 8,
    cardHeight: 284,
    cardWidth: 196,
    horizontalCardWidth: 384,
    horizontalCardHeight: 166,
    titleHeight: 48,
    headerFarmMarginBottom: 40,
    headerFarmHeight: 180,
    productRowMarginBottom: 40,
    popularProductRowMarginBottom: 56,
    lastProductRowMarginBottom: 88,
    headerHeight: 194,
};
const SCREEN_XL = {
    breakpoint: 1360,
    itemPerRow: 5,
    horizontalItemPerRow: 2,
    gap: 8,
    cardHeight: 284,
    cardWith: 196,
    containerWidth: 1012,
    horizontalCardWidth: 384,
    horizontalCardHeight: 166,
    titleHeight: 48,
    headerFarmMarginBottom: 40,
    headerFarmHeight: 180,
    productRowMarginBottom: 40,
    popularProductRowMarginBottom: 56,
    lastProductRowMarginBottom: 88,
    headerHeight: 194,
};
export const getScreenConfig = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < SCREEN_XS.breakpoint) {
        return { ...SCREEN_XXS, containerWidth: windowWidth };
    }
    if (windowWidth < SCREEN_S.breakpoint) {
        return SCREEN_XS;
    }
    if (windowWidth < SCREEN_M.breakpoint) {
        return SCREEN_S;
    }
    if (windowWidth < SCREEN_L.breakpoint) {
        return SCREEN_M;
    }
    if (windowWidth < SCREEN_XL.breakpoint) {
        return SCREEN_L;
    }
    return SCREEN_XL;
};

const getRowMarginBottom = (component, screenConfig) => {
    if (component.type === POPULAR_PRODUCTS_ROW_TYPE) {
        return screenConfig.popularProductRowMarginBottom;
    }

    return component.isLastRow === true
        ? screenConfig.lastProductRowMarginBottom
        : screenConfig.productRowMarginBottom;
};

// Calcul la hauteur d'une row de la section "Tout les produits" d'un producteur
const getAllProductsHeight = component => {
    const screenConfig = getScreenConfig();
    const itemPerRow =
        component.type === MODE_HORIZONTAL
            ? screenConfig.horizontalItemPerRow
            : screenConfig.itemPerRow;
    const rowCount = Math.ceil(component.itemCount / itemPerRow);
    const cardHeight =
        component.type === MODE_HORIZONTAL
            ? screenConfig.horizontalCardHeight
            : screenConfig.cardHeight;

    return (
        cardHeight * rowCount +
        screenConfig.gap * (rowCount - 1) +
        getRowMarginBottom(component, screenConfig)
    );
};

// Calcul la hauteur de section "Ses produits populaires" d'un producteur
const getPopularProductHeight = component => {
    if (window.innerWidth < SCREEN_M.breakpoint) {
        return getScreenConfig().cardHeight + 42;
    }
    return getAllProductsHeight(component);
};

// Calcul la hauteur d'une row en fonction du type de row
export const getRowHeight = component => {
    const screenConfig = getScreenConfig();

    switch (component.type) {
        case FARM_ROW_TYPE:
            return screenConfig.headerFarmHeight + screenConfig.headerFarmMarginBottom;
        case POPULAR_PRODUCTS_ROW_TYPE:
            return getPopularProductHeight(component);
        case TITLE_ROW_TYPE:
            return screenConfig.titleHeight;
        default:
            return getAllProductsHeight(component);
    }
};

/* Pattern d'affichage des lignes de produits
 *   |------------------------------|---------------------------------|
 *   |        screen XS & M         |        screen S, L & XL         |
 *   |------------------------------|---------------------------------|
 *   |                    1 ligne de card vertical                    |
 *   |------------------------------|---------------------------------|
 * Et on répète le pattern si il y a encore des produits à afficher
 */
function* lineGenerator(verticalProducts) {
    const screenConfig = getScreenConfig();
    const localVerticalProducts = R.clone(verticalProducts);
    do {
        yield {
            mode: MODE_LARGE,
            productsLine: localVerticalProducts.splice(0, screenConfig.itemPerRow),
            isLastRow: localVerticalProducts.length === 0,
        };
    } while (localVerticalProducts.length > 0);
}

const stockAvailable = R.ascend(a => (hasAvailableStock(a) ? 0 : 1));
const typeComp = R.ascend(R.path(['type', 'id']));
const nameComp = R.ascend(R.prop('name'));

const sortProducts = R.sortWith([stockAvailable, typeComp, nameComp]);

export const getLineConfig = productsOfFarm => lineGenerator(sortProducts(productsOfFarm));
