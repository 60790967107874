module.exports = {
    center: {
        default: [2.65869, 46.65509],
        byLocale: {
            'fr-FR': [2.65869, 46.65509],
            'fr-BE': [4.96033, 50.56361],
            'nl-BE': [4.96033, 50.56361],
            'es-ES': [-3.39478, 40.33294],
            'ca-ES': [-3.39478, 40.33294],
            'de-DE': [10.50293, 51.46428],
            'it-IT': [9.99756, 45.37337],
            'nl-NL': [5.46966, 52.41682],
            'fr-CH': [7.57, 46.64],
            'de-CH': [7.57, 46.64],
        },
    },
    zoom: {
        default: 4,
        byLocale: {
            'fr-BE': 7,
            'nl-BE': 7,
            'nl-NL': 7,
            'de-CH': 8,
            'fr-CH': 8,
        },
    },
};
