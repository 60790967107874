import * as R from 'ramda';
import { send } from 'modules/http';
import { impersonate, authenticate, jsonRequest, prefixUrl, setLocale } from 'modules/http/request';
import { useRefreshToken, getToken } from 'modules/api/authentication';

function getLocaleFromLocation() {
    return window.location.pathname.split('/')[1];
}

export function redirectToLogin() {
    const path = window.location.pathname.split('/');
    const locale = path[1];
    const hasMinirucheInPath = path[2] === 'miniruche';
    const url = `/${path.splice(hasMinirucheInPath ? 3 : 2).join('/')}`;
    window.location = `/${locale}${
        hasMinirucheInPath ? '/miniruche' : ''
    }/login?redirectPath=${encodeURIComponent(url)}`;
}

function authenticateAndRetry(request) {
    return useRefreshToken()
        .then(({ access_token }) => send(authenticate(access_token, request)))
        .catch(err => {
            if (err.message === 'refresh_token_invalid') {
                redirectToLogin();
            } else {
                throw err;
            }
        });
}

function showMaintenancePage(request, error) {
    // TODO display a maintenance page
    throw error;
}

function showErrorPage(request, error) {
    // TODO display an error page
    throw error;
}

function handleServerError(request) {
    return function(error) {
        if (error.status === 401) {
            return authenticateAndRetry(request);
        } else if (error.status === 503) {
            return showMaintenancePage(request, error);
        } else if (error.status >= 500) {
            return showErrorPage(request, error);
        }
        throw error;
    };
}

function createMethod(method) {
    return function(url, data, headers) {
        const request = R.compose(
            setLocale(getLocaleFromLocation()),
            authenticate(getToken('access_token')),
            impersonate(getToken('impersonate')),
            prefixUrl(process.env.API_ROOT),
            jsonRequest
        )({
            method,
            url,
            data: method === 'GET' ? data : JSON.stringify(data),
            headers,
        });
        return send(request).catch(handleServerError(request));
    };
}

export const del = createMethod('DELETE');
export const get = createMethod('GET');
export const post = createMethod('POST');
export const put = createMethod('PUT');
export const patch = createMethod('PATCH');
