import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import InlineSVG from 'svg-inline-react';

import useI18n from 'hooks/I18n/useI18n';

import Icon from 'components/Icon.jsx';
import Input, { SMALL_SIZE } from 'src/components/atoms/Input/Input.jsx';

import searchIcon from 'app/assets/new-design/images/icons/search.svg';
import cross from 'src/components/atoms/Icons/svg/cross.svg';
import classNames from 'classnames';

const ProductSearch = ({ onFocus, onChange, searchString, clearSearch }) => {
    const { trans } = useI18n();
    const inputRef = useRef();
    const [isSearchString, setIsSearchString] = useState(searchString.length > 0);
    const clearSearchAndFocusInput = () => {
        clearSearch();
        inputRef.current.focus();
        setIsSearchString(false);
    };

    const inputOnChange = e => {
        onChange(e);
        setIsSearchString(e.target.value.length > 0);
    };

    useEffect(() => {
        setIsSearchString(searchString.length > 0);
    }, [searchString]);

    return (
        <Input
            ref={inputRef}
            onFocus={onFocus}
            onChange={inputOnChange}
            size={SMALL_SIZE}
            prefix={<Icon src={searchIcon} color="gray4" size="large" />}
            suffix={
                <InlineSVG
                    className={classNames('search-cross', { 'search-cross-show': isSearchString })}
                    onClick={() => clearSearchAndFocusInput()}
                    src={cross}
                />
            }
            placeholder={trans('salePage.search.placeholder')}
            value={searchString}
            autocomplete={false}
        ></Input>
    );
};

ProductSearch.propTypes = {
    onFocus: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    searchString: PropTypes.string,
    clearSearch: PropTypes.func.isRequired,
};

export default ProductSearch;
