import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import * as R from 'ramda';

import { appLogin } from 'models/auth';

import useI18n from 'hooks/I18n/useI18n';

import ResponsiveSidePanel, {
    POSITION_RIGHT,
} from 'components/Navigation/ProductIdentity/ResponsiveSidePanel.jsx';
import LoginForm from 'src/components/organisms/LoginForm/LoginForm.jsx';

const AnonymousProfilePanel = ({ onClose }) => {
    const ordersCollectionById = useSelector(state => state.ordersCollection?.byId || []);
    const methods = useForm();
    const { watch } = methods;
    const [email, password, remember] = watch(['email', 'password', 'remember']);
    const { pathname } = useLocation();
    const { trans } = useI18n();

    const getBasketUuidAndDistributionIdFromOrdersCollection = localOrdersCollectionById => {
        const orderCollectionAnonymous = R.find(R.propEq('anonymous', true))(
            R.values(localOrdersCollectionById)
        );
        return {
            anonymousBasketUuid: orderCollectionAnonymous?.uuid,
            distributionId: orderCollectionAnonymous?.distributionId,
        };
    };

    const handleSubmit = useCallback(() => {
        const {
            anonymousBasketUuid,
            distributionId,
        } = getBasketUuidAndDistributionIdFromOrdersCollection(ordersCollectionById);

        return appLogin(
            email,
            password,
            remember,
            pathname,
            anonymousBasketUuid,
            distributionId,
            true
        );
    }, [email, password, remember, pathname, ordersCollectionById]);

    return (
        <ResponsiveSidePanel
            isOpened
            position={POSITION_RIGHT}
            title={trans('login.title')}
            onClose={onClose}
            className="pi-anonymous-profile-panel"
        >
            <FormProvider {...methods}>
                <LoginForm onSubmit={handleSubmit} />
            </FormProvider>
        </ResponsiveSidePanel>
    );
};

AnonymousProfilePanel.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default AnonymousProfilePanel;
