import { getNewsfeeds, getUnreadNewsfeedCount, postReadNewsfeed } from 'api/newsfeeds';

export const FETCH_NEWSFEED = 'newsfeed/FETCH_NEWSFEED';
export const FETCH_NEWSFEED_SUCCESS = 'newsfeed/FETCH_NEWSFEED_SUCCESS';
export const FETCH_NEWSFEED_ERROR = 'newsfeed/FETCH_NEWSFEED_ERROR';
export const FETCH_UNREAD_NEWSFEED_COUNT = 'newsfeed/FETCH_UNREAD_NEWSFEED_COUNT';
export const READ_NEWSFEED = 'newsfeed/READ_NEWSFEED';

export const fetchNewsfeed = recipientType => dispatch => {
    dispatch({
        type: FETCH_NEWSFEED,
    });

    return getNewsfeeds(recipientType)
        .then(data => {
            dispatch({
                type: FETCH_NEWSFEED_SUCCESS,
                payload: data,
            });
        })
        .catch(() => {
            dispatch({
                type: FETCH_NEWSFEED_ERROR,
            });
        });
};

export const fetchUnreadNewsfeedCount = recipientType => dispatch => {
    if (!recipientType) {
        return dispatch({
            type: FETCH_NEWSFEED_ERROR,
        });
    }
    return getUnreadNewsfeedCount(recipientType)
        .then(data => {
            if (data && data.unreadCount) {
                return dispatch({
                    type: FETCH_UNREAD_NEWSFEED_COUNT,
                    payload: parseInt(data.unreadCount, 10),
                });
            }
            return dispatch({
                type: FETCH_NEWSFEED_ERROR,
            });
        })
        .catch(() =>
            dispatch({
                type: FETCH_NEWSFEED_ERROR,
            })
        );
};

export const readNewsfeed = recipientType => dispatch =>
    postReadNewsfeed(recipientType).then(() =>
        dispatch({
            type: READ_NEWSFEED,
        })
    );
