import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';
import {
    FEATURE_STATUS_CONSTRUCT,
    FEATURE_STATUS_OPEN,
    FEATURE_TYPE_ASSEMBLY,
    FEATURE_TYPE_PICKUP,
} from 'modules/maps/mapboxGL/clusteredMap';
import useI18n from 'hooks/I18n/useI18n';
import Modal from 'src/components/organisms/Modal/Modal.jsx';
import AssemblyWithOpenDistributions from './AssemblyWithOpenDistributions.jsx';
import AssemblyWithoutAnyOpenDistributions from './AssemblyWithoutAnyOpenDistribution.jsx';
import Button, { LINK_MODE } from 'src/components/atoms/Button/Button.jsx';
import { AssembliesMapModalNavContext } from 'components/Maps/ProductIdentity/AssembliesMap/AssembliesMapModalNavigactionContext.jsx';
import ConstructAssembly from './ConstructAssembly.jsx';
import Pickup from './Pickup.jsx';

import Caret from 'app/assets/new-design/images/icons/caret.svg';

export const MobileAssemblyInformationsModal = ({
    selectedItem,
    closeModal,
    assemblies,
    onClickNearbyAssembly,
    goBack,
    opened = false,
}) => {
    const { trans } = useI18n();
    const { historyAssemblies, showNearbyAssemblies } = useContext(AssembliesMapModalNavContext);
    const selectedItemIsAnOpenedAssembly =
        selectedItem?.type === FEATURE_TYPE_ASSEMBLY &&
        selectedItem.properties.status === FEATURE_STATUS_OPEN;
    const selectedItemIsAConstructAssembly =
        selectedItem?.type === FEATURE_TYPE_ASSEMBLY &&
        selectedItem.properties.status === FEATURE_STATUS_CONSTRUCT;
    const selectedItemIsAPickup = selectedItem?.type === FEATURE_TYPE_PICKUP;

    return (
        <Modal
            isMounted={opened}
            title={
                <>
                    {(historyAssemblies.length > 1 || showNearbyAssemblies) && (
                        <Button
                            mode={LINK_MODE}
                            className="map-modal-go-back-button"
                            onClick={goBack}
                        >
                            <InlineSVG raw src={Caret} />
                        </Button>
                    )}
                    {trans('assembliesMap.assemblyInformationModal.title')}
                </>
            }
            closeModal={closeModal}
            withTransitions
            delayTime={300}
        >
            <>
                {selectedItemIsAnOpenedAssembly &&
                    !!selectedItem.properties?.openedDistributions && (
                        <AssemblyWithOpenDistributions assembly={selectedItem.properties} />
                    )}
                {selectedItemIsAnOpenedAssembly &&
                    !selectedItem.properties?.openedDistributions && (
                        <AssemblyWithoutAnyOpenDistributions
                            assembly={selectedItem.properties}
                            assemblies={assemblies}
                            onClickNearbyAssembly={onClickNearbyAssembly}
                        />
                    )}
                {selectedItemIsAConstructAssembly && (
                    <ConstructAssembly assembly={selectedItem.properties} />
                )}
                {selectedItemIsAPickup && <Pickup pickup={selectedItem.properties} />}
            </>
        </Modal>
    );
};

MobileAssemblyInformationsModal.propTypes = {
    opened: PropTypes.bool,
    selectedItem: PropTypes.object,
    closeModal: PropTypes.func.isRequired,
    assemblies: PropTypes.arrayOf(PropTypes.object),
    onClickNearbyAssembly: PropTypes.func,
    goBack: PropTypes.func,
};

export default MobileAssemblyInformationsModal;
