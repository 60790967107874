import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useI18n from 'hooks/I18n/useI18n';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import classnames from 'classnames';

import { get } from 'modules/api';
import { changeSelectedPickup } from 'modules/delivery';
import { LOADING, READY } from 'modules/utils/ajaxStatuses';
import { getIsoWeekday, getLocalizedTime, getLocalizedDay } from 'modules/utils/dateAndTime';
import * as localstorage from 'modules/localstorage';
import { getLocalizedDate } from 'modules/utils/dateAndTime';

import { getCurrentDistributions } from 'models/distributions';
import { getDeliveryOptionInfo, getOptionValue } from 'models/delivery';

import ResponsiveSidePanel, {
    POSITION_LEFT,
} from 'components/Navigation/ProductIdentity/ResponsiveSidePanel.jsx';
import { capitalizeFirstLetter } from 'modules/utils/string';
import Text from 'components/ProductIdentity/Text.jsx';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import { Heading } from 'components/ui';

const DeliveryPanel = ({ currentDistributionId, currentAssembly, onClose }) => {
    const { trans, i18n } = useI18n();
    const dispatch = useDispatch();
    const history = useHistory();
    const [salesWithOptions, setSalesWithOptions] = useState();
    const [status, setStatus] = useState(LOADING);
    const [selectedOption, setSelectedOption] = useState();

    useEffect(() => {
        const salesOpen = getCurrentDistributions(currentAssembly);
        const salesOptionsRequests = R.map(
            sale => get(`distributions/${sale.distributionId}/pickup-and-delivery-options`),
            salesOpen
        );
        Promise.all(salesOptionsRequests).then(salesOptions => {
            const createSalesWithOptions = (sale, options) => {
                return {
                    distributionId: sale.distributionId,
                    distributionDate: sale.distributionDate,
                    options,
                };
            };
            setSalesWithOptions(R.zipWith(createSalesWithOptions, salesOpen, salesOptions));
            setStatus(READY);
            setSelectedOption(
                localstorage.getItem(`delivery.option_${currentDistributionId}`) ||
                    `${currentDistributionId}_assembly`
            );
        });
    }, [currentAssembly, currentDistributionId]);

    useEffect(() => {
        if (!selectedOption) {
            return;
        }

        const { distributionId } = getDeliveryOptionInfo(selectedOption);

        dispatch(changeSelectedPickup(distributionId, selectedOption));

        if (distributionId !== currentDistributionId.toString()) {
            history.push(
                `/assemblies/${currentAssembly.id}/collections/${distributionId}/products`
            );
            window.location.reload();
        }
    }, [selectedOption, currentAssembly, currentDistributionId, dispatch, history]);

    if (status !== READY) {
        return (
            <ResponsiveSidePanel
                isOpened
                position={POSITION_LEFT}
                title={trans('hives.informations.distributionPanelTitle')}
                onClose={onClose}
            >
                <LoadingAnimation type="pi-spinner" />
            </ResponsiveSidePanel>
        );
    }

    return (
        <ResponsiveSidePanel
            className="delivery-panel"
            isOpened
            position={POSITION_LEFT}
            title={trans('hives.informations.distributionPanelTitle')}
            onClose={onClose}
        >
            {salesWithOptions.map(sale => {
                return (
                    <Fragment key={sale.distributionId}>
                        <Heading className="pi-heading" size={3}>
                            {capitalizeFirstLetter(
                                getLocalizedDate(i18n, 'I', sale.distributionDate)
                            )}
                        </Heading>
                        {sale.options.map(option => {
                            const optionValue = getOptionValue(option, sale.distributionId);

                            return (
                                <div
                                    className={classnames('assembly-delivery-slot', {
                                        selected: selectedOption === optionValue,
                                    })}
                                    key={option.id}
                                    onClick={() => {
                                        setSelectedOption(optionValue);
                                    }}
                                >
                                    <Text
                                        className={classnames('delivery-slot-name', {
                                            'delivery-offer': option.type === 'deliveryOffer',
                                        })}
                                        size="16px"
                                        lineHeight="24px"
                                        family="inter"
                                        weight={600}
                                        color="grey8"
                                    >
                                        {capitalizeFirstLetter(option.name)}
                                    </Text>
                                    {option.type !== 'deliveryOffer' && (
                                        <>
                                            <Text
                                                size="14px"
                                                lineHeight="20px"
                                                color="grey8"
                                                dangerouslySetInnerHTML={{
                                                    __html: option.meetingPoint.fullAddress
                                                        .split(', ')
                                                        .filter(
                                                            addressPart => addressPart !== 'France'
                                                        )
                                                        .join('<br/>'),
                                                }}
                                            />
                                            <ul>
                                                {option.timeSlots.map(timeSlot => {
                                                    return (
                                                        <li key={timeSlot.dayOfWeek}>
                                                            {trans('selectDelivery.timeSlot', {
                                                                '%day%': getLocalizedDay(
                                                                    i18n,
                                                                    getIsoWeekday(
                                                                        timeSlot.dayOfWeek
                                                                    )
                                                                ),
                                                                '%start%': getLocalizedTime(
                                                                    i18n,
                                                                    timeSlot.timeStart
                                                                ),
                                                                '%end%': getLocalizedTime(
                                                                    i18n,
                                                                    timeSlot.timeEnd
                                                                ),
                                                            })}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </Fragment>
                );
            })}
        </ResponsiveSidePanel>
    );
};

DeliveryPanel.propTypes = {
    currentDistributionId: PropTypes.number.isRequired,
    currentAssembly: PropTypes.shape({
        id: PropTypes.number,
        hasHomeDelivery: PropTypes.bool,
        distributions: PropTypes.array,
    }).isRequired,
    onClose: PropTypes.func,
};
DeliveryPanel.defaultProps = {
    onClose: () => null,
};
export default DeliveryPanel;
