import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { changeSelectedPickup } from 'modules/delivery';
import { getOptionValue } from 'models/delivery';
import { get } from 'modules/api';
import useI18n from 'hooks/I18n/useI18n';
import Link, { DISPLAY_MODES } from 'src/components/atoms/Link/Link.jsx';
import Text from 'src/components/atoms/Text/Text.jsx';
import HostCard from './HostCard.jsx';
import PickupSlot from './PickupSlot.jsx';

export const Pickup = ({ pickup }) => {
    const { i18n, trans } = useI18n();
    const [selectedPickup, setSelectedPickup] = useState(null);
    const [selectedHiveId, setSelectedHiveId] = useState(null);
    const dispatch = useDispatch();
    const sortedPickupDates = useMemo(() => {
        return pickup.pickupDates.slice().sort((a, b) => {
            const aStartDate = new Date(a.timeStart);
            const bStartDate = new Date(b.timeStart);
            return aStartDate - bStartDate;
        });
    }, [pickup.pickupDates]);

    useEffect(() => {
        setSelectedHiveId(sortedPickupDates[0].assemblyId);
        setSelectedPickup(sortedPickupDates[0]);
    }, [sortedPickupDates]);

    const handleDistributionSlotClick = (assemblyId, pickupSelected) => {
        get(`distributions/${pickupSelected.distributionId}/pickup-and-delivery-options`).then(
            deliveryOptions => {
                const deliveryOptionsPickup = deliveryOptions.filter(
                    ({ type, ref }) => type === 'pickup' && ref === pickup.uuid
                );
                setSelectedHiveId(assemblyId);
                setSelectedPickup(pickupSelected);
                deliveryOptionsPickup.length &&
                    dispatch(
                        changeSelectedPickup(
                            pickupSelected.distributionId,
                            getOptionValue(deliveryOptionsPickup[0], pickupSelected.distributionId)
                        )
                    );
            }
        );
    };

    return (
        <>
            <div className="map-assembly-and-distribution-block">
                <HostCard
                    photoUuid={pickup.host.photoUuid}
                    pointOfSaleName={pickup.name}
                    street={pickup.meetingPoint.fullAddress}
                    hostFirstname={pickup.host.firstname}
                />
                <div className="map-available-distribution-slot-container pi-typo">
                    {sortedPickupDates.map(pickupDate => {
                        const selected =
                            pickupDate.distributionId +
                                pickupDate.timeStart +
                                pickupDate.timeEnd ===
                            selectedPickup?.distributionId +
                                selectedPickup?.timeStart +
                                selectedPickup?.timeEnd;
                        return (
                            <PickupSlot
                                key={pickupDate.distributionId + pickupDate.timeStart}
                                assemblyCity={pickupDate.assemblyCity}
                                assemblyName={pickupDate.assemblyName}
                                timeStart={pickupDate.timeStart}
                                timeEnd={pickupDate.timeEnd}
                                selected={selected}
                                onSlotClick={() =>
                                    handleDistributionSlotClick(pickupDate.assemblyId, pickupDate)
                                }
                            />
                        );
                    })}
                </div>
            </div>
            <div className="go-to-sale-btn-container">
                <Link
                    href={`/${i18n}/assemblies/${selectedHiveId}/collections/${selectedPickup?.distributionId}/products`}
                    className={classnames({ disabled: !selectedPickup?.distributionId })}
                    displayMode={DISPLAY_MODES.BUTTON}
                    bypassRouter
                >
                    <Text>{trans('assembliesMap.modal.goToSaleBtn')}</Text>
                </Link>
            </div>
        </>
    );
};

Pickup.propTypes = {
    pickup: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        host: PropTypes.shape({
            photoUuid: PropTypes.string,
            firstname: PropTypes.string.isRequired,
        }).isRequired,
        meetingPoint: PropTypes.shape({
            fullAddress: PropTypes.string.isRequired,
        }).isRequired,
        pickupDates: PropTypes.arrayOf(
            PropTypes.shape({
                assemblyCity: PropTypes.string.isRequired,
                assemblyName: PropTypes.string.isRequired,
                distributionId: PropTypes.number.isRequired,
                timeStart: PropTypes.string.isRequired,
                timeEnd: PropTypes.string.isRequired,
            })
        ),
    }),
};

export default Pickup;
