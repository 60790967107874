import React from 'react';
import PropTypes from 'prop-types';
import StructuredSidePanel from 'components/StructuredSidePanel.jsx';
import { NEWSFEED_PANEL, getVisiblePanel } from 'modules/visiblePanel.js';
import { useSelector } from 'react-redux';
import NewsfeedContainer from 'components/Newsfeed/NewsfeedContainer.jsx';
import { HIVE_RECIPIENT, FARM_RECIPIENT } from 'models/newsfeed.js';

const NewsfeedBottomDropdown = ({ recipientType }) => {
    const visiblePanel = useSelector(getVisiblePanel);
    const isNewsfeedPanelActive = visiblePanel === NEWSFEED_PANEL;
    return (
        <StructuredSidePanel isOpened={isNewsfeedPanelActive}>
            <NewsfeedContainer recipientType={recipientType} />
        </StructuredSidePanel>
    );
};

NewsfeedBottomDropdown.propTypes = {
    recipientType: PropTypes.oneOf([HIVE_RECIPIENT, FARM_RECIPIENT]).isRequired,
};

export default NewsfeedBottomDropdown;
