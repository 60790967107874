import { submitBasketDeliveryOption } from 'api/baskets';

import * as localstorage from 'modules/localstorage';
import { ASSEMBLY } from 'modules/utils/deliveryOptions';

export const SUBMIT_DELIVERY_OPTION_SUCCEEDED = 'SUBMIT_DELIVERY_OPTION_SUCCEEDED';
export const CHANGE_DELIVERY_OPTION = 'CHANGE_DELIVERY_OPTION';

export const submitDeliveryOption = (dataToSend, basketId) => dispatch => {
    return submitBasketDeliveryOption(basketId, dataToSend).then(basket => {
        dispatch({
            type: SUBMIT_DELIVERY_OPTION_SUCCEEDED,
            basket,
        });
    });
};

const setDeliveryOption = selectedOption => ({
    type: CHANGE_DELIVERY_OPTION,
    selectedOption,
});

export const changeDeliveryOption = (distributionId, newSelectedOption) => dispatch => {
    localstorage.setItem(`delivery.option_${distributionId}`, newSelectedOption);
    return dispatch(setDeliveryOption(newSelectedOption));
};

export const initDeliveryOption = (distributionId, deliveryOptions) => dispatch => {
    const storedPickup = localstorage.getItem(`delivery.option_${distributionId}`);
    const firstOptionProposed = deliveryOptions[0];
    const isFirstOptionAssemblyType = firstOptionProposed.type === ASSEMBLY;
    const defaultOption = isFirstOptionAssemblyType
        ? `${distributionId}_${ASSEMBLY}`
        : `${distributionId}_${firstOptionProposed.type}_${firstOptionProposed.ref}`;
    const selectedOption = storedPickup || defaultOption;
    return dispatch(setDeliveryOption(selectedOption));
};
