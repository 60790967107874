import React, { createContext, useState } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

export const ProductsContext = createContext();

export const ProductsProvider = ({ children }) => {
    const [products, setProducts] = useState([]);

    const setCustomEventTagged = (productId, enabled) => {
        setProducts(prevProducts =>
            R.map(
                R.when(R.propEq('id', productId), R.assoc('isCustomEventTagged', enabled)),
                prevProducts
            )
        );
    };

    return (
        <ProductsContext.Provider value={{ products, setCustomEventTagged, setProducts }}>
            {children}
        </ProductsContext.Provider>
    );
};

ProductsProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf([PropTypes.element])]),
};
