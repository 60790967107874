import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { requiredIfOtherAbsent } from 'modules/propTypes';

const Heading = ({
    size,
    rank,
    bold,
    serif,
    responsive,
    className,
    children,
    dangerouslySetInnerHTML,
    productIdentity,
    family,
    id,
}) => {
    const actualRank = rank || size;
    const Tag = `h${actualRank}`;

    const classNames = classnames(
        {
            'fa-text--bold': bold,
            'fa-text--serif': serif,
            [`heading--h${size}`]: size && !productIdentity,
            [`pi-heading pi-heading--h${size}`]: size && productIdentity,
            'heading--responsive': responsive,
            [`pi-heading-${family}`]: family && productIdentity,
        },
        className
    );

    if (dangerouslySetInnerHTML) {
        return (
            <Tag id={id} className={classNames} dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
        );
    }

    return (
        <Tag id={id} className={classNames}>
            {children}
        </Tag>
    );
};

Heading.propTypes = {
    id: PropTypes.string,
    size: PropTypes.oneOf([1, 2, 3, 4]),
    className: PropTypes.string,
    responsive: PropTypes.bool,
    children: requiredIfOtherAbsent('dangerouslySetInnerHTML'),
    dangerouslySetInnerHTML: requiredIfOtherAbsent('children'),
    rank: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
    serif: PropTypes.bool,
    bold: PropTypes.bool,
    productIdentity: PropTypes.bool,
    family: PropTypes.oneOf(['inter']),
};

export default Heading;
