import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import useI18n from 'hooks/I18n/useI18n';

import { resetOrderToCart } from 'modules/orders';

import Text from 'components/ProductIdentity/Text.jsx';
import Button, { SMALL_SIZE } from 'src/components/atoms/Button/Button.jsx';
import Modal from 'src/components/organisms/Modal/Modal.jsx';

const OrderResetToCartModal = ({ orderId, onClose, show }) => {
    const { trans } = useI18n();
    const dispatch = useDispatch();

    const onValidate = useCallback(() => {
        dispatch(resetOrderToCart(orderId));
        onClose();
    }, [dispatch, orderId, onClose]);
    return (
        <Modal
            className="order-reset-card-modal"
            isMounted={show}
            closeModal={onClose}
            title={trans('order.reset_to_cart.title')}
            footer={
                <Button className="reset-card-button" onClick={onValidate} size={SMALL_SIZE}>
                    {trans('global.continue')}
                </Button>
            }
        >
            <Text size="16px" lineHeight="24px" color="gray2">
                {trans('basket.reset_to_cart.confirm.message')}
            </Text>
        </Modal>
    );
};
OrderResetToCartModal.propTypes = {
    orderId: PropTypes.number,
    show: PropTypes.bool,
    onClose: PropTypes.func, // Propagate by Modal component
};
export default OrderResetToCartModal;
