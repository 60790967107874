import moment from 'moment';
import { momentNow } from 'models/date';
import { getLocaleConfig } from 'modules/config/i18n';
import Globalize from 'globalize';
import * as R from 'ramda';

const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export function getIsoWeekday(shortDay) {
    return daysOfWeek.indexOf(shortDay) + 1;
}

export function getShortDayFromIsoWeekday(isoWeekday) {
    return daysOfWeek[isoWeekday - 1];
}

export function getLocalizedDate(locale, format, date) {
    if (!date || !format || !locale) {
        return date;
    }
    const presets = getLocaleConfig(locale).dateFormats;
    const formatOrPreset = presets[format] || format;
    return (
        moment(date)
            .locale(locale)
            .format(formatOrPreset)
            // the library moment.js send italian day with undesirable caracter º "gio 7º mar"
            .replace('º', '')
    );
}

export function getLocalizedTime(locale, time) {
    const momentTime = moment.isDate(time) ? moment(time) : moment(time, 'HH:mm:ss');
    return momentTime.locale(locale || 'fr').format('LT');
}

export function getLocalizedDay(locale, day) {
    const dayIndex = day === 7 ? 0 : day;
    return moment()
        .day(dayIndex)
        .locale(locale || 'fr')
        .format('dddd');
}

export function getLocalizedMonth(locale, month) {
    return moment()
        .month(month)
        .locale(locale || 'fr')
        .format('MMMM');
}

export function isDateIntheFuture(date) {
    return moment(date).isAfter(momentNow());
}

export function isDateUnderOneYear(date) {
    return moment(date).isAfter(momentNow().subtract(12, 'months'));
}

export function isToday(date) {
    if (date) {
        const today = new Date();
        const toCompare = new Date(date);
        if (today.toDateString() === toCompare.toDateString()) {
            return true;
        }
    }
    return false;
}

export function nextHour() {
    return new Date().getHours() + 1;
}

/**
 * Whether or not the given UTC date string is oldest than N hours.
 *
 * @param  {String} utcDate (e.g. '2017-08-24T12:00:00.000Z')
 * @param  {Number} hours
 * @return {Boolean}
 */
export function isDatePastInHours(utcDate, hours) {
    return momentNow().diff(moment.utc(utcDate), 'hours', true) >= hours;
}

function pad(number) {
    return number < 10 ? `0${number}` : `${number}`;
}

function minutesToTime(minutes) {
    const hour = Math.floor(minutes / 60);
    const remainingMinutes = minutes - hour * 60;
    return `${pad(hour)}:${pad(remainingMinutes)}:00`;
}

export function getTimesWithInterval(intervalInMinutes = 1) {
    const minutesInADay = 60 * 24;
    const result = R.pipe(
        R.range(0),
        R.map(R.pipe(R.multiply(intervalInMinutes), minutesToTime))
    )(Math.floor(minutesInADay / intervalInMinutes));
    return result;
}

export function isoStringDatePart(dateTimeIsoStr) {
    return dateTimeIsoStr.substring(0, 10);
}

function _getFullWeek(locale) {
    const dayNames = Globalize.culture(locale).calendar.days.names;
    const week = dayNames.map((day, index) => {
        const dayId = index === 0 ? 7 : index;
        return R.pipe(
            R.assoc('id', dayId),
            R.assoc('name', day),
            R.assoc('shortName', Globalize.culture(locale).calendar.days.namesShort[index])
        )({});
    });
    return R.sortBy(R.prop('id'), week);
}

export const getFullWeek = R.memoizeWith(R.identity, _getFullWeek);

function _getWorkingHours(locale) {
    const hours = [];

    for (let i = 6; i <= 22; i++) {
        const formattedHour = `0${i}:00:00`.slice('-8');
        const half = `0${i}:30:00`.slice('-8');

        hours.push({
            id: parseInt(formattedHour.replace(':', ''), 10),
            name: getLocalizedTime(locale, formattedHour),
            value: formattedHour,
        });

        hours.push({
            id: parseInt(half.replace(':', ''), 10),
            name: getLocalizedTime(locale, half),
            value: half,
        });
    }

    return hours;
}

export const getWorkingHours = R.memoizeWith(R.identity, _getWorkingHours);

export const getDaysOfWeekAsNumbers = R.range(1, 7);

export const getYear = date => {
    if (R.isNil(date) || R.isEmpty(date)) {
        return null;
    }

    const year = R.pipe(R.split('-'), R.head, R.flip(R.curry(parseInt))(10))(date);

    if (Number.isNaN(year)) {
        return null;
    }

    return year;
};

/**
 * returns date in plain text following the locale.
 * Example :
 * - en : thursday, July 30
 * - fr : jeudi 30 juillet
 *
 * @param locale ('en', 'fr'...)
 * @param date : a Date object
 */
export const getLocalizedDateWithMonthDayWithoutYear = (locale, date) => {
    const options = { weekday: 'long', month: 'long', day: 'numeric' };

    const localizedDate = date.toLocaleDateString(locale, options);
    return localizedDate;
};

export const isSameYear = (date1, date2) => getYear(date1) === getYear(date2);
