const INIT_SCOPE = '@@INIT_SCOPE';

const INIT_ACTION = {
    type: INIT_SCOPE,
};

/**
 *
 */
export default function createScopedReducer(initialReducer, options) {
    const { contextKey, actionTypes } = options;

    return (state = {}, action) => {
        // If the reducer is concerned
        if (actionTypes.indexOf(action.type) !== -1) {
            if (!action.payload[contextKey]) {
                throw new Error(
                    `You need to send a ${contextKey} property in the payload of the actions of types ${actionTypes}`
                );
            }

            const context = action.payload[contextKey];
            const contextState = state[context];
            return {
                ...state,
                [context]: contextState
                    ? initialReducer(contextState, action)
                    : initialReducer(initialReducer(contextState, INIT_ACTION), action),
            };
        }
        return state;
    };
}
