import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Badge from 'components/Badge.jsx';
import useI18n from 'hooks/I18n/useI18n.js';
import InlineSVG from 'svg-inline-react';
import { useSelector, useDispatch } from 'react-redux';
import StructuredSidePanel from 'components/StructuredSidePanel.jsx';
import { toggle as togglePanel, getVisiblePanel, NEWSFEED_PANEL } from 'modules/visiblePanel';
import { Button } from 'components/ui';
import NewsfeedContainer from 'components/Newsfeed/NewsfeedContainer.jsx';
import { HIVE_RECIPIENT, FARM_RECIPIENT } from 'models/newsfeed.js';
import { fetchUnreadNewsfeedCount, unreadSelector } from 'modules/newsfeed';
import * as R from 'ramda';

const NewsfeedDropdown = ({ recipientType, activeLink, currentAssembly }) => {
    const { trans } = useI18n();
    const visiblePanel = useSelector(getVisiblePanel);
    const isPanelActive = visiblePanel === NEWSFEED_PANEL;
    const badgeValue = useSelector(unreadSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUnreadNewsfeedCount(recipientType));
    }, [recipientType, activeLink, dispatch]);

    if (R.not(R.contains(recipientType, [HIVE_RECIPIENT, FARM_RECIPIENT]))) {
        return null;
    }

    const togglePanelHandle = () => dispatch(togglePanel(NEWSFEED_PANEL));

    return (
        <div className="topNavigation-panelContainer">
            <Button
                type="button"
                weight="light"
                onClick={togglePanelHandle}
                className="topNavigation-link"
            >
                <InlineSVG src={require('icons/newsfeed.svg')} className="icon icon-newsfeed" />
                {trans('navigation.left.newsfeed')}
                {badgeValue !== 0 && <Badge>{badgeValue}</Badge>}
                <StructuredSidePanel isOpened={isPanelActive} isNewNavigationLayout>
                    <NewsfeedContainer
                        recipientType={recipientType}
                        currentAssemblyId={currentAssembly?.id}
                    />
                </StructuredSidePanel>
            </Button>
        </div>
    );
};

NewsfeedDropdown.propTypes = {
    recipientType: PropTypes.oneOf([HIVE_RECIPIENT, FARM_RECIPIENT]).isRequired,
    activeLink: PropTypes.string,
    currentAssembly: PropTypes.object,
};

export default NewsfeedDropdown;
