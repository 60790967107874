import PropTypes from 'prop-types';
import React from 'react';
import { POPULAR_PRODUCTS_BY_FARM } from 'modules/originUtils.js';
import PIProductCard, { MODE_LARGE } from 'components/Sale/ProductIdentity/ProductCard.jsx';

export class PopularProducts extends React.Component {
    static propTypes = {
        products: PropTypes.array.isRequired,
        addOfferToBasket: PropTypes.func.isRequired,
        productTypes: PropTypes.object.isRequired,
        distributionId: PropTypes.number.isRequired,
        isSmallWidth: PropTypes.bool.isRequired,
        onClickOnProduct: PropTypes.func.isRequired,
    };
    render() {
        const {
            products,
            addOfferToBasket,
            productTypes,
            distributionId,
            isSmallWidth,
            onClickOnProduct,
        } = this.props;

        return (
            <div className="popularProducts-cards">
                {products.map(product => (
                    <PIProductCard
                        key={product.id}
                        addOfferToBasket={addOfferToBasket}
                        allProductTypesIndexedById={productTypes}
                        distributionId={distributionId}
                        product={product}
                        variant="vertical"
                        size="small"
                        mode={MODE_LARGE}
                        showProducer={false}
                        displayLegendBelow={isSmallWidth}
                        actionOrigin={POPULAR_PRODUCTS_BY_FARM}
                        onClick={() => onClickOnProduct(product.id)}
                    />
                ))}
            </div>
        );
    }
}

export default PopularProducts;
