import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import * as R from 'ramda';

import useI18n from 'hooks/I18n/useI18n';

import FeaturesService, { FEATURES } from 'models/features';
import { currentAssemblySelector } from 'modules/navigation';

import Price from 'components/Price.jsx';
import Discount, { MODE_PARENTHESIS } from 'components/Discount.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import helpIcon from 'icons/pi/help-circle.svg';
import Tooltip from 'components/Tooltip.jsx';
import InlineSVG from 'svg-inline-react';
import Popover from 'src/components/atoms/Popover/Popover.jsx';

export const PriceAndPromotion = ({
    offer,
    className,
    hiddenOriginalOfferOnMobile,
    displayColumnReverseOnMobile,
    reverseOnMobile,
    catalogVariant,
    classNameContainer,
    showDiscount,
    isMultiOffer,
}) => {
    const { trans } = useI18n();
    const isPromotion = !R.isNil(offer?.promotion) || offer.engagedPriceDiscount > 0;
    const assembly = useSelector(currentAssemblySelector);
    const engagedPricePromotionEnabled = FeaturesService.isFeatureActive(
        FEATURES.ENGAGED_PRICE_PROMOTION,
        assembly
    );

    const referenceOfferPrice = !R.isNil(offer?.promotion)
        ? offer.promotion.originalOfferPrice
        : offer.engagedpricethreshold;

    const engagedPriceTooltip = (
        <Popover
            className="pi-product-card-tag-tooltip"
            pointer={'bottom'}
            customOffset={'-36px 2px'}
            overlay={
                <Tooltip pointerPosition={2}>
                    <Text
                        family="inter"
                        weight={400}
                        size="12px"
                        lineHeight="20px"
                        color="grey8"
                        dangerouslySetInnerHTML={{
                            __html: trans('offer.engaged_price.tooltip'),
                        }}
                    />
                </Tooltip>
            }
        >
            <InlineSVG src={helpIcon} raw className="svgIcon helpIcon" width="20" height="20" />
        </Popover>
    );

    return (
        <div
            className={classnames('productCard-prices', classNameContainer, {
                'productCard-prices--column-reverse': displayColumnReverseOnMobile,
            })}
        >
            {showDiscount && (
                <div className="productCard-discount-container">
                    {((offer.promotion &&
                        offer.promotion.originalOfferPrice.amount > offer.price.amount) ||
                        offer.bulkDiscount ||
                        (engagedPricePromotionEnabled &&
                            offer.isengagedprice &&
                            offer.engagedPriceDiscount > 0)) && (
                        <Discount
                            className="productCard-discount"
                            offer={offer}
                            mode={MODE_PARENTHESIS}
                        />
                    )}
                    {engagedPricePromotionEnabled &&
                        !isMultiOffer &&
                        offer.isengagedprice &&
                        engagedPriceTooltip}
                </div>
            )}
            {isPromotion && !hiddenOriginalOfferOnMobile && (
                <Price
                    price={referenceOfferPrice}
                    className={classnames('productCard-price u-lineThrough', {
                        'u-mr-1': catalogVariant,
                        'productCard-original': isPromotion,
                    })}
                />
            )}
            <Price
                price={offer.price}
                className={classnames('productCard-price', className, {
                    'productCard-promotion': isPromotion,
                    'u-mr-2': reverseOnMobile,
                    'productCard-price--order-first': reverseOnMobile,
                })}
            />
        </div>
    );
};

PriceAndPromotion.propTypes = {
    offer: PropTypes.object.isRequired,
    className: PropTypes.string,
    hiddenOriginalOfferOnMobile: PropTypes.bool,
    displayColumnReverseOnMobile: PropTypes.bool,
    reverseOnMobile: PropTypes.bool,
    catalogVariant: PropTypes.bool,
    classNameContainer: PropTypes.string,
    showDiscount: PropTypes.bool,
    isMultiOffer: PropTypes.bool,
};

export default PriceAndPromotion;
