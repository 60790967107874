import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import Utils from 'modules/utils';
const { filerPhoto } = Utils;
import classNames from 'classnames';
import { connect } from 'react-redux';
import { toggle as togglePanel } from 'modules/visiblePanel';
import Caret from './Caret.jsx';
import ContextPanel from './ContextPanel.jsx';
import StructuredSidePanel from 'components/StructuredSidePanel.jsx';

export class ContextDropdown extends React.Component {
    static propTypes = {
        context: PropTypes.object,
        visiblePanel: PropTypes.string,
        userAssemblies: PropTypes.array,
        hostAssemblies: PropTypes.array,
        currentUser: PropTypes.object,
        farm: PropTypes.object,
        currentAssembly: PropTypes.object,
        trans: PropTypes.func.isRequired,
        togglePanel: PropTypes.func.isRequired,
    };

    getCityName = () => {
        return this.props.currentAssembly && this.props.currentAssembly.place
            ? this.props.currentAssembly.place.address.city.name
            : '';
    };

    getAssemblyName = () => {
        return this.props.currentAssembly && this.props.currentAssembly.name
            ? this.props.currentAssembly.name
            : this.props.trans('hives.newHiveName');
    };

    isActive = () => {
        return this.props.visiblePanel === 'context';
    };

    isHostPendingOrInit = () => {
        return this.props.context.type === 'questionnaire';
    };

    hasPendingLeaderProfile = () => {
        return (
            this.props.currentUser.leaderProfile &&
            this.props.currentUser.leaderProfile.status !== 'validated' &&
            this.props.currentUser.leaderProfile.status !== 'refused'
        );
    };

    showContextPanel = e => {
        e.preventDefault();
        this.props.togglePanel('context');
    };

    renderDropdownTrigger = () => {
        // existing assembly
        if (this.getCityName()) {
            return (
                <div>
                    <span className="contextDropdown-assemblyCity">{this.getCityName()}</span>
                    <span className="contextDropdown-assemblyName">{this.getAssemblyName()}</span>
                </div>
            );
        }

        // pending host
        if (this.isHostPendingOrInit()) {
            return (
                <span className="contextDropdown-assemblyPendingName">
                    {this.getAssemblyName()}
                </span>
            );
        }

        // farm
        const farm = this.props.farm;
        return (
            <div>
                <img
                    className="contextDropdown-farmPicture"
                    height="30"
                    width="30"
                    src={filerPhoto(farm && farm.photos && farm.photos[0], 'small', 'user')}
                />
                <span className="contextDropdown-farmName">{farm && farm.name}</span>
            </div>
        );
    };

    render() {
        const { userAssemblies, hostAssemblies, farm, trans } = this.props;

        return (
            <div className="topNavigation-panelContainer">
                <a
                    onClick={this.showContextPanel}
                    className={classNames('contextDropdown ignore-react-onclickoutside', {
                        'is-active': this.isActive(),
                    })}
                >
                    {this.renderDropdownTrigger()}
                    <Caret />
                </a>
                <StructuredSidePanel
                    isOpened={this.isActive()}
                    variant="left"
                    title={trans('navigation.context.assemblies_as_host_new')}
                    isNewNavigationLayout
                >
                    <ContextPanel
                        userAssemblies={userAssemblies}
                        hostAssemblies={hostAssemblies}
                        farm={farm}
                        hasPendingLeaderProfile={this.hasPendingLeaderProfile()}
                    />
                </StructuredSidePanel>
            </div>
        );
    }
}

export default connect(undefined, {
    togglePanel,
})(connectToI18n(ContextDropdown));
