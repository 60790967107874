/* eslint no-use-before-define: 0 */

export const BASKET_PANEL = 'basket';
export const HELP_PANEL = 'help';
export const USER_PANEL = 'user';
export const DELIVERY_PANEL = 'delivery';
export const CONTEXT_PANEL = 'context';
export const NEWSFEED_PANEL = 'newsfeed';
export const SEARCH_PANEL = 'search';

const SHOW_VISIBLE_PANEL = 'panels/SHOW_VISIBLE_PANEL';
const TOGGLE_VISIBLE_PANEL = 'panels/TOGGLE_VISIBLE_PANEL';
const CLOSE_ALL_PANELS = 'panels/CLOSE_ALL_PANELS';

export default function reducer(state = null, { payload, type }) {
    switch (type) {
        case SHOW_VISIBLE_PANEL:
            return payload;

        case TOGGLE_VISIBLE_PANEL:
            if (state === payload) {
                return null;
            }
            return payload;

        case CLOSE_ALL_PANELS:
            return null;

        default:
            return state;
    }
}

export const toggle = name => ({
    type: TOGGLE_VISIBLE_PANEL,
    payload: name,
});

export const closeAll = () => ({
    type: CLOSE_ALL_PANELS,
});

export const showImmediately = name => ({
    type: SHOW_VISIBLE_PANEL,
    payload: name,
});

let timeout;

export const show = (name, displayDuration) => (dispatch, getState) => {
    const state = getState();
    if (getVisiblePanel(state) === BASKET_PANEL) {
        return;
    }

    dispatch({
        type: SHOW_VISIBLE_PANEL,
        payload: name,
    });

    window.clearTimeout(timeout);
    timeout = window.setTimeout(() => {
        if (getState().visiblePanel === name) {
            dispatch(closeAll());
        }
    }, displayDuration);
};

export const getVisiblePanel = state => state.visiblePanel;
