import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Portal } from 'react-portal';
import FocusTrap from 'focus-trap-react';
import * as Backdrop from 'modules/utils/backdrop';
import InlineSVG from 'svg-inline-react';

import Heading from 'components/Heading.jsx';
import CloseSvg from 'app/assets/new-design/images/icons/close-with-margin.svg';
import delayUnmounting from 'hoc/delayUnmounting.jsx';

const ESCAPE_KEYCODE = 27;

/**
 * This component is and uses the "stateless" style of the Portal library
 * Which means the closeModal prop MUST switch isMounted to false
 */
const Modal = ({
    isBlocking,
    closeModal,
    children,
    footer,
    noMargin,
    className,
    title,
    isMounted,
    withTransitions,
    withFixedHeight,
    size,
    transparentBackdrop,
}) => {
    const [shouldBeShown, setShouldBeShown] = useState(false);
    const [contentIsOverflowing, setContentIsOverflowing] = useState(false);
    const contentRef = useRef();
    const childrenRef = useRef();

    const onClickInside = useCallback(e => {
        e.stopPropagation();
    }, []);

    const handleKeydown = useCallback(
        e => {
            if (e.keyCode === ESCAPE_KEYCODE) {
                closeModal && closeModal();
            }
        },
        [closeModal]
    );

    const handleCloseModal = useCallback(
        e => {
            if (isBlocking) {
                return;
            }

            e.stopPropagation();
            closeModal && closeModal(e);
        },
        [isBlocking, closeModal]
    );
    const onResize = useCallback(() => {
        const computedContent = window.getComputedStyle(contentRef.current, null);
        const contentHeight =
            contentRef.current.clientHeight -
            (parseInt(computedContent.paddingTop, 10) +
                parseInt(computedContent.paddingBottom, 10));
        setContentIsOverflowing(contentHeight < childrenRef.current.offsetHeight);
    }, []);
    useEffect(() => {
        document.body.classList.add('modal-open');
        onResize();
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [onResize]);

    useEffect(() => {
        setShouldBeShown(isMounted);
    }, [isMounted]);

    useEffect(() => {
        if (!isBlocking) {
            document.addEventListener('keydown', handleKeydown);
        }
        return () => {
            Backdrop.hide();
            if (!isBlocking) {
                document.removeEventListener('keydown', handleKeydown);
            }
        };
    }, [handleKeydown, isBlocking]);

    const a11yProps = useMemo(() => {
        const obj = {
            role: 'dialog',
            ['aria-modal']: true,
        };
        if (title) {
            obj['aria-labelledby'] = 'modal-title';
        }
        return obj;
    }, [title]);

    return (
        <Portal>
            <div
                onClick={handleCloseModal}
                className={classnames('nd-modal', {
                    show: shouldBeShown,
                    '--with-transitions': withTransitions,
                    '--with-fixed-height': withFixedHeight,
                })}
            >
                <div
                    className={classnames('nd-modal-backdrop', {
                        'nd-modal-backdrop--transparent': transparentBackdrop,
                    })}
                ></div>
                <FocusTrap
                    focusTrapOptions={{
                        clickOutsideDeactivates: !isBlocking,
                        fallbackFocus: '.modal-content',
                    }}
                >
                    <div className="nd-modal-layout">
                        <div
                            {...a11yProps}
                            className={classnames(
                                'nd-modal-dialog',
                                {
                                    'nd-modal-sm': size === 'small',
                                    'modal-dialog--noMargin': noMargin,
                                },
                                className
                            )}
                            onClick={onClickInside}
                        >
                            <div className="nd-modal-header">
                                {title && (
                                    <Heading size={2} rank={1} productIdentity id="modal-title">
                                        {title}
                                    </Heading>
                                )}
                                {!isBlocking && (
                                    <button
                                        className="nd-modal-close-btn"
                                        onClick={handleCloseModal}
                                    >
                                        <InlineSVG raw src={CloseSvg} />
                                    </button>
                                )}
                            </div>
                            <div
                                ref={contentRef}
                                className={classnames('nd-modal-content', {
                                    'nd-modal-content--overflowing': contentIsOverflowing,
                                })}
                            >
                                <div ref={childrenRef}>
                                    {React.cloneElement(children, {
                                        closeModal: handleCloseModal,
                                        ref: childrenRef,
                                    })}
                                </div>
                            </div>
                            {footer && (
                                <div
                                    className={classnames('nd-modal-footer', {
                                        'nd-modal-footer--border': contentIsOverflowing,
                                    })}
                                >
                                    {footer}
                                </div>
                            )}
                        </div>
                    </div>
                </FocusTrap>
            </div>
        </Portal>
    );
};

Modal.propTypes = {
    children: PropTypes.node,
    title: PropTypes.node,
    className: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    transparentBackdrop: PropTypes.bool,
    isBlocking: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'x-large']),
    noPadding: PropTypes.bool,
    noMargin: PropTypes.bool,
    isMounted: PropTypes.bool.isRequired,
    withTransitions: PropTypes.bool,
    withFixedHeight: PropTypes.bool,
    footer: PropTypes.element,
};
Modal.defaultProps = {
    withTransitions: false,
    withFixedHeight: false,
    size: 'medium',
    transparentBackdrop: false,
};

export default delayUnmounting(Modal);
