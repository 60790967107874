import configureStore from '../store/configureStore';
import reducerRegistry from '../reducers/ReducerRegistry';
import coreReducers from '../reducers/coreReducers';

export const getDefaultStore = locale => {
    reducerRegistry.register(coreReducers);
    const { store } = configureStore(reducerRegistry)({
        locale,
    });

    return store;
};
