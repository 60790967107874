import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Utils from 'modules/utils';
import { getUniqLabelsList } from 'models/products';
import PIText from 'components/ProductIdentity/Text.jsx';
import ProductLabel from 'components/Product/ProductLabel.jsx';
import Tag from 'src/components/atoms/Tag/Tag.jsx';

const { filerPhoto } = Utils;

const getProductSrc = (photoId, productCategoryId) => {
    if (!photoId) {
        const categoryId = productCategoryId || 'default';
        return `/assets/images/products/pi/product-${categoryId}.png`;
    }
    return filerPhoto(photoId, 'medium', 'product');
};

const ProductCardImage = React.memo(props => {
    const {
        product: { name, photoId, type },
        product,
        isLabelDescriptionAvailable,
        tag,
    } = props;

    const defaultSrc = useMemo(() => getProductSrc(photoId, type.root_id), [photoId, type]);
    const { label: tagLabel, type: tagType } = tag;

    const labelList = getUniqLabelsList(product);

    return (
        <>
            <div className="pi-product-card-image">
                <img src={defaultSrc} alt={name} />
            </div>
            <div>
                {labelList.slice(0, 2).map((label, index) => {
                    if (labelList.length > 2 && index >= 1) {
                        return (
                            <ProductLabel
                                className="pi-product-card-picto"
                                key="product-label-count"
                                count={product.labels.length - 1}
                            />
                        );
                    }
                    return (
                        <ProductLabel
                            className="pi-product-card-picto"
                            key={label.id}
                            name={label.name}
                            description={isLabelDescriptionAvailable ? label.description : null}
                            photoId={label.photoId}
                        />
                    );
                })}
            </div>
            {tagLabel && (
                <Tag
                    className={classNames('pi-product-card-tag', tagType)}
                    readOnly
                    label={
                        <PIText family="inter" size="12px">
                            {tagLabel}
                        </PIText>
                    }
                ></Tag>
            )}
        </>
    );
});
ProductCardImage.propTypes = {
    product: PropTypes.shape({
        name: PropTypes.string,
        labels: PropTypes.array,
        photoId: PropTypes.string,
    }),
    isLabelDescriptionAvailable: PropTypes.bool,
    tag: PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string,
    }),
};
ProductCardImage.defaultProps = {
    tag: {},
};
export default ProductCardImage;
