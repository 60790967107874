import URI from 'URIjs';
import {
    isOnAssemblyHomepage,
    isOnBasketPage,
    isOnBecameHost,
    isOnBecameProducer,
    isOnCheckoutAccountPage,
    isOnDeliveryPage,
    isOnMap,
    isOnMyAccountPage,
    isOnSalePage,
    isOnGiftCardPage,
} from 'models/pageContext';
import { isLeaderOfAssembly, isProvidingAssembly } from 'models/users';

const isOnRebrandedPage = () => {
    const pathname = new URI().pathname();

    return (
        isOnSalePage(pathname) ||
        isOnDeliveryPage(pathname) ||
        isOnBasketPage(pathname) ||
        isOnCheckoutAccountPage(pathname) ||
        isOnAssemblyHomepage(pathname) ||
        isOnMyAccountPage(pathname) ||
        isOnBecameHost(pathname) ||
        isOnBecameProducer(pathname) ||
        isOnMap(pathname) ||
        isOnGiftCardPage(pathname)
    );
};

const currentUserManageOrProvideCurrentHive = currentUser => {
    const pathname = new URI().pathname();
    const currentAssemblyId = parseInt(pathname.match(/assemblies\/(\d+)\/?$/i)[1], 10);
    const isLeaderOfCurrentAssembly = isLeaderOfAssembly({
        assemblyId: currentAssemblyId,
        user: currentUser,
    });
    const isProvidingCurrentAssembly = isProvidingAssembly(currentAssemblyId, currentUser);

    return isLeaderOfCurrentAssembly || isProvidingCurrentAssembly;
};

export const shouldDeployProductIdentity = (currentUser, contextType) => {
    const pathname = new URI().pathname();

    if (
        isOnSalePage(pathname) ||
        isOnBasketPage(pathname) ||
        isOnDeliveryPage(pathname) ||
        isOnMyAccountPage(pathname)
    )
        return true;
    if (![undefined, null, 'member', 'profile'].includes(contextType)) return false;
    if (!isOnRebrandedPage()) return false;

    return !(isOnAssemblyHomepage(pathname) && currentUserManageOrProvideCurrentHive(currentUser));
};

export const deployOrRemoveProductIdentity = (currentUser, contextType) => {
    if (!shouldDeployProductIdentity(currentUser, contextType)) {
        document.body.classList.remove('productIdentity');
        return;
    }

    document.body.classList.add('productIdentity');
};
