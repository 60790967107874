import Backbone from 'backbone';
import _ from 'underscore';
import Cookies from 'js-cookie';

const originalSync = Backbone.sync;

let _userImpersonated = Cookies.get('impersonate');

const Impersonate = {
    clear() {
        _userImpersonated = null;
        Cookies.remove('impersonate');
    },

    set(_username) {
        let username;
        try {
            username = decodeURIComponent(_username);
        } catch (e) {
            username = _username;
        }
        Impersonate.clear();
        Cookies.set('impersonate', username);
        _userImpersonated = username;
    },

    getHeader() {
        if (!_userImpersonated) {
            return {};
        }

        return {
            'rest-impersonate-user': encodeURIComponent(_userImpersonated),
        };
    },

    getParameter() {
        if (!_userImpersonated) {
            return {};
        }

        return {
            'rest-impersonate-user': _userImpersonated,
        };
    },

    getImpersonated() {
        return _userImpersonated;
    },
};

Backbone.sync = function impersonateSync(method, model, options) {
    const noAuthorizationHeader =
        model.noAuthorizationHeader || options.noAuthorizationHeader || false;

    // no impersonated
    if (_userImpersonated === null || noAuthorizationHeader) {
        return originalSync.call(model, method, model, options);
    }

    if (!noAuthorizationHeader) {
        options.headers = options.headers || {};
        _.extend(options.headers, Impersonate.getHeader());
    }
    return originalSync.call(model, method, model, options);
};

export default Impersonate;

export const getUserEmailFromUsernameRouter = userName =>
    /\?/.test(userName) ? userName.split('?')[0] : userName;
