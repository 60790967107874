import _ from 'underscore';

export default function(Backbone) {
    const _sync = Backbone.sync;

    Backbone.sync = function(method, entity, options = {}) {
        _.defaults(options, {
            beforeSend: function() {
                this.trigger('sync:start', this);
            }.bind(entity),
            complete: function() {
                this.trigger('sync:stop', this);
            }.bind(entity),
        });

        const sync = _sync(method, entity, options);

        if (method === 'read') {
            entity._fetch = sync;
        }

        return sync;
    };
}
