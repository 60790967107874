/* eslint-disable react/prop-types */
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import App from 'onSiteSales/App.jsx';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import Backbone from 'backbone';
import UserFarmIdProvider from 'components/UserFarmIdProvider.jsx';
import RoutePageView from 'components/RoutePageView.jsx';
import { ProductsProvider } from 'components/FarmProducts/ProductsContext.jsx';

const ProducerFolderContainer = lazy(() =>
    import('components/ProducerFolder/ProducerFolderContainer.jsx')
);
const ProducerPresentationContainer = lazy(() =>
    import('components/ProducerPresentation/ProducerPresentationContainer.jsx')
);
const ProducerProfessionsContainer = lazy(() =>
    import('components/ProducerProfessions/ProducerProfessionsContainer.jsx')
);
const ProducerPartnersContainer = lazy(() =>
    import('components/FarmProfile/Partners/ProducerPartnersContainer.jsx')
);
const ListAssembliesContainer = lazy(() =>
    import('components/ListAssemblies/ListAssembliesContainer.jsx')
);
const FindAssembliesContainer = lazy(() =>
    import('components/ProducerAssemblies/FindAssembliesContainer.jsx')
);
const AssembliesInvitationContainer = lazy(() =>
    import('components/ProducerAssemblies/AssembliesInvitationContainer.jsx')
);
const ProducerPlanningContainer = lazy(() =>
    import('components/ProducerDistributions/Planning/ProducerPlanningContainer.jsx')
);
const FollowUpContainer = lazy(() =>
    import('components/ProducerDistributions/FollowUp/ProducerDistributionsFollowUpContainer.jsx')
);
const DistributionPage = lazy(() =>
    import('components/ProducerDistributions/SingleDistribution/DistributionPage.jsx')
);
const ProductContainer = lazy(() =>
    import('components/FarmProducts/AvailabilityAndStock/ProductContainer.jsx')
);
const CreateProductContainer = lazy(() =>
    import('components/ProductCreation/CreateProductContainer.jsx')
);
const EditProductContainer = lazy(() =>
    import('components/ProductCreation/EditProductContainer.jsx')
);
const ImportProductsContainer = lazy(() =>
    import('components/ImportProducts/ImportProductsContainer.jsx')
);
const FinancesContainer = lazy(() =>
    import('components/ProducerDistributions/Finances/FinancesContainer.jsx')
);
const LabelsContainer = lazy(() => import('components/Documents/LabelsContainer.jsx'));
const ProducerNewsfeedContainer = lazy(() =>
    import('components/ProducerNewsfeed/ProducerNewsfeedContainer.jsx')
);
const FarmProfileContainer = lazy(() => import('components/FarmProfile/FarmProfileContainer.jsx'));
const FarmProfileMapContainer = lazy(() =>
    import('components/FarmProfile/FarmProfileMapContainer.jsx')
);
const FarmProfileProductsContainer = lazy(() =>
    import('components/FarmProfile/FarmProfileProductsContainer.jsx')
);
const ProducerHistoryContainer = lazy(() =>
    import('components/ProducerHistory/ProducerHistoryContainer.jsx')
);
const FarmProfileAssembliesContainer = lazy(() =>
    import('components/FarmProfile/FarmProfileAssembliesContainer.jsx')
);
const ProducerDashboardStatsContainer = lazy(() =>
    import('components/ProducerDashboard/ProducerDashboardStatsContainer.jsx')
);
const ProducerDashboardProductsContainer = lazy(() =>
    import('components/ProducerDashboard/ProducerDashboardProductsContainer.jsx')
);
const ProducerDashboardFeedbacksContainer = lazy(() =>
    import('components/ProducerDashboard/ProducerDashboardFeedbacksContainer.jsx')
);
const AccountingContainer = lazy(() =>
    import('components/ProducerDistributions/Accounting/AccountingContainer.jsx')
);
const DownloadMultiplePdf = lazy(() =>
    import('components/ProducerDistributions/DownloadMultiplePdf.jsx')
);
const ProductFusionModuleContainer = lazy(() =>
    import('components/ProductFusion/ModuleContainer.jsx')
);

const FarmInvoicesPage = lazy(() => import('src/components/pages/FarmInvoices/FarmInvoices.jsx'));

export const producerRoutes = [
    {
        definition: '/producers/folder:searchQuery?',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.farm.farmFolder',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ProducerFolderContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/presentation',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.farm.farmFolder',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ProducerPresentationContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/professions',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.farm.farmFolder',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ProducerProfessionsContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/partners',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.farm.farmFolder',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ProducerPartnersContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/assemblies',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.hives',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ListAssembliesContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/assemblies/find',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.hives',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <FindAssembliesContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/assemblies/invitations',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.hives',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <AssembliesInvitationContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/calendar',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.collections',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ProducerPlanningContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/collections',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.collections',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <FollowUpContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/collections/:distributionId([0-9]+)/follow/:tab?',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.collections',
        context: 'farm',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <DistributionPage
                    follow
                    distributionId={parseInt(match.params.distributionId, 10)}
                    tab={match.params.tab || 'products'}
                />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/collections/:distributionId([0-9]+)/prepare/:tab?',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.collections',
        context: 'farm',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <DistributionPage
                    prepare
                    distributionId={parseInt(match.params.distributionId, 10)}
                    tab={match.params.tab || 'products'}
                />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/products',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.catalog',
        context: 'farm',
        exact: true,
        render: () => {
            return Backbone.history.navigate('producers/me/products/stocks', {
                trigger: true,
                replace: true,
            });
        },
    },
    {
        definition: '/producers/me/products/stocks',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.catalog',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ProductsProvider>
                    <ProductContainer scope="local" />
                </ProductsProvider>
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/interregional-products',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.interregionalCatalog',
        context: 'farm',
        exact: true,
        render: () => {
            return Backbone.history.navigate('producers/me/interregional-products/stocks', {
                trigger: true,
                replace: true,
            });
        },
    },
    {
        definition: '/producers/me/interregional-products/stocks',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.interregionalCatalog',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ProductsProvider>
                    <ProductContainer scope="interregional" />
                </ProductsProvider>
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/products/create',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.catalog',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <CreateProductContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/products/:productId/new-edit',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.catalog',
        context: 'farm',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <EditProductContainer
                    productId={parseInt(match.params.productId, 10)}
                    scope="local"
                />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/interregional-products/:productId/new-edit',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.interregionalCatalog',
        context: 'farm',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <EditProductContainer
                    productId={parseInt(match.params.productId, 10)}
                    scope="interregional"
                />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/interregional-products/import',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.interregionalCatalog',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ImportProductsContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/collections/financial',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.collections',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <FinancesContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/companies/:companyId/labels',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.farm.farmFolder',
        context: 'farm',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <LabelsContainer companyId={parseInt(match.params.companyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/newsfeed',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.newsfeed',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ProducerNewsfeedContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/profile',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.farm.farmFolder',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <UserFarmIdProvider>
                    {({ farmId }) => <FarmProfileContainer farmId={farmId} userIsOwnerOfFarm />}
                </UserFarmIdProvider>
            </Suspense>
        ),
    },
    {
        definition: '/producers/profile/map',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.farm.farmFolder',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <UserFarmIdProvider>
                    {({ farmId }) => <FarmProfileMapContainer farmId={farmId} userIsOwnerOfFarm />}
                </UserFarmIdProvider>
            </Suspense>
        ),
    },
    {
        definition: '/producers/profile/products',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.farm.farmFolder',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <FarmProfileProductsContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/collections/history',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.collections',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ProducerHistoryContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/profile/assemblies',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.farm.farmFolder',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <UserFarmIdProvider>
                    {({ farmId }) => (
                        <FarmProfileAssembliesContainer farmId={farmId} userIsOwnerOfFarm />
                    )}
                </UserFarmIdProvider>
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/dashboard/stats',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.statistics',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ProducerDashboardStatsContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/dashboard/products',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.statistics',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ProducerDashboardProductsContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/dashboard/feedbacks',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.statistics',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ProducerDashboardFeedbacksContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/onsite-sales',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: null,
        context: 'farm',
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <Router basename={`${window.language}/producers/onsite-sales`}>
                    <RoutePageView />
                    <App />
                </Router>
            </Suspense>
        ),
    },
    {
        definition: '/producers/accounting',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.accounting',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <AccountingContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/accounting/spreadsheets',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.accounting',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <FinancesContainer />
            </Suspense>
        ),
    },
    {
        definition: '/producers/accounting/invoices',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'navigation.left.accounting',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <FarmInvoicesPage />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/downloadDocument/:fileName',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: null,
        context: 'farm',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <DownloadMultiplePdf fileName={match.params.fileName} />
            </Suspense>
        ),
    },
    {
        definition: '/producers/me/products/product-fusion',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.catalog',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ProductFusionModuleContainer />
            </Suspense>
        ),
    },
];
