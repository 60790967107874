import { useEffect, useState } from 'react';

const useResponsive = (maxWidth = 767) => {
    const [isSmallWidth, setIsSmallWidth] = useState(null);
    useEffect(() => {
        if (!window || !window.matchMedia) {
            setIsSmallWidth(true);
            return;
        }

        const smallWidthQuery = window.matchMedia(`(max-width: ${maxWidth}px)`);
        const handleMediaQueryChange = () => {
            const newIsSmallWidth = smallWidthQuery.matches;
            if (isSmallWidth !== newIsSmallWidth) {
                setIsSmallWidth(newIsSmallWidth);
            }
        };
        smallWidthQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange();
        // eslint-disable-next-line consistent-return
        return () => {
            smallWidthQuery.removeListener(handleMediaQueryChange);
        };
    }, [setIsSmallWidth, isSmallWidth, maxWidth]);

    return isSmallWidth;
};

export default useResponsive;
