import React, { useState, useCallback } from 'react';
import useI18n from 'hooks/I18n/useI18n';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getLocalizedDate } from 'modules/utils/dateAndTime';
import Utils from 'modules/utils';

import Text from 'components/ProductIdentity/Text.jsx';
import Button, { LINK_MODE } from 'src/components/atoms/Button/Button.jsx';
import Tag from 'src/components/atoms/Tag/Tag.jsx';
import Modal from 'src/components/organisms/Modal/Modal.jsx';
import SVGinline from 'svg-inline-react';

import HeartSolidIcon from 'app/assets/new-design/images/icons/heart-solid.svg';

const { filerPhoto } = Utils;

export const TYPE_REVIEW = 'review';
export const TYPE_RESPONSE = 'response';

export const ProductFoodFeedbackCard = ({ classNames, feedback, typeFeedback }) => {
    const { i18n, trans } = useI18n();
    const [isOpen, setIsOpen] = useState(false);
    const [modalTypeFeedback, setModalTypeFeedback] = useState('');
    const [isTextOverflowing, setTextOverflowing] = useState(false);
    const showModal = type => {
        setModalTypeFeedback(type);
        setIsOpen(true);
    };

    const commentRef = useCallback(node => {
        if (node !== null) {
            setTextOverflowing(node.offsetHeight < node.scrollHeight);
        }
    }, []);

    return (
        <div className={classnames('food-feedback-card', classNames, typeFeedback)}>
            <div className="food-feedback-card-header">
                <div className="food-feedback-card-author-container">
                    {TYPE_RESPONSE === typeFeedback && (
                        <img
                            className="food-feedback-card-author-picture"
                            src={filerPhoto(feedback.producerPhoto, 'small', 'user')}
                        />
                    )}
                    <div className="food-feedback-card-author-info">
                        <div className="food-feedback-card-author-name">
                            <Text
                                family="inter"
                                size="16px"
                                lineHeight="24px"
                                weight={600}
                                color="gray2"
                            >
                                {TYPE_REVIEW === typeFeedback
                                    ? feedback.memberName
                                    : feedback.producerName}
                            </Text>
                        </div>
                        <div className="food-feedback-card-author-since">
                            <Text
                                family="inter"
                                size="12px"
                                lineHeight="12px"
                                weight={400}
                                color="gray9"
                            >
                                {TYPE_REVIEW === typeFeedback
                                    ? `${trans('products.productPage.feedback.memberSince')} ${
                                          feedback.memberSince
                                      }`
                                    : trans('user.roles.producer')}
                            </Text>
                        </div>
                    </div>
                </div>
                <div className="food-feedback-card-rating-container">
                    {TYPE_REVIEW === typeFeedback && (
                        <div className="food-feedback-card-rating">
                            <Tag
                                readOnly
                                className={`feedback-${feedback.rating} outline`}
                                label={
                                    <>
                                        {feedback.rating === 'heart' && (
                                            <SVGinline raw src={HeartSolidIcon}></SVGinline>
                                        )}
                                        <Text family="inter" size="12px">
                                            {trans(
                                                `products.productPage.feedback.${feedback.rating}`
                                            )}
                                        </Text>
                                    </>
                                }
                            ></Tag>
                        </div>
                    )}
                    <div className="food-feedback-card-rating-at">
                        <Text
                            family="inter"
                            size="12px"
                            lineHeight="12px"
                            weight={400}
                            color="gray9"
                        >
                            {getLocalizedDate(
                                i18n,
                                'd',
                                TYPE_REVIEW === typeFeedback
                                    ? feedback.commentedAt
                                    : feedback.respondedAt
                            )}
                        </Text>
                    </div>
                </div>
            </div>
            <div className="food-feedback-card-comment">
                <Text
                    ref={commentRef}
                    family="inter"
                    size="16px"
                    lineHeight="24px"
                    weight={400}
                    color="gray2"
                    dangerouslySetInnerHTML={{
                        __html: TYPE_REVIEW === typeFeedback ? feedback.comment : feedback.response,
                    }}
                />
            </div>
            {TYPE_RESPONSE !== typeFeedback && (
                <div className="food-feedback-card-footer">
                    <div className="food-feedback-card-response">
                        {feedback.response && (
                            <Button mode={LINK_MODE} onClick={() => showModal(TYPE_RESPONSE)}>
                                <Text
                                    size="14px"
                                    lineHeight="20px"
                                    family="inter"
                                    color="turquoise2"
                                >
                                    {trans('products.productPage.feedback.seeProducerResponse')}
                                </Text>
                            </Button>
                        )}
                    </div>
                    <div className="food-feedback-card-read-more">
                        {isTextOverflowing && (
                            <Button mode={LINK_MODE} onClick={() => showModal(TYPE_REVIEW)}>
                                <Text
                                    size="14px"
                                    lineHeight="20px"
                                    family="inter"
                                    color="turquoise2"
                                >
                                    {trans('products.productPage.feedback.seeAllReview')}
                                </Text>
                            </Button>
                        )}
                    </div>
                </div>
            )}
            {isOpen && (
                <Modal
                    className="food-feedback-modal"
                    closeModal={() => setIsOpen(false)}
                    title={
                        TYPE_REVIEW === typeFeedback
                            ? trans('products.productPage.feedback.modal.titleReview')
                            : trans('products.productPage.feedback.modal.titleResponse')
                    }
                >
                    <ProductFoodFeedbackCard feedback={feedback} typeFeedback={modalTypeFeedback} />
                </Modal>
            )}
        </div>
    );
};

ProductFoodFeedbackCard.propTypes = {
    classNames: PropTypes.string,
    feedback: PropTypes.shape({
        id: PropTypes.number.isRequired,
        rating: PropTypes.string.isRequired,
        comment: PropTypes.string.isRequired,
        commentedAt: PropTypes.string.isRequired,
        memberName: PropTypes.string.isRequired,
        memberSince: PropTypes.string.isRequired,
        response: PropTypes.string,
        respondedAt: PropTypes.string,
        producerName: PropTypes.string,
        producerPhoto: PropTypes.string,
    }),
    typeFeedback: PropTypes.string.isRequired,
};

export default ProductFoodFeedbackCard;
