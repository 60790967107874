import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const delayUnmounting = Component => {
    const WrappedComponent = ({ isMounted, delayTime, ...props }) => {
        const [shouldRender, setShouldRender] = useState(isMounted);

        useEffect(() => {
            if (!isMounted) {
                setTimeout(() => setShouldRender(false), delayTime);

                return;
            }

            setShouldRender(true);
        }, [isMounted, delayTime]);

        return shouldRender ? <Component isMounted={isMounted} {...props} /> : null;
    };

    WrappedComponent.propTypes = {
        isMounted: PropTypes.bool,
        delayTime: PropTypes.number,
    };
    WrappedComponent.defaultProps = {
        isMounted: true,
        delayTime: 0,
    };

    return WrappedComponent;
};

delayUnmounting.propTypes = {
    Component: PropTypes.element.isRequired,
};

export default delayUnmounting;
