import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export class List extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
    };

    render() {
        return <ul className={classnames('list', this.props.className)}>{this.props.children}</ul>;
    }
}

export default List;
