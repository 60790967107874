import * as Translator from './Translator';

function createTranslator(translations = {}, locale = 'en') {
    Translator.addTranslations(locale, translations);

    return {
        trans: Translator.trans.bind(null, locale),
        transChoice: Translator.transChoice.bind(null, locale),
        transOrNull: Translator.transOrNull.bind(null, locale),
    };
}

const addTranslations = Translator.addTranslations;

export { Translator, addTranslations, createTranslator };
