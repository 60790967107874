import { captureException } from 'modules/error-reporting';

export const ASSEMBLY_DATA_LOADED = 'ASSEMBLY_DATA_LOADED';
export const ASSEMBLY_DATA_CHANGED = 'ASSEMBLY_DATA_CHANGED';
export const ALL_DATA_LOADED = 'ALL_DATA_LOADED';
export const OPEN_PLACE_MODAL_BTN_CLICKED = 'OPEN_PLACE_MODAL_BTN_CLICKED';
export const CLOSE_PLACE_MODAL_BTN_CLICKED = 'CLOSE_PLACE_MODAL_BTN_CLICKED';
export const NEW_COMMENT_HAS_BEEN_POSTED = 'NEW_COMMENT_HAS_BEEN_POSTED';
export const COMMENT_HAS_BEEN_DELETED = 'COMMENT_HAS_BEEN_DELETED';
export const NEW_DISCUSSION_HAS_BEEN_POSTED = 'NEW_DISCUSSION_HAS_BEEN_POSTED';
export const DISCUSSION_HAS_BEEN_DELETED = 'DISCUSSION_HAS_BEEN_DELETED';
export const NEW_DISCUSSIONS_HAVE_BEEN_LOADED = 'NEW_DISCUSSIONS_HAVE_BEEN_LOADED';

export const getInitialState = preloadedAssembly => {
    return {
        dataLoaded: false,
        assembly: preloadedAssembly,
        pickups: [],
        distributions: [],
        farmsGallery: [],
        discussions: [],
        nextDiscussionPage: 2,
        canLoadMoreDiscussions: true,
        isPlaceModalOpen: false,
        placeModalEntityType: '',
        placeModalEntity: null,
    };
};

const assemblyHomeReducer = (state, { type, payload }) => {
    switch (type) {
        case ASSEMBLY_DATA_LOADED:
            return {
                ...state,
                assembly: payload,
            };
        case ASSEMBLY_DATA_CHANGED:
            return {
                ...state,
                dataLoaded: false,
                assembly: payload,
            };
        case ALL_DATA_LOADED:
            return {
                ...state,
                dataLoaded: true,
                pickups: payload.pickups,
                distributions: payload.distributions,
                farmsGallery: payload.farmsGallery,
                discussions: payload.discussions?.discussions || [],
                canLoadMoreDiscussions: payload.discussions.total > payload.discussions.count,
                nearbyAssemblies: payload.nearbyAssemblies || [],
            };
        case OPEN_PLACE_MODAL_BTN_CLICKED:
            return {
                ...state,
                isPlaceModalOpen: true,
                placeModalEntityType: payload.entityType,
                placeModalEntity: payload.entity,
            };
        case CLOSE_PLACE_MODAL_BTN_CLICKED:
            return {
                ...state,
                isPlaceModalOpen: false,
            };
        case DISCUSSION_HAS_BEEN_DELETED:
        case COMMENT_HAS_BEEN_DELETED:
        case NEW_COMMENT_HAS_BEEN_POSTED:
            return {
                ...state,
                discussions: payload,
            };
        case NEW_DISCUSSION_HAS_BEEN_POSTED:
            return {
                ...state,
                discussions: [payload, ...state.discussions],
            };
        case NEW_DISCUSSIONS_HAVE_BEEN_LOADED:
            return {
                ...state,
                nextDiscussionPage: state.nextDiscussionPage + 1,
                discussions: [...state.discussions, ...payload.discussions],
                canLoadMoreDiscussions:
                    payload.total > [...state.discussions, ...payload.discussions].length,
            };
        default:
            captureException(new Error(`${type} action not found in AssemblyHomeReducer.`));

            return state;
    }
};

export default assemblyHomeReducer;
