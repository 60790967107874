export const PRODUCT_LIST_PADDING_SMALL = 8;

export const SCREEN_SM = 768; // Same as the $screen-sm SCSS variable
export const SCREEN_MD = 992; // Same as the $screen-md SCSS variable
export const NEW_SALE_LARGE_BREAKPOINT = 1200; // Breakpoint specific to the sale page

export const PRODUCT_CARD_WIDTH_LARGE = 660;
export const PRODUCT_CARD_WIDTH_MEDIUM_AND_EXTRA_LARGE = 720;

export const getProductsListWidth = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth < SCREEN_SM) {
        return windowWidth - PRODUCT_LIST_PADDING_SMALL * 2;
    }
    if (windowWidth < SCREEN_MD) {
        return PRODUCT_CARD_WIDTH_MEDIUM_AND_EXTRA_LARGE;
    }
    if (windowWidth < NEW_SALE_LARGE_BREAKPOINT) {
        return PRODUCT_CARD_WIDTH_LARGE;
    }
    return PRODUCT_CARD_WIDTH_MEDIUM_AND_EXTRA_LARGE;
};
