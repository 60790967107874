import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ERROR_MODE = 'error';
export const WARNING_MODE = 'warning';

export const SMALL_SIZE = 'small';

export const MODES = {
    [ERROR_MODE]: ERROR_MODE,
    [WARNING_MODE]: WARNING_MODE,
};
const Input = forwardRef(
    ({ mode, id, suffix, prefix, error, size, className, autocomplete, ...props }, ref) => {
        const cls = classNames('nd-input', className, MODES[mode], {
            suffix: !!suffix,
            prefix: !!prefix,
            [size]: !!size,
        });
        const inputComponent = (
            <input
                ref={ref}
                className={cls}
                id={id}
                autoComplete={autocomplete ? 'on' : 'off'}
                {...props}
            />
        );
        const errorComponent = error && <div className="nd-input-error-msg">{error}</div>;
        if (suffix || prefix) {
            return (
                <>
                    <div className="nd-input-wrapper">
                        {inputComponent}
                        {prefix && <span className="nd-input-prefix">{prefix}</span>}
                        {suffix && <span className="nd-input-suffix">{suffix}</span>}
                    </div>
                    {errorComponent}
                </>
            );
        }
        return (
            <div>
                {inputComponent}
                {errorComponent}
            </div>
        );
    }
);

Input.propTypes = {
    error: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    mode: PropTypes.oneOf(Object.keys(MODES)),
    size: PropTypes.oneOf([SMALL_SIZE]),
    className: PropTypes.string,
    autocomplete: PropTypes.bool,
};

Input.defaultProps = {
    mode: undefined,
    id: null,
    type: 'text',
    prefix: null,
    suffix: null,
    error: '',
    autocomplete: true,
};
export default Input;
