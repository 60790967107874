import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { debounce } from 'underscore';
import { withRouter } from 'react-router';

import { fetchPickupAndDelivry } from 'modules/checkout/actions.basket';
import { pickupsSelector } from 'modules/checkout';
import * as localstorage from 'modules/localstorage';
import connectToI18n from 'modules/i18n/connectToI18n';
import connectToAnalytics from 'modules/analytics/connectToAnalytics';
import { getLocalizedDate } from 'modules/utils/dateAndTime';

import {
    toggle as togglePanel,
    closeAll as closeAllToggle,
    showImmediately as showPanelImmediately,
    CONTEXT_PANEL,
    HELP_PANEL,
    USER_PANEL,
    DELIVERY_PANEL,
    BASKET_PANEL,
    SEARCH_PANEL,
} from 'modules/visiblePanel';
import { DELIVERY_OFFER, PICKUP } from 'modules/utils/deliveryOptions';
import {
    searchProducts,
    setSearchString,
    getSearchString,
    clearSearch,
} from 'modules/productsSearch';
import { historySelector } from 'modules/routerHistory';
import { userSelector } from 'modules/currentUser';
import { getBasketOfDistribution } from 'modules/orders';

import { getDeliveryOptionInfo } from 'models/delivery';
import { formatFullName } from 'models/users';
import Icon from 'components/Icon.jsx';
import Text from 'components/Text.jsx';
import PIText from 'components/ProductIdentity/Text.jsx';
import AnonymousUserPanel from 'components/Navigation/AnonymousUserPanel.jsx';
import Caret from 'components/Navigation/Caret.jsx';
import DeliveryPanel from 'components/Navigation/DeliveryPanel.jsx';
import PIDeliveryPanel from 'components/Navigation/ProductIdentity/DeliveryPanel.jsx';
import NavigationRegion from 'components/Navigation/NavigationRegion.jsx';
import ResponsiveNavigation from 'components/Navigation/ResponsiveNavigation.jsx';
import UserPanel from 'components/Navigation/UserPanel.jsx';
import StructuredSidePanel from 'components/StructuredSidePanel.jsx';
import ProductSearch from 'components/Navigation/Implementations/ProductSearch.jsx';
import SearchModal from 'components/Navigation/Implementations/SearchModal.jsx';
import PIBasketPanel from 'components/Sale/ProductIdentity/Panels/BasketPanel.jsx';
import SaleSmallScreenNavigation from 'components/Navigation/ProductIdentity/SaleSmallScreenNavigation.jsx';
import ProfilePanel from 'components/Navigation/ProductIdentity/ProfilePanel/ProfilePanel.jsx';
import AnonymousProfilePanel from 'components/Navigation/ProductIdentity/ProfilePanel/AnonymousProfilePanel.jsx';
import AssemblyInfos from 'components/Navigation/ProductIdentity/AssemblyInfos.jsx';
import HelpButton from 'components/Navigation/ProductIdentity/HelpButton.jsx';
import AccountButton from 'components/Navigation/ProductIdentity/AccountButton.jsx';
import { RIGHT_TOOLTIP, TOP_TOOLTIP } from 'src/components/atoms/Tooltip/Tooltip.jsx';
import BasketButton, {
    LIGHT_MODE,
    DARK_MODE,
} from 'components/Navigation/ProductIdentity/BasketButton.jsx';
import AnonymousContextPanel from 'components/Navigation/AnonymousContextPanel.jsx';
import ContextPanel from 'components/Navigation/ContextPanel.jsx';
import HelpPanel from 'components/Navigation/ProductIdentity/HelpPanel.jsx';
import userIcon from 'app/assets/new-design/images/icons/user.svg';
import searchNavIcon from 'app/assets/new-design/images/icons/search.svg';
import logoWithoutText from 'icons/logo-without-text.svg';
import homeIcon from 'app/assets/new-design/images/icons/home.svg';
const currentAssemblyIcon = '/assets/images/icons/icon-assembly.svg';

export class SimpleMemberNavigation extends React.Component {
    static propTypes = {
        currentAssembly: PropTypes.object,
        currentDistributionDate: PropTypes.string,
        currentDistributionId: PropTypes.number,
        currentUser: PropTypes.object.isRequired,
        distributionTimeStart: PropTypes.string,
        farm: PropTypes.object,
        i18n: PropTypes.string.isRequired,
        isSmallWidth: PropTypes.bool,
        isOnSalePage: PropTypes.bool,
        trans: PropTypes.func.isRequired,
        togglePanel: PropTypes.func.isRequired,
        showPanelImmediately: PropTypes.func.isRequired,
        visiblePanel: PropTypes.string,
        analytics: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        setSearchString: PropTypes.func.isRequired,
        searchProducts: PropTypes.func.isRequired,
        searchString: PropTypes.string,
        clearSearch: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        basket: PropTypes.object,
        interSaleProductPushBasket: PropTypes.object,
        pickups: PropTypes.array.isRequired,
        selectedPickup: PropTypes.string,
        fetchPickupAndDelivry: PropTypes.func.isRequired,
        isOnDeliveryPage: PropTypes.bool,
        isOnBasketPage: PropTypes.bool,
        isOnCheckoutAccountPage: PropTypes.bool,
        shouldShowProductIdentity: PropTypes.bool,
        closeAllToggle: PropTypes.func,
        isOnAssemblyHomepage: PropTypes.bool,
        hostAssemblies: PropTypes.array,
        userAssemblies: PropTypes.array.isRequired,
    };

    static defaultProps = {
        interSaleProductPushBasket: {
            items: [],
            totalPrice: 0,
        },
        isOnDeliveryPage: false,
    };

    constructor(props) {
        super(props);
        this.basketRef = React.createRef();
        this.searchInputContainerRef = React.createRef();
        this.simpleMemberTopLeftMenuRef = React.createRef();
        this.simpleMemberTopRightMenuRef = React.createRef();
        this.deliveryInfosRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const { basket, currentDistributionId } = this.props;
        if (
            basket &&
            currentDistributionId &&
            currentDistributionId !== prevProps.currentDistributionId
        ) {
            this.props.fetchPickupAndDelivry(basket);
        }
    }

    onValueChange = e => {
        this.props.setSearchString(this.props.currentDistributionId, e.target.value);
        this.doProductsSearch();
    };

    doProductsSearch = debounce(function search() {
        const { currentDistributionId, searchString } = this.props;
        this.props.searchProducts(currentDistributionId, searchString);
    }, 300);

    openMenu = (e, panel) => {
        e.preventDefault();
        this.props.showPanelImmediately(panel);
    };

    openContextMenu = e => this.openMenu(e, CONTEXT_PANEL);

    openUserMenu = e => this.openMenu(e, USER_PANEL);

    openSearchPanel = e => this.openMenu(e, SEARCH_PANEL);

    openDeliveryMenu = e => {
        this.trackOpenDeliveryPanel();
        return this.openMenu(e, DELIVERY_PANEL);
    };
    openHelpMenu = e => this.openMenu(e, HELP_PANEL);

    trackOpenDeliveryPanel = () => {
        this.props.analytics.track('clickOnDeliveryPanel', {
            category: 'Livraison',
        });
    };

    toggleBasketPanel = e => {
        e && e.preventDefault();
        if (this.props.isSmallWidth) {
            const checkoutUrl = this.props.user.anonymous
                ? `/checkout/${this.props.basket.uuid}/basket`
                : '/basket';
            this.props.closeAllToggle();
            return this.props.history.push(checkoutUrl);
        }
        return this.props.togglePanel(BASKET_PANEL);
    };

    clearSearchInputAndResult = () => {
        this.props.clearSearch(this.props.currentDistributionId);
    };

    renderAssemblyButtonMobile = () => {
        return (
            <button
                aria-label={this.props.trans('navigation.member.assemblies')}
                className="simpleMemberTopRightMenu-assemblyButton simpleMemberTopNavigation-button u-mr-2"
                onClick={this.openContextMenu}
            >
                {!this.props.shouldShowProductIdentity && <img alt="" src={currentAssemblyIcon} />}
                {this.props.shouldShowProductIdentity && (
                    <Icon src={logoWithoutText} color="white" size="xx-large" />
                )}
            </button>
        );
    };

    renderDistributionButton = () => {
        const {
            trans,
            pickups,
            currentAssembly,
            shouldShowProductIdentity,
            isOnBasketPage,
            isOnDeliveryPage,
        } = this.props;
        const { headline, content } = this.renderDistributionButtonContent();
        const disabled =
            (pickups.length < 2 && currentAssembly?.distributions?.length < 2) ||
            isOnDeliveryPage ||
            isOnBasketPage;
        return (
            <>
                {shouldShowProductIdentity && content && (
                    <div ref={this.deliveryInfosRef} className="delivery-infos">
                        <button
                            className="simpleMemberTopNavigation-button simpleMemberTopLeftMenu-deliveryPanelButton without-end-border"
                            onClick={this.openDeliveryMenu}
                            disabled={disabled}
                        >
                            {!disabled && <Caret productIdentity={shouldShowProductIdentity} />}
                            <div>
                                <PIText size="14px" bold>
                                    {trans('global.distribution')}
                                </PIText>
                                <PIText className="delivery-name" size="14px">
                                    {content}
                                </PIText>
                            </div>
                        </button>
                    </div>
                )}
                {!shouldShowProductIdentity && (
                    <>
                        <button
                            className={classnames(
                                'simpleMemberTopNavigation-button simpleMemberTopLeftMenu-deliveryPanelButton',
                                {
                                    'without-end-border': shouldShowProductIdentity,
                                }
                            )}
                            onClick={this.openDeliveryMenu}
                        >
                            <div className="simpleMemberTopLeftMenu-assemblyPanel">
                                <Text weight="bold">{headline}</Text>
                                <Text>{content}</Text>
                            </div>
                            <Caret />
                        </button>
                    </>
                )}
            </>
        );
    };

    renderDistributionButtonContent = () => {
        const {
            currentDistributionId,
            currentDistributionDate,
            distributionTimeStart,
            i18n,
            trans,
        } = this.props;

        const deliveryOption =
            localstorage.getItem(`delivery.option_${currentDistributionId}`) ||
            `${currentDistributionId}_assembly`;

        const option = getDeliveryOptionInfo(deliveryOption);

        if (option.type === DELIVERY_OFFER) {
            return {
                headline: trans('navigation.delivery.panel.trigger.delivery'),
                content: getLocalizedDate(i18n, 'ddd Do MMM', currentDistributionDate),
            };
        }

        if (option.type === PICKUP) {
            return {
                headline: trans('navigation.delivery.panel.trigger.pickup'),
                content: option.name,
            };
        }

        return {
            headline: trans('navigation.delivery.panel.trigger.pickup'),
            content: currentDistributionId && (
                <>
                    {getLocalizedDate(i18n, 'ddd Do MMM', currentDistributionDate)}
                    <span>
                        {distributionTimeStart && ` • ${distributionTimeStart.substring(0, 5)}`}
                    </span>
                </>
            ),
        };
    };

    renderHelpButton = () => {
        const { visiblePanel, currentAssembly, farm, currentUser } = this.props;
        const displayHelpPanel = visiblePanel === HELP_PANEL;

        return (
            <HelpButton
                open={displayHelpPanel}
                currentAssembly={currentAssembly}
                farm={farm}
                currentUser={currentUser}
                openHelpMenu={this.openHelpMenu}
            />
        );
    };

    renderSearchButton = () => {
        return (
            <ProductSearch
                onFocus={this.openSearchPanel}
                onChange={this.onValueChange}
                searchString={this.props.searchString}
                clearSearch={this.clearSearchInputAndResult}
            />
        );
    };

    renderAssemblyButton = () => {
        const { currentAssembly } = this.props;

        return (
            <button
                className={classnames(
                    this.props.shouldShowProductIdentity &&
                        'simpleMemberTopRightMenu-panel simpleMemberTopNavigation-button u-mr-2',
                    !this.props.shouldShowProductIdentity &&
                        'simpleMemberTopLeftMenu-assemblyButton simpleMemberTopNavigation-button',
                    { 'without-end-border': this.props.shouldShowProductIdentity }
                )}
                onClick={this.openContextMenu}
            >
                <div className="simpleMemberTopLeftMenu-assemblyPanel">
                    {this.props.shouldShowProductIdentity && (
                        <div className="d-flex v-center">
                            <div className="u-mr-4">
                                <Icon src={logoWithoutText} color="white" size="xxx-large" />
                            </div>
                            <div>
                                <PIText size="16px" bold>
                                    {currentAssembly.place.address.city.name}
                                </PIText>
                                <PIText size="16px">{currentAssembly.name}</PIText>
                            </div>
                        </div>
                    )}
                    {!this.props.shouldShowProductIdentity && (
                        <>
                            <Text weight="bold">{currentAssembly.place.address.city.name}</Text>
                            <Text>{currentAssembly.name}</Text>
                        </>
                    )}
                </div>
                <Caret productIdentity={this.props.shouldShowProductIdentity} />
            </button>
        );
    };

    renderBasketButton = () => {
        const { isSmallWidth, basket: { items = [], purchasedPrice = {} } = {} } = this.props;
        const totalQuantity = items.reduce((acc, { quantity }) => acc + quantity, 0);
        return (
            <BasketButton
                className={!isSmallWidth ? 'simpleMemberTopRightMenu-panel' : ''}
                ref={this.basketRef}
                onClick={this.toggleBasketPanel}
                quantity={totalQuantity}
                price={purchasedPrice}
                tooltipOrientation={isSmallWidth ? TOP_TOOLTIP : RIGHT_TOOLTIP}
                mode={isSmallWidth ? DARK_MODE : LIGHT_MODE}
            />
        );
    };

    renderLoginButton = () => {
        const {
            isSmallWidth,
            isOnBasketPage,
            isOnSalePage,
            shouldShowProductIdentity,
        } = this.props;

        return (
            <AccountButton
                isSmallWidth={isSmallWidth}
                isOnHeader={(!isOnBasketPage && !isOnSalePage) || !isSmallWidth}
                shouldShowProductIdentity={shouldShowProductIdentity}
            />
        );
    };

    renderContextPanel = () => {
        const { currentAssembly, currentUser, farm, hostAssemblies, userAssemblies } = this.props;

        if (this.props.shouldShowProductIdentity) {
            return null;
        }
        if (currentUser.anonymous) {
            return (
                <StructuredSidePanel isOpened variant="left" isNewNavigationLayout>
                    <AnonymousContextPanel assembly={currentAssembly} />
                </StructuredSidePanel>
            );
        }

        return (
            <StructuredSidePanel
                isOpened
                variant="left"
                title={this.props.trans('navigation.context.assemblies_as_member_only')}
                isNewNavigationLayout
            >
                <ContextPanel
                    farm={farm}
                    hasPendingLeaderProfile={false}
                    hostAssemblies={hostAssemblies}
                    userAssemblies={userAssemblies}
                />
            </StructuredSidePanel>
        );
    };

    renderDeliveryPanel = () => {
        const { currentAssembly, currentDistributionId, shouldShowProductIdentity } = this.props;
        const DeliveryPanelComponent = shouldShowProductIdentity ? PIDeliveryPanel : DeliveryPanel;
        return (
            <DeliveryPanelComponent
                className="simpleMemberTopNavigation-deliveryPanel"
                currentAssembly={currentAssembly}
                currentDistributionId={currentDistributionId}
                onClose={() => this.props.togglePanel(DELIVERY_PANEL)}
            />
        );
    };

    renderUserPanel = () => {
        const { currentAssembly, currentUser } = this.props;
        const assemblyId = currentAssembly && currentAssembly.id;

        if (this.props.shouldShowProductIdentity) {
            if (currentUser.anonymous) {
                return <AnonymousProfilePanel onClose={() => this.props.togglePanel(USER_PANEL)} />;
            }

            return <ProfilePanel onClose={() => this.props.togglePanel(USER_PANEL)} />;
        }

        // It should be impossible
        if (currentUser.anonymous) {
            return (
                <StructuredSidePanel isOpened isNewNavigationLayout>
                    <AnonymousUserPanel assemblyId={assemblyId} />
                </StructuredSidePanel>
            );
        }

        return (
            <StructuredSidePanel isOpened title={formatFullName(currentUser)} isNewNavigationLayout>
                <UserPanel currentUser={currentUser} />
            </StructuredSidePanel>
        );
    };
    renderBasketPanel() {
        const {
            basket,
            currentAssembly,
            currentDistributionId,
            pickups,
            interSaleProductPushBasket,
            selectedPickup,
        } = this.props;

        return currentAssembly ? (
            <PIBasketPanel
                basket={basket}
                selectedPickup={selectedPickup}
                interSaleProductPushBasket={interSaleProductPushBasket}
                currentAssemblyId={currentAssembly.id}
                currentDistributionId={currentDistributionId}
                toggleBasketPanel={e => {
                    !this.basketRef.current.contains(e.target) && this.toggleBasketPanel(e);
                }}
                pickups={pickups}
            />
        ) : null;
    }

    renderLegacyNavigation = () => {
        const { isSmallWidth, isOnSalePage, currentAssembly } = this.props;
        let theme = 'default';

        if (currentAssembly && currentAssembly.theme.name) {
            theme = currentAssembly.theme.name;
        }
        return (
            <nav
                aria-label="navigation"
                className={classnames(`simpleMemberTopNavigation color-${theme}`, {
                    productIdentity: this.props.shouldShowProductIdentity,
                })}
            >
                {currentAssembly && (
                    <div className="simpleMemberTopLeftMenu">
                        {!isSmallWidth && this.renderAssemblyButton()}
                        {isOnSalePage && this.renderDistributionButton()}
                    </div>
                )}
                {!isSmallWidth && (
                    <div className="simpleMemberTopNavigation-rightSeparator--spacer" />
                )}
                <div
                    className={classnames(
                        { 'simpleMemberTopRightMenu-noSale': !isOnSalePage },
                        'simpleMemberTopRightMenu'
                    )}
                >
                    {!isSmallWidth && this.renderHelpButton()}
                    {isSmallWidth && currentAssembly && this.renderAssemblyButtonMobile()}
                    {this.renderLoginButton()}
                </div>
            </nav>
        );
    };
    /** Product identity functions */
    renderProductIdentityNavigation = () => {
        const {
            currentAssembly,
            isOnSalePage,
            isSmallWidth,
            trans,
            visiblePanel,
            isOnDeliveryPage,
            isOnBasketPage,
            isOnCheckoutAccountPage,
            isOnAssemblyHomepage,
            currentUser,
            farm,
        } = this.props;

        const displayBasketPanel = visiblePanel === BASKET_PANEL;
        const displayHelpPanel = visiblePanel === HELP_PANEL;

        return (
            <>
                <nav
                    aria-label="navigation"
                    className={classnames(`simpleMemberTopNavigation color-turquoise2 col-xs-12`)}
                >
                    <div
                        ref={this.simpleMemberTopLeftMenuRef}
                        className="simpleMemberTopLeftMenu col-xs-12 col-sm-6"
                    >
                        {currentAssembly && <AssemblyInfos />}
                        {(isOnSalePage ||
                            isOnDeliveryPage ||
                            isOnBasketPage ||
                            isOnCheckoutAccountPage) &&
                            currentAssembly &&
                            this.renderDistributionButton()}
                    </div>
                    {(!isSmallWidth ||
                        (isSmallWidth &&
                            isOnAssemblyHomepage &&
                            this.props.shouldShowProductIdentity)) && (
                        <div
                            ref={this.simpleMemberTopRightMenuRef}
                            className={classnames(
                                { 'simpleMemberTopRightMenu-noSale': !isOnSalePage },
                                'simpleMemberTopRightMenu col-sm-6'
                            )}
                        >
                            {!isSmallWidth && (
                                <div
                                    ref={this.searchInputContainerRef}
                                    className={classnames(
                                        this.props.shouldShowProductIdentity
                                            ? 'col-sm-8'
                                            : 'col-sm-6'
                                    )}
                                >
                                    {isOnSalePage && this.renderSearchButton()}
                                </div>
                            )}
                            {this.props.shouldShowProductIdentity && (
                                <div className="simpleMemberTopRightMenu-buttons-container">
                                    {!isSmallWidth && this.renderHelpButton()}
                                    {this.renderLoginButton()}
                                    {!isSmallWidth && isOnSalePage && this.renderBasketButton()}
                                </div>
                            )}
                            {!this.props.shouldShowProductIdentity && (
                                <>
                                    {this.renderHelpButton()}
                                    {this.renderLoginButton()}
                                    {isOnSalePage && this.renderBasketButton()}
                                </>
                            )}
                        </div>
                    )}
                </nav>
                {!this.props.shouldShowProductIdentity && isSmallWidth && isOnSalePage && (
                    <div className="saleSmallScreenNavigation-fixed">
                        <div className="saleSmallScreenNavigation-container">
                            <button onClick={this.openContextMenu}>
                                <Icon color="gray2" src={homeIcon} size="xx-large" />
                                <Text size="small">{trans('menu.home')}</Text>
                            </button>
                            <button onClick={this.openSearchPanel}>
                                <Icon color="gray2" src={searchNavIcon} size="xx-large" />
                                <Text size="small">{trans('global.search')}</Text>
                            </button>
                            <button onClick={this.openUserMenu}>
                                <Icon color="gray2" src={userIcon} size="xx-large" />
                                <Text size="small">{trans('navigation.user.account')}</Text>
                            </button>
                            {this.renderBasketButton()}
                        </div>
                    </div>
                )}
                {this.props.shouldShowProductIdentity && isSmallWidth && isOnSalePage && (
                    <SaleSmallScreenNavigation
                        onSearchBtnClick={this.openSearchPanel}
                        onHomeBtnClick={this.openContextMenu}
                        onBasketBtnClick={this.toggleBasketPanel}
                        basketRef={this.basketRef}
                        basket={this.props.basket}
                        activePanel={visiblePanel}
                        isOnAssemblyHomepage={isOnAssemblyHomepage}
                    />
                )}
                {!isSmallWidth && displayBasketPanel && this.renderBasketPanel()}
                {isSmallWidth && displayHelpPanel && (
                    <HelpPanel
                        user={currentUser}
                        currentAssembly={currentAssembly || null}
                        farm={farm}
                    />
                )}
            </>
        );
    };

    renderProductIdentitySearch = () => {
        return (
            this.props.visiblePanel === SEARCH_PANEL && (
                <SearchModal
                    onBlur={() => this.props.togglePanel(SEARCH_PANEL)}
                    onChange={this.onValueChange}
                    distributionId={this.props.currentDistributionId}
                    searchInputContainerRef={this.searchInputContainerRef}
                    simpleMemberTopLeftMenuRef={this.simpleMemberTopLeftMenuRef}
                    simpleMemberTopRightMenuRef={this.simpleMemberTopRightMenuRef}
                    deliveryInfosRef={this.deliveryInfosRef}
                />
            )
        );
    };

    render() {
        const { isSmallWidth, isOnSalePage, visiblePanel } = this.props;
        const displayContextPanel = visiblePanel === CONTEXT_PANEL;
        const displayDeliveryPanel = visiblePanel === DELIVERY_PANEL;

        return (
            <>
                {this.props.shouldShowProductIdentity && this.renderProductIdentitySearch()}
                <ResponsiveNavigation isSmallWidth={isSmallWidth} isNewNavigationLayout>
                    <NavigationRegion
                        key="top"
                        position="top"
                        absolute={!this.props.shouldShowProductIdentity && isOnSalePage}
                        sticky={this.props.shouldShowProductIdentity && isOnSalePage}
                    >
                        {this.props.shouldShowProductIdentity &&
                            this.renderProductIdentityNavigation()}
                        {!this.props.shouldShowProductIdentity && this.renderLegacyNavigation()}
                        {displayContextPanel && this.renderContextPanel()}
                        {displayDeliveryPanel && this.renderDeliveryPanel()}
                    </NavigationRegion>
                </ResponsiveNavigation>
            </>
        );
    }
}

function mapStateToProps(state, { currentDistributionId, interSaleProductPush }) {
    return {
        pickups: pickupsSelector(state),
        saleHistory: historySelector(state, { historyName: 'sale' }),
        searchString: getSearchString(state, { distributionId: currentDistributionId }),
        user: userSelector(state),
        basket: getBasketOfDistribution(state, { distributionId: currentDistributionId }),
        selectedPickup:
            state.delivery.selectedPickups[currentDistributionId] ||
            localstorage.getItem(`delivery.option_${currentDistributionId}`) ||
            `${currentDistributionId}_assembly`,
        interSaleProductPushBasket: getBasketOfDistribution(state, {
            distributionId: interSaleProductPush?.sourceSale?.id,
        }),
    };
}

export default connect(mapStateToProps, {
    togglePanel,
    showPanelImmediately,
    searchProducts,
    setSearchString,
    fetchPickupAndDelivry,
    clearSearch,
    closeAllToggle,
})(connectToAnalytics(withRouter(connectToI18n(SimpleMemberNavigation))));
