import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import PageHeader from 'components/PageHeader.jsx';
import Wrapper from 'components/Wrapper.jsx';
import TermsPublicFooter from 'components/TermsPublicFooter.jsx';
import FeaturesService from 'models/features';
import { firstFarmSelector } from 'modules/currentUser';
import { connect } from 'react-redux';
import { FEATURES } from 'models/features.js';

export class DistributionsPage extends React.Component {
    static propTypes = {
        activeSlug: PropTypes.string.isRequired,
        children: PropTypes.node,
        className: PropTypes.string,
        trans: PropTypes.func.isRequired,
        farm: PropTypes.object.isRequired,
    };

    render() {
        const { activeSlug, trans, className } = this.props;

        const hasOnsiteSalesFeature =
            FeaturesService.isFeatureActive(FEATURES.ONSITE_SALES, this.props.farm) ||
            FeaturesService.isFeatureActive(FEATURES.ONSITE_SALES_COUNTRY, this.props.farm);

        const navigationLinks = [
            {
                label: trans('farms.collections.follow.link'),
                path: 'producers/me/collections',
                slug: 'follow',
            },
            {
                label: trans('farms.collections.planning.link'),
                path: 'producers/me/calendar',
                slug: 'calendar',
            },
            {
                label: trans('farms.collections.history.link'),
                path: 'producers/me/collections/history',
                slug: 'history',
            },
        ];

        if (hasOnsiteSalesFeature) {
            navigationLinks.splice(1, 0, {
                label: trans('farms.collections.onsitesale.link'),
                path: 'producers/onsite-sales',
                slug: 'onsite-sales',
            });
        }

        return (
            <div className={className}>
                <PageHeader
                    background="default"
                    title={trans('navigation.left.collections')}
                    navigationActiveSlug={activeSlug}
                    navigationLinks={navigationLinks}
                    overlap="small"
                />
                <Wrapper xsPadding>{this.props.children}</Wrapper>
                <TermsPublicFooter />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    farm: firstFarmSelector(state),
});

export default connect(mapStateToProps)(connectToI18n(DistributionsPage));
