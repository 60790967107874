import Backbone from 'backbone';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet-async';
import * as R from 'ramda';
import Url from 'modules/url';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'underscore';

import { hasAvailableStock } from 'modules/stockUtils';
import connectToI18n from 'modules/i18n/connectToI18n';
import FeatureService, { FEATURES } from 'models/features';
import installI18nContext from 'modules/i18n/installI18nContext';
import { displayableCity } from 'models/address';
import { editBasketOfferQuantity } from 'modules/orders';
import {
    BASKET_PANEL,
    getVisiblePanel,
    toggle as togglePanel,
    USER_PANEL,
} from 'modules/visiblePanel';
import { INITIAL_LOADING, READY, SAVED, PROCESSING } from 'modules/utils/ajaxStatuses';
import { setHistory } from 'modules/routerHistory';

import { getUniqTagKeyList } from 'models/tag';
import {
    addFarmAndLabelsToProduct,
    groupProductsByAlreadyOrderedFarms,
    groupProductsByCategories,
} from 'models/products';
import { getProductCategoriesForSalePage } from 'models/productTypes';
import { isDeliveryProduct } from 'models/delivery';

import LoadingAnimation from 'components/LoadingAnimation.jsx';
import HighlightedProducts from 'components/Sale/HighlightedProducts/HighlightedProducts.jsx';
import PIProductsByCategories from 'components/Sale/ProductIdentity/ProductsByCategories/ProductsByCategories.jsx';
import PIProductPageContainer from 'components/Product/ProductIdentity/ProductPageContainer.jsx';
import PIProductsPanel from 'components/Sale/ProductIdentity/ProductsPanel.jsx';
import SaleNavigationContainer from './SaleNavigationContainer.jsx';
import NavigationBackButton from 'components/Navigation/NavigationBackButton.jsx';
import SearchResults from './SearchResults.jsx';
import {
    ALL_PRODUCTS_CATEGORY,
    ALL_PRODUCTS_FILTER,
    ALL_TAGS_FILTER,
    ALREADY_BOUGHT_CATEGORY,
    filterProducts,
    GOOD_DEAL_CATEGORY,
    HIGHLIGHTED_CATEGORY,
    memoizeProductsOfFarm,
    ORGANIC_FILTER,
    filterByCategory,
    filterByPromotion,
    getCurrentFarmsOrderedByPriority,
    filterSubcategoriesOfCategory,
    CUSTOM_EVENT_TAG_CATEGORY,
} from 'modules/utils/saleCategoriesAndFilters';
import SearchPanel from './SearchPanel.jsx';
import PISaleNavigationCategoriesCarousel from './ProductIdentity/SaleNavigationCategoriesCarousel.jsx';
import { isMemberOfAssembly } from 'models/users';
import { getFarms, getProducts, getSourceFarms } from 'models/distributions.js';
import ErrorPage from 'components/Error/ErrorPage.jsx';
import TermsFooter from 'components/ProductIdentity/TermsFooter.jsx';
import { imperativeEditUser } from 'modules/currentUser';
import { userJoinAssembly } from 'api/hives';
import ChristmasBanner from './banners/ChristmasBanner.jsx';
import CoronavirusBanner from './banners/CoronavirusBanner.jsx';
import GiftcardBanner from './banners/GiftcardBanner.jsx';
import PICurrentCategoryInformation from 'components/Sale/ProductIdentity/CurrentCategoryInformation.jsx';
import FilterButtonMobile from 'components/Sale/ProductIdentity/FilterButtonMobile.jsx';

const goToHomepage = ({ assemblyId, i18n }) => {
    window.location = `/${i18n}/assemblies/${assemblyId}`;
};

/**
 * React Router does not handle the navigaton outside the sale for now.
 * We must use the Backbone router.
 */
const goToOtherSaleProduct = (assemblyId, distributionId) => productId => {
    Backbone.history.navigate(
        `assemblies/${assemblyId}/collections/${distributionId}/products/${productId}`,
        { trigger: true }
    );
};

const getSubcategoryName = (currentSubCategoryId, currentSubCategories) => {
    const currentSubCategory = R.find(R.propEq('subCategoryId', currentSubCategoryId))(
        currentSubCategories
    );
    return currentSubCategory ? currentSubCategory.subCategoryName : null;
};

// eslint-disable-next-line eqeqeq
const isSameCategoryId = R.curry((categoryId, category) => categoryId == category.id);

export class Sale extends React.Component {
    static propTypes = {
        assembly: PropTypes.object.isRequired,
        distribution: PropTypes.object.isRequired,
        editBasketOfferQuantity: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
        isSmallWidth: PropTypes.bool,
        visiblePanel: PropTypes.string,
        history: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        imperativeEditUser: PropTypes.func.isRequired,
        setHistory: PropTypes.func.isRequired,
        togglePanel: PropTypes.func.isRequired,
    };

    state = {
        allProducts: [],
        hasNextPage: true,
        isLoading: false,
        isSearchPanelOpen: false,
        status: INITIAL_LOADING,
        isBasketMenuOpen: false,
        isFilterMobilePanelOpen: false,
        hasAlreadyBoughtProducts: false,
        currentFarmId: null,
        lastVisitedCategoryId: null,
        joinAssemblyStatus: INITIAL_LOADING,
    };

    componentDidMount = () => {
        const distributionId = this.props.distribution.id;
        const farmsRequest = getFarms(distributionId);
        const sourceFarmsRequest = getSourceFarms(distributionId);
        const fetchAllProductsRequest = getProducts(distributionId, this.props.user);
        this.props.setHistory('sale', this.props.history);

        const requests = [farmsRequest, fetchAllProductsRequest, sourceFarmsRequest];

        Promise.all(requests).then(this.onDataLoaded, this.handleSaleLoadingError);
        this.updateSmallScreenPanelOpeningStatus();
        // cf. https://github.com/ReactTraining/history#usage
        this.unlisten = this.props.history.listen(() => {
            this.updateSmallScreenPanelOpeningStatus();
        });

        document.addEventListener('scroll', this.updateTopShadow);
    };

    componentWillUnmount = () => {
        this.unlisten();
        document.removeEventListener('scroll', this.updateTopShadow);
    };

    onDataLoaded = ([{ farms, productTypes }, { products, labels }, sourceFarms]) => {
        const { assembly } = this.props;

        const hasCustomEventTagFeature = FeatureService.isFeatureActive(
            FEATURES.HIVE_MEMBER_HAS_CUSTOM_EVENT_TAG,
            assembly
        );

        const allProducts = R.reject(isDeliveryProduct, products);
        const hasHighlightedCategory = R.filter(hasAvailableStock, allProducts).length >= 5;
        const hasAlreadyBoughtProducts =
            filterProducts(ALREADY_BOUGHT_CATEGORY, null, null, allProducts).length > 0;
        const hasGoodDealProducts =
            this.props.distribution.hasGoodDealProducts &&
            filterProducts(GOOD_DEAL_CATEGORY, null, null, allProducts).length > 0;
        const hasCustomEventTagProducts =
            hasCustomEventTagFeature &&
            this.props.distribution.hasCustomEventTaggedProducts &&
            filterProducts(CUSTOM_EVENT_TAG_CATEGORY, null, null, allProducts).length > 0;

        // remove source farms not in farms endpoint because their products have been removed from sale by the producer
        const isInSaleNow = sourceFarm => !R.isNil(farms[sourceFarm.authorFarmId]);
        const sourceFarmsInSale = R.filter(isInSaleNow, sourceFarms);

        // give id and prority to sourceFarm so that structure is similar to other farms
        const sourceFarmsWithId = R.map(
            sourceFarm =>
                R.merge(
                    {
                        id: sourceFarm.uuid,
                        priority: farms[sourceFarm.authorFarmId].priority,
                    },
                    sourceFarm
                ),
            sourceFarmsInSale
        );

        const categories = getProductCategoriesForSalePage(productTypes, this.props.i18n, {
            hasHighlightedCategory,
            hasAlreadyBoughtProducts,
            hasAllProducts: true,
            hasGoodDealProducts,
            hasCustomEventTagProducts,
        });

        const categoriesSubCategoriesFiltered = categories.map(category => {
            if (category.id !== GOOD_DEAL_CATEGORY && category.id !== CUSTOM_EVENT_TAG_CATEGORY) {
                return category;
            }
            return filterSubcategoriesOfCategory(
                category,
                filterProducts(category.id, null, null, allProducts)
            );
        });

        const allFarms = { ...farms, ...sourceFarmsWithId };
        const farmsWithDisplayableCity = R.map(farm =>
            R.assoc('city', displayableCity(farm), farm)
        )(allFarms);
        const allProductsWithFarmsAndLabelsInfo = allProducts.map(product =>
            addFarmAndLabelsToProduct(product, farmsWithDisplayableCity, labels)
        );

        this.setState({
            allProducts: allProductsWithFarmsAndLabelsInfo,
            categories: categoriesSubCategoriesFiltered,
            farms: allFarms,
            productTypes,
            status: READY,
            hasAlreadyBoughtProducts,
            hasHighlightedCategory,
        });
    };

    /**
     * React Router handle the navigation inside the sale. Use it instead of Backbone router.
     */
    goToSameSaleProduct = categoryId => productId => {
        this.props.history.push(`/${productId}`, { categoryId });
    };

    updateSmallScreenPanelOpeningStatus() {
        const currentUrl = new Url();
        this.setState({
            smallScreenNavigationPanelIsOpen:
                currentUrl.getQueryParameters().smallScreenNavigationPanelIsOpen === 'true',
        });
    }

    handleSaleLoadingError = () => {
        // Error on data loading is probably due to the case where the user clicked on an obsolete sale link, pointing
        // to a closed sale.
        // We redirect to the assembly homepage
        goToHomepage({ assemblyId: this.props.assembly.id, i18n: this.props.i18n });
    };

    getPageData = (categoryId, subCategoryId, filter) => {
        const { allProducts, farms } = this.state;

        const currentProducts = filterProducts(categoryId, subCategoryId, filter)(allProducts);
        const currentFarmsOrderedByPriority = currentProducts
            ? getCurrentFarmsOrderedByPriority(farms, currentProducts)
            : null;

        const organicProductsOfCategory = filterProducts(
            categoryId,
            subCategoryId,
            ORGANIC_FILTER
        )(allProducts);
        const firstFarmOfList = !R.isEmpty(currentFarmsOrderedByPriority)
            ? currentFarmsOrderedByPriority[0].id
            : null;

        const currentFarmId = R.find(R.propEq('id', this.state.currentFarmId))(
            currentFarmsOrderedByPriority
        )
            ? this.state.currentFarmId
            : firstFarmOfList;

        return {
            currentProducts,
            currentFarmId,
            currentFarmsOrderedByPriority,
            categoryHasOrganicProducts: organicProductsOfCategory.length > 0,
        };
    };

    addOfferToBasket = (offerId, quantity = 1, actionOrigin) => {
        this.setState({ isBasketMenuOpen: true });
        this.props.editBasketOfferQuantity(
            this.props.distribution.id,
            offerId,
            quantity,
            actionOrigin
        );
    };

    setCurrentFarm = farmId => {
        this.setState({ currentFarmId: farmId });
    };

    setLastVisitedCategoryId = categoryId => {
        this.setState({
            lastVisitedCategoryId: categoryId,
        });
    };

    toggleFilterMobilePanel = () => {
        this.setState(prevState => ({
            isFilterMobilePanelOpen: !prevState.isFilterMobilePanelOpen,
        }));
    };

    closeFilterMobilePanel = () => {
        this.setState({ isFilterMobilePanelOpen: false });
    };

    showSmallScreenNavigationPanel = e => {
        e.preventDefault();
        this.props.history.push('?smallScreenNavigationPanelIsOpen=true');
        this.setState({ smallScreenNavigationPanelIsOpen: true });
    };

    closeSmallScreenNavigationPanel = () => {
        this.setState({ smallScreenNavigationPanelIsOpen: false });
    };

    joinAssembly = () => {
        const { assembly, user } = this.props;
        if (user.anonymous) {
            this.props.togglePanel(USER_PANEL);
            return;
        }
        this.setState({
            joinAssemblyStatus: PROCESSING,
        });
        userJoinAssembly(assembly.id, user.id).then(() => {
            this.props.imperativeEditUser(
                R.evolve({
                    hivesAsMember: R.append(assembly),
                    isMember: R.T,
                })
            );
            this.setState({
                joinAssemblyStatus: SAVED,
            });
        });
    };

    subCategoriesToFilter = (category, products) =>
        filterSubcategoriesOfCategory(category, products).subCategories.map(subCategory => ({
            id: subCategory.id,
            name: subCategory.name,
            key: subCategory.categoryKey,
        }));

    saleNavigationContainerRef = React.createRef();

    updateTopShadow = () => {
        const containerClassList = this.saleNavigationContainerRef?.current?.classList;
        if (!containerClassList) {
            return;
        }
        document.documentElement.scrollTop <= (this.props.isSmallWidth ? 10 : 35)
            ? containerClassList.remove('saleTopNavigation-shadow')
            : containerClassList.add('saleTopNavigation-shadow');
    };

    renderNavigation = (currentCategory, currentSubCategoryId) => {
        const productTypesOfCurrentCategory = R.filter(
            R.propEq('categoryId', currentCategory.id),
            this.state.productTypes
        );
        const currentSubCategories = R.pipe(
            R.map(R.pick(['subCategoryId', 'subCategoryKey', 'subCategoryName'])),
            R.values,
            R.uniq
        )(productTypesOfCurrentCategory);

        const subCategoryName = getSubcategoryName(currentSubCategoryId, currentSubCategories);

        const currentCategoryOrSubCategoryName = subCategoryName
            ? subCategoryName
            : R.prop('name', currentCategory);

        const { assembly, distribution, isSmallWidth, visiblePanel } = this.props;
        const { categories, smallScreenNavigationPanelIsOpen, productTypes } = this.state;

        return (
            <SaleNavigationContainer
                addOfferToBasket={this.addOfferToBasket}
                allProductTypesIndexedById={productTypes}
                assemblyId={assembly.id}
                categories={categories}
                currentCategory={currentCategory}
                currentSubCategories={currentSubCategories}
                currentCategoryName={currentCategoryOrSubCategoryName}
                distributionId={distribution.id}
                interSaleProductPushSourceSaleId={
                    distribution.interSaleProductPush &&
                    distribution.interSaleProductPush.sourceSale.id
                }
                isSmallWidth={isSmallWidth}
                basketIsActive={visiblePanel === BASKET_PANEL}
                smallScreenNavigationPanelIsOpen={smallScreenNavigationPanelIsOpen}
                showSmallScreenNavigationPanel={this.showSmallScreenNavigationPanel}
                closeSmallScreenNavigationPanel={this.closeSmallScreenNavigationPanel}
                currentSubCategoryId={currentSubCategoryId}
                setLastVisitedCategoryId={this.setLastVisitedCategoryId}
                onClickOnProduct={this.goToSameSaleProduct(currentCategory.id)}
                ref={this.saleNavigationContainerRef}
                isForeground
            >
                <PISaleNavigationCategoriesCarousel
                    categories={categories}
                    isSmallWidth={isSmallWidth}
                    currentCategoryId={currentCategory.id}
                />
            </SaleNavigationContainer>
        );
    };

    render() {
        const { assembly, distribution, isSmallWidth, visiblePanel, user } = this.props;

        const {
            allProducts,
            categories,
            isLoading,
            isSearchPanelOpen,
            isFilterMobilePanelOpen,
            productTypes,
            status,
            farms,
            joinAssemblyStatus,
        } = this.state;

        if (status === INITIAL_LOADING) {
            return <LoadingAnimation type="pi-spinner" />;
        }

        const hasGiftCardFeatureFlag = FeatureService.isFeatureActive(
            FEATURES.GIFTCARD_BANNER,
            assembly
        );
        const hasCoronavirusFeatureFlag = FeatureService.isFeatureActive(
            FEATURES.CORONAVIRUS_BANNER,
            assembly
        );
        const hasChristmasFeatureFlag = FeatureService.isFeatureActive(
            FEATURES.CHRISTMAS_THEME,
            assembly
        );

        const hasCustomEventTagFeature = FeatureService.isFeatureActive(
            FEATURES.HIVE_MEMBER_HAS_CUSTOM_EVENT_TAG,
            assembly
        );

        const params = new URLSearchParams(location.search);
        const filter = params.get('filter') || ALL_PRODUCTS_FILTER;
        const filterTag = params.get('filterTag') || ALL_TAGS_FILTER;
        const isMember = isMemberOfAssembly(assembly.id, user);

        return (
            <div data-testid="sale-content">
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <Switch>
                    <Route
                        path="/"
                        exact
                        render={() => {
                            if (this.state.hasHighlightedCategory) {
                                return <Redirect to={`/category/${HIGHLIGHTED_CATEGORY}`} />;
                            }
                            return <Redirect to={`/category/${ALL_PRODUCTS_CATEGORY}`} />;
                        }}
                    />
                    <Route
                        path={`/category/${HIGHLIGHTED_CATEGORY}`}
                        render={() => {
                            const { currentProducts: filteredProducts } = this.getPageData(
                                HIGHLIGHTED_CATEGORY,
                                null,
                                filter
                            );

                            const currentCategory = R.find(
                                isSameCategoryId(HIGHLIGHTED_CATEGORY),
                                categories
                            );

                            return (
                                <>
                                    {this.renderNavigation(currentCategory)}
                                    {hasGiftCardFeatureFlag && <GiftcardBanner />}
                                    {hasCoronavirusFeatureFlag && <CoronavirusBanner />}
                                    {hasChristmasFeatureFlag && <ChristmasBanner />}
                                    <HighlightedProducts
                                        isAnonymous={this.props.user.anonymous || false}
                                        addOfferToBasket={this.addOfferToBasket}
                                        assembly={assembly}
                                        isSmallWidth={isSmallWidth}
                                        productsByFarm={groupProductsByAlreadyOrderedFarms(
                                            filteredProducts
                                        )}
                                        categories={categories}
                                        distributionId={distribution.id}
                                        status={status}
                                        hasNextPage={false}
                                        saleOpenMessage={distribution.saleOpenMessage}
                                        productTypes={productTypes}
                                        allProducts={allProducts}
                                        interSaleProductPush={distribution.interSaleProductPush}
                                        farms={farms}
                                        goToOtherSaleProduct={goToOtherSaleProduct}
                                        goToSameSaleProduct={this.goToSameSaleProduct(
                                            HIGHLIGHTED_CATEGORY
                                        )}
                                        isMemberOfAssembly={isMember}
                                        userId={user.id}
                                        joinAssembly={this.joinAssembly}
                                        joinAssemblyStatus={joinAssemblyStatus}
                                        withTracking
                                    />
                                </>
                            );
                        }}
                    />
                    <Route
                        path={`/category/${GOOD_DEAL_CATEGORY}/:category?`}
                        render={({ match }) => {
                            const {
                                currentProducts: filteredProducts,
                                categoryHasOrganicProducts,
                            } = this.getPageData(GOOD_DEAL_CATEGORY, null, filter);

                            const trueCategories = categories.filter(category => {
                                return category.isTrueCategory === true;
                            });

                            const activeCategoryFilter = match.params.category
                                ? parseInt(match.params.category, 10)
                                : null;

                            const filteredProductsByTrueCategory = filterByCategory(
                                filteredProducts,
                                activeCategoryFilter
                            );
                            const tagList = getUniqTagKeyList(
                                filteredProductsByTrueCategory,
                                false,
                                true
                            );

                            const filteredProductsByPromotion = filterByPromotion(
                                filteredProductsByTrueCategory,
                                filterTag,
                                true
                            );

                            const sortedByTypeProducts = filteredProductsByPromotion.sort(
                                (productA, productB) => {
                                    const productAHasStock = hasAvailableStock(productA);
                                    const productBHasStock = hasAvailableStock(productB);
                                    let stockOrder;
                                    if (productAHasStock === productBHasStock) {
                                        stockOrder = 0;
                                    } else {
                                        stockOrder = productBHasStock ? 1 : -1;
                                    }
                                    return (
                                        stockOrder ||
                                        productA.type.id - productB.type.id ||
                                        (productA.name > productB.name ? 1 : -1)
                                    );
                                }
                            );

                            const currentCategory = R.find(
                                isSameCategoryId(GOOD_DEAL_CATEGORY),
                                categories
                            );
                            const filterList = this.subCategoriesToFilter(
                                _.clone(currentCategory),
                                filteredProducts
                            );

                            return (
                                <>
                                    {this.renderNavigation(currentCategory, activeCategoryFilter)}
                                    {hasChristmasFeatureFlag && <ChristmasBanner />}
                                    <div className="saleContent productByCategories-saleContent">
                                        {
                                            <PICurrentCategoryInformation
                                                categoryHasOrganicProducts={
                                                    categoryHasOrganicProducts
                                                }
                                                currentCategory={currentCategory}
                                                currentFarmId={''}
                                                currentFarms={R.pipe(
                                                    R.pluck('farm'),
                                                    R.uniq
                                                )(sortedByTypeProducts)}
                                                currentFilter={filter}
                                                currentTag={filterTag}
                                                isFilterMobilePanelOpen={isFilterMobilePanelOpen}
                                                productsCount={sortedByTypeProducts.length}
                                                onClickFunction={() => {}}
                                                toggleFilterMobilePanel={
                                                    this.toggleFilterMobilePanel
                                                }
                                                isSmallWidth={isSmallWidth}
                                                filterList={filterList}
                                                tagList={tagList}
                                                activeFilter={activeCategoryFilter}
                                                showFarmAnchor={false}
                                            />
                                        }

                                        <PIProductsByCategories
                                            addOfferToBasket={this.addOfferToBasket}
                                            productsByCategories={groupProductsByCategories(
                                                trueCategories,
                                                sortedByTypeProducts
                                            )}
                                            categories={categories}
                                            distributionId={distribution.id}
                                            status={status}
                                            allProductTypesIndexedById={productTypes}
                                            isNewNavigation
                                            goToSameSaleProduct={this.goToSameSaleProduct(
                                                GOOD_DEAL_CATEGORY
                                            )}
                                            filter={filter}
                                            assembly={assembly}
                                            hasGoodDealMode
                                            filterTag={filterTag}
                                            category="good-deals"
                                        />
                                    </div>
                                    {isSmallWidth && (
                                        <FilterButtonMobile
                                            allProducts={this.state.allProducts}
                                            organicFilter={filter}
                                            mainCategory={currentCategory}
                                            activeCategoryFilter={activeCategoryFilter}
                                            activeFilterTag={filterTag}
                                            hasGoodDealMode
                                        />
                                    )}
                                </>
                            );
                        }}
                    />
                    {hasCustomEventTagFeature && (
                        <Route
                            path={`/category/${CUSTOM_EVENT_TAG_CATEGORY}/:category?`}
                            render={({ match }) => {
                                const {
                                    currentProducts: filteredProducts,
                                    categoryHasOrganicProducts,
                                } = this.getPageData(CUSTOM_EVENT_TAG_CATEGORY, null, filter);

                                const trueCategories = categories.filter(category => {
                                    return category.isTrueCategory === true;
                                });

                                const activeCategoryFilter = match.params.category
                                    ? parseInt(match.params.category, 10)
                                    : null;

                                const filteredProductsByTrueCategory = filterByCategory(
                                    filteredProducts,
                                    activeCategoryFilter
                                );
                                const tagList = getUniqTagKeyList(
                                    filteredProductsByTrueCategory,
                                    false,
                                    true
                                );

                                const filteredProductsByPromotion = filterByPromotion(
                                    filteredProductsByTrueCategory,
                                    filterTag,
                                    true
                                );

                                const sortedByTypeProducts = filteredProductsByPromotion.sort(
                                    (productA, productB) => {
                                        const productAHasStock = hasAvailableStock(productA);
                                        const productBHasStock = hasAvailableStock(productB);
                                        let stockOrder;
                                        if (productAHasStock === productBHasStock) {
                                            stockOrder = 0;
                                        } else {
                                            stockOrder = productBHasStock ? 1 : -1;
                                        }
                                        return (
                                            stockOrder ||
                                            productA.type.id - productB.type.id ||
                                            (productA.name > productB.name ? 1 : -1)
                                        );
                                    }
                                );

                                const currentCategory = R.find(
                                    isSameCategoryId(CUSTOM_EVENT_TAG_CATEGORY),
                                    categories
                                );
                                const filterList = this.subCategoriesToFilter(
                                    _.clone(currentCategory),
                                    filteredProducts
                                );

                                return (
                                    <>
                                        {this.renderNavigation(
                                            currentCategory,
                                            activeCategoryFilter
                                        )}
                                        {hasChristmasFeatureFlag && <ChristmasBanner />}
                                        <div className="saleContent productByCategories-saleContent">
                                            {
                                                <PICurrentCategoryInformation
                                                    categoryHasOrganicProducts={
                                                        categoryHasOrganicProducts
                                                    }
                                                    currentCategory={currentCategory}
                                                    currentFarmId={''}
                                                    currentFarms={R.pipe(
                                                        R.pluck('farm'),
                                                        R.uniq
                                                    )(sortedByTypeProducts)}
                                                    currentFilter={filter}
                                                    currentTag={filterTag}
                                                    isFilterMobilePanelOpen={
                                                        isFilterMobilePanelOpen
                                                    }
                                                    productsCount={sortedByTypeProducts.length}
                                                    onClickFunction={() => {}}
                                                    toggleFilterMobilePanel={
                                                        this.toggleFilterMobilePanel
                                                    }
                                                    isSmallWidth={isSmallWidth}
                                                    filterList={filterList}
                                                    tagList={tagList}
                                                    activeFilter={activeCategoryFilter}
                                                    showFarmAnchor={false}
                                                />
                                            }

                                            <PIProductsByCategories
                                                addOfferToBasket={this.addOfferToBasket}
                                                productsByCategories={groupProductsByCategories(
                                                    trueCategories,
                                                    sortedByTypeProducts
                                                )}
                                                categories={categories}
                                                distributionId={distribution.id}
                                                status={status}
                                                allProductTypesIndexedById={productTypes}
                                                isNewNavigation
                                                goToSameSaleProduct={this.goToSameSaleProduct(
                                                    CUSTOM_EVENT_TAG_CATEGORY
                                                )}
                                                filter={filter}
                                                assembly={assembly}
                                                hasGoodDealMode
                                                filterTag={filterTag}
                                                category="christmas"
                                            />
                                        </div>
                                        {isSmallWidth && (
                                            <FilterButtonMobile
                                                allProducts={this.state.allProducts}
                                                organicFilter={filter}
                                                mainCategory={currentCategory}
                                                activeCategoryFilter={activeCategoryFilter}
                                                activeFilterTag={filterTag}
                                                hasGoodDealMode
                                            />
                                        )}
                                    </>
                                );
                            }}
                        />
                    )}
                    <Route
                        path={`/category/:routerCurrentCategory/:subCategory?`}
                        render={({ match }) => {
                            const currentCategoryId = match.params.routerCurrentCategory;
                            const currentSubCategoryId = match.params.subCategory
                                ? parseInt(match.params.subCategory, 10)
                                : null;

                            const sanitizedCategoryId = isNaN(parseInt(currentCategoryId, 10))
                                ? currentCategoryId
                                : parseInt(currentCategoryId, 10);

                            const currentCategory = R.find(
                                isSameCategoryId(sanitizedCategoryId),
                                this.state.categories
                            );

                            const {
                                currentProducts: filteredProducts,
                                currentFarmId,
                                currentFarmsOrderedByPriority: currentFarms,
                                categoryHasOrganicProducts,
                            } = this.getPageData(sanitizedCategoryId, currentSubCategoryId, filter);

                            const productTypesOfCurrentCategory = R.filter(
                                R.propEq('categoryId', sanitizedCategoryId),
                                productTypes
                            );
                            const subCategories = R.pipe(
                                R.map(
                                    R.pick(['subCategoryId', 'subCategoryKey', 'subCategoryName'])
                                ),
                                R.values,
                                R.uniq
                            )(productTypesOfCurrentCategory);

                            return (
                                <>
                                    {!R.isEmpty(allProducts) &&
                                        this.renderNavigation(
                                            currentCategory,
                                            currentSubCategoryId
                                        )}
                                    {hasCoronavirusFeatureFlag &&
                                        R.prop('categoryKey', currentCategory) ===
                                            ALL_PRODUCTS_CATEGORY && <CoronavirusBanner />}
                                    {hasChristmasFeatureFlag && <ChristmasBanner />}

                                    <PIProductsPanel
                                        addOfferToBasket={this.addOfferToBasket}
                                        assembly={assembly}
                                        categoryHasOrganicProducts={categoryHasOrganicProducts}
                                        currentFarmId={currentFarmId}
                                        currentCategory={currentCategory}
                                        currentFarms={currentFarms}
                                        currentFilter={filter}
                                        currentSubCategories={subCategories}
                                        currentSubCategoryId={currentSubCategoryId}
                                        distribution={distribution}
                                        isLoading={isLoading}
                                        isSmallWidth={isSmallWidth}
                                        isFilterMobilePanelOpen={isFilterMobilePanelOpen}
                                        productTypes={productTypes}
                                        products={filteredProducts}
                                        setCurrentFarm={this.setCurrentFarm}
                                        toggleFilterMobilePanel={this.toggleFilterMobilePanel}
                                        onClickOnProduct={this.goToSameSaleProduct(
                                            sanitizedCategoryId
                                        )}
                                        joinAssembly={this.joinAssembly}
                                        isAnonymous={this.props.user.anonymous || false}
                                        isMemberOfAssembly={isMember}
                                        joinAssemblyStatus={joinAssemblyStatus}
                                        hasNoProducts={R.isEmpty(allProducts)}
                                        currentFilterTag={filterTag}
                                    />
                                    {isSmallWidth && (
                                        <FilterButtonMobile
                                            allProducts={this.state.allProducts}
                                            organicFilter={filter}
                                            mainCategory={currentCategory}
                                            activeCategoryFilter={currentSubCategoryId}
                                            activeFilterTag={filterTag}
                                        />
                                    )}
                                </>
                            );
                        }}
                    />
                    <Route
                        path={`/search`}
                        render={() => (
                            <>
                                <SaleNavigationContainer
                                    addOfferToBasket={this.addOfferToBasket}
                                    allProductTypesIndexedById={productTypes}
                                    assemblyId={assembly.id}
                                    distributionId={distribution.id}
                                    interSaleProductPushSourceSaleId={
                                        distribution.interSaleProductPush &&
                                        distribution.interSaleProductPush.sourceSale.id
                                    }
                                    isForeground={!visiblePanel || visiblePanel === BASKET_PANEL}
                                    isSmallWidth={isSmallWidth}
                                    isSearchPanelOpen={isSearchPanelOpen}
                                    basketIsActive={visiblePanel === BASKET_PANEL}
                                    onClickOnProduct={this.goToSameSaleProduct()}
                                >
                                    <div className="saleTopNavigation-searchBar">
                                        <NavigationBackButton
                                            className="saleTopNavigation-backButton"
                                            withSeparator
                                        />
                                        <SearchPanel
                                            distributionId={distribution.id}
                                            isSmallWidth={false}
                                        />
                                    </div>
                                </SaleNavigationContainer>
                                <SearchResults
                                    allProductTypesIndexedById={productTypes}
                                    assemblyId={assembly.id}
                                    distributionId={distribution.id}
                                    onClickOnProduct={this.goToSameSaleProduct()}
                                />
                            </>
                        )}
                    />
                    <Route
                        exact
                        path={`/:activeProductId`}
                        render={({ match, location }) => {
                            const activeProductId = parseInt(match.params.activeProductId, 10);

                            const activeProduct = R.find(
                                R.propEq('id', activeProductId),
                                allProducts
                            );

                            if (!activeProduct) {
                                return <ErrorPage errorType="notFound" />;
                            }

                            const otherProductsOfFarm = R.reject(
                                R.propEq('id', activeProduct.id),
                                memoizeProductsOfFarm(activeProduct.farm.id, allProducts)
                            );
                            const navigationCategoryId =
                                parseInt(location.state?.categoryId, 10) ||
                                location.state?.categoryId;

                            return (
                                <PIProductPageContainer
                                    addOfferToBasket={this.addOfferToBasket}
                                    product={activeProduct}
                                    distributionId={distribution.id}
                                    assembly={assembly}
                                    productTypes={productTypes}
                                    otherProductsOfFarm={otherProductsOfFarm}
                                    isSmallWidth={isSmallWidth}
                                    lastVisitedCategoryId={this.state.lastVisitedCategoryId}
                                    onClickOnProduct={this.goToSameSaleProduct(
                                        navigationCategoryId
                                    )}
                                    history={this.props.history}
                                />
                            );
                        }}
                    />
                </Switch>
                <TermsFooter />
            </div>
        );
    }
}

export default withRouter(
    connect(state => ({ visiblePanel: getVisiblePanel(state) }), {
        editBasketOfferQuantity,
        imperativeEditUser,
        setHistory,
        togglePanel,
    })(installI18nContext(connectToI18n(Sale)))
);
