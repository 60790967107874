/* eslint no-console: 0 */
const noop = function noop() {};

const boundConsoleLog = (console.log && console.log.bind(console)) || noop;
const boundConsoleWarn = console.warn && console.warn.bind(console);
const boundConsoleError = console.error && console.error.bind(console);

const Logger = {
    trace: boundConsoleLog,
    debug: boundConsoleLog,
    info: boundConsoleLog,
    warn: boundConsoleWarn || boundConsoleLog,
    error: boundConsoleError || boundConsoleLog,
    fatal: boundConsoleError || boundConsoleLog,
};

// suppress console if in test
if (process.env.TESTING === 'true') {
    Logger._trace = Logger.trace;
    Logger.trace = noop;
    Logger._debug = Logger.debug;
    Logger.debug = noop;
    Logger._info = Logger.info;
    Logger.info = noop;
    Logger._warn = Logger.warn;
    Logger.warn = noop;
    Logger._error = Logger.error;
    Logger.error = noop;
    Logger._fatal = Logger.fatal;
    Logger.fatal = noop;
}

export default Logger;
