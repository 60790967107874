import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';

import eyeOpen from 'app/assets/new-design/images/icons/icon-eye-open.svg';
import eyeClosed from 'app/assets/new-design/images/icons/icon-eye-closed.svg';

const EyeIcon = ({ isOpened, productIdentity }) => {
    if (isOpened) {
        return (
            <InlineSVG
                src={productIdentity ? eyeOpen : require('icons/icon-eye-open.svg')}
                className="eye-icon--opened"
            />
        );
    }

    return (
        <InlineSVG
            src={productIdentity ? eyeClosed : require('icons/icon-eye-closed.svg')}
            className="eye-icon--closed"
        />
    );
};

EyeIcon.propTypes = {
    isOpened: PropTypes.bool.isRequired,
    productIdentity: PropTypes.bool,
};

export default EyeIcon;
