import reducerRegistry from '../../reducers/ReducerRegistry';
import { navigationReducer } from './reducers';

export { switchAssembly, switchDistribution, updateTheme, updateActiveLink } from './actions';

export {
    currentAssemblySelector,
    currentDistributionSelector,
    activeLinkSelector,
} from './selectors';

reducerRegistry.register({
    navigation: navigationReducer,
});
