import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import connectToI18n from 'modules/i18n/connectToI18n';
import Button from './Button.jsx';

export class LimitOverflowingContent extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        trans: PropTypes.func.isRequired,
        className: PropTypes.string,
        collapsible: PropTypes.bool,
        expandKey: PropTypes.string,
        collapseKey: PropTypes.string,
        maxHeight: PropTypes.string,
        background: PropTypes.oneOf(['white', 'bgLight70']),
    };

    static defaultProps = {
        collapsible: false,
        expandKey: 'global.readMore',
        collapseKey: 'global.collapse',
        maxHeight: '6em',
    };

    state = {
        isExpanded: false,
        isOverflowing: false,
    };

    componentDidMount() {
        this.updateOverflowingState();
    }

    componentDidUpdate() {
        this.updateOverflowingState();
    }

    isOverflowing = () => {
        const el = ReactDOM.findDOMNode(this).firstChild;
        return el.scrollHeight > el.offsetHeight;
    };

    updateOverflowingState = () => {
        const isOverflowing = this.isOverflowing();
        if (this.state.isOverflowing !== isOverflowing) {
            this.setState({ isOverflowing });
        }
    };

    collapse = () => {
        this.setState({
            isExpanded: false,
        });
    };

    expand = () => {
        this.setState({
            isExpanded: true,
            isOverflowing: false,
        });
    };

    renderButton = baseClassName => {
        const { collapsible, collapseKey, expandKey, trans } = this.props;

        const { isOverflowing, isExpanded } = this.state;

        if (!isOverflowing && (!isExpanded || !collapsible)) {
            return null;
        }

        if (isExpanded && collapsible) {
            return (
                <Button
                    className={classnames(
                        `${baseClassName}-button`,
                        `${baseClassName}-button--collapse`
                    )}
                    type="button"
                    weight="link"
                    onClick={this.collapse}
                >
                    <i className="icon icon-angle-up" /> {trans(collapseKey)}
                </Button>
            );
        }

        return (
            <Button
                className={classnames(`${baseClassName}-button`, `${baseClassName}-button--expand`)}
                type="button"
                weight="link"
                onClick={this.expand}
            >
                <i className="icon icon-angle-down" /> {trans(expandKey)}
            </Button>
        );
    };

    render() {
        const baseClassName = 'overflowing';

        const classNames = classnames(
            baseClassName,
            {
                [`${baseClassName}--expanded`]: this.state.isExpanded,
                [`${baseClassName}--overflowing`]: this.state.isOverflowing,
                [`${baseClassName}--${this.props.background}`]: this.props.background,
            },
            this.props.className
        );

        let styles;
        if (!this.state.isExpanded) {
            styles = { maxHeight: this.props.maxHeight };
        }

        return (
            <div className={classNames}>
                <div className={`${baseClassName}-content`} style={styles}>
                    <div>{this.props.children}</div>
                </div>
                <div className={`${baseClassName}-trigger`}>{this.renderButton(baseClassName)}</div>
            </div>
        );
    }
}

export default connectToI18n(LimitOverflowingContent);
