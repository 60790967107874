import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { filerPhoto } from 'modules/utils/filer';

import PIText from 'components/ProductIdentity/Text.jsx';

import PriceAndPromotion from 'components/Sale/PriceAndPromotion.jsx';
import IncrementButton from 'src/components/atoms/Button/IncrementButton.jsx';

export const BUTTON_LARGE = 'large';
export const BASKET_TEMPLATE = 'basket';

const BasketOfferTemplate = React.memo(
    ({
        photo,
        buttonSize,
        offer,
        handleAddBasket,
        handleRemoveBasket,
        formattedQuantities,
        pricePerQuantity,
        quantityInBasket,
        productName,
        onClick,
    }) => {
        const disabled = useMemo(() => !handleAddBasket && !handleRemoveBasket, [
            handleRemoveBasket,
            handleAddBasket,
        ]);
        return (
            <div
                onClick={onClick}
                className={classNames('pi-offer-container', 'basket-template', {
                    hover: !!onClick,
                })}
            >
                <img src={filerPhoto(photo, 'small')} />
                <div className="pi-offer-container-informations">
                    <div>
                        <PIText
                            className="pi-offer-product-name"
                            family="inter"
                            size="14px"
                            color="gray2"
                            lineHeight="20px"
                            bold
                        >
                            {productName}
                        </PIText>
                        <PIText
                            className="pi-product-offer-price"
                            family="inter"
                            color="gray2"
                            bold
                            size="16px"
                            lineHeight="20px"
                        >
                            <PriceAndPromotion offer={offer} />
                        </PIText>
                    </div>
                    <div>
                        <PIText family="inter" size="14px" color="gray2" lineHeight="16px">
                            {formattedQuantities}
                        </PIText>
                    </div>
                    <div>
                        <PIText
                            className="pi-offer-price-per-quantity"
                            family="inter"
                            color="gray9"
                            size="12px"
                            lineHeight="14px"
                        >
                            {pricePerQuantity}
                        </PIText>
                        {disabled ? (
                            <PIText
                                className="pi-product-offer-price"
                                family="inter"
                                color="gray9"
                                size="14px"
                                lineHeight="20px"
                            >
                                X {quantityInBasket}
                            </PIText>
                        ) : (
                            <IncrementButton
                                large={buttonSize === BUTTON_LARGE}
                                onAdd={handleAddBasket}
                                onRemove={handleRemoveBasket}
                                count={quantityInBasket}
                            ></IncrementButton>
                        )}
                    </div>
                </div>
            </div>
        );
    }
);

BasketOfferTemplate.propTypes = {
    photo: PropTypes.object,
    offer: PropTypes.object.isRequired,
    buttonSize: PropTypes.oneOf([BUTTON_LARGE, '']),
    handleAddBasket: PropTypes.func,
    handleRemoveBasket: PropTypes.func,
    productName: PropTypes.string,
    formattedQuantities: PropTypes.string,
    pricePerQuantity: PropTypes.string,
    quantityInBasket: PropTypes.number,
    onClick: PropTypes.func,
};

BasketOfferTemplate.defaultProps = {
    showProducer: true,
    buttonSize: '',
    onClick: null,
};

export default BasketOfferTemplate;
