/**
 * Exports an object that is empty that should never get modified
 */
const emptyObject = {};

if (___DEV___) {
    // In strict mode this will throw at any attempt to
    // add, edit or remove properties
    Object.freeze(emptyObject);
}

export default emptyObject;
