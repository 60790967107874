import React from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { forgetCampaign, getPersistedCampaign } from 'modules/campaignTracker';
import { registerWithAnonymousBasket } from 'api/users';
import { grant } from 'modules/oauth';
import { loadCurrentUser } from 'modules/currentUser';

import useI18n from 'hooks/I18n/useI18n';
import useAnalytics from 'hooks/Analytics/useAnalytics.js';

import FormRegisterCheckout from './FormRegisterCheckout.jsx';
import LayoutCheckoutPayment from 'components/Checkout/Anonymous/LayoutCheckoutPayment.jsx';
import Heading from 'components/Heading.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import Link from 'src/components/atoms/Link/Link.jsx';
import { STEP_INFORMATIONS_ANONYMOUS } from 'components/Checkout/ProductIdentity/CheckoutTimeline.jsx';

const Account = props => {
    const { basketUuid } = props;
    const { analytics } = useAnalytics();
    const { trans } = useI18n();
    const methods = useForm();
    const dispatch = useDispatch();
    const { setError, handleSubmit } = methods;
    const basket = useSelector(state => state.checkout.basket);
    const onSubmit = datas => {
        const data = {
            basketUuid: basket.uuid,
            lastName: datas.lastName,
            firstName: datas.firstName,
            plainPassword: datas.plainPassword,
            email: datas.email,
            address: {
                street: datas.street,
                city: datas.cityId,
            },
            phone: datas.phone,
            hasAcceptedTOS: datas.hasAcceptedTOS,
            birthday: datas.birthday,
            nationality: datas.nationality,
            accountSourceApp: 'laruchequiditoui',
            accountSourcePage: 'checkout',
        };

        const campaign = getPersistedCampaign();

        if (campaign) {
            data.marketingCampaign = campaign;
        }

        registerWithAnonymousBasket(data)
            .then(() => {
                analytics.registerFromAnonymousPayment();

                forgetCampaign();
                grant('password', {
                    username: data.email,
                    password: data.plainPassword,
                    remember: true,
                })
                    .done(() => {
                        dispatch(
                            loadCurrentUser({
                                redirectUrl: `basket?fromAnonymousBasket=${basketUuid}`,
                            })
                        );
                    })
                    .fail(() => {});
            })
            .catch(error => {
                const serverErrors = error.responseJSON.detail.errors;
                Object.entries(serverErrors).forEach(([name, fieldError]) => {
                    if (name === 'address') {
                        Object.entries(fieldError).forEach(([addressField, addressFieldError]) =>
                            setError(addressField, { message: addressFieldError.join('\n') })
                        );
                        return;
                    }

                    setError(name, { message: fieldError.join('\n') });
                });
            });
    };

    return (
        <div className="nd-checkout-create-account-page">
            <LayoutCheckoutPayment
                deliveryAmount={basket.shippingFees.amount}
                finalAmount={basket.totalPrice.amount}
                amount={basket.totalPriceWithoutShipping.amount}
                onClick={handleSubmit(onSubmit)}
                currency={basket.totalPrice.currency}
                buttonLabel={trans('deliveryPage.summary.continue')}
                currentStep={STEP_INFORMATIONS_ANONYMOUS}
                distributionId={basket.distribution.id}
                assemblyId={basket.distribution.hiveId}
            >
                <div className="nd-checkout-create-account-header">
                    <Heading productIdentity size={4}>
                        {trans('checkout.anonymous.registration.title')}
                    </Heading>
                    <Text size="16px" lineHeight="24px" weight="400" color="gray2">
                        {trans('checkout.anonymous.registration.account.existing')}
                        <Link to="login">{trans('login.login')}</Link>
                    </Text>
                </div>
                <FormProvider {...methods}>
                    <FormRegisterCheckout basketUuid={basketUuid} />
                </FormProvider>
            </LayoutCheckoutPayment>
        </div>
    );
};

Account.propTypes = {
    basketUuid: PropTypes.string,
};
export default Account;
