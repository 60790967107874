import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import Text from 'components/ProductIdentity/Text.jsx';
import { getReduction } from 'models/offers';

export const MODE_FILL = 'fill';
export const MODE_PARENTHESIS = 'parenthesis';
export const MODE_OUTLINE = 'outline';

export const Discount = ({ className, offer, mode }) => {
    const classNames = classnames('reduction discount', className, mode);

    const getPromotionPercentage = () => {
        const percentage = `-${getReduction(offer)}%`;

        if (mode === MODE_PARENTHESIS) {
            return `(${percentage})`;
        }

        return percentage;
    };

    return (
        <div className={classNames}>
            <Text size="14px" lineHeight="16px" weight="500" color="white">
                {getPromotionPercentage()}
            </Text>
        </div>
    );
};

Discount.propTypes = {
    className: PropTypes.string,
    offer: PropTypes.object.isRequired,
    mode: PropTypes.string,
};

Discount.defaultProps = {
    mode: MODE_FILL,
};

export default Discount;
