/**
 * Serialize values from a DOMNode (should probably be an input)
 * @param  {DOMNode} el The element you want to serialize
 * @return {object}     The couple { object, value } of the serialized field
 *
 * @note Needs an Array.from es6 polyfill
 * because of destructuring of NodeList DOM stuff
 */
export function serializeValueFromDOMNode(/* DOMNode */ el) {
    const name = el.name;
    const type = el.type;
    let value;

    if (type === 'select-multiple') {
        value = [...el.options].filter(option => option.selected).map(option => option.value);
    } else if (type === 'checkbox') {
        // if multiple checkboxes had the same name it won't return a checkbox
        // it will return a list ... thus it should not have a type
        // REVERSE DUCK TYPING FTW \o/
        if (el.form && el.form.elements[name].type !== 'checkbox') {
            value = [...el.form.elements[name]]
                .filter(checkBox => checkBox.checked)
                .map(checkBox => checkBox.value);
        } else {
            value = el.checked;
        }
    } else {
        // case for select-simple (no multiple), radio, textarea, input (text and such)
        value = el.value;
    }

    return {
        name,
        value,
    };
}

export default serializeValueFromDOMNode;
