import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const LINK_MODE = 'link';
export const LINK_BLUE_MODE = 'link blue';
export const SECONDARY_MODE = 'secondary';
export const SMALL_SIZE = 'small';
export const XSMALL_SIZE = 'xsmall';
export const XXSMALL_SIZE = 'xxsmall';
export const MEDIUM_SIZE = 'medium';
export const BIG_SIZE = 'big';
const Button = forwardRef(
    ({ children, className, size, mode, block, underline, ...props }, ref) => {
        const cls = classnames('nd-button', className, size, mode, { block, underline });
        return (
            <button ref={ref} {...props} className={cls}>
                {children}
            </button>
        );
    }
);

Button.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    size: PropTypes.oneOf([SMALL_SIZE, MEDIUM_SIZE, BIG_SIZE, XSMALL_SIZE, XXSMALL_SIZE]),
    className: PropTypes.string,
    mode: PropTypes.oneOf([LINK_MODE, SECONDARY_MODE]),
    block: PropTypes.bool,
    underline: PropTypes.bool,
};

Button.defaultProps = {
    className: '',
    mode: null,
    block: false,
    underline: false,
};
export default Button;
