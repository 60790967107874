import useI18n from 'hooks/I18n/useI18n';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import LoginForm from 'components/Auth/LoginForm.jsx';
import AppChannel from 'modules/channels/App';
import emptyObject from 'modules/utils/emptyObject';
import { READY, PROCESSING } from 'modules/utils/ajaxStatuses';
import { validate as validateModule, validators } from 'modules/validation';
import Impersonate from 'modules/impersonate';
import {
    isPasswordGrantSuspended,
    passwordGrantSuspensionDelayInMilliseconds,
    hasPasswordGrantFailedTwice,
} from 'modules/oauth';

const BaseLoginContainer = ({ assemblyId, handleSubmit, showRememberMe, embedded, hasTitle }) => {
    const { trans } = useI18n();
    const [status, setStatus] = useState(READY);
    const [showErrors, setShowErrors] = useState(false);
    const [formClientErrors, setFormClientErrors] = useState(emptyObject);
    const [authenticationProperties, setAuthenticationProperties] = useState({
        authenticationError: false,
        isLoginSuspended: false,
        showForgotPasswordNotice: false,
    });
    const suspensionTimeoutRef = useRef(null);

    const errorRefreshForm = () => {
        const isLoginSuspended = isPasswordGrantSuspended();

        setStatus(READY);
        setAuthenticationProperties({
            authenticationError: true,
            isLoginSuspended,
            showForgotPasswordNotice: !isLoginSuspended && hasPasswordGrantFailedTwice(),
        });

        if (isLoginSuspended) {
            suspensionTimeoutRef.current = setTimeout(() => {
                setAuthenticationProperties({
                    authenticationError: false,
                    isLoginSuspended: false,
                    showForgotPasswordNotice: false,
                });
            }, passwordGrantSuspensionDelayInMilliseconds);
        }
    };

    useEffect(() => {
        AppChannel.vent.on('auth:error', errorRefreshForm);

        return () => {
            AppChannel.vent.off('auth:error', errorRefreshForm);
            if (suspensionTimeoutRef.current) {
                clearTimeout(suspensionTimeoutRef.current);
            }
        };
    }, []);

    const validate = fields => {
        const { assertNotBlank, assertValidEmail } = validators(trans);

        const [isValid, errors] = validateModule.all([
            validateModule.prop('email', assertValidEmail),
            validateModule.prop('password', assertNotBlank),
        ])(fields);

        setFormClientErrors(errors);

        return isValid;
    };

    const localHandleSubmit = fields => {
        setShowErrors(true);

        const isFormValid = validate(fields);

        if (isFormValid) {
            setStatus(PROCESSING);
            handleSubmit(fields);
        }
    };

    return (
        <LoginForm
            {...authenticationProperties}
            status={status}
            impersonatedEmail={Impersonate.getImpersonated()}
            showErrors={showErrors}
            formClientErrors={formClientErrors}
            assemblyId={assemblyId}
            handleSubmit={localHandleSubmit}
            handleEdit={validate}
            showRememberMe={showRememberMe}
            embedded={embedded}
            hasTitle={hasTitle}
        />
    );
};

BaseLoginContainer.propTypes = {
    assemblyId: PropTypes.number,
    handleSubmit: PropTypes.func.isRequired,
    showRememberMe: PropTypes.bool.isRequired,
    embedded: PropTypes.bool,
    hasTitle: PropTypes.bool,
};

export default BaseLoginContainer;
