import { get, post, patch } from 'modules/api';

/**
 * Get basket by id
 *
 * @param {string} basketUuid
 * @returns
 */
export const fetchAnonymousBasket = basketUuid => get(`baskets/${basketUuid}`);

/**
 * Get list of baskets
 *
 * @param {Array<string>} basketUuids
 * @returns {Promise}
 */
export const fetchAnonymousBaskets = basketUuids => get('baskets', { uuid: basketUuids });

/**
 * Claim anonymous user basket
 *
 * @param {string} basketUuid
 * @returns {Promise<Object>}
 */
export const claimAnonymousBasket = basketUuid => post(`baskets/${basketUuid}/claim`);

/**
 *
 * @param {string} basketUuid
 * @param {Object} dataToSend
 * @returns {Promise}
 */
export const submitBasketDeliveryOption = (basketUuid, dataToSend) =>
    patch(`baskets/${basketUuid}/delivery`, dataToSend);

/**
 *
 * @param {string} basketUuid
 * @param {number} _.offerId
 * @param {number} _.quantity
 * @param {string} _.searchQueryId
 * @returns {Promise}
 */
export const addBasketOffer = (basketUuid, { offerId, quantity, searchQueryId }) =>
    post(`baskets/${basketUuid}/items`, {
        offer: offerId,
        quantity,
        search_query_id: searchQueryId,
    });

/**
 * Update the quantity of offer in basket
 *
 * @param {string} basketUuid
 * @param {number} offerId
 * @param {number} _.quantity
 * @param {string} _.searchQueryId
 * @returns {Promise}
 */
export const updateBasketOfferQuantity = (basketUuid, offerId, { quantity, searchQueryId }) =>
    patch(`baskets/${basketUuid}/items/${offerId}`, {
        quantity,
        search_query_id: searchQueryId,
    });
