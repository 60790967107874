export function setItem(key, value) {
    try {
        localStorage.setItem(key, value); // eslint-disable-line no-restricted-globals
    } catch (e) {
        // Could not store. The user is probably in private mode
    }
}

export function getItem(key) {
    try {
        return localStorage.getItem(key); // eslint-disable-line no-restricted-globals
    } catch (e) {
        return null;
        // Could not store. The user is probably in private mode
    }
}

export function removeItem(key) {
    try {
        localStorage.removeItem(key); // eslint-disable-line no-restricted-globals
    } catch (e) {
        // Could not store. The user is probably in private mode
    }
}

// To standardize the notion of 'true' in local storage
export const LOCAL_STORAGE_TRUE = '1';

export const IS_ANDROID_TWA = 'isAndroidTWA';

export const IS_NOTIFY_WEBPUSH_PERMISSION_ERROR = 'inwpe';
