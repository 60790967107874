import React from 'react';
import Link from 'src/components/atoms/Link/Link.jsx';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { userSelector } from 'modules/currentUser';
import { filerPhoto } from 'modules/utils/filer';
import useI18n from 'hooks/I18n/useI18n';
import Heading from 'components/Heading.jsx';
import Text from 'components/ProductIdentity/Text.jsx';

const FarmsSection = ({ farmsGallery, assemblyId }) => {
    const { trans, i18n } = useI18n();
    const currentUser = useSelector(userSelector);
    return (
        <div className="pi-farms-block">
            <Heading size={4} productIdentity>
                {trans('hiveHome.farmsSection.title')}
            </Heading>
            <Text size="16px" lineHeight="24px" className="pi-farms-text">
                {trans('hiveHome.farmsSection.text')}
            </Text>
            <Text size="14px" lineHeight="20px" className="pi-farms-link">
                <Link
                    bypassRouter
                    href={
                        currentUser.anonymous
                            ? `/${i18n}/assemblies/${assemblyId}/producers`
                            : `/${i18n}/assemblies/${assemblyId}/farms`
                    }
                >
                    {trans('hives.providers')}
                </Link>
            </Text>
            <div className="pi-farms-photos">
                {farmsGallery.map(farmPhoto => {
                    return (
                        <img
                            key={farmPhoto.id}
                            className="pi-farm-photo"
                            src={filerPhoto(farmPhoto.id, 'small', 'user')}
                        />
                    );
                })}
            </div>
        </div>
    );
};

FarmsSection.propTypes = {
    farmsGallery: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
        })
    ).isRequired,
    assemblyId: PropTypes.number.isRequired,
};

export default FarmsSection;
