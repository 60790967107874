import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { fetchHivesNearFarm } from 'api/hives';
import connectToI18n from 'modules/i18n/connectToI18n';

import { acceptInvitation, declineInvitation } from 'api/invitations';
import { sendProposal } from 'api/proposals';

import { Notice, Button, Box } from 'components/ui';
import ProposalPanel from 'components/ProducerDashboard/ProposalPanel.jsx';
import Utils from 'modules/utils';
const { linkTo } = Utils;
import { INITIAL_LOADING, READY, PROCESSING, FAILED } from 'modules/utils/ajaxStatuses';

function getRelationshipStatus(user, { id: assemblyId }) {
    if (user.hivesAsFarmer.some(hive => hive.id === assemblyId)) {
        return Promise.resolve({ relationshipStatus: 'alreadyProviding' });
    }

    return fetchHivesNearFarm(user.farm[0].id)
        .then(({ hives }) => hives.find(assembly => assembly.id === assemblyId) || {})
        .then(({ proposal, invitation }) => {
            if (proposal && proposal.status === 'pending_validation') {
                return { relationshipStatus: 'proposalPending' };
            }
            if (invitation && invitation.status === 'pending_validation') {
                return {
                    relationshipStatus: 'invitationPending',
                    invitationId: invitation.id,
                };
            }
            return { relationshipStatus: 'notProvidingYet' };
        });
}

export class ProducerNotice extends React.Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        assembly: PropTypes.object.isRequired,
        trans: PropTypes.func.isRequired,
    };

    state = {
        status: INITIAL_LOADING,
        relationshipStatus: null,
        invitationId: null,
        isProposalPanelOpen: false,
        isProposalSaving: false,
        deliveryMeanOption: 'dropping',
    };

    componentDidMount() {
        getRelationshipStatus(this.props.user, this.props.assembly).then(
            ({ relationshipStatus, invitationId }) => {
                this.setState({ relationshipStatus, invitationId, status: READY });
            }
        );
    }

    sendProposal = (assemblyId, message, deliveryMean = null) => {
        this.setState({ status: PROCESSING, isProposalSaving: true });

        return sendProposal({
            assemblyId,
            farmId: this.props.user.farm[0].id,
            message,
            deliveryMean,
        })
            .then(() => {
                this.setState({
                    status: READY,
                    relationshipStatus: 'proposalPending',
                    isProposalPanelOpen: false,
                    isProposalSaving: false,
                });
            })
            .catch(() => {
                this.setState({ status: FAILED });
            });
    };

    openProposalPanel = () => {
        this.setState({ isProposalPanelOpen: true });
    };

    closeProposalPanel = () => {
        this.setState({ isProposalPanelOpen: false });
    };

    setProposalAsPending = () => {
        this.setState({
            relationshipStatus: 'proposalPending',
            isProposalPanelOpen: false,
        });
    };

    acceptInvitation = () => {
        acceptInvitation(this.state.invitationId).then(() => {
            this.setState({ relationshipStatus: 'alreadyProviding' });
        });
    };

    declineInvitation = () => {
        declineInvitation(this.state.invitationId).then(() => {
            this.setState({ relationshipStatus: 'notProvidingYet' });
        });
    };

    render() {
        const { assembly, trans } = this.props;

        if (this.state.status === INITIAL_LOADING) {
            return <Box className="u-mb-4">{trans('global.loading')}</Box>;
        }

        let message;
        let action = null;

        switch (this.state.relationshipStatus) {
            case 'alreadyProviding':
                message = trans('farms.hive.status.alreadyProviding');
                action = (
                    <Button href={linkTo('producers/me/collections')}>
                        {trans('distributions.followSalesTitle')}
                    </Button>
                );
                break;
            case 'invitationPending':
                message = trans('farms.hive.status.invitationPending');
                action = (
                    <div>
                        <Button
                            variant="danger"
                            className="u-mr-2"
                            onClick={this.declineInvitation}
                        >
                            {trans('global.deny')}
                        </Button>
                        <Button variant="success" onClick={this.acceptInvitation}>
                            {trans('global.accept')}
                        </Button>
                    </div>
                );
                break;
            case 'proposalPending':
                message = trans('farms.hive.status.proposalPending');
                action = (
                    <Button dirty={false} disabled block variant="success">
                        {trans('hives.horizontalBox.proposal.sent')}
                    </Button>
                );
                break;
            case 'notProvidingYet':
            default:
                message = trans('farms.hive.status.notProvidingYet');
                action = (
                    <Button onClick={this.openProposalPanel}>
                        {trans('hives.horizontalBox.suggestMyProducts')}
                    </Button>
                );
        }

        return (
            <Fragment>
                <Notice className="u-mb-4 producerStatus" action={action}>
                    {message}
                </Notice>
                {this.state.isProposalPanelOpen && (
                    <ProposalPanel
                        assembly={assembly}
                        farmId={this.props.user.farm[0].id}
                        farmCoordinates={this.props.user.farm[0].address.coordinates}
                        onClose={this.closeProposalPanel}
                        setProposalAsPending={this.setProposalAsPending}
                    />
                )}
            </Fragment>
        );
    }
}

export default connectToI18n(ProducerNotice);
