import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ children, ...props }) => (
    <label className="nd-label" {...props}>
        {children}
    </label>
);

Label.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default Label;
