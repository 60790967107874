import PropTypes from 'prop-types';
import React from 'react';

export class AutocompleteItem extends React.Component {
    static propTypes = {
        isSelected: PropTypes.bool,
        children: PropTypes.node.isRequired,
        onMouseDown: PropTypes.func.isRequired,
        data: PropTypes.object.isRequired,
    };

    onMouseDown = () => {
        this.props.onMouseDown(this.props.data);
    };

    render() {
        return React.cloneElement(this.props.children, {
            onMouseDown: this.onMouseDown,
        });
    }
}

export default AutocompleteItem;
