import PropTypes from 'prop-types';
import React from 'react';
import HelpCenterContainer from './HelpCenter/HelpCenterContainer.jsx';
import { HELP_PANEL } from 'modules/visiblePanel.js';
import StructuredSidePanel from 'components/StructuredSidePanel.jsx';
import useI18n from 'hooks/I18n/useI18n.js';

const HelpBottomDropdown = ({ visiblePanel, currentAssembly, farm, currentUser }) => {
    const { trans } = useI18n();
    const isHelpPanelActive = visiblePanel === HELP_PANEL;

    return (
        <StructuredSidePanel isOpened={isHelpPanelActive} title={trans('menu.help.title')}>
            <HelpCenterContainer
                user={currentUser}
                currentAssembly={currentAssembly || null}
                farm={farm}
            />
        </StructuredSidePanel>
    );
};

HelpBottomDropdown.propTypes = {
    visiblePanel: PropTypes.string,
    currentAssembly: PropTypes.object,
    farm: PropTypes.object,
    currentUser: PropTypes.object,
};

export default HelpBottomDropdown;
