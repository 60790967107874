export const BANNER_TRANSLATION_KEY = {
    'good-deals--allTags': {
        title: 'salePage.banner.good-deals.all-tags.title',
        text: 'salePage.banner.good-deals.all-tags.text',
    },
    'good-deals--bulk-offer': {
        title: 'salePage.banner.good-deals.bulk-offer.title',
        text: 'salePage.banner.good-deals.bulk-offer.text',
    },
    'good-deals--anti_waste': {
        title: 'salePage.banner.good-deals.anti-waste.title',
        text: 'salePage.banner.good-deals.anti-waste.text',
    },
    'good-deals--engaged-price': {
        title: 'salePage.banner.good-deals.engaged-price.title',
        text: 'salePage.banner.good-deals.engaged-price.text',
    },
    'good-deals--to_be_discovered': {
        title: 'salePage.banner.good-deals.to-be-discovered.title',
        text: 'salePage.banner.good-deals.to-be-discovered.text',
    },
    'good-deals--custom-event-tag': {
        title: 'salePage.banner.custom-event-tag.title',
        text: 'salePage.banner.custom-event-tag.text',
    },
    christmas: {
        title: 'salePage.banner.custom-event-tag.title',
        text: 'salePage.banner.custom-event-tag.text',
    },
};
