import * as R from 'ramda';
import { localesArray } from 'modules/config/i18n';
import AppConfig from 'modules/config';

const findLanguageBy = criteria => value => R.find(R.propEq(criteria, value), localesArray);

export const findLanguageByShortcut = findLanguageBy('shortcut');

export const findLanguageByCode = findLanguageBy('code');

export const findLanguageBybCode = findLanguageBy('bCode');

export const isCurrentLanguage = language =>
    AppConfig.locale === language.shortcut || AppConfig.locale === language.code;

export const getActiveLanguage = () =>
    findLanguageByCode(AppConfig.locale) || findLanguageByShortcut(AppConfig.locale);
