import React from 'react';
import PropTypes from 'prop-types';

import useI18n from 'hooks/I18n/useI18n.js';

import PIText from 'components/ProductIdentity/Text.jsx';

const AverageDistanceTraveledContent = ({ distance, season }) => {
    const { trans } = useI18n();

    let titleTranslationKey = 'salePage.product.average.distance.variant1.title';
    let textTranslationKey = 'salePage.product.average.distance.variant1.text';

    if (distance >= 100 && distance < 1000) {
        titleTranslationKey = 'salePage.product.average.distance.variant2.title';
        textTranslationKey = 'salePage.product.average.distance.variant2.text';
    } else if (distance >= 1000) {
        titleTranslationKey = 'salePage.product.average.distance.variant3.title';
        textTranslationKey = 'salePage.product.average.distance.variant3.text';
    }

    return (
        <div className="pi-average-distance-traveled-text-block pi-average-distance-traveled-text-block">
            <PIText
                className="pi-average-distance-traveled-title"
                family="mr"
                size="16px"
                lineHeight="24px"
                bold
                color="grey8"
            >
                {trans(titleTranslationKey)}
            </PIText>
            {distance < 100 && (
                <PIText
                    className={`pi-average-distance-traveled-km--${season}`}
                    family="inter"
                    size="24px"
                    lineHeight="34px"
                    bold
                >
                    {trans('farms.pageHeader.distance', { '%count%': distance })}
                </PIText>
            )}
            <PIText family="inter" color="gray2" size="16px" lineHeight="24px">
                {trans(textTranslationKey, {
                    '%average%': distance,
                })}
            </PIText>
        </div>
    );
};

AverageDistanceTraveledContent.propTypes = {
    distance: PropTypes.number.isRequired,
    season: PropTypes.string.isRequired,
};

export default AverageDistanceTraveledContent;
