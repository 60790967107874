import environment from 'modules/utils/environment';

export const SANDBOX_MINIRUCHE_GA_APPID = 'UA-112655458-2';
export const PRODUCTION_MINIRUCHE_GA_APPID = 'UA-112655458-1';

export const addUniversalEvent = ({ eventCategory, eventAction, eventLabel, eventValue }) => {
    /* global dataLayer */
    /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
    dataLayer &&
        dataLayer.push &&
        dataLayer.push({
            event: 'UniversalEvent',
            eventCategory,
            eventAction,
            eventLabel,
            eventValue,
            gaAppId:
                environment === 'production'
                    ? PRODUCTION_MINIRUCHE_GA_APPID
                    : SANDBOX_MINIRUCHE_GA_APPID,
        });
};
