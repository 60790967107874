import PropTypes from 'prop-types';
import React from 'react';

const layoutClasses = [
    'u-bodySpacer--top',
    'u-bodySpacer--left',
    'u-bodySpacer--bottom',
    'layout-fixed-mobile',
    'layout-fixed',
];

function setBodyClasses(nextClasses) {
    const classList = document.body.classList;
    layoutClasses.forEach(className => classList.remove(className));
    nextClasses.forEach(className => classList.add(className));
}

function resetBodyClasses() {
    const classList = document.body.classList;
    layoutClasses.forEach(className => classList.remove(className));
}

export class ResponsiveNavigation extends React.Component {
    static propTypes = {
        isSmallWidth: PropTypes.bool,
        isNewNavigationLayout: PropTypes.bool,
        children: PropTypes.arrayOf(PropTypes.node).isRequired,
    };

    componentDidMount() {
        setBodyClasses(this.bodyClasses);
    }

    componentDidUpdate() {
        setBodyClasses(this.bodyClasses);
    }

    componentWillUnmount() {
        resetBodyClasses();
    }

    render() {
        const bodyClasses = [];
        let allowedPositions;

        // Legacy class names for compatibility, could be checked elsewhere to know the screen size.
        if (this.props.isSmallWidth) {
            allowedPositions = this.props.isNewNavigationLayout ? ['top'] : ['bottom'];
            bodyClasses.push('layout-fixed-mobile');
        } else {
            allowedPositions = ['top', 'left'];
            bodyClasses.push('layout-fixed');
        }

        const children = React.Children.toArray(this.props.children).filter(child => {
            return allowedPositions.indexOf(child.props.position) !== -1;
        });

        children.forEach(child => {
            bodyClasses.push(`u-bodySpacer--${child.props.position}`);
        });

        this.bodyClasses = bodyClasses;

        return <>{children}</>;
    }
}

export default ResponsiveNavigation;
