export const ADD_TOAST = 'ADD_TOAST';
export const DELETE_TOAST = 'DELETE_TOAST';
export const CLEAR_TOASTS = 'CLEAR_TOASTS';

export const addToast = toast => ({
    type: ADD_TOAST,
    payload: { ...toast },
});

export const deleteToast = toastId => ({
    type: DELETE_TOAST,
    payload: toastId,
});

export const clearToasts = () => ({
    type: CLEAR_TOASTS,
});
