import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export class FormGroup extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        noMargin: PropTypes.bool,
    };

    render() {
        const { noMargin } = this.props;
        const classes = classnames({ 'u-mb-4': !noMargin }, this.props.className);
        return <div className={classes}>{this.props.children}</div>;
    }
}

export default FormGroup;
