/* eslint-disable react/prop-types */
import React, { lazy, Suspense } from 'react';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import CompanyIdProvider from 'components/CompanyIdProvider.jsx';

const CompanyInformationsContainer = lazy(() =>
    import('components/CompanyInformations/CompanyInformationsContainer.jsx')
);

const EInvoicingInformationInItalyContainer = lazy(() =>
    import('components/CompanyInformations/EInvoicingInformationInItalyContainer.jsx')
);

const ProfessionalTaxContainer = lazy(() =>
    import('components/ProfessionalProfile/ProfessionalTaxContainer.jsx')
);

const LegalDocumentsContainer = lazy(() =>
    import('components/Documents/LegalDocumentsContainer.jsx')
);

export const professionalRoutes = [
    {
        definition: '/producers/company',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.farm.farmFolder',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <CompanyIdProvider userType="producer">
                    {({ companyId, user }) => (
                        <CompanyInformationsContainer companyId={companyId} user={user} />
                    )}
                </CompanyIdProvider>
            </Suspense>
        ),
    },
    {
        definition: '/producers/company/e-invoicing-information-in-italy',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.farm.farmFolder',
        context: 'farm',
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <EInvoicingInformationInItalyContainer userType="producer" />
            </Suspense>
        ),
    },
    {
        definition: '/producers/company/tax',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.farm.farmFolder',
        context: 'farm',
        exact: true,
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <CompanyIdProvider userType="producer">
                    {({ companyId, user }) => (
                        <ProfessionalTaxContainer
                            companyId={companyId}
                            userType="producer"
                            user={user}
                        />
                    )}
                </CompanyIdProvider>
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/tax',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.leader.configuration',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <CompanyIdProvider
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                    userType="host"
                >
                    {({ companyId, assembly, user }) => (
                        <ProfessionalTaxContainer
                            companyId={companyId}
                            assembly={assembly}
                            userType="host"
                            user={user}
                        />
                    )}
                </CompanyIdProvider>
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/company',
        conditions: ['isAuthenticated', 'isLeaderOfHive', 'hasHiveCompany'],
        activeLink: 'menu.leader.configuration',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <CompanyIdProvider
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                    userType="host"
                >
                    {({ companyId, assembly, user }) => (
                        <CompanyInformationsContainer
                            companyId={companyId}
                            assembly={assembly}
                            user={user}
                        />
                    )}
                </CompanyIdProvider>
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/company/e-invoicing-information-in-italy',
        conditions: ['isAuthenticated', 'isLeaderOfHive', 'hasHiveCompany'],
        activeLink: 'menu.leader.configuration',
        context: 'hive',
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <EInvoicingInformationInItalyContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                    userType="host"
                />
            </Suspense>
        ),
    },
    {
        definition: '/producers/companies/:companyId/documents',
        conditions: ['isAuthenticated', 'hasFarm'],
        activeLink: 'menu.farm.farmFolder',
        context: 'farm',
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <LegalDocumentsContainer companyId={parseInt(match.params.companyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/companies/:companyId/documents',
        conditions: ['isAuthenticated', 'isLeaderOfHive', 'hasHiveCompany'],
        activeLink: 'menu.leader.configuration',
        context: 'hive',
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <LegalDocumentsContainer
                    companyId={parseInt(match.params.companyId, 10)}
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                />
            </Suspense>
        ),
    },
];
