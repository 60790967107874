import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InlineSVG from 'svg-inline-react';
import Text from 'components/ProductIdentity/Text.jsx';
import checkIcon from 'app/assets/new-design/images/icons/validate.svg';

const STEP_PROCESS = 'process';
const STEP_FINISH = 'finish';
const STEP_WAIT = 'wait';

const Steps = ({ current, items }) => {
    const statusStep = useCallback(
        step => {
            if (current === step) {
                return STEP_PROCESS;
            }
            return step < current ? STEP_FINISH : STEP_WAIT;
        },
        [current]
    );
    return (
        <div className="nd-steps">
            {items.map(({ label, onClick }, index) => {
                const stepStatus = statusStep(index + 1);
                return (
                    <div
                        className={classNames('nd-step', stepStatus)}
                        key={label}
                        onClick={stepStatus !== STEP_WAIT ? onClick : null}
                    >
                        <div className="nd-step-icon">
                            {stepStatus === STEP_FINISH ? (
                                <InlineSVG raw src={checkIcon}></InlineSVG>
                            ) : (
                                <Text size="10px" lineHeight="14px" color="grey8" weight={500}>
                                    {index + 1}
                                </Text>
                            )}
                        </div>
                        <Text
                            className="nd-step-label"
                            size="12px"
                            lineHeight="20px"
                            color="grey8"
                            weight={500}
                        >
                            {label}
                        </Text>
                    </div>
                );
            })}
        </div>
    );
};
Steps.propTypes = {
    current: PropTypes.number,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            onClick: PropTypes.func,
        })
    ),
};
export default Steps;
