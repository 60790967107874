import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';

const splitAddressString = address =>
    address.split(', ').filter(addressPart => addressPart !== 'France');

export class Address extends PureComponent {
    static propTypes = {
        address: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                street: PropTypes.string.isRequired,
                city: PropTypes.shape({
                    zipCode: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                }).isRequired,
            }),
        ]).isRequired,
        name: PropTypes.string,
        className: PropTypes.string,
    };

    render() {
        const { address, name } = this.props;

        return (
            <div className={classnames('address', this.props.className)}>
                {name && <div className="address-name">{name}</div>}
                {typeof address === 'string' ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: splitAddressString(address).join('<br/>'),
                        }}
                    />
                ) : (
                    <Fragment>
                        <div className="address-street">{address.street}</div>
                        <div className="address-city">
                            <span className="address-zipCode">{address.city.zipCode}</span>{' '}
                            <span className="address-cityName">{address.city.name}</span>
                        </div>
                    </Fragment>
                )}
            </div>
        );
    }
}

export default Address;
