import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import connectToI18n from 'modules/i18n/connectToI18n';
import FormGroup from './FormGroup.jsx';
import InputErrors from './InputErrors.jsx';
import AutocompleteItemsList from 'components/AutocompleteItemsList.jsx';
import EyeIcon from 'components/Auth/EyeIcon.jsx';
import Text from 'components/Text.jsx';

export class TextInputGroup extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        label: PropTypes.string,
        value: PropTypes.string.isRequired,
        isRequired: PropTypes.bool,
        placeholder: PropTypes.string,
        showErrors: PropTypes.bool,
        clientErrors: PropTypes.array,
        serverErrors: PropTypes.array,
        className: PropTypes.string,
        children: PropTypes.element,
        onChange: PropTypes.func.isRequired,
        onItemSelected: PropTypes.func,
        onBlur: PropTypes.func,
        onKeyDown: PropTypes.func,
        type: PropTypes.oneOf(['password', 'text', 'tel']).isRequired,
        isDisabled: PropTypes.bool,
        inputVariant: PropTypes.oneOf(['inverse']),
        trans: PropTypes.func.isRequired,
        autocomplete: PropTypes.bool,
        options: PropTypes.array,
        ItemComponent: PropTypes.func,
        noMargin: PropTypes.bool,
        withEye: PropTypes.bool,
        hideLabelOnSmallScreen: PropTypes.bool,
        maxLength: PropTypes.number,
        productIdentity: PropTypes.bool,
    };

    static defaultProps = {
        inputVariant: null,
        type: 'text',
        productIdentity: false,
    };

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    state = {
        isEyeOpened: true,
    };

    handleChange = event => {
        const input = event.target;
        this.props.onChange(this.props.name, input.value);
    };

    switchVisibilityPassword = e => {
        e.preventDefault();
        if (this.inputRef.current.type === 'password') {
            this.inputRef.current.type = 'text';
            this.setState({ isEyeOpened: false });
        } else {
            this.inputRef.current.type = 'password';
            this.setState({ isEyeOpened: true });
        }
    };

    render() {
        const {
            name,
            label,
            value,
            showErrors,
            clientErrors,
            serverErrors,
            isRequired,
            placeholder,
            isDisabled,
            inputVariant,
            trans,
            type,
            onBlur,
            autocomplete,
            options,
            onItemSelected,
            ItemComponent,
            onKeyDown,
            noMargin,
            withEye,
            hideLabelOnSmallScreen,
            maxLength,
            productIdentity,
        } = this.props;

        const { isEyeOpened } = this.state;

        return (
            <FormGroup
                className={classnames('textInputGroup', this.props.className)}
                noMargin={noMargin}
            >
                {label && (
                    <label
                        htmlFor={name}
                        className={classnames({
                            'hidden-xs': hideLabelOnSmallScreen,
                            'fa-input--disabled': isDisabled,
                            'nd-label': productIdentity,
                        })}
                    >
                        {label}
                        {isRequired && (
                            <span className="required-label">{trans('global.required')}</span>
                        )}
                    </label>
                )}

                <input
                    ref={this.inputRef}
                    type={type}
                    id={name}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    onChange={this.handleChange}
                    className={classnames('fa-input fa-input--block', {
                        'fa-input--error':
                            showErrors &&
                            ((clientErrors && clientErrors.length > 0) ||
                                (serverErrors && serverErrors.length > 0)),
                        'fa-input--inverse': inputVariant === 'inverse',
                        'nd-input': productIdentity,
                    })}
                    disabled={isDisabled}
                    placeholder={placeholder}
                    autoComplete={autocomplete ? 'off' : 'on'}
                    maxLength={maxLength}
                />

                {withEye && type === 'password' && (
                    <span
                        className={classnames('eyePointer', {
                            'eyePointer--opened': isEyeOpened,
                        })}
                        onClick={this.switchVisibilityPassword}
                    >
                        <EyeIcon isOpened={isEyeOpened} productIdentity={productIdentity} />
                    </span>
                )}

                {autocomplete && (
                    <AutocompleteItemsList
                        key={value}
                        className="hostFollowSale-autocompleteSuggestions"
                        itemsData={options}
                        onItemSelected={onItemSelected}
                        isVisible={!!options.length}
                        ItemComponent={ItemComponent}
                        searchInput={value}
                    />
                )}
                {maxLength && (
                    <Text className="u-right" color="info" size="small">
                        {trans('global.chars.maxLength', { '%maxLength%': maxLength })}
                    </Text>
                )}
                <InputErrors
                    isVisible={showErrors}
                    clientErrors={clientErrors}
                    serverErrors={serverErrors}
                />
                {this.props.children}
            </FormGroup>
        );
    }
}

export default connectToI18n(TextInputGroup);
