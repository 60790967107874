import moment from 'moment';
import { Translator } from 'modules/i18n';

export function dateStringToDateObject(date) {
    if (!date) {
        return {
            day: '',
            month: '',
            year: '',
        };
    }
    const dateArray = date.split('-');
    return {
        day: dateArray[2] || '',
        month: dateArray[1] || '',
        year: dateArray[0] || '',
    };
}

export function dateStringOneFieldToDateObject(date) {
    if (!date) {
        return {
            day: '',
            month: '',
            year: '',
        };
    }
    const dateArray = date.split(' / ');
    return {
        day: dateArray[0] || '',
        month: dateArray[1] || '',
        year: dateArray[2] || '',
    };
}

export function dateObjectToDateString({ year, month, day }) {
    return `${year}-${month}-${day}`;
}

export function dateObjectOneFieldToDateString({ year, month, day }) {
    return `${day} / ${month} / ${year}`;
}

export function momentNow() {
    return moment();
}

export function getDayBeforeDate(date) {
    return moment(date)
        .subtract(24, 'hours')
        .toISOString();
}

export function isTodayOrAfter(date) {
    return momentNow()
        .startOf('day')
        .isSameOrBefore(moment(date));
}

export function getLocalizedWeekdayFromDayAsNumber(day, locale) {
    return [
        Translator.trans(locale, 'global.weekday.monday'),
        Translator.trans(locale, 'global.weekday.tuesday'),
        Translator.trans(locale, 'global.weekday.wednesday'),
        Translator.trans(locale, 'global.weekday.thursday'),
        Translator.trans(locale, 'global.weekday.friday'),
        Translator.trans(locale, 'global.weekday.saturday'),
        Translator.trans(locale, 'global.weekday.sunday'),
    ][day - 1];
}

export const removeSeconds = dateString => dateString.substr(0, dateString.length - 3);
