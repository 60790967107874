const LEFT_TYPE = '[';
const RIGHT_TYPE = ']';

function IntervalSymbol(symbol) {
    this.symbol = symbol;
    if (this.symbol !== LEFT_TYPE && this.symbol !== RIGHT_TYPE) {
        throw new Error(`Symbol ${this.symbol} not recognized`);
    }
}

IntervalSymbol.prototype.isLeftOpen = function() {
    return this.symbol === RIGHT_TYPE;
};

IntervalSymbol.prototype.isLeftClose = function() {
    return this.symbol === LEFT_TYPE;
};

IntervalSymbol.prototype.isRightOpen = function() {
    return this.symbol === LEFT_TYPE;
};

IntervalSymbol.prototype.isRightClose = function() {
    return this.symbol === RIGHT_TYPE;
};

export default IntervalSymbol;
