import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import Button from 'components/Button.jsx';
import Text from 'components/Text.jsx';
import { withRouter } from 'react-router';

export class NavigationBackButton extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        trans: PropTypes.func.isRequired,
        onClick: PropTypes.func,
        withSeparator: PropTypes.bool,
        classNames: PropTypes.string,
        history: PropTypes.object.isRequired,
    };

    back = () => {
        this.props.history.goBack();
    };

    render() {
        const { children, trans, withSeparator, ...props } = this.props;

        return (
            <Fragment>
                <Button
                    icon="angle-left"
                    weight="link"
                    onClick={this.props.onClick ? null : this.back}
                    {...props}
                >
                    {children || (
                        <Text classname="saleTopNavigation-backButtonText" size="large">
                            {trans('global.back')}
                        </Text>
                    )}
                </Button>
                {withSeparator && <div className="saleTopNavigation-backButtonSeparator" />}
            </Fragment>
        );
    }
}

export default withRouter(connectToI18n(NavigationBackButton));
