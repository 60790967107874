import { fetchSearchProduct } from 'api/products';

import { wait } from 'modules/utils/promise';

import { getSearchString } from './selectors';
import { actionTypes } from './index';

export const setSearchString = (distributionId, searchString) => dispatch => {
    const payload = {
        distributionId,
        searchString,
    };

    dispatch({
        type: actionTypes.SET_PRODUCTS_SEARCH.REQUEST,
        payload,
    });
};

export const clearSearch = distributionId => dispatch => {
    const payload = {
        distributionId,
    };

    dispatch({
        type: actionTypes.CLEAR_PRODUCTS_SEARCH,
        payload,
    });
};

export const searchProducts = (distributionId, searchString) => dispatch => {
    const payload = {
        distributionId,
        searchString,
    };

    const trimmedSearchString = searchString.trim();

    if (trimmedSearchString.length < 3) {
        const emptyResults = { products: [] };
        return dispatch({
            type: actionTypes.CLEAR_PRODUCTS_SEARCH,
            payload: {
                ...payload,
                response: emptyResults,
            },
        });
    }

    return fetchSearchProduct(trimmedSearchString, distributionId).then(
        response =>
            dispatch({
                type: actionTypes.SET_PRODUCTS_SEARCH.SUCCESS,
                payload: {
                    ...payload,
                    response,
                },
                analytics: ({ GA }) => {
                    GA.productSearch({
                        query: searchString,
                        resultCount: (response.products && response.products.length) || 0,
                    });
                },
            }),
        error =>
            dispatch({
                type: actionTypes.SET_PRODUCTS_SEARCH.FAILURE,
                payload: {
                    ...payload,
                    error: error.message,
                },
                error: true,
            })
    );
};

export const addSearchResultToBasket = (distributionId, offerId, offerPriceAmount) => (
    dispatch,
    getState
) => {
    const payload = {
        distributionId,
        offerId,
    };
    const previousState = getState();
    const searchString = getSearchString(previousState, { distributionId });

    // first : display a spinner on the product card
    dispatch({
        type: actionTypes.ADD_SEARCHED_PRODUCT_TO_BASKET.REQUEST,
        payload,
        analytics: ({ GA }) => {
            GA.addToBasketFromSearch({ query: searchString, offerPriceAmount });
        },
    });
    // second : reload the searched products list to update the number of offers added to basket and remove the spinner
    // the wait is used to be sure that the basket will be updated before we request the new search results
    wait(1000).then(() => {
        fetchSearchProduct(searchString, distributionId).then(response => {
            dispatch({
                type: actionTypes.SET_PRODUCTS_SEARCH.SUCCESS,
                payload: {
                    ...payload,
                    response,
                },
            });
        });
    });
};
