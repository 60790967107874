import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Link, Text, MediaObject, Heading } from 'components/ui';
import Avatar from 'components/Avatar.jsx';
import InlineSVG from 'svg-inline-react';
import moment from 'moment';
import { Link as ReactRouterLink } from 'react-router-dom';
import AppChannel from 'modules/channels/App';
import ContextService from 'app/scripts/entities/context/context.service';
import Backbone from 'backbone';

export const TYPE_NEW_FEATURE = 'new_feature';
export const TYPE_ANNOUCEMENT = 'annoucement';
export const TYPE_COMMUNITY = 'community';
export const TYPE_ADVICE = 'advice';
export const TYPE_NEW_PRODUCT = 'new_product';

const NewsItem = React.memo(({ news, currentAssemblyId }) => {
    const requireIcon = type => {
        switch (type) {
            case TYPE_ANNOUCEMENT:
                return require('icons/newsfeed-annoucement.svg');
            case TYPE_NEW_FEATURE:
                return require('icons/newsfeed-feature.svg');
            case TYPE_COMMUNITY:
                return require('icons/newsfeed-community.svg');
            case TYPE_ADVICE:
                return require('icons/newsfeed-advice.svg');
            case TYPE_NEW_PRODUCT:
                return require('icons/newsfeed-new-product.svg');
            default:
                return '';
        }
    };

    const activeLinkByLinkTo = {
        collections: 'menu.distributions',
    };

    const updateMenu = (activeLink, link) => {
        Backbone.history.navigate(link, { trigger: false });
        AppChannel.vent.trigger('route', {
            activeLink,
            context: ContextService.getCurrentContext(),
        });
    };

    const createLink = () => {
        if (R.isNil(news.link) || R.isEmpty(news.link) || R.isNil(R.path(['link', 'url'], news))) {
            return null;
        }

        let url = news.link.url;

        if (url.startsWith('linkTo:')) {
            const linkDestination = url.split(':').pop();

            if (!currentAssemblyId || undefined === activeLinkByLinkTo[linkDestination]) {
                return null;
            }

            url = `/assemblies/${currentAssemblyId}/${linkDestination}`;

            return (
                <ReactRouterLink
                    className={'fa-link fa-link--primary fa-link--noDecoration'}
                    to={url}
                    onClick={() => {
                        updateMenu(activeLinkByLinkTo[linkDestination], url);
                    }}
                >
                    {news.link.title}
                </ReactRouterLink>
            );
        }

        return (
            <Link href={url} variant="primary" noDecoration target="_blank">
                {news.link.title}
            </Link>
        );
    };

    return (
        <div className="u-mb-8">
            <Text color="info" className="u-mb-2">
                {moment(news.date).format('L')}
            </Text>
            <MediaObject
                className="u-flexible-row u-mb-1"
                size="xsmall"
                valignCenter
                media={
                    news.photo ? (
                        <Avatar photoId={news.photo} size="small" rounded />
                    ) : (
                        <InlineSVG
                            className="icon-news u-flexible-row"
                            src={requireIcon(news.type)}
                        />
                    )
                }
            >
                <Heading size={4} rank={2}>
                    {news.title}
                </Heading>
            </MediaObject>
            <Text className="u-mb-2">{news.content}</Text>
            {createLink()}
        </div>
    );
});

export const newsShape = {
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string,
    content: PropTypes.string,
    type: PropTypes.oneOf([
        TYPE_NEW_FEATURE,
        TYPE_ANNOUCEMENT,
        TYPE_COMMUNITY,
        TYPE_ADVICE,
        TYPE_NEW_PRODUCT,
    ]),
    link: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
    }),
    photo: PropTypes.string,
};

NewsItem.propTypes = {
    news: PropTypes.shape(newsShape).isRequired,
    currentAssemblyId: PropTypes.number,
};

export default NewsItem;
