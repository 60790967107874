import PropTypes from 'prop-types';
import React from 'react';
import ReactDom from 'react-dom';
import PageHeaderNavigationLink from './PageHeaderNavigationLink.jsx';
import classnames from 'classnames';
import { throttle } from 'underscore';

export class PageHeaderNavigation extends React.Component {
    static propTypes = {
        links: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                path: PropTypes.string.isRequired,
                slug: PropTypes.string.isRequired,
                badgeValue: PropTypes.number,
            })
        ).isRequired,
        activeSlug: PropTypes.string.isRequired,
    };

    state = {
        scrollMinReached: true,
        scrollMaxReached: true,
    };

    componentDidMount() {
        const activeLink = ReactDom.findDOMNode(this.activeLink);
        this.nav.scrollLeft = activeLink.offsetLeft - 12;
        this.onScroll();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.activeSlug !== nextProps.activeSlug) {
            return true;
        }
        if (this.state.scrollMinReached !== nextState.scrollMinReached) {
            return true;
        }
        if (this.state.scrollMaxReached !== nextState.scrollMaxReached) {
            return true;
        }
        if (this.props.links !== nextProps.links) {
            return true;
        }
        return false;
    }

    onScroll = () => {
        const scrollLeft = this.nav.scrollLeft;
        this.setState({
            scrollMinReached: scrollLeft === 0,
            scrollMaxReached: scrollLeft === this.nav.scrollWidth - this.nav.clientWidth,
        });
    };

    setNavRef = element => {
        this.nav = element;
    };

    render() {
        const { links, activeSlug } = this.props;

        return (
            <nav
                onScroll={throttle(this.onScroll, 100)}
                className={classnames('pageHeaderNavigation', {
                    'pageHeaderNavigation--fullLeft': this.state.scrollMinReached,
                    'pageHeaderNavigation--fullRight': this.state.scrollMaxReached,
                })}
                ref={this.setNavRef}
            >
                {links.map(link => {
                    const isActive = link.slug === activeSlug;
                    return (
                        <PageHeaderNavigationLink
                            ref={currentLink => {
                                if (isActive) {
                                    this.activeLink = currentLink;
                                }
                            }}
                            key={link.slug}
                            link={link}
                            isActive={isActive}
                            badgeValue={link.badgeValue}
                        />
                    );
                })}
            </nav>
        );
    }
}

export default PageHeaderNavigation;
