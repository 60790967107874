import { createSelector } from 'reselect';
import { LOADING } from '../../actions';
import { GLOBAL_STATE_KEY } from './index';

export const getProductsScope = (state, { distributionId }) => {
    return state[GLOBAL_STATE_KEY][distributionId] || false;
};

export const getProducts = createSelector(
    getProductsScope,
    state => (state && state.ids.map(id => state.byId[id])) || []
);

export const getSearchString = createSelector(
    getProductsScope,
    state => (state && state.searchString) || ''
);

export const isFetchingSearchResults = createSelector(
    getProductsScope,
    state => (state && state.fetchStatus === LOADING) || false
);

export const getCurrentSearchId = createSelector(
    getProductsScope,
    state => (state && state.searchQueryId) || ''
);
