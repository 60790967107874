import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InlineSVG from 'svg-inline-react';
import searchIcon from 'app/assets/new-design/images/icons/search.svg';
import useI18n from 'hooks/I18n/useI18n';
import { AssembliesMapModalNavContext } from 'components/Maps/ProductIdentity/AssembliesMap/AssembliesMapModalNavigactionContext.jsx';
import { PRIMARY_FILTERS } from '../assembliesMapReducer';
import Heading from 'components/Heading.jsx';
import Autocomplete from 'src/components/molecules/Autocomplete/Autocomplete.jsx';
import Button, { LINK_MODE } from 'src/components/atoms/Button/Button.jsx';
import AssembliesMapFilters from './AssembliesMapFilters.jsx';
import Caret from 'app/assets/new-design/images/icons/caret.svg';

export const AssembliesMapModal = ({
    selectedPrimaryFilter,
    onChangePrimaryFilter,
    onToggleOnlyOpenSaleFilter,
    onAutocompleteChange,
    onAutocompleteBlur,
    onSuggestionClick,
    isOnlyOpenSaleFilterEnabled,
    children,
    autocompleteRef,
    autocompleteSuggestions = [],
    opened = false,
    goBack,
}) => {
    const { trans } = useI18n();
    const [shouldOpen, setShouldOpen] = useState(false);
    const { showNearbyAssemblies, historyAssemblies } = useContext(AssembliesMapModalNavContext);

    useEffect(() => {
        setTimeout(() => setShouldOpen(opened), 1);
    }, [opened]);

    const searchSVG = <InlineSVG className="map-search-icon" src={searchIcon} />;

    return (
        <div className="map-modal-flex-container">
            <div className="map-modal-grid">
                <div className={classnames('map-modal', { open: shouldOpen })}>
                    <Heading className="map-modal-title" rank={1} size={4} bold productIdentity>
                        {trans('assembliesMap.modal.title')}
                    </Heading>
                    {(historyAssemblies.length > 1 || showNearbyAssemblies) && (
                        <Button
                            className="map-modal-go-back-button"
                            onClick={goBack}
                            mode={LINK_MODE}
                        >
                            <InlineSVG raw src={Caret} />
                            {trans('global.back')}
                        </Button>
                    )}
                    <div className="map-modal-scrollable-container u-pl-4 u-pr-4">
                        {!showNearbyAssemblies && (
                            <>
                                <Autocomplete
                                    ref={autocompleteRef}
                                    placeholder={trans('assembliesMap.autocomplete.placeholder')}
                                    prefix={searchSVG}
                                    onChange={onAutocompleteChange}
                                    onBlur={onAutocompleteBlur}
                                    onItemClick={onSuggestionClick}
                                    suggestions={autocompleteSuggestions}
                                />
                                <AssembliesMapFilters
                                    isOnlyOpenSaleFilterEnabled={isOnlyOpenSaleFilterEnabled}
                                    onToggleOnlyOpenSaleFilter={onToggleOnlyOpenSaleFilter}
                                    onChangePrimaryFilter={onChangePrimaryFilter}
                                    selectedPrimaryFilter={selectedPrimaryFilter}
                                />
                            </>
                        )}
                        <div className={classnames('map-assembly-block', { open: shouldOpen })}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

AssembliesMapModal.propTypes = {
    children: PropTypes.node,
    opened: PropTypes.bool,
    selectedPrimaryFilter: PropTypes.oneOf(PRIMARY_FILTERS).isRequired,
    onChangePrimaryFilter: PropTypes.func.isRequired,
    onToggleOnlyOpenSaleFilter: PropTypes.func.isRequired,
    onAutocompleteChange: PropTypes.func.isRequired,
    onAutocompleteBlur: PropTypes.func.isRequired,
    onSuggestionClick: PropTypes.func.isRequired,
    isOnlyOpenSaleFilterEnabled: PropTypes.bool.isRequired,
    autocompleteRef: PropTypes.object.isRequired,
    autocompleteSuggestions: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            metadata: PropTypes.shape({
                geoloc: PropTypes.shape({
                    lat: PropTypes.number.isRequired,
                    lng: PropTypes.number.isRequired,
                }).isRequired,
            }).isRequired,
        })
    ).isRequired,
    goBack: PropTypes.func,
};

export default AssembliesMapModal;
