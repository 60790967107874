import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import * as R from 'ramda';

import 'modules/checkout';
import { fetchBasketIfNeeded } from 'modules/checkout/actions.basket';
import { submitDeliveryOption } from 'modules/checkout/actions.pickup';
import { switchAssembly, switchDistribution, currentAssemblySelector } from 'modules/navigation';

import { getCountriesAsJSON } from 'models/countries.js';
import { fetchHive } from 'api/hives';

import LoadingAnimation from 'components/LoadingAnimation.jsx';
import { getDeliveryOptionInfo } from 'models/delivery';
import RoutePageView from 'components/RoutePageView.jsx';
import TermsPublicFooter from 'components/TermsPublicFooter.jsx';
import ErrorPage from 'components/Error/ErrorPage.jsx';
import PIBasketsContainer from 'components/Baskets/ProductIdentity/BasketsContainer.jsx';
import PISelectDeliveryContainer from 'components/Checkout/ProductIdentity/SelectDeliveryContainer.jsx';
import PIAccount from 'components/Checkout/Anonymous/ProductIdentity/Account.jsx';
import PILogin from 'components/Checkout/Anonymous/ProductIdentity/Login.jsx';

export class AnonymousCheckoutRouter extends React.Component {
    static propTypes = {
        basket: PropTypes.object,
        basketUuid: PropTypes.string.isRequired,
        submitDeliveryOption: PropTypes.func.isRequired,
        deliveryOptions: PropTypes.array.isRequired,
        fetchBasketIfNeeded: PropTypes.func.isRequired,
        isFetchingBasket: PropTypes.bool.isRequired,
        basketPrices: PropTypes.object,
        selectedOption: PropTypes.string,
        currentAssembly: PropTypes.object,
        switchAssembly: PropTypes.func.isRequired,
        switchDistribution: PropTypes.func.isRequired,
    };

    constructor() {
        super();
        this.state = {
            countries: [],
        };
    }

    componentDidMount() {
        this.props.fetchBasketIfNeeded(this.props.basketUuid, true).then(() => {
            getCountriesAsJSON().then(countries => {
                this.setState({
                    countries,
                });
            });
        });
        this.suspensionTimeoutId = null;
    }
    componentDidUpdate(prevProps) {
        const { basket, currentAssembly, basketUuid } = this.props;
        if (basket?.distribution?.hiveId !== currentAssembly?.id || currentAssembly === null) {
            basket?.distribution?.hiveId &&
                fetchHive(basket.distribution.hiveId).then(hive => {
                    this.props.switchAssembly(hive);
                    this.props.switchDistribution(hive, {
                        ...basket.distribution,
                        distributionId: basket.distributionId,
                    });
                });
        }
        if (basketUuid !== prevProps.basketUuid) {
            this.props.fetchBasketIfNeeded(basketUuid, true).then(() => {
                getCountriesAsJSON().then(countries => {
                    this.setState({
                        countries,
                    });
                });
            });
            this.suspensionTimeoutId = null;
        }
    }
    componentWillUnmount() {
        if (this.suspensionTimeoutId) {
            clearTimeout(this.suspensionTimeoutId);
        }
    }

    onButtonClick = history => () => {
        const { type, ref } = getDeliveryOptionInfo(this.props.selectedOption);
        this.props.submitDeliveryOption({ type, ref }, this.props.basketUuid).then(() => {
            history.push('login');
            window.scroll(0, 0);
        });
    };

    render() {
        const {
            basket,
            deliveryOptions,
            isFetchingBasket,
            basketPrices,
            selectedOption,
        } = this.props;

        const { countries } = this.state;

        if (isFetchingBasket || R.isEmpty(countries) || !basketPrices) {
            return <LoadingAnimation type="pi-spinner" />;
        }

        return (
            <Fragment>
                <RoutePageView />
                <Switch>
                    <Route
                        exact
                        path="/checkout/:uuid/basket"
                        render={() => <PIBasketsContainer />}
                    />
                    <Route
                        exact
                        path="/checkout/:uuid/pickup"
                        render={props => (
                            <div>
                                <PISelectDeliveryContainer
                                    onDeliveryFormSubmit={this.onButtonClick(props.history)}
                                    basket={basket}
                                    basketPrices={basketPrices}
                                    deliveryOptions={deliveryOptions}
                                    selectedOption={selectedOption}
                                />
                            </div>
                        )}
                    />
                    <Route
                        exact
                        path="/checkout/:uuid/account"
                        render={props => <PIAccount basketUuid={props.basketUuid} />}
                    />
                    <Route exact path="/checkout/:uuid/login" render={() => <PILogin />} />

                    <Route
                        render={() => (
                            <>
                                <ErrorPage errorType="notFound" />
                                <TermsPublicFooter />
                            </>
                        )}
                    />
                </Switch>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    currentAssembly: currentAssemblySelector(state),
    basket: state.checkout.basket,
    deliveryOptions: state.checkout.deliveryOptions,
    selectedOption: state.checkout.selectedOption,
    isFetchingBasket: state.checkout.isFetchingBasket,
    basketPrices: state.checkout.basketPrices,
});

const mapDispatchToProps = {
    fetchBasketIfNeeded,
    submitDeliveryOption,
    switchAssembly,
    switchDistribution,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnonymousCheckoutRouter);
