import * as R from 'ramda';
import { compareAlpha } from './string.js';

export const sortAlpha = R.curry((path, array) =>
    R.sort((a, b) => compareAlpha(R.path([...path], a), R.path([...path], b)), array)
);

export const toArray = x => {
    if (R.is(Array, x)) {
        return x;
    }

    if (x === null || x === undefined) {
        return [];
    }

    return [x];
};
