import PropTypes from 'prop-types';

const CONSTRUCT = 'construct';
const OPEN = 'open';
const CLOSED = 'closed';
const PRECONSTRUCT = 'pre-construct';
const INIT = 'init';

const propType = PropTypes.oneOf([CONSTRUCT, OPEN, CLOSED, PRECONSTRUCT, INIT]).isRequired;

export { CONSTRUCT, OPEN, CLOSED, PRECONSTRUCT, INIT, propType };
