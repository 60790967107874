import environment from 'modules/utils/environment';

const enabledLocales = [
    'fr-FR',
    'de-DE',
    'ca-ES',
    'es-ES',
    'it-IT',
    'nl-NL',
    'fr-BE',
    'nl-BE',
    'en-GB',
    'de-CH',
    'fr-CH',
    'da-DK',
];

if (environment !== 'production') {
    enabledLocales.push('xh-ZA');
}

export const localesArray = enabledLocales.map(locale => {
    return require(`./${locale}/index.js`).default;
});

export const localesArraySortedByName = localesArray.sort((a, b) => {
    return a.name > b.name ? 1 : -1;
});

export const localesMap = {};
localesArray.forEach(locale => {
    localesMap[locale.code] = locale;
    localesMap[locale.bCode] = locale;
    if (locale.shortcut) {
        localesMap[locale.shortcut] = locale;
    }
});

export function getLocaleConfig(localeString = 'en') {
    return localesMap[localeString] || null;
}
