import frConfig from '../fr-FR';

export default {
    code: 'xh-ZA',

    // bcode is here for the "backCode" ... the code as seen/sent by the back :O
    bCode: 'xh_ZA',

    name: 'Crowdin',
    supportEmail: 'support@lrqdo.fr',
    privacyEmail: 'privacy@lrqdo.fr',

    trainingSite: {
        url: 'http://formation.laruchequiditoui.fr',
        password: 'formation-ruche2014',
    },

    supportSite: {
        // Do not use support.laruchequiditoui.fr (yet ?)
        // it does not support https and we need https
        widgetDomain: 'laruchequiditoui.zendesk.com',
        homepage: 'http://support.laruchequiditoui.fr/hc/fr',
        host:
            'http://support.laruchequiditoui.fr/hc/fr/categories/200133228-Le-Coin-des-Responsables-de-Ruche',
        farmer: 'https://support.laruchequiditoui.fr/hc/fr/categories/5495742283410',
        member: 'http://support.laruchequiditoui.fr/hc/fr/categories/200133208-Le-coin-des-membres',
        user: 'http://support.laruchequiditoui.fr/hc/fr/categories/200133208-Le-coin-des-membres',
        kycInformation:
            'http://support.laruchequiditoui.fr/hc/fr/articles/218582808-Informations-suppl%C3%A9mentaires-requises-pour-le-paiement',
        proImprovementsHost:
            'http://support.laruchequiditoui.fr/hc/fr/articles/218796998-Des-am%C3%A9liorations-dans-votre-espace-professionnel',
        proImprovementsProd:
            'http://support.laruchequiditoui.fr/hc/fr/articles/219236457-Am%C3%A9lioration-de-votre-espace-professionnel',
    },

    logos: {
        brown: '/assets/images/logos/brown/logo-fr.svg',
        white: '/assets/images/logos/white/logo-fr.svg',
        whiteOutlined: '/assets/images/logos/whiteOutlined/logo-fr.svg',
        blue: '/assets/images/logos/blue/logo-fr.svg',
        christmas: '/assets/images/logos/christmas/logo-fr.svg',
        whiteChristmas: '/assets/images/logos/christmas/white/logo-fr.svg',
    },

    facebook: 'https://www.facebook.com/laruchequiditoui',
    twitter: 'https://twitter.com/ruchequiditoui',
    inlineLogo: frConfig.inlineLogo,

    dateFormats: {
        d: 'DD/MM/YYYY',
        D: 'dddd DD MMMM YYYY',
        I: 'dddd DD MMMM',
        i: 'DD MMMM YYYY',
        t: 'HH:mm',
        T: 'HH:mm:ss',
        f: 'dddd DD MMMM YYYY HH:mm',
        F: 'dddd DD MMMM YYYY HH:mm:ss',
        M: 'DD MMMM',
        Y: 'MMMM YYYY',
        shortDayDate: 'ddd DD MMMM',
        dateWithoutYear: 'DD/MM',
    },

    zendeskLocale: 'fr',
    zendeskSearch: 'https://laruchequiditoui.zendesk.com/api/v2/help_center/',
    zendeskCategories: {
        farmer: '5495742283410',
        member: '200133208',
        user: '200133208',
        host: '200133228,360001048374',
    },

    cguPage: 'https://laruchequiditoui.fr/fr/p/terms',
    homepage: 'https://laruchequiditoui.fr',
};
