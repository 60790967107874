import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useI18n from 'hooks/I18n/useI18n.js';

import { filerPhoto } from 'modules/utils/filer';

import { Heading } from 'components/ui';
import LimitOverflowingContentWithEllipsis from 'components/ProductIdentity/LimitOverflowingContentWithEllipsis.jsx';
import PIText from 'components/ProductIdentity/Text.jsx';

const HiveProductsHeader = props => {
    const { assembly, saleOpenMessage, className } = props;
    const { trans } = useI18n();

    return (
        <div className={classNames('pi-product-header', className)}>
            <img
                className="pi-product-header-avatar"
                src={filerPhoto(assembly?.leader?.photo?.id, 'medium', 'user')}
                alt=""
            />
            <Heading size={2} className="pi-product-header-title">
                {trans('products.highlighted.hostWord', {
                    '%hostName%': assembly.leader.firstName,
                })}
            </Heading>
            <PIText
                className="pi-product-header-role"
                size="14px"
                lineHeight="20px"
                family="inter"
                color="gray2"
            >
                {trans('hives.page.leaderRole')}
            </PIText>
            {saleOpenMessage && (
                <LimitOverflowingContentWithEllipsis
                    reduceKey="global.show.less"
                    expandKey="global.show.more"
                    className="pi-product-header-description"
                >
                    {saleOpenMessage}
                </LimitOverflowingContentWithEllipsis>
            )}
        </div>
    );
};

HiveProductsHeader.propTypes = {
    assembly: PropTypes.shape({
        leader: PropTypes.shape({
            firstName: PropTypes.string,
            photo: PropTypes.object,
        }),
    }).isRequired,
    saleOpenMessage: PropTypes.string,
    className: PropTypes.string,
};

HiveProductsHeader.defaultProps = {
    className: '',
    saleOpenMessage: '',
};

export default HiveProductsHeader;
