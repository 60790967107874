import geodist from 'geodist';

const MAX_METERS_LIST_VIEW = 8000;

export const filterMaxRange = lookupCoords => pickup =>
    lookupCoords &&
    lookupCoords.latitude &&
    lookupCoords.longitude &&
    geodist(
        { lat: lookupCoords.latitude, long: lookupCoords.longitude },
        {
            lat: pickup.meetingPoint.coordinates.latitude,
            long: pickup.meetingPoint.coordinates.longitude,
        },
        { unit: 'meters' }
    ) < MAX_METERS_LIST_VIEW;

export const getAddressParts = fullAddress => {
    const fullAddressParts = fullAddress.split(',');

    // Remove country
    fullAddressParts.pop();

    const zipcodeAndCity = fullAddressParts.pop();
    const streetAndNumber = fullAddressParts.join(',');

    return { streetAndNumber, zipcodeAndCity };
};
