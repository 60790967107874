import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Select = forwardRef(({ options, placeholder, error, ...props }, ref) => {
    return (
        <>
            <select ref={ref} {...props} className="nd-select">
                {placeholder && (
                    <option selected value="" disabled>
                        {placeholder}
                    </option>
                )}
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            {error && <div className="nd-input-error-msg">{error}</div>}
        </>
    );
});

Select.propTypes = {
    options: PropTypes.array,
    placeholder: PropTypes.string,
    error: PropTypes.string,
};

Select.defaultProps = {
    options: [],
    placeholder: '',
    error: '',
};

export default Select;
