import Globalize from 'globalize';
import './globalize.culture.it-IT';
Globalize.addCultureInfo('it', 'it-IT');

import moment from 'moment';
import 'moment/locale/it';
moment.defineLocale('it-IT', { parentLocale: 'it' });

export default {
    code: 'it-IT',

    // bcode is here for the "backCode" ... the code as seen/sent by the back :O
    bCode: 'it_IT',

    name: 'Italiano',
    shortcut: 'it',
    supportEmail: 'assistenza@alvearechedicesi.it',
    privacyEmail: 'privacy@alvearechedicesi.it',

    trainingSite: {
        url: 'http://formazione.lalvearechedicesi.it',
        password: 'equanum007',
    },

    appStores: {
        apple: 'https://itunes.apple.com/it/app/lalveare-che-dice-s%C3%AC/id1052198033?mt=8',
        google: 'https://play.google.com/store/apps/details?id=com.lrqdo&hl=it',
    },

    supportSite: {
        widgetDomain: 'alvearechedicesi.zendesk.com',
        homepage: 'https://alvearechedicesi.zendesk.com/hc/it',
        host: 'https://alvearechedicesi.zendesk.com/hc/it/categories/200496942-Aprire-un-Alveare',
        farmer: 'https://linktr.ee/assistenzaalveare',
        member:
            'https://alvearechedicesi.zendesk.com/hc/it/categories/200494102-L-angolo-dei-membri',
        user: 'https://alvearechedicesi.zendesk.com/hc/it/categories/200494102-L-angolo-dei-membri',
        kycInformation:
            'https://alvearechedicesi.zendesk.com/hc/it/articles/208497415-Informazioni-supplementari-richieste-per-i-pagamenti',
        proImprovementsHost: 'https://alvearechedicesi.zendesk.com/hc/it/articles/208497415',
        proImprovementsProd: 'https://alvearechedicesi.zendesk.com/hc/it/articles/208497415',
        farmerInterregionalCatalog:
            'https://alvearechedicesi.zendesk.com/hc/it/articles/115001916329',
    },

    logos: {
        brown: '/assets/images/logos/brown/logo-it.svg',
        turquoise: '/assets/images/logos/turquoise/logo-it.svg',
        white: '/assets/images/logos/white/logo-it.svg',
        whiteOutlined: '/assets/images/logos/whiteOutlined/logo-it.svg',
        blue: '/assets/images/logos/blue/logo-it.svg',
        christmas: '/assets/images/logos/christmas/logo-it.svg',
        whiteChristmas: '/assets/images/logos/christmas/white/logo-it.svg',
    },

    social: {
        facebook: 'https://www.facebook.com/LAlveareCheDiceSi',
        blog: 'http://blog.lalvearechedicesi.it/',
        twitter: 'https://twitter.com/AlveareDiceSi',
        youtube: 'https://www.youtube.com/channel/UCG6g8ZaHIOlYb_16pn7CSuw',
        linkedin: "https://www.linkedin.com/company/l'alveare-che-dice-s%C3%AC-",
    },

    links: {
        about: 'https://noi.alvearechedicesi.it/',
        press: 'https://noi.alvearechedicesi.it/stampa/',
        joinUs: 'https://noi.alvearechedicesi.it/nostro-team/',
        order: 'https://alvearechedicesi.it/it/assemblies',
        giftCards: 'https://alvearechedicesi.it/it/giftcards',
        openRuche: 'https://alvearechedicesi.it/it/p/open',
        provideRuche: 'https://alvearechedicesi.it/it/p/provide',
        proposeLocal: '',
    },

    proposeLocalForm:
        'https://docs.google.com/forms/d/e/1FAIpQLScj6iK45MgKSSL9HJbjcqXN7PUt_9O4kA4mw8M-YI9b7wLykg/viewform',

    dateFormats: {
        d: 'DD/MM/YYYY',
        D: 'dddd DD MMMM YYYY',
        I: 'dddd DD MMMM',
        i: 'DD MMMM YYYY',
        t: 'HH:mm',
        T: 'HH:mm:ss',
        f: 'dddd DD MMMM YYYY HH:mm',
        F: 'dddd DD MMMM YYYY HH:mm:ss',
        M: 'DD MMMM',
        Y: 'MMMM YYYY',
        shortDayDate: 'ddd DD MMMM',
        dateWithoutYear: 'DD/MM',
    },

    inlineLogo: require('./logo.svg'),
    zendeskLocale: 'it',
    zendeskSearch: 'https://alvearechedicesi.zendesk.com/api/v2/help_center/',
    zendeskCategories: {
        farmer: '5990667502994,5990613858706,5990617385362',
        member: '200494102',
        user: '200494102',
        host: '200496942,200476501',
    },

    cguPage: 'https://alvearechedicesi.it/it/p/terms',
    homepage: 'https://alvearechedicesi.it',
    minirucheHelpCenter:
        'https://alvearechedicesi.zendesk.com/hc/it/categories/360002652320-Mini-Alveari-In-costruzione-',
};
