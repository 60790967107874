/* eslint guard-for-in: 0 */

import logger from 'modules/logger';
import IntervalParser from './IntervalParser';

const MULTIPLE_CHOICES_SEPARATOR = '|';
const INTERVAL_SEPARATOR = ' ';
const translations = {};
const intervalParser = new IntervalParser();

function checkIdExists(locale, id) {
    if (!(id in translations[locale])) {
        throw new Error(`No translation found for id "${id}" and locale "${locale}"`);
    }
}

function findTranslation(locale, id) {
    checkIdExists(locale, id);
    return translations[locale][id];
}

function findTranslationOrNull(locale, id) {
    if (!(id in translations[locale])) {
        logger.error(`No translation found for id "${id}" and locale "${locale}"`);
        return null;
    }
    return translations[locale][id];
}

function splitIntoChoices(translation) {
    const choices = {};
    const parts = translation.split(MULTIPLE_CHOICES_SEPARATOR);

    for (let i = 0, len = parts.length; i < len; ++i) {
        const part = parts[i];
        const pos = part.indexOf(INTERVAL_SEPARATOR);
        const intervalData = part.substr(0, pos);
        choices[intervalData] = part.substr(pos + 1);
    }

    return choices;
}

function replaceParametersWithValues(text, parameters) {
    let returnText = text;
    for (const index in parameters) {
        returnText = returnText.replace(new RegExp(index, 'g'), parameters[index]);
    }
    return returnText;
}

function addTranslations(locale, _translations) {
    translations[locale] = translations[locale] || {};

    if (___DEV___ && !process.env.TESTING) {
        const R = require('ramda');

        const intersection = R.intersection(R.keys(translations[locale]), R.keys(_translations));
        const errorMessage = `You cannot add duplicate keys under the same locale ${locale}:\n${intersection
            .map(
                x =>
                    `${x}: ${
                        translations[locale][x] === _translations[x]
                            ? 'same translation'
                            : `${translations[locale][x]} => ${_translations[x]}`
                    }`
            )
            .join('\n')}`;
        if (intersection.length > 0) {
            logger.error(Error(errorMessage));
        }
    }

    Object.assign(translations[locale], _translations);
}

function trans(locale, id, parameters = {}) {
    let translation;
    try {
        translation = findTranslation(locale, id);
    } catch (e) {
        logger.error(e);
        return id;
    }
    return replaceParametersWithValues(translation, parameters);
}

function transOrNull(locale, id, parameters = {}) {
    const trad = replaceParametersWithValues(findTranslationOrNull(locale, id), parameters);

    return trad !== id ? trad : null;
}

function selectAndProcessChoice(choices, number, parameters) {
    let translation;
    for (const intervalData in choices) {
        translation = choices[intervalData];
        const interval = intervalParser.parse(intervalData);
        if (interval.contains(number)) {
            return replaceParametersWithValues(translation, parameters);
        }
    }
    throw new Error(`Number ${number} not in choices`);
}

function transChoice(locale, id, number, parameters = {}) {
    if (locale === 'xh-ZA') {
        return trans(locale, id, parameters);
    }
    let translation;
    try {
        translation = findTranslation(locale, id);
        const choices = splitIntoChoices(translation);
        translation = selectAndProcessChoice(choices, number, parameters);
    } catch (e) {
        logger.error(e);
        return id;
    }
    return translation;
}

function getTranslations(locale) {
    if (locale && translations[locale]) {
        return translations[locale];
    }
    return translations;
}

export { trans, transOrNull, transChoice, addTranslations, getTranslations };
