import PropTypes from 'prop-types';
import React from 'react';
import * as Translator from 'modules/i18n/Translator';
import { getLocaleConfig } from 'modules/config/i18n';
import { formatPrice } from 'modules/utils/currency';

function getDisplayName(Component) {
    return Component.displayName || Component.name || 'Component';
}

export default function(Component) {
    class ConnectToI18n extends React.Component {
        static contextTypes = {
            i18n: PropTypes.string.isRequired,
        };

        trans = (translationKey, parameters) => {
            return Translator.trans(this.context.i18n, translationKey, parameters);
        };

        transChoice = (translationKey, number, parameters) => {
            const extendedParameters = {
                '%count%': number,
                ...parameters,
            };
            return Translator.transChoice(
                this.context.i18n,
                translationKey,
                number,
                extendedParameters
            );
        };

        formatPrice = (amount, currency, options) => {
            return formatPrice(this.context.i18n, amount, currency, options);
        };

        render() {
            const { forwardedRef, ...rest } = this.props;

            return (
                <Component
                    formatPrice={this.formatPrice}
                    trans={this.trans}
                    transChoice={this.transChoice}
                    i18n={this.context.i18n}
                    i18nConfig={getLocaleConfig(this.context.i18n)}
                    ref={forwardedRef}
                    {...rest}
                />
            );
        }
    }

    function forwardRef(props, ref) {
        return <ConnectToI18n {...props} forwardedRef={ref} />;
    }

    forwardRef.displayName = `ConnectToI18n(${getDisplayName(Component)})`;

    return React.forwardRef(forwardRef);
}
