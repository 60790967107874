import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useI18n from 'hooks/I18n/useI18n';
import Summary from 'components/Payment/ProductIdentity/Form/components/Summary.jsx';
import CheckoutTimeline from 'components/Checkout/ProductIdentity/CheckoutTimeline.jsx';
import Banner from 'components/Banner.jsx';

const LayoutCheckoutPayment = ({
    children,
    currentStep,
    orderId,
    distributionId,
    assemblyId,
    showSummary,
    shouldShowPaymentIssueBanner,
    ...props
}) => {
    const { trans } = useI18n();

    return (
        <>
            <div className="pi-layout-checkout-payment-steps">
                <CheckoutTimeline
                    step={currentStep}
                    orderId={orderId}
                    distributionId={distributionId}
                    assemblyId={assemblyId}
                />
            </div>

            {shouldShowPaymentIssueBanner && (
                <Banner className="pi-layout-checkout-payment-banner">
                    {trans('banner.paymentIssue')}
                </Banner>
            )}

            <div className="pi-layout-checkout-payment">
                <div className="pi-layout-checkout-payment-body">
                    <div
                        className={classnames('pi-layout-checkout-payment-children', {
                            centered: !showSummary,
                        })}
                    >
                        {children}
                    </div>
                    {showSummary && (
                        <Summary className="pi-layout-checkout-payment-summary" {...props} />
                    )}
                </div>
            </div>
        </>
    );
};

LayoutCheckoutPayment.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    currentStep: PropTypes.string,
    amount: PropTypes.number.isRequired,
    finalAmount: PropTypes.number.isRequired,
    creditUsed: PropTypes.number,
    charityAmount: PropTypes.number,
    amounGiftCardUsed: PropTypes.number,
    deliveryAmount: PropTypes.number,
    organisation: PropTypes.shape({ name: PropTypes.string }),
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    currency: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    orderId: PropTypes.number,
    distributionId: PropTypes.number,
    assemblyId: PropTypes.number,
    showSummary: PropTypes.bool,
    shouldShowPaymentIssueBanner: PropTypes.bool,
};

LayoutCheckoutPayment.defaultProps = {
    showSummary: true,
};

export default LayoutCheckoutPayment;
