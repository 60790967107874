import React from 'react';
import PropTypes from 'prop-types';

import useI18n from 'hooks/I18n/useI18n.js';

import Offer, { PRODUCT_TEMPLATE } from 'components/Product/ProductIdentity/Offer.jsx';

import Modal from 'src/components/organisms/Modal/Modal.jsx';

export const MultiOffersModal = props => {
    const {
        addOfferToBasket,
        className,
        distributionId,
        product,
        actionOrigin,
        closeModal,
        offertButtonSize,
    } = props;
    const { trans } = useI18n();

    return (
        <Modal
            size="small"
            className={className}
            closeModal={closeModal}
            isMounted
            title={trans('salePage.product.title.showAllOffers')}
        >
            <div className="pi-product-offers-modal">
                {product.offers.map(offer => {
                    return (
                        <Offer
                            buttonSize={offertButtonSize}
                            template={PRODUCT_TEMPLATE}
                            key={offer.id}
                            distributionId={distributionId}
                            offer={offer}
                            addOfferToBasket={addOfferToBasket}
                            product={product}
                            actionOrigin={actionOrigin}
                        />
                    );
                })}
            </div>
        </Modal>
    );
};

MultiOffersModal.propTypes = {
    addOfferToBasket: PropTypes.func.isRequired,
    className: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    distributionId: PropTypes.number.isRequired,
    product: PropTypes.shape({}).isRequired,
    actionOrigin: PropTypes.string,
    offertButtonSize: PropTypes.string,
};

export default MultiOffersModal;
