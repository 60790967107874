import { currencies } from 'modules/config/currencies';
import { isNil, includes } from 'ramda';
import { EUR, CHF } from 'modules/config/currencies.js';

/**
 * Detects if Number.toLocaleString() supports locales and options arguments (IE 11 ≥, Safari 10 ≥).
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString#Example:_Checking_for_support_for_locales_and_options_arguments
 */
const toLocaleStringSupportsLocales = (() => {
    const number = 0;
    try {
        number.toLocaleString('i');
    } catch (e) {
        return e.name === 'RangeError';
    }
    return false;
})();

const PRICE_DEFAULT_FRACTION_DIGITS = 2;

/**
 * Format a float price amount using the ECMAScript Internationalization API.
 *
 * Examples:
 *
 * ```
 * formatPrice('fr', 9.5, 'EUR'); // Return  '9,50 €'
 * formatPrice('fr', 10, 'EUR');  // Return '10,00 €'
 * formatPrice('en', 10, 'GBP');  // Return '£10.00'
 * ```
 *
 * @see http://www.ecma-international.org/ecma-402/1.0/
 * @see http://norbertlindenberg.com/2012/12/ecmascript-internationalization-api/index.html
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
 *
 * @param  {String}  locale Language tag (https://tools.ietf.org/html/bcp47)
 * @param  {Number}  amount Amount as float
 * @param  {String}  currency ISO-4217 currency code (https://www.iso.org/iso-4217-currency-codes.html)
 * @param  {Object}  options
 * @return {String}
 */
export function formatPrice(locale, amount, currency, options = {}) {
    // Fallback for browers not supporting Number.toLocaleString() with locales
    // and options arguments (ECMA-402)
    if (!toLocaleStringSupportsLocales) {
        return formatPriceLegacy(locale, amount, currency);
    }

    if (isNil(options.maximumFractionDigits)) {
        options.maximumFractionDigits = PRICE_DEFAULT_FRACTION_DIGITS;
    }

    if (isNil(options.minimumFractionDigits)) {
        // FF 44 throws RangeError: invalid digits value: 0
        // with options `{ maximumFractionDigits: 0 }`
        options.minimumFractionDigits = Math.min(
            PRICE_DEFAULT_FRACTION_DIGITS,
            options.maximumFractionDigits
        );
    }

    // Use the ECMAScript Intl API (toLocaleString is equivalent to Intl.NumberFormat)
    return Number(amount).toLocaleString(locale, {
        style: 'currency',
        minimumFractionDigits: options.minimumFractionDigits,
        maximumFractionDigits: options.maximumFractionDigits,
        currency,
    });
}

/**
 * Fallback for browers not supporting Number.toLocaleString() with locales
 * and options arguments (ECMA-402) : ≤ IE 10, ≤ Safari 9.
 */
function formatPriceLegacy(locale, amount, currency) {
    const currencyConfig = currencies[currency];
    const floatPrice = Math.abs(amount);
    const integerPart = Math.floor(floatPrice);
    const decimalPart = (floatPrice - integerPart).toFixed(2).substr(2);
    const negativeSign = amount < 0 ? '-' : '';
    const nonBreakingSpace = '\u00A0';
    const startsWithSymbol = locale.indexOf('en') !== -1;

    const formattedAmount = `${integerPart}${currencyConfig.decimalSeparator}${decimalPart}`;
    if (startsWithSymbol) {
        return `${negativeSign}${currencyConfig.symbol}${formattedAmount}`;
    }
    return `${negativeSign}${formattedAmount}${nonBreakingSpace}${currencyConfig.symbol}`;
}

export const getRoundedPrice = amount => Math.ceil(amount / 100) * 100;
export const getRoundedFromPrice = amount => getRoundedPrice(amount) - amount;

export const getPriceDecimalSeparator = ({ locale, currency }) => {
    if (includes(locale, ['en-GB', 'de-CH', 'fr-CH']) && currency === CHF) {
        return '.';
    }

    if (includes(locale, ['en-GB', 'de-CH', 'fr-CH']) && currency === EUR) {
        return '.';
    }

    return ',';
};

export const formatPriceWithoutSymbol = ({ locale, currency, price }) => {
    const separator = getPriceDecimalSeparator({ locale, currency });
    const newPrice = price.replace(',', '.');
    const integerPart = Math.floor(Number(newPrice));
    const decimalPart = (newPrice - integerPart).toFixed(2).substr(2);

    return `${integerPart}${separator}${decimalPart}`;
};

export const formatPriceToAmount = value =>
    parseInt(Number.parseFloat(value.replace(',', '.') * 100).toFixed(0), 10);
