import React from 'react';
import PropTypes from 'prop-types';

import Utils from 'modules/utils';

import PIText from 'components/ProductIdentity/Text.jsx';
import Heading from 'components/Heading.jsx';
import TroncatedDescription from 'components/Sale/ProductIdentity/TroncatedDescription.jsx';

const { filerPhoto } = Utils;

const FarmHeader = props => {
    const { image, title, subtitle, description } = props;
    return (
        <div className="pi-farmHeader">
            <img
                className="pi-farmHeader-image"
                alt={title}
                src={filerPhoto(image, 'medium', 'user')}
            />
            <div className="pi-farmHeader-header">
                <Heading rank={3} size={4} bold serif productIdentity>
                    {title}
                </Heading>
                <PIText family="inter" size="14px" color="gray2" lineHeight="20px">
                    {subtitle}
                </PIText>
            </div>
            {description && <TroncatedDescription title={title} description={description} />}
        </div>
    );
};

FarmHeader.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
};
export default FarmHeader;
