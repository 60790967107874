import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'underscore';
import { decodeHTML } from 'entities';
import { useFormContext } from 'react-hook-form';

import AppChannel from 'modules/channels/App';
import { reactFormValidators } from 'modules/validation';

import useI18n from 'hooks/I18n/useI18n.js';

import Input from 'src/components/atoms/Input/Input.jsx';
import Label from 'src/components/atoms/Label/Label.jsx';
import Button from 'src/components/atoms/Button/Button.jsx';
import Link from 'src/components/atoms/Link/Link.jsx';
import Text from 'src/components/atoms/Text/Text.jsx';

import Checkbox from 'src/components/molecules/Checkbox/Checkbox.jsx';
import Password from 'src/components/molecules/Password/Password.jsx';
import Alert, { ERROR_TYPE } from 'src/components/molecules/Alert/Alert.jsx';

const LoginForm = ({ onSubmit }) => {
    const { trans, i18n } = useI18n();
    const {
        watch,
        register,
        handleSubmit,
        formState: { errors, isSubmitted },
        setError,
    } = useFormContext();

    const { assertValidEmail } = useMemo(() => reactFormValidators(trans), [trans]);
    const mailProps = {
        mode: errors.email && 'error',
        error: get(errors, ['email', 'message']),
        ...register('email', {
            validate: assertValidEmail('email'),
            setValueAs: v => v.trim(),
        }),
    };
    const passwordProps = {
        mode: errors.password && 'error',
        error: get(errors, ['password', 'message']),
        ...register('password', {
            setValueAs: v => v.trim(),
        }),
    };
    const rememberProps = register('remember');
    const [email, password] = watch(['email', 'password']);

    const alert = get(errors, ['root', 'alert'], {});
    const isSending = isSubmitted && isEmpty(errors);

    useEffect(() => {
        const setServerError = err => {
            const error = get(err, ['responseJSON', 'error']);
            if (error === 'invalid_grant') {
                setError('root.alert', {
                    type: 'error',
                    message: 'session.loginForm.invalidPassword',
                });
            } else {
                setError('root.alert', {
                    type: 'error',
                    message: 'global.error.retryMessage',
                });
            }
        };
        AppChannel.vent.on('auth:error', setServerError);

        return () => {
            AppChannel.vent.off('auth:error', setServerError);
        };
    }, [setError]);
    return (
        <div className="nd-login-form">
            <Alert
                visible={!isEmpty(alert)}
                type={alert.type || ERROR_TYPE}
                message={alert.message && trans(alert.message)}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Label htmlFor="email">{trans('login.email')}</Label>
                <Input {...mailProps} id="email" />
                <Label htmlFor="password">{trans('login.password')}</Label>
                <Password {...passwordProps} id="password" />
                <Text>
                    <Link className="password-lost" bypassRouter href={`/${i18n}/forgot_password`}>
                        {trans('login.forgotPassword')}
                    </Link>
                </Text>
                <Checkbox {...rememberProps} id="remember">
                    {decodeHTML(trans('session.keepAlive'))}
                </Checkbox>
                <Button disabled={!(email && password) || isSending} size="big" type="submit">
                    {isSending ? (
                        <div>
                            <img
                                width="auto"
                                height="16px"
                                src="/assets/images/loaderanims/loader-pi-white.gif"
                            />
                        </div>
                    ) : (
                        trans('login.login')
                    )}
                </Button>
            </form>
            <div className="first-connection">
                <Text strong>{trans('login.firstConnexion')}</Text>
                <div
                    className="nd-link nd-text"
                    dangerouslySetInnerHTML={{
                        __html: trans('login.noAccount.text', { '%url%': `/${i18n}/join` }),
                    }}
                />
            </div>
        </div>
    );
};

LoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
