import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import optimist from 'redux-optimist';
// @NOTE: yes, we do leave this in production, no effect if user does not have redux devtools,
// and practical for devs to leave it on, should ease debugging in the future ...
const composeEnhancers = (___BROWSER___ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const middlewares = [thunk];

if (___BROWSER___) {
    middlewares.push(require('./analyticsMiddleware').default());
}

const getRootReducer = reducers => optimist(combineReducers(reducers));

const configureStore = reducerRegistry => preloadedState => {
    const store = createStore(
        getRootReducer(reducerRegistry.getReducers()),
        preloadedState,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    return {
        store,
        reload: reducers => store.replaceReducer(getRootReducer(reducers)),
    };
};

export default configureStore;
