import React, { useMemo } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';

import { useSelector } from 'react-redux';

import Utils from 'modules/utils';
import { userSelector } from 'modules/currentUser';
import useI18n from 'hooks/I18n/useI18n';

import Badge from 'components/Badge.jsx';
import Heading from 'components/Heading.jsx';
import Text from 'components/ProductIdentity/Text.jsx';

import AccountingIcon from 'app/assets/images/icons/icon-accounting.svg';
import MobileIconFolder from 'app/assets/images/navigation/mobile/icon-mobile-dossierprod-white.svg';
import Caret from 'app/assets/new-design/images/icons/caret.svg';
import AssemblyIcon from 'app/assets/images/icons/icon-assembly.svg';

const { linkTo, filerPhoto } = Utils;

const HiveFarmSectionPanel = ({
    shouldDisplayProviderFolder,
    shouldDisplayAccounting,
    shouldDisplayAssemblies,
    badgeValue,
}) => {
    const { trans } = useI18n();

    const currentUser = useSelector(userSelector);

    const farm = useMemo(() => R.path(['farm', 0], currentUser), [currentUser]);

    const isFarmValidated = useMemo(() => farm?.status === 'validated farm', [farm]);

    const badge = useMemo(
        () =>
            badgeValue && badgeValue > 0 ? (
                <Badge className="farmSection-badge">{badgeValue}</Badge>
            ) : null,
        [badgeValue]
    );
    return (
        <div className="hives-panel-section">
            <Heading className="hives-panel-section-title" productIdentity size={1} bold>
                {trans('navigation.context.farmTitle_new')}
            </Heading>
            <div className="hives-panel-list">
                <a
                    href={linkTo(isFarmValidated ? 'producers/me/collections' : 'producers/folder')}
                    className={`hives-panel-hive`}
                >
                    <img
                        className="hives-panel-producer-logo"
                        width="48"
                        height="48"
                        src={filerPhoto(farm.photos && farm.photos[0], 'small', 'user')}
                    />
                    <div className="hives-panel-hive-content">
                        <div className="hives-panel-hive-content-title">
                            <Text size="16px" lineHeight="20px" weight={600} color="gray2">
                                {farm.name || trans('global.toBeDefined')}
                            </Text>
                        </div>
                    </div>
                    <InlineSVG raw className="hives-panel-hive-caret" src={Caret} />
                </a>
                {isFarmValidated && shouldDisplayProviderFolder && (
                    <a className="hives-panel-hive" href={linkTo('producers/folder')}>
                        <div className="hives-panel-hive-logo">
                            <InlineSVG src={MobileIconFolder} />
                        </div>
                        <div className="hives-panel-hive-content">
                            <div className="hives-panel-hive-content-title">
                                <Text size="16px" lineHeight="20px" weight={600} color="gray2">
                                    {trans('global.providerFolder')}
                                </Text>
                            </div>
                        </div>
                        <InlineSVG raw className="hives-panel-hive-caret" src={Caret} />
                    </a>
                )}
                {isFarmValidated && shouldDisplayAccounting && (
                    <a className="hives-panel-hive" href={linkTo('producers/accounting')}>
                        <div className="hives-panel-hive-logo">
                            <InlineSVG src={AccountingIcon} />
                        </div>
                        <div className="hives-panel-hive-content">
                            <div className="hives-panel-hive-content-title">
                                <Text size="16px" lineHeight="20px" weight={600} color="gray2">
                                    {trans('navigation.left.accounting')}
                                </Text>
                            </div>
                        </div>
                        <InlineSVG raw className="hives-panel-hive-caret" src={Caret} />
                    </a>
                )}
                {shouldDisplayAssemblies && (
                    <a className="hives-panel-hive" href={linkTo('producers/me/assemblies')}>
                        <div className="hives-panel-hive-logo">
                            <InlineSVG src={AssemblyIcon} />
                        </div>
                        <div className="hives-panel-hive-content">
                            <div className="hives-panel-hive-content-title">
                                <Text size="16px" lineHeight="20px" weight={600} color="gray2">
                                    {trans('navigation.bottom.assemblies')}
                                    {badge}
                                </Text>
                            </div>
                        </div>
                        <InlineSVG raw className="hives-panel-hive-caret" src={Caret} />
                    </a>
                )}
            </div>
        </div>
    );
};

HiveFarmSectionPanel.propTypes = {
    shouldDisplayProviderFolder: PropTypes.bool,
    shouldDisplayAccounting: PropTypes.bool,
    shouldDisplayAssemblies: PropTypes.bool,
    badgeValue: PropTypes.number,
};
export default HiveFarmSectionPanel;
