import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const supportedColors = ['success', 'warning', 'info', 'danger'];

export class Note extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        color: PropTypes.oneOf(supportedColors),
        tagName: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        tagName: 'span',
    };

    render() {
        const { children, color, tagName, className, ...otherProps } = this.props;

        const classes = ['note'];

        if (color) {
            classes.push(`note--${color}`);
        }

        return React.createElement(
            tagName,
            {
                ...otherProps,
                className: classnames(classes, className),
            },
            children
        );
    }
}

export default Note;
