import React, { lazy, Suspense } from 'react';
import AppChannel from 'modules/channels/App';

import LoadingAnimation from 'components/LoadingAnimation.jsx';
import LoginLayout from 'src/components/layouts/LoginLayout.jsx';

const Login = lazy(() => import('src/components/pages/Login/Login.jsx'));
const ForgotPassword = lazy(() => import('src/components/pages/ForgotPassword/ForgotPassword.jsx'));
const ForgotPasswordSuccess = lazy(() =>
    import('src/components/pages/ForgotPasswordSuccess/ForgotPasswordSuccess.jsx')
);
const RecoveryPassword = lazy(() =>
    import('src/components/pages/RecoveryPassword/RecoveryPassword.jsx')
);
const InvalidLink = lazy(() => import('src/components/pages/InvalidLink/InvalidLink.jsx'));
const Register = lazy(() => import('src/components/pages/Register/Register.jsx'));

// TODO: next evolution -> make Layout: https://reactrouter.com/en/main/start/concepts#layout-routes
export const v2Routes = [
    {
        conditions: ['isNotAuthenticated'],
        definition: '/login/:userId/:token',
        exact: true,
        // eslint-disable-next-line react/prop-types
        render: ({ match }) => {
            return (
                <LoginLayout>
                    <Suspense fallback={<LoadingAnimation type="spinner" />}>
                        <RecoveryPassword
                            user={{
                                id: match.params.userId,
                                token: match.params.token,
                            }}
                        />
                    </Suspense>
                </LoginLayout>
            );
        },
    },
    {
        definition: '/login/:redirectUrl?',
        // eslint-disable-next-line react/prop-types
        render: ({ currentUser }) => {
            if (currentUser && !currentUser.anonymous) {
                AppChannel.commands.execute(
                    'navigate',
                    AppChannel.reqres.request('homepage:route'),
                    {
                        trigger: true,
                        replace: true,
                    }
                );
                return null;
            }
            return (
                <LoginLayout>
                    <Suspense fallback={<LoadingAnimation type="spinner" />}>
                        <Login />
                    </Suspense>
                </LoginLayout>
            );
        },
    },
    {
        conditions: ['isNotAuthenticated'],
        definition: '/forgot_password/success',
        render: () => (
            <LoginLayout>
                <Suspense fallback={<LoadingAnimation type="spinner" />}>
                    <ForgotPasswordSuccess />
                </Suspense>
            </LoginLayout>
        ),
    },
    {
        conditions: ['isNotAuthenticated'],
        definition: '/forgot_password/invalid_link',
        render: () => (
            <LoginLayout>
                <Suspense fallback={<LoadingAnimation type="spinner" />}>
                    <InvalidLink />
                </Suspense>
            </LoginLayout>
        ),
    },
    {
        conditions: ['isNotAuthenticated'],
        definition: '/forgot_password',
        render: () => (
            <LoginLayout>
                <Suspense fallback={<LoadingAnimation type="spinner" />}>
                    <ForgotPassword />
                </Suspense>
            </LoginLayout>
        ),
    },
    {
        conditions: ['isNotAuthenticated'],
        definition: '/join/assembly/:assemblyId',
        exact: true,
        // eslint-disable-next-line react/prop-types
        render: ({ match }) => {
            if (isFinite(match.params.assemblyId)) {
                AppChannel.vent.trigger('assembly:join:show', match.params.assemblyId);
            }
            return (
                <LoginLayout>
                    <Suspense fallback={<LoadingAnimation type="spinner" />}>
                        <Register assemblyId={match.params.assemblyId} role="member" />
                    </Suspense>
                </LoginLayout>
            );
        },
    },
    {
        conditions: ['isNotAuthenticated'],
        definition: '/join',
        exact: true,
        render: () => {
            return (
                <LoginLayout>
                    <Suspense fallback={<LoadingAnimation type="spinner" />}>
                        <Register />
                    </Suspense>
                </LoginLayout>
            );
        },
    },
];

export default v2Routes;
