import React from 'react';
import PropTypes from 'prop-types';
import NewsItem from 'components/Newsfeed/NewsItem.jsx';
import * as R from 'ramda';
import { newsShape } from './NewsItem.jsx';

const NewsList = React.memo(({ newsListData, currentAssemblyId }) => (
    <div className="newsfeed">
        {R.map(
            news => (
                <NewsItem key={news.uuid} news={news} currentAssemblyId={currentAssemblyId} />
            ),
            newsListData
        )}
    </div>
));

NewsList.propTypes = {
    newsListData: PropTypes.arrayOf(PropTypes.shape(newsShape)),
    currentAssemblyId: PropTypes.number,
};

export default NewsList;
