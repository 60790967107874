import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Caret = ({ productIdentity }) => {
    return (
        <span
            aria-hidden="true"
            className={classnames('topNavigation-caret icon icon-angle-down', {
                'pi-typo-sui-16px': productIdentity,
            })}
        />
    );
};

Caret.propTypes = {
    productIdentity: PropTypes.bool,
};

export default Caret;
