import PropTypes from 'prop-types';
import React from 'react';
import ResponsiveNavigation from '../ResponsiveNavigation.jsx';
import NavigationRegion from '../NavigationRegion.jsx';
import ContextDropdown from '../ContextDropdown.jsx';
import UserDropdown from '../UserDropdown.jsx';
import HelpDropdown from '../HelpDropdown.jsx';
import HelpBottomDropdown from '../HelpBottomDropdown.jsx';
import UserBottomDropdown from '../UserBottomDropdown.jsx';
import LeftNavigationLink from '../LeftNavigationLink.jsx';
import MessagesLink from '../MessagesLink.jsx';
import BottomNavigationLink from '../BottomNavigationLink.jsx';
import connectToI18n from 'modules/i18n/connectToI18n';
import NewsfeedDropdown from 'components/Navigation/NewsfeedDropdown.jsx';
import NewsfeedBottomDropdown from 'components/Navigation/NewsfeedBottomDropdown.jsx';

export class HostPreconstructNavigation extends React.Component {
    static propTypes = {
        isSmallWidth: PropTypes.bool,
        currentAssembly: PropTypes.object,
        activeLink: PropTypes.string,
        trans: PropTypes.func.isRequired,
    };

    render() {
        const { isSmallWidth, currentAssembly, activeLink, trans } = this.props;

        if (!currentAssembly) {
            return null;
        }

        return (
            <ResponsiveNavigation isSmallWidth={isSmallWidth}>
                <NavigationRegion position="top" legacy>
                    <div className="topNavigation-left">
                        <ContextDropdown {...this.props} />
                    </div>
                    <div className="topNavigation-right">
                        <NewsfeedDropdown {...this.props} />
                        <MessagesLink {...this.props} />
                        <HelpDropdown {...this.props} />
                        <UserDropdown {...this.props} />
                    </div>
                </NavigationRegion>
                <NavigationRegion position="left">
                    <LeftNavigationLink
                        href={`assemblies/${currentAssembly.id}`}
                        icon="assembly"
                        isActive={activeLink === 'menu.home'}
                    >
                        {trans('navigation.left.home')}
                    </LeftNavigationLink>
                    <LeftNavigationLink
                        href={`assemblies/${currentAssembly.id}/progress`}
                        icon="progress"
                        isActive={activeLink === 'menu.progression'}
                    >
                        {trans('navigation.left.progress')}
                    </LeftNavigationLink>
                    <LeftNavigationLink
                        href={`assemblies/${currentAssembly.id}/preconstruction-space`}
                        icon="settings"
                        isActive={activeLink === 'menu.leader.configuration'}
                    >
                        {trans('navigation.left.settings')}
                    </LeftNavigationLink>
                </NavigationRegion>
                <NavigationRegion position="bottom">
                    <BottomNavigationLink icon="home" href={`assemblies/${currentAssembly.id}`}>
                        {trans('navigation.bottom.home')}
                    </BottomNavigationLink>
                    <BottomNavigationLink
                        icon="progress"
                        href={`assemblies/${currentAssembly.id}/progress`}
                    >
                        {trans('navigation.bottom.progress')}
                    </BottomNavigationLink>
                    <BottomNavigationLink />
                    <UserBottomDropdown {...this.props} />
                    <HelpBottomDropdown {...this.props} />
                    <NewsfeedBottomDropdown {...this.props} />
                </NavigationRegion>
            </ResponsiveNavigation>
        );
    }
}

export default connectToI18n(HostPreconstructNavigation);
