import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Heading, Text, Grid, Link, Button } from 'components/ui';
import LimitOverflowingContentWithEllipsis from 'components/LimitOverflowingContentWithEllipsis.jsx';
import { formatFullName } from 'models/users';
import Avatar from 'components/Avatar.jsx';
import Address from 'components/Address.jsx';
import connectToI18n from 'modules/i18n/connectToI18n';
import { getLatLng } from 'app/scripts/components/geo/placeHelpers.js';
import Utils from 'modules/utils';
const { linkTo } = Utils;
import * as R from 'ramda';

const TitleColumn = ({ children }) => (
    <Text weight="bold" size="large">
        {children}
    </Text>
);

TitleColumn.propTypes = {
    children: PropTypes.node.isRequired,
};

const TextColumn = ({ children }) => <Text size="large">{children}</Text>;

TextColumn.propTypes = {
    children: PropTypes.node.isRequired,
};

const ColumnRight = ({ children }) => (
    <div className="u-flexible-row u-flexible-row--pullRight">{children}</div>
);

ColumnRight.propTypes = {
    children: PropTypes.node.isRequired,
};

export class AnonymousContextPanel extends React.Component {
    static propTypes = {
        assembly: PropTypes.object.isRequired,
        trans: PropTypes.func.isRequired,
    };

    render() {
        const { trans, assembly } = this.props;
        const assemblyCoordinates = getLatLng('assembly', assembly);

        return (
            <div className="contextPanel contextPanel-anonymous">
                <Heading className="u-mb-4" size={2}>
                    <span className="u-text-capitalize">
                        {trans('selectDelivery.assembly', {
                            '%name%': `${assembly.place.address.city.name} - ${assembly.name}`,
                        })}
                    </span>
                </Heading>
                <LimitOverflowingContentWithEllipsis
                    maxHeight="118px"
                    collapsible
                    background="white"
                    className="u-mb-6 fa-text--x-large"
                >
                    {assembly.description}
                </LimitOverflowingContentWithEllipsis>
                <Grid xs="7|5" className="u-mb-6">
                    <Fragment>
                        <TitleColumn>{formatFullName(assembly.leader)}</TitleColumn>
                        <TextColumn>{trans('user.roles.host')}</TextColumn>
                    </Fragment>
                    <ColumnRight>
                        <Avatar
                            photoId={R.path(['leader', 'photo', 'id'], assembly)}
                            size="small"
                            rounded
                        />
                    </ColumnRight>
                </Grid>
                <Grid xs="7|5" className="u-mb-6">
                    <Fragment>
                        <TitleColumn>{trans('global.address')}</TitleColumn>
                        <TextColumn>{assembly.place.name}</TextColumn>
                        <Address address={assembly.place.address} />
                    </Fragment>
                    <ColumnRight>
                        <Link
                            href={linkTo(
                                `assemblies#13/${assemblyCoordinates.lat}/${assemblyCoordinates.lng}`
                            )}
                            className="u-right"
                            variant="primary"
                            noDecoration
                        >
                            {trans('farms.distributions.address.action')}
                        </Link>
                    </ColumnRight>
                </Grid>
                <Grid xs="7|5" className="u-mb-6">
                    <Fragment>
                        <TitleColumn>{trans('anonymous.context.information.title')}</TitleColumn>
                        <TextColumn>{trans('anonymous.context.information.text')}</TextColumn>
                    </Fragment>
                    <ColumnRight>
                        <Link
                            href={linkTo(`join/assembly/${assembly.id}`)}
                            className="u-right"
                            variant="primary"
                            noDecoration
                        >
                            {trans('anonymous.context.information.link')}
                        </Link>
                    </ColumnRight>
                </Grid>
                <Grid xs="7|5" className="u-mb-6">
                    <Fragment>
                        <TitleColumn>{trans('anonymous.context.hive.title')}</TitleColumn>
                        <TextColumn>
                            <i>{trans('brand.name')}</i> {trans('anonymous.context.hive.text')}
                        </TextColumn>
                    </Fragment>
                    <ColumnRight>
                        <Link
                            href={linkTo('assemblies')}
                            className="u-right"
                            variant="primary"
                            noDecoration
                        >
                            {trans('hives.find.title')}
                        </Link>
                    </ColumnRight>
                </Grid>
            </div>
        );
    }
}

export default connectToI18n(AnonymousContextPanel);
