import PropTypes from 'prop-types';
import { LOGISTIC_MODE } from 'models/offers.js';

const LOCAL = 'local';
const INTERREGIONAL = 'interregional';

const propType = PropTypes.oneOf([LOCAL, INTERREGIONAL]);

export const getCurrentCatalog = scope =>
    scope === INTERREGIONAL ? 'interregional-products' : 'products';

export { LOCAL, INTERREGIONAL, propType };

export const convertLogisticModeToLegacyScope = logisticMode => {
    if (logisticMode === LOGISTIC_MODE.DROP_OFF) {
        return LOCAL;
    }
    if (logisticMode === LOGISTIC_MODE.SHIPPING) {
        return INTERREGIONAL;
    }

    return LOCAL;
};
