import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { CategoryIcon } from 'components/Sale/CategoryIcon.jsx';
import PIText from 'components/ProductIdentity/Text.jsx';

const Navigation = forwardRef((props, ref) => {
    const { category, currentCategoryId } = props;
    const isActive = currentCategoryId === category.id;

    return (
        <NavLink
            ref={ref}
            to={`/category/${category.id}`}
            data-bypass
            className={classNames('saleTopNavigation-categoryItem', `cat_${category.id}`)}
            activeClassName="saleNavigation-categoryLabel--selected"
            isActive={() => currentCategoryId === category.id}
        >
            <span className="categoryIcon">
                <CategoryIcon categoryId={category.id} />
            </span>
            <PIText
                color={isActive ? 'turquoise2' : null}
                bold={isActive}
                size="12px"
                lineHeight="16px"
            >
                {category.name}
            </PIText>
        </NavLink>
    );
});

Navigation.propTypes = {
    category: PropTypes.object.isRequired,
    currentCategoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // 1, 2, highlighted,...
};

export default Navigation;
