import PropTypes from 'prop-types';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import Backbone from 'backbone';

import * as R from 'ramda';
import createResponsiveComponent from 'components/createResponsiveComponent';
import { get } from 'modules/api';
import { fetchHive } from 'api/hives';
import { switchDistribution } from 'modules/navigation';
import { fetchBasket } from 'modules/orders';
import { INITIAL_LOADING, READY } from 'modules/utils/ajaxStatuses';
import { getCurrentDistributions } from 'models/distributions';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import Sale from 'components/Sale/Sale.jsx';
import ScrollToTop from './ScrollToTop.jsx';
import RoutePageView from 'components/RoutePageView.jsx';
import connectToI18n from 'modules/i18n/connectToI18n';

export class SaleContainer extends React.Component {
    static propTypes = {
        assemblyId: PropTypes.number.isRequired,
        distributionId: PropTypes.number,
        fetchBasket: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
        isSmallWidth: PropTypes.bool,
        switchDistribution: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        productIdentity: PropTypes.bool,
    };

    state = {
        assembly: null,
        distribution: null,
        status: INITIAL_LOADING,
    };

    componentDidMount() {
        const { assemblyId, distributionId, productIdentity } = this.props;

        if (productIdentity) {
            document.body.classList.add('background-blue6');
        }
        const assemblyRequest = fetchHive(assemblyId);
        const distributionRequest = distributionId
            ? get(`distributions/${distributionId}?view=flat`)
            : Promise.resolve(null);

        Promise.all([assemblyRequest, distributionRequest]).then(this.onDataLoaded);
    }

    componentDidUpdate(prevProps) {
        const { distributionId, assemblyId, productIdentity } = this.props;
        if (prevProps.distributionId !== distributionId && productIdentity) {
            const assemblyRequest = !this.state.assembly
                ? fetchHive(assemblyId)
                : Promise.resolve(this.state.assembly);
            const distributionRequest = distributionId
                ? get(`distributions/${distributionId}?view=flat`)
                : Promise.resolve(null);

            Promise.all([assemblyRequest, distributionRequest]).then(this.onDataLoaded);
        }
    }
    onDataLoaded = ([assembly, distribution]) => {
        if (!distribution) {
            return this.redirectToSalePageOrHomePage(assembly);
        }

        // force basket reload in case the user who just authenticated also had an unauthenticated basket
        this.props.fetchBasket(distribution.id);

        this.props.switchDistribution(assembly, distribution);

        return this.setState({
            assembly,
            distribution,
            status: READY,
        });
    };

    redirectToSalePageOrHomePage = assembly => {
        const distributionId = R.pipe(
            getCurrentDistributions,
            R.head,
            R.prop('distributionId')
        )(assembly);

        if (!distributionId && this.props.user.anonymous) {
            // make sure to show server-side rendered version of assembly page to unauthenticated users
            return window.location.replace(`/${this.props.i18n}/assemblies/${assembly.id}`);
        }

        const redirectUrl = distributionId
            ? `/assemblies/${assembly.id}/collections/${distributionId}/products`
            : `/assemblies/${assembly.id}`;

        return Backbone.history.navigate(redirectUrl, {
            replace: true,
            trigger: true,
        });
    };

    render() {
        const { i18n, isSmallWidth, user, productIdentity } = this.props;

        const { assembly, distribution, status } = this.state;

        if (status !== READY) {
            return <LoadingAnimation type={productIdentity ? 'pi-spinner' : 'food'} />;
        }

        return (
            <Router
                basename={`/${i18n}/assemblies/${assembly.id}/collections/${distribution.id}/products`}
            >
                <RoutePageView />
                <ScrollToTop>
                    <Sale
                        assembly={assembly}
                        distribution={distribution}
                        i18n={i18n}
                        isSmallWidth={isSmallWidth}
                        user={user}
                    />
                </ScrollToTop>
            </Router>
        );
    }
}

const mapDispatchToProps = {
    fetchBasket,
    switchDistribution,
};

export default connectToI18n(
    connect(
        state => ({ user: state.currentUser.user }),
        mapDispatchToProps
    )(createResponsiveComponent(SaleContainer, { maxWidth: 767 }))
);
