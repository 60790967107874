import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import Button from 'components/Button.jsx';
import Date from 'components/I18n/Date.jsx';
import Utils from 'modules/utils';
const { filerPhoto } = Utils;
import { validate, validators } from '../../../modules/validation';
import { formatFullName } from 'models/users';
import LoadingAnimation from 'components/LoadingAnimation.jsx';

const WysiwygEditor = React.lazy(() => import('components/WysiwygEditor.jsx'));

import { addBypassRouterToLink } from 'modules/utils/text';

export class Discussion extends React.Component {
    static propTypes = {
        discussion: PropTypes.object,
        addMessageToDiscussion: PropTypes.func,
        deleteDiscussion: PropTypes.func,
        deleteMessageFromDiscussion: PropTypes.func,
        currentUser: PropTypes.object,
        isAssemblyLeader: PropTypes.bool.isRequired,
        trans: PropTypes.func.isRequired,
    };

    state = {
        content: '',
        title: '',
        isDiscussionOpen: false,
        showErrors: false,
        errors: null,
    };

    onInput = (e, value) => {
        this.setState(prevState => ({
            ...prevState,
            content: value,
            title: value,
        }));
    };

    onSave = e => {
        e.preventDefault();
        const [isValid, errors] = this.validateForm();
        if (!isValid) {
            this.setState({
                showErrors: true,
                errors,
            });
            return;
        }
        this.setState(state => {
            if (state.content !== '') {
                this.props.addMessageToDiscussion(state.content, this.props.discussion.id);
            }
            return {
                showErrors: false,
                errors: null,
                isDiscussionOpen: false,
                content: '',
                title: '',
            };
        });
    };

    validateForm = () => {
        const { assertNotBlank } = validators(this.props.trans);
        const requiredFields = ['content'];

        return validate.all(requiredFields.map(field => validate.prop(field, assertNotBlank)))(
            this.state
        );
    };

    handleClickDeleteDiscussion = discussionId => {
        this.props.deleteDiscussion(discussionId);
    };

    handleClickDeleteMessageFromDiscussion = (discussionId, messageId) => {
        this.props.deleteMessageFromDiscussion(discussionId, messageId);
    };

    renderDate = message => {
        if (message.created) {
            return (
                <div className="date">
                    {<Date date={message.created} format="M" />} –{' '}
                    {<Date date={message.created} format="t" />}
                </div>
            );
        }

        return (
            <div className="date">
                {this.props.trans('discussions.sending')}
                &hellip;
            </div>
        );
    };

    render() {
        const t = this.props.trans;
        const currentUserPicture = filerPhoto(this.props.currentUser.photoId, 'small', 'user');
        const renderDate = this.renderDate;
        const isAssemblyLeader = this.props.isAssemblyLeader;
        const discussionId = this.props.discussion.id;

        return (
            <div className="thread">
                {this.props.discussion.messages.map((message, index) => {
                    const profilePicture = filerPhoto(message.author.photoId, 'small', 'user');
                    const isAuthor = this.props.currentUser.uuid === message.author.uuid;
                    const messageId = message.uuid;
                    return (
                        <div key={message.uuid} className="message">
                            <div className="message-header">
                                <img
                                    className="image"
                                    src={profilePicture}
                                    height="48"
                                    width="48"
                                />
                                <div className="text">
                                    <p className="name">{formatFullName(message.author)}</p>
                                    <div className="action">
                                        {renderDate(message)}
                                        {(isAssemblyLeader || isAuthor) && (
                                            <Button
                                                weight="link"
                                                variant="danger"
                                                onClick={() => {
                                                    if (index === 0) {
                                                        this.handleClickDeleteDiscussion(
                                                            discussionId
                                                        );
                                                    } else {
                                                        this.handleClickDeleteMessageFromDiscussion(
                                                            discussionId,
                                                            messageId
                                                        );
                                                    }
                                                }}
                                            >
                                                {t('discussions.delete')}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="message-content">
                                <div
                                    className="message-content-title"
                                    dangerouslySetInnerHTML={{
                                        __html: message.title,
                                    }}
                                />
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: addBypassRouterToLink(message.content),
                                    }}
                                />
                                <div className="crop-bar">
                                    <span className="read-more">{t('global.readMore')}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}

                <form className="thread-footer" onSubmit={this.onFormSubmit}>
                    <div className="author">
                        <img className="image" src={currentUserPicture} height="48" width="48" />
                        <div className="arrow arrow-left" />
                    </div>
                    {!this.state.isDiscussionOpen && (
                        <textarea
                            ref={c => (this.textarea = c)}
                            name="content"
                            cols="30"
                            rows="10"
                            className="fa-input fa-input--block"
                            placeholder={t('discussions.reply')}
                            onFocus={() =>
                                this.setState(prevState => ({
                                    ...prevState,
                                    isDiscussionOpen: true,
                                }))
                            }
                        />
                    )}
                    {this.state.isDiscussionOpen && (
                        <>
                            <React.Suspense fallback={<LoadingAnimation type="spinner" />}>
                                <WysiwygEditor
                                    content={this.state.content}
                                    editContent={this.onInput}
                                    showErrors={this.state.showErrors}
                                    errors={this.state.errors?.content}
                                />
                            </React.Suspense>
                            <div className="buttonContainer">
                                <Button
                                    variant="success"
                                    onClick={this.onSave}
                                    onMouseDown={e => e.preventDefault()}
                                >
                                    {t('discussions.reply')}
                                </Button>
                            </div>
                        </>
                    )}
                </form>
            </div>
        );
    }
}

export default connectToI18n(Discussion);
