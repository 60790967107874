import { createSelector } from 'reselect';
import * as R from 'ramda';
import { isBasket, isOrder, basketsFirst, byMostRecentlyCreated } from 'models/orders';
import { LOADING, FAILED, LOADED } from '../../actions';
import { GLOBAL_STATE_KEY, makeTemporaryOrderId, makeTemporaryOrderItemId } from './index';

const isBasketOrOrder = R.either(isBasket, isOrder);
const basketOrderConditions = {
    basket: isBasket,
    order: isOrder,
    all: isBasketOrOrder,
    default: isBasket,
};
const getOrdersIds = state => state[GLOBAL_STATE_KEY].ids;
const getOrdersEntities = state => state[GLOBAL_STATE_KEY].byId;

const justGiveMeMyDistributionId = (_, { distributionId }) => distributionId;
const justGiveMeMyOrderStatus = (_, { orderStatus = 'basket' }) => orderStatus;
export const getOrders = createSelector(
    [getOrdersIds, getOrdersEntities],
    (ordersIds, orderEntities) =>
        R.sortWith(
            [basketsFirst, byMostRecentlyCreated],
            ordersIds.map(id => orderEntities[id])
        )
);

export const getOrderEntity = (state, { orderId }) => getOrdersEntities(state)[orderId];
const getOrderItemsEntities = state => state[GLOBAL_STATE_KEY].orderItems_byId;
export const getOrderItemEntity = (state, { orderItemId }) =>
    getOrderItemsEntities(state)[orderItemId];

export const itemsByFarm = createSelector(
    [getOrderEntity, getOrderItemsEntities],
    (order, orderItems) =>
        R.sort(
            R.ascend(R.pipe(R.head, R.prop('farmName'))),
            R.values(
                R.groupBy(
                    R.prop('farmName'),
                    order.items.map(orderItemId => orderItems[orderItemId])
                )
            )
        )
);

export const isInitialLoad = state => state[GLOBAL_STATE_KEY].isInitialLoad;
export const isOrdersCollectionLoading = state => state[GLOBAL_STATE_KEY].fetchStatus === LOADING;
export const isOrdersCollectionLoaded = state => state[GLOBAL_STATE_KEY].fetchStatus === LOADED;

export const isOrderLoading = (state, { orderId }) =>
    state[GLOBAL_STATE_KEY].orders_fetchStatus[orderId] === LOADING;
export const isOrderFailed = (state, { orderId }) =>
    state[GLOBAL_STATE_KEY].orders_fetchStatus[orderId] === FAILED;

export const getBasketOfDistribution = createSelector(
    [getOrdersEntities, getOrderItemsEntities, justGiveMeMyDistributionId, justGiveMeMyOrderStatus],
    (orders, orderItems, distributionId, orderStatus = 'default') => {
        const condition = basketOrderConditions[orderStatus];
        return R.pipe(
            R.filter(R.allPass([condition, R.propEq('distributionId', distributionId)])),
            R.head,
            R.when(
                x => x !== undefined,
                R.evolve({
                    items: R.pipe(
                        R.map(id => orderItems[id]),
                        R.sort(
                            R.comparator((a, b) => new Date(a.updatedAt) > new Date(b.updatedAt))
                        )
                    ),
                })
            )
        )(R.values(orders));
    }
);

// cache a basket order items indexed by distributionId_offerId
const getBasketItemsInternalRepresentation = createSelector(
    [getBasketOfDistribution],
    basket =>
        R.reduce(
            (acc, orderItem) => {
                acc[
                    makeTemporaryOrderItemId(basket.distributionId, orderItem.offer.id)
                ] = orderItem;
                return acc;
            },
            {},
            R.propOr([], 'items', basket)
        ) || {}
);

export const isBasketOfDistributionLoading = (state, { distributionId, orderId }) =>
    orderId
        ? isOrderLoading(state, { orderId })
        : state[GLOBAL_STATE_KEY].orders_fetchStatus[makeTemporaryOrderId(distributionId)] ===
          LOADING;

export const isOrderItemLoading = (state, { orderItemId, distributionId, offerId }) => {
    if (orderItemId) {
        return state[GLOBAL_STATE_KEY].orderItems_fetchStatus[orderItemId] === LOADING;
    }
    const orderItemId_ = makeTemporaryOrderItemId(distributionId, offerId);
    if (state[GLOBAL_STATE_KEY].orderItems_fetchStatus[orderItemId_]) {
        return state[GLOBAL_STATE_KEY].orderItems_fetchStatus[orderItemId_] === LOADING;
    }
    const orderItem = getBasketItemsInternalRepresentation(state, { distributionId })[orderItemId_];
    return (
        state[GLOBAL_STATE_KEY].orderItems_fetchStatus[R.propOr(undefined, 'id', orderItem)] ===
        LOADING
    );
};
export const getQuantityInBasket = (state, { distributionId, offerId, orderStatus }) =>
    R.propOr(
        0,
        'quantity',
        getBasketItemsInternalRepresentation(state, { distributionId, orderStatus })[
            makeTemporaryOrderItemId(distributionId, offerId)
        ]
    );

export const getQuantityInBasketByOffers = (state, { distributionId, offersId }) =>
    offersId.map(offerId => {
        return { id: offerId, quantity: getQuantityInBasket(state, { distributionId, offerId }) };
    });
