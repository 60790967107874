import _ from 'underscore';
import {
    assertNotBlank,
    assertChecked,
    assertValidEmail,
    assertLongerThan,
    assertStartWith,
    assertIsEqualTo,
    assertValidPassword,
    assertValidBirthdate,
    assertDateNotBlank,
    assertValidDate,
    assertValidRange,
    assertValidPhone,
    validate,
} from './raw';

const validators = trans => ({
    assertStartWith: str => assertStartWith(str)(trans('errors.form')),
    assertNotBlank: assertNotBlank(trans('formValidation.notblank')),
    assertChecked: assertChecked(trans('formValidation.required')),
    assertValidEmail: assertValidEmail(trans('formValidation.type.email')),
    assertLongerThan: min =>
        assertLongerThan(min)(trans('formValidation.minlength', { '%s': min })),
    assertIsEqualTo: to => assertIsEqualTo(to)(trans('formValidation.equalto')),
    assertValidPassword: assertValidPassword(trans('formValidation.type.password')),
    assertValidBirthdate: assertValidBirthdate(trans('formValidation.type.birthdate')),
    assertDateNotBlank: assertDateNotBlank(trans('formValidation.notblank')),
    assertValidDate: assertValidDate(trans('formValidation.type.date')),
    assertValidRange: (min, max) => assertValidRange(min, max)(trans('formValidation.type.range')),
});

export const validateNotEmpty = trans => fieldName => {
    const assert = assertNotBlank(trans('formValidation.notblank'));
    return {
        assertNotBlank: value => {
            const ret = validate.prop(fieldName, assert)({ [fieldName]: value });
            return !ret || _.get(ret, [fieldName, 0]);
        },
    };
};

export const validateEmail = trans => fieldName => {
    const assert = assertValidEmail(trans('formValidation.type.email'));
    return {
        assertValidEmail: value => {
            const ret = validate.prop(fieldName, assert)({ [fieldName]: value });
            return !ret || _.get(ret, [fieldName, 0]);
        },
    };
};

export const validatePhone = trans => fieldName => {
    const assert = assertValidPhone(trans('formValidation.type.phone'));
    return {
        assertValidPhone: value => {
            const ret = validate.prop(fieldName, assert)({ [fieldName]: value });
            return !ret || _.get(ret, [fieldName, 0]);
        },
    };
};

export const validatePassword = trans => fieldName => {
    const assert = assertValidPassword(trans('formValidation.type.password'));
    return {
        assertValidPassword: value => {
            const ret = validate.prop(fieldName, assert)({ [fieldName]: value });
            return !ret || _.get(ret, [fieldName, 0]);
        },
    };
};

export const validateChecked = trans => fieldName => {
    const assert = assertChecked(trans('formValidation.required'));
    return {
        assertChecked: value => {
            const ret = validate.prop(fieldName, assert)({ [fieldName]: value });
            return !ret || _.get(ret, [fieldName, 0]);
        },
    };
};
export const validateEquality = trans => (fieldName1, fieldName2) => {
    const assert = to => assertIsEqualTo(to)(trans('formValidation.equalto'));
    return {
        assertIsEqualTo: (value, formValues) => {
            const ret = validate.prop(
                fieldName1,
                assert(formValues[fieldName2])
            )({ [fieldName1]: value, [fieldName2]: formValues[fieldName2] });
            return !ret || _.get(ret, [fieldName1, 0]);
        },
    };
};
export const validateBirthdate = trans => fieldName => {
    const assert = assertValidBirthdate(trans('formValidation.type.birthdate'));
    return {
        assertValidBirthdate: value => {
            const ret = validate.prop(fieldName, assert)({ [fieldName]: value });
            return !ret || _.get(ret, [fieldName, 0]);
        },
    };
};
const reactFormValidators = trans => ({
    assertNotBlank: validateNotEmpty(trans),
    assertValidEmail: validateEmail(trans),
    assertValidPassword: validatePassword(trans),
    assertChecked: validateChecked(trans),
    assertIsEqualTo: validateEquality(trans),
    assertValidBirthdate: validateBirthdate(trans),
    assertValidPhone: validatePhone(trans),
});

export { validate } from './raw';
export { validators, reactFormValidators };
