import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { Box, BoxSection, Heading, Link } from 'components/ui';
import AssemblySummary from 'components/PublicPages/AssemblySummary.jsx';
import { getLatLng } from 'app/scripts/components/geo/placeHelpers.js';

export class NearbyAssemblies extends React.Component {
    static propTypes = {
        assembly: PropTypes.object.isRequired,
        nearbyAssemblies: PropTypes.array.isRequired,
        trans: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
    };

    render() {
        const { nearbyAssemblies, assembly, trans, i18n } = this.props;
        const assemblyCoordinates = getLatLng('assembly', assembly);

        return (
            <section className="u-mb-8">
                <div className="u-flexible-row u-mb-3">
                    <Heading size={1} className="u-flexible-item">
                        {trans('public.assembly.header.link.assemblies')}
                    </Heading>
                    <Link
                        href={`/${i18n}/assemblies#13/${assemblyCoordinates.lat}/${assemblyCoordinates.lng}`}
                        variant="primary"
                    >
                        {trans('global.seeOnMap')}
                    </Link>
                </div>
                <Box>
                    <img
                        width="100%"
                        src="/assets/images/public-pages/illu-assemblies.png"
                        srcSet="/assets/images/public-pages/illu-assemblies@2x.png 2x"
                        alt=""
                    />
                    {nearbyAssemblies.map(currentAssembly => (
                        <BoxSection bordered key={currentAssembly.id}>
                            <AssemblySummary assembly={currentAssembly} />
                        </BoxSection>
                    ))}
                </Box>
            </section>
        );
    }
}

export default connectToI18n(NearbyAssemblies);
