import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import Price from 'components/Price.jsx';

export class StartingAtPrice extends React.Component {
    static propTypes = {
        price: PropTypes.object.isRequired,
        className: PropTypes.string,
    };

    render() {
        const { price, className } = this.props;

        return (
            <Price
                className={className}
                price={price}
                textWithPrice={{ key: 'salePage.from', placeholder: '%price%' }}
            />
        );
    }
}

export default connectToI18n(StartingAtPrice);
