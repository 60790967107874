import PropTypes from 'prop-types';
import React from 'react';
import useI18n from 'hooks/I18n/useI18n';

import HelpCenterSearch from 'components/Navigation/ProductIdentity/HelpCenter/HelpCenterSearch.jsx';
import { Heading, Paragraph } from 'components/ui';
import HelpCenterContactCard from 'components/Navigation/ProductIdentity/HelpCenter/HelpCenterContactCard.jsx';
import { propType as statusPropType } from 'modules/utils/ajaxStatuses';
import { USER, HOST } from 'modules/utils/userTypes';
import { getLocaleByCode, getLocaleShortform } from 'modules/utils/locales';

import Link from 'src/components/atoms/Link/Link.jsx';

const HelpCenter = props => {
    const {
        searchValue,
        onSearch,
        loadingArticles,
        articles,
        promotedArticles,
        userType,
        status,
        supportContact,
        onMessage,
    } = props;
    const { trans, i18n } = useI18n();

    const localeConfig = getLocaleByCode(i18n);

    return (
        <div className="pi-helpCenter u-p-0">
            <Heading productIdentity family="inter" size={4}>
                {trans('menu.help.search')}
            </Heading>
            <HelpCenterSearch
                articles={articles}
                searchValue={searchValue}
                onSearch={onSearch}
                loadingArticles={loadingArticles}
                promotedArticles={promotedArticles}
                userType={userType}
                status={status}
            />
            {userType !== USER && (
                <footer className="helpCenter-footer">
                    <Paragraph productIdentity color="grey8" size="16px" lineHeight="24px">
                        {trans('helpCenter.contact.label')}
                        {userType === HOST && getLocaleShortform(localeConfig) !== 'fr' && (
                            <div>
                                {trans('helpCenter.contact.label.support.contact')}
                                <Link
                                    href={`mailto:${localeConfig.supportEmail}`}
                                    data-bypass="true"
                                    bypassRouter
                                >
                                    {localeConfig.supportEmail}
                                </Link>
                            </div>
                        )}
                    </Paragraph>
                    <HelpCenterContactCard
                        userType={userType}
                        onMessage={onMessage}
                        {...supportContact}
                    />
                </footer>
            )}
        </div>
    );
};

HelpCenter.propTypes = {
    supportContact: PropTypes.func,
    searchValue: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    onMessage: PropTypes.func.isRequired,
    loadingArticles: PropTypes.func.isRequired,
    articles: PropTypes.array.isRequired,
    promotedArticles: PropTypes.array.isRequired,
    userType: PropTypes.string.isRequired,
    status: statusPropType,
};
export default HelpCenter;
