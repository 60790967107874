import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { Notice, Button } from 'components/ui';
import Utils from 'modules/utils';
import { createSale } from 'models/calendar';
const { linkTo } = Utils;
import Backbone from 'backbone';
import { READY } from 'modules/utils/ajaxStatuses';

function redirectToPreparationPage(sale, assemblyId) {
    Backbone.history.navigate(`assemblies/${assemblyId}/collections/${sale.id}/prepare`, {
        trigger: true,
    });
}

export class SalePreparationBanner extends React.Component {
    static propTypes = {
        trans: PropTypes.func.isRequired,
        assemblyId: PropTypes.number.isRequired,
        nextSale: PropTypes.object,
        preparationSaleStatus: PropTypes.string.isRequired,
    };

    initNextSale = () => {
        createSale(this.props.nextSale, this.props.assemblyId).then(createdSale => {
            redirectToPreparationPage(createdSale, this.props.assemblyId);
        });
    };

    renderPrepareSaleButton = () => {
        if (this.props.preparationSaleStatus !== READY) {
            return (
                <div className="u-center">
                    <span className="spinner shown spinner--lg" />
                </div>
            );
        }
        if (this.props.nextSale && this.props.nextSale.id !== null) {
            return (
                <Button
                    href={linkTo(
                        `/assemblies/${this.props.assemblyId}/collections/${this.props.nextSale.id}/prepare`
                    )}
                    responsive
                    variant="success"
                >
                    {this.props.trans('distributions.prepare.continue')}
                </Button>
            );
        } else if (this.props.nextSale) {
            return (
                <Button onClick={this.initNextSale} responsive variant="success">
                    {this.props.trans('distributions.prepare')}
                </Button>
            );
        }
        return (
            <Button
                href={linkTo(`/assemblies/${this.props.assemblyId}/collections/upcoming`)}
                responsive
                variant="success"
            >
                {this.props.trans('distributions.prepare')}
            </Button>
        );
    };

    render() {
        const { trans } = this.props;

        return (
            <Notice
                type="hint"
                title={trans('sale.preparation.hostBanner.title')}
                className="u-mb-2"
                action={this.renderPrepareSaleButton()}
            >
                {trans('sale.preparation.hostBanner.text')}
            </Notice>
        );
    }
}

export default connectToI18n(SalePreparationBanner);
