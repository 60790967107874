import FeaturesService, { FEATURES } from 'models/features';

import { getCurrentAssembly } from 'modules/utils/windowReduxStore.js';

const GOODDEALS_MIN_PROMO = 5;

export const offerPromotionIsEnoughToBeInGoodDeal = offer => {
    const assembly = getCurrentAssembly();

    if (offer.promotion === undefined) {
        return false;
    }

    if (!FeaturesService.isFeatureActive(FEATURES.GOODDEAL_MINIMUM_PROMOTION_HIVE, assembly)) {
        return true;
    }

    return (
        ((offer.promotion.originalOfferPrice.amount - offer.price.amount) * 100) /
            offer.promotion.originalOfferPrice.amount >=
        GOODDEALS_MIN_PROMO
    );
};
