import { get } from 'modules/api';
import { initDeliveryOption } from 'modules/checkout/actions.pickup';
import { fetchAnonymousBasket } from 'api/baskets';
export const FETCH_BASKET_REQUEST_SENT = 'FETCH_BASKET_REQUEST_SENT';
export const FETCH_BASKET_SUCCEEDED = 'FETCH_BASKET_SUCCEEDED';
export const UPDATE_BASKET_PRICES = 'UPDATE_BASKET_PRICES';

const fetchBasketRequestSent = () => ({
    type: FETCH_BASKET_REQUEST_SENT,
});

const fetchBasketSucceeded = (basket, deliveryOptions) => ({
    type: FETCH_BASKET_SUCCEEDED,
    basket,
    deliveryOptions,
});

const fetchBasket = (basketId, isAnonymous) => dispatch => {
    dispatch(fetchBasketRequestSent());
    const req = isAnonymous ? fetchAnonymousBasket(basketId) : get(`orders/${basketId}/details`);

    return req.then(basket => {
        get(`distributions/${basket.distribution.id}/pickup-and-delivery-options`).then(
            deliveryOptions => {
                dispatch(fetchBasketSucceeded(basket, deliveryOptions));
                dispatch(initDeliveryOption(basket.distributionId, deliveryOptions));
            }
        );
    });
};

export const fetchPickupAndDelivry = basket => dispatch => {
    get(`distributions/${basket.distributionId}/pickup-and-delivery-options`).then(
        deliveryOptions => {
            dispatch(fetchBasketSucceeded(basket, deliveryOptions));
        }
    );
};

export const fetchBasketIfNeeded = (basketId, isAnonymous) => (dispatch, getState) => {
    const state = getState().checkout;
    if (!state.isFetchingBasket) {
        return dispatch(fetchBasket(basketId, isAnonymous));
    }
    return Promise.resolve();
};
