import { post, put } from 'modules/api';
import { LOCAL } from 'models/salesScopes';
import { VALIDATED_ACTION_PAYLOAD, REFUSED_ACTION_PAYLOAD } from 'models/demand';

export const sendInvitation = ({ toFarm, fromAssembly, message, scope = LOCAL }) => {
    return post('invitations/', {
        hive: fromAssembly,
        farm: toFarm,
        message,
        scope,
    });
};

export const acceptInvitation = (invitationId, deliveryMean = null, addAllCatalog = null) => {
    const payload = VALIDATED_ACTION_PAYLOAD;

    if (deliveryMean) {
        payload.deliveryMean = deliveryMean;
    }

    if (addAllCatalog !== null) {
        payload.addAllCatalog = addAllCatalog;
    }

    return put(`invitations/${invitationId}`, payload);
};

export const declineInvitation = invitationId => {
    return put(`invitations/${invitationId}`, REFUSED_ACTION_PAYLOAD);
};
