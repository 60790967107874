import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AssemblyPage from './AssemblyPage.jsx';
import RoutePageView from 'components/RoutePageView.jsx';

export class AssemblyPageContainer extends React.Component {
    state = {
        ...window.pageContext,
    };

    render() {
        return (
            <BrowserRouter
                basename={`${this.state.urlLocale}/assemblies/${this.state.assembly.id}`}
            >
                <RoutePageView />
                <AssemblyPage {...this.state} />
            </BrowserRouter>
        );
    }
}

export default AssemblyPageContainer;
