import PropTypes from 'prop-types';
import React from 'react';
import { Link, Grid, Heading, Text } from 'components/ui';
import connectToI18n from 'modules/i18n/connectToI18n';
import Utils from 'modules/utils';
const { filerLinkTo, linkTo } = Utils;

export class ProducersFeaturedList extends React.Component {
    static propTypes = {
        assemblyId: PropTypes.number.isRequired,
        photos: PropTypes.array.isRequired,
        userIsMember: PropTypes.bool,
        trans: PropTypes.func.isRequired,
    };

    renderEmpty() {
        const trans = this.props.trans;

        return (
            <Grid sm="4|8" className="u-mb-12">
                <div>
                    <Heading rank={2} size={3} className="u-mb-2">
                        {trans('hives.page.providersSectionTitle')}
                    </Heading>
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: trans('hives.page.noProvidersSectionBody'),
                        }}
                    />
                </div>
                <div className="assembly-no-providers-illustration">
                    <img src="/assets/images/illustrations/providers.png" alt="" />
                </div>
            </Grid>
        );
    }

    render() {
        const { assemblyId, photos, userIsMember, trans } = this.props;

        if (photos.length < 3) {
            return this.renderEmpty();
        }

        return (
            <div className="u-mb-12">
                <div className="u-flexible-row u-mb-2">
                    <Heading rank={2} size={3} className="u-mr-2">
                        {trans('hives.page.providersSectionTitle')}
                    </Heading>
                    {userIsMember && (
                        <Link href={linkTo(`assemblies/${assemblyId}/farms`)} variant="primary">
                            {trans('hives.providers')}
                        </Link>
                    )}
                </div>
                <Text
                    dangerouslySetInnerHTML={{
                        __html: trans('hives.page.providersSectionBody'),
                    }}
                />
                <a href={linkTo(`assemblies/${assemblyId}/farms`)}>
                    {photos.map(id => (
                        <img
                            key={id}
                            src={filerLinkTo(`/photo/${id}/view/medium`)}
                            className="photoGallery-photo"
                        />
                    ))}
                </a>
            </div>
        );
    }
}

export default connectToI18n(ProducersFeaturedList);
