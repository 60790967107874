import PropTypes from 'prop-types';
import React from 'react';
import BaseLoginContainer from 'components/Auth/BaseLoginContainer.jsx';
import { appLogin } from 'models/auth';
import Backbone from 'backbone';
import { connect } from 'react-redux';
import * as R from 'ramda';

export class AnonymousUserPanel extends React.Component {
    static propTypes = {
        assemblyId: PropTypes.number,
        ordersCollectionById: PropTypes.object,
    };

    handleSubmit = ({ email, password, remember }) => {
        // @TODO: for now will force reload the current url.
        // Would be better to set current user without reloading,
        // but will have to wait for full React/Redux.
        const redirectUrl = Backbone.history.getFragment();
        const { ordersCollectionById } = this.props;
        const anonymousBasketUuid = this.getBasketUuidFromOrdersCollection(ordersCollectionById);
        return appLogin(email, password, remember, redirectUrl, anonymousBasketUuid);
    };

    getBasketUuidFromOrdersCollection = ordersCollectionById => {
        const orderCollectionAnonymous = R.find(R.propEq('anonymous', true))(
            R.values(ordersCollectionById)
        );
        return R.prop('uuid')(orderCollectionAnonymous);
    };

    render() {
        return (
            <div className="userPanel userPanel--anonymous">
                <BaseLoginContainer
                    assemblyId={this.props.assemblyId}
                    showRememberMe
                    embedded
                    handleSubmit={this.handleSubmit}
                    hasTitle
                />
            </div>
        );
    }
}

export default connect(state => ({
    ordersCollectionById: state.ordersCollection.byId,
}))(AnonymousUserPanel);
