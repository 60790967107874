import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ClusteredMap from 'modules/maps/mapboxGL/clusteredMap';
import useI18n from 'hooks/I18n/useI18n';

export const AssembliesMap = ({
    currentAssembly,
    assemblies,
    pickups,
    onMarkerSelect,
    onMapClick,
    isSelectedMarkerLayerVisible,
    panToCoordinates,
}) => {
    const { i18n } = useI18n();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const isEventHandlersAttached = useRef(false);

    useEffect(() => {
        if (map.current === null) {
            map.current = new ClusteredMap(mapContainer, i18n);
        }

        return () => {
            if (!map.current) return;

            map.current = null;
            isEventHandlersAttached.current = false;
        };
    }, [i18n]);

    useEffect(() => {
        if (assemblies.length === 0 && pickups.length === 0) {
            return;
        }

        map.current.refreshMainSourceData(assemblies, pickups);

        if (!isEventHandlersAttached.current) {
            map.current.attachEventHandlers(onMarkerSelect, onMapClick);
            isEventHandlersAttached.current = true;
        }
    }, [assemblies, pickups, onMarkerSelect, onMapClick]);

    useEffect(() => {
        if (!isSelectedMarkerLayerVisible) {
            map.current.hideActiveMarker();
        }
    }, [isSelectedMarkerLayerVisible]);

    useEffect(() => {
        if (panToCoordinates === null) {
            return;
        }

        map.current.panTo(panToCoordinates);
    }, [panToCoordinates]);

    useEffect(() => {
        currentAssembly && map.current.selectHiveById(currentAssembly.properties.id);
    }, [currentAssembly]);

    return <div ref={mapContainer} className="pi-map"></div>;
};

AssembliesMap.propTypes = {
    assemblies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    pickups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onMarkerSelect: PropTypes.func.isRequired,
    onMapClick: PropTypes.func.isRequired,
    isSelectedMarkerLayerVisible: PropTypes.bool.isRequired,
    panToCoordinates: PropTypes.array,
    currentAssembly: PropTypes.shape({ properties: PropTypes.shape({ id: PropTypes.string }) }),
};

export default React.memo(AssembliesMap);
