import PropTypes from 'prop-types';
import React from 'react';
import Utils from 'modules/utils';
const { linkTo } = Utils;
import Badge from 'components/Badge.jsx';

const icons = {
    exchange: 'icon icon-exchange',
    basket: 'icon icon-lrqdo-panier',
    marker: 'icon icon-map-marker',
    info: 'icon icon-info-sign',
    catalog: 'icon icon-th',
};

const images = {
    home: '/assets/images/navigation/mobile/icon-mobile-home.svg',
    distributions: '/assets/images/navigation/mobile/icon-mobile-distributions.svg',
    'producer-folder': '/assets/images/navigation/mobile/icon-mobile-producer-folder.svg',
    catalog: '/assets/images/navigation/mobile/icon-mobile-catalog.svg',
    assemblies: '/assets/images/navigation/mobile/icon-mobile-assemblies.svg',
    progress: '/assets/images/navigation/mobile/icon-mobile-progress.svg',
    questionnaire: '/assets/images/navigation/mobile/icon-mobile-questionnaire.svg',
    sale: '/assets/images/navigation/mobile/icon-mobile-sale.svg',
    newsfeed: '/assets/images/navigation/mobile/icon-mobile-newsfeed.svg',
    statistics: '/assets/images/navigation/mobile/icon-mobile-statistics.svg',
};

export class BottomNavigationLink extends React.Component {
    static propTypes = {
        href: PropTypes.string,
        icon: PropTypes.string,
        children: PropTypes.string,
        badgeValue: PropTypes.number,
        onClick: PropTypes.func,
    };

    getIconClass = () => {
        return icons[this.props.icon] || `icon icon-${this.props.icon}`;
    };

    getImageSrc = () => {
        return images[this.props.icon];
    };

    render() {
        const imgSrc = this.getImageSrc();

        let illu;
        if (imgSrc) {
            illu = <img src={imgSrc} className="bottomNavigation-image" alt="" />;
        } else {
            illu = <span className={this.getIconClass()} />;
        }

        let badge;
        if (this.props.badgeValue && this.props.badgeValue > 0) {
            badge = <Badge className="bottomNavigationLink-badge">{this.props.badgeValue}</Badge>;
        }

        return (
            <a
                href={linkTo(this.props.href)}
                className="bottomNavigation-link"
                onClick={this.props.onClick}
            >
                {illu}
                {badge}
                <div>{this.props.children}</div>
            </a>
        );
    }
}

export default BottomNavigationLink;
