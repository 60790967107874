import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { linkTo } from 'modules/utils';
import { Heading, Button, Link, Icon } from 'components/ui';
import AssemblyName from 'components/AssemblyName.jsx';
import useI18n from 'hooks/I18n/useI18n.js';
import useAnalytics from 'hooks/Analytics/useAnalytics.js';
import * as R from 'ramda';

export const AssembliesSection = ({
    userAssemblies,
    hostAssemblies,
    hasPendingLeaderProfile,
    canCreateLeaderProfile,
    createLeaderProfile,
    isUserFrench,
    hasFarm,
    shouldShowMiniRucheLink = false,
}) => {
    const { trans, i18nConfig } = useI18n();
    const { analytics } = useAnalytics();

    const trackMiniRucheLink = () => {
        analytics.track('clickMiniRucheCreationShortcut', {
            category: 'Pickups',
        });
    };

    const renderAssemblyBlock = assemblies => {
        if (!assemblies || assemblies.length === 0) {
            return null;
        }

        return (
            <div className="u-mb-2">
                {assemblies.map(assembly => (
                    <a
                        key={assembly.id}
                        href={linkTo(`assemblies/${assembly.id}`)}
                        className={`contextPanel-assembly contextPanel-assembly--${assembly.theme.name}`}
                    >
                        <img
                            className="contextPanel-assemblyIcon"
                            src="/assets/images/icons/icon-assembly.svg"
                        />
                        <AssemblyName
                            className="contextPanel-assemblyName"
                            assembly={assembly}
                            showOpenSaleTag
                        />
                        <Icon src="angle-right" color="maroon" size="xx-large" />
                    </a>
                ))}
            </div>
        );
    };

    const hasHostAssemblies = (hostAssemblies && hostAssemblies.length) || hasPendingLeaderProfile;

    return (
        <div>
            {hasHostAssemblies && (
                <div className="u-mb-4">
                    <div className="u-mb-2">
                        {shouldShowMiniRucheLink && (
                            <Button
                                weight="link"
                                variant="primary"
                                href={`https://miniruche.com/${R.propOr(
                                    'fr-FR',
                                    'code',
                                    i18nConfig
                                )}`}
                                noPadding
                                target="_blank"
                                onClick={trackMiniRucheLink}
                            >
                                {trans('navigation.context.createMiniRuche')}
                            </Button>
                        )}
                    </div>
                    {renderAssemblyBlock(hostAssemblies)}
                    {hasPendingLeaderProfile && (
                        <a
                            href={linkTo('progress')}
                            className="contextPanel-assembly contextPanel-assembly--pending"
                        >
                            <img
                                className="contextPanel-assemblyIcon"
                                src="/assets/images/icons/icon-assembly-construction.svg"
                            />

                            <span className="contextPanel-assemblyPendingName">
                                {trans('hives.newHiveName')}
                            </span>
                            <Icon src="angle-right" color="maroon" size="xx-large" />
                        </a>
                    )}
                </div>
            )}
            <div className="u-mb-4">
                <div className="u-mb-2">
                    {hasHostAssemblies && (
                        <Heading size={2} className="u-mb-1">
                            {trans('navigation.context.assemblies_as_member_new')}
                        </Heading>
                    )}
                    <div>
                        <Button
                            weight="link"
                            variant="primary"
                            noPadding
                            href={linkTo('configuration/account/assemblies')}
                        >
                            {trans('member.panel.manageHives')}
                        </Button>{' '}
                        -{' '}
                        <Button
                            weight="link"
                            variant="primary"
                            noPadding
                            href={linkTo('assemblies')}
                        >
                            {trans('menu.joinHive')}
                        </Button>
                    </div>
                </div>
                {renderAssemblyBlock(userAssemblies)}
            </div>
            <div
                className={classnames({
                    'u-mb-1': !hasFarm,
                    'u-mb-4': hasFarm,
                })}
            >
                {canCreateLeaderProfile && !hasPendingLeaderProfile && (
                    <React.Fragment>
                        {isUserFrench ? (
                            <Link
                                href="https://ouvrir-une-ruche.laruchequiditoui.fr/"
                                target="_blank"
                                variant="primary"
                                className="fa-text--uiMedium"
                            >
                                {trans('hives.create.title')}
                            </Link>
                        ) : (
                            <Button
                                variant="primary"
                                className="button--noPadding create-leader-profile-link"
                                weight="link"
                                onClick={createLeaderProfile}
                            >
                                {trans('hives.create.title')}
                            </Button>
                        )}
                    </React.Fragment>
                )}
                {hasPendingLeaderProfile && (
                    <Link href={linkTo('progress')} variant="primary" className="fa-text--uiMedium">
                        {trans('initLeaderProfile.actionTextRestart')}
                    </Link>
                )}
            </div>
            <div className="u-mb-1">
                <Link href={linkTo('accomodate')} variant="primary" className="fa-text--uiMedium">
                    {trans('local.propose')}
                </Link>
            </div>
        </div>
    );
};

AssembliesSection.propTypes = {
    userAssemblies: PropTypes.array,
    hostAssemblies: PropTypes.array,
    hasPendingLeaderProfile: PropTypes.bool,
    shouldShowMiniRucheLink: PropTypes.bool,
    canCreateLeaderProfile: PropTypes.bool.isRequired,
    createLeaderProfile: PropTypes.func.isRequired,
    isUserFrench: PropTypes.bool,
    hasFarm: PropTypes.bool,
};

export default AssembliesSection;
