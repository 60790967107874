import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { Box, MediaObject, Text } from 'components/ui';
import Avatar from 'components/Avatar.jsx';
import { convertRawTextToHtml } from 'modules/utils/text';
import { formatFullName } from 'models/users';

export const WelcomeMessage = ({ message, host, trans }) => (
    <Box className="u-mb-6">
        <MediaObject media={<Avatar photoId={host.photo && host.photo.id} size="xmedium" />}>
            <div>
                <Text
                    tagName="div"
                    serif
                    size="x-large"
                    dangerouslySetInnerHTML={{ __html: convertRawTextToHtml(message) }}
                    className="u-mb-4"
                />
                <Text weight="bold">{formatFullName(host)}</Text>
                <Text color="info">{trans('user.roles.host')}</Text>
            </div>
        </MediaObject>
    </Box>
);

WelcomeMessage.propTypes = {
    message: PropTypes.string.isRequired,
    host: PropTypes.object.isRequired,
    trans: PropTypes.func.isRequired,
};

export default connectToI18n(WelcomeMessage);
