import * as R from 'ramda';
import { addFarmAndLabelsToProduct } from 'models/products';
import createScopedReducer from '../../reducers/createScopedReducer';
import reducerRegistry from '../../reducers/ReducerRegistry';
import { NONE, LOADING, LOADED, FAILED, createApiActionTypes } from '../../actions';

export const actionTypes = {
    ADD_SEARCHED_PRODUCT_TO_BASKET: createApiActionTypes(
        'productsSearch',
        'ADD_SEARCHED_PRODUCT_TO_BASKET'
    ),
    SET_PRODUCTS_SEARCH: createApiActionTypes('productsSearch', 'SET_PRODUCTS_SEARCH'),
    CLEAR_PRODUCTS_SEARCH: 'productsSearch/CLEAR_PRODUCTS_SEARCH',
};

export const GLOBAL_STATE_KEY = 'productsSearch';

const initialProductsState = {
    fetchStatus: NONE,
    isInitialLoad: true,
    ids: [],
    byId: {},
    error: undefined,
};

export const productsSearchReducer = (state = initialProductsState, { type, payload }) => {
    switch (type) {
        case actionTypes.SET_PRODUCTS_SEARCH.REQUEST:
            return {
                ...state,
                searchString: payload.searchString,
                ids: [],
                fetchStatus: LOADING,
            };
        case actionTypes.SET_PRODUCTS_SEARCH.SUCCESS:
            const searchResultsKeyword = payload.response.keyword;
            if (searchResultsKeyword === state.searchString) {
                return {
                    ...state,
                    ids: payload.response.products.map(product => product.id),
                    byId: payload.response.products.reduce((acc, product) => {
                        acc[product.id] = addFarmAndLabelsToProduct(
                            product,
                            payload.response.farms,
                            payload.response.labels
                        );
                        return acc;
                    }, {}),
                    searchQueryId: payload.response.search_query_id,
                    fetchStatus: LOADED,
                };
            }
            return state;
        case actionTypes.SET_PRODUCTS_SEARCH.FAILURE:
            return {
                ...state,
                _error: payload.error,
                fetchStatus: FAILED,
            };
        case actionTypes.ADD_SEARCHED_PRODUCT_TO_BASKET.REQUEST:
            return {
                ...state,
                byId: R.map(product => {
                    return {
                        ...product,
                        offers: R.map(offer => {
                            if (offer.id === payload.offerId) {
                                return R.assoc('status', 'ADDING_TO_BASKET', offer);
                            }
                            return offer;
                        }, product.offers),
                    };
                }, state.byId),
            };
        case actionTypes.CLEAR_PRODUCTS_SEARCH:
            return {
                ...state,
                byId: [],
                ids: [],
                searchString: payload.searchString || '',
                fetchStatus: LOADED,
            };
        default:
            return state;
    }
};

reducerRegistry.register({
    productsSearch: createScopedReducer(productsSearchReducer, {
        contextKey: 'distributionId',
        actionTypes: [
            ...R.values(actionTypes.ADD_SEARCHED_PRODUCT_TO_BASKET),
            ...R.values(actionTypes.SET_PRODUCTS_SEARCH),
            actionTypes.CLEAR_PRODUCTS_SEARCH,
        ],
    }),
});

export { addSearchResultToBasket, clearSearch, searchProducts, setSearchString } from './actions';

export {
    getCurrentSearchId,
    getProductsScope,
    getProducts,
    getSearchString,
    isFetchingSearchResults,
} from './selectors';
