import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { Button, Heading } from 'components/ui';
import Utils from 'modules/utils';
const { getAssemblyTitle } = Utils;
import { getLocalizedDay, getLocalizedTime } from 'modules/utils/dateAndTime';
import { isMemberOfAssembly } from 'models/users';
import { hasExtraDeliveryTimeSlot } from 'models/assembly.js';

export class AssemblyHeader extends React.Component {
    static propTypes = {
        assembly: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        joinAssembly: PropTypes.func,
        trans: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
    };

    state = {
        isJoining: false,
    };

    onJoin = () => {
        this.setState({ isJoining: true });
        this.props.joinAssembly();
    };

    getTimeSlotMessage = () => {
        const { assembly, i18n } = this.props;
        if (assembly.status !== 'open') {
            return this.props.trans('hives.pageHeaderAsVisitor.constructionMessage');
        }
        const isAssemblyHasExtraDelivery = hasExtraDeliveryTimeSlot(assembly);
        const { day, starting, ending } = assembly.deliveryTimeSlot;

        if (isAssemblyHasExtraDelivery) {
            const {
                day: extraDay,
                starting: extraStarting,
                ending: extraEnding,
            } = assembly.extraDeliveryTimeSlot;
            return this.props.trans('hives.pageHeaderAsVisitor.openMessageWithExtraTimeSlot', {
                '%day%': getLocalizedDay(i18n, day),
                '%startingHour%': getLocalizedTime(i18n, starting),
                '%endingHour%': getLocalizedTime(i18n, ending),
                '%extraDay%': getLocalizedDay(i18n, extraDay),
                '%extraStartingHour%': getLocalizedTime(i18n, extraStarting),
                '%extraEndingHour%': getLocalizedTime(i18n, extraEnding),
            });
        }
        return this.props.trans('hives.pageHeaderAsVisitor.openMessage', {
            '%day%': getLocalizedDay(i18n, day),
            '%startingHour%': getLocalizedTime(i18n, starting),
            '%endingHour%': getLocalizedTime(i18n, ending),
        });
    };

    render() {
        const { trans, assembly, user } = this.props;

        const isMember = isMemberOfAssembly(assembly.id, user);

        return (
            <div className={`assemblyHome-header color-${assembly.theme.name}`}>
                <Heading size={1} className="title">
                    {getAssemblyTitle(assembly)}
                </Heading>
                <div className="address">{assembly.name}</div>
                {assembly.status !== 'closed' && (
                    <Fragment>
                        <div
                            className="u-mb-4"
                            dangerouslySetInnerHTML={{
                                __html: this.getTimeSlotMessage(),
                            }}
                        />
                        {!isMember && (
                            <Button
                                rounded
                                extended
                                variant="primary"
                                onClick={this.onJoin}
                                disabled={this.state.isJoining}
                                className="joinAssemblyButton"
                            >
                                {trans('hives.pageHeaderAsVisitor.joinHive')}
                            </Button>
                        )}
                    </Fragment>
                )}
            </div>
        );
    }
}

export default connectToI18n(AssemblyHeader);
