import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Image = ({ className, rounded, src, ...rest }) => {
    return (
        <img
            className={classnames('image', { 'image--rounded': rounded }, className)}
            src={src}
            {...rest}
        />
    );
};

Image.propTypes = {
    className: PropTypes.string,
    rounded: PropTypes.bool,
    src: PropTypes.string.isRequired,
};

export default Image;
