import PropTypes from 'prop-types';
import React from 'react';
import Backbone from 'backbone';
import connectToI18n from 'modules/i18n/connectToI18n';
import { Button, Icon } from 'components/ui';
import angleLeft from 'icons/angle-left.svg';

export class BackButton extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        trans: PropTypes.func.isRequired,
        href: PropTypes.string,
        iconOnly: PropTypes.bool,
    };

    back = event => {
        event.preventDefault();
        Backbone.history.history.back();
        return;
    };

    render() {
        const { children, trans, iconOnly, ...props } = this.props;

        if (iconOnly) {
            return (
                <Button onClick={this.props.href ? null : this.back} weight="link" {...props}>
                    <Icon size="xx-large" src={angleLeft} />
                </Button>
            );
        }

        return (
            <Button
                icon="angle-left"
                weight="link"
                iconPosition="outside"
                onClick={this.props.href ? null : this.back}
                {...props}
            >
                {children || trans('global.back')}
            </Button>
        );
    }
}

export default connectToI18n(BackButton);
