import React from 'react';
import classNames from 'classnames';
import InlineSVG from 'svg-inline-react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import useI18n from 'hooks/I18n/useI18n.js';
import useResponsive from 'hooks/Navigation/useResponsive';
import { SAVED, propType as statusPropType } from 'modules/utils/ajaxStatuses';

import Button from 'src/components/atoms/Button/Button.jsx';
import Text from 'components/ProductIdentity/Text.jsx';

import StayInTouchDesktop from 'app/assets/new-design/images/illustrations/stay-in-touch.svg';
import StayInTouchMobile from 'app/assets/new-design/images/illustrations/stay-in-touch-mobile.svg';
import checkIcon from 'icons/check.svg';

function KeepInTouch({ joinAssembly, joinAssemblyStatus, className }) {
    const { trans } = useI18n();
    const isSmallWidth = useResponsive();

    return (
        <div className={classNames('pi-keep-in-touch', className)}>
            <div className="pi-keep-in-touch-img">
                <InlineSVG raw src={isSmallWidth ? StayInTouchMobile : StayInTouchDesktop} />
            </div>
            <div className="pi-keep-in-touch-content">
                <Text
                    className="pi-keep-in-touch-title"
                    family="mr"
                    color="grey8"
                    size="18px"
                    lineHeight="28px"
                    bold
                >
                    {trans('anonymous.registration.push.title')}
                </Text>
                <Text family="inter" color="gray2" size="16px" lineHeight="24px">
                    {trans('anonymous.registration.push.text')}
                </Text>
                {joinAssemblyStatus !== SAVED && (
                    <Button size="small" onClick={joinAssembly}>
                        {trans('assembly.follow')}
                    </Button>
                )}
                {joinAssemblyStatus === SAVED && (
                    <Text family="inter" color="success" icon={checkIcon} weight="bold">
                        {trans('assembly.following')}
                    </Text>
                )}
            </div>
        </div>
    );
}

KeepInTouch.propTypes = {
    joinAssembly: PropTypes.func.isRequired,
    joinAssemblyStatus: statusPropType,
    className: PropTypes.string,
};
KeepInTouch.defaultProps = {
    className: '',
};
export default connectToI18n(KeepInTouch);
