import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export class Badge extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.node,
    };

    render() {
        return (
            <span className={classnames('badge', this.props.className)}>{this.props.children}</span>
        );
    }
}

export default Badge;
