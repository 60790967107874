import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import Heading from 'components/Heading.jsx';
import MediaObject from 'components/MediaObject.jsx';
import Avatar from 'components/Avatar.jsx';
import Text from 'components/Text.jsx';
import Link from 'components/Link.jsx';
import Icon from 'components/Icon.jsx';
import phoneIcon from 'icons/phone.svg';
import Utils from 'modules/utils';
const { linkTo } = Utils;
import { MEMBER } from 'modules/utils/userTypes';

export class HelpCenterContactCard extends React.Component {
    static propTypes = {
        trans: PropTypes.func.isRequired,
        onMessage: PropTypes.func,
        header: PropTypes.string,
        label: PropTypes.string,
        phone: PropTypes.string.isRequired,
        email: PropTypes.string,
        photo: PropTypes.object,
        userType: PropTypes.string.isRequired,
        hostId: PropTypes.number,
    };

    render() {
        return (
            <div>
                {this.props.header && (
                    <Heading size={4} className="u-mb-2">
                        {this.props.header}
                    </Heading>
                )}
                <MediaObject
                    media={
                        <Avatar photoId={this.props.photo ? this.props.photo.id : null} rounded />
                    }
                >
                    <Text size="large">{this.props.label}</Text>
                    <Text size="large">
                        <Icon className="u-mr-1" src={phoneIcon} size="large" color="maroon" />
                        {this.props.phone}
                    </Text>
                    <span className="helpCenter-messageLink">
                        <Icon
                            className="u-mr-1 helpCenter-messageLink-icon"
                            src="envelope-alt"
                            size="large"
                            color="warning"
                        />
                        {this.props.userType === MEMBER ? (
                            <Link
                                className="helpCenter-messageLink-text"
                                variant="primary"
                                onClick={this.props.onMessage}
                                href={linkTo(`messages/contact/${this.props.hostId}`)}
                            >
                                {this.props.trans('helpCenter.contact.send.message')}
                            </Link>
                        ) : (
                            <Link
                                className="helpCenter-messageLink-text"
                                variant="primary"
                                href={`mailto:${this.props.email}`}
                                data-bypass="true"
                            >
                                {this.props.email}
                            </Link>
                        )}
                    </span>
                </MediaObject>
            </div>
        );
    }
}

export default connectToI18n(HelpCenterContactCard);
