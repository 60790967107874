import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import connectToI18n from 'modules/i18n/connectToI18n';
import { getLocaleByCode, getLocaleShortcut } from 'modules/utils/locales';
import { getChristmasLogoForEnabledCountries } from 'modules/logo';
import { Link, Wrapper } from 'components/ui';

export class NewPublicHeader extends React.Component {
    static propTypes = {
        trans: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
    };

    render() {
        const { i18n, trans } = this.props;

        const locale = getLocaleByCode(i18n);
        const logo = getChristmasLogoForEnabledCountries(locale.logos.brown, locale);

        return (
            <div className="publicHeader">
                <div className={classnames('topNavigation topNavigation--relative')}>
                    <Wrapper size="medium" xsPadding margin="none">
                        <Link href={`/${i18n}`} data-bypass className="topNavigation-left">
                            <img alt={trans('brand.name')} src={logo} />
                        </Link>
                        <Link
                            noDecoration
                            className="u-right"
                            href={`/${getLocaleShortcut(locale)}/login`}
                        >
                            {trans('header.login')}
                        </Link>
                    </Wrapper>
                </div>
            </div>
        );
    }
}

export default connectToI18n(NewPublicHeader);
