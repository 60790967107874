import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Impersonate, { getUserEmailFromUsernameRouter } from 'modules/impersonate';
import { isEmailValid } from 'modules/utils/string.js';

const ImpersonateRedirect = () => {
    const { username } = useParams();
    const redirectUrl = new URLSearchParams(window.location.search).get('redirectUrl');

    const userEmail = getUserEmailFromUsernameRouter(username);

    if (userEmail && isEmailValid(userEmail)) {
        Impersonate.set(userEmail);
    } else {
        Impersonate.clear();
    }
    const loginUrl = redirectUrl ? `/login?redirectUrl=${redirectUrl}` : '/login';

    return <Redirect to={loginUrl} />;
};

export default ImpersonateRedirect;
