import PropTypes from 'prop-types';
import React from 'react';
import Utils from 'modules/utils';
const { filerPhoto } = Utils;
import classnames from 'classnames';
import ProductLabel from 'components/Product/ProductLabel.jsx';
import { Text } from 'components/ui';
import { getLocalizedDate } from 'modules/utils/dateAndTime';
import useI18n from 'hooks/I18n/useI18n.js';

const ProductImage = ({
    className,
    photoId,
    labels = [],
    productCategoryId,
    isLabelDescriptionAvailable,
    noBackgroundColor,
    size = 'small',
    productName,
    src,
    distributionDate,
}) => {
    const { trans, i18n } = useI18n();
    const getImageResolution = sizeData => {
        switch (sizeData) {
            case 'full':
                return 'large';
            case 'xsmall':
                return 'small';
            default:
                return sizeData;
        }
    };

    let defaultSrc;
    if (!src) {
        if (!photoId) {
            const categoryId = productCategoryId || 'default';
            defaultSrc = `/assets/images/products/product-${categoryId}.png`;
        } else {
            defaultSrc = filerPhoto(photoId, getImageResolution(size), 'product');
        }
    }
    return (
        <div
            className={classnames(
                'productImage-imageContainer',
                { [`productImage-imageContainer--${size}`]: size },
                {
                    'productImage-imageContainer--noBackgroundColor': noBackgroundColor,
                },
                className
            )}
        >
            <img src={src || defaultSrc} className="productImage-image" alt={productName} />
            {labels.length > 0 && (
                <div
                    className={classnames('labels', {
                        'labels--moreThanOne': labels.length > 1,
                    })}
                >
                    {labels.map(label => (
                        <ProductLabel
                            key={label.id}
                            name={label.name}
                            description={isLabelDescriptionAvailable ? label.description : null}
                            photoId={label.photoId}
                        />
                    ))}
                </div>
            )}
            {distributionDate && (
                <Text className="verticalProductCard-distributionDate">
                    {trans('salePage.product.distributionDate', {
                        '%distributionDate%': getLocalizedDate(
                            i18n,
                            'dateWithoutYear',
                            distributionDate
                        ),
                    })}
                </Text>
            )}
        </div>
    );
};

ProductImage.propTypes = {
    className: PropTypes.string,
    photoId: PropTypes.string,
    labels: PropTypes.array,
    productCategoryId: PropTypes.number,
    isLabelDescriptionAvailable: PropTypes.bool,
    noBackgroundColor: PropTypes.bool,
    size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'full']),
    productName: PropTypes.string.isRequired,
    src: PropTypes.string,
    distributionDate: PropTypes.string,
};

export default ProductImage;
