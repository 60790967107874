import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as R from 'ramda';
import classnames from 'classnames';
import connectToI18n from 'modules/i18n/connectToI18n';
import Heading from 'components/Heading.jsx';
import Separator from 'components/Separator.jsx';
import Modal from 'components/Modal.jsx';
import Offer from './Offer.jsx';

const propTypes = {
    addOfferToBasket: PropTypes.func.isRequired,
    className: PropTypes.string,
    closeModal: PropTypes.func.isRequired,
    distributionId: PropTypes.number.isRequired,
    i18n: PropTypes.string.isRequired,
    isSmallWidth: PropTypes.bool,
    product: PropTypes.shape({}).isRequired,
    actionOrigin: PropTypes.string,
};

export class OffersModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            addOfferToBasket,
            className,
            distributionId,
            i18n,
            isSmallWidth,
            product,
            actionOrigin,
        } = this.props;

        return (
            <Modal
                className={classnames('salePageModal', className)}
                closeModal={this.props.closeModal}
                isOpened
                size="small"
            >
                <div>
                    <Heading className="salePageModal-multiOfferTitle" size={3}>
                        {product.name}
                    </Heading>
                    <Separator className="salePageModal-separator" />
                    {product.offers.map(offer => {
                        return (
                            <div key={offer.id}>
                                <Offer
                                    displayPricePerQuantity
                                    distributionId={distributionId}
                                    i18n={i18n}
                                    isSmallWidth={isSmallWidth}
                                    offer={offer}
                                    addOfferToBasket={addOfferToBasket}
                                    product={product}
                                    actionOrigin={actionOrigin}
                                />
                                {offer !== R.last(product.offers) && (
                                    <Separator className="salePageModal-separator" />
                                )}
                            </div>
                        );
                    })}
                </div>
            </Modal>
        );
    }
}

OffersModal.propTypes = propTypes;

export default connectToI18n(OffersModal);
