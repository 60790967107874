import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';
import OptionsModal from 'components/OptionsModal.jsx';

export default class FormSelect extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onChange: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string,
                options: PropTypes.array,
            })
        ).isRequired,
        emptyOption: PropTypes.string,
        variant: PropTypes.oneOf(['inverse', 'productIdentity']),
        hasError: PropTypes.bool,
        disabled: PropTypes.bool,
        block: PropTypes.bool,
        className: PropTypes.string,
        displayOptionsModal: PropTypes.bool,
    };

    static defaultProps = {
        displayOptionsModal: false,
        disabled: false,
        variant: null,
        block: false,
    };

    state = {
        isOptionsModalOpened: false,
    };

    onClick = () => {
        this.setState({ isOptionsModalOpened: true });
    };

    closeOptionsModal = () => {
        this.setState({ isOptionsModalOpened: false });
    };

    handleChange = e => {
        this.props.onChange(e.target.name, e.target.value);
    };

    getModalSelectedOptionLabel = () => {
        let flattenedOptions = this.props.options;

        const hasOptionGroup = this.props.options.some(option => !!option.options);
        if (hasOptionGroup) {
            flattenedOptions = R.pipe(R.pluck('options'), R.flatten)(flattenedOptions);
        }

        const selectedOption = R.find(R.propEq('value', this.props.value))(flattenedOptions);

        return selectedOption ? selectedOption.label : this.props.emptyOption;
    };

    renderOptionGroup = optionGroup => {
        return (
            <optgroup key={optionGroup.label} label={optionGroup.label}>
                {optionGroup.options.map(this.renderOption)}
            </optgroup>
        );
    };

    renderOption = option => {
        return (
            <option key={option.value} value={option.value}>
                {option.label}
            </option>
        );
    };

    render() {
        let options = this.props.options;
        const {
            name,
            value,
            emptyOption,
            disabled,
            className,
            hasError,
            variant,
            block,
            displayOptionsModal,
        } = this.props;

        const isProductIdentityVariant = variant === 'productIdentity';
        const baseClassName = 'fa-input';
        const classNames = !isProductIdentityVariant
            ? classnames(
                  baseClassName,
                  {
                      [`${baseClassName}--select`]: !isProductIdentityVariant,
                      [`${baseClassName}--${variant}`]: variant,
                      [`${baseClassName}--disabled`]: disabled,
                      [`${baseClassName}--error`]: hasError,
                      [`${baseClassName}--block`]: block,
                  },
                  className
              )
            : '';

        if (emptyOption && !displayOptionsModal) {
            options = [
                {
                    value: '',
                    label: emptyOption,
                },
                ...options,
            ];
        }

        return (
            <div className={classNames}>
                {!displayOptionsModal && (
                    <select
                        id={name}
                        name={name}
                        value={value}
                        disabled={disabled}
                        onChange={this.handleChange}
                        className={
                            classnames({ 'nd-select': isProductIdentityVariant }) || undefined
                        }
                    >
                        {options.map(option => {
                            if (option.options) {
                                return this.renderOptionGroup(option);
                            }
                            return this.renderOption(option);
                        })}
                    </select>
                )}
                {displayOptionsModal && (
                    <div className="fakeSelect" onClick={this.onClick}>
                        {this.getModalSelectedOptionLabel()}
                    </div>
                )}
                {displayOptionsModal && (
                    <OptionsModal
                        title={emptyOption}
                        name={name}
                        value={value}
                        options={options}
                        closeModal={this.closeOptionsModal}
                        isOpened={this.state.isOptionsModalOpened}
                        onChange={this.props.onChange}
                    />
                )}
            </div>
        );
    }
}
