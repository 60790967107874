import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import InlineSVG from 'svg-inline-react';

import { useSelector } from 'react-redux';

import useI18n from 'hooks/I18n/useI18n.js';
import Steps from 'src/components/organisms/Steps/Steps.jsx';
import { userSelector } from 'modules/currentUser';
import Button, { LINK_MODE } from 'src/components/atoms/Button/Button.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import Caret from 'app/assets/new-design/images/icons/caret.svg';

export const STEP_BASKET = 'basket';
export const STEP_PICKUP = 'pickup';
export const STEP_INFORMATIONS_ANONYMOUS = 'informations_anonymous';
export const STEP_INFORMATIONS_NEW_ACCOUNT = 'informations_new_account';
export const STEP_PAYMENT = 'payment';

const CheckoutTimeline = props => {
    const { step, orderId, assemblyId, distributionId } = props;

    const isAnonymous = useSelector(userSelector)?.anonymous;
    const basket = useSelector(state => state?.checkout?.basket);

    const {
        trans,
        i18nConfig: { code },
    } = useI18n();

    const buttonProps = useMemo(() => {
        switch (step) {
            case STEP_BASKET:
                return {
                    backHref:
                        distributionId &&
                        `/${code}/assemblies/${assemblyId}/collections/${distributionId}/products`,
                    backLabel: trans('basket.button.salePage'),
                };
            case STEP_INFORMATIONS_ANONYMOUS:
                return {
                    backHref: `/${code}/checkout/${basket.uuid}/pickup`,
                    backLabel: trans('checkout.button.pickupPage'),
                };
            case STEP_INFORMATIONS_NEW_ACCOUNT:
                return {
                    backHref: `/${code}/basket`,
                    backLabel: trans('deliveryPage.button.basket'),
                };
            case STEP_PICKUP:
                return {
                    backHref:
                        basket?.uuid &&
                        (!isAnonymous
                            ? `/${code}/basket/${distributionId}`
                            : `/${code}/checkout/${basket.uuid}/basket`),
                    backLabel: trans('deliveryPage.button.basket'),
                };
            case STEP_PAYMENT:
                return {
                    backHref: `/${code}/orders/${orderId}/pickup`,
                    backLabel: trans('checkout.button.pickupPage'),
                };
            default:
                return {};
        }
    }, [basket, isAnonymous, step, trans, orderId, code, assemblyId, distributionId]);

    const stepsItems = useMemo(() => {
        if (!basket?.uuid && isAnonymous && step !== STEP_PAYMENT) {
            return [];
        }
        const list = [
            {
                id: STEP_BASKET,
                label: trans('checkout.step.basket'),
            },
            {
                id: STEP_PICKUP,
                label: trans('checkout.step.pickup'),
            },

            {
                id: STEP_PAYMENT,
                label: trans('checkout.step.payment'),
            },
        ];
        if (isAnonymous && step !== STEP_PAYMENT) {
            list.splice(2, 0, {
                id: STEP_INFORMATIONS_ANONYMOUS,
                label: trans('checkout.step.informations'),
            });
        }
        if (step === STEP_INFORMATIONS_NEW_ACCOUNT) {
            list.splice(1, 0, {
                id: STEP_INFORMATIONS_NEW_ACCOUNT,
                label: trans('checkout.step.informations'),
            });
        }
        return list;
    }, [basket, isAnonymous, trans, step]);

    return !stepsItems ? null : (
        <div className="pi-checkoutTimeline-container">
            <div className="pi-checkoutTimeline">
                {buttonProps.backHref && (
                    <Button
                        className="pi-checkoutTimeline-back-button"
                        onClick={() => {
                            window.location = `${buttonProps.backHref}`;
                        }}
                        mode={LINK_MODE}
                    >
                        <>
                            <InlineSVG width="24px" height="24px" raw src={Caret} />

                            <Text className="pi-checkoutTimeline-back-button-label">
                                {buttonProps.backLabel}
                            </Text>
                        </>
                    </Button>
                )}
                <Steps
                    current={R.findIndex(R.propEq('id', step))(stepsItems) + 1}
                    items={stepsItems}
                />
            </div>
        </div>
    );
};
CheckoutTimeline.propTypes = {
    step: PropTypes.oneOf([
        STEP_BASKET,
        STEP_PAYMENT,
        STEP_INFORMATIONS_ANONYMOUS,
        STEP_PICKUP,
        STEP_INFORMATIONS_NEW_ACCOUNT,
    ]).isRequired,
    orderId: PropTypes.number,
    assemblyId: PropTypes.number,
    distributionId: PropTypes.number,
};

export default CheckoutTimeline;
