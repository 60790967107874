export function getCurrentSeasonName() {
    const date = new Date();
    const dayOfYear = Math.floor(
        (date - new Date(date.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24)
    );

    if (dayOfYear >= 80 && dayOfYear < 172) {
        return 'spring';
    } else if (dayOfYear >= 172 && dayOfYear < 264) {
        return 'summer';
    } else if (dayOfYear >= 264 && dayOfYear < 355) {
        return 'autumn';
    }

    return 'winter';
}
