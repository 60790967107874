import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { getFormattedQuantity } from 'models/offers';

export const Quantity = ({ offer, strategy, className, i18n }) => {
    return (
        <span className={className}>
            {getFormattedQuantity(offer, {
                strategy,
                locale: i18n,
            })}
        </span>
    );
};

Quantity.propTypes = {
    offer: PropTypes.object.isRequired,
    strategy: PropTypes.string.isRequired,
    className: PropTypes.string,
    i18n: PropTypes.string.isRequired,
};

export default connectToI18n(Quantity);
