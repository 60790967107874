import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import useResponsive from 'hooks/Navigation/useResponsive.js';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import { Box, BoxSection, Icon, Image, Paragraph, Text } from 'components/ui';
import angleRight from 'icons/angle-right.svg';
import DistributionsPage from 'components/ProducerDistributions/DistributionsPage.jsx';
import CarouselOnSiteNotice from 'onSiteSales/components/CarouselOnSiteNotice.jsx';
import PlainTextOnSiteNotice from 'onSiteSales/components/PlainTextOnSiteNotice.jsx';
import { getDistributions } from 'api/onsite-sales';
import useI18n from 'hooks/I18n/useI18n.js';

const DistributionSelection = ({ farmId }) => {
    const { trans } = useI18n();
    const isSmallWidth = useResponsive();
    const [loading, setLoading] = useState(true);
    const [distributions, setDistributions] = useState([]);

    useEffect(() => {
        getDistributions(farmId).then(distributionsList => {
            setLoading(false);
            setDistributions(distributionsList);
        });
    }, [farmId]);

    const renderContent = () => {
        if (loading === true) {
            return <LoadingAnimation type="spinner" />;
        }

        if (R.isEmpty(distributions)) {
            return (
                <Paragraph className="u-mb-4">
                    {trans('onsite_sales.noDistributionToday')}
                </Paragraph>
            );
        }

        return (
            <Fragment>
                <BoxSection>
                    <Paragraph size="large" weight="bold">
                        {trans('onsite_sales.distribution_selection_page.text3')}
                    </Paragraph>
                </BoxSection>
                {distributions.map(distribution => (
                    <BoxSection bordered key={distribution.id}>
                        <Link className="u-flexible-row" to={`/history/${distribution.id}`}>
                            <Image
                                className="u-mr-2"
                                rounded
                                src="/assets/images/icons/icon-assembly.svg"
                                style={{ backgroundColor: '#FFA526' }}
                            />
                            <div className="u-flexible-item">
                                <Text weight="bold">{`${distribution.hiveCity}`}</Text>
                                <Text>{distribution.hiveName}</Text>
                            </div>
                            <Icon src={angleRight} />
                        </Link>
                    </BoxSection>
                ))}
            </Fragment>
        );
    };

    return (
        <DistributionsPage activeSlug="onsite-sales">
            {isSmallWidth ? <CarouselOnSiteNotice /> : <PlainTextOnSiteNotice />}
            <Box padding="small">{renderContent()}</Box>
        </DistributionsPage>
    );
};

DistributionSelection.propTypes = {
    farmId: PropTypes.number.isRequired,
};

export default connectToI18n(DistributionSelection);
