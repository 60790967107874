import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link as LinkRouter } from 'react-router-dom';

export const DISPLAY_MODES = {
    LINK: 'link',
    BUTTON: 'button',
    BUTTON_SECONDARY: 'button-secondary',
    BLACK_LINK: 'nd-link--black',
};

const Link = ({ children, className, underlined, displayMode, bypassRouter, ...props }) => {
    const cls = classnames(className, {
        'nd-link': displayMode === DISPLAY_MODES.LINK || displayMode === DISPLAY_MODES.BLACK_LINK,
        'nd-button':
            displayMode === DISPLAY_MODES.BUTTON || displayMode === DISPLAY_MODES.BUTTON_SECONDARY,
        secondary: displayMode === DISPLAY_MODES.BUTTON_SECONDARY,
        [DISPLAY_MODES.BLACK_LINK]: displayMode === DISPLAY_MODES.BLACK_LINK,
        underlined,
    });
    const Component = bypassRouter ? 'a' : LinkRouter;

    return (
        <Component {...props} className={cls}>
            {children}
        </Component>
    );
};

Link.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    underlined: PropTypes.bool,
    displayMode: PropTypes.oneOf(Object.values(DISPLAY_MODES)),
    bypassRouter: PropTypes.bool,
};

Link.defaultProps = {
    className: '',
    underlined: false,
    displayMode: 'link',
    bypassRouter: false,
};

export default Link;
