import React from 'react';
import PropTypes from 'prop-types';

export const CategoryIcon = ({ categoryId }) => (
    <img src={`/assets/new-design/images/product_categories/${categoryId}.svg`} />
);

CategoryIcon.propTypes = {
    categoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // 1, 2, highlighted,...
};

export default CategoryIcon;
