import { ADD_TOAST, DELETE_TOAST, CLEAR_TOASTS } from './actions';
import * as R from 'ramda';

export const initialState = [];

export const toastsReducer = (
    state = {
        toasts: initialState,
    },
    { type, payload }
) => {
    switch (type) {
        case ADD_TOAST:
            return R.evolve(
                {
                    toasts: R.append({
                        id: `${Date.now()}_${Math.floor(Math.random() * 1000000)}`,
                        ...payload,
                    }),
                },
                state
            );
        case DELETE_TOAST:
            return R.evolve(
                {
                    toasts: R.reject(toast => toast.id === payload),
                },
                state
            );
        case CLEAR_TOASTS:
            return {
                toasts: initialState,
            };
        default:
            return state;
    }
};
