import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'ramda';
import InlineSVG from 'svg-inline-react';

import useI18n from 'hooks/I18n/useI18n';

import { formatPrice } from 'modules/utils/currency';
import Tooltip from 'components/Tooltip.jsx';

import Text from 'components/ProductIdentity/Text.jsx';
import Button, { SMALL_SIZE } from 'src/components/atoms/Button/Button.jsx';
import PIText from 'components/ProductIdentity/Text.jsx';
import Caret from 'components/Navigation/Caret.jsx';
import Popover from 'src/components/atoms/Popover/Popover.jsx';
import helpIcon from 'icons/pi/help-circle.svg';

const PaymentSummary = ({
    className,
    amount,
    finalAmount,
    creditUsed,
    charityAmount,
    amounGiftCardUsed,
    deliveryAmount,
    organisation,
    onClick,
    currency,
    disabled,
    buttonLabel,
}) => {
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const { trans, i18n } = useI18n();

    return (
        <div className={classNames('pi-payment-summary', className)}>
            <button
                className={classNames('pi-payment-summary-toggle', { open: isDetailOpen })}
                onClick={() => setIsDetailOpen(!isDetailOpen)}
            >
                <Caret productIdentity />
            </button>
            <Text
                className="pi-payment-summary-title"
                size="18px"
                lineHeight="28px"
                color="gray2"
                bold
            >
                {trans('paymentPage.summary.title')}
            </Text>
            <div className={classNames('pi-payment-summary-detail', { open: isDetailOpen })}>
                <div className="pi-payment-summary-detail-row">
                    <PIText size="14px" lineHeight="20px" color="gray2">
                        {trans('paymentpage.summary.totalOrder')}
                    </PIText>
                    <PIText bold size="14px" lineHeight="20px" color="gray2">
                        {formatPrice(i18n, amount / 100, currency)}
                    </PIText>
                </div>

                <div className="pi-payment-summary-detail-row">
                    <div className="pi-payment-summary-detail-row-title">
                        <PIText size="14px" lineHeight="20px" color="gray2">
                            {trans('paymentpage.summary.delivery')}
                        </PIText>
                        <Popover
                            overlay={
                                <Tooltip>
                                    <PIText
                                        className="pi-tooltip-title"
                                        bold
                                        size="12px"
                                        lineHeight="20px"
                                        color="gray2"
                                    >
                                        {trans('paymentpage.summary.delivery')}
                                    </PIText>
                                    <PIText size="12px" lineHeight="20px" color="gray2">
                                        {!deliveryAmount
                                            ? trans('selectDelivery.feeTooltip')
                                            : trans('selectDelivery.feeTooltip.home')}
                                    </PIText>
                                </Tooltip>
                            }
                        >
                            <InlineSVG
                                src={helpIcon}
                                raw
                                className="svgIcon helpIcon"
                                width="20"
                                height="20"
                            />
                        </Popover>
                    </div>

                    <PIText bold size="14px" lineHeight="20px" color="gray2">
                        {!deliveryAmount
                            ? trans('global.offered.singular')
                            : `+ ${formatPrice(i18n, deliveryAmount / 100, currency)}`}
                    </PIText>
                </div>

                {!!creditUsed && (
                    <div className="pi-payment-summary-detail-row">
                        <PIText size="14px" lineHeight="20px" color="gray2">
                            {trans('paymentpage.summary.creditUsed')}
                        </PIText>
                        <PIText bold size="14px" lineHeight="20px" color="gray2">
                            - {formatPrice(i18n, creditUsed / 100, currency)}
                        </PIText>
                    </div>
                )}
                {!!charityAmount && !isEmpty(organisation) && (
                    <div className="pi-payment-summary-detail-row">
                        <PIText size="14px" lineHeight="20px" color="gray2">
                            {trans('paymentpage.summary.charity', {
                                '%organisation%': organisation.name,
                            })}
                        </PIText>
                        <PIText bold size="14px" lineHeight="20px" color="gray2">
                            + {formatPrice(i18n, charityAmount / 100, currency)}
                        </PIText>
                    </div>
                )}
                {!!amounGiftCardUsed && (
                    <div className="pi-payment-summary-detail-row">
                        <PIText size="14px" lineHeight="20px" color="gray2">
                            {trans('paymentpage.summary.giftcard')}
                        </PIText>
                        <PIText bold size="14px" lineHeight="20px" color="gray2">
                            - {formatPrice(i18n, amounGiftCardUsed / 100, currency)}
                        </PIText>
                    </div>
                )}
            </div>
            <div className="pi-payment-summary-amount">
                <PIText bold size="16px" lineHeight="24px" color="gray2">
                    {trans('paymentpage.summary.totalToPay')}
                </PIText>
                <PIText bold size="16px" lineHeight="24px" color="gray2">
                    {formatPrice(i18n, finalAmount / 100, currency)}
                </PIText>
            </div>
            <Button size={SMALL_SIZE} onClick={onClick} type="button" disabled={disabled}>
                {buttonLabel}
            </Button>
        </div>
    );
};

PaymentSummary.propTypes = {
    className: PropTypes.string,
    amount: PropTypes.number.isRequired,
    finalAmount: PropTypes.number.isRequired,
    creditUsed: PropTypes.number,
    charityAmount: PropTypes.number,
    amounGiftCardUsed: PropTypes.number,
    deliveryAmount: PropTypes.number,
    organisation: PropTypes.shape({ name: PropTypes.string }),
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    currency: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
};

PaymentSummary.defaultProps = {
    creditUsed: 0,
    charityAmount: 0,
    organisation: {},
    canPay: false,
    className: '',
    amounGiftCardUsed: 0,
};

export default PaymentSummary;
