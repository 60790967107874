import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import ProducersFeaturedList from './ProducersFeaturedList.jsx';
import { LoadingAnimation } from 'components/ui';
import { fetchFarmsGallery } from 'api/hives';
import { INITIAL_LOADING, READY } from 'modules/utils/ajaxStatuses';

export class ProducersFeaturedListContainer extends React.Component {
    static propTypes = {
        assemblyId: PropTypes.number.isRequired,
        userIsMember: PropTypes.bool,
    };

    state = {
        photos: [],
        status: INITIAL_LOADING,
    };

    componentDidMount() {
        const assemblyId = this.props.assemblyId;
        const limit = 16;

        fetchFarmsGallery(assemblyId, limit).then(response => {
            this.setState({
                photos: (response && response.photos && R.pluck('id', response.photos)) || [],
                status: READY,
            });
        });
    }

    render() {
        if (this.state.status !== READY) {
            return <LoadingAnimation type="spinner" />;
        }

        return (
            <ProducersFeaturedList
                assemblyId={this.props.assemblyId}
                photos={this.state.photos}
                userIsMember={this.props.userIsMember}
            />
        );
    }
}

export default ProducersFeaturedListContainer;
