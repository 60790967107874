import React, { lazy, Suspense } from 'react';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
const ProposeLocal = lazy(() => import('./ProposeLocal.jsx'));

const ProposeLocalContainer = () => {
    return (
        <Suspense fallback={<LoadingAnimation type="spinner" />}>
            <ProposeLocal />
        </Suspense>
    );
};

export default ProposeLocalContainer;
