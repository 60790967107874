import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import PropTypes from 'prop-types';

export class ErrorPage extends React.Component {
    static propTypes = {
        trans: PropTypes.func.isRequired,
        errorType: PropTypes.string.isRequired,
    };

    renderMaintenance = () => {
        const trans = this.props.trans;

        return (
            <div className="maintenance">
                <div className="row">
                    <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 u-center">
                        <img
                            src="/assets/images/maintenance.svg"
                            className="maintenance-img"
                            alt=""
                        />
                        <div className="box maintenance-box">
                            <h2 className="maintenance-title">{trans('maintenance.title')}</h2>
                            <p className="maintenance-text">
                                {trans('maintenance.reason.maintenance')}{' '}
                                {trans('maintenance.until.inAFewMinutes')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderOther = (errorCode, title, content) => {
        const trans = this.props.trans;

        return (
            <div className="main">
                <div className="error-page" data-error={errorCode}>
                    <h1>{trans(title)}</h1>
                    <p>{trans(content)}</p>
                    <img src="/assets/images/error-illustration.png" alt="" />
                    <p className="back-link">
                        <a className="btn btn-large" href="#" data-behavior="back">
                            {trans('global.back')}
                        </a>
                    </p>
                </div>
            </div>
        );
    };

    render() {
        const errorType = this.props.errorType;

        switch (errorType) {
            case 'maintenance':
                return this.renderMaintenance();
            case 'notFound':
                return this.renderOther(404, 'errors.notFound.title', 'errors.notFound.content');
            default:
                return this.renderOther(500, 'errors.internal.title', 'errors.internal.content');
        }
    }
}

export default connectToI18n(ErrorPage);
