export const computeFees = (totalPrice, fee) => {
    if (fee.type === 'fixed') {
        const { amount, currency } = fee.price;

        return {
            amount,
            currency,
        };
    }

    return {
        currency: totalPrice.currency,
        amount: Math.round(totalPrice.amount * fee.rate),
    };
};

export const isZeroFee = fee =>
    (fee.type === 'fixed' && fee.amount === 0) || (fee.type === 'percentage' && fee.rate === 0);
