import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BASKET_PAGE } from 'modules/originUtils.js';
import { editBasketOfferQuantity } from 'modules/orders';

import {
    STATE_CART,
    STATE_CART_LOCKED,
    STATE_CANCELLED,
    STATE_INVALIDATED,
    STATE_CONFIRMED,
    STATE_RETURNED,
    STATE_ABANDONED,
    STATE_PENDING,
} from 'models/orders';
import PIText from 'components/ProductIdentity/Text.jsx';
import Offer, {
    ORIENTATION_HORIZONTAL,
    BASKET_TEMPLATE,
    PRICE_FORMAT_TOTAL,
} from 'components/Product/ProductIdentity/Offer.jsx';

const BasketFarm = props => {
    const { items, order } = props;
    const history = useHistory();

    const { distributionId, isCancelledByTheMember, state } = order;
    const dispatch = useDispatch();
    const addOfferToBasket = useCallback(
        (offerId, quantity = 1, actionOrigin) => {
            if (state === STATE_CART) {
                dispatch(editBasketOfferQuantity(distributionId, offerId, quantity, actionOrigin));
            }
        },
        [dispatch, distributionId, state]
    );
    const disabled = useMemo(
        () =>
            isCancelledByTheMember ||
            [
                STATE_CART_LOCKED,
                STATE_CANCELLED,
                STATE_INVALIDATED,
                STATE_CONFIRMED,
                STATE_RETURNED,
                STATE_ABANDONED,
                STATE_PENDING,
            ].includes(state),
        [isCancelledByTheMember, state]
    );

    const onItemClick = useCallback(
        item =>
            history.push(
                `/assemblies/${order.distribution.hiveId}/collections/${order.distribution.id}/products/${item.product.id}`
            ),
        [order.distribution, history]
    );

    return (
        <div className="basket-farm-container">
            <PIText
                className="basket-farm-name"
                family="inter"
                color="gray2"
                size="16px"
                lineHeight="24px"
                bold
            >
                {items[0].farmName}
            </PIText>
            <div className="basket-farm-list-offer">
                {/* Display order changes when incrementing an offer due to creation of a new object to replace the old one in redux
                Possible solution: create a selector to retrieve the same list of items. */}
                {items.map(item => (
                    <Offer
                        key={item.id}
                        onClick={() => onItemClick(item)}
                        template={BASKET_TEMPLATE}
                        orientation={ORIENTATION_HORIZONTAL}
                        addOfferToBasket={addOfferToBasket}
                        distributionId={order.distributionId}
                        productName={item.product.name}
                        product={{
                            ...item.product,
                            farm: { name: item.farmName },
                            quantity: item.quantity,
                            offers: [item.offer],
                            type: { quantityStrategy: item.quantityStrategy },
                        }}
                        actionOrigin={BASKET_PAGE}
                        offer={item.offer}
                        disabled={disabled}
                        priceFormat={PRICE_FORMAT_TOTAL}
                        orderStatus={order.status}
                    />
                ))}
            </div>
        </div>
    );
};

BasketFarm.propTypes = {
    order: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
};
export default BasketFarm;
