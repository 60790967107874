import URIjs from 'URIjs';
import Backbone from 'backbone';
import _ from 'underscore';

const Url = function() {
    this._uri = new URIjs();
};

Url.prototype = {
    _updateWindowLocation() {
        const link = this._getAppLink();
        Backbone.history.navigate(link, { replace: true });
        return link;
    },

    _getAppLink() {
        let url = window.location.pathname;
        url = url.replace(Backbone.history.root, '');
        url += this._uri.search() + this._uri.hash();
        return url;
    },

    _prepareParameters(parameters) {
        const newParameters = {};
        _.each(parameters, function(value, key) {
            if (_.isArray(value) && key.indexOf('[]') === -1) {
                newParameters[`${key}[]`] = value;
            } else {
                newParameters[key] = value;
            }
        });
        return newParameters;
    },

    resetQueryParameters(_parameters) {
        const parameters = this._prepareParameters(_parameters);
        this._uri.search(parameters);
        return this._updateWindowLocation();
    },

    setQueryParameters(_parameters) {
        const parameters = this._prepareParameters(_parameters);
        this._uri.setSearch(parameters);
        return this._updateWindowLocation();
    },

    removeQueryParameters(_parameters) {
        const parameters = this._prepareParameters(_parameters);
        this._uri.removeSearch(parameters);
        return this._updateWindowLocation();
    },

    getQueryParameters() {
        const parameters = this._uri.search(true);
        const newParameters = {};
        _.each(parameters, function(value, key) {
            if (key.indexOf('[]') !== -1) {
                if (_.isArray(value)) {
                    newParameters[key.replace('[]', '')] = value;
                } else {
                    newParameters[key.replace('[]', '')] = [value];
                }
            } else {
                newParameters[key] = value;
            }
        });

        return newParameters;
    },
};

export default Url;
