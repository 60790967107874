import React from 'react';
import PropTypes from 'prop-types';
import useI18n from 'hooks/I18n/useI18n';
import Heading from 'components/Heading.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import LimitOverflowingContentWithEllipsis from 'components/ProductIdentity/LimitOverflowingContentWithEllipsis.jsx';

const HostSection = ({ description, leaderName }) => {
    const { trans } = useI18n();

    return (
        <div className="pi-host-block">
            <Heading size={4} productIdentity>
                {trans('hiveHome.hostSection.title', { '%leaderName%': leaderName })}
            </Heading>
            <Text size="14px" lineHeight="20px">
                {trans('user.roles.host')}
            </Text>
            <LimitOverflowingContentWithEllipsis
                className="pi-host-description"
                reduceKey="global.collapse"
            >
                {description}
            </LimitOverflowingContentWithEllipsis>
        </div>
    );
};

HostSection.propTypes = {
    description: PropTypes.string.isRequired,
    leaderName: PropTypes.string.isRequired,
};

export default HostSection;
