import React from 'react';
import AnalyticsSingleton from 'modules/analytics';

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function(WrappedComponent) {
    class WithAnalytics extends React.Component {
        constructor(props) {
            super(props);
            this.analytics = AnalyticsSingleton;
        }

        render() {
            return <WrappedComponent analytics={this.analytics} {...this.props} />;
        }
    }

    WithAnalytics.displayName = `WithAnalytics(${getDisplayName(WrappedComponent)})`;

    return WithAnalytics;
}
