import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { filerPhoto } from 'modules/utils/filer';
import { getLocalizedDate } from 'modules/utils/dateAndTime';
import { userSelector } from 'modules/currentUser';
import { formatFullName } from 'models/users';
import useI18n from 'hooks/I18n/useI18n';
import Text from 'components/ProductIdentity/Text.jsx';
import Button, { LINK_MODE } from 'src/components/atoms/Button/Button.jsx';

const NewsComment = ({ discussionId, comment, onDelete }) => {
    const { i18n, trans } = useI18n();
    const currentUser = useSelector(userSelector);

    const isAuthorOfComment = currentUser?.id === comment.author.id;
    const defaultAvatarLetters = `${comment.author.firstName[0]}${comment.author.lastName[0]}`;

    return (
        <div key={comment.uuid} className="pi-assembly-news-comment">
            <div className="pi-assembly-news-comment-header">
                {comment.author.photoId ? (
                    <img src={filerPhoto(comment.author.photoId, 'small')} />
                ) : (
                    <div className="pi-assembly-news-comment-header-avatar">
                        {defaultAvatarLetters}
                    </div>
                )}
                <div className="pi-assembly-news-comment-header-infos">
                    <div className="pi-assembly-news-comment-header-infos-main-col">
                        <Text size="16px" lineHeight="20px" weight={500}>
                            {formatFullName(comment.author, { shortenedLastName: true })}
                        </Text>
                        <Text
                            size="16px"
                            lineHeight="20px"
                            className="pi-assembly-news-comment-datetime"
                        >
                            {getLocalizedDate(i18n, 'M', comment.created)} –{' '}
                            {getLocalizedDate(i18n, 't', comment.created)}
                        </Text>
                    </div>
                    {isAuthorOfComment && (
                        <Button
                            mode={LINK_MODE}
                            className="pi-assembly-news-comment-delete-btn"
                            onClick={() => onDelete(discussionId, comment.uuid)}
                        >
                            {trans('global.delete')}
                        </Button>
                    )}
                </div>
            </div>
            <div
                className="pi-assembly-news-comment-content"
                dangerouslySetInnerHTML={{ __html: comment.content }}
            />
        </div>
    );
};

NewsComment.propTypes = {
    discussionId: PropTypes.string.isRequired,
    comment: PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string.isRequired,
        created: PropTypes.string.isRequired,
        author: PropTypes.shape({
            photoId: PropTypes.string,
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default NewsComment;
