import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as Stickyfill from 'stickyfilljs';

import useResponsive from 'hooks/Navigation/useResponsive';

const addPositionStickyPolyfillForIE11 = () => {
    Stickyfill.removeAll();
    // Top sale navigation bar on medium and large screens
    const stickyNav = document.querySelectorAll('.saleTopNavigation');
    Stickyfill.add(stickyNav);
    // Left menu with subcategories, filters, farms...
    const stickyInfos = document.querySelectorAll('.sale-content-info');
    Stickyfill.add(stickyInfos);
};

const SaleNavigationContainer = forwardRef(
    ({ currentCategory, isForeground, children, classNames, setLastVisitedCategoryId }, ref) => {
        const isSmallWidth = useResponsive();

        useEffect(() => {
            if (!isSmallWidth) {
                // Add the polyfill after first render
                addPositionStickyPolyfillForIE11();
            }

            if (currentCategory) {
                setLastVisitedCategoryId(currentCategory.id);
            }

            return () => {
                Stickyfill.removeAll();
            };
        }, []);

        useEffect(() => {
            if (!isSmallWidth) {
                // Add the polyfill after component update, particularly useful if the viewport was in small width at first
                // render and then was being expanded or rotated
                addPositionStickyPolyfillForIE11();
            }

            currentCategory?.id && setLastVisitedCategoryId(currentCategory.id);
        }, [currentCategory]);

        return (
            <div
                ref={ref}
                className={classnames(classNames, 'saleTopNavigation', {
                    'saleTopNavigation--foreground': isForeground,
                })}
            >
                {children}
            </div>
        );
    }
);

SaleNavigationContainer.propTypes = {
    currentCategory: PropTypes.object,
    isForeground: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    classNames: PropTypes.string,
    setLastVisitedCategoryId: PropTypes.func,
};

export default SaleNavigationContainer;
