import React, { useState, useRef } from 'react';
import onsiteIcon from 'icons/onsite-sale-logo.svg';
import { Box, Button, Heading, Icon, Paragraph, Text } from 'components/ui';
import useI18n from 'hooks/I18n/useI18n.js';
import angleUpIcon from 'icons/angle-up.svg';
import angleDownIcon from 'icons/angle-down.svg';
import Carousel from 'nuka-carousel';

const CarouselOnSiteNotice = () => {
    const { trans } = useI18n();
    const [isDetailsOpen, setDetailsOpen] = useState(false);

    const carouselRef = useRef(null);

    const carouselProperties = {
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Box className="u-mb-3">
            <div className="u-flexible-row u-mb-2">
                <Icon className="u-mr-2" size="xxx-large" src={onsiteIcon} />
                <Heading size={3}>{trans('onsite_sales_notice_title')}</Heading>
            </div>
            <Paragraph className="u-mb-2">{trans('onsite_sales_notice_text')}</Paragraph>
            <div className="u-center">
                <Button
                    icon={isDetailsOpen ? angleUpIcon : angleDownIcon}
                    iconPosition="outside"
                    iconSide="right"
                    onClick={() => {
                        /**
                         * Our use case is not well handled by Nuka carousel, probably because the div is collapsed when the page is rendered.
                         * As showed in the links above, we have to trigger the resize because the height calculus was not correct at first mount.
                         * https://github.com/FormidableLabs/nuka-carousel/issues/662
                         * https://codesandbox.io/s/nuka-carousel-expand-issue-with-ref-owm2j?file=/src/App.js:24-32
                         */
                        if (!isDetailsOpen) {
                            setTimeout(() => carouselRef.current.setDimensions(), 300);
                        }
                        setDetailsOpen(!isDetailsOpen);
                    }}
                    variant="primary"
                    weight="link"
                >
                    {trans('header.how')}
                </Button>
            </div>
            {isDetailsOpen && (
                <Carousel
                    ref={carouselRef}
                    renderCenterLeftControls={({ currentSlide, previousSlide }) => {
                        const displayLeftButton = currentSlide !== 0;
                        return (
                            displayLeftButton && (
                                <Button
                                    icon="angle-left"
                                    noPadding
                                    onClick={() => {
                                        previousSlide();
                                    }}
                                    weight="link"
                                />
                            )
                        );
                    }}
                    renderCenterRightControls={({ currentSlide, nextSlide }) => {
                        const displayRightButton =
                            currentSlide + carouselProperties.slidesToShow < 4;
                        return (
                            displayRightButton && (
                                <Button
                                    className="u-right"
                                    icon="angle-right"
                                    noPadding
                                    onClick={() => {
                                        nextSlide();
                                    }}
                                    weight="link"
                                />
                            )
                        );
                    }}
                    {...carouselProperties}
                >
                    {[1, 2, 3, 4].map(step => {
                        return (
                            <div
                                className="u-flexible-row u-ml-3 u-mr-3 u-mb-6 u-pl-2 u-pr-2"
                                key={step}
                                style={{ height: '124px' }}
                            >
                                <img
                                    className="u-mr-2"
                                    src={`/assets/images/onsite-sales/notice-step-${step}.svg`}
                                    width="92px"
                                />
                                <Text>{trans(`onsite_sales_notice_details_step${step}`)}</Text>
                            </div>
                        );
                    })}
                </Carousel>
            )}
        </Box>
    );
};

export default CarouselOnSiteNotice;
