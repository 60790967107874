import React from 'react';
import SaleBanner from './SaleBanner.jsx';

const GiftcardBanner = ({ isSmallWidth }) => {
    const text = {
        smartphone: {
            title: 'buyGiftCard.banner.title.mobile',
            text: 'buyGiftCard.banner.text.and.link',
        },
        desktop: {
            title: 'buyGiftCard.banner.title',
            text: 'buyGiftCard.banner.text',
        },
    };

    return (
        <SaleBanner
            url="/giftcards"
            isSmallWidth={isSmallWidth}
            illustration="/assets/images/illustrations/AssemblyCroc.svg"
            backgroundColor="#afe1e1"
            color="#17465d"
            text={text}
        />
    );
};

export default GiftcardBanner;
