import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { LoadingAnimation, Wrapper } from 'components/ui';
import DistributionInformation from 'components/AssemblyArchives/DistributionInformation.jsx';
import AppChannel from 'modules/channels/App';
import connectToI18n from 'modules/i18n/connectToI18n';
import { READY } from 'modules/utils/ajaxStatuses';

export class InvoicesPage extends React.Component {
    static propTypes = {
        distributions: PropTypes.array.isRequired,
        status: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
    };

    render() {
        const { distributions, status, trans } = this.props;

        if (status !== READY) {
            return <LoadingAnimation />;
        }

        const queryParameters = AppChannel.reqres.request('special:query:parameters');

        return (
            <Wrapper size="medium" xsPadding className="invoices">
                <h1>{trans('documents.financial.bills.title')}</h1>
                {distributions.map(distribution => (
                    <Fragment key={distribution.id}>
                        <p>{distribution.hiveName}</p>
                        <DistributionInformation
                            distribution={distribution}
                            queryParameters={queryParameters}
                        />
                    </Fragment>
                ))}
            </Wrapper>
        );
    }
}

export default connectToI18n(InvoicesPage);
