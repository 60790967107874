import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import createResponsiveComponent from 'components/createResponsiveComponent';
import {
    CONSTRUCT,
    OPEN,
    PRECONSTRUCT,
    INIT,
    propType as AssemblyStatusProptype,
} from 'modules/utils/AssemblyStatuses.js';

export class HostAssemblyNavigation extends React.Component {
    static propTypes = {
        assemblyId: PropTypes.number.isRequired,
        isSmallWidth: PropTypes.bool,
        trans: PropTypes.func.isRequired,
        assembyStatus: AssemblyStatusProptype,
    };

    render() {
        if (!this.props.isSmallWidth) {
            return null;
        }

        const { assembyStatus, assemblyId } = this.props;

        const progressLink = {
            pageName: 'progress',
            icon: '/assets/images/navigation/icon-progress-active.svg',
            transKey: 'navigation.left.progress',
        };

        const configLink = {
            pageName: 'configuration',
            icon: '/assets/images/navigation/mobile/icon-mobile-maruche-config.svg',
            transKey: 'menu.leader.configuration',
        };

        const producersLink = {
            pageName: 'producers/find',
            icon: '/assets/images/navigation/mobile/icon-mobile-maruche-prod.svg',
            transKey: 'navigation.left.producers',
        };

        const membersLink = {
            pageName: 'members',
            icon: '/assets/images/navigation/mobile/icon-mobile-maruche-membres.svg',
            transKey: 'community.title',
        };

        const distributionsLink = {
            pageName: 'collections',
            icon: '/assets/images/navigation/mobile/icon-mobile-maruche-distributions.svg',
            transKey: 'navigation.left.collections',
        };

        const statsLink = {
            pageName: 'dashboard/stats',
            icon: '/assets/images/navigation/icon-statistics-active.svg',
            transKey: 'navigation.left.statistics',
        };

        const links = [];

        if (assembyStatus === INIT) {
            links.push(progressLink);
        }
        if (assembyStatus === PRECONSTRUCT) {
            links.push(progressLink, configLink);
        }
        if (assembyStatus === CONSTRUCT) {
            links.push(membersLink, producersLink, configLink);
        }
        if (assembyStatus === OPEN) {
            links.push(distributionsLink, statsLink, producersLink, membersLink, configLink);
        }

        return (
            <div className="u-mb-4">
                {links.map(link => {
                    return (
                        <a
                            href={`assemblies/${assemblyId}/${link.pageName}`}
                            className="hostAssemblyNavigation-link"
                            key={link.pageName}
                        >
                            <img
                                className="hostAssemblyNavigation-linkImage"
                                src={link.icon}
                                alt=""
                            />
                            {this.props.trans(link.transKey)}
                        </a>
                    );
                })}
            </div>
        );
    }
}

export default connectToI18n(createResponsiveComponent(HostAssemblyNavigation));
