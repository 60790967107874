/* eslint-disable react/prop-types */
import React from 'react';
import AssemblyHome from 'components/Assembly/AssemblyHome.jsx';
import AssemblyHomeContainer from 'components/Assembly/ProductIdentity/AssemblyHome/AssemblyHomeContainer.jsx';
import SaleContainer from 'components/Sale/SaleContainer.jsx';
import { isLeaderOfAssembly, isProvidingAssembly } from '../../models/users';
import ContextService from 'app/scripts/entities/context/context.service';

export const assemblyRoutes = [
    {
        definition: '/assemblies/:assemblyId([0-9]+)',
        activeLink: 'menu.home',
        context: 'hive',
        exact: true,
        render: ({ match, currentUser }) => {
            const assemblyId = parseInt(match.params.assemblyId, 10);
            const isLeaderOfThisAssembly = isLeaderOfAssembly({ assemblyId, user: currentUser });
            const isProvidingThisAssembly = isProvidingAssembly(assemblyId, currentUser);
            const contextType = ContextService.getCurrentContext().toJSON().type;

            if (
                !isLeaderOfThisAssembly &&
                !isProvidingThisAssembly &&
                [undefined, null, 'member', 'profile'].includes(contextType)
            ) {
                return <AssemblyHomeContainer assemblyId={assemblyId} />;
            }

            return <AssemblyHome assemblyId={assemblyId} />;
        },
    },
    {
        definition: '/assemblies/:assemblyId/products',
        activeLink: 'menu.distributions',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <SaleContainer productIdentity assemblyId={parseInt(match.params.assemblyId, 10)} />
        ),
    },
    {
        definition: '/assemblies/:assemblyId/collections/:distributionId/products',
        activeLink: 'menu.distributions',
        context: 'hive',
        render: ({ match }) => (
            <SaleContainer
                productIdentity
                assemblyId={parseInt(match.params.assemblyId, 10)}
                distributionId={parseInt(match.params.distributionId, 10)}
            />
        ),
    },
];
