import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import classnames from 'classnames';
import connectToI18n from 'modules/i18n/connectToI18n';
import ProductImage from 'components/Sale/ProductImage.jsx';
import PriceAndQuantity from 'components/Sale/PriceAndQuantity.jsx';
import Tag from 'components/Sale/Tag.jsx';
import { Heading, Paragraph, Button } from 'components/ui';
import Legend from 'components/Legend.jsx';
import { upperCaseRatio } from 'modules/utils/string';
import { totalAvailableStock } from 'modules/stockUtils.js';
import { getRawTextWithTruncatedUrls } from 'modules/utils/text';
import { getFormattedQuantityEquivalent } from 'models/offers';

const DESCRIPTION_MAX_CHARS = 110;
const DESCRIPTION_MIN_CHARS = 50;

const dummyBecauseClickPropagate = () => {};

export class HorizontalProductCard extends React.Component {
    static propTypes = {
        trans: PropTypes.func.isRequired,
        transChoice: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
        product: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string,
            offers: PropTypes.array,
            photoId: PropTypes.string,
            type: PropTypes.shape({
                id: PropTypes.number.isRequired,
                quantityStrategy: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        productName: PropTypes.string.isRequired,
        allProductTypesIndexedById: PropTypes.object.isRequired,
        addToCartButton: PropTypes.node.isRequired,
        formattedQuantities: PropTypes.string.isRequired,
        totalQuantityInBasket: PropTypes.number.isRequired,
        displayProducer: PropTypes.bool,
        isAlreadyBoughtCategory: PropTypes.bool,
        hideTag: PropTypes.bool,
        isMultiOffers: PropTypes.bool.isRequired,
        isPromoted: PropTypes.bool,
    };

    render() {
        const {
            trans,
            i18n,
            transChoice,
            product,
            allProductTypesIndexedById,
            displayProducer,
            isAlreadyBoughtCategory,
            hideTag,
            productName,
            addToCartButton,
            formattedQuantities,
            totalQuantityInBasket,
            isMultiOffers,
            isPromoted,
            onClick,
        } = this.props;

        const quantityEquivalent = getFormattedQuantityEquivalent(product.offers[0], {
            strategy: product.type.quantityStrategy,
            locale: i18n,
        });
        const availableStock = totalAvailableStock(product);
        const descriptionWithTruncatedUrls = getRawTextWithTruncatedUrls(product.description);
        const truncateRatio = upperCaseRatio(descriptionWithTruncatedUrls) > 0.8 ? 0.8 : 1;
        const charsCount =
            (displayProducer ? DESCRIPTION_MIN_CHARS : DESCRIPTION_MAX_CHARS) * truncateRatio;
        const truncatedDescription =
            descriptionWithTruncatedUrls &&
            descriptionWithTruncatedUrls.substring(0, charsCount).trim();

        return (
            <div className="horizontalProductCard productCard" onClick={onClick}>
                <ProductImage
                    productCategoryId={R.prop(
                        'categoryId',
                        allProductTypesIndexedById[product.type.id]
                    )}
                    className="horizontalProductCard-image"
                    photoId={product.photoId}
                    labels={product.labels}
                    productTypeId={product.type.id}
                    resolution="small"
                    size="small"
                    productName={product.name}
                />
                <div className="horizontalProductCard-container">
                    <div className="horizontalProductCard-header">
                        <div className="horizontalProductCard-nameAndQuantity">
                            <div className="horizontalProductCard-nameAndStock">
                                {!hideTag && (
                                    <Tag
                                        isMultiOffers={isMultiOffers}
                                        availableStock={availableStock}
                                        alreadyBought={product.alreadyBought}
                                        isNewInAssembly={product.isNewInAssembly}
                                        isAlreadyBoughtCategory={isAlreadyBoughtCategory}
                                        promotionType={
                                            isPromoted ? product.offers[0].promotion.type : null
                                        }
                                    />
                                )}
                                <Heading
                                    rank={5}
                                    serif
                                    className={classnames('productCard-name', {
                                        'productCard--nameWithProducer': displayProducer,
                                    })}
                                >
                                    {productName}
                                </Heading>
                                {displayProducer && (
                                    <Legend className="horizontalProductCard-producerInHeader">
                                        {product.farm.name}
                                    </Legend>
                                )}
                            </div>
                            <Legend className="horizontalProductCard-quantityInHeader">
                                {product.offers.length > 3
                                    ? transChoice('offers.count', product.offers.length, {
                                          '%count%': product.offers.length,
                                      })
                                    : formattedQuantities}
                                {product.type.quantityStrategy !== 'unit' &&
                                    quantityEquivalent !== null &&
                                    product.offers.length < 2 &&
                                    ` (${quantityEquivalent})`}
                            </Legend>
                        </div>
                        <PriceAndQuantity
                            className="horizontalProductCard-pricesInHeader"
                            formattedQuantities={formattedQuantities}
                            product={product}
                        />
                    </div>
                    <div className="horizontalProductCard-body">
                        <PriceAndQuantity
                            className="horizontalProductCard-pricesInBody"
                            formattedQuantities={
                                product.offers.length > 3
                                    ? transChoice('offers.count', product.offers.length, {
                                          '%count%': product.offers.length,
                                      })
                                    : formattedQuantities
                            }
                            product={product}
                            hasAtLeastOneOfferInBasket={totalQuantityInBasket > 0}
                        />
                        <div className="horizontalProductCard-paragraphContainer">
                            {displayProducer && (
                                <Legend className="horizontalProductCard-producerInBody">
                                    {product.farm.name}
                                </Legend>
                            )}
                            <Paragraph
                                className={classnames('horizontalProductCard-paragraph', {
                                    'horizontalProductCard-paragraphWithProducer': displayProducer,
                                })}
                                serif
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            product.description &&
                                            product.description.length > charsCount
                                                ? `${truncatedDescription}... `
                                                : truncatedDescription,
                                    }}
                                />
                                <Button
                                    className="button--noPadding horizontalProductCard-seeMore"
                                    type="button"
                                    weight="link"
                                    onClick={dummyBecauseClickPropagate}
                                >
                                    {trans('products.highlighted.multipleOffers.seeDetails')}
                                </Button>
                            </Paragraph>
                        </div>
                        <div className="horizontalProductCard-addToCart">{addToCartButton}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connectToI18n(HorizontalProductCard);
