import React from 'react';

import CategoryBannerContent from 'components/Sale/banners/CategoryBannerContent.jsx';
import PropTypes from 'prop-types';
import { BANNER_TRANSLATION_KEY } from './utils';

const CategoryBanner = ({ category }) => {
    return (
        category in BANNER_TRANSLATION_KEY && (
            <div className={`category-banner category-banner-background--${category}`}>
                <CategoryBannerContent category={category} />
            </div>
        )
    );
};

CategoryBanner.propTypes = {
    category: PropTypes.string.isRequired,
};

export default CategoryBanner;
