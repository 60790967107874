import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { getLocalizedDateWithMonthDayWithoutYear } from 'modules/utils/dateAndTime';
import { capitalizeFirstLetter } from 'modules/utils/string';
import useI18n from 'hooks/I18n/useI18n';
import Text from 'src/components/atoms/Text/Text.jsx';

export const DistributionSlot = ({ distribution, onSlotClick, selected = false }) => {
    const { i18n, trans } = useI18n();

    return (
        <div
            className={classnames('assembly-distribution-slot', { selected })}
            onClick={() => onSlotClick(distribution.id)}
        >
            <Text className="distribution-slot-date">
                {capitalizeFirstLetter(
                    getLocalizedDateWithMonthDayWithoutYear(
                        i18n,
                        moment(distribution.timeStart).toDate()
                    )
                )}
            </Text>
            <ul>
                <li>
                    {trans('assembliesMap.assembly.timeslot', {
                        '%startHour%': moment(distribution.timeStart).format('LT'),
                        '%endHour%': moment(distribution.timeEnd).format('LT'),
                    })}
                </li>
                {distribution.hasHomeDelivery && <li>{trans('global.deliveredAtHome')}</li>}
            </ul>
        </div>
    );
};

DistributionSlot.propTypes = {
    distribution: PropTypes.shape({
        id: PropTypes.number.isRequired,
        timeStart: PropTypes.string.isRequired,
        timeEnd: PropTypes.string.isRequired,
        hasHomeDelivery: PropTypes.bool.isRequired,
    }).isRequired,
    onSlotClick: PropTypes.func,
    selected: PropTypes.bool.isRequired,
};

DistributionSlot.defaultProps = {
    onSlotClick: () => null,
};

export default DistributionSlot;
