/* eslint-disable no-return-assign */
import PropTypes from 'prop-types';

import React from 'react';
import classnames from 'classnames';

function notEmptyIfChildrenIsEmpty({ children, ariaLabel = '' }) {
    if (ariaLabel.trim() === '' && React.Children.count(children) === 0) {
        return new Error(
            'This checkbox is empty, you should provide an ariaLabel for accessibility'
        );
    }
    return null;
}

export class Checkbox extends React.Component {
    static propTypes = {
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        isChecked: PropTypes.bool.isRequired,
        indeterminate: PropTypes.bool,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        ariaLabel: notEmptyIfChildrenIsEmpty,
        children: PropTypes.node,
        inverse: PropTypes.bool,
        className: PropTypes.string,
        variant: PropTypes.oneOf(['pill', 'cross', 'productIdentity']),
    };

    static defaultProps = {
        disabled: false,
        indeterminate: false,
        inverse: false,
    };

    componentDidMount() {
        if (this.props.indeterminate) {
            this.setIndeterminate();
        }
    }

    componentDidUpdate(previousProps) {
        if (previousProps.indeterminate !== this.props.indeterminate) {
            this.setIndeterminate();
        }
    }

    setIndeterminate = () => {
        this.checkbox.indeterminate = this.props.indeterminate;
    };

    handleChange = e => {
        this.props.onChange(e.target.id, e.target.checked);
    };

    render() {
        const checkboxClasses = classnames('fa-checkbox', {
            'fa-checkbox--cross': this.props.variant === 'cross',
            'fa-checkbox--indeterminate': this.props.indeterminate,
        });

        if (this.props.variant === 'productIdentity') {
            return (
                <>
                    <input
                        ref={el => (this.checkbox = el)}
                        type="checkbox"
                        className="pi-checkbox"
                        checked={this.props.isChecked}
                        id={this.props.id}
                        onChange={this.handleChange}
                        aria-label={this.props.ariaLabel}
                        disabled={this.props.disabled}
                    />
                    <label htmlFor={this.props.id} className="pi-label">
                        {this.props.children}
                    </label>
                </>
            );
        }

        if (this.props.variant === 'pill') {
            return (
                <label
                    className={classnames(
                        {
                            'fa-input-pill': true,
                            'fa-input-pill--checkbox': true,
                            'fa-input--inverse': this.props.inverse,
                            'is-selected': this.props.isChecked,
                        },
                        this.props.className
                    )}
                >
                    <input
                        ref={el => (this.checkbox = el)}
                        type="checkbox"
                        className={checkboxClasses}
                        checked={this.props.isChecked}
                        id={this.props.id}
                        onChange={this.handleChange}
                        aria-label={this.props.ariaLabel}
                        disabled={this.props.disabled}
                    />
                    {this.props.children}
                </label>
            );
        }

        return (
            <span className={this.props.className}>
                <input
                    ref={el => (this.checkbox = el)}
                    type="checkbox"
                    className={classnames(checkboxClasses, {
                        'fa-checkbox--inverse': this.props.inverse,
                    })}
                    checked={this.props.isChecked}
                    id={this.props.id}
                    onChange={this.handleChange}
                    aria-label={this.props.ariaLabel}
                    disabled={this.props.disabled}
                />
                <label htmlFor={this.props.id}>{this.props.children}</label>
            </span>
        );
    }
}

export default Checkbox;
