import PropTypes from 'prop-types';
import React from 'react';
import IconTooltip from 'components/IconTooltip.jsx';
import helpIcon from 'icons/help-circle.svg';
import { INFO } from 'modules/utils/colors';

const propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
    className: PropTypes.string,
};

export const HelpTooltip = props => (
    <IconTooltip
        icon={helpIcon}
        children={props.children}
        className={props.className}
        color={INFO}
    />
);

HelpTooltip.propTypes = propTypes;

export default HelpTooltip;
