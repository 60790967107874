import * as R from 'ramda';
import Backbone from 'backbone';

import ReactRouterController from './controllers/reactRouterController';

import AppChannel from 'modules/channels/App';
import Impersonate from 'modules/impersonate';
import * as OAuth from 'modules/oauth';
import { getPersistedCampaign, forgetCampaign } from 'modules/campaignTracker';
import { dispatchLoadCurrentUser, getCurrentUser } from 'modules/utils/windowReduxStore.js';
import { isEmailValid } from 'modules/utils/string.js';
import * as localstorage from 'modules/localstorage';
import { confirmBasket } from 'modules/orders';

import { clearUserAndToken } from 'models/users.js';
import { isOnCheckoutAccountPage } from 'models/pageContext';

import { updateUser } from 'api/users';

function lastContextKey(userId) {
    return `lastContext_${userId}`;
}

const API = {
    logout(options) {
        // Sometimes options is null, can't rely on default arguments
        const _options = {
            reload: true,
            ...options,
        };
        clearUserAndToken();
        AppChannel.vent.trigger('logout');

        if (_options && _options.reload) {
            AppChannel.commands.execute('navigate', 'login', true);
        }
    },

    impersonate(username) {
        if (username && isEmailValid(username)) {
            Impersonate.set(username);
        } else {
            Impersonate.clear();
        }
        const redirectUrl = new URLSearchParams(window.location.search).get('redirectUrl');
        AppChannel.commands.execute('navigate:login', {
            replace: true,
            redirectUrl,
        });
    },

    routeWithReact() {
        return new ReactRouterController();
    },

    _getHomepage() {
        const user = getCurrentUser();

        if (!user || user.anonymous) {
            return 'assemblies';
        }

        if (user.isLeader) {
            const assembly = R.pipe(
                R.propOr([], 'hivesAsLeader'),
                R.reject(R.propEq('status', 'closed')),
                R.head
            )(user);

            if (assembly) {
                if (assembly.status === 'open') {
                    return `assemblies/${assembly.id}`;
                }
                return `assemblies/${assembly.id}/progress`;
            }
        }

        if (user.isFarmer) {
            const farm = R.pipe(R.propOr([], 'farm'), R.head)(user);
            if (farm.status === 'validated farm') {
                return 'producers/me/collections';
            }
            return 'producers/folder';
        }

        const lastStoredContext = localstorage.getItem(lastContextKey(user.id));
        if (lastStoredContext) {
            return lastStoredContext;
        }

        if (user.isMember) {
            const assemblyId = R.pipe(R.propOr([], 'hivesAsMember'), R.head, R.prop('id'))(user);
            return `assemblies/${assemblyId}`;
        }

        if (user.leaderProfile) {
            return 'progress';
        }

        return 'assemblies';
    },
};

AppChannel.commands.setHandler('navigate:login', function(options) {
    const loginUrl = options.redirectUrl ? `login?redirectUrl=${options.redirectUrl}` : 'login';
    Backbone.history.navigate(loginUrl, {
        trigger: true,
        replace: true,
    });
});

AppChannel.commands.setHandler('login', function(options) {
    async function success() {
        const onCheckoutAccountPage = isOnCheckoutAccountPage(options && options.redirectUrl);
        const delay = ms => new Promise(res => setTimeout(res, ms));
        let confirmBasketData;

        if (onCheckoutAccountPage) {
            // we have to add some delay to make sure the claim basket call is done, otherwise confirm basket will fail
            await delay(300);
            confirmBasketData = await confirmBasket(options.distributionId);
        }

        dispatchLoadCurrentUser({
            redirectUrl: onCheckoutAccountPage
                ? `orders/${confirmBasketData && confirmBasketData.orderId}/pickup`
                : options && options.redirectUrl,
            hasRegistered: options && options.hasRegistered,
            refresh: options.refresh,
        })
            .then(user => {
                const lastMarketingCampaign = getPersistedCampaign();
                if (lastMarketingCampaign) {
                    // @NOTE: fire & forget
                    updateUser(user.id, { lastMarketingCampaign });
                    forgetCampaign();
                }
                AppChannel.vent.trigger('login', user.id);
            })
            .catch(() => {
                // Login was successful but loading the current user failed
                // This is because the user we tried to load was impersonated but
                // the account impersonating did not have the rights to do so
                // Clear the authentication and report the error
                OAuth.clear();
                AppChannel.vent.trigger('auth:error');
            });
    }
    // login with username / password
    if (options && options.username && options.password) {
        OAuth.grantAndClaimAnonymousBasket('password', options)
            .done(success)
            .fail(function(error) {
                AppChannel.vent.trigger('auth:error', error);
            });
    } else {
        // login with refresh_token
        OAuth.grantAndClaimAnonymousBasket('refresh_token').done(success);
    }
});

AppChannel.commands.setHandler('logout', function(options) {
    API.logout(options);
});

AppChannel.reqres.setHandler('homepage:route', API._getHomepage);

export default API;
