function TranslationSet(data) {
    this.data = data;
}

TranslationSet.prototype.contains = function(number) {
    const ref = this.data;

    for (let i = 0, len = ref.length; i < len; i++) {
        const dataNumber = ref[i];
        if (parseInt(dataNumber, 10) === number) {
            return true;
        }
    }
    return false;
};

export default TranslationSet;
