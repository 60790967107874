const environment = (function() {
    switch (process.env.API_ROOT) {
        case 'https://api-dev.thefoodassembly.com':
            return 'development';
        case 'https://api-ppr.thefoodassembly.com':
            return 'preproduction';
        case 'https://api-sandbox.thefoodassembly.com':
            return 'sandbox';
        case 'https://api7-sandbox.thefoodassembly.com':
            return 'sandbox';
        case 'https://api.test.thefoodassembly.com':
            return 'sandbox';
        case 'https://api.thefoodassembly.com':
            return 'production';
        default:
            return 'dev-local';
    }
})();

export default environment;
