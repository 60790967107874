import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import connectToI18n from 'modules/i18n/connectToI18n';
import searchIcon from 'icons/search.svg';
import Icon from 'components/Icon.jsx';

const propTypes = {
    className: PropTypes.string,
    isSmallWidth: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    trans: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

export const SearchBar = props => {
    const { className, isSmallWidth, onChange, trans, value } = props;

    const onValueChange = e => {
        onChange(e.target.value);
    };

    if (isSmallWidth) {
        return (
            <div className="saleSmallScreenNavigationPanel-searchBar u-mr-2 fa-input">
                <Icon className="u-mr-2" size="large" src={searchIcon} />
                <input
                    className="saleSmallScreenNavigationPanel-searchInput"
                    onChange={onValueChange}
                    placeholder={trans('salePage.searchPlaceholder')}
                    value={value}
                />
            </div>
        );
    }

    return (
        <Fragment>
            <span className="icon icon-search" />
            <input
                autoFocus
                className={classNames(className, 'saleTopNavigation-searchBarInput')}
                onChange={onValueChange}
                placeholder={trans('salePage.searchPlaceholder')}
                value={value}
            />
        </Fragment>
    );
};

SearchBar.propTypes = propTypes;

export default connectToI18n(SearchBar);
