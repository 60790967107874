import React from 'react';
import { Route } from 'react-router-dom';
import AnalyticsSingleton from 'modules/analytics';

export default function() {
    return (
        <Route
            render={() => {
                /**
                 * @note analytics side effect
                 * no effect server side
                 * renders nothing anyway
                 */
                AnalyticsSingleton.sendPageView();
                return null;
            }}
        />
    );
}
