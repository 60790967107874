import mapboxgl from 'mapbox-gl';
import { MAPBOX_APP_KEY } from 'models/mapbox';
import mapConfig from './config';
import { getLocaleConfig } from 'modules/config/i18n';

export const FEATURE_TYPE_ASSEMBLY = 'assembly';
export const FEATURE_TYPE_PICKUP = 'pickup';

export const FEATURE_STATUS_OPEN = 'open';
export const FEATURE_STATUS_CONSTRUCT = 'construct';

export default class ClusteredMapV2 {
    constructor(mapContainer, locale) {
        this.mainSourceExists = false;
        this.markerHasBeenClicked = false;

        this.initMap(mapContainer, locale);
    }

    initMap(mapContainer, locale) {
        const localeCode = getLocaleConfig(locale).code;

        this.map = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/lrqdo/clm21bnzs00pl01qu0gf6g91h',
            center: mapConfig.center.byLocale[localeCode] || mapConfig.center.default,
            zoom: mapConfig.zoom.byLocale[localeCode] || mapConfig.zoom.default,
            attributionControl: false,
            hash: true,
            accessToken: MAPBOX_APP_KEY,
            minZoom: 5,
            maxZoom: 17,
            fadeDuration: 0,
        });

        // disable map rotation using right click + drag
        this.map.dragRotate.disable();

        // disable map rotation using touch rotation gesture
        this.map.touchZoomRotate.disableRotation();

        this.addControls();
    }

    addControls() {
        this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right');
        this.map.addControl(new mapboxgl.GeolocateControl(), 'bottom-right');
    }

    createClustersAndMarkers(assemblies, pickups) {
        this.map.addSource('pickupAndAssemblies', {
            type: 'geojson',
            data: this.createFeatures(assemblies, pickups),
            cluster: true,
            clusterMaxZoom: 10,
            clusterRadius: 50,
        });

        this.createClustersLayers();
        this.createAssembliesLayers();
        this.createActiveMarkerLayer();
        this.changeCursorToPointerOnMarkerHover();

        this.mainSourceExists = true;
    }

    createFeatures(assemblies, pickups) {
        return {
            type: 'FeatureCollection',
            features: [
                ...this.createAssemblyFeatures(assemblies),
                ...this.createPickupFeatures(pickups),
            ],
        };
    }

    createAssemblyFeatures(assemblies) {
        return assemblies.map(assembly => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [
                    assembly.place.address.coordinates.longitude,
                    assembly.place.address.coordinates.latitude,
                ],
            },
            properties: {
                id: assembly.id,
                type: FEATURE_TYPE_ASSEMBLY,
                status: assembly.status,
            },
        }));
    }

    createPickupFeatures(pickups) {
        return pickups.map(pickup => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [
                    pickup.meetingPoint.coordinates.longitude,
                    pickup.meetingPoint.coordinates.latitude,
                ],
            },
            properties: {
                id: pickup.uuid,
                type: FEATURE_TYPE_PICKUP,
            },
        }));
    }

    createClustersLayers() {
        this.map.addLayer({
            id: 'clusters',
            type: 'symbol',
            source: 'pickupAndAssemblies',
            filter: ['has', 'point_count'],
            paint: {
                'text-color': '#fff',
                'text-opacity': 1,
                'text-translate': [-1, 2],
            },
            layout: {
                'icon-image': 'cluster',
                'icon-allow-overlap': true,
                'text-allow-overlap': true,
                'icon-size': 1,
                'text-size': 16,
                'text-font': ['Inter SemiBold'],
                'text-field': '{point_count}',
            },
        });

        this.map.on('click', 'clusters', e => {
            const features = this.map.queryRenderedFeatures(e.point, {
                layers: ['clusters'],
            });
            const clusterId = features[0].properties.cluster_id;

            this.handleClusterClick(clusterId, features[0].geometry.coordinates);
        });
    }

    createAssembliesLayers() {
        this.map.addLayer({
            id: 'unclustered-assemblies',
            type: 'symbol',
            source: 'pickupAndAssemblies',
            filter: ['!', ['has', 'point_count']],
            layout: {
                'icon-image': [
                    'case',
                    [
                        'all',
                        ['has', 'status'],
                        ['has', 'type'],
                        ['==', ['get', 'type'], FEATURE_TYPE_ASSEMBLY],
                        ['==', ['get', 'status'], FEATURE_STATUS_OPEN],
                    ],
                    'assembly',
                    [
                        'all',
                        ['has', 'status'],
                        ['has', 'type'],
                        ['==', ['get', 'type'], FEATURE_TYPE_ASSEMBLY],
                        ['==', ['get', 'status'], FEATURE_STATUS_CONSTRUCT],
                    ],
                    'assembly-construct',
                    ['all', ['has', 'type'], ['==', ['get', 'type'], FEATURE_TYPE_PICKUP]],
                    'pickup',
                    'assembly', // default
                ],
                'icon-allow-overlap': true,
                'text-allow-overlap': true,
                'icon-size': 1,
            },
        });
    }

    createActiveMarkerLayer() {
        this.map.addSource('selected-marker', {
            type: 'geojson',
            data: null,
        });

        this.map.addLayer({
            id: 'selected-marker',
            type: 'symbol',
            source: 'selected-marker',
            layout: {
                'icon-image': [
                    'case',
                    [
                        'all',
                        ['has', 'status'],
                        ['has', 'type'],
                        ['==', ['get', 'type'], FEATURE_TYPE_ASSEMBLY],
                        ['==', ['get', 'status'], FEATURE_STATUS_OPEN],
                    ],
                    'assembly-active',
                    [
                        'all',
                        ['has', 'status'],
                        ['has', 'type'],
                        ['==', ['get', 'type'], FEATURE_TYPE_ASSEMBLY],
                        ['==', ['get', 'status'], FEATURE_STATUS_CONSTRUCT],
                    ],
                    'assembly-construct-active',
                    ['all', ['has', 'type'], ['==', ['get', 'type'], FEATURE_TYPE_PICKUP]],
                    'pickup-active',
                    'assembly-active', // default
                ],
                'icon-allow-overlap': true,
                'icon-size': 1,
            },
        });
    }

    handleClusterClick(clusterId, coordinates) {
        this.map
            .getSource('pickupAndAssemblies')
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return;

                this.map.easeTo({
                    center: coordinates,
                    zoom,
                });
            });
    }

    attachEventHandlers(onMarkerClick, onMapClick) {
        this.attachEventHandlerOnMarkerClick(onMarkerClick);
        this.attachEventHandlerOnMapClick(onMapClick);
    }

    attachEventHandlerOnMarkerClick(handler) {
        this.map.on('click', 'unclustered-assemblies', e => {
            this.markerHasBeenClicked = true;

            const features = this.map.queryRenderedFeatures(e.point, {
                layers: ['unclustered-assemblies'],
            });

            this.selectMarker(features[0]);
            handler(features[0].properties);
        });
    }

    attachEventHandlerOnMapClick(handler) {
        this.map.on('click', () => {
            if (this.markerHasBeenClicked) {
                this.markerHasBeenClicked = false;
                return;
            }

            this.hideActiveMarker();
            handler();
        });
    }

    selectMarker(feature) {
        this.hideActiveMarker();

        const sourceData = {
            type: 'FeatureCollection',
            features: [feature],
        };

        this.map.getSource('selected-marker').setData(sourceData);
        this.map.setLayoutProperty('selected-marker', 'visibility', 'visible');
    }

    hideActiveMarker() {
        this.map.setLayoutProperty('selected-marker', 'visibility', 'none');
    }

    refreshMainSourceData(assemblies, pickups = []) {
        if (!this.mainSourceExists) {
            if (this.map.loaded()) {
                this.createClustersAndMarkers(assemblies, pickups);
            } else {
                this.map.on('load', () => {
                    this.createClustersAndMarkers(assemblies, pickups);
                });
            }

            return;
        }

        this.hideActiveMarker();
        this.map.getSource('pickupAndAssemblies').setData(this.createFeatures(assemblies, pickups));
    }

    panTo(coordinates) {
        this.map.jumpTo({ center: coordinates, zoom: 13 });
    }

    changeCursorToPointerOnMarkerHover() {
        this.map.on('mousemove', function(e) {
            const features = this.queryRenderedFeatures(e.point, {
                layers: ['unclustered-assemblies', 'clusters'],
            });

            if (features[0]) {
                this.getCanvasContainer().style.cursor = 'pointer';
            } else {
                this.getCanvasContainer().style.cursor = 'grabbing';
            }
        });
    }

    selectHiveById(id) {
        const feature = this.map
            .getSource('pickupAndAssemblies')
            ._data.features.find(hive => hive.properties.id === id);
        this.selectMarker(feature);
    }
}
