import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import ResponsiveNavigation from '../ResponsiveNavigation.jsx';
import NavigationRegion from '../NavigationRegion.jsx';
import ContextDropdown from '../ContextDropdown.jsx';
import UserDropdown from '../UserDropdown.jsx';
import HelpDropdown from '../HelpDropdown.jsx';
import HelpBottomDropdown from '../HelpBottomDropdown.jsx';
import UserBottomDropdown from '../UserBottomDropdown.jsx';
import LeftNavigationLink from '../LeftNavigationLink.jsx';
import MessagesLink from '../MessagesLink.jsx';
import BottomNavigationLink from '../BottomNavigationLink.jsx';
import BottomCatalogsNavigationLinks, {
    PANEL_NAME as BOTTOM_CATALOGS_NAVIGATION_LINKS_PANEL,
} from '../BottomCatalogsNavigationLinks.jsx';
import { fetchInvitations } from 'modules/farmerInvitations';
import { connect } from 'react-redux';
import NewsfeedDropdown from 'components/Navigation/NewsfeedDropdown.jsx';
import NewsfeedBottomDropdown from 'components/Navigation/NewsfeedBottomDropdown.jsx';
import { fetchUnreadNewsfeedCount, unreadSelector } from 'modules/newsfeed';
import { FARM_RECIPIENT } from 'models/newsfeed.js';

export class FarmOpenInterregionalNavigation extends React.Component {
    static propTypes = {
        isSmallWidth: PropTypes.bool,
        activeLink: PropTypes.string,
        trans: PropTypes.func.isRequired,
        fetchInvitations: PropTypes.func.isRequired,
        visiblePanel: PropTypes.string,
        invitationsCount: PropTypes.number,
        farm: PropTypes.object.isRequired,
        newsfeedUnreadCount: PropTypes.number,
        fetchUnreadNewsfeedCount: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.fetchInvitations(this.props.farm.id);
        this.props.fetchUnreadNewsfeedCount(FARM_RECIPIENT);
    }

    render() {
        const t = this.props.trans;
        const { isSmallWidth, activeLink, invitationsCount, newsfeedUnreadCount } = this.props;

        return (
            <ResponsiveNavigation isSmallWidth={isSmallWidth}>
                <NavigationRegion position="top" legacy>
                    <div className="topNavigation-left">
                        <ContextDropdown {...this.props} />
                    </div>
                    <div className="topNavigation-right">
                        <NewsfeedDropdown {...this.props} />
                        <MessagesLink {...this.props} />
                        <HelpDropdown {...this.props} />
                        <UserDropdown {...this.props} />
                    </div>
                </NavigationRegion>
                <NavigationRegion position="left">
                    <LeftNavigationLink
                        href="producers/me/collections"
                        icon="distributions"
                        isActive={activeLink === 'navigation.left.collections'}
                    >
                        {t('navigation.left.collections')}
                    </LeftNavigationLink>
                    <LeftNavigationLink
                        href="producers/me/dashboard/stats"
                        icon="statistics"
                        isActive={activeLink === 'navigation.left.statistics'}
                    >
                        {t('navigation.left.statistics')}
                    </LeftNavigationLink>
                    <LeftNavigationLink
                        href="producers/me/products/stocks"
                        icon="catalog"
                        isActive={activeLink === 'menu.catalog'}
                    >
                        {t('navigation.left.farm.products')}
                    </LeftNavigationLink>

                    <LeftNavigationLink
                        href="producers/me/assemblies"
                        icon="assemblies"
                        isActive={activeLink === 'menu.hives'}
                        badgeValue={this.props.invitationsCount}
                    >
                        {t('navigation.left.assemblies')}
                    </LeftNavigationLink>
                    <LeftNavigationLink
                        href="producers/accounting"
                        icon="accounting"
                        isActive={activeLink === 'navigation.left.accounting'}
                    >
                        {t('navigation.left.accounting')}
                    </LeftNavigationLink>
                    <LeftNavigationLink
                        href="producers/folder"
                        icon="folder"
                        isActive={activeLink === 'menu.farm.farmFolder'}
                    >
                        {t('navigation.left.producerFolder')}
                    </LeftNavigationLink>
                </NavigationRegion>
                <NavigationRegion position="bottom">
                    <BottomCatalogsNavigationLinks
                        isActive={
                            this.props.visiblePanel === BOTTOM_CATALOGS_NAVIGATION_LINKS_PANEL
                        }
                        visiblePanel={this.props.visiblePanel}
                    />
                    <BottomNavigationLink href="producers/me/dashboard/stats" icon="statistics">
                        {t('navigation.left.statistics')}
                    </BottomNavigationLink>
                    <BottomNavigationLink icon="distributions" href="producers/me/collections">
                        {t('navigation.bottom.collections')}
                    </BottomNavigationLink>
                    <UserBottomDropdown
                        {...this.props}
                        newsfeedUnreadCount={newsfeedUnreadCount}
                        invitationsCount={invitationsCount}
                    />
                    <HelpBottomDropdown {...this.props} />
                    <NewsfeedBottomDropdown {...this.props} />
                </NavigationRegion>
            </ResponsiveNavigation>
        );
    }
}

const mapStateToProps = state => ({
    invitationsCount: state.farmerInvitations.pendingInvitationsCount,
    newsfeedUnreadCount: unreadSelector(state),
});

export default connect(mapStateToProps, {
    fetchInvitations,
    fetchUnreadNewsfeedCount,
})(connectToI18n(FarmOpenInterregionalNavigation));
