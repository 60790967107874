import { localesArray as locales, getLocaleConfig } from 'modules/config/i18n';
import * as R from 'ramda';

export function getLocaleShortcut(locale) {
    return locale.shortcut ? locale.shortcut : locale.code;
}

export function getLocaleShortform(locale) {
    return locale.code.substr(0, 2);
}

export function getLocaleByCode(code) {
    return (
        R.find(R.whereEq({ code }), locales) ||
        R.find(R.whereEq({ shortcut: code }), locales) ||
        R.find(R.whereEq({ code: 'en-GB' }), locales)
    );
}

export const isGermanLanguage = localeString => {
    return getLocaleShortform(getLocaleConfig(localeString)) === 'de';
};

export const convertLocaleToAPI = locale => (locale ? locale.replace('-', '_') : 'fr');

export const getLocaleShort = localeString =>
    getLocaleConfig(localeString) ? getLocaleShortform(getLocaleConfig(localeString)) : 'en';

export const isItalianLanguage = localeString => getLocaleShort(localeString) === 'it';

export const getCountry = locale => locale.code.substr(3, 2);

export const getCountryFromLocal = localeString =>
    getLocaleConfig(localeString) ? getCountry(getLocaleConfig(localeString)) : 'EN';
