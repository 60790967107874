import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import useI18n from 'hooks/I18n/useI18n.js';

import { Text } from 'components/ui';
import MediaObject from 'components/MediaObject.jsx';
import Avatar from 'components/Avatar.jsx';
import Button from 'components/Button.jsx';
import FeedbackMessageResponse from 'components/ProducerDashboard/FeedbackMessageResponse.jsx';

export const FeedbackMessage = ({
    productUuid,
    commentId,
    comment,
    commentInfo,
    userPhotoId,
    smallPadding,
    className,
    rating,
    commentResponse,
}) => {
    const { trans } = useI18n();
    const [showResponseArea, setShowResponseArea] = useState(false);

    return (
        <div className={classnames('feedbackMessage', className)}>
            <MediaObject
                valignCenter
                media={<Avatar photoId={userPhotoId} size="small" rounded bordered />}
            >
                <Text
                    size="large"
                    serif
                    className={classnames('feedbackMessage-content', {
                        ['feedbackMessage-content--smallPadding']: smallPadding,
                        'feedbackMessage-content-heart': rating === 'heart',
                    })}
                >
                    {comment}
                </Text>
            </MediaObject>
            <div className="feedbackMessage-informations-container">
                <Text className="feedbackMessage-informations" color="info">
                    {commentInfo}
                    {commentResponse &&
                        showResponseArea === false &&
                        commentResponse.response === null && (
                            <>
                                {' • '}
                                <Button
                                    weight="link"
                                    variant="primary"
                                    onClick={() => {
                                        setShowResponseArea(!showResponseArea);
                                    }}
                                >
                                    <Text color="primary"> {trans('messages.reply')} </Text>
                                </Button>
                            </>
                        )}
                </Text>
            </div>
            {commentResponse && (showResponseArea || commentResponse.response !== null) && (
                <FeedbackMessageResponse
                    productUuid={productUuid}
                    feedbackId={commentId}
                    cancel={() => {
                        setShowResponseArea(false);
                    }}
                    feedbackResponse={commentResponse}
                />
            )}
        </div>
    );
};

FeedbackMessage.propTypes = {
    productUuid: PropTypes.string.isRequired,
    commentId: PropTypes.number.isRequired,
    comment: PropTypes.string.isRequired,
    commentInfo: PropTypes.node,
    userPhotoId: PropTypes.string,
    smallPadding: PropTypes.bool,
    className: PropTypes.string,
    rating: PropTypes.oneOf(['dislike', 'like', 'heart']),
    commentResponse: PropTypes.shape({
        farm_name: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        photo: PropTypes.string,
        responded_at: PropTypes.string,
        response: PropTypes.string,
    }),
};

export default FeedbackMessage;
