import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import DistributionSelection from 'onSiteSales/pages/DistributionSelection.jsx';
import NewDistributionSelection from 'onSiteSales/pages/NewDistributionSelection.jsx';
import History from 'onSiteSales/pages/History.jsx';
import NotFound from 'onSiteSales/pages/NotFound.jsx';
import PaymentRequestForm from 'onSiteSales/pages/PaymentRequestForm.jsx';
import NewPaymentRequestForm from 'onSiteSales/pages/NewPaymentRequestForm.jsx';
import PaymentRequestStatus from 'onSiteSales/pages/PaymentRequestStatus.jsx';
import { connect } from 'react-redux';
import { firstFarmSelector } from 'modules/currentUser';
import FeaturesService, { FEATURES } from 'models/features.js';

class App extends React.Component {
    static propTypes = {
        farm: PropTypes.object.isRequired,
    };

    render() {
        const hasOnsiteSalesFeature =
            FeaturesService.isFeatureActive(FEATURES.ONSITE_SALES, this.props.farm) ||
            FeaturesService.isFeatureActive(FEATURES.ONSITE_SALES_COUNTRY, this.props.farm);

        return (
            <Switch>
                <Route
                    exact
                    path="/"
                    render={props =>
                        hasOnsiteSalesFeature ? (
                            <NewDistributionSelection farmId={this.props.farm.id} />
                        ) : (
                            <DistributionSelection {...props} farmId={this.props.farm.id} />
                        )
                    }
                />
                <Route
                    exact
                    path="/history/:distributionId"
                    render={props => <History {...props} farmId={this.props.farm.id} />}
                />
                <Route
                    exact
                    path="/amount/:id"
                    render={props =>
                        hasOnsiteSalesFeature ? (
                            <NewPaymentRequestForm
                                {...props}
                                farmId={this.props.farm.id}
                                farmCurrency={this.props.farm.currency}
                            />
                        ) : (
                            <PaymentRequestForm
                                {...props}
                                farmId={this.props.farm.id}
                                farmCurrency={this.props.farm.currency}
                            />
                        )
                    }
                />
                <Route exact path="/request" component={PaymentRequestStatus} />
                <Route component={NotFound} />
            </Switch>
        );
    }
}

const mapStateToProps = state => ({
    farm: firstFarmSelector(state),
});

export default connect(mapStateToProps)(App);
