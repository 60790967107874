import Discussion from 'components/Assembly/Discussions/Discussion.jsx';
import { Button } from 'components/ui';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
    addMessageToDiscussion,
    deleteDiscussion,
    deleteMessageFromDiscussion,
    restoreDiscussion,
    restoreMessageFromDiscussion,
} from 'api/discussions';
import { connect } from 'react-redux';
import { addToast } from 'modules/toasts';
import useI18n from 'hooks/I18n/useI18n';
import { getRoleInAssembly } from 'models/users';
import { LOADING_MORE } from 'modules/utils/ajaxStatuses';

const LastDiscussionsList = ({
    addNewToast,
    assemblyId,
    buttonStatus,
    discussions,
    isLastDiscussion,
    maximumCount,
    onChangeDiscussions,
    handleShowMoreDiscussions,
    user,
    userIsMember,
}) => {
    const { trans } = useI18n();

    const isAssemblyLeader =
        getRoleInAssembly({
            assemblyId,
            user,
        }) === 'leader';

    const addNewMessageToDiscussion = (content, discussionId) => {
        addMessageToDiscussion(discussionId, content).then(message => {
            const discussionIndex = R.findIndex(R.propEq('id', discussionId))(discussions);
            const appendMessage = R.evolve({ messages: R.append(message) });
            onChangeDiscussions(R.adjust(discussionIndex, appendMessage));
        });
    };

    const printToast = action => {
        addNewToast({
            content: trans('discussions.deletedMessage'),
            action: trans('discussions.cancel'),
            onClick: () => {
                action().then(newDiscussion => {
                    onChangeDiscussions(newDiscussion);
                });
            },
            timeOut: 5000,
        });
    };

    const deleteDiscussionMessage = (discussionId, messageId) => {
        deleteMessageFromDiscussion(assemblyId, discussionId, messageId).then(newDiscussions =>
            onChangeDiscussions(newDiscussions)
        );
        printToast(() => restoreMessageFromDiscussion(assemblyId, discussionId, messageId));
    };

    const deleteNewDiscussion = discussionId => {
        deleteDiscussion(assemblyId, discussionId).then(newDiscussions => {
            onChangeDiscussions(R.take(maximumCount, newDiscussions));
        });
        printToast(() => restoreDiscussion(assemblyId, discussionId));
    };

    return (
        <Fragment>
            {discussions.map(discussion => {
                return (
                    <Discussion
                        addMessageToDiscussion={addNewMessageToDiscussion}
                        currentUser={user}
                        deleteMessageFromDiscussion={deleteDiscussionMessage}
                        deleteDiscussion={deleteNewDiscussion}
                        discussion={discussion}
                        isAssemblyLeader={isAssemblyLeader}
                        key={discussion.id}
                        trans={trans}
                    />
                );
            })}
            {userIsMember && !isLastDiscussion && (
                <Button
                    block
                    loading={buttonStatus === LOADING_MORE}
                    onClick={handleShowMoreDiscussions}
                    variant="primary"
                    weight="link"
                >
                    {trans('hives.discussions.older')}
                </Button>
            )}
        </Fragment>
    );
};

LastDiscussionsList.propTypes = {
    addNewToast: PropTypes.func,
    assemblyId: PropTypes.number.isRequired,
    buttonStatus: PropTypes.string.isRequired,
    discussions: PropTypes.array.isRequired,
    maximumCount: PropTypes.number,
    onChangeDiscussions: PropTypes.func.isRequired,
    handleShowMoreDiscussions: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    userIsMember: PropTypes.bool.isRequired,
    isLastDiscussion: PropTypes.bool.isRequired,
};

export default connect(undefined, {
    addNewToast: addToast,
})(LastDiscussionsList);
