import PropTypes from 'prop-types';

const INITIAL = 'INITIAL';
const INITIAL_LOADING = 'INITIAL_LOADING';
const PROCESSING = 'PROCESSING';
const SAVING = 'SAVING';
const LOADING = 'LOADING';
const LOADING_UPDATE = 'LOADING_UPDATE';
const LOADING_MORE = 'LOADING_MORE';
const READY = 'READY';
const FAILED = 'FAILED';
const SAVED = 'SAVED';

const propType = PropTypes.oneOf([
    INITIAL_LOADING,
    PROCESSING,
    SAVING,
    LOADING,
    LOADING_UPDATE,
    LOADING_MORE,
    READY,
    FAILED,
    SAVED,
    INITIAL,
]).isRequired;

export {
    INITIAL_LOADING,
    PROCESSING,
    SAVING,
    LOADING,
    LOADING_UPDATE,
    LOADING_MORE,
    READY,
    FAILED,
    SAVED,
    INITIAL,
    propType,
};
