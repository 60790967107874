import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import URI from 'URIjs';
import { getAllPickups } from 'models/assemblies';
import { fetchHives } from 'api/hives';
import connectToI18n from 'modules/i18n/connectToI18n';
import { connect } from 'react-redux';
import { userSelector } from 'modules/currentUser';
import { geocode } from 'models/mapbox';
import { AssembliesMapModalNavProvider } from 'components/Maps/ProductIdentity/AssembliesMap/AssembliesMapModalNavigactionContext.jsx';
import AssembliesMapContainer from 'components/Maps/ProductIdentity/AssembliesMap/AssembliesMapContainer.jsx';

export class FindAssembliesContainer extends React.Component {
    static propTypes = {
        prefilledcountrycode: PropTypes.string,
        prefilledcountryname: PropTypes.string,
        prefilledpostcodes: PropTypes.arrayOf(PropTypes.string), // e.g : ['75001'], ['75001,75002'] (may contains multiple codes)
        prefilledFullAddress: PropTypes.string,
        preloadedAssemblies: PropTypes.array,
        preloadedPickups: PropTypes.array,
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            assemblies: props.preloadedAssemblies || [],
            pickups: props.preloadedPickups || [],
            mapView: null,
            address: '',
        };
    }

    componentDidMount() {
        const { prefilledcountrycode, prefilledcountryname, prefilledpostcodes, user } = this.props;

        const fetchAssembliesAndPickups =
            this.props.preloadedAssemblies && this.props.preloadedPickups
                ? Promise.resolve([this.props.preloadedAssemblies, this.props.preloadedPickups])
                : Promise.all([fetchHives(), getAllPickups()]);

        fetchAssembliesAndPickups.then(([assemblies, pickups]) => {
            if (prefilledcountrycode && prefilledcountryname && prefilledpostcodes) {
                // coming from homepage with prefilled country code and name and one or more postcodes,
                // -> get full data for first postcode and center map on it with adapted zoom
                this.geocode(`${prefilledpostcodes[0]} ${prefilledcountryname}`, [
                    prefilledcountrycode,
                ]);
            }

            const hash = new URI().hash();

            const coordsFromHash = hash ? hash.split('/') : [null, null, null];

            const defautLookupCoords = hash
                ? {
                      latitude: parseFloat(coordsFromHash[1]),
                      longitude: parseFloat(coordsFromHash[2]),
                  }
                : R.path(['address', 'coordinates'], user);

            this.setState({
                assemblies,
                pickups,
                lookupCoords: defautLookupCoords,
                address: hash ? '' : R.pathOr('', ['address', 'city', 'name'], user),
                country_code: prefilledcountrycode,
                zipCodes: prefilledpostcodes,
            });
        });
    }

    // eslint-disable-next-line camelcase
    onAddressChange = ({ address, coordinates, country_code, splittedAddress }) => {
        if (coordinates) {
            this.updateMapView({ ...coordinates, zoom: 13 });
            this.updateLookupCoords(coordinates);
        }
        this.setState({
            address,
            country_code,
            zipCodes: [R.prop('zipCode', splittedAddress)],
        });
    };

    geocode = (address, countries) => {
        geocode(address, countries).then(result => {
            if (R.isNil(result)) {
                // cf. https://sentry.io/share/issue/573b8628287f4f0f9ed9f0d799bb761f/
                // Sometimes the result is null. Check and do nothing if it is the case.
                return;
            }
            const { coordinates, countryCode, postcode } = result;
            if (coordinates) {
                this.updateMapView({ ...coordinates, zoom: 13 });
                this.updateLookupCoords(coordinates);
            }

            if (countryCode && postcode) {
                this.setState({
                    country_code: countryCode,
                    zipCodes: postcode,
                });
            }
        });
    };

    updateMapView = mapView => {
        this.setState({ mapView });
    };

    updateLookupCoords = coords => {
        this.setState({
            lookupCoords: coords,
        });
    };

    render() {
        return (
            <AssembliesMapModalNavProvider>
                <AssembliesMapContainer
                    assemblies={this.state.assemblies}
                    pickups={this.state.pickups}
                    prefilledFullAddress={this.props.prefilledFullAddress}
                />
            </AssembliesMapModalNavProvider>
        );
    }
}

const mapStateToProps = state => ({
    user: userSelector(state),
});

export default connect(mapStateToProps)(connectToI18n(FindAssembliesContainer));
