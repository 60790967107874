import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import moment from 'moment';
import connectToI18n from 'modules/i18n/connectToI18n';
import { Grid, LoadingAnimation, Notice, Button } from 'components/ui';
import AssemblyHeader from './AssemblyHeader.jsx';
import HighlightClustersContainer from './HighlightClustersContainer.jsx';
import LastDiscussions from './LastDiscussions/LastDiscussions.jsx';
import AssemblyDetails from './AssemblyDetails.jsx';
import WelcomeMessage from './WelcomeMessage.jsx';
import SalePreparationBanner from './SalePreparationBanner.jsx';
import ProducerNotice from './ProducerNotice.jsx';
import Distributions from 'components/AssemblyPages/Distributions.jsx';
import ConstructionBox from 'components/AssemblyPages/ConstructionBox.jsx';
import HostAssemblyNavigation from 'components/Navigation/HostAssemblyNavigation.jsx';
import ProducersFeaturedListContainer from 'components/AssemblyPages/ProducersFeaturedListContainer.jsx';
import FeedbackSuggestionsContainer from 'components/FeedbackOnProducer/FeedbackSuggestionsContainer.jsx';
import { isMemberOfAssembly, isHostOfAssembly } from 'models/users';
import {
    getCurrentDistributions,
    saleCanBePrepared,
    getNextSaleToPrepare,
} from 'models/distributions';
import { getHiveCalendar } from 'api/hives';
import Utils from 'modules/utils';
const { linkTo } = Utils;
import { LOADING, READY } from 'modules/utils/ajaxStatuses';
import TermsPublicFooter from 'components/TermsPublicFooter.jsx';
import { OPEN, CLOSED } from 'modules/utils/AssemblyStatuses.js';
import { connect } from 'react-redux';
import { userSelector, getAssemblyByIdSelector, imperativeEditUser } from 'modules/currentUser';
import { userJoinAssembly, fetchHive } from 'api/hives';
import AppChannel from 'modules/channels/App';
import { Helmet } from 'react-helmet-async';

const ClosedNotice = connectToI18n(({ trans }) => {
    return (
        <Notice
            action={
                <Button variant="primary" href={linkTo('assemblies')}>
                    {trans('hives.find.title')}
                </Button>
            }
        >
            {trans('assemblyPage.closedMessage.title')}
        </Notice>
    );
});

const DISPLAY_FEEDBACK_SUGGESTIONS_WEEKS = [1, 13, 26, 39];

export class AssemblyHome extends React.Component {
    static propTypes = {
        initialAssembly: PropTypes.object,
        assemblyId: PropTypes.number.isRequired,
        user: PropTypes.object.isRequired,
        trans: PropTypes.func.isRequired,
        imperativeEditUser: PropTypes.func.isRequired,
        productIdentity: PropTypes.bool,
    };

    state = {
        assembly: this.props.initialAssembly,
        nextSale: null,
        preparationSaleStatus: LOADING,
    };

    componentDidMount() {
        const userIsHost = isHostOfAssembly(this.props.user, this.props.assemblyId);
        if (userIsHost) {
            // It's important to get calendar for 2 month for month transition
            getHiveCalendar(this.props.assemblyId, { months: 2 }).then(calendar => {
                // nextSales has no reason to be empty
                const nextSaleToBePrepared = R.pipe(
                    R.map(R.prop('distributions')),
                    R.map(R.head),
                    R.filter(saleCanBePrepared),
                    getNextSaleToPrepare
                )(calendar.days);

                this.setState({
                    nextSale: nextSaleToBePrepared,
                    preparationSaleStatus: READY,
                });
            });
        }

        if (!this.state.assembly) {
            fetchHive(this.props.assemblyId).then(assembly => {
                this.setState({ assembly });

                AppChannel.vent.trigger('analytics:assembly:visit', this.state.assembly);
            });
        } else {
            AppChannel.vent.trigger('analytics:assembly:visit', this.state.assembly);
        }
    }

    joinAssembly = () => {
        userJoinAssembly(this.props.assemblyId, this.props.user.id).then(assembly => {
            this.setState({ assembly });
            this.props.imperativeEditUser(
                R.evolve({
                    hivesAsMember: R.append(assembly),
                })
            );

            AppChannel.vent.trigger('analytics:assembly:join', assembly.id, assembly.name);
        });
    };

    render() {
        const { assembly, nextSale, preparationSaleStatus } = this.state;
        const { user } = this.props;

        if (!assembly) {
            return <LoadingAnimation type={this.props.productIdentity ? 'pi-spinner' : 'food'} />;
        }

        const assemblyIsOpen = assembly.status === OPEN;
        const assemblyIsClosed = assembly.status === CLOSED;
        const userIsMember = isMemberOfAssembly(assembly.id, user);
        const userIsHost = isHostOfAssembly(user, assembly.id);
        const userIsProducer = user.isFarmer;

        const displayFeedbackSuggestion =
            assemblyIsOpen && DISPLAY_FEEDBACK_SUGGESTIONS_WEEKS.indexOf(moment().week()) !== -1;

        return (
            <main className="assemblyHome">
                <Helmet>
                    <title>{`${this.props.trans('brand.name')} - ${assembly.name}`}</title>
                </Helmet>
                <AssemblyHeader user={user} assembly={assembly} joinAssembly={this.joinAssembly} />
                <div className="main">
                    {assemblyIsOpen && userIsHost && (
                        <HighlightClustersContainer assembly={assembly} />
                    )}
                    {assemblyIsClosed ? (
                        <ClosedNotice />
                    ) : (
                        <Grid md="9|3">
                            <div>
                                {userIsHost && (
                                    <Fragment>
                                        <HostAssemblyNavigation
                                            assemblyId={assembly.id}
                                            assembyStatus={assembly.status}
                                        />
                                        {displayFeedbackSuggestion && (
                                            <FeedbackSuggestionsContainer
                                                assemblyId={assembly.id}
                                            />
                                        )}
                                        {getCurrentDistributions(assembly).length < 2 &&
                                            assemblyIsOpen && (
                                                <SalePreparationBanner
                                                    assemblyId={assembly.id}
                                                    nextSale={nextSale}
                                                    preparationSaleStatus={preparationSaleStatus}
                                                />
                                            )}
                                    </Fragment>
                                )}
                                {userIsProducer && (
                                    <ProducerNotice assembly={assembly} user={user} />
                                )}
                                {assemblyIsOpen ? (
                                    <Fragment>
                                        {userIsMember ? (
                                            <Distributions assembly={assembly} />
                                        ) : (
                                            <WelcomeMessage
                                                host={assembly.leader}
                                                message={assembly.description}
                                            />
                                        )}
                                    </Fragment>
                                ) : (
                                    <ConstructionBox
                                        hostId={assembly.leader.id}
                                        assemblyId={assembly.id}
                                        userIsHost={userIsHost}
                                        userIsMember={userIsMember}
                                    />
                                )}
                                <ProducersFeaturedListContainer
                                    assemblyId={assembly.id}
                                    userIsMember={userIsMember}
                                />
                                <LastDiscussions
                                    user={user}
                                    assembly={assembly}
                                    userIsMember={userIsMember}
                                />
                            </div>
                            <div className="assemblyHome-details">
                                <AssemblyDetails assembly={assembly} />
                            </div>
                        </Grid>
                    )}
                    <TermsPublicFooter />
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    user: userSelector(state),
    initialAssembly: getAssemblyByIdSelector(ownProps.assemblyId)(state),
});

export default connect(mapStateToProps, { imperativeEditUser })(connectToI18n(AssemblyHome));
