import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import installI18nContext from 'modules/i18n/installI18nContext';
import { Wrapper, Heading, Text, MediaObject, Button, Grid } from 'components/ui';
import { Route, Switch, NavLink, useLocation } from 'react-router-dom';
import useI18n from 'hooks/I18n/useI18n';

import NewPublicHeader from 'components/NewPublicHeader.jsx';
import Avatar from 'components/Avatar.jsx';
import NearbyAssemblies from './NearbyAssemblies.jsx';
import AssemblyFarms from './AssemblyFarms.jsx';
import AssemblyClosed from './AssemblyClosed.jsx';
import { formatFullAddress } from 'models/assemblies';
import { CLOSED } from 'modules/utils/AssemblyStatuses';
import { formatFullName } from 'models/users';
import * as R from 'ramda';
import ScrollToTop from 'components/Sale/ScrollToTop.jsx';
import Modal from 'components/Modal.jsx';
import SimpleMap from 'components/Maps/SimpleMap.jsx';
import Pickup from 'components/AssemblyPages/Pickup.jsx';
import { getShortDayFromIsoWeekday } from 'modules/utils/dateAndTime.js';
import { slugify } from 'modules/utils/string.js';
import TermsPublicFooter from 'components/TermsPublicFooter.jsx';
import PINewPublicHeader from 'components/ProductIdentity/NewPublicHeader.jsx';
import PIAssemblyHomeContainer from 'components/Assembly/ProductIdentity/AssemblyHome/AssemblyHomeContainer.jsx';

const NavBar = ({ hasFarms }) => {
    const { trans } = useI18n();
    return (
        <nav>
            <NavLink
                exact
                to="/"
                className="publicPage-mainNavLink"
                activeClassName="publicPage-mainNavLink--selected"
            >
                {trans('public.assembly.header.link.assembly')}
            </NavLink>
            {hasFarms && (
                <NavLink
                    to="/producers"
                    className="publicPage-mainNavLink"
                    activeClassName="publicPage-mainNavLink--selected"
                >
                    {trans('public.assembly.header.link.producers')}
                </NavLink>
            )}

            <NavLink
                to="/assemblies"
                className="publicPage-mainNavLink"
                activeClassName="publicPage-mainNavLink--selected"
            >
                {trans('public.assembly.header.link.assemblies')}
            </NavLink>
        </nav>
    );
};
NavBar.propTypes = {
    hasFarms: PropTypes.bool,
};

const FooterNav = ({ hasFarms }) => {
    const { trans } = useI18n();
    return (
        <nav className="publicPage-footerNav">
            <NavLink
                exact
                to="/"
                className="publicPage-footerNavLink"
                activeClassName="publicPage-footerNavLink--selected"
            >
                <MediaObject
                    valignCenter
                    size="large"
                    media={<img alt="" src="/assets/images/public-pages/icon-products.svg" />}
                >
                    {trans('public.assembly.footerNav.products')}
                </MediaObject>
            </NavLink>
            {hasFarms && (
                <NavLink
                    to="/producers"
                    className="publicPage-footerNavLink"
                    activeClassName="publicPage-footerNavLink--selected"
                >
                    <MediaObject
                        valignCenter
                        size="large"
                        media={<img alt="" src="/assets/images/public-pages/icon-producers.svg" />}
                    >
                        {trans('public.assembly.footerNav.producers')}
                    </MediaObject>
                </NavLink>
            )}

            <NavLink
                to="/assemblies"
                className="publicPage-footerNavLink"
                activeClassName="publicPage-footerNavLink--selected"
            >
                <MediaObject
                    valignCenter
                    size="large"
                    media={<img alt="" src="/assets/images/public-pages/icon-farm.svg" />}
                >
                    {trans('public.assembly.footerNav.assemblies')}
                </MediaObject>
            </NavLink>
        </nav>
    );
};
FooterNav.propTypes = {
    hasFarms: PropTypes.bool,
};

export const AssemblyPage = props => {
    const { assembly, deliveryOptions, nearbyAssemblies, assemblyFarms } = props;
    const { pathname } = useLocation();

    const { trans } = useI18n();
    const [openPickupModal, setOpenPickupModal] = useState(false);
    const assemblyFarmsValidated = R.filter(R.propEq('status', 'validated farm'))(assemblyFarms);

    const pickupsForMap =
        deliveryOptions.length > 0
            ? deliveryOptions
                  .filter(deliveryOption => deliveryOption.type !== 'deliveryOffer')
                  .map(deliveryOption => {
                      if (deliveryOption.type === 'assembly') {
                          return { entity: assembly, placeType: 'assembly' };
                      }
                      return { entity: deliveryOption, placeType: deliveryOption.type };
                  })
            : [{ entity: assembly, placeType: 'assembly' }];
    const deliveryTimeSlotsForDeliveryOptions = [];
    if (assembly.deliveryTimeSlot) {
        // closed assemblies or assemblies in construction may have no deliveryTimeSlot
        deliveryTimeSlotsForDeliveryOptions.push({
            dayOfWeek: getShortDayFromIsoWeekday(assembly.deliveryTimeSlot.day),
            timeEnd: assembly.deliveryTimeSlot.ending,
            timeStart: assembly.deliveryTimeSlot.starting,
            timeZone: 'Europe/Paris', // Set an arbitrary timezone as they are not available in assembly data but they are not used here actually
        });
    }
    if (assembly.extraDeliveryTimeSlot) {
        deliveryTimeSlotsForDeliveryOptions.push({
            dayOfWeek: getShortDayFromIsoWeekday(assembly.extraDeliveryTimeSlot.day),
            timeEnd: assembly.extraDeliveryTimeSlot.ending,
            timeStart: assembly.extraDeliveryTimeSlot.starting,
            timeZone: 'Europe/Paris', // Set an arbitrary timezone as they are not available in assembly data but they are not used here actually
        });
    }

    return (
        <div className="publicPage">
            {pathname === '/' ? (
                <>
                    <PINewPublicHeader />
                    <PIAssemblyHomeContainer assemblyId={assembly.id}></PIAssemblyHomeContainer>
                </>
            ) : (
                <>
                    <NewPublicHeader />
                    <div className="publicPage-headerWrapper">
                        <Wrapper size="medium" xsPadding>
                            <header className="publicPage-header">
                                <div className="publicPage-titleBlock">
                                    <Text size="x-large">
                                        {trans('public.assembly.header.prefix')}
                                    </Text>
                                    <Heading size={1} className="publicPage-mainTitle">
                                        {assembly.place.address.city.name} - {assembly.name}
                                    </Heading>
                                    <Button
                                        weight="link"
                                        variant="primary"
                                        className="button--noPadding"
                                        onClick={() => setOpenPickupModal(true)}
                                    >
                                        {trans('global.practicalInformations')}
                                    </Button>
                                </div>
                                <div className="publicPage-headerAvatar">
                                    <Avatar
                                        photoId={assembly.leader.photo && assembly.leader.photo.id}
                                        size="xmedium"
                                        rounded
                                    />
                                    <div>
                                        <Text size="large">{formatFullName(assembly.leader)}</Text>
                                        <Text>{trans('assembly.roles.host')}</Text>
                                    </div>
                                </div>
                            </header>
                            {assembly.status !== CLOSED && (
                                <NavBar hasFarms={!R.isEmpty(assemblyFarms)} />
                            )}
                        </Wrapper>
                    </div>
                    <Wrapper size="medium" xsPadding>
                        <Fragment>
                            <ScrollToTop>
                                <Switch>
                                    <Route
                                        path="/producers"
                                        component={() => (
                                            <AssemblyFarms assemblyFarms={assemblyFarmsValidated} />
                                        )}
                                    />
                                    <Route
                                        path="/assemblies"
                                        component={() => (
                                            <NearbyAssemblies
                                                nearbyAssemblies={nearbyAssemblies}
                                                assembly={assembly}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/"
                                        component={() => {
                                            if (assembly.status === CLOSED) {
                                                return (
                                                    <AssemblyClosed
                                                        assembly={assembly}
                                                        nearbyAssemblies={nearbyAssemblies}
                                                    />
                                                );
                                            }
                                            return (
                                                <PIAssemblyHomeContainer assemblyId={assembly.id} />
                                            );
                                        }}
                                    />
                                </Switch>
                            </ScrollToTop>
                            {assembly.status !== CLOSED && (
                                <FooterNav hasFarms={!R.isEmpty(assemblyFarms)} />
                            )}
                        </Fragment>
                    </Wrapper>
                    <Modal
                        size="large"
                        title={trans('assemblyPage.products.pickUp')}
                        isOpened={openPickupModal}
                        closeModal={() => setOpenPickupModal(false)}
                    >
                        <Fragment>
                            <SimpleMap className="assemblyPageMap u-mb-4" places={pickupsForMap} />
                            <Grid md="4+">
                                {deliveryOptions.map(pickup => (
                                    <Pickup key={slugify(pickup.name)} pickup={pickup} />
                                ))}
                                {deliveryOptions.length === 0 && assembly.deliveryTimeSlot && (
                                    // if there are no open sales, display the delivery time slots of the assembly as open sales
                                    <Pickup
                                        pickup={{
                                            meetingPoint: {
                                                fullAddress: formatFullAddress(assembly),
                                            },
                                            timeSlots: deliveryTimeSlotsForDeliveryOptions,
                                        }}
                                    />
                                )}
                            </Grid>
                        </Fragment>
                    </Modal>
                    <TermsPublicFooter />
                </>
            )}
        </div>
    );
};

AssemblyPage.propTypes = {
    assembly: PropTypes.object.isRequired,
    nearbyAssemblies: PropTypes.array.isRequired,
    deliveryOptions: PropTypes.array.isRequired,
    assemblyFarms: PropTypes.array.isRequired,
};

export default installI18nContext(AssemblyPage);
