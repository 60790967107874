import './globalize.culture.fr-CH';

import 'moment/locale/fr-ch';

import frConfig from '../fr-FR';

export default {
    code: 'fr-CH',

    // bcode is here for the "backCode" ... the code as seen/sent by the back :O
    bCode: 'fr_CH',

    name: 'Français (Suisse)',
    supportEmail: 'support@ruchequiditoui.ch',
    privacyEmail: 'support@ruchequiditoui.ch',

    trainingSite: null,

    appStores: {
        apple: 'https://itunes.apple.com/fr/app/la-ruche-qui-dit-oui/id1052198033?mt=8',
        google: 'https://play.google.com/store/apps/details?id=com.lrqdo&hl=fr',
    },

    supportSite: {
        // Do not use support.laruchequiditoui.fr (yet ?)
        // it does not support https and we need https
        widgetDomain: 'laruchequiditoui.zendesk.com',
        homepage: 'http://support.laruchequiditoui.fr/hc/fr',
        host:
            'http://support.laruchequiditoui.fr/hc/fr/categories/200133228-Le-Coin-des-Responsables-de-Ruche',
        farmer: 'https://support.laruchequiditoui.fr/hc/fr/categories/5495742283410',
        member: 'http://support.laruchequiditoui.fr/hc/fr/categories/200133208-Le-coin-des-membres',
        user: 'http://support.laruchequiditoui.fr/hc/fr/categories/200133208-Le-coin-des-membres',
        kycInformation:
            'http://support.laruchequiditoui.fr/hc/fr/articles/218582808-Informations-suppl%C3%A9mentaires-requises-pour-le-paiement',
        farmerOrderMinimum: 'http://support.laruchequiditoui.fr/hc/fr/articles/115000669927',
        farmerOrderMinimumHowToChange:
            'http://support.laruchequiditoui.fr/hc/fr/articles/235538808',
        farmerInterregionalCatalog:
            'https://support.laruchequiditoui.fr/hc/fr/articles/115002918373',
        hostShippingInformationIntraCommunityVat:
            'https://laruchequiditoui.zendesk.com/hc/fr/articles/360002471674',
        producerShippingInformationIntraCommunityVat:
            'https://support.laruchequiditoui.fr/hc/fr/articles/360011464974-Ventes-transfrontali%C3%A8res-D%C3%A9clarer-la-TVA',
    },

    logos: {
        brown: '/assets/images/logos/brown/logo-fr.svg',
        turquoise: '/assets/images/logos/turquoise/logo-fr.svg',
        white: '/assets/images/logos/white/logo-fr.svg',
        whiteOutlined: '/assets/images/logos/whiteOutlined/logo-fr.svg',
        blue: '/assets/images/logos/blue/logo-fr.svg',
        christmas: '/assets/images/logos/christmas/logo-fr.svg',
        whiteChristmas: '/assets/images/logos/christmas/white/logo-fr.svg',
    },

    social: {
        facebook: 'https://www.facebook.com/laruchequiditoui',
        twitter: 'https://twitter.com/ruchequiditoui',
        blog: 'https://magazine.ruchequiditoui.ch/',
        instagram: 'https://instagram.com/laruchequiditoui',
        youtube: 'https://www.youtube.com/channel/UCXZPpeChS9Uw8n7Ipz3xqmA',
        linkedin: 'https://www.linkedin.com/company/la-ruche-qui-dit-oui-',
    },

    links: {
        about: 'https://nous.ruchequiditoui.ch/',
        press: 'http://ressources.laruchequiditoui.fr/presse',
        joinUs: 'https://nous.ruchequiditoui.ch/notre-equipe/',
        order: 'https://ruchequiditoui.ch/fr-CH/assemblies',
        giftCards: 'https://ruchequiditoui.ch/fr-CH/giftcards',
        openRuche: 'https://ruchequiditoui.ch/fr-CH/p/open',
        provideRuche: 'https://ruchequiditoui.ch/fr-CH/p/provide',
        proposeLocal: '',
    },

    proposeLocalForm:
        'https://docs.google.com/forms/d/e/1FAIpQLSdVdwOl-cIjMKgar2tm26KScljQtYB6Hbm8UPG9zcxaU1Oivw/viewform',

    dateFormats: {
        d: 'DD.MM.YYYY',
        D: 'dddd DD MMMM YYYY',
        I: 'dddd DD MMMM',
        i: 'DD MMMM YYYY',
        t: 'HH:mm',
        T: 'HH:mm:ss',
        f: 'dddd DD MMMM YYYY HH:mm',
        F: 'dddd DD MMMM YYYY HH:mm:ss',
        M: 'DD MMMM',
        Y: 'MMMM YYYY',
        shortDayDate: 'ddd DD MMMM',
        dateWithoutYear: 'DD.MM.',
    },

    inlineLogo: frConfig.inlineLogo,
    zendeskLocale: 'fr',
    zendeskSearch: 'https://laruchequiditoui.zendesk.com/api/v2/help_center/',
    zendeskCategories: {
        farmer: '5495742283410',
        member: '200133208',
        user: '200133208',
        host: '200133228,360001048374',
    },

    cguPage: 'https://ruchequiditoui.ch/fr-CH/p/terms',
    homepage: 'https://ruchequiditoui.ch',
    onSiteSaleArticle: 'https://support.laruchequiditoui.fr/hc/fr/articles/360010298913',
    minirucheHelpCenter: 'https://miniruche.zendesk.com/hc/fr',
};
