import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import AuthRoute from './AuthRoute.jsx';
import ErrorPage from 'components/Error/ErrorPage.jsx';
import FindAssembliesContainer from 'components/FindAssemblies/FindAssembliesContainer.jsx';
import InvoicesContainer from 'components/Invoices/InvoicesContainer.jsx';
import ProductFeedbackContainer from 'components/ProductFeedback/ProductFeedbackContainer.jsx';
import CheckoutRouter from 'components/Checkout/CheckoutRouter.jsx';
import PIBasketsContainer from 'components/Baskets/ProductIdentity/BasketsContainer.jsx';
import MobileOnboardingPage from 'components/MobileOnboarding/MobileOnboardingPage.jsx';
import ValidateSCUContainer from 'components/ValidateSCU/ValidateSCUContainer.jsx';
import Url from 'modules/url';
import MainRouter from '../../app/scripts/config/MainRouter.js';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import * as R from 'ramda';
import { memberRoutes } from './MemberRoutes.jsx';
import { messagingRoutes } from './MessagingRoutes.jsx';
import { hostProfileRoutes } from './HostProfileRoutes.jsx';
import { professionalRoutes } from './ProfessionalRoutes.jsx';
import { hostRoutes } from './HostRoutes.jsx';
import { useSelector } from 'react-redux';
import { userSelector } from 'modules/currentUser/selectors';

import { producerRoutes } from './ProducerRoutes.jsx';
import { unauthenticatedRoutes } from './UnauthenticatedRoutes.jsx';
import useI18n from 'hooks/I18n/useI18n.js';
import { assemblyRoutes } from './AssemblyRoutes.jsx';
import AnonymousCheckoutRouter from 'components/Checkout/Anonymous/AnonymousCheckoutRouter.jsx';
import NavigationContainer from 'components/Navigation/NavigationContainer.jsx';
import TenYearsContainer from 'components/TenYears/TenYearsContainer.jsx';
import ImpersonateRedirect from 'components/ImpersonateRedirect.jsx';

import Logout from 'components/Logout.jsx';
import { QueryClientProvider } from 'react-query';
import queryClient from 'modules/api/queryClient.js';
import { ReactQueryDevtools } from 'react-query/devtools';
import ProposeLocalContainer from '../Corpo/views/ProposeLocal/ProposeLocalContainer.jsx';
import v2Routes from './V2Routes.jsx';

const CommunityRouter = lazy(() => import('./CommunityRouter.jsx'));

export const CoreRouter = () => {
    const currentUser = useSelector(userSelector);
    const { i18n } = useI18n();

    const renderReactRoute = route => (
        <AuthRoute
            key={route.definition}
            path={route.definition}
            conditions={route.conditions ? route.conditions : false}
            render={routerProps => {
                MainRouter.changeReactContext(
                    route.activeLink,
                    route.context,
                    R.path(['params', 'assemblyId'], routerProps.match)
                );
                return route.render({ ...routerProps, currentUser });
            }}
            exact={!!route.exact}
        />
    );

    return (
        <HelmetProvider context={{}}>
            <QueryClientProvider client={queryClient}>
                <Router basename={`/${i18n}`}>
                    <Switch>
                        <Route path="/accomodate" component={ProposeLocalContainer} />
                        <AuthRoute
                            path="/10-ans"
                            conditions={['isAuthenticated']}
                            component={TenYearsContainer}
                        />

                        <Route>
                            <Switch>
                                {/* V2 routes */}
                                {v2Routes.map(renderReactRoute)}
                                <Route>
                                    <NavigationContainer />
                                    <Switch>
                                        {assemblyRoutes.map(renderReactRoute)}
                                        {memberRoutes.map(renderReactRoute)}
                                        {messagingRoutes.map(renderReactRoute)}
                                        {hostProfileRoutes.map(renderReactRoute)}
                                        {professionalRoutes.map(renderReactRoute)}
                                        {hostRoutes.map(renderReactRoute)}

                                        {producerRoutes.map(renderReactRoute)}

                                        {unauthenticatedRoutes.map(renderReactRoute)}
                                        <Route
                                            path="/assemblies/:assemblyId/community/"
                                            render={() => (
                                                <Suspense
                                                    fallback={<LoadingAnimation type="spinner" />}
                                                >
                                                    <CommunityRouter />
                                                </Suspense>
                                            )}
                                        />
                                        <Route
                                            path="/mobile/onboarding:queryString?"
                                            component={MobileOnboardingPage}
                                        />
                                        <Route
                                            path="/validate/scu/:type?"
                                            component={ValidateSCUContainer}
                                        />
                                        <AuthRoute
                                            path="/feedback"
                                            component={ProductFeedbackContainer}
                                            conditions={['isAuthenticated']}
                                        />
                                        <AuthRoute
                                            path="/invoices"
                                            component={InvoicesContainer}
                                            conditions={['isAuthenticated']}
                                        />
                                        <Route
                                            path="/assemblies"
                                            exact
                                            render={() => {
                                                const uri = new Url();
                                                const params = uri.getQueryParameters();

                                                if (params.fullAddress) {
                                                    return (
                                                        <FindAssembliesContainer
                                                            prefilledFullAddress={
                                                                params.fullAddress
                                                            }
                                                            productIdentity
                                                        />
                                                    );
                                                }

                                                return <FindAssembliesContainer productIdentity />;
                                            }}
                                        />
                                        <Route
                                            path="/assemblies?register=success"
                                            render={() => {
                                                return <FindAssembliesContainer productIdentity />;
                                            }}
                                        />
                                        <AuthRoute
                                            path="/orders/:orderId"
                                            conditions={['isAuthenticated']}
                                            render={({ match }) => (
                                                <CheckoutRouter
                                                    orderId={Number(match.params.orderId)}
                                                />
                                            )}
                                        />
                                        <Route
                                            path="/checkout/:basketUuid"
                                            render={({ match }) => (
                                                <AnonymousCheckoutRouter
                                                    basketUuid={match.params.basketUuid}
                                                />
                                            )}
                                        />
                                        <AuthRoute
                                            path={['/basket/:distributionId?', '/basket*']}
                                            conditions={['isAuthenticated']}
                                            render={() => <PIBasketsContainer />}
                                        />
                                        <Route
                                            path="/impersonate/:username"
                                            component={ImpersonateRedirect}
                                        />
                                        <Route path="/logout" component={Logout} />
                                        {/*
                            handle URL like
                            /assemblies?countrycode=fr&postcode=75001&countryname=France#11/50.632/3.05749
                            or
                            /assemblies?countrycode=fr&postcode=75001,75002&countryname=France#11/50.632/3.05749 (multiple post codes)
                            */}
                                        <Route
                                            path="/assemblies:filter"
                                            render={() => {
                                                const uri = new Url();
                                                const params = uri.getQueryParameters();

                                                if (
                                                    params.postcodes &&
                                                    typeof params.postcodes === 'string'
                                                ) {
                                                    const prefilledpostcodes = params.postcodes.split(
                                                        ','
                                                    );
                                                    return (
                                                        <FindAssembliesContainer
                                                            prefilledcountrycode={
                                                                params.countrycode
                                                            }
                                                            prefilledcountryname={
                                                                params.countryname
                                                            }
                                                            prefilledpostcodes={prefilledpostcodes}
                                                            productIdentity
                                                        />
                                                    );
                                                }

                                                if (params.fullAddress) {
                                                    return (
                                                        <FindAssembliesContainer
                                                            prefilledFullAddress={
                                                                params.fullAddress
                                                            }
                                                            productIdentity
                                                        />
                                                    );
                                                }

                                                return <FindAssembliesContainer productIdentity />;
                                            }}
                                        />
                                        <Route
                                            component={() => <ErrorPage errorType="notFound" />}
                                        />
                                    </Switch>
                                </Route>
                            </Switch>
                        </Route>
                    </Switch>
                </Router>
                {___DEV___ && <ReactQueryDevtools initialIsOpen={false} />}
            </QueryClientProvider>
        </HelmetProvider>
    );
};

export default CoreRouter;
