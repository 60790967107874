import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';
import filterIcon from 'app/assets/new-design/images/icons/filter.svg';
import searchIcon from 'app/assets/new-design/images/icons/search.svg';
import useI18n from 'hooks/I18n/useI18n';
import Autocomplete from 'src/components/molecules/Autocomplete/Autocomplete.jsx';
import { SMALL_SIZE } from 'src/components/atoms/Input/Input.jsx';

export const MobileTopBar = ({
    filterEnabledCount,
    onFilterClick,
    onAutocompleteChange,
    onAutocompleteBlur,
    onSuggestionClick,
    autoCompleteRef,
    autocompleteSuggestions = [],
}) => {
    const { trans } = useI18n();
    const searchSVG = <InlineSVG className="map-search-icon" src={searchIcon} />;

    return (
        <div className="map-top-bar">
            <Autocomplete
                placeholder={trans('assembliesMap.autocomplete.placeholder')}
                prefix={searchSVG}
                onChange={onAutocompleteChange}
                onBlur={onAutocompleteBlur}
                onItemClick={onSuggestionClick}
                suggestions={autocompleteSuggestions}
                ref={autoCompleteRef}
                size={SMALL_SIZE}
            />
            <button role="button" className="filter-btn" onClick={onFilterClick}>
                <InlineSVG raw className="map-filter-icon" src={filterIcon} />
                {filterEnabledCount > 0 && <div className="filter-count">{filterEnabledCount}</div>}
            </button>
        </div>
    );
};

MobileTopBar.propTypes = {
    filterEnabledCount: PropTypes.number.isRequired,
    onFilterClick: PropTypes.func.isRequired,
    onAutocompleteChange: PropTypes.func.isRequired,
    onAutocompleteBlur: PropTypes.func.isRequired,
    onSuggestionClick: PropTypes.func.isRequired,
    autoCompleteRef: PropTypes.object.isRequired,
    autocompleteSuggestions: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            metadata: PropTypes.shape({
                geoloc: PropTypes.shape({
                    lat: PropTypes.number.isRequired,
                    lng: PropTypes.number.isRequired,
                }).isRequired,
            }).isRequired,
        })
    ).isRequired,
};

export default MobileTopBar;
