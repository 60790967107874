import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';
import { Separator } from 'components/ui';

import LoadingAnimation from 'components/LoadingAnimation.jsx';
import Heading from 'components/Heading.jsx';
import MediaObject from 'components/MediaObject.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import { LOADING_UPDATE, propType as statusPropType } from 'modules/utils/ajaxStatuses';
import AppChannel from 'modules/channels/App';
import useI18n from 'hooks/I18n/useI18n.js';
import { FARMER, HOST } from 'modules/utils/userTypes';
import Caret from 'app/assets/new-design/images/icons/caret.svg';
import Input from 'src/components/atoms/Input/Input.jsx';
import Link from 'src/components/atoms/Link/Link.jsx';

export const HelpCenterSearch = ({
    searchValue,
    onSearch,
    loadingArticles,
    articles,
    promotedArticles,
    userType,
    status,
}) => {
    const { trans, i18nConfig } = useI18n();

    const handleSearch = e => {
        onSearch(e.target.value);
        loadingArticles(e.target.value);
        AppChannel.vent.trigger('analytics:newHelpCenter:searchOnHelpCenter');
    };

    const handleClick = () => {
        AppChannel.vent.trigger('analytics:newHelpCenter:visitArticle');
    };

    let result = null;
    const title =
        searchValue.length > 0
            ? trans('helpCenter.search.results', {
                  '%searchValue%': searchValue,
              })
            : trans('helpCenter.search.promoted');
    if (status === LOADING_UPDATE) {
        result = <LoadingAnimation type="pi-spinner" />;
    } else {
        if (articles.length > 0) {
            result = (
                <div className="helpCenter-list">
                    {articles.map(article => (
                        <a
                            className="helpCenter-article"
                            key={article.id}
                            target="_blank"
                            href={article.html_url}
                            onClick={handleClick}
                        >
                            {article.name}
                            <div>
                                <InlineSVG raw src={Caret} />
                            </div>
                        </a>
                    ))}
                </div>
            );
        } else if (searchValue.length > 0) {
            result = (
                <Text size="16px" lineHeight="24px" color="grey8" className="helpCenter-list">
                    {trans('helpCenter.search.no.results', {
                        '%searchValue%': searchValue,
                    })}
                </Text>
            );
        }
    }

    return (
        <div className="helpCenter-search">
            <Input
                onChange={handleSearch}
                placeholder={trans('helpCenter.search.label')}
                value={searchValue}
            />
            <Heading productIdentity family="inter" size={4}>
                {title}
            </Heading>
            {result}
            {!result && (
                <div className="helpCenter-list">
                    {promotedArticles.map(article => (
                        <a
                            className="helpCenter-article"
                            key={article.id}
                            target="_blank"
                            href={article.html_url}
                            onClick={handleClick}
                        >
                            {article.name}
                            <div>
                                <InlineSVG raw src={Caret} />
                            </div>
                        </a>
                    ))}
                </div>
            )}
            <Link
                bypassRouter
                className="helpCenter-link nd-link"
                target="_blank"
                href={i18nConfig.supportSite[userType]}
            >
                {trans('helpCenter.go.to.helpCenter')}
            </Link>
            <Separator className="helpCenter-separator" color={'gray1'} />

            {userType === HOST && i18nConfig.hostEssential && (
                <>
                    <div className="helpCenter-role">
                        <Heading productIdentity family="inter" size={4}>
                            {trans('helpCenter.title.hostEssential')}
                        </Heading>
                        <MediaObject
                            media={
                                <img
                                    className="helpCenter-hostIcon"
                                    src="/assets/new-design/images/Host-tiny.svg"
                                />
                            }
                            size="small"
                            valignCenter
                        >
                            <Text size="14px" lineHeight="20px" color="grey8">
                                {trans('helpCenter.hostEssential.intro')}
                            </Text>
                            <a
                                className="helpCenter-link nd-link"
                                target="_blank"
                                href={i18nConfig.hostEssential.url}
                            >
                                <Text size="14px" lineHeight="20px">
                                    {trans('helpCenter.go.to.hostEssential')}
                                </Text>
                            </a>
                        </MediaObject>
                    </div>
                    <Separator className="helpCenter-separator" color={'gray1'} />
                </>
            )}

            {userType === FARMER && i18nConfig.prodEssential && (
                <>
                    <div className="helpCenter-role">
                        <Heading productIdentity family="inter" size={4}>
                            {trans('helpCenter.prodEssential.title')}
                        </Heading>
                        <MediaObject
                            media={
                                <img
                                    className="helpCenter-producerIcon"
                                    src="/assets/new-design/images/Producer-tiny.svg"
                                />
                            }
                            size="small"
                            valignCenter
                        >
                            <Text size="14px" lineHeight="20px" color="grey8">
                                {trans('helpCenter.prodEssential.intro')}
                            </Text>
                            <a
                                className="helpCenter-link nd-link"
                                target="_blank"
                                href={i18nConfig.prodEssential.url}
                            >
                                <Text size="14px" lineHeight="20px">
                                    {trans('helpCenter.prodEssential.goTo')}
                                </Text>
                            </a>
                        </MediaObject>
                    </div>
                    <Separator className="helpCenter-separator" color={'gray1'} />
                </>
            )}

            {userType === HOST && i18nConfig.trainingSite && !i18nConfig.hostEssential && (
                <>
                    <div className="helpCenter-role">
                        <Heading productIdentity family="inter" size={4}>
                            {trans('helpCenter.title.hostEssential')}
                        </Heading>
                        <a
                            className="helpCenter-link nd-link u-mr-1"
                            target="_blank"
                            href={i18nConfig.trainingSite.url}
                        >
                            <Text tagName="span" size="14px" lineHeight="20px">
                                {trans('helpCenter.go.to.trainingSite')}
                            </Text>
                        </a>
                        {i18nConfig.trainingSite.email && (
                            <div>{i18nConfig.trainingSite.email}</div>
                        )}
                        <Text tagName="span" size="14px" lineHeight="20px" color="grey8">
                            (
                            {trans('navigation.help.trainingSitePassword', {
                                '%password%': i18nConfig.trainingSite.password,
                            })}
                            )
                        </Text>
                    </div>
                    <Separator className="helpCenter-separator" color={'gray1'} />
                </>
            )}
        </div>
    );
};

HelpCenterSearch.propTypes = {
    searchValue: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    loadingArticles: PropTypes.func.isRequired,
    articles: PropTypes.array.isRequired,
    promotedArticles: PropTypes.array.isRequired,
    userType: PropTypes.string.isRequired,
    status: statusPropType,
};

export default HelpCenterSearch;
