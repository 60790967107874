import React from 'react';
import * as R from 'ramda';

export function childrenCount(count) {
    return function(props, propName, componentName) {
        if (React.Children.count(props[propName]) !== count) {
            return new Error(`${componentName} should have exactly ${count} children.`);
        }
        return null;
    };
}

export function requiredIfOtherAbsent(otherPropName) {
    return function(props, propName) {
        if (R.isNil(props[propName]) && R.isNil(props[otherPropName])) {
            return new Error(
                `${propName} is required to be provided when ${otherPropName} is not provided.`
            );
        }
        return null;
    };
}
