import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Portal } from 'react-portal';
import InlineSVG from 'svg-inline-react';

import onClickOutside from 'react-onclickoutside';

import * as Backdrop from 'modules/utils/backdrop';

import { Heading } from 'components/ui';

import CloseSvg from 'app/assets/new-design/images/icons/close.svg';

const KEYCODES = {
    ESCAPE: 27,
};

export const POSITION_LEFT = 'left';
export const POSITION_RIGHT = 'right';

const ResponsiveSidePanel = ({
    onClose,
    children,
    footer,
    title,
    isOpened,
    position,
    className,
}) => {
    const [isScrollable, setIsScrollable] = useState(false);
    const containerContentRef = useRef();

    useEffect(() => {
        if (isOpened) {
            Backdrop.show();
        } else {
            Backdrop.hide();
        }
        return () => {
            Backdrop.hide();
        };
    }, [isOpened]);

    useEffect(() => {
        const handleKeydown = e => {
            if (e.keyCode === KEYCODES.ESCAPE && isOpened) {
                onClose(e);
            }
        };
        document.addEventListener('keydown', handleKeydown);
        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, [isOpened, onClose]);

    ResponsiveSidePanel.handleClickOutside = () => {
        return;
    };

    const onClickInside = e => e.stopPropagation();

    useEffect(() => {
        const childrenHeight = Array.from(containerContentRef.current?.children || []).reduce(
            (acc, el) => acc + el.offsetHeight,
            0
        );
        setIsScrollable(containerContentRef.current?.offsetHeight < childrenHeight);
    }, [children]);
    return (
        <Portal>
            <div className={classnames('pi-responsive-side-panel', className)} onClick={onClose}>
                <div
                    className={classnames('responsiveSidePanel', { [position]: position })}
                    onClick={onClickInside}
                    role="dialog"
                    aria-modal="true"
                >
                    <div className="pi-responsive-side-panel-header">
                        {title && (
                            <Heading family="inter" productIdentity size={4} rank={1}>
                                {title}
                            </Heading>
                        )}
                        <button className="close-button-panel" onClick={onClose}>
                            <InlineSVG raw src={CloseSvg} />
                        </button>
                    </div>
                    <div ref={containerContentRef} className="pi-responsive-side-panel-content">
                        {children}
                    </div>
                    {footer && (
                        <div
                            className={classnames('pi-responsive-side-panel-footer-container', {
                                'content-scrollable': isScrollable,
                            })}
                        >
                            {footer}
                        </div>
                    )}
                </div>
            </div>
        </Portal>
    );
};

ResponsiveSidePanel.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    footer: PropTypes.node,
    title: PropTypes.string,
    isOpened: PropTypes.bool,
    position: PropTypes.oneOf([POSITION_LEFT, POSITION_RIGHT]),
    className: PropTypes.string,
};
ResponsiveSidePanel.defaultProps = {
    position: POSITION_RIGHT,
};

const clickOutsideConfig = {
    handleClickOutside: () => ResponsiveSidePanel.handleClickOutside,
};

export default onClickOutside(ResponsiveSidePanel, clickOutsideConfig);
