import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useResponsive from 'hooks/Navigation/useResponsive';

import useI18n from 'hooks/I18n/useI18n';
import { toggle as togglePanel, HELP_PANEL } from 'modules/visiblePanel';
import HelpCenterContainer from 'components/Navigation/HelpCenter/HelpCenterContainer.jsx';
import ResponsiveSidePanel, {
    POSITION_RIGHT,
} from 'components/Navigation/ProductIdentity/ResponsiveSidePanel.jsx';

const HelpPanel = props => {
    const { currentAssembly, farm, user } = props;
    const isSmallWidth = useResponsive();
    const dispatch = useDispatch();
    const onClose = () => dispatch(togglePanel(HELP_PANEL));
    const { trans } = useI18n();
    return (
        <ResponsiveSidePanel
            isOpened
            position={POSITION_RIGHT}
            onClose={onClose}
            title={isSmallWidth ? `${user.firstName} ${user.lastName}` : trans('menu.help.title')}
            className="simpleMemberTopNavigation-helpPanel"
            isNewNavigationLayout
        >
            <HelpCenterContainer
                user={user}
                currentAssembly={currentAssembly || null}
                farm={farm}
            />
        </ResponsiveSidePanel>
    );
};

HelpPanel.propTypes = {
    currentAssembly: PropTypes.object,
    farm: PropTypes.object,
    user: PropTypes.object,
};
export default HelpPanel;
