import { each } from 'underscore';
import AnalyticsSingleton from 'modules/analytics';

function TrackerProxy(channel) {
    this.tracker = AnalyticsSingleton;
    this.channel = channel;
}

TrackerProxy.prototype.events = {
    route: 'sendPageView',
    'analytics:role:changed': 'updateRole',
    logout: 'clearRole',
    login: 'updateUserId',
    register: 'trackUserRegistration',
    'context:change': 'updateAssemblyId',
    'analytics:payment:success': 'eCommercePayment',
    'analytics:assemblyCard:callHost': 'assemblyCardCallHost',
    'analytics:assemblyCard:openComposeMessageHost': 'assemblyCardOpenComposeMessageHost',
    'assembly:join:show': 'showJoinAssemblyForm',
    'assembly:join:submit': 'submitJoinAssemblyForm',
    'assemblyPage:join:submit': 'submitAssemblyPageJoinForm',
    'professional:financialDocuments:download': 'downloadFinancialDocument',
    'download:producerOrders': 'downloadProducerOrders',
    'analytics:farmSearch:productTypeOptionSelected': 'userInputProductTypeFilter',
    'http:error': 'trackHttpError',
    'analytics:producerCard:callProducer': 'producerCardCallProducer',
    'analytics:producerCard:openComposeMessageProducer': 'producerCardOpenComposeMessageProducer',
    'analytics:newHelpCenterContactCard:contactHost': 'newHelpCenterComposeMessageHost',
    'analytics:newHelpCenter:openHelpPanel': 'newHelpCenterOpenHelpPanel',
    'analytics:newHelpCenter:searchOnHelpCenter': 'newHelpCenterSearchOnHelpCenter',
    'analytics:newHelpCenter:visitArticle': 'newHelpCenterVisitArticle',
    'analytics:anonymousCheckout:register': 'registerFromAnonymousPayment',
    'analytics:map:navigate': 'trackMapNavigation',
    'analytics:productPage:view': 'trackProductPageView',
    'analytics:cartPage:view': 'trackCartPageView',
    'analytics:cart:add': 'trackAddToBasket',
    'analytics:payment:addPaymentInfo': 'trackPaymentFormSubmission',
    'analytics:assembly:visit': 'trackAssemblyVisit',
    'analytics:assembly:join': 'trackAssemblyJoin',
    'analytics:itemList:view': 'trackViewItemList',
    'analytics:checkout:begin': 'trackBeginCheckout',
};

TrackerProxy.prototype.start = function() {
    each(
        this.events,
        function(handlerName, eventName) {
            this.channel.on(eventName, this.tracker[handlerName].bind(this.tracker));
        }.bind(this)
    );
};

TrackerProxy.prototype.stop = function() {
    each(
        this.events,
        function(handlerName, eventName) {
            this.channel.off(eventName, this.tracker[handlerName].bind(this.tracker));
        }.bind(this)
    );
};

export default TrackerProxy;
