import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const InputErrors = ({ isVisible, className, clientErrors = [], serverErrors = [] }) => {
    if (!isVisible || (serverErrors.length === 0 && clientErrors.length === 0)) {
        return null;
    }

    return (
        <ul className={classnames('errors-list', className)}>
            {serverErrors.map(error => (
                <li key={error}>{error}</li>
            ))}
            {clientErrors.map(error => (
                <li key={error}>{error}</li>
            ))}
        </ul>
    );
};

InputErrors.propTypes = {
    isVisible: PropTypes.bool,
    clientErrors: PropTypes.array,
    serverErrors: PropTypes.array,
    className: PropTypes.string,
};

export default InputErrors;
