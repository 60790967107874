export const SWITCH_ASSEMBLY = 'SWITCH_ASSEMBLY';
export const SWITCH_DISTRIBUTION = 'SWITCH_DISTRIBUTION';
export const UPDATE_THEME = 'UPDATE_THEME';
export const UPDATE_ACTIVE_LINK = 'UPDATE_ACTIVE_LINK';

export const switchAssembly = assembly => dispatch => {
    dispatch({
        type: SWITCH_ASSEMBLY,
        payload: {
            assembly,
        },
    });
};

export const switchDistribution = (assembly, distribution) => dispatch => {
    dispatch({
        type: SWITCH_DISTRIBUTION,
        payload: {
            assembly,
            distribution,
        },
    });
};

export const updateTheme = theme => dispatch =>
    dispatch({
        type: UPDATE_THEME,
        payload: {
            theme,
        },
    });

export const updateActiveLink = activeLink => dispatch =>
    dispatch({
        type: UPDATE_ACTIVE_LINK,
        payload: {
            activeLink,
        },
    });
