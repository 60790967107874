import './globalize.culture.nl-BE';

import moment from 'moment';
moment.defineLocale('nl-BE', { parentLocale: 'nl' });

import nlConfig from '../nl-NL';

export default {
    code: 'nl-BE',

    // bcode is here for the "backCode" ... the code as seen/sent by the back :O
    bCode: 'nl_BE',

    name: 'Nederlands (België)',
    supportEmail: 'helpdesk@boerenenburen.be',
    privacyEmail: 'privacy@boerenenburen.be',
    trainingSite: null,

    appStores: {
        apple: 'https://itunes.apple.com/be/app/boeren-buren/id1052198033?l=nl&mt=8',
        google: 'https://play.google.com/store/apps/details?id=com.lrqdo&hl=nl',
    },

    supportSite: {
        widgetDomain: 'boerenenburen.zendesk.com',
        kycInformation:
            'https://s3-eu-west-1.amazonaws.com/laruche-prod/BENLcom/2016.04.06-FAQMembers-AdditionalInformationbeforePaymentBoerenBuren.pdf',
        proImprovementsHost:
            'https://s3-eu-west-1.amazonaws.com/help-nl/HelpPageNLBuren-VERBETERINGEN+IN+DE+RUIMTE+VOOR+JE+BV-DOSSIER.pdf',
        proImprovementsProd:
            'https://s3-eu-west-1.amazonaws.com/help-nl/HelpPageNLBuren-VERBETERINGEN+IN+DE+RUIMTE+VOOR+JE+PRODUCENTEN-DOSSIER.pdf',
        host:
            'https://boerenenburen.zendesk.com/hc/nl/categories/201190425-Buurderij-Verantwoordelijken',
        farmer: 'https://boerenenburen.zendesk.com/hc/nl/categories/201190385-Producenten',
        member: 'https://boerenenburen.zendesk.com/hc/nl/categories/201190405-Buren',
        user: 'https://boerenenburen.zendesk.com/hc/nl/categories/201190405-Buren',
    },

    logos: {
        brown: '/assets/images/logos/brown/logo-nl.svg',
        turquoise: '/assets/images/logos/turquoise/logo-nl.svg',
        white: '/assets/images/logos/white/logo-nl.svg',
        whiteOutlined: '/assets/images/logos/whiteOutlined/logo-nl.svg',
        blue: '/assets/images/logos/blue/logo-nl.svg',
        christmas: '/assets/images/logos/christmas/logo-nl.svg',
        whiteChristmas: '/assets/images/logos/christmas/white/logo-nl.svg',
    },

    social: {
        facebook: 'https://www.facebook.com/TheFoodAssembly',
        twitter: 'https://twitter.com/foodassembly',
        blog: 'http://blog.boerenenburen.be/',
        instagram: 'https://instagram.com/boerenenburen',
        youtube: 'https://vimeo.com/user45067441',
        linkedin: 'https://www.linkedin.com/company/la-ruche-qui-dit-oui-',
    },

    links: {
        about: 'https://wij.boerenenburen.be/',
        press: 'https://wij.boerenenburen.be/pers/',
        joinUs: 'https://wij.boerenenburen.be/ons-team/',
        order: 'https://boerenenburen.be/nl-BE/assemblies',
        giftCards: 'https://boerenenburen.be/nl-BE/giftcards',
        openRuche: 'https://boerenenburen.be/nl-BE/p/open',
        provideRuche: 'https://boerenenburen.be/nl-BE/p/provide',
        ourDatas: 'https://wij.boerenenburen.be/jouw-gegevens',
        proposeLocal: '',
    },

    proposeLocalForm:
        'https://docs.google.com/forms/d/e/1FAIpQLSf7molLy-A9sH_Lgvf7QfhNR2hTBD5mZnO_LRyflK8R4y5UzA/viewform',

    dateFormats: {
        d: 'DD/MM/YYYY',
        D: 'dddd DD MMMM YYYY',
        I: 'dddd DD MMMM',
        i: 'DD MMMM YYYY',
        t: 'HH:mm',
        T: 'HH:mm:ss',
        f: 'dddd DD MMMM YYYY HH:mm',
        F: 'dddd DD MMMM YYYY HH:mm:ss',
        M: 'DD MMMM',
        Y: 'MMMM YYYY',
        shortDayDate: 'ddd DD MMMM',
        dateWithoutYear: 'DD/MM',
    },

    inlineLogo: nlConfig.inlineLogo,
    zendeskLocale: 'nl',
    zendeskSearch: 'https://boerenenburen.zendesk.com/api/v2/help_center/',
    zendeskCategories: {
        farmer: '201190385',
        member: '201190405',
        user: '201190405',
        host: '201190425',
    },

    cguPage: 'https://boerenenburen.be/nl-BE/p/terms',
    homepage: 'https://boerenenburen.be',
    minirucheHelpCenter: 'https://boerenenburen.zendesk.com/hc/nl',
};
