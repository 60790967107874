import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import RedirectToLogin from './RedirectToLogin.jsx';
import { useSelector } from 'react-redux';
import { userSelector, hivesAsLeaderSelector } from 'modules/currentUser/selectors';
import { isMemberOfAssembly, isLeaderOfAssembly } from 'models/users.js';
import * as R from 'ramda';
import Backbone from 'backbone';

const AuthRoute = ({ path, conditions, ...rest }) => {
    const currentUser = useSelector(userSelector);
    const leaderAssemblies = useSelector(hivesAsLeaderSelector);

    const isAuthenticated = () => !currentUser.anonymous;
    const isMemberOfHive = assemblyId => isMemberOfAssembly(parseInt(assemblyId, 10), currentUser);
    const isLeaderOfHive = assemblyId =>
        isLeaderOfAssembly({
            user: currentUser,
            assemblyId: parseInt(assemblyId, 10),
        });
    const hasLeaderProfile = () => currentUser && !!currentUser.leaderProfile;
    const hasFarm = () => currentUser && !!currentUser.farm;
    const hasHiveCompany = assemblyId => {
        const assembly = R.find(R.propEq('id', parseInt(assemblyId, 10)))(leaderAssemblies);
        return assembly && !!assembly.company;
    };
    const hiveHasTimeslot = assemblyId => {
        const assembly = R.find(R.propEq('id', parseInt(assemblyId, 10)))(leaderAssemblies);
        const cond = assembly && !!assembly.deliveryTimeSlot;
        if (!cond) {
            return Backbone.history.navigate(`assemblies/${assemblyId}/collection`);
        }
        return cond;
    };

    const isNotAuthenticated = () => !isAuthenticated();

    const checkCondition = params => condition => {
        switch (condition) {
            case 'isMemberOfHive':
                return isMemberOfHive(params.assemblyId);

            case 'isAuthenticated':
                return isAuthenticated();

            case 'isLeaderOfHive':
                return isLeaderOfHive(params.assemblyId);

            case 'hasLeaderProfile':
                return hasLeaderProfile();

            case 'hasFarm':
                return hasFarm();

            case 'hasHiveCompany':
                return hasHiveCompany(params.assemblyId);

            case 'hiveHasTimeslot':
                return hiveHasTimeslot(params.assemblyId);

            case 'isNotAuthenticated':
                return isNotAuthenticated();

            default:
                return false;
        }
    };

    return (
        <Route
            path={path}
            render={({ location, match }) => {
                if (conditions) {
                    if (!conditions.every(checkCondition(match.params))) {
                        return <RedirectToLogin redirectUrl={location.pathname} />;
                    }
                }

                return <Route path={path} {...rest} />;
            }}
        />
    );
};

AuthRoute.propTypes = {
    path: PropTypes.string.isRequired,
    conditions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.bool]),
};

export default AuthRoute;
