import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import * as R from 'ramda';
import useI18n from 'hooks/I18n/useI18n';

import { reactFormValidators } from 'modules/validation';

import Text from 'components/ProductIdentity/Text.jsx';
import Input, { ERROR_MODE } from 'src/components/atoms/Input/Input.jsx';
import Label from 'src/components/atoms/Label/Label.jsx';
import Link from 'src/components/atoms/Link/Link.jsx';
import Password from 'src/components/molecules/Password/Password.jsx';
import Alert, { ERROR_TYPE } from 'src/components/molecules/Alert/Alert.jsx';

const FormLoginCheckout = ({ isLoginSuspended, showForgotPasswordNotice }) => {
    const { trans } = useI18n();
    const methods = useFormContext();
    const {
        register,
        formState: { errors },
    } = methods;

    const { assertValidEmail, assertNotBlank } = useMemo(() => reactFormValidators(trans), [trans]);

    const mailProps = {
        mode: errors.email && ERROR_MODE,
        error: R.path(['email', 'message'], errors),
        ...register('email', {
            validate: assertValidEmail('email'),
            setValueAs: v => v.trim(),
        }),
    };
    const passwordProps = {
        mode: errors.plainPassword && ERROR_MODE,
        error: R.path(['plainPassword', 'message'], errors),
        ...register('plainPassword', {
            validate: assertNotBlank('plainPassword'),
            setValueAs: v => v.trim(),
        }),
    };

    return (
        <form className="nd-checkout-login-account-form">
            {isLoginSuspended && (
                <Alert
                    visible={isLoginSuspended || showForgotPasswordNotice}
                    type={ERROR_TYPE}
                    message={
                        isLoginSuspended
                            ? trans('login.messageWhenSuspended')
                            : trans('login.forgotPasswordHintMessage', {
                                  '%url%': '/forgot_password',
                              })
                    }
                ></Alert>
            )}
            <Text
                className="nd-checkout-create-account-form-heading"
                size="16px"
                lineHeight="24px"
                color="gray2"
                weight="600"
            >
                {trans('checkout.account.personal_information.title')}
            </Text>
            <div className="nd-checkout-login-account-form-section">
                <Label htmlFor="email">{trans('login.email')}*</Label>
                <Input {...mailProps} id="email" />
            </div>
            <div className="nd-checkout-login-account-form-section">
                <Label htmlFor="plainPassword">{trans('login.password')}*</Label>
                <Password {...passwordProps} id="plainPassword" />
            </div>
            <Link to="/forgot_password">{trans('login.forgotPassword')}</Link>
        </form>
    );
};

FormLoginCheckout.propTypes = {
    basketUuid: PropTypes.string.isRequired,
    isLoginSuspended: PropTypes.bool,
    showForgotPasswordNotice: PropTypes.bool,
};
FormLoginCheckout.defaultProps = {
    isLoginSuspended: false,
    showForgotPasswordNotice: false,
};
export default FormLoginCheckout;
