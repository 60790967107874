import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';

import useI18n from 'hooks/I18n/useI18n';

import { getLocalizedDay } from 'modules/utils/dateAndTime';
import Utils from 'modules/utils';

import { getNearbyAssemblies } from 'models/assemblies';

import Text from 'components/ProductIdentity/Text.jsx';
import Caret from 'app/assets/new-design/images/icons/caret.svg';

const { getLocalizedDistance } = Utils;

const NearbyAssemblies = ({ assembly, assemblies, onClick }) => {
    const { trans, i18n } = useI18n();
    const nearbyAssemblies = useMemo(() => getNearbyAssemblies(assembly, assemblies, 4), [
        assembly,
        assemblies,
    ]);
    const getDistributionDay = useCallback(
        nearbyAssembly => {
            return (
                getLocalizedDay(i18n, nearbyAssembly.deliveryTimeSlot.day) +
                (nearbyAssembly.extraDeliveryTimeSlot
                    ? ` ${trans('global.and')} ${getLocalizedDay(
                          i18n,
                          nearbyAssembly.extraDeliveryTimeSlot.day
                      )}`
                    : '')
            );
        },
        [i18n, trans]
    );
    const getDistance = useCallback(
        nearbyAssembly =>
            getLocalizedDistance(
                nearbyAssembly.place.address.coordinates,
                assembly.place.address.coordinates,
                assembly.place.address.country.code
            ),
        [assembly]
    );

    return (
        <div className="map-nearby-assemblies">
            {nearbyAssemblies.map(nearbyAssembly => (
                <div
                    className="map-nearby-assemblies-item"
                    key={nearbyAssembly.id}
                    onClick={() => onClick(nearbyAssembly)}
                >
                    <div className="map-nearby-assemblies-item-content">
                        <div className="map-nearby-assemblies-item-content-header">
                            <Text size="16px" lineHeight="20px" weight={600} color="gray2">
                                {nearbyAssembly.place.address.city.name}
                            </Text>
                            {nearbyAssembly?.openedDistributions?.length && (
                                <Text
                                    size="14px"
                                    lineHeight="20px"
                                    color="green6"
                                    className="map-nearby-assemblies-item-content-header-status"
                                >
                                    {trans('hive.openSale')}
                                </Text>
                            )}
                        </div>
                        <Text size="16px" lineHeight="20px" color="gray2" weight={500}>
                            {nearbyAssembly.name}
                        </Text>
                        <Text size="14px" lineHeight="20px" color="gray5" weight={400}>
                            {`${trans('global.distribution')} : ${getDistributionDay(
                                nearbyAssembly
                            )}`}
                        </Text>
                        <Text size="14px" lineHeight="20px" color="gray5" weight={400}>
                            {`${trans('global.distance')} : ${getDistance(nearbyAssembly)}`}
                        </Text>
                    </div>
                    <InlineSVG raw className="map-nearby-assemblies-item-caret" src={Caret} />
                </div>
            ))}
        </div>
    );
};

NearbyAssemblies.propTypes = {
    assembly: PropTypes.object,
    assemblies: PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func,
};
NearbyAssemblies.defaultProps = { onClick: () => null, assemblies: [], assembly: {} };

export default NearbyAssemblies;
