import React, { useEffect, useRef, useMemo, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'underscore';

import NavigationCategoryButton from '../NavigationCategoryButton.jsx';

const INITIAL_STATE = {
    offset: 0,
    itemWidth: 0,
    hasNextSlide: false,
    hasPreviousSlide: false,
};

const SaleNavigationCategoriesCarousel = props => {
    const { categories, currentCategoryId } = props;
    const [state, dispatch] = useReducer(
        (prevState, action) => ({ ...prevState, ...action }),
        INITIAL_STATE
    );
    const { hasPreviousSlide, offset, hasNextSlide } = state;

    const container = useRef();
    const firstItem = useRef();

    const getItemsPerSlide = useCallback(() => {
        return Math.floor(container.current?.clientWidth / firstItem.current?.clientWidth);
    }, []);

    const handleResize = useCallback(() => {
        if (!container.current) {
            return;
        }
        const localItemsPerSlide = getItemsPerSlide();
        const currentleftItem = offset / firstItem.current?.clientWidth;
        dispatch({
            offset: currentleftItem * firstItem.current?.clientWidth,
            hasNextSlide: currentleftItem + localItemsPerSlide <= categories.length,
            hasPreviousSlide: offset > 0,
        });
    }, [categories, offset, getItemsPerSlide]);

    const _handleResizeThrottled = useMemo(() => _.throttle(handleResize, 200), [handleResize]);

    const slideNext = useCallback(() => {
        const itemsPerSlide = getItemsPerSlide();
        const lastSlideFirstItem = categories.length - itemsPerSlide + 1;
        const currentleftItem = offset / firstItem.current?.clientWidth;
        const newLeftItem = Math.min(lastSlideFirstItem, currentleftItem + itemsPerSlide);

        container.current.scroll({
            left: newLeftItem * firstItem.current?.clientWidth,
            behavior: 'smooth',
        });

        dispatch({
            offset: newLeftItem * firstItem.current?.clientWidth,
            hasPreviousSlide: true,
            hasNextSlide: newLeftItem < lastSlideFirstItem,
        });
    }, [categories, offset, getItemsPerSlide]);

    const slidePrevious = useCallback(() => {
        const itemsPerSlide = getItemsPerSlide();
        const currentleftItem = offset / firstItem.current?.clientWidth;
        const newLeftItem = Math.max(currentleftItem - itemsPerSlide, 0);

        container.current.scroll({
            left: newLeftItem * firstItem.current?.clientWidth,
            behavior: 'smooth',
        });

        dispatch({
            offset: newLeftItem * firstItem.current?.clientWidth,
            hasPreviousSlide: newLeftItem > 0,
            hasNextSlide: true,
        });
    }, [offset, getItemsPerSlide]);

    const onScroll = useCallback(() => {
        const maxLeftScroll =
            categories.length * firstItem.current?.clientWidth - container.current.clientWidth;

        dispatch({
            offset: container.current.scrollLeft,
            hasPreviousSlide: container.current.scrollLeft > 0,
            hasNextSlide: container.current.scrollLeft < maxLeftScroll,
        });
    }, [categories]);

    useEffect(() => {
        window.addEventListener('resize', _handleResizeThrottled);
        return window.removeEventListener('resize', _handleResizeThrottled);
    }, [_handleResizeThrottled]);

    useEffect(() => {
        const onresize = () => {
            dispatch({
                hasNextSlide:
                    categories.length * firstItem.current?.clientWidth -
                        container.current.clientWidth >
                    0,
            });
        };

        onresize();
        window.addEventListener('resize', onresize);
        return () => window.removeEventListener('resize', onresize);
    }, [categories]);

    return (
        <div className="saleTopNavigation-container">
            <div
                className={classnames('saleTopNavigation-categoriesArrowLeft', {
                    'saleTopNavigation-categoriesArrowLeft--disabled': !hasPreviousSlide,
                })}
                onClick={slidePrevious}
            >
                <img
                    alt="Button to display the previous products categories in categories carousel"
                    height="32"
                    width="32"
                    src="/assets/new-design/images/icons/Icon-right.svg"
                />
            </div>

            <div className="saleTopNavigation-categories" ref={container} onScroll={onScroll}>
                <div className="saleTopNavigation-categoriesInner">
                    {categories.map((category, index) => (
                        <NavigationCategoryButton
                            currentCategoryId={currentCategoryId}
                            category={category}
                            key={category.id}
                            ref={index === 0 ? firstItem : null}
                        />
                    ))}
                </div>
            </div>
            {hasNextSlide && (
                <div
                    className={classnames('saleTopNavigation-categoriesArrowRight')}
                    onClick={slideNext}
                >
                    <img
                        alt="Button to display the next products categories in categories carousel"
                        height="32"
                        width="32"
                        src="/assets/new-design/images/icons/Icon-right.svg"
                    />
                </div>
            )}
        </div>
    );
};

SaleNavigationCategoriesCarousel.propTypes = {
    categories: PropTypes.array.isRequired,
    currentCategoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // 1, 2, highlighted,...
};
export default SaleNavigationCategoriesCarousel;
