import Marionette from 'backbone.marionette';
import AppChannel from 'modules/channels/App';
import $ from 'jquery';

const AppLayout = Marionette.LayoutView.extend({
    template: false,
    el: 'body',

    triggerBeforeAttach: false,
    triggerAttach: false,

    regions: {
        header: 'header',
        banner: '#banner-region',
        fullscreen: '#fullscreen-modal',
        main: '.pages',
    },

    initialize() {
        const $body = $('html, body');
        this.listenTo(this.getRegion('main'), 'show', function() {
            $body.animate(
                {
                    scrollTop: 0,
                },
                'fast'
            );
        });
        this.listenTo(AppChannel.vent, 'change:background', this.changeBackground);
    },

    changeBackground(themeId, suffix) {
        let newClass;

        if (themeId) {
            newClass = `background-${themeId}`;
            if (suffix) {
                newClass += `-${suffix}`;
            }
        }

        this.$el.removeClass(function(index, className) {
            const match = className.match(/background-([\w\d-])+/g);
            return match && match.length && className.match(/background-([\w\d-])+/g)[0];
        });

        if (newClass) {
            this.$el.addClass('background').addClass(newClass);
        }
    },
});

const appLayoutSingleton = new AppLayout();

export default appLayoutSingleton;
