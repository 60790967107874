import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { requiredIfOtherAbsent } from 'modules/propTypes';

const propTypes = {
    children: requiredIfOtherAbsent('dangerouslySetInnerHTML'),
    dangerouslySetInnerHTML: requiredIfOtherAbsent('children'),
    className: PropTypes.string,
    variant: PropTypes.oneOf(['primary', 'secondary', 'turquoise2']),
    noDecoration: PropTypes.bool,
    noHover: PropTypes.bool,
    href: PropTypes.string,
    navigate: PropTypes.func,
};

export class Link extends PureComponent {
    render() {
        const {
            children,
            className,
            variant,
            noDecoration,
            noHover,
            /**
             * https://github.com/remix-run/react-router/issues/6962
             */
            // eslint-disable-next-line no-unused-vars
            navigate,
            ...props
        } = this.props;

        const linkClasses = {
            [`fa-link--${variant}`]: variant,
            ['fa-link--noDecoration']: noDecoration,
            ['fa-link--noHover']: noHover,
        };

        return (
            <a
                className={classnames('fa-link', linkClasses, className)}
                {...props}
                role={!this.props.href ? 'button' : undefined}
            >
                {children}
            </a>
        );
    }
}

Link.propTypes = propTypes;

export default Link;
