import { useEffect, useMemo, useRef } from 'react';
import { debounce } from 'underscore';

const useDebounce = callback => {
    const ref = useRef();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    return useMemo(() => {
        const func = () => {
            // eslint-disable-next-line no-unused-expressions
            ref.current?.();
        };

        return debounce(func, 300);
    }, []);
};

export default useDebounce;
