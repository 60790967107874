import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import classnames from 'classnames';
import connectToI18n from 'modules/i18n/connectToI18n';
import {
    convertRawTextToHtml,
    asWysiwygHtml,
    convertHtmlToRawText,
    addBypassRouterToLink,
} from 'modules/utils/text';
import Button from './Button.jsx';

export class LimitOverflowingContentWithEllipsis extends React.Component {
    static propTypes = {
        children: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
        className: PropTypes.string,
        expandKey: PropTypes.string,
        maxChars: PropTypes.number,
        background: PropTypes.oneOf(['white', 'bgLight70']),
        sansSerif: PropTypes.bool,
    };

    static defaultProps = {
        collapsible: false,
        expandKey: 'global.readMore',
        collapseKey: 'global.collapse',
        maxChars: 250,
        background: 'bgLight70',
    };

    state = {
        isExpanded: false,
        isOverflowing: false,
    };

    componentDidMount() {
        this.updateOverflowingState();
    }

    componentDidUpdate() {
        this.updateOverflowingState();
    }

    getTextWithoutHtml = () =>
        asWysiwygHtml(this.props.children)
            ? convertHtmlToRawText(this.props.children)
            : this.props.children;

    isOverflowing = () =>
        asWysiwygHtml(this.props.children) || this.props.children.length > this.props.maxChars;

    updateOverflowingState = () => {
        const isOverflowing = this.isOverflowing();
        if (this.state.isOverflowing !== isOverflowing) {
            this.setState({ isOverflowing });
        }
    };

    expand = e => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            isExpanded: true,
            isOverflowing: false,
        });
    };

    render() {
        const baseClassName = 'newOverflowing';
        const { sansSerif, children } = this.props;

        const classNames = classnames(
            baseClassName,
            {
                [`${baseClassName}--expanded`]: this.state.isExpanded,
                [`${baseClassName}--${this.props.background}`]: this.props.background,
            },
            this.props.className
        );

        const truncatedText = this.getTextWithoutHtml()
            .substring(0, this.props.maxChars)
            .trim();

        return (
            <div className={classNames}>
                <div className={`${baseClassName}-content`}>
                    {this.state.isOverflowing ? (
                        <Fragment>
                            {this.state.isExpanded ? (
                                <div
                                    className={classnames(
                                        { 'fa-text--serif': !sansSerif },
                                        { 'fa-text': sansSerif }
                                    )}
                                    dangerouslySetInnerHTML={{
                                        __html: asWysiwygHtml(children)
                                            ? addBypassRouterToLink(children)
                                            : convertRawTextToHtml(children),
                                    }}
                                />
                            ) : (
                                <div
                                    className={classnames(
                                        'u-mb-0',
                                        { 'fa-text--serif': !sansSerif },
                                        { 'fa-text': sansSerif }
                                    )}
                                >
                                    {`${truncatedText}... `}
                                    <span className={`${baseClassName}-trigger`}>
                                        <Button
                                            className={classnames(
                                                `${baseClassName}-button`,
                                                `${baseClassName}-button--expand`,
                                                'button--noPadding'
                                            )}
                                            type="button"
                                            weight="link"
                                            onClick={this.expand}
                                        >
                                            {this.props.trans(this.props.expandKey)}
                                        </Button>
                                    </span>
                                </div>
                            )}
                        </Fragment>
                    ) : (
                        <div
                            className={classnames(
                                { 'fa-text--serif': !sansSerif },
                                { 'fa-text': sansSerif }
                            )}
                            dangerouslySetInnerHTML={{
                                __html: asWysiwygHtml(children)
                                    ? addBypassRouterToLink(children)
                                    : convertRawTextToHtml(children),
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default connectToI18n(LimitOverflowingContentWithEllipsis);
