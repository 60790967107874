import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as R from 'ramda';
import InlineSVG from 'svg-inline-react';

import useI18n from 'hooks/I18n/useI18n';
import useAnalytics from 'hooks/Analytics/useAnalytics';
import { BASKET_PANEL_ORIGIN } from 'modules/analytics';

import { get } from 'modules/api';
import { userSelector } from 'modules/currentUser';
import { getOrders, fetchOrders } from 'modules/orders';
import { BASKET_RESUME } from 'modules/originUtils.js';
import { editBasketOfferQuantity, validateOrder } from 'modules/orders';
import { getLocalizedDate } from 'modules/utils/dateAndTime';
import { formatPrice } from 'modules/utils/currency';
import { sanitizeStock } from 'modules/stockUtils';
import { INITIAL_LOADING, READY, FAILED } from 'modules/utils/ajaxStatuses';

import Button, { SMALL_SIZE, SECONDARY_MODE } from 'src/components/atoms/Button/Button.jsx';
import ResponsiveSidePanel from 'components/Navigation/ProductIdentity/ResponsiveSidePanel.jsx';
import Offer, {
    ORIENTATION_HORIZONTAL,
    BASKET_TEMPLATE,
    PRICE_FORMAT_TOTAL,
} from 'components/Product/ProductIdentity/Offer.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import EmptyBasketSvg from 'app/assets/new-design/images/illustrations/empty-basket.svg';

const outOfStock = x => sanitizeStock(x.availableStock) <= 0;

const BasketPanel = props => {
    const { basket, toggleBasketPanel, currentDistributionId } = props;
    const [deliveryOptions, setDeliveryOptions] = useState([]);
    const [loadingDeliveryOptions, setLoadingDeliveryOptions] = useState(INITIAL_LOADING);
    const saleHistory = useSelector(state => state.routerHistory.sale);

    const { analytics } = useAnalytics();
    const dispatch = useDispatch();
    const { trans, transChoice, i18n } = useI18n();
    const history = useHistory();
    const user = useSelector(userSelector);
    const ordersCollection = useSelector(getOrders);
    const countAvailableOrdersBasket = useMemo(() => {
        return R.filter(({ countItems }) => countItems > 0, Object.values(ordersCollection) || []);
    }, [ordersCollection]);
    const areDeliveryOptionsAvailable = useMemo(() => {
        const pickups = R.filter(R.propEq('type', 'pickup'), deliveryOptions);
        const deliveryOffers = R.filter(R.propEq('type', 'deliveryOffer'), deliveryOptions);
        const availableDeliveryOffers = R.reject(outOfStock, deliveryOffers);
        return pickups.length > 0 || availableDeliveryOffers.length > 0;
    }, [deliveryOptions]);
    const addOfferToBasket = useCallback(
        (offerId, quantity = 1, actionOrigin) => {
            dispatch(
                editBasketOfferQuantity(currentDistributionId, offerId, quantity, actionOrigin)
            );
        },
        [dispatch, currentDistributionId]
    );

    const handleValidateOrder = useCallback(
        () =>
            basket.id
                ? dispatch(
                      validateOrder(
                          basket.id,
                          areDeliveryOptionsAvailable,
                          undefined,
                          BASKET_PANEL_ORIGIN
                      )
                  )
                : null,
        [dispatch, areDeliveryOptionsAvailable, basket]
    );
    const goToBasket = useCallback(
        e => {
            const checkoutUrl = user.anonymous ? `/checkout/${basket?.uuid}/basket` : '/basket';
            history.push(checkoutUrl);
            toggleBasketPanel(e);
        },
        [basket, history, toggleBasketPanel, user]
    );
    const renderEmptyBasket = useMemo(() => {
        return (
            <div className="sale-panel-basket-content-empty">
                <InlineSVG className="basket-empty-illustration" src={EmptyBasketSvg}></InlineSVG>
                <Text size="18px" lineHeight="26px" family="inter" color="gray2" bold>
                    {trans('panel.basket.empty')}
                </Text>
                <Text size="16px" lineHeight="24px" family="inter" color="gray2">
                    {trans('panel.basket.empty.message')}
                </Text>
            </div>
        );
    }, [trans]);

    const onItemClick = useCallback(item => saleHistory.push(`/${item.product.id}`), [saleHistory]);

    useEffect(() => {
        basket?.distribution?.uuid &&
            get(`distributions/${basket.distribution.uuid}/pickup-and-delivery-options`)
                .then(_deliveryOptions => {
                    setDeliveryOptions(_deliveryOptions);
                    setLoadingDeliveryOptions(READY);
                })
                .catch(() => {
                    setLoadingDeliveryOptions(FAILED);
                });
    }, [basket]);

    useEffect(() => {
        dispatch(fetchOrders());
    }, [dispatch]);

    return (
        <div className="sale-basket-panel">
            <ResponsiveSidePanel
                isOpened
                title={trans('basket.minibasket.mybasket')}
                onClose={toggleBasketPanel}
                footer={
                    <div className="sale-basket-panel-footer">
                        {basket && basket.countItems ? (
                            <Button
                                size={SMALL_SIZE}
                                onClick={
                                    user.anonymous
                                        ? e => {
                                              toggleBasketPanel(e);
                                              analytics.trackGoToDeliveryPage(
                                                  basket.uuid,
                                                  user,
                                                  BASKET_PANEL_ORIGIN
                                              );
                                              history.push(`/checkout/${basket.uuid}/pickup`);
                                          }
                                        : handleValidateOrder
                                }
                                className="basketPanel-checkoutBtn"
                                disabled={loadingDeliveryOptions !== READY}
                            >
                                {transChoice('panel.basket.cta.order.products', basket.countItems)}
                            </Button>
                        ) : null}
                        {countAvailableOrdersBasket.length > 0 && (
                            <Button onClick={goToBasket} size={SMALL_SIZE} mode={SECONDARY_MODE}>
                                {`${trans(
                                    countAvailableOrdersBasket.length === 1
                                        ? 'basket.checkAndValidate'
                                        : 'panel.basket.cta.show.all.baskets'
                                )}${
                                    countAvailableOrdersBasket.length > 1
                                        ? ` (${countAvailableOrdersBasket.length})`
                                        : ''
                                }`}
                            </Button>
                        )}
                    </div>
                }
            >
                {!basket || basket.countItems === 0 ? (
                    renderEmptyBasket
                ) : (
                    <>
                        <Text size="16px" lineHeight="24px" bold color="gray2">
                            {trans('basket.orderDate', {
                                '%date%': getLocalizedDate(
                                    i18n,
                                    'i',
                                    basket.distribution.distributionDate
                                ),
                            })}
                        </Text>
                        <div className="basket-order-total">
                            <Text size="16px" lineHeight="24px" family="inter" color="gray2">{`${
                                basket.countItems
                            } ${transChoice('basket.count.items', basket.countItems)}`}</Text>
                            <Text
                                size="16px"
                                lineHeight="24px"
                                family="inter"
                                color="gray2"
                            >{`${trans('order.total')} :`}</Text>
                            <Text size="16px" lineHeight="24px" family="inter" bold color="gray2">
                                {formatPrice(
                                    i18n,
                                    basket.purchasedPrice.amount / 100,
                                    basket.purchasedPrice.currency
                                )}
                            </Text>
                        </div>
                        <div className="sale-basket-panel-list-farms">
                            {Object.entries(R.groupBy(item => item.farmName)(basket.items)).map(
                                ([farmName, items]) => (
                                    <div key={farmName}>
                                        <Text
                                            className="basket-farm-name"
                                            family="inter"
                                            color="gray2"
                                            size="16px"
                                            lineHeight="24px"
                                            bold
                                        >
                                            {farmName}
                                        </Text>
                                        <div className="sale-basket-panel-list-items">
                                            {items.map(item => (
                                                <Offer
                                                    key={item.id}
                                                    onClick={() => onItemClick(item)}
                                                    template={BASKET_TEMPLATE}
                                                    orientation={ORIENTATION_HORIZONTAL}
                                                    actionOrigin={BASKET_RESUME}
                                                    addOfferToBasket={addOfferToBasket}
                                                    distributionId={currentDistributionId}
                                                    offer={item.offer}
                                                    priceFormat={PRICE_FORMAT_TOTAL}
                                                    productName={item.product.name}
                                                    product={{
                                                        ...item.product,
                                                        farm: { name: farmName },
                                                        offers: [item.offer],
                                                        type: {
                                                            quantityStrategy: item.quantityStrategy,
                                                        },
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </>
                )}
            </ResponsiveSidePanel>
        </div>
    );
};

BasketPanel.propTypes = {
    basket: PropTypes.object,
    toggleBasketPanel: PropTypes.func,
    currentDistributionId: PropTypes.number,
};
export default BasketPanel;
