import PropTypes from 'prop-types';

const INFO = 'info';
const MAROON = 'maroon';
const MAROON_LIGHT = 'maroon7';
const DANGER = 'danger';
const SUCCESS = 'success';
const WARNING = 'warning';
const ALPHA = 'alpha';
const WHITE = 'white';
const TURQUOISE2 = 'turquoise2';

const propType = PropTypes.oneOf([
    INFO,
    MAROON,
    MAROON_LIGHT,
    DANGER,
    SUCCESS,
    WARNING,
    ALPHA,
    WHITE,
    TURQUOISE2,
]);

export { INFO, MAROON, MAROON_LIGHT, DANGER, SUCCESS, WARNING, ALPHA, WHITE, TURQUOISE2, propType };
