import React, { cloneElement, useEffect, useState, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Portal } from 'react-portal';
import Tether from 'tether';
import onClickOutside from 'react-onclickoutside';

const triggerType = PropTypes.oneOf(['click', 'hover']);

const PortalWithClickOutside = onClickOutside(Portal);

const attachmentMap = {
    top: 'top center',
    bottom: 'bottom center',
    left: 'middle left',
    right: 'middle right',
};

const targetAttachmentMap = {
    top: 'top center',
    bottom: 'bottom center',
    left: 'middle right',
    right: 'middle left',
};

export const Popover = props => {
    const {
        className,
        children,
        trigger,
        overlay,
        pointer,
        offset,
        customOffset,
        onOverlayClose,
    } = props;
    const [isOpened, setIsOpened] = useState(false);
    const target = React.Children.only(children);
    const closeTimeout = useRef();
    const _tether = useRef();
    const portalRef = useRef();
    const targetRef = useRef();

    const attachTether = useCallback(() => {
        // onClickOutside component access
        const overlayElement = portalRef.current.getInstance().defaultNode.firstChild;
        const targetElement = ReactDOM.findDOMNode(targetRef.current);

        const targetOffsetMap = {
            top: `${offset}px 0`,
            bottom: `-${offset}px 0`,
            left: `0 ${offset}px`,
            right: `0 -${offset}px`,
        };

        _tether.current = new Tether({
            attachment: attachmentMap[pointer],
            targetAttachment: targetAttachmentMap[pointer],
            targetOffset: customOffset ? customOffset : targetOffsetMap[pointer],
            element: overlayElement,
            target: targetElement,
            addTargetClasses: false,
            constraints: [
                {
                    to: 'window',
                    attachment: 'together',
                    pin: true,
                },
            ],
        });
    }, [offset, pointer, customOffset]);

    const cleanupTether = useCallback(() => {
        if (_tether.current) {
            _tether.current.destroy();
            _tether.current = null;
        }
    }, []);

    const immediateClose = useCallback(() => {
        if (closeTimeout.current) {
            clearTimeout(closeTimeout.current);
            closeTimeout.current = null;
        }
        setIsOpened(false);
        cleanupTether();
        onOverlayClose && onOverlayClose();
    }, [onOverlayClose, cleanupTether]);

    const open = useCallback(() => {
        if (closeTimeout.current) {
            clearTimeout(closeTimeout.current);
            closeTimeout.current = null;
        }
        setIsOpened(true);
        setTimeout(() => attachTether());
    }, [attachTether]);

    const close = useCallback(
        ({ delay = 150 } = {}) => {
            if (delay > 0) {
                closeTimeout.current = setTimeout(immediateClose, delay);
            } else {
                immediateClose();
            }
        },
        [immediateClose]
    );
    const toggle = useCallback(() => {
        isOpened ? close() : open();
    }, [isOpened, close, open]);

    const targetProps = useMemo(
        () => ({
            ref: targetRef,
            onClick: trigger.indexOf('click') !== -1 && toggle,
            onMouseOver: trigger.indexOf('hover') !== -1 && open,
            onMouseOut: trigger.indexOf('hover') !== -1 && close,
        }),
        [trigger, toggle, open, close]
    );

    const overlayEl = useMemo(
        () =>
            cloneElement(overlay, {
                pointer,
                show: open,
                hide: close,
            }),
        [open, close, pointer, overlay]
    );

    useEffect(() => immediateClose(), [immediateClose]);
    return (
        <span className={className}>
            {cloneElement(target, targetProps)}
            {isOpened && (
                <PortalWithClickOutside handleClickOutside={close} ref={portalRef}>
                    {overlayEl}
                </PortalWithClickOutside>
            )}
        </span>
    );
};

Popover.propTypes = {
    className: PropTypes.string,
    children: PropTypes.element.isRequired,
    overlay: PropTypes.element.isRequired,
    pointer: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
    trigger: PropTypes.oneOfType([triggerType, PropTypes.arrayOf(triggerType)]),
    onOverlayClose: PropTypes.func,
    offset: PropTypes.number,
    customOffset: PropTypes.string,
};

Popover.defaultProps = {
    pointer: 'bottom',
    trigger: ['click', 'hover'],
    offset: 30,
};
export default Popover;
