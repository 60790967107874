import PropTypes from 'prop-types';
import React, { cloneElement } from 'react';
import ReactDOM from 'react-dom';
import { Portal } from 'react-portal';
import Tether from 'tether';
import onClickOutside from 'react-onclickoutside';

const triggerType = PropTypes.oneOf(['click', 'hover']);

const PortalWithClickOutside = onClickOutside(Portal);

export class OverlayTrigger extends React.Component {
    static propTypes = {
        children: PropTypes.element.isRequired,
        overlay: PropTypes.element.isRequired,
        pointer: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
        trigger: PropTypes.oneOfType([triggerType, PropTypes.arrayOf(triggerType)]),
        onOverlayClose: PropTypes.func,
        offset: PropTypes.number,
    };

    static defaultProps = {
        pointer: 'bottom',
        trigger: ['click', 'hover'],
        offset: 30,
    };

    state = {
        isOpened: false,
    };

    componentWillUnmount() {
        this._immediateClose();
    }

    _attachTether = () => {
        const { pointer, offset } = this.props;
        // onClickOutside component access
        const overlayElement = this.portal.getInstance().defaultNode.firstChild;
        const targetElement = ReactDOM.findDOMNode(this.refs.target);

        const attachmentMap = {
            top: 'top center',
            bottom: 'bottom center',
            left: 'middle left',
            right: 'middle right',
        };

        const targetAttachmentMap = {
            top: 'top center',
            bottom: 'bottom center',
            left: 'middle right',
            right: 'middle left',
        };

        const targetOffsetMap = {
            top: `${offset}px 0`,
            bottom: `-${offset}px 0`,
            left: `0 ${offset}px`,
            right: `0 -${offset}px`,
        };

        this._tether = new Tether({
            attachment: attachmentMap[pointer],
            targetAttachment: targetAttachmentMap[pointer],
            targetOffset: targetOffsetMap[pointer],
            element: overlayElement,
            target: targetElement,
            addTargetClasses: false,
            constraints: [
                {
                    to: 'window',
                    attachment: 'together',
                    pin: true,
                },
            ],
        });
    };

    cleanupTether = () => {
        if (this._tether) {
            this._tether.destroy();
            this._tether = null;
        }
    };

    open = () => {
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
            this.closeTimeout = null;
        }
        this.setState({ isOpened: true });
        setTimeout(() => this._attachTether());
    };

    close = ({ delay = 150 } = {}) => {
        if (delay > 0) {
            this.closeTimeout = setTimeout(this._immediateClose, delay);
        } else {
            this._immediateClose();
        }
    };

    _immediateClose = () => {
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
            this.closeTimeout = null;
        }

        this.setState({ isOpened: false });

        this.cleanupTether();

        if (this.props.onOverlayClose) {
            this.props.onOverlayClose();
        }
    };

    toggle = () => {
        if (this.state.isOpened) {
            this.close();
        } else {
            this.open();
        }
    };

    render() {
        const target = React.Children.only(this.props.children);
        const targetProps = {
            ref: 'target',
        };

        if (this.props.trigger.indexOf('click') !== -1) {
            targetProps.onClick = this.toggle;
        }

        if (this.props.trigger.indexOf('hover') !== -1) {
            targetProps.onMouseOver = this.open;
            targetProps.onMouseOut = this.close;
        }

        const overlay = cloneElement(this.props.overlay, {
            pointer: this.props.pointer,
            show: this.open,
            hide: this.close,
        });

        return (
            <span>
                {cloneElement(target, targetProps)}
                {this.state.isOpened && (
                    <PortalWithClickOutside
                        handleClickOutside={this.close}
                        ref={c => {
                            this.portal = c;
                        }}
                    >
                        {overlay}
                    </PortalWithClickOutside>
                )}
            </span>
        );
    }
}

export default OverlayTrigger;
