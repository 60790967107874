import React, { lazy, Suspense } from 'react';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
const TenYears = lazy(() => import('./TenYears.jsx'));

const TenYearsContainer = () => {
    return (
        <Suspense fallback={<LoadingAnimation type="spinner" />}>
            <TenYears />
        </Suspense>
    );
};

export default TenYearsContainer;
