module.exports = {
    geo: {
        geocoding: {
            biasingBounds: {
                allCountries: {
                    north: 60,
                    east: 20,
                    south: 35,
                    west: -12,
                },
            },
        },
        mapbox: {
            legacyStyle: 'https://{s}.tiles.mapbox.com/v3/lrqdo.2f8d4ef0/{z}/{x}/{y}.png',
            modernStyle:
                'https://api.mapbox.com/styles/v1/lrqdo/cio1hq3mq005vd8nvnsi6vhrn/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibHJxZG8iLCJhIjoibzBPTzE0ZyJ9.-udp6yXoZGNOC7u3wF4GkQ',
        },
        clusterCenters: {
            default: [46.65509, 2.65869],
            FR: [46.65509, 2.65869],
            BE: [50.56361, 4.96033],
            ES: [40.33294, -3.39478],
            DE: [51.46428, 10.50293],
            IT: [45.37337, 9.99756],
            NL: [52.41682, 5.46966],
            CH: [46.64, 7.57],
        },
        zoomLevelsByLocale: {
            default: 6,
            BE: 7,
            NL: 7,
            CK: 8,
            CH: 8,
        },
        clusterOptions: {
            noRegions: {
                countries: {
                    zmax: 5,
                },
                autoClustered: {
                    zmin: 6,
                },
            },
            hasRegions: {
                countries: {
                    zmax: 5,
                },
                regions: {
                    zmin: 6,
                    zmax: 7,
                },
                autoClustered: {
                    zmin: 8,
                },
            },
            autoClusters: {
                maxClusterRadius: 60,
                disableClusteringAtZoom: 13,
            },
        },
        clusterRules: {
            countries: {
                clusters: [
                    {
                        name: 'France',
                        id: 'FR',
                        countryCode: 'FR',
                    },
                ],
            },
            countries_noRegions: {
                clusters: [
                    {
                        name: 'Belgique',
                        id: 'BE',
                        countryCode: 'BE',
                    },
                    {
                        name: 'España',
                        id: 'ES',
                        countryCode: 'ES',
                    },
                    {
                        name: 'Deutschland',
                        id: 'DE',
                        countryCode: 'DE',
                    },
                    {
                        name: 'Italia',
                        id: 'IT',
                        countryCode: 'IT',
                    },
                    {
                        name: 'Nederland',
                        id: 'NL',
                        countryCode: 'NL',
                    },
                    {
                        name: 'Suisse',
                        id: 'CH',
                        countryCode: 'CH',
                    },
                ],
            },
            regions: {
                clusters: [
                    {
                        name: 'Alsace',
                        id: 1,
                        countryCode: 'FR',
                        deptCode: ['67', '68'],
                    },
                    {
                        name: 'Aquitaine',
                        id: 2,
                        countryCode: 'FR',
                        deptCode: ['24', '33', '40', '47', '64'],
                    },
                    {
                        name: 'Auvergne',
                        id: 3,
                        countryCode: 'FR',
                        deptCode: ['03', '15', '43', '63'],
                    },
                    {
                        name: 'Basse-Normandie',
                        id: 4,
                        countryCode: 'FR',
                        deptCode: ['14', '50', '61'],
                        markerOffset: [20, 0],
                    },
                    {
                        name: 'Bourgogne',
                        id: 5,
                        countryCode: 'FR',
                        deptCode: ['21', '58', '71', '89'],
                    },
                    {
                        name: 'Bretagne',
                        id: 6,
                        countryCode: 'FR',
                        deptCode: ['22', '29', '35', '56'],
                    },
                    {
                        name: 'Centre',
                        id: 7,
                        countryCode: 'FR',
                        deptCode: ['18', '28', '36', '37', '41', '45'],
                        markerOffset: [-5, -20],
                    },
                    {
                        name: 'Champagne-Ardenne',
                        id: 8,
                        countryCode: 'FR',
                        deptCode: ['08', '10', '51', '52'],
                        markerOffset: [0, -20],
                    },
                    {
                        name: 'Corse',
                        id: 9,
                        countryCode: 'FR',
                        deptCode: ['2A', '2B', '20'],
                    },
                    {
                        name: 'Franche-Comté',
                        id: 10,
                        countryCode: 'FR',
                        deptCode: ['25', '39', '70', '90'],
                    },
                    {
                        name: 'Haute-Normandie',
                        id: 11,
                        countryCode: 'FR',
                        deptCode: ['27', '76'],
                    },
                    {
                        name: 'Île de France',
                        id: 12,
                        countryCode: 'FR',
                        deptCode: ['75', '77', '78', '91', '92', '93', '94', '95'],
                    },
                    {
                        name: 'Languedoc-Roussillon',
                        id: 13,
                        countryCode: 'FR',
                        deptCode: ['11', '30', '34', '48', '66'],
                        markerOffset: [-10, -20],
                    },
                    {
                        name: 'Limousin',
                        id: 14,
                        countryCode: 'FR',
                        deptCode: ['19', '23', '87'],
                    },
                    {
                        name: 'Lorraine',
                        id: 15,
                        countryCode: 'FR',
                        deptCode: ['54', '55', '57', '88'],
                        markerOffset: [0, 10],
                    },
                    {
                        name: 'Midi-Pyrénées',
                        id: 16,
                        countryCode: 'FR',
                        deptCode: ['09', '12', '31', '32', '46', '65', '81', '82'],
                        markerOffset: [0, 10],
                    },
                    {
                        name: 'Nord-Pas-de-Calais',
                        id: 17,
                        countryCode: 'FR',
                        deptCode: ['59', '62'],
                        markerOffset: [20, 5],
                    },
                    {
                        name: 'PACA',
                        id: 18,
                        countryCode: 'FR',
                        deptCode: ['04', '05', '06', '13', '83', '84'],
                        markerOffset: [0, -30],
                    },
                    {
                        name: 'Pays de la Loire',
                        id: 19,
                        countryCode: 'FR',
                        deptCode: ['44', '49', '53', '72', '85'],
                    },
                    {
                        name: 'Picardie',
                        id: 20,
                        countryCode: 'FR',
                        deptCode: ['02', '60', '80'],
                    },
                    {
                        name: 'Poitou-Charentes',
                        id: 21,
                        countryCode: 'FR',
                        deptCode: ['16', '17', '79', '86'],
                    },
                    {
                        name: 'Réunion',
                        id: 22,
                        countryCode: 'FR',
                        deptCode: ['974'],
                    },
                    {
                        name: 'Rhône-Alpes',
                        id: 23,
                        countryCode: 'FR',
                        deptCode: ['01', '07', '26', '38', '42', '69', '73', '74'],
                    },
                ],
            },
        },

        regions_polygons: {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    properties: {
                        name: 'Languedoc-Roussillon',
                        id: 13,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [4.23013, 43.46039],
                                [3.90749, 43.51718],
                                [3.06773, 43.02501],
                                [3.04055, 42.47458],
                                [2.4832, 42.33985],
                                [2.01237, 42.35283],
                                [1.78638, 42.57397],
                                [1.95138, 42.73579],
                                [1.90489, 43.40764],
                                [2.58163, 43.42333],
                                [2.61432, 43.59974],
                                [3.20745, 43.81241],
                                [3.45117, 44.02233],
                                [3.12252, 44.2854],
                                [2.98226, 44.64515],
                                [3.3369, 44.95609],
                                [3.86248, 44.74385],
                                [4.09414, 44.33472],
                                [4.64949, 44.27016],
                                [4.84064, 43.98592],
                                [4.23013, 43.46039],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Alsace',
                        id: 1,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [7.13025, 47.50298],
                                [6.84576, 47.82294],
                                [7.19837, 48.31026],
                                [7.2911, 48.79433],
                                [7.63333, 49.05417],
                                [8.19674, 48.95785],
                                [7.84428, 48.64534],
                                [7.57744, 48.12043],
                                [7.48762, 47.48213],
                                [7.13025, 47.50298],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Auvergne',
                        id: 3,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [2.98226, 44.64515],
                                [2.73818, 44.94119],
                                [2.4679, 44.64274],
                                [2.16936, 44.63823],
                                [2.0629, 44.97664],
                                [2.44542, 45.38322],
                                [2.60671, 45.96658],
                                [2.28114, 46.42046],
                                [3.03203, 46.79466],
                                [3.62239, 46.73992],
                                [3.99838, 46.46534],
                                [3.90002, 46.27599],
                                [3.68845, 45.79141],
                                [4.48341, 45.23659],
                                [3.86248, 44.74385],
                                [3.3369, 44.95609],
                                [2.98226, 44.64515],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Basse-Normandie',
                        id: 4,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [-1.57089, 48.62626],
                                [-1.56202, 49.00552],
                                [-1.85914, 49.6487],
                                [-1.27833, 49.69262],
                                [-1.1191, 49.35965],
                                [-0.22918, 49.28607],
                                [0.29616, 49.42987],
                                [0.44658, 49.01486],
                                [0.81607, 48.67088],
                                [0.97634, 48.43878],
                                [0.79818, 48.19484],
                                [0.36434, 48.45199],
                                [-1.07011, 48.50881],
                                [-1.57089, 48.62626],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Bretagne',
                        id: 6,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [-1.57089, 48.62626],
                                [-1.07011, 48.50881],
                                [-1.02108, 47.99513],
                                [-1.9691, 47.6895],
                                [-2.45713, 47.44814],
                                [-2.78256, 47.62107],
                                [-3.09766, 47.56482],
                                [-3.53154, 47.76696],
                                [-4.81201, 47.87214],
                                [-4.30366, 48.19628],
                                [-4.79544, 48.41415],
                                [-4.753, 48.54394],
                                [-3.0878, 48.86627],
                                [-2.62971, 48.52595],
                                [-2.46803, 48.64994],
                                [-1.57089, 48.62626],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Centre',
                        id: 7,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [0.05379, 47.16347],
                                [0.22002, 47.5019],
                                [0.77478, 47.8396],
                                [0.79818, 48.19484],
                                [0.97634, 48.43878],
                                [0.81607, 48.67088],
                                [1.32974, 48.76101],
                                [1.50142, 48.94102],
                                [1.97267, 48.28834],
                                [2.40015, 48.31591],
                                [2.93656, 48.16348],
                                [3.12678, 47.96814],
                                [2.85187, 47.75776],
                                [3.03203, 46.79466],
                                [2.28114, 46.42046],
                                [1.17715, 46.38399],
                                [0.69192, 46.97453],
                                [0.31113, 46.93809],
                                [0.05379, 47.16347],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Champagne-Ardenne',
                        id: 8,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [5.37398, 47.60595],
                                [4.95384, 47.70238],
                                [4.78952, 48.00807],
                                [3.89411, 47.92919],
                                [3.41472, 48.39018],
                                [3.48504, 48.85184],
                                [3.64423, 49.3129],
                                [3.92489, 49.40742],
                                [4.23301, 49.95766],
                                [4.68763, 50.00069],
                                [5.3941, 49.61691],
                                [5.11323, 49.59233],
                                [4.93965, 49.18515],
                                [4.9943, 48.62154],
                                [5.73062, 48.18982],
                                [5.88492, 47.92576],
                                [5.37398, 47.60595],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Corse',
                        id: 9,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [9.31641, 41.29432],
                                [8.80951, 41.55688],
                                [8.80255, 41.89037],
                                [8.57729, 42.1554],
                                [8.66681, 42.51607],
                                [9.36035, 43.08494],
                                [9.5331, 42.54629],
                                [9.54897, 42.10355],
                                [9.31641, 41.29432],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Franche-Comté',
                        id: 10,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [5.31077, 46.44684],
                                [5.44026, 46.63307],
                                [5.25451, 46.97095],
                                [5.48079, 47.21873],
                                [5.37398, 47.60595],
                                [5.88492, 47.92576],
                                [6.14705, 48.01548],
                                [6.84576, 47.82294],
                                [7.13025, 47.50298],
                                [7.04561, 47.32648],
                                [6.06386, 46.4164],
                                [5.4565, 46.27456],
                                [5.31077, 46.44684],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Haute-Normandie',
                        id: 11,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [1.50142, 48.94102],
                                [1.32974, 48.76101],
                                [0.81607, 48.67088],
                                [0.44658, 49.01486],
                                [0.29616, 49.42987],
                                [0.2057, 49.71458],
                                [1.38015, 50.06499],
                                [1.71148, 49.87395],
                                [1.70444, 49.23235],
                                [1.50142, 48.94102],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Île de France',
                        id: 12,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [2.93656, 48.16348],
                                [2.40015, 48.31591],
                                [1.97267, 48.28834],
                                [1.50142, 48.94102],
                                [1.70444, 49.23235],
                                [2.58297, 49.08069],
                                [3.16523, 49.0998],
                                [3.48504, 48.85184],
                                [3.41472, 48.39018],
                                [2.93656, 48.16348],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Aquitaine',
                        id: 2,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [-0.3137, 42.84938],
                                [-1.47101, 43.07897],
                                [-1.73026, 43.29575],
                                [-1.44544, 43.64668],
                                [-1.16075, 44.77406],
                                [-1.15584, 45.46898],
                                [-0.71679, 45.32771],
                                [-0.04003, 45.10253],
                                [0.62934, 45.7148],
                                [1.32494, 45.37989],
                                [1.44814, 45.01938],
                                [1.02157, 44.54503],
                                [0.90628, 44.19045],
                                [0.65428, 44.04238],
                                [-0.19164, 43.92684],
                                [-0.28203, 43.64314],
                                [0.03028, 43.34614],
                                [-0.3137, 42.84938],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Réunion',
                        id: 22,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [55.59357, -21.59615],
                                [55.04425, -21.00247],
                                [55.38483, -20.72272],
                                [55.7666, -20.9204],
                                [56.12366, -21.3559],
                                [55.59357, -21.59615],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Limousin',
                        id: 14,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [1.44814, 45.01938],
                                [1.32494, 45.37989],
                                [0.62934, 45.7148],
                                [0.92658, 46.00914],
                                [0.79998, 46.2015],
                                [1.17715, 46.38399],
                                [2.28114, 46.42046],
                                [2.60671, 45.96658],
                                [2.44542, 45.38322],
                                [2.0629, 44.97664],
                                [1.44814, 45.01938],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Lorraine',
                        id: 15,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [5.3941, 49.61691],
                                [6.54212, 49.43231],
                                [6.73856, 49.16367],
                                [7.43556, 49.18014],
                                [7.63333, 49.05417],
                                [7.2911, 48.79433],
                                [7.19837, 48.31026],
                                [6.84576, 47.82294],
                                [6.14705, 48.01548],
                                [5.88492, 47.92576],
                                [5.73062, 48.18982],
                                [4.9943, 48.62154],
                                [4.93965, 49.18515],
                                [5.11323, 49.59233],
                                [5.3941, 49.61691],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Midi-Pyrénées',
                        id: 16,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [-0.3137, 42.84938],
                                [0.03028, 43.34614],
                                [-0.28203, 43.64314],
                                [-0.19164, 43.92684],
                                [0.65428, 44.04238],
                                [0.90628, 44.19045],
                                [1.02157, 44.54503],
                                [1.44814, 45.01938],
                                [2.0629, 44.97664],
                                [2.16936, 44.63823],
                                [2.4679, 44.64274],
                                [2.73818, 44.94119],
                                [2.98226, 44.64515],
                                [3.12252, 44.2854],
                                [3.45117, 44.02233],
                                [3.20745, 43.81241],
                                [2.61432, 43.59974],
                                [2.58163, 43.42333],
                                [1.90489, 43.40764],
                                [1.95138, 42.73579],
                                [1.78638, 42.57397],
                                [0.70865, 42.86152],
                                [0.66852, 42.68959],
                                [0.00025, 42.68536],
                                [-0.3137, 42.84938],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Nord-Pas-de-Calais',
                        id: 17,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [1.62844, 50.36319],
                                [1.5815, 50.87082],
                                [2.54485, 51.08984],
                                [2.63514, 50.81289],
                                [3.151, 50.78361],
                                [3.28634, 50.52793],
                                [4.18141, 50.23218],
                                [4.14055, 49.979],
                                [3.17245, 50.0119],
                                [2.45185, 50.2302],
                                [1.62844, 50.36319],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Pays de la Loire',
                        id: 19,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [-2.45713, 47.44814],
                                [-1.9691, 47.6895],
                                [-1.02108, 47.99513],
                                [-1.07011, 48.50881],
                                [0.36434, 48.45199],
                                [0.79818, 48.19484],
                                [0.77478, 47.8396],
                                [0.22002, 47.5019],
                                [0.05379, 47.16347],
                                [-0.82765, 46.99218],
                                [-0.60229, 46.53316],
                                [-0.65714, 46.31497],
                                [-1.13167, 46.3049],
                                [-1.81376, 46.49536],
                                [-2.14523, 46.81749],
                                [-1.98127, 47.02716],
                                [-2.43248, 47.25747],
                                [-2.45713, 47.44814],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Picardie',
                        id: 20,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [1.70444, 49.23235],
                                [1.71148, 49.87395],
                                [1.38015, 50.06499],
                                [1.62844, 50.36319],
                                [2.45185, 50.2302],
                                [3.17245, 50.0119],
                                [4.14055, 49.979],
                                [4.23301, 49.95766],
                                [3.92489, 49.40742],
                                [3.64423, 49.3129],
                                [3.48504, 48.85184],
                                [3.16523, 49.0998],
                                [2.58297, 49.08069],
                                [1.70444, 49.23235],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Poitou-Charentes',
                        id: 21,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [-0.71679, 45.32771],
                                [-1.23648, 45.69323],
                                [-1.05322, 46.00369],
                                [-1.13167, 46.3049],
                                [-0.65714, 46.31497],
                                [-0.60229, 46.53316],
                                [-0.82765, 46.99218],
                                [0.05379, 47.16347],
                                [0.31113, 46.93809],
                                [0.69192, 46.97453],
                                [1.17715, 46.38399],
                                [0.79998, 46.2015],
                                [0.92658, 46.00914],
                                [0.62934, 45.7148],
                                [-0.04003, 45.10253],
                                [-0.71679, 45.32771],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'PACA',
                        id: 18,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [4.23013, 43.46039],
                                [4.84064, 43.98592],
                                [4.64949, 44.27016],
                                [5.41839, 44.42476],
                                [6.13273, 44.86365],
                                [6.22923, 45.10878],
                                [6.63, 45.10945],
                                [7.02333, 44.82137],
                                [6.85451, 44.52886],
                                [7.00851, 44.23524],
                                [7.71848, 44.08272],
                                [7.52997, 43.78419],
                                [7.15956, 43.6547],
                                [6.63933, 43.17237],
                                [6.15459, 43.08034],
                                [5.34161, 43.21277],
                                [5.36121, 43.3089],
                                [4.23013, 43.46039],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Rhône-Alpes',
                        id: 23,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [3.86248, 44.74385],
                                [4.48341, 45.23659],
                                [3.68845, 45.79141],
                                [3.90002, 46.27599],
                                [4.28228, 46.15683],
                                [4.73683, 46.23244],
                                [4.93307, 46.51231],
                                [5.31077, 46.44684],
                                [5.4565, 46.27456],
                                [6.06386, 46.4164],
                                [6.865, 46.28108],
                                [7.02343, 45.97893],
                                [6.80863, 45.72507],
                                [7.1824, 45.40775],
                                [7.12509, 45.24396],
                                [6.63, 45.10945],
                                [6.22923, 45.10878],
                                [6.13273, 44.86365],
                                [5.41839, 44.42476],
                                [4.64949, 44.27016],
                                [4.09414, 44.33472],
                                [3.86248, 44.74385],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Bourgogne',
                        id: 5,
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [3.90002, 46.27599],
                                [3.99838, 46.46534],
                                [3.62239, 46.73992],
                                [3.03203, 46.79466],
                                [2.85187, 47.75776],
                                [3.12678, 47.96814],
                                [2.93656, 48.16348],
                                [3.41472, 48.39018],
                                [3.89411, 47.92919],
                                [4.78952, 48.00807],
                                [4.95384, 47.70238],
                                [5.37398, 47.60595],
                                [5.48079, 47.21873],
                                [5.25451, 46.97095],
                                [5.44026, 46.63307],
                                [5.31077, 46.44684],
                                [4.93307, 46.51231],
                                [4.73683, 46.23244],
                                [4.28228, 46.15683],
                                [3.90002, 46.27599],
                            ],
                        ],
                    },
                },
            ],
        },

        // http://geojson.io/#id=gist:nerik/0b82e5a31369ebb33555&map=5/48.92200/2.81300
        countries_polygons: {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    properties: {
                        name: 'France',
                        id: 'FR',
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [2.46094, 51.11732],
                                [1.46118, 51.0483],
                                [-0.21973, 50.30338],
                                [-2.92236, 49.85215],
                                [-7.00928, 48.95137],
                                [-1.73584, 43.40505],
                                [-1.4502, 43.26121],
                                [-1.40625, 43.06889],
                                [-0.46143, 42.7954],
                                [0.68115, 42.71473],
                                [0.70312, 42.87596],
                                [1.86768, 42.60162],
                                [1.99951, 42.34231],
                                [3.12012, 42.42346],
                                [9.25049, 41.31082],
                                [10.15137, 42.22852],
                                [9.53613, 43.67582],
                                [7.60254, 43.70759],
                                [7.73437, 44.0718],
                                [7.60254, 44.1822],
                                [6.89941, 44.43378],
                                [6.96533, 44.84029],
                                [6.61377, 45.15105],
                                [7.20703, 45.44472],
                                [6.8335, 45.81349],
                                [7.00928, 45.93587],
                                [6.76758, 46.36209],
                                [6.13037, 46.37725],
                                [6.1084, 46.60417],
                                [6.43799, 46.95026],
                                [6.96533, 47.33882],
                                [7.60254, 47.60616],
                                [7.64648, 48.29781],
                                [8.17383, 49.03787],
                                [6.81152, 49.1817],
                                [6.3501, 49.49667],
                                [5.75684, 49.55373],
                                [4.85596, 49.78126],
                                [4.85596, 50.1769],
                                [4.10889, 49.99362],
                                [2.46094, 51.11732],
                            ],
                        ],
                    },
                },
            ],
        },

        countries_polygons_noRegions: {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    properties: {
                        name: 'Belgique',
                        id: 'BE',
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [2.47192, 51.11732],
                                [4.18579, 49.97949],
                                [4.85596, 50.1769],
                                [4.92188, 49.78836],
                                [5.80078, 49.52521],
                                [5.82275, 49.91586],
                                [6.1084, 50.18393],
                                [6.37207, 50.35948],
                                [6.25122, 50.62507],
                                [5.99854, 50.75731],
                                [5.65796, 50.87531],
                                [5.77881, 51.17246],
                                [5.00977, 51.48138],
                                [4.44946, 51.47454],
                                [3.82324, 51.17934],
                                [3.32886, 51.37864],
                                [2.47192, 51.11732],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'United Kingdom',
                        id: 'GB',
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [1.53809, 51.05521],
                                [3.18604, 52.49616],
                                [3.55957, 56.82493],
                                [-1.62598, 59.77852],
                                [-8.65723, 59.26588],
                                [-8.65723, 56.17002],
                                [-7.29492, 55.12865],
                                [-7.69043, 54.74999],
                                [-8.21777, 54.49557],
                                [-7.60254, 54.11094],
                                [-7.0752, 54.36776],
                                [-6.67969, 54.05939],
                                [-6.1084, 54.03359],
                                [-5.49316, 53.27835],
                                [-7.0752, 48.95137],
                                [-2.85645, 49.86632],
                                [-0.35156, 50.26125],
                                [1.53809, 51.05521],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Deutschland',
                        id: 'DE',
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [6.06445, 50.73646],
                                [5.91064, 51.06902],
                                [6.19629, 51.17934],
                                [6.21826, 51.50874],
                                [5.97656, 51.79503],
                                [6.13037, 51.89005],
                                [6.76758, 51.94426],
                                [6.74561, 52.05249],
                                [7.05322, 52.21434],
                                [6.9873, 52.44262],
                                [6.70166, 52.49616],
                                [6.70166, 52.66972],
                                [7.09717, 52.69636],
                                [7.25098, 53.08083],
                                [7.16309, 53.27835],
                                [6.9873, 53.43572],
                                [6.96533, 55.00283],
                                [10.0415, 54.81335],
                                [11.75537, 54.40614],
                                [13.16162, 55.06579],
                                [14.34814, 54.88925],
                                [14.23828, 53.89139],
                                [14.37012, 53.14677],
                                [14.17236, 52.86913],
                                [14.65576, 52.54964],
                                [14.96338, 51.01375],
                                [14.74365, 50.8337],
                                [14.45801, 51.05521],
                                [14.32617, 50.88917],
                                [12.17285, 50.26125],
                                [12.52441, 49.90879],
                                [12.41455, 49.76707],
                                [12.81006, 49.33944],
                                [13.84277, 48.76343],
                                [13.71094, 48.50205],
                                [13.51318, 48.56025],
                                [13.3374, 48.31243],
                                [12.74414, 48.10743],
                                [13.05176, 47.44295],
                                [12.23877, 47.69497],
                                [10.98633, 47.39835],
                                [10.78857, 47.53204],
                                [10.43701, 47.59135],
                                [10.19531, 47.32393],
                                [9.68994, 47.5617],
                                [9.58008, 47.50236],
                                [8.54736, 47.81315],
                                [8.50342, 47.63578],
                                [7.66846, 47.59135],
                                [7.66846, 48.32704],
                                [8.17383, 49.05227],
                                [6.81152, 49.21042],
                                [6.28418, 49.51094],
                                [6.50391, 49.80963],
                                [6.17432, 49.93708],
                                [6.08643, 50.12058],
                                [6.37207, 50.3735],
                                [6.24023, 50.6808],
                                [6.06445, 50.73646],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'España',
                        id: 'ES',
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [-1.73584, 43.40505],
                                [-2.70264, 44.46515],
                                [-11.03027, 45.13556],
                                [-11.0083, 41.91863],
                                [-8.87695, 41.90228],
                                [-8.15186, 42.13082],
                                [-8.1958, 41.8532],
                                [-6.5918, 41.95132],
                                [-6.56982, 41.68932],
                                [-6.21826, 41.57436],
                                [-6.78955, 41.11247],
                                [-6.81152, 40.3298],
                                [-7.00928, 40.17887],
                                [-6.89941, 39.97712],
                                [-7.05322, 39.67337],
                                [-7.55859, 39.69028],
                                [-7.03125, 39.04479],
                                [-7.33887, 38.44498],
                                [-6.9873, 38.18639],
                                [-7.53662, 37.59682],
                                [-7.38281, 37.21283],
                                [-7.44873, 35.85344],
                                [-1.73584, 36.0491],
                                [5.55908, 38.54817],
                                [6.9873, 41.70573],
                                [3.14209, 42.43967],
                                [1.97754, 42.37478],
                                [1.8457, 42.60162],
                                [0.7251, 42.87596],
                                [0.63721, 42.74701],
                                [-0.4834, 42.77928],
                                [-1.40625, 43.06889],
                                [-1.38428, 43.2452],
                                [-1.73584, 43.40505],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Italia',
                        id: 'IT',
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [7.00928, 45.95115],
                                [6.8335, 45.82114],
                                [7.19604, 45.46784],
                                [6.62476, 45.16655],
                                [6.93237, 44.86366],
                                [6.9104, 44.46515],
                                [7.60254, 44.1822],
                                [7.72339, 44.07969],
                                [7.6355, 43.72347],
                                [9.52515, 43.67582],
                                [10.16235, 42.23665],
                                [9.27246, 41.34382],
                                [6.9873, 41.77131],
                                [6.81152, 37.8922],
                                [10.85449, 37.99616],
                                [12.56836, 36.84446],
                                [17.57812, 35.17381],
                                [19.07227, 38.99357],
                                [18.94043, 40.01079],
                                [18.54492, 41.21172],
                                [16.08398, 42.87596],
                                [13.40332, 44.49651],
                                [13.5022, 45.49095],
                                [13.90869, 45.63709],
                                [13.56812, 45.81349],
                                [13.52417, 46.07323],
                                [13.63403, 46.18744],
                                [13.41431, 46.24065],
                                [13.71094, 46.50595],
                                [12.40356, 46.7248],
                                [12.11792, 47.08509],
                                [11.44775, 47.00273],
                                [11.12915, 46.94276],
                                [10.98633, 46.76997],
                                [10.46997, 46.89774],
                                [10.38208, 46.65698],
                                [10.448, 46.58907],
                                [10.38208, 46.55131],
                                [10.22827, 46.62681],
                                [10.17334, 46.24065],
                                [10.05249, 46.21785],
                                [9.92065, 46.38483],
                                [9.52515, 46.309],
                                [9.43726, 46.49083],
                                [9.25049, 46.4757],
                                [9.01978, 45.84411],
                                [8.89893, 45.85941],
                                [8.84399, 46.06561],
                                [8.59131, 46.157],
                                [8.4375, 46.30141],
                                [8.4375, 46.453],
                                [8.09692, 46.26344],
                                [7.82227, 45.92059],
                                [7.00928, 45.95115],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Nederland',
                        id: 'NL',
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [3.3268, 51.38155],
                                [3.82352, 51.17987],
                                [4.45995, 51.47864],
                                [5.02089, 51.47967],
                                [5.78186, 51.17509],
                                [5.66279, 50.87593],
                                [6.06861, 50.73538],
                                [5.90618, 51.07105],
                                [6.20471, 51.18055],
                                [6.2234, 51.50304],
                                [5.97971, 51.80106],
                                [6.14016, 51.90196],
                                [6.76429, 51.94848],
                                [6.73842, 52.04722],
                                [7.0531, 52.21818],
                                [6.98617, 52.43889],
                                [6.70735, 52.49491],
                                [6.71427, 52.67263],
                                [7.10453, 52.70036],
                                [7.23559, 53.07516],
                                [7.18812, 53.27271],
                                [6.98845, 53.41206],
                                [6.99352, 53.75593],
                                [5.20954, 53.54272],
                                [4.60495, 53.19956],
                                [4.36473, 52.49121],
                                [3.94365, 52.03253],
                                [3.55337, 51.73375],
                                [3.33377, 51.54767],
                                [3.3268, 51.38155],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Danmark',
                        id: 'DK',
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [7.0531, 55.00727],
                                [7.6178, 57.04422],
                                [10.76197, 58.05784],
                                [11.4173, 57.13513],
                                [11.18724, 56.07229],
                                [12.52411, 56.2621],
                                [12.7907, 55.79501],
                                [12.87436, 55.57098],
                                [12.58852, 55.22656],
                                [12.94407, 54.96727],
                                [11.77285, 54.39496],
                                [10.06481, 54.82294],
                                [7.0531, 55.00727],
                            ],
                        ],
                    },
                },
                {
                    type: 'Feature',
                    properties: {
                        name: 'Suisse',
                        id: 'CH',
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [6.12239, 46.38207],
                                [6.12148, 46.60555],
                                [6.44098, 46.94818],
                                [6.95202, 47.33017],
                                [7.59339, 47.59233],
                                [8.51681, 47.63523],
                                [8.55157, 47.82337],
                                [9.69532, 47.46524],
                                [9.60818, 47.07497],
                                [10.39945, 47.00608],
                                [10.48311, 46.89663],
                                [10.37505, 46.65552],
                                [10.43711, 46.58356],
                                [10.3859, 46.55259],
                                [10.23315, 46.63508],
                                [10.17636, 46.24966],
                                [10.05166, 46.20954],
                                [9.92038, 46.38769],
                                [9.51331, 46.3123],
                                [9.43711, 46.49823],
                                [9.24684, 46.47895],
                                [9.01211, 45.85291],
                                [8.91058, 45.8668],
                                [8.84612, 46.07641],
                                [8.58578, 46.15686],
                                [8.4356, 46.30733],
                                [8.45419, 46.46323],
                                [8.07717, 46.25248],
                                [7.81997, 45.9257],
                                [7.0043, 45.95964],
                                [6.7753, 46.3653],
                                [6.12239, 46.38207],
                            ],
                        ],
                    },
                },
            ],
        },
    },
};
