import PropTypes from 'prop-types';
import React from 'react';
import { getLocalizedDay, getIsoWeekday } from 'modules/utils/dateAndTime';
import connectToI18n from 'modules/i18n/connectToI18n';

export const normalizePickupTimeSlot = ({ dayOfWeek, timeStart, timeEnd }) => ({
    day: getIsoWeekday(dayOfWeek),
    starting: timeStart,
    ending: timeEnd,
});

const removeSeconds = dateString => dateString.substr(0, dateString.length - 3);

export class DeliveryTimeSlot extends React.PureComponent {
    static propTypes = {
        timeSlot: PropTypes.shape({
            day: PropTypes.number,
            starting: PropTypes.string,
            ending: PropTypes.string,
        }).isRequired,
        i18n: PropTypes.string.isRequired,
    };

    render() {
        const { day, starting, ending } = this.props.timeSlot;
        return `${getLocalizedDay(this.props.i18n, day)}, ${removeSeconds(
            starting
        )}-${removeSeconds(ending)}`;
    }
}

export default connectToI18n(DeliveryTimeSlot);
