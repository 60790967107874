import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import List from 'components/List.jsx';
import Address from 'components/Address.jsx';
import { getIsoWeekday, getLocalizedTime, getLocalizedDay } from 'modules/utils/dateAndTime';
import classnames from 'classnames';

export class DeliveryOptionInfo extends React.Component {
    static propTypes = {
        i18n: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
        timeSlots: PropTypes.array.isRequired,
        timeSlotsEmphasis: PropTypes.bool,
        address: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    };

    render() {
        const { address, timeSlots, timeSlotsEmphasis } = this.props;
        return (
            <Fragment>
                <List>
                    {R.pipe(
                        R.sortBy(timeSlot => getIsoWeekday(timeSlot.dayOfWeek)),
                        R.map(timeSlot => (
                            <li
                                className={classnames({
                                    'fa-text--bold': timeSlotsEmphasis,
                                })}
                                key={`${timeSlot.dayOfWeek}_${timeSlot.timeStart}`}
                            >
                                {this.props.trans('selectDelivery.timeSlot', {
                                    '%day%': getLocalizedDay(
                                        this.props.i18n,
                                        getIsoWeekday(timeSlot.dayOfWeek)
                                    ),
                                    '%start%': getLocalizedTime(
                                        this.props.i18n,
                                        timeSlot.timeStart
                                    ),
                                    '%end%': getLocalizedTime(this.props.i18n, timeSlot.timeEnd),
                                })}
                            </li>
                        ))
                    )(timeSlots)}
                </List>
                <Address address={address} className="u-mb-1" />
            </Fragment>
        );
    }
}

export default connectToI18n(DeliveryOptionInfo);
