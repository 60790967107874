import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import classNames from 'classnames';

import useI18n from 'hooks/I18n/useI18n';

import Password from 'src/components/molecules/Password/Password.jsx';

import Icon from 'src/components/atoms/Icons/Icon.jsx';
import Text from 'src/components/atoms/Text/Text.jsx';

import getIcons, { ICONS } from './getIcons';

const PasswordValidation = ({ password, className }) => {
    const { trans } = useI18n();
    const [value, setValue] = useState();
    const validators = {
        uppercase: /[A-Z]/.test(value),
        lowercase: /[a-z]/.test(value),
        digit: /[0-9]/.test(value),
        eightCharacters: value && value.length > 7,
    };
    const handleOnChange = e => {
        setValue(e.target.value);
        password.onChange(e);
    };
    const passwordIsEmpty = isEmpty(value);

    return (
        <div
            className={classNames(
                'nd-password-validation',
                className,
                passwordIsEmpty && 'nd-password-empty'
            )}
        >
            <Password {...password} onChange={handleOnChange} id="nd-new-password" />
            <div className="nd-validator-password">
                <div className="nd-validator-row">
                    <div className="nd-validator-item">
                        <Icon
                            size="large"
                            src={getIcons(validators.uppercase, passwordIsEmpty)}
                            color={
                                getIcons(validators.uppercase, passwordIsEmpty) === ICONS.error
                                    ? 'error'
                                    : undefined
                            }
                        ></Icon>
                        <Text>{trans('formValidation.type.password.uppercase')}</Text>
                    </div>
                    <div className="nd-validator-item">
                        <Icon
                            size="large"
                            src={getIcons(validators.lowercase, passwordIsEmpty)}
                            color={
                                getIcons(validators.lowercase, passwordIsEmpty) === ICONS.error
                                    ? 'error'
                                    : undefined
                            }
                        ></Icon>

                        <Text>{trans('formValidation.type.password.lowercase')}</Text>
                    </div>
                </div>
                <div className="nd-validator-row">
                    <div className="nd-validator-item">
                        <Icon
                            size="large"
                            src={getIcons(validators.digit, passwordIsEmpty)}
                            color={
                                getIcons(validators.digit, passwordIsEmpty) === ICONS.error
                                    ? 'error'
                                    : undefined
                            }
                        ></Icon>

                        <Text>{trans('formValidation.type.password.digit')}</Text>
                    </div>
                    <div className="nd-validator-item">
                        <Icon
                            size="large"
                            src={getIcons(value && value.length > 7, passwordIsEmpty)}
                            color={
                                getIcons(value && value.length > 7, passwordIsEmpty) === ICONS.error
                                    ? 'error'
                                    : undefined
                            }
                        ></Icon>
                        <Text>{trans('formValidation.type.password.eightCharacters')}</Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

PasswordValidation.propTypes = {
    password: PropTypes.shape({
        onChange: PropTypes.func,
        error: PropTypes.string,
    }),
    className: PropTypes.string,
    error: PropTypes.string,
};
PasswordValidation.defaultProps = {
    password: {
        error: null,
        onChange: e => e,
    },
    className: '',
};
export default PasswordValidation;
