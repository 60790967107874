import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as R from 'ramda';

import useResponsive from 'hooks/Navigation/useResponsive';
import useI18n from 'hooks/I18n/useI18n';

import { getLocaleByCode } from 'modules/utils/locales';
import { getChristmasLogoForEnabledCountries } from 'modules/logo';
import { currentAssemblySelector } from 'modules/navigation';
import { userSelector } from 'modules/currentUser';
import {
    showImmediately as showPanelImmediately,
    getVisiblePanel,
    HELP_PANEL,
} from 'modules/visiblePanel';
import environment from 'modules/utils/environment';

import { Link } from 'components/ui';
import AssemblyInfos from 'components/Navigation/ProductIdentity/AssemblyInfos.jsx';
import HelpButton from 'components/Navigation/ProductIdentity/HelpButton.jsx';
import AccountButton from 'components/Navigation/ProductIdentity/AccountButton.jsx';

const PublicHeader = () => {
    const { i18n, trans } = useI18n();
    const locale = getLocaleByCode(i18n);
    const logo = getChristmasLogoForEnabledCountries(locale.logos.whiteOutlined, locale, true);

    const isSmallWidth = useResponsive();
    const currentUser = useSelector(userSelector);
    const visiblePanel = useSelector(getVisiblePanel);
    const currentAssembly = useSelector(currentAssemblySelector);
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const farm = R.path(['farm', 0], currentUser);

    const displayHelpPanel = visiblePanel === HELP_PANEL;

    const isOnAssemblyHomepage = useMemo(() => pathname === '/', [pathname]);

    const openMenu = (e, panel) => {
        e.preventDefault();
        dispatch(showPanelImmediately(panel));
    };
    const openHelpMenu = e => openMenu(e, HELP_PANEL);
    useEffect(() => {
        document.body.classList.add('productIdentity');
    });
    return (
        <div className="pi-public-header">
            <nav>
                {currentAssembly ? (
                    <AssemblyInfos />
                ) : (
                    <Link
                        href={
                            environment === 'production' ? `${locale.homepage}/${i18n}` : `/${i18n}`
                        }
                        data-bypass
                    >
                        <img className="topNavigation-logo" alt={trans('brand.name')} src={logo} />
                    </Link>
                )}
                <div className="pi-public-header-right">
                    {
                        <HelpButton
                            currentAssembly={currentAssembly}
                            open={displayHelpPanel}
                            openHelpMenu={openHelpMenu}
                            currentUser={currentUser}
                            farm={farm}
                            productIdentity
                        />
                    }
                    <AccountButton
                        isSmallWidth={isSmallWidth}
                        isOnHeader={isOnAssemblyHomepage}
                        shouldShowProductIdentity
                    />
                </div>
            </nav>
        </div>
    );
};

export default PublicHeader;
