import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const PillButton = ({ children, color, href, isActive, onClick, style }) => {
    const classNames = classnames('pillButton', `pillButton--${color}`, {
        [`pillButton-active--${color}`]: isActive,
    });
    if (href) {
        return (
            <a className={classNames} href={href} style={style}>
                {children}
            </a>
        );
    }
    return (
        <button className={classNames} onClick={onClick} style={style}>
            {children}
        </button>
    );
};

PillButton.propTypes = {
    children: PropTypes.node,
    color: PropTypes.oneOf(['maroon', 'white', 'turquoise2']).isRequired,
    href: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object,
};

PillButton.defaultProps = {
    color: 'white',
};

export default PillButton;
