import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
    showImmediately as showPanelImmediately,
    getVisiblePanel,
    toggle as togglePanel,
    CONTEXT_PANEL,
} from 'modules/visiblePanel';
import { getLogoWithoutText } from 'modules/logo';
import useResponsive from 'hooks/Navigation/useResponsive';
import useI18n from 'hooks/I18n/useI18n';
import Icon from 'components/Icon.jsx';
import PIText from 'components/ProductIdentity/Text.jsx';
import Caret from 'components/Navigation/Caret.jsx';
import PIContextPanel from 'components/Navigation/ProductIdentity/ContextPanel.jsx';

import { currentAssemblySelector } from 'modules/navigation';

const AssemblyInfos = () => {
    const { trans, i18nConfig } = useI18n();
    const dispatch = useDispatch();
    const isSmallWidth = useResponsive();
    const visiblePanel = useSelector(getVisiblePanel);

    const displayContextPanel = visiblePanel === CONTEXT_PANEL;

    const openMenu = useCallback(
        (e, panel) => {
            e.preventDefault();
            dispatch(showPanelImmediately(panel));
        },
        [dispatch]
    );
    const openContextMenu = useCallback(e => openMenu(e, CONTEXT_PANEL), [openMenu]);

    const currentAssembly = useSelector(currentAssemblySelector);

    return (
        <>
            <div className={classnames('assembly-infos', { 'col-xs-6': isSmallWidth })}>
                <button
                    aria-label={trans('navigation.member.assemblies')}
                    className="home-button simpleMemberTopNavigation-button without-end-border"
                    onClick={openContextMenu}
                >
                    <Icon
                        className="u-mt-2 u-mb-2"
                        src={getLogoWithoutText(i18nConfig, true)}
                        color="white"
                        size="xxxxs-large"
                    />
                </button>
                <button
                    className={classnames(
                        'simpleMemberTopNavigation-button simpleMemberTopLeftMenu-deliveryPanelButton without-end-border'
                    )}
                    onClick={openContextMenu}
                >
                    <div className="simpleMemberTopLeftMenu-assemblyPanel">
                        {
                            <>
                                <PIText
                                    className="simpleMemberTopLeftMenu-assemblyPanel-title"
                                    size="14px"
                                    bold
                                >
                                    {currentAssembly?.place?.address?.city?.name}
                                </PIText>
                                <PIText
                                    className="simpleMemberTopLeftMenu-assemblyPanel-name"
                                    size="14px"
                                >
                                    {currentAssembly?.name}
                                </PIText>
                            </>
                        }
                    </div>
                    <Caret productIdentity />
                </button>
            </div>
            {displayContextPanel && (
                <PIContextPanel
                    isOpened
                    assembly={currentAssembly}
                    onClose={() => dispatch(togglePanel(CONTEXT_PANEL))}
                />
            )}
        </>
    );
};

export default AssemblyInfos;
