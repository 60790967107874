import PropTypes from 'prop-types';
import React from 'react';

function getDisplayName(Component) {
    return Component.displayName || Component.name || 'Component';
}

export default function(Component) {
    return class extends React.Component {
        static displayName = `InstallI18N(${getDisplayName(Component)})`;

        static propTypes = {
            i18n: PropTypes.string.isRequired,
        };

        static childContextTypes = {
            i18n: PropTypes.string.isRequired,
        };

        getChildContext() {
            return {
                i18n: this.props.i18n,
            };
        }

        render() {
            return <Component {...this.props} />;
        }
    };
}

// eslint-disable-next-line react/no-multi-comp
export class ProvideLegacyI18nContext extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        i18n: PropTypes.string.isRequired,
    };

    static childContextTypes = {
        i18n: PropTypes.string.isRequired,
    };

    getChildContext() {
        return {
            i18n: this.props.i18n,
        };
    }

    render() {
        return this.props.children;
    }
}
