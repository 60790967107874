import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export default class BoxSection extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        alternate: PropTypes.bool,
        bordered: PropTypes.bool,
        warning: PropTypes.bool,
        onClick: PropTypes.func,
        className: PropTypes.string,
    };

    handleClick = e => {
        if (['A', 'LABEL', 'INPUT', 'BUTTON'].indexOf(e.target.nodeName) === -1) {
            e.preventDefault();
            this.props.onClick();
        }
    };

    render() {
        const baseClassName = 'boxSection';

        return (
            <div
                className={classnames(
                    baseClassName,
                    {
                        [`${baseClassName}--main`]: !this.props.alternate,
                        [`${baseClassName}--alternate`]: this.props.alternate,
                        [`${baseClassName}--bordered`]: this.props.bordered,
                        [`${baseClassName}--warning`]: this.props.warning,
                        [`${baseClassName}--clickable`]: this.props.onClick,
                    },
                    this.props.className
                )}
                onClick={this.props.onClick ? this.handleClick : null}
            >
                {this.props.children}
            </div>
        );
    }
}
