import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { filerPhoto } from 'modules/utils/filer';
import { getLocalizedDate } from 'modules/utils/dateAndTime';
import { userSelector } from 'modules/currentUser';
import { formatFullName } from 'models/users';
import useI18n from 'hooks/I18n/useI18n';
import Text from 'components/ProductIdentity/Text.jsx';
import Button, { LINK_MODE, SECONDARY_MODE } from 'src/components/atoms/Button/Button.jsx';
import NewsMessageForm from './NewsMessageForm.jsx';
import NewsComment from './NewsComment.jsx';

const NewsMessage = ({ discussion, onNewCommentFormSubmit, onDeleteComment, onDelete }) => {
    const { i18n, trans } = useI18n();
    const [isNewCommentFormOpen, setIsNewCommentFormOpen] = useState(false);
    const currentUser = useSelector(userSelector);

    const firstMessage = discussion.messages[0];
    const comments = discussion.messages.slice(1);
    const isAuthorOfMessage = currentUser?.id === firstMessage.author.id;
    const defaultAvatarLetters = `${firstMessage.author.firstName[0]}${firstMessage.author.lastName[0]}`;

    const handleNewCommentFormSubmit = content => {
        onNewCommentFormSubmit(discussion.id, content, () => setIsNewCommentFormOpen(false));
    };

    return (
        <div className="pi-assembly-news-message">
            <div className="pi-assembly-news-message-header">
                {firstMessage.author.photoId ? (
                    <img src={filerPhoto(firstMessage.author.photoId, 'small')} />
                ) : (
                    <div className="pi-assembly-news-message-header-avatar">
                        {defaultAvatarLetters}
                    </div>
                )}
                <div className="pi-assembly-news-message-header-infos">
                    <div className="pi-assembly-news-message-header-infos-main-col">
                        <Text size="16px" lineHeight="20px" weight={500}>
                            {formatFullName(firstMessage.author, { shortenedLastName: true })}
                        </Text>
                        <Text
                            size="16px"
                            lineHeight="20px"
                            className="pi-assembly-news-message-datetime"
                        >
                            {getLocalizedDate(i18n, 'M', firstMessage.created)} –{' '}
                            {getLocalizedDate(i18n, 't', firstMessage.created)}
                        </Text>
                    </div>
                    {isAuthorOfMessage && (
                        <Button
                            mode={LINK_MODE}
                            className="pi-assembly-news-message-delete-btn"
                            onClick={() => onDelete(discussion.id)}
                        >
                            {trans('global.delete')}
                        </Button>
                    )}
                </div>
            </div>
            <div className="pi-assembly-news-message-content">
                <Text size="16px" lineHeight="24px">
                    {firstMessage.title && (
                        <div className="message-title">
                            <b>{firstMessage.title}</b>
                        </div>
                    )}
                    <div
                        className="message-body"
                        dangerouslySetInnerHTML={{ __html: firstMessage.content }}
                    ></div>
                </Text>
            </div>
            {!!comments.length && (
                <div className="pi-assembly-news-comments">
                    {comments.map(comment => (
                        <NewsComment
                            discussionId={discussion.id}
                            comment={comment}
                            key={comment.uuid}
                            onDelete={onDeleteComment}
                        />
                    ))}
                </div>
            )}
            {!isNewCommentFormOpen && (
                <div className="pi-assembly-news-message-actions">
                    <Button mode={SECONDARY_MODE} onClick={() => setIsNewCommentFormOpen(true)}>
                        {trans('global.toComment')}
                    </Button>
                </div>
            )}
            {isNewCommentFormOpen && (
                <NewsMessageForm
                    saveButtonLabel={trans('global.toComment')}
                    onSave={handleNewCommentFormSubmit}
                    onCancel={() => setIsNewCommentFormOpen(false)}
                    placeholder={trans('hiveHome.newsSection.form.commentEditor.placeholder')}
                />
            )}
        </div>
    );
};

NewsMessage.propTypes = {
    discussion: PropTypes.shape({
        messages: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                content: PropTypes.string.isRequired,
                created: PropTypes.string.isRequired,
                author: PropTypes.shape({
                    photoId: PropTypes.string,
                    firstName: PropTypes.string.isRequired,
                    lastName: PropTypes.string.isRequired,
                }).isRequired,
            })
        ).isRequired,
    }).isRequired,
    onNewCommentFormSubmit: PropTypes.func.isRequired,
    onDeleteComment: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default NewsMessage;
