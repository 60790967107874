import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { formatFullName } from 'models/users';
import { Box, BoxSection, Heading, Grid, Button, Legend, Text, MediaObject } from 'components/ui';
import Avatar from 'components/Avatar.jsx';
import { compareAlpha } from 'modules/utils/string.js';
import { sort } from 'ramda';

export class AssemblyFarms extends React.Component {
    static propTypes = {
        assemblyFarms: PropTypes.array.isRequired,
        trans: PropTypes.func.isRequired,
        transChoice: PropTypes.func.isRequired,
        i18n: PropTypes.func.isRequired,
    };

    render() {
        const { assemblyFarms, trans, transChoice, i18n } = this.props;

        const sortedProducers = sort(
            (a, b) => compareAlpha(a.name.toLowerCase(), b.name.toLowerCase()),
            assemblyFarms
        );

        return (
            <Fragment>
                <div className="u-mb-3">
                    <Heading size={1}>{trans('public.assembly.header.link.producers')}</Heading>
                </div>
                <Box>
                    <img
                        width="100%"
                        src="/assets/images/public-pages/illu-producers.png"
                        srcSet="/assets/images/public-pages/illu-producers@2x.png 2x"
                        alt=""
                    />
                    {sortedProducers.map(farm => (
                        <BoxSection bordered key={farm.id}>
                            <Grid xs="12|6|6|12" md="4|2|3|3">
                                <div className="u-mb-2 u-mb-md-0">
                                    <MediaObject
                                        media={
                                            <Avatar
                                                photoId={farm.photoid || null}
                                                responsive
                                                rounded
                                                size="small"
                                            />
                                        }
                                        size="xsmall"
                                        className="u-mb-4"
                                    >
                                        <Text weight="bold">{farm.name}</Text>
                                        <Text>{formatFullName(farm.user)}</Text>
                                    </MediaObject>
                                </div>
                                <div className="u-mb-2 u-mb-md-0">
                                    <Legend>{trans('global.city')}</Legend>
                                    <Text>{farm.cityName}</Text>
                                </div>
                                <div className="u-mb-2 u-mb-md-0">
                                    <Legend>
                                        {transChoice('global.jobs', farm.professions.length)}
                                    </Legend>
                                    <Text>{farm.professions.join(', ')}</Text>
                                </div>
                                <div className="u-mb-2 u-mb-md-0">
                                    <Button
                                        weight="link"
                                        variant="primary"
                                        href={`/${i18n}/producers/${farm.id}`}
                                        block
                                    >
                                        {trans('farms.profile')}
                                    </Button>
                                </div>
                            </Grid>
                        </BoxSection>
                    ))}
                </Box>
            </Fragment>
        );
    }
}
export default connectToI18n(AssemblyFarms);
