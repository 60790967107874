import useI18n from 'hooks/I18n/useI18n';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import Utils from 'modules/utils';
const { linkTo } = Utils;
import FormGroup from 'components/FormGroup.jsx';
import InputErrors from 'components/InputErrors.jsx';
import classnames from 'classnames';
import { PROCESSING, propType as statusPropType } from 'modules/utils/ajaxStatuses';
import { Button, Box, Heading, Link, Notice } from 'components/ui';
import EyeIcon from './EyeIcon.jsx';

const LoginForm = ({
    assemblyId,
    formClientErrors,
    status,
    impersonatedEmail,
    handleEdit,
    handleSubmit,
    authenticationError,
    showErrors,
    showRememberMe,
    embedded,
    isLoginSuspended,
    showForgotPasswordNotice,
    hasTitle,
}) => {
    const { trans } = useI18n();
    const [isEyeOpened, setIsEyeOpened] = useState(true);
    const emailInput = useRef(null);
    const passwordInput = useRef(null);
    const rememberMeInput = useRef(null);

    const getFields = () => {
        return {
            email: emailInput.current.value,
            password: passwordInput.current.value,
            remember: showRememberMe ? rememberMeInput.current.checked : false,
        };
    };

    const onEdit = () => {
        handleEdit(getFields());
    };

    const onSubmit = e => {
        e.preventDefault();
        handleSubmit(getFields());
    };

    const switchVisibilityPassword = e => {
        e.preventDefault();
        if (passwordInput.current.type === 'password') {
            passwordInput.current.type = 'text';
            setIsEyeOpened(false);
        } else {
            passwordInput.current.type = 'password';
            setIsEyeOpened(true);
        }
    };

    const FormWrapper = embedded ? 'div' : Box;
    const joinUrl = !!assemblyId ? linkTo(`join/assembly/${assemblyId}`) : linkTo('join');

    return (
        <div className="login">
            {hasTitle && (
                <Heading size={4} className="u-mb-2">
                    {trans('login.Ilogin')}
                </Heading>
            )}
            {impersonatedEmail && <div>Impersonating: {impersonatedEmail}</div>}
            <FormWrapper className="u-mb-6">
                {isLoginSuspended && (
                    <Notice className="u-mb-4" type="error">
                        {trans('login.messageWhenSuspended')}
                    </Notice>
                )}
                {showForgotPasswordNotice && (
                    <Notice className="u-mb-4" type="hint">
                        {trans('login.forgotPasswordHintMessage', {
                            '%url%': linkTo('forgot_password'),
                        })}
                    </Notice>
                )}
                <form onChange={onEdit}>
                    <FormGroup>
                        <label htmlFor="email">{trans('login.email')}</label>
                        <input
                            ref={emailInput}
                            type="email"
                            id="email"
                            name="email"
                            className={classnames('fa-input fa-input--block fa-input--inverse', {
                                'fa-input--error': showErrors && formClientErrors.email,
                            })}
                            autoFocus
                        />
                        <InputErrors isVisible={showErrors} clientErrors={formClientErrors.email} />
                    </FormGroup>
                    <FormGroup>
                        <label htmlFor="password">{trans('login.password')}</label>
                        <input
                            ref={passwordInput}
                            type="password"
                            id="password"
                            name="password"
                            className={classnames('fa-input fa-input--block fa-input--inverse', {
                                'fa-input--error': showErrors && formClientErrors.password,
                            })}
                        />
                        <span
                            className={classnames('eyePointer', {
                                'eyePointer--opened': isEyeOpened,
                            })}
                            onClick={switchVisibilityPassword}
                        >
                            <EyeIcon isOpened={isEyeOpened} />
                        </span>
                        <InputErrors
                            isVisible={showErrors}
                            clientErrors={formClientErrors.password}
                        />
                        {authenticationError ? (
                            <ul className="server-error">
                                <li>
                                    {trans('session.loginForm.invalidPassword')}
                                    <br />
                                    {impersonatedEmail && (
                                        <span>
                                            or this is not an admin account
                                            <br />
                                        </span>
                                    )}
                                    {!showForgotPasswordNotice && (
                                        <Link
                                            className="forgotInError"
                                            href={linkTo('forgot_password')}
                                        >
                                            {trans('login.forgotPassword')}
                                        </Link>
                                    )}
                                </li>
                            </ul>
                        ) : (
                            <Link className="forgot" href={linkTo('forgot_password')}>
                                {trans('login.forgotPassword')}
                            </Link>
                        )}
                    </FormGroup>
                    {showRememberMe && (
                        <FormGroup>
                            <input
                                ref={rememberMeInput}
                                type="checkbox"
                                name="remember"
                                id="remember"
                                className="fa-checkbox fa-checkbox--inverse"
                                defaultChecked
                            />
                            <label htmlFor="remember">{trans('session.keepAlive')}</label>
                        </FormGroup>
                    )}
                    <Button
                        block
                        variant="success"
                        onClick={onSubmit}
                        loading={status === PROCESSING}
                        disabled={isLoginSuspended}
                    >
                        {trans('login.login')}
                    </Button>
                </form>
            </FormWrapper>
            <Heading size={4} className="u-mb-2">
                {trans('login.noAccount.title')}
            </Heading>
            <FormWrapper>
                <div
                    dangerouslySetInnerHTML={{
                        __html: trans('login.noAccount.text', { '%url%': joinUrl }),
                    }}
                />
            </FormWrapper>
        </div>
    );
};

LoginForm.propTypes = {
    assemblyId: PropTypes.number,
    formClientErrors: PropTypes.object.isRequired,
    status: statusPropType,
    impersonatedEmail: PropTypes.string,
    handleEdit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    authenticationError: PropTypes.bool,
    showErrors: PropTypes.bool,
    showRememberMe: PropTypes.bool,
    embedded: PropTypes.bool,
    isLoginSuspended: PropTypes.bool,
    showForgotPasswordNotice: PropTypes.bool,
    hasTitle: PropTypes.bool,
};

export default LoginForm;
