import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import connectToI18n from 'modules/i18n/connectToI18n';

export const Price = ({
    trans,
    price,
    bold = false,
    className,
    maximumFractionDigits = 2,
    formatPrice,
    textWithPrice,
    discount,
}) => {
    const classNames = classnames(
        'price',
        {
            'u-font-weight-bold': bold,
        },
        className
    );

    const priceAmount = discount ? price.amount - (price.amount * discount) / 100 : price.amount;
    const formattedPrice = formatPrice(priceAmount / 100, price.currency, {
        maximumFractionDigits,
    });
    if (textWithPrice) {
        return (
            <span className={classNames}>
                {price &&
                    trans(textWithPrice.key, {
                        [`${textWithPrice.placeholder}`]: formattedPrice,
                    })}
            </span>
        );
    }

    return <span className={classNames}>{formattedPrice}</span>;
};

Price.propTypes = {
    trans: PropTypes.func,
    price: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
    }),
    bold: PropTypes.bool,
    className: PropTypes.string,
    maximumFractionDigits: PropTypes.number,
    formatPrice: PropTypes.func,
    textWithPrice: PropTypes.shape({
        key: PropTypes.string,
        placeholder: PropTypes.string,
    }),
    discount: PropTypes.number,
};

export default connectToI18n(Price);
