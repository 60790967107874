import React from 'react';
import PropTypes from 'prop-types';
import useI18n from 'hooks/I18n/useI18n.js';
import useResponsive from 'hooks/Navigation/useResponsive';
import classnames from 'classnames';
import { Link, Text } from 'components/ui';
import NearbyAssemblies from './NearbyAssemblies.jsx';

const AssemblyClosed = ({ assembly, nearbyAssemblies }) => {
    const { trans } = useI18n();
    const isSmallWidth = useResponsive();

    return (
        <div>
            <div className="u-mb-6">
                <Text serif size="x-large">
                    {trans('public.assembly.closed.description', {
                        '%assembly%': `${assembly.place.address.city.name} - ${assembly.name}`,
                    })}
                </Text>
                <div className={classnames({ 'u-flexible-row': !isSmallWidth })}>
                    <Text serif size="x-large u-mr-1">
                        {trans('public.assembly.closed.openAnAssembly')}
                    </Text>
                    <Link
                        href={trans('public.assembly.closed.openLinkURL')}
                        variant="primary"
                        noDecoration
                    >
                        <Text serif size="x-large">
                            {trans('public.assembly.closed.openLinkLabel')}
                        </Text>
                    </Link>
                </div>
            </div>
            <NearbyAssemblies nearbyAssemblies={nearbyAssemblies} assembly={assembly} />
        </div>
    );
};

AssemblyClosed.propTypes = {
    assembly: PropTypes.object.isRequired,
    nearbyAssemblies: PropTypes.array.isRequired,
};

export default AssemblyClosed;
