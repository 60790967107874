import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import ProductImage from 'components/Sale/ProductImage.jsx';
import { Heading } from 'components/ui';
import Legend from 'components/Legend.jsx';
import StartingAt from 'components/Offers/StartingAt.jsx';
import PriceAndPromotion from './PriceAndPromotion.jsx';
import connectToI18n from 'modules/i18n/connectToI18n';

export class CompactProductCard extends React.Component {
    static propTypes = {
        transChoice: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        product: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string,
            offers: PropTypes.array,
            photoId: PropTypes.string,
            type: PropTypes.shape({
                id: PropTypes.number.isRequired,
                quantityStrategy: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        productName: PropTypes.string.isRequired,
        allProductTypesIndexedById: PropTypes.object.isRequired,
        addToCartButton: PropTypes.node.isRequired,
        formattedQuantities: PropTypes.string.isRequired,
        isMultiOffers: PropTypes.bool,
        isPromoted: PropTypes.bool,
    };

    render() {
        const {
            transChoice,
            product,
            allProductTypesIndexedById,
            productName,
            addToCartButton,
            formattedQuantities,
            isMultiOffers,
            isPromoted,
            onClick,
        } = this.props;

        return (
            <div className="productCard compactProductCard" onClick={onClick}>
                <div className="compactProductCard-imageAndButtonContainer">
                    <ProductImage
                        className="compactProductCard-image"
                        productCategoryId={R.prop(
                            'categoryId',
                            allProductTypesIndexedById[product.type.id]
                        )}
                        photoId={product.photoId}
                        labels={product.labels}
                        productTypeId={product.type.id}
                        resolution="medium"
                        size="medium"
                        productName={product.name}
                    />
                    <div className="compactProductCard-addToCart">{addToCartButton}</div>
                </div>
                <div className="compactProductCard-container">
                    <div className="compactProductCard-nameContainer">
                        <Heading rank={5} className="productCard-name" size={4}>
                            {productName}
                        </Heading>
                    </div>

                    <div className="compactProductCard-body">
                        <div className="compactProductCard-quantityContainer">
                            {isMultiOffers ? (
                                <Legend className="productCard-offerCount" size="large">
                                    {transChoice('offers.count', product.offers.length, {
                                        '%count%': product.offers.length,
                                    })}
                                </Legend>
                            ) : (
                                <Legend className="productCard-quantity" size="large">
                                    {formattedQuantities}
                                </Legend>
                            )}
                        </div>
                        <div className="compactProductCard-price-container">
                            {isMultiOffers && !isPromoted ? (
                                <StartingAt
                                    className="compactProductCard-price fa-text--info"
                                    price={product.offers[0].price}
                                />
                            ) : (
                                <PriceAndPromotion
                                    className="compactProductCard-price fa-text--info"
                                    offer={product.offers[0]}
                                    hiddenOriginalOfferOnMobile
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connectToI18n(CompactProductCard);
