import checkIcon from 'src/components/atoms/Icons/svg/check.svg';
import crossIcon from 'src/components/atoms/Icons/svg/cross.svg';
import bulletIcon from 'src/components/atoms/Icons/svg/bullet.svg';

export const ICONS = {
    default: bulletIcon,
    error: crossIcon,
    valid: checkIcon,
};

const getIcons = (isValid, isEmpty) => {
    if (isEmpty) {
        return ICONS.default;
    }
    return isValid ? ICONS.valid : ICONS.error;
};

export default getIcons;
