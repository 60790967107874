// Sets the body style to use when opening a modal dialog.

// Also compensates the content shift due to the browser scrollbar disappearing
// when disabling overflow on body. Useful for modal dialogs.
// Based on https://davidwalsh.name/detect-scrollbar-width

let originalBodyPadding;

function offsetScrollBar() {
    // Save normal body right padding
    originalBodyPadding = parseInt(document.body.style.paddingRight || 0, 10);
    // Apply new padding with the scrollbar width to compensate shifting effect
    const newPadding = originalBodyPadding + getScrollBarWidth();
    document.body.style.paddingRight = `${newPadding}px`;
}

function resetScrollBar() {
    document.body.style.paddingRight = `${originalBodyPadding}px`;
}

function getScrollBarWidth() {
    // Detect browser scrollbar width using a helper element
    const scrollDiv = document.createElement('div');
    scrollDiv.className = 'scrollbar-measure';
    document.body.appendChild(scrollDiv);

    const scrollBarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

    document.body.removeChild(scrollDiv);

    return scrollBarWidth;
}

export function show() {
    if (document.body.classList.contains('modal-open')) {
        return;
    }

    document.body.classList.add('modal-open');
    offsetScrollBar();
}

export function hide() {
    document.body.classList.remove('modal-open');
    resetScrollBar();
}
