import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import serializeValueFromDOMNode from 'modules/utils/serializeValueFromDOMNode';
import classnames from 'classnames';
import { dateStringToDateObject, dateObjectToDateString } from 'models/date';

export class DateInput extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        trans: PropTypes.func.isRequired,
        className: PropTypes.string,
        inputVariant: PropTypes.oneOf(['inverse']),
        hasError: PropTypes.bool,
    };

    static defaultProps = {
        inputVariant: null,
    };

    onChange = e => {
        const { name, value } = serializeValueFromDOMNode(e.target);

        const datePartName = name.substring(name.lastIndexOf('-') + 1);

        const newDate = dateStringToDateObject(this.props.value);
        newDate[datePartName] = value;

        this.props.onChange(dateObjectToDateString(newDate));
    };

    render() {
        const { name, trans, disabled, className, hasError, inputVariant } = this.props;
        const { day, month, year } = dateStringToDateObject(this.props.value);

        const inputClasses = classnames('fa-input', {
            'fa-input--error': hasError,
            'fa-input--inverse': inputVariant === 'inverse',
        });

        return (
            <div className={classnames(className, 'dateInput')}>
                <input
                    type="text"
                    id={`${name}-day`}
                    name={`${name}-day`}
                    size="2"
                    maxLength="2"
                    value={day}
                    disabled={disabled}
                    onChange={this.onChange}
                    placeholder={trans('placeholder.day')}
                    className={inputClasses}
                />
                {' / '}
                <input
                    type="text"
                    id={`${name}-month`}
                    name={`${name}-month`}
                    size="2"
                    maxLength="2"
                    value={month}
                    disabled={disabled}
                    onChange={this.onChange}
                    placeholder={trans('placeholder.month')}
                    className={inputClasses}
                />
                {' / '}
                <input
                    type="text"
                    id={`${name}-year`}
                    name={`${name}-year`}
                    size="4"
                    maxLength="4"
                    value={year}
                    disabled={disabled}
                    onChange={this.onChange}
                    placeholder={trans('placeholder.year')}
                    className={inputClasses}
                />
            </div>
        );
    }
}

export default connectToI18n(DateInput);
