import Context from './context.model';
const _contextStorageKey = 'lrqdoContext';
import { getCurrentUser, getAssemblyById } from 'modules/utils/windowReduxStore.js';
import { getRoleInAssembly } from 'models/users.js';
import * as R from 'ramda';

let _context = new Context();

function _getHiveContext(hive) {
    const user = getCurrentUser();
    const role = getRoleInAssembly({ assemblyId: hive.id, user });

    const context = {
        data: {
            hiveId: hive.id,
        },
    };

    // Member
    if (role === 'member') {
        context.type = 'member';
        return context;
    }

    // Leader
    switch (hive.status) {
        case 'init':
            context.type = 'leaderHiveInit';
            return context;

        case 'pre-construct':
            switch (hive.place.status) {
                case 'construction':
                    context.type = 'leaderPreconstruct';
                    return context;
                case 'pending':
                    context.type = 'leaderPendingPlace';
                    return context;
                case 'open':
                    context.type = 'leaderPreconstruct';
                    return context;
                case 'close':
                    context.type = 'leaderRefusedPlace';
                    return context;
                // no default
            }
            break;

        case 'construct':
            context.type = 'leaderConstruct';
            return context;

        case 'open':
            context.type = 'leaderOpen';
            return context;
        default:
            return null;
    }
}

const ContextService = {
    getCurrentContext() {
        if (_context) {
            return _context;
        }
        _context = new Context();
        return _context;
    },

    getDefaultContext(serializedUser) {
        if (serializedUser.isFarmer) {
            return { type: 'farm' };
        }
        if (serializedUser.isLeader || serializedUser.isMember) {
            const assemblies = serializedUser.isLeader
                ? serializedUser.hivesAsLeader
                : serializedUser.hivesAsMember;
            if (assemblies.length) {
                return { type: 'hive', assemblyId: assemblies[0].id };
            }
            return { type: 'profile' };
        }
        if (serializedUser.leaderProfile) {
            return { type: 'questionnaire' };
        }

        return { type: 'profile' };
    },

    resetToDefaultContext() {
        const user = getCurrentUser();

        if (!user || user.anonymous) {
            _context.set({
                type: null,
                data: {},
            });
            return;
        }

        ContextService.retrieve();

        if (_context && _context.get('type')) {
            return;
        }

        const defaultContext = ContextService.getDefaultContext(user);
        ContextService.change(defaultContext.type, defaultContext.assemblyId);
    },

    store(type, hiveId) {
        const user = getCurrentUser();
        if (!sessionStorage || !user || user.anonymous || ['hive', 'farm'].indexOf(type) === -1) {
            return null;
        }

        const data = JSON.stringify({
            type,
            hiveId,
        });

        try {
            sessionStorage.setItem(_contextStorageKey + user.id, data);
        } catch (e) {
            if (e.name === 'QUOTA_EXCEEDED_ERR') {
                return _context;
            }
        }

        return _context;
    },

    retrieve() {
        const user = getCurrentUser();
        if (
            !sessionStorage ||
            !user ||
            user.anonymous ||
            !sessionStorage.getItem(_contextStorageKey + user.id)
        ) {
            return null;
        }

        const data = JSON.parse(sessionStorage.getItem(_contextStorageKey + user.id));

        if (data.hiveId) {
            const id = parseInt(data.hiveId, 10);
            const hive = getAssemblyById(id);

            if (!hive) {
                return null;
            }
        }

        ContextService.change(data.type, data.hiveId);
        return _context;
    },

    change(type, hiveId) {
        const user = getCurrentUser();

        if (!user || user.anonymous) {
            _context.set({
                type: null,
                data: {},
            });
            return;
        }

        if (type === 'hive' && hiveId) {
            const hive = getAssemblyById(parseInt(hiveId, 10));
            if (!hive) {
                ContextService.resetToDefaultContext();
                return;
            }
            _context.set(_getHiveContext(hive));
        } else if (type === 'farm') {
            const farm = R.path(['farm', '0'], user);
            const farmStatusToContextType = {
                init: 'farmInit',
                pending: 'farmPending',
                'validated farm': 'farmOpenInterregional',
            };
            _context.set({
                type: farmStatusToContextType[farm.status],
                data: {
                    invitationsCount: farm.invitationsCount,
                },
            });
        } else if (type === 'profile') {
            _context.set({ type: 'profile', data: {} });
        } else {
            _context.set({ type, data: {} });
        }

        ContextService.store(type, hiveId);
    },
};

export default ContextService;
