export const EUR = 'EUR';
export const GBP = 'GBP';
export const DKK = 'DKK';
export const CHF = 'CHF';

export const currenciesPropTypes = [EUR, GBP, DKK, CHF];

export const currencies = {
    [EUR]: {
        symbol: '€',
        symbolPosition: 'after',
        decimalSeparator: ',',
    },

    [GBP]: {
        symbol: '£',
        symbolPosition: 'before',
        decimalSeparator: '.',
    },

    [DKK]: {
        symbol: 'kr',
        symbolPosition: 'after',
        decimalSeparator: ',',
    },

    [CHF]: {
        symbol: 'CHF',
        symbolPosition: 'after',
        decimalSeparator: '.',
    },
};

// If the app needs to somehow decide on a currency
// user should choose but design doesn't want a select
export function getCurrencyByCountry(countryId) {
    switch (countryId) {
        case 55: // Denmark
            return DKK;
        case 40: // Switzerland
            return CHF;
        case 70: // United Kingdom
        case 90: // Isle of Man
            return GBP;
        default:
            return EUR;
    }
}

export const getCurrencyByLangcode = langcode => {
    switch (langcode) {
        case 'de-CH': // Switzerland
        case 'fr-CH':
            return CHF;
        default:
            return EUR;
    }
};
