import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import useI18n from 'hooks/I18n/useI18n.js';
import { fetchNewsfeed, newsSelector, isFetchingSelector, readNewsfeed } from 'modules/newsfeed';
import NewsList from 'components/Newsfeed/NewsList.jsx';
import { Heading } from 'components/ui';
import { HIVE_RECIPIENT, FARM_RECIPIENT } from 'models/newsfeed.js';

const NewsfeedContainer = ({ recipientType, currentAssemblyId }) => {
    const { trans } = useI18n();
    const dispatch = useDispatch();
    const newsListData = useSelector(newsSelector);
    const isNewsfeedLoading = useSelector(isFetchingSelector);

    useEffect(() => {
        dispatch(fetchNewsfeed(recipientType)).then(() => dispatch(readNewsfeed(recipientType)));
    }, [recipientType, dispatch]);

    if (isNewsfeedLoading && R.isEmpty(newsListData)) {
        return <LoadingAnimation type="spinner" />;
    }

    if (R.isEmpty(newsListData)) {
        return <div className="conversation-emptyText">{trans('messages.list.empty')}</div>;
    }

    return (
        <React.Fragment>
            {isNewsfeedLoading && <LoadingAnimation type="spinner" />}
            <Heading size={2} rank={1} className="u-mb-8">
                {trans('navigation.left.newsfeed')}
            </Heading>
            <NewsList newsListData={newsListData} currentAssemblyId={currentAssemblyId} />
        </React.Fragment>
    );
};

NewsfeedContainer.propTypes = {
    recipientType: PropTypes.oneOf([HIVE_RECIPIENT, FARM_RECIPIENT]).isRequired,
    currentAssemblyId: PropTypes.number,
};

export default NewsfeedContainer;
