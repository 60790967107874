import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Backbone from 'backbone';
import * as R from 'ramda';
import * as localstorage from 'modules/localstorage';
import { get } from 'modules/api';
import { getDeliveryOptionInfo } from 'models/delivery';
import connectToI18n from 'modules/i18n/connectToI18n';
import DeliveryOption from 'components/Baskets/DeliveryOption.jsx';
import { getOptionValue } from 'models/delivery';
import { changeSelectedPickup } from 'modules/delivery';
import 'modules/delivery';
import { getLocalizedDate } from 'modules/utils/dateAndTime';
import { closeAll as closeAllPanels } from 'modules/visiblePanel';
import { getCurrentDistributions } from 'models/distributions';
import { connect } from 'react-redux';
import DeliveryOptionInfo from 'components/DeliveryOptionInfo.jsx';
import { LOADING, READY, PROCESSING } from 'modules/utils/ajaxStatuses';
import { DELIVERY_OFFER } from 'modules/utils/deliveryOptions';
import { Button, Grid, Heading } from 'components/ui';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import StructuredSidePanel from 'components/StructuredSidePanel.jsx';
import LimitOverflowingContentWithEllipsis from 'components/LimitOverflowingContentWithEllipsis.jsx';

export class DeliveryPanel extends React.Component {
    static propTypes = {
        i18n: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
        currentDistributionId: PropTypes.number.isRequired,
        currentAssembly: PropTypes.object.isRequired,
        closeAllPanels: PropTypes.func.isRequired,
        changeSelectedPickup: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    state = {
        selectedOption: '',
        salesWithOptions: [],
        status: LOADING,
        buttonStatus: READY,
    };

    componentDidMount() {
        const salesOpen = getCurrentDistributions(this.props.currentAssembly);
        const salesOptionsRequests = R.map(
            sale => get(`distributions/${sale.distributionId}/pickup-and-delivery-options`),
            salesOpen
        );
        Promise.all(salesOptionsRequests).then(salesOptions => {
            const createSalesWithOptions = (sale, options) => {
                const salesWithOptions = {};
                salesWithOptions.distributionId = sale.distributionId;
                salesWithOptions.distributionDate = sale.distributionDate;
                salesWithOptions.options = options;
                return salesWithOptions;
            };
            const salesWithOptions = R.zipWith(createSalesWithOptions, salesOpen, salesOptions);
            const selectedOption =
                localstorage.getItem(`delivery.option_${this.props.currentDistributionId}`) ||
                `${this.props.currentDistributionId}_assembly`;
            this.setState({
                status: READY,
                buttonStatus: READY,
                selectedOption,
                salesWithOptions,
            });
        });
    }

    onChangeDeliveryOption = deliveryOption => {
        this.setState({
            selectedOption: deliveryOption,
            buttonStatus: READY,
        });
    };

    onSubmitDeliveryOption = () => {
        this.setState({
            buttonStatus: PROCESSING,
        });
        const { distributionId } = getDeliveryOptionInfo(this.state.selectedOption);
        this.props.changeSelectedPickup(distributionId, this.state.selectedOption);
        if (distributionId !== this.props.currentDistributionId.toString()) {
            Backbone.history.navigate(
                `assemblies/${this.props.currentAssembly.id}/collections/${distributionId}/products`,
                true
            );
        } else {
            this.props.closeAllPanels();
            this.setState({
                buttonStatus: READY,
            });
        }
    };

    renderDeliveryOptionList = (options, distributionId) => {
        return (
            <Fragment key={distributionId}>
                {options.map(pickup => {
                    const optionValue = getOptionValue(pickup, distributionId);
                    return (
                        <DeliveryOption
                            className="u-mb-2"
                            key={optionValue}
                            title={pickup.name}
                            value={optionValue}
                            isSelected={this.state.selectedOption === optionValue}
                            onChange={this.onChangeDeliveryOption}
                            deliveryOption={pickup}
                            variant={'block'}
                        >
                            {pickup.meetingPoint && (
                                <DeliveryOptionInfo
                                    timeSlots={pickup.timeSlots}
                                    address={pickup.meetingPoint.fullAddress}
                                />
                            )}

                            {pickup.type === DELIVERY_OFFER && (
                                <LimitOverflowingContentWithEllipsis
                                    background="white"
                                    maxChars={150}
                                >
                                    {pickup.description}
                                </LimitOverflowingContentWithEllipsis>
                            )}
                        </DeliveryOption>
                    );
                })}
            </Fragment>
        );
    };

    render() {
        return (
            <StructuredSidePanel
                onClose={this.props.closeAllPanels}
                className={this.props.className}
                isOpened
                title={this.props.trans('navigation.delivery.panel.title')}
                variant="left"
                actions={
                    <Grid xs="6|6" className="u-center">
                        <Button weight="link" onClick={this.props.closeAllPanels}>
                            {this.props.trans('global.cancel')}
                        </Button>
                        <Button
                            block
                            variant="success"
                            disabled={this.state.buttonStatus !== READY}
                            loading={this.state.buttonStatus === PROCESSING}
                            onClick={this.onSubmitDeliveryOption}
                        >
                            {this.props.trans('global.save')}
                        </Button>
                    </Grid>
                }
            >
                {this.state.status === LOADING ? (
                    <LoadingAnimation type="spinner" />
                ) : (
                    this.state.salesWithOptions.map(saleOptions => {
                        return (
                            <Fragment key={saleOptions.distributionId}>
                                <Heading size={4} className="u-mb-2">
                                    {getLocalizedDate(
                                        this.props.i18n,
                                        'I',
                                        saleOptions.distributionDate
                                    )}
                                </Heading>
                                {this.renderDeliveryOptionList(
                                    saleOptions.options,
                                    saleOptions.distributionId
                                )}
                            </Fragment>
                        );
                    })
                )}
            </StructuredSidePanel>
        );
    }
}

const DeliveryPanelRedux = connect(undefined, {
    closeAllPanels,
    changeSelectedPickup,
})(DeliveryPanel);

export default connectToI18n(DeliveryPanelRedux);
