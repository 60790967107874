import Autolinker from 'autolinker';
import escapeHtml from 'escape-html';
import * as R from 'ramda';

const autolinker = new Autolinker({
    truncate: 21,
    className: 'bypass',
    email: false,
    twitter: false,
});

const carriageReturnRegexp = /\r\n?/g;
const paragraphsRegexp = /\n\n+/g;
const newlineRegexp = /([^\n]\n)(?=[^\n])/g;

export function convertRawTextToHtml(rawText) {
    if (!rawText) {
        return '';
    }

    let html = escapeHtml(rawText);
    html = html.replace(carriageReturnRegexp, '\n'); // \r\n and \r -> \n
    html = html.replace(paragraphsRegexp, '</p>\n\n<p>'); // 2+ newline  -> paragraph
    html = html.replace(newlineRegexp, '$1<br/>'); // 1 newline   -> br
    html = `<p>${html}</p>`;
    html = autolinker.link(html);

    return html;
}

/**
 *
 * @param rawText : a raw text containing urls as pure strings
 * @return the rawText but with truncated Urls strings
 */
export function getRawTextWithTruncatedUrls(rawText) {
    if (!rawText) {
        return '';
    }

    const html = `<span>${autolinker.link(rawText)}</span>`;
    return html.replace(/<\/?[^>]+(>|$)/g, '');
}

const wysiwygRegex = /<\s*(p|strong|ul|li|i|a){1}[^>]*>/gi;
const baliseToBreakRegex = /<\/\s*(p|ul|li)[^<]*>/gi;

const searchWYSIWYGInText = searchText => searchText.search(wysiwygRegex) !== -1;

export const asWysiwygHtml = R.anyPass([searchWYSIWYGInText]);

export const cleanHtmlFromText = text => text.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, ' ');
export const convertEndBaliseIntoBreakline = html =>
    html ? html.replace(baliseToBreakRegex, '\n') : '';

export const convertHtmlToRawText = R.pipe(convertEndBaliseIntoBreakline, cleanHtmlFromText);

export const addBypassRouterToLink = html => {
    const parser = new DOMParser();
    const document = parser.parseFromString(html, 'text/html');
    const elements = document.getElementsByTagName('a');
    for (let i = 0; i < elements.length; i++) {
        elements.item(i).setAttribute('data-bypass', true);
        elements.item(i).setAttribute('target', '_blank');
    }

    return document.body.innerHTML;
};
