import PropTypes from 'prop-types';
import * as R from 'ramda';
import { get } from 'modules/api';

export const STATE_CART = 1;
export const STATE_CART_LOCKED = 2;
export const STATE_PENDING = 3;
export const STATE_CONFIRMED = 4;
export const STATE_SHIPPED = 5;
export const STATE_ABANDONED = 6;
export const STATE_CANCELLED = 7;
export const STATE_RETURNED = 8;
export const STATE_INVALIDATED = 9;

export const cancellationReasons = [
    'order.cancellation.reason.unavailable',
    'order.cancellation.reason.orderWrongProducts',
    'order.cancellation.reason.someProductsNoMoreWanted',
    'order.cancellation.reason.wrongAssembly',
    'order.cancellation.reason.wrongDistributionDate',
    'order.cancellation.reason.doubleOrder',
    'order.cancellation.reason.other',
];

cancellationReasons.propType = PropTypes.oneOf(cancellationReasons).isRequired;

export const isBasket = R.propEq('status', 'basket');
export const isOrder = R.propEq('status', 'order');

export const basketsFirst = R.descend(isBasket);
export const byMostRecentlyCreated = R.descend(R.pipe(R.prop('createdAt'), x => new Date(x)));

export function deduplicatePendingOrders(orders = []) {
    if (!orders.length) {
        return orders;
    }

    const pendingOrders = orders.filter(R.propEq('state', 3));
    if (!pendingOrders.length) {
        return orders;
    }

    const distributionsWithPendingOrders = R.pluck('distributionId', pendingOrders);
    return R.reject(order => {
        return (
            R.propEq('state', 1, order) &&
            R.contains(order.distributionId, distributionsWithPendingOrders)
        );
    }, orders);
}

export const fetchOrdersImpact = orderId => get(`orders/${orderId}/impact`);
