import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export class NavigationRegion extends React.Component {
    static propTypes = {
        position: PropTypes.oneOf(['top', 'bottom', 'left']).isRequired,
        children: PropTypes.node.isRequired,
        absolute: PropTypes.bool,
        legacy: PropTypes.bool,
        sticky: PropTypes.bool,
    };

    render() {
        return (
            <div
                className={classnames(`${this.props.position}Navigation`, {
                    [`${this.props.position}Navigation--absolute`]: this.props.absolute,
                    [`${this.props.position}Navigation--legacy`]: this.props.legacy,
                    [`${this.props.position}Navigation--sticky`]: this.props.sticky,
                })}
            >
                {this.props.children}
            </div>
        );
    }
}

export default NavigationRegion;
