import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { DISLIKE, LIKE, HEART } from './ratingValues.js';
import { Button } from 'components/Button.jsx';
import classnames from 'classnames';

const variants = {
    [DISLIKE]: { icon: require('icons/expressionless-face-outline.svg'), rating: 'dislike' },
    [LIKE]: { icon: require('icons/smiling-face-outline.svg'), rating: 'like' },
    [HEART]: { icon: require('icons/heart-outline.svg'), rating: 'heart' },
};

export class RatingButton extends React.Component {
    static propTypes = {
        value: PropTypes.number.isRequired,
        isSelected: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired,
        trans: PropTypes.func.isRequired,
    };

    onClick = () => {
        this.props.onClick(this.props.value);
    };

    render() {
        const { rating, icon } = variants[this.props.value];
        const { trans, isSelected } = this.props;

        return (
            <Button
                block
                className={classnames(`ratingButton ratingButton--${rating}`, {
                    'ratingButton--isSelected': isSelected,
                })}
                onClick={this.onClick}
                rounded
                weight="outline"
                icon={icon}
            >
                {trans(`products.feedback.rating.${rating}`)}
            </Button>
        );
    }
}

export default connectToI18n(RatingButton);
