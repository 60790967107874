import Cookies from 'js-cookie';
import { send } from 'modules/http';

let _refreshRequest = null;
const _defaultExpireTimeInDays = 7;

const API_ROOT = process.env.API_ROOT;
const OAUTH_ID = process.env.OAUTH_ID;

export function useRefreshToken() {
    if (_refreshRequest === null) {
        const refreshToken = Cookies.get('refresh_token');
        if (!refreshToken) {
            return Promise.reject(new Error('refresh_token_invalid'));
        }
        _refreshRequest = send({
            type: 'POST',
            url: `${API_ROOT}/oauth/v2/token`,
            data: JSON.stringify({
                grant_type: 'refresh_token',
                client_id: OAUTH_ID,
                refresh_token: Cookies.get('refresh_token'),
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(
            res => {
                _refreshRequest = null;
                storeTokens(res);
                return res;
            },
            () => {
                _refreshRequest = null;
                throw new Error('refresh_token_invalid');
            }
        );
    }
    return _refreshRequest;
}

export function getToken(token) {
    return Cookies.get(token);
}

function storeTokens({ refresh_token, access_token }) {
    Cookies.set('auth', refresh_token, { expires: _defaultExpireTimeInDays });
    Cookies.set('refresh_token', refresh_token, { expires: _defaultExpireTimeInDays });
    Cookies.set('access_token', access_token);
}
