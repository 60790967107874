import React from 'react';
import PropTypes from 'prop-types';
import Text from 'components/ProductIdentity/Text.jsx';
import InlineSVG from 'svg-inline-react';
import Link from 'src/components/atoms/Link/Link.jsx';
import classnames from 'classnames';
import Button, { LINK_MODE } from 'src/components/atoms/Button/Button.jsx';

const InfoBox = ({
    headingIcon,
    headingText,
    children,
    className = '',
    footerLinkOnClick = null,
    footerLinkHref = null,
    footerLinkText = null,
    bypassRouter = null,
}) => {
    const isExternalUrl = () => {
        if (footerLinkHref === null) {
            return false;
        }

        return !footerLinkHref.startsWith('/');
    };

    return (
        <div className={classnames('pi-assembly-side-infos-box', className)}>
            <div className="pi-assembly-side-infos-box-heading">
                <InlineSVG src={headingIcon} />
                <Text size="16px" lineHeight="24px" weight={600}>
                    {headingText}
                </Text>
            </div>
            <div className="pi-assembly-side-infos-box-content">{children}</div>
            {footerLinkText && (footerLinkHref || footerLinkOnClick) && (
                <Text
                    size="14px"
                    lineHeight="20px"
                    className="pi-assembly-side-infos-box-footer-link"
                >
                    {footerLinkHref ? (
                        <Link
                            to={footerLinkHref}
                            href={footerLinkHref}
                            bypassRouter={bypassRouter !== null ? bypassRouter : isExternalUrl()}
                            target="_blank"
                        >
                            {footerLinkText}
                        </Link>
                    ) : (
                        <Button onClick={footerLinkOnClick} mode={LINK_MODE}>
                            {footerLinkText}
                        </Button>
                    )}
                </Text>
            )}
        </div>
    );
};

InfoBox.propTypes = {
    headingIcon: PropTypes.string.isRequired,
    headingText: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    footerLinkOnClick: PropTypes.func,
    footerLinkHref: PropTypes.string,
    footerLinkText: PropTypes.string,
    bypassRouter: PropTypes.bool,
};

export default InfoBox;
