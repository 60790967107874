import '../../../app/scripts/polyfills';

import $ from 'jquery';
import React from 'react';
import ReactDom from 'react-dom';
import moment from 'moment';
import * as Translator from 'modules/i18n/Translator';
import TrackerProxy from 'app/scripts/components/proxy/trackerProxy';
import AppChannel from 'modules/channels/App';
import { Provider } from 'react-redux';
import { getDefaultStore } from 'modules/storeUtils.js';
import AssemblyPageContainer from 'components/AssemblyPages/AssemblyPageContainer.jsx';

require('app/scripts/config/loadConfig');
import 'app/scripts/modules/core/coreApp.js';
// Error reporting should be loaded after all external dependencies, but before all lrqdo code.
import { setBundle, enableReporting } from 'modules/error-reporting';
setBundle('assembly-page');
enableReporting();

const trackerProxy = new TrackerProxy(AppChannel.vent);
trackerProxy.start();

Translator.addTranslations(window.pageContext.i18n, window.messages);

$.ajaxSetup({
    headers: {
        'Accept-Language': window.pageContext.i18n,
    },
});

const store = getDefaultStore(window.pageContext.i18n);
window.reduxStore = store;
moment.locale(window.pageContext.i18n);
ReactDom.hydrate(
    <Provider store={store}>
        <AssemblyPageContainer />
    </Provider>,
    document.getElementById('react-app')
);
