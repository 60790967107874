import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

function notEmptyIfChildrenIsEmpty({ children, ariaLabel = '' }) {
    if (ariaLabel.trim() === '' && React.Children.count(children) === 0) {
        return new Error(
            'This checkbox is empty, you should provide an ariaLabel for accessibility'
        );
    }
    return null;
}

export class ToggleSwitch extends React.Component {
    static propTypes = {
        id: PropTypes.number,
        name: PropTypes.string.isRequired,
        isChecked: PropTypes.bool,
        children: PropTypes.node,
        loading: PropTypes.bool,
        onChange: PropTypes.func,
        ariaLabel: notEmptyIfChildrenIsEmpty,
        className: PropTypes.string,
        isDisabled: PropTypes.bool,
    };

    static defaultProps = {
        variant: 'base',
    };

    handleChange = e => {
        if (this.props.onChange) {
            this.props.onChange(this.props.name, e.target.checked);
        }
    };

    render() {
        const {
            id,
            name,
            isChecked,
            children,
            ariaLabel,
            className,
            loading,
            isDisabled,
        } = this.props;

        return (
            <span
                className={classnames(
                    'toggleSwitch',
                    { 'toggleSwitch--loading': loading },
                    className
                )}
            >
                <input
                    type="checkbox"
                    id={id || name}
                    name={name}
                    aria-label={ariaLabel}
                    onChange={this.handleChange}
                    checked={isChecked}
                    disabled={loading || isDisabled}
                />
                <label htmlFor={id || name}>
                    <div className="toggle" />
                    {children && <span>{children}</span>}
                </label>
            </span>
        );
    }
}

export default ToggleSwitch;
