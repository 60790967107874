import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from 'components/Button.jsx';
import Utils from 'modules/utils';
import { SAVING } from 'modules/utils/ajaxStatuses';
import { validate, validators } from '../../../modules/validation';
import Heading from '../../Heading.jsx';
import { TextInputGroup } from '../../ui/form';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import useI18n from 'hooks/I18n/useI18n';

const WysiwygEditor = React.lazy(() => import('components/WysiwygEditor.jsx'));

const { filerPhoto } = Utils;

const NewDiscussion = ({ author, saveNewDiscussion, status }) => {
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [showErrors, setShowErrors] = useState(false);
    const [errors, setErrors] = useState(null);
    const { trans } = useI18n();

    const onInputContent = (name, value) => {
        setContent(value);
    };

    const onInputTitle = (name, value) => {
        setTitle(value);
    };

    const validateForm = () => {
        const { assertNotBlank } = validators(trans);
        const requiredFields = ['content', 'title'];

        return validate.all(requiredFields.map(field => validate.prop(field, assertNotBlank)))({
            content,
            title,
        });
    };

    const onSave = e => {
        e.preventDefault();
        const [isValid, newErrors] = validateForm();

        if (isValid) {
            setShowErrors(false);
        } else {
            setShowErrors(true);
            setErrors(newErrors);

            return;
        }

        if (content !== '') {
            saveNewDiscussion(content, title);
        }

        setTitle('');
        setContent('');
    };

    const authorPicture = filerPhoto(author.photoId, 'small', 'user');

    return (
        <form className="new-message">
            <Heading size={2}>{trans('hives.discussions.start')}</Heading>
            <div className="message_editor">
                <img className="image" src={authorPicture} height="48" width="48" />
                <div className="message-input">
                    <TextInputGroup
                        name="title"
                        type="text"
                        className="text message-header fa-input--block u-mb-2 sale-message-subject"
                        noMargin
                        onChange={onInputTitle}
                        value={title}
                        showErrors={showErrors}
                        clientErrors={errors?.title}
                        maxLength={70}
                    />
                    <div className="message-content">
                        <React.Suspense fallback={<LoadingAnimation type="spinner" />}>
                            <WysiwygEditor
                                content={content}
                                editContent={onInputContent}
                                showErrors={showErrors}
                                errors={errors?.content}
                            />
                        </React.Suspense>
                    </div>
                </div>
            </div>
            <div className="u-right">
                <Button variant="success" onClick={onSave} loading={status === SAVING}>
                    {trans('discussions.sendToAll')}
                </Button>
            </div>
        </form>
    );
};

NewDiscussion.propTypes = {
    author: PropTypes.object.isRequired,
    saveNewDiscussion: PropTypes.func,
    status: PropTypes.string.isRequired,
};

export default NewDiscussion;
