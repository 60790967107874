import * as R from 'ramda';
import { SWITCH_ASSEMBLY, SWITCH_DISTRIBUTION, UPDATE_THEME, UPDATE_ACTIVE_LINK } from './actions';

export const navigationReducer = (
    state = {
        assembly: null,
        distribution: null,
        activeLink: '',
    },
    { type, payload }
) => {
    switch (type) {
        case SWITCH_ASSEMBLY:
            return {
                ...state,
                assembly: payload.assembly,
                distribution: null,
            };
        case SWITCH_DISTRIBUTION:
            return {
                ...state,
                assembly: payload.assembly,
                distribution: payload.distribution,
            };
        case UPDATE_THEME:
            return R.evolve({
                assembly: {
                    theme: R.always(payload.theme),
                },
            })(state);
        case UPDATE_ACTIVE_LINK:
            return R.evolve({
                activeLink: R.always(payload.activeLink),
            })(state);
        default:
            return state;
    }
};
