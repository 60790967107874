import { get, post } from 'modules/api';
import * as R from 'ramda';
import { del, patch } from 'miniruche/services/api';

export const getAssemblyDiscussions = (assemblyId, page) =>
    get(`hives/${assemblyId}/discussions`, { page })
        .then(R.prop('discussions'))
        .then(R.defaultTo([]));

export const getAssemblyDiscussionsWithCounters = (assemblyId, page) =>
    get(`hives/${assemblyId}/discussions`, { page });

export const createDiscussionInAssembly = (assemblyId, content, title) =>
    post('discussions/', {
        hive: assemblyId,
        messages: [{ content, title }],
    });

export const addMessageToDiscussion = (discussionUuid, content) =>
    post(`discussions/${discussionUuid}/messages`, { content });

export const deleteDiscussion = (hiveId, discussionUuid) =>
    del(`hives/${hiveId}/discussions/${discussionUuid}`)
        .then(R.prop('discussions'))
        .then(R.defaultTo([]));

export const deleteMessageFromDiscussion = (hiveId, discussionId, messageId) =>
    del(`hives/${hiveId}/discussions/${discussionId}/${messageId}`)
        .then(R.prop('discussions'))
        .then(R.defaultTo([]));

export const restoreDiscussion = (hiveId, discussionId) =>
    patch(`hives/${hiveId}/discussions/${discussionId}/restore`)
        .then(R.prop('discussions'))
        .then(R.defaultTo([]));

export const restoreMessageFromDiscussion = (hiveId, discussionId, messageId) =>
    patch(`hives/${hiveId}/discussions/${discussionId}/${messageId}/restore`)
        .then(R.prop('discussions'))
        .then(R.defaultTo([]));
