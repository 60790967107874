import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export class RadioButton extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        isSelected: PropTypes.bool,
        value: PropTypes.string.isRequired,
        inputId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        children: PropTypes.node,
        variant: PropTypes.oneOf(['button', 'circle', 'pill']),
        inverse: PropTypes.bool,
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        details: PropTypes.node,
    };

    static defaultProps = {
        inverse: false,
        variant: 'button',
    };

    onChange = e => {
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    };

    stopClickPropagation = event => {
        event.stopPropagation();
    };

    render() {
        const {
            isSelected,
            className,
            inputId,
            inverse,
            name,
            value,
            children,
            variant,
            disabled,
            details,
        } = this.props;

        if (variant === 'circle') {
            // Legacy markup
            return (
                <div className={className}>
                    <input
                        disabled={disabled}
                        checked={isSelected}
                        className="fa-radio"
                        id={inputId}
                        name={name}
                        onChange={this.onChange}
                        type="radio"
                        value={value}
                    />
                    <label htmlFor={inputId} onClick={this.stopClickPropagation}>
                        {children}
                    </label>
                    {details && <div className="fa-radio-details">{details}</div>}
                </div>
            );
        }

        return (
            <label
                className={classnames(
                    {
                        button: variant === 'button',
                        'fa-input-pill': variant === 'pill',
                        'fa-input-pill--radio': variant === 'pill',
                        'fa-input--inverse': inverse,
                        'is-selected': isSelected,
                        disabled,
                    },
                    className
                )}
                onClick={this.stopClickPropagation}
            >
                <input
                    disabled={disabled}
                    checked={isSelected}
                    className="fa-radio"
                    id={inputId}
                    name={name}
                    onChange={this.onChange}
                    type="radio"
                    value={value}
                />
                {children}
            </label>
        );
    }
}

export default RadioButton;
