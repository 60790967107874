import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import useI18n from 'hooks/I18n/useI18n';
import useResponsive from 'hooks/Navigation/useResponsive';

import { getFormattedQuantity, getFormattedPricePerQuantity } from 'models/offers';

import { validateOrder } from 'modules/orders';
import Utils from 'modules/utils';

import Modal from 'src/components/organisms/Modal/Modal.jsx';
import Button, { SMALL_SIZE } from 'src/components/atoms/Button/Button.jsx';
import Text from 'components/ProductIdentity/Text.jsx';

const { filerPhoto } = Utils;

const getItem = (data, i18n) => {
    const { basketItem, originalBasketItem } = data;
    const hasQuantityChanged = data.previousQuantity !== data.newQuantity;
    return {
        id: hasQuantityChanged ? data.id : basketItem.id,
        photo: hasQuantityChanged ? data.productPhotoId : basketItem.product.photo.id,
        name: hasQuantityChanged ? data.name : basketItem.product.name,
        formattedQuantity: getFormattedQuantity(
            hasQuantityChanged ? data.offer : basketItem.offer,
            {
                strategy: hasQuantityChanged ? data.quantityStrategy : basketItem.quantityStrategy,
                locale: i18n,
            }
        ),
        formattedPricePerQuantity: getFormattedPricePerQuantity(
            hasQuantityChanged ? data.offer : basketItem.offer,
            {
                strategy: hasQuantityChanged ? data.quantityStrategy : basketItem.quantityStrategy,
                locale: i18n,
            }
        ),
        previousValue: hasQuantityChanged
            ? `x ${data.previousQuantity}`
            : originalBasketItem.totalPrice,
        newValue: hasQuantityChanged ? `x ${data.newQuantity}` : basketItem.totalPrice,
    };
};

const BasketChangedModal = props => {
    const {
        isOpened,
        closeModal,
        replacementItems,
        removedItems,
        hasDeliveryOptions,
        orderId,
    } = props;

    const { trans, i18n } = useI18n();
    const isSmallWidth = useResponsive();
    const dispatch = useDispatch();

    const handleValidateOrder = useCallback(
        () => dispatch(validateOrder(orderId, hasDeliveryOptions)),
        [dispatch, orderId, hasDeliveryOptions]
    );

    const renderItem = useCallback(
        removedItem => {
            const item = getItem(removedItem, i18n);
            return (
                <div key={item.id} className="basket-changed-item-modal">
                    <img src={filerPhoto(item.productPhotoId, 'small', 'productIcon')} />
                    <div className="basket-changed-item-infos">
                        <Text size="14px" lineHeight="20px" color="gray2">
                            {item.name}
                        </Text>
                        <Text size="14px" lineHeight="20px" color="gray2">
                            {item.formattedQuantity}
                        </Text>
                        <Text
                            className="basket-changed-item-price-per-quantity"
                            size="12px"
                            lineHeight="14px"
                            color="gray9"
                        >
                            {item.formattedPricePerQuantity}
                        </Text>
                    </div>
                    <div className="basket-changed-item-modify-content">
                        <Text size="14px" lineHeight="20px" color="red4">
                            {item.previousValue}
                        </Text>
                        <Text size="16px" lineHeight="24px" color="gray2">
                            {item.newValue}
                        </Text>
                    </div>
                </div>
            );
        },
        [i18n]
    );

    return (
        <Modal
            withFixedHeight={isSmallWidth}
            isMounted={isOpened}
            closeModal={closeModal}
            title={trans('basket.BasketChanged.mainTitle')}
            noPadding
            footer={
                <Button
                    className="basket-change-price-qty-button"
                    size={SMALL_SIZE}
                    onClick={handleValidateOrder}
                >
                    {hasDeliveryOptions
                        ? trans('basket.selectDelivery')
                        : trans('basket.stockAlert.validateBasket')}
                </Button>
            }
        >
            <div className="basket-changed-stock-price-container">
                <div className="basket-changed-stock-price-content">
                    {replacementItems.length > 0 && (
                        <>
                            <Text
                                size="16px"
                                lineHeight="24px"
                                color="gray2"
                                dangerouslySetInnerHTML={{
                                    __html: trans('basket.BasketChanged.replacementLabel'),
                                }}
                            ></Text>
                            {replacementItems.map(renderItem)}
                        </>
                    )}
                    {removedItems.length > 0 && (
                        <>
                            <Text
                                size="16px"
                                lineHeight="24px"
                                color="gray2"
                                dangerouslySetInnerHTML={{
                                    __html: trans('basket.stockAlert.title'),
                                }}
                            ></Text>
                            {removedItems.map(renderItem)}
                        </>
                    )}
                    <Text size="16px" lineHeight="24px" color="gray2">
                        {trans('basket.stockAlert.body')}
                    </Text>
                </div>
            </div>
        </Modal>
    );
};
BasketChangedModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpened: PropTypes.bool,
    replacementItems: PropTypes.array.isRequired,
    removedItems: PropTypes.array.isRequired,
    hasDeliveryOptions: PropTypes.bool,
    orderId: PropTypes.number,
};

export default BasketChangedModal;
