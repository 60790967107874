import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { getLocalizedDate } from 'modules/utils/dateAndTime';

export const Date = ({ date, format = 'D', i18n }) =>
    date ? <span>{getLocalizedDate(i18n, format, date)}</span> : null;

Date.propTypes = {
    date: PropTypes.string,
    format: PropTypes.string,
    i18n: PropTypes.string.isRequired,
};

export default connectToI18n(Date);
