import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { fetchUnreadMessages } from 'modules/privateConversations';
import Badge from 'components/Badge.jsx';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { unreadMessagesSelector, allThreadsReadSelector } from 'modules/privateConversations';

const MessagesLink = ({ trans }) => {
    const dispatch = useDispatch();
    const unreadMessages = useSelector(unreadMessagesSelector);
    const allThreadsRead = useSelector(allThreadsReadSelector);

    useEffect(() => {
        dispatch(fetchUnreadMessages());
    }, [dispatch]);

    const badgeValue = allThreadsRead ? null : R.values(unreadMessages).length;

    return (
        <Link to="/messages" className="topNavigation-link">
            <span className="icon icon-envelope-alt" />
            {trans('navigation.top.messages')}
            {badgeValue > 0 && <Badge>{badgeValue}</Badge>}
        </Link>
    );
};

MessagesLink.propTypes = {
    trans: PropTypes.func.isRequired,
};

export default MessagesLink;
