import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { BoxSection, Grid, Heading, Text } from 'components/ui';
import MediaObject from 'components/MediaObject.jsx';
import { filerPhoto } from 'modules/utils/filer';
import RatingCommentSection from './RatingCommentSection.jsx';
import RatingButton from './RatingButton.jsx';
import { DISLIKE, LIKE, HEART } from './ratingValues.js';
import ProductImage from 'components/Sale/ProductImage.jsx';
import * as R from 'ramda';
import FeedbackMessage from 'components/ProducerDashboard/FeedbackMessage.jsx';
import okIcon from 'icons/ok.svg';
import connectToI18n from 'modules/i18n/connectToI18n';

export class ProductCard extends React.Component {
    static propTypes = {
        product: PropTypes.shape({
            identifier: PropTypes.string.isRequired,
            rating: PropTypes.number,
            product: PropTypes.string.isRequired,
            product_photo: PropTypes.string,
            farm: PropTypes.string.isRequired,
            farmer: PropTypes.string.isRequired,
            farm_photo: PropTypes.string,
            photourl: PropTypes.string,
            comment: PropTypes.string,
        }).isRequired,
        onRate: PropTypes.func.isRequired,
        trans: PropTypes.func.isRequired,
        userPhotoId: PropTypes.string,
    };

    state = {
        // comment section should never be displayed at init
        commentSectionVisible: false,
    };

    onChooseRating = rating => {
        if (rating === this.props.product.rating) {
            return;
        }

        this.props.onRate(this.props.product.identifier, rating);
        this.setState({
            commentSectionVisible: true,
        });
    };

    onComment = comment => {
        const { identifier, rating } = this.props.product;
        this.props.onRate(identifier, rating, comment);
    };

    render() {
        const { product } = this.props;
        const { rating, product: productName, farm: farmName } = product;
        const photoUrl = filerPhoto(product.product_photo, 'small', 'productCategory');
        const isCommented = !R.isNil(product.comment);
        const displayComment = isCommented && product.comment !== '';
        const isCommentSectionVisible = this.state.commentSectionVisible && !isCommented;

        return (
            <Fragment>
                <BoxSection bordered>
                    <MediaObject
                        media={
                            <ProductImage
                                src={photoUrl}
                                className="hostSaleFollow-productImage"
                                productName={productName}
                                size="xsmall"
                            />
                        }
                        size="xsmall"
                        className="u-mb-4"
                    >
                        <Heading size={3} className="productFeedback--productName">
                            {productName}
                        </Heading>
                        <Text color="info">{farmName}</Text>
                    </MediaObject>
                    <Grid xs="4|4|4" className="u-mb-4">
                        <RatingButton
                            value={DISLIKE}
                            onClick={this.onChooseRating}
                            isSelected={rating === DISLIKE}
                        />
                        <RatingButton
                            value={LIKE}
                            onClick={this.onChooseRating}
                            isSelected={rating === LIKE}
                        />
                        <RatingButton
                            value={HEART}
                            onClick={this.onChooseRating}
                            isSelected={rating === HEART}
                        />
                    </Grid>
                    {displayComment && (
                        <FeedbackMessage
                            comment={this.props.product.comment}
                            userPhotoId={this.props.userPhotoId}
                            commentInfo={
                                <Text color="info" icon={okIcon}>
                                    {this.props.trans('products.feedback.comment.sent')}
                                </Text>
                            }
                        />
                    )}
                </BoxSection>
                {isCommentSectionVisible && (
                    <RatingCommentSection
                        product={this.props.product}
                        onSendingComment={this.onComment}
                    />
                )}
            </Fragment>
        );
    }
}

export default connectToI18n(ProductCard);
