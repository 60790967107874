import * as R from 'ramda';

export const isStockIntoAlertZone = stockAvailable => stockAvailable > 0 && stockAvailable < 6;

export const sanitizeStock = stock => (stock === 'unlimited' ? Infinity : stock);

const sanitizedStocks = product =>
    R.map(sanitizeStock, R.pluck('availableStock', product.offers || []));

export const totalAvailableStock = product => R.sum(sanitizedStocks(product));

export const hasAvailableStock = product => totalAvailableStock(product) !== 0;
