import AppChannel from 'modules/channels/App.js';
import { currentAssemblySelector } from 'modules/navigation/index.js';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { connect } from 'react-redux';
import { isOrderItemLoading, getQuantityInBasket } from 'modules/orders';
import PricePerQuantity from 'components/Offers/PricePerQuantity.jsx';
import QuantityEquivalent from 'components/Offers/QuantityEquivalent.jsx';
import Quantity from 'components/Offers/Quantity.jsx';
import PriceAndPromotion from 'components/Sale/PriceAndPromotion.jsx';
import AddToCart from 'components/AddToCart.jsx';
import Text from 'components/Text.jsx';
import Legend from 'components/Legend.jsx';
import { getFormattedQuantity } from 'models/offers';
import { isStockIntoAlertZone } from 'modules/stockUtils';
import PurchaseButton from './PurchaseButton.jsx';
import classnames from 'classnames';

export class Offer extends React.Component {
    static propTypes = {
        offer: PropTypes.object.isRequired,
        addOfferToBasket: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isSmallWidth: PropTypes.bool,
        product: PropTypes.object.isRequired,
        quantityInBasket: PropTypes.number.isRequired,
        displayPricePerQuantity: PropTypes.bool,
        transChoice: PropTypes.func.isRequired,
        actionOrigin: PropTypes.string,
        classNames: PropTypes.string,
        assembly: PropTypes.object.isRequired,
    };

    addToBasket = () => {
        this.props.addOfferToBasket(
            this.props.offer.id,
            this.props.quantityInBasket + 1,
            this.props.actionOrigin
        );

        AppChannel.vent.trigger(
            'analytics:cart:add',
            this.props.product,
            this.props.offer,
            this.props.assembly
        );
    };

    removeFromBasket = () => {
        this.props.addOfferToBasket(
            this.props.offer.id,
            this.props.quantityInBasket - 1,
            this.props.actionOrigin
        );
    };

    render() {
        const {
            addOfferToBasket,
            displayPricePerQuantity,
            i18n,
            isLoading,
            isSmallWidth,
            offer,
            product,
            quantityInBasket,
            classNames,
            transChoice,
        } = this.props;

        const isStockExhausted = offer.availableStock <= 0;

        return (
            <div className={classnames(classNames)}>
                <div className="u-flexible-row highlightedProducts-offer">
                    {displayPricePerQuantity ? (
                        <Fragment>
                            <div className="highlightedProducts-productPrice">
                                <Text size="large">
                                    {getFormattedQuantity(offer, {
                                        strategy: product.type.quantityStrategy,
                                        locale: i18n,
                                    })}
                                </Text>
                                <Text>
                                    <QuantityEquivalent
                                        offer={offer}
                                        strategy={product.type.quantityStrategy}
                                    />
                                </Text>
                                {isStockIntoAlertZone(offer.availableStock) && (
                                    <Text color="danger">
                                        {transChoice('salePage.stockAlert', offer.availableStock, {
                                            '%stockAvailable%': offer.availableStock,
                                        })}
                                    </Text>
                                )}
                            </div>
                            <div>
                                <PriceAndPromotion
                                    className="u-font-weight-bold highlightedProducts-price"
                                    offer={offer}
                                    displayColumnReverseOnMobile
                                />
                                <Legend className="highlightedProducts-productQuantity">
                                    <PricePerQuantity
                                        offer={offer}
                                        strategy={product.type.quantityStrategy}
                                    />
                                </Legend>
                            </div>
                            <div className="productCard-addToCart">
                                <AddToCart
                                    addOfferToBasket={addOfferToBasket}
                                    offers={[offer]}
                                    quantity={quantityInBasket}
                                    onAdd={this.addToBasket}
                                    onRemove={this.removeFromBasket}
                                    isStockExhausted={isStockExhausted}
                                    isLoading={isLoading}
                                />
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="highlightedProducts-productPrice">
                                <PriceAndPromotion
                                    className="highlightedProducts-productPriceNumber"
                                    offer={offer}
                                    displayColumnReverseOnMobile
                                />
                                <Text className="highlightedProducts-productQuantity">
                                    <Quantity
                                        offer={offer}
                                        strategy={product.type.quantityStrategy}
                                        i18n={i18n}
                                    />
                                </Text>
                            </div>
                            <PurchaseButton
                                offerId={offer.id}
                                addOfferToBasket={addOfferToBasket}
                                isLoading={isLoading}
                                isSmallWidth={isSmallWidth}
                                isStockExhausted={isStockExhausted}
                                quantityInBasket={quantityInBasket}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, { distributionId, offer }) {
    return {
        quantityInBasket: getQuantityInBasket(state, { distributionId, offerId: offer.id }),
        isLoading: isOrderItemLoading(state, { distributionId, offerId: offer.id }),
        assembly: currentAssemblySelector(state),
    };
}

export default connect(mapStateToProps)(connectToI18n(Offer));
