import AppChannel from 'modules/channels/App';
import Marionette from 'backbone.marionette';
import _ from 'underscore';
import rootView from '../views/_base/appLayoutView';

const BaseController = Marionette.Controller.extend({
    constructor(options = {}) {
        if (options === null) {
            options = {};
        }
        this.region = options.region || rootView.main;
        this._instanceId = _.uniqueId('controller');
        if (process.env.NODE_ENV === 'development') {
            AppChannel.commands.execute('register:instance', this, this._instanceId);
        }
        Marionette.Controller.prototype.constructor.call(this, options);
    },

    initialize() {},

    onDestroy() {
        this.region = null;
        this.options = null;
        this._mainView = null;
        if (process.env.NODE_ENV === 'development') {
            AppChannel.commands.execute('unregister:instance', this, this._instanceId);
        }
    },

    show(view, _options) {
        const options = _options || {};
        _.defaults(options, {
            region: this.region,
        });
        this._setMainView(view);
        this._manageView(view, options);
    },

    _setMainView(view) {
        if (this._mainView) {
            return;
        }
        this._mainView = view;
        this.listenTo(view, 'destroy', function() {
            this.triggerMethod('destroy');
        });
    },

    _manageView(view, options) {
        options.region.show(view);
    },

    /**
     * Show help overlay
     * @param  {boolean}   force      if true, ignore localStorage preferences for this help module
     * @param  {string} moduleName the help module to trigger. If omitted, will use the standard name (the current route)
     */
    triggerHelp(force, eventName) {
        AppChannel.commands.execute('help:show', force, eventName);
    },
});

export default BaseController;
