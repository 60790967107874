import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
    grant,
    isPasswordGrantSuspended,
    passwordGrantSuspensionDelayInMilliseconds,
    hasPasswordGrantFailedTwice,
} from 'modules/oauth';
import { fetchOrdersAndValidateBasket } from 'modules/orders';
import { dispatchLoadCurrentUser } from 'modules/utils/windowReduxStore';
import { captureException } from 'modules/error-reporting';

import { claimAnonymousBasket } from 'api/baskets';

import useI18n from 'hooks/I18n/useI18n';

import Heading from 'components/Heading.jsx';
import Text from 'components/ProductIdentity/Text.jsx';

import FormLoginCheckout from 'components/Checkout/Anonymous/ProductIdentity/FormLoginCheckout.jsx';
import LayoutCheckoutPayment from 'components/Checkout/Anonymous/LayoutCheckoutPayment.jsx';
import Link from 'src/components/atoms/Link/Link.jsx';
import { STEP_INFORMATIONS_ANONYMOUS } from 'components/Checkout/ProductIdentity/CheckoutTimeline.jsx';

const Login = () => {
    const [isLoginSuspended, setIsLoginSuspended] = useState(false);
    const [showForgotPasswordNotice, setShowForgotPasswordNotice] = useState(false);
    const suspensionTimeoutId = useRef();
    const basket = useSelector(state => state.checkout.basket);
    const dispatch = useDispatch();
    const { trans } = useI18n();
    const methods = useForm();
    const { handleSubmit, setError, watch } = methods;
    const [emailValue, passwordValue] = watch(['email', 'plainPassword']);

    const onSubmit = useCallback(
        formData => {
            const { email, plainPassword } = formData;
            grant('password', {
                username: email,
                password: plainPassword,
            }).then(
                () =>
                    claimAnonymousBasket(basket.uuid).then(() => {
                        dispatchLoadCurrentUser({
                            skipRedirection: true,
                        }).then(() => {
                            dispatch(fetchOrdersAndValidateBasket(basket.uuid));
                        });
                    }),
                error => {
                    const loginError = error.status === 400;
                    const localIsLoginSuspended = isPasswordGrantSuspended();
                    setIsLoginSuspended(localIsLoginSuspended);
                    setShowForgotPasswordNotice(!isLoginSuspended && hasPasswordGrantFailedTwice());
                    const errorMessage =
                        loginError || localIsLoginSuspended
                            ? 'session.loginForm.invalidPassword'
                            : 'documents.upload.errorDuringUpload';
                    setError('email', { message: trans(errorMessage) });

                    if (localIsLoginSuspended) {
                        suspensionTimeoutId.current = setTimeout(() => {
                            setIsLoginSuspended(false);
                        }, passwordGrantSuspensionDelayInMilliseconds);
                    }
                    if (!loginError) {
                        captureException(new Error(errorMessage));
                    }
                }
            );
        },
        [trans, setError, basket, isLoginSuspended, dispatch]
    );

    useEffect(() => {
        return () => clearTimeout(suspensionTimeoutId.current);
    }, []);

    return (
        <div className="nd-checkout-login-account-page">
            <FormProvider {...methods}>
                <LayoutCheckoutPayment
                    onClick={handleSubmit(onSubmit)}
                    deliveryAmount={basket.shippingFees.amount}
                    finalAmount={basket.totalPrice.amount}
                    amount={basket.totalPriceWithoutShipping.amount}
                    currency={basket.totalPrice.currency}
                    buttonLabel={trans('deliveryPage.summary.continue')}
                    disabled={!emailValue || !passwordValue}
                    currentStep={STEP_INFORMATIONS_ANONYMOUS}
                    distributionId={basket.distribution.id}
                    assemblyId={basket.distribution.hiveId}
                >
                    <div className="nd-checkout-login-account-header">
                        <Heading productIdentity size={4}>
                            {trans('login.title')}
                        </Heading>
                        <Text size="16px" lineHeight="24px" weight="400" color="gray2">
                            {trans('login.firstConnexion')}
                            <Link to="account">{trans('login.createAccount')}</Link>
                        </Text>
                    </div>
                    <FormLoginCheckout
                        showForgotPasswordNotice={showForgotPasswordNotice}
                        isLoginSuspended={isLoginSuspended}
                    />
                </LayoutCheckoutPayment>
            </FormProvider>
        </div>
    );
};

Login.propTypes = {};
export default Login;
