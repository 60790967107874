import React from 'react';
import { debounce } from 'underscore';
import PropTypes from 'prop-types';
import { searchProducts, setSearchString } from 'modules/productsSearch';
import { connect } from 'react-redux';
import { getSearchString } from 'modules/productsSearch';

import SearchBar from './SearchBar.jsx';

const propTypes = {
    closePanel: PropTypes.func,
    distributionId: PropTypes.number.isRequired,
    isSmallWidth: PropTypes.bool,
    searchProducts: PropTypes.func.isRequired,
    searchString: PropTypes.string.isRequired,
    setSearchString: PropTypes.func.isRequired,
};

export class SearchPanel extends React.Component {
    onValueChange = value => {
        this.props.setSearchString(this.props.distributionId, value);
        this.doProductsSearch();
    };

    doProductsSearch = debounce(function search() {
        this.props.searchProducts(this.props.distributionId, this.props.searchString);
    }, 300);

    render() {
        const { closePanel, isSmallWidth, searchString } = this.props;

        return (
            <SearchBar
                closePanel={closePanel}
                isSmallWidth={isSmallWidth}
                onChange={this.onValueChange}
                value={searchString}
            />
        );
    }
}

SearchPanel.propTypes = propTypes;

function mapStateToProps(state, { distributionId }) {
    return {
        searchString: getSearchString(state, { distributionId }),
    };
}

export default connect(mapStateToProps, {
    searchProducts,
    setSearchString,
})(SearchPanel);
