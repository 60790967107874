import PropTypes from 'prop-types';
import React from 'react';
import InlineSVG from 'svg-inline-react';
import classnames from 'classnames';

const Icon = props => {
    const { src, className, color, size, verticalAlign, raw, ...rest } = props;

    const classNames = classnames(
        'nd-icon',
        `nd-icon-${src}`,
        `nd-icon--${verticalAlign}`,
        `nd-icon--${size}`,
        {
            [`nd-icon--${color}`]: color,
        },
        className
    );

    const isSVGIcon = src.slice(0, 4) === '<svg';
    if (isSVGIcon) {
        return <InlineSVG raw={raw} className={`svgIcon ${classNames}`} src={src} {...rest} />;
    }

    return <i className={classNames} aria-hidden="true" {...rest} />;
};

Icon.propTypes = {
    src: PropTypes.string.isRequired,
    className: PropTypes.string,
    color: PropTypes.oneOf([
        'info',
        'maroon',
        'danger',
        'success',
        'warning',
        'lavanda',
        'alpha',
        'white',
        'turquoise2',
        'gray2',
        'error',
    ]),
    size: PropTypes.oneOf([
        'xxxx-large',
        'xxx-large',
        'xx-large',
        'x-large',
        'large',
        'medium',
        'small',
        's-small',
    ]),
    verticalAlign: PropTypes.oneOf(['top', 'middle']),
    raw: PropTypes.bool,
};

Icon.defaultProps = {
    size: 'medium',
    verticalAlign: 'middle',
    raw: false,
};

export default Icon;
