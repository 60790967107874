import PropTypes from 'prop-types';
import React from 'react';
import useI18n from 'hooks/I18n/useI18n';

import HelpCenterSearch from './HelpCenterSearch.jsx';
import { Heading, Link, Paragraph, Separator } from 'components/ui';
import HelpCenterContactCard from './HelpCenterContactCard.jsx';
import { propType as statusPropType } from 'modules/utils/ajaxStatuses';
import { USER, HOST } from 'modules/utils/userTypes';
import { getLocaleByCode, getLocaleShortform } from 'modules/utils/locales';

const HelpCenter = props => {
    const {
        searchValue,
        onSearch,
        loadingArticles,
        articles,
        promotedArticles,
        userType,
        status,
        supportContact,
    } = props;
    const { trans, i18n } = useI18n();

    const localeConfig = getLocaleByCode(i18n);

    return (
        <div className="helpCenter u-p-0">
            <Heading className="u-mb-4" size={4}>
                {trans('menu.help.search')}
            </Heading>
            <HelpCenterSearch
                articles={articles}
                searchValue={searchValue}
                onSearch={onSearch}
                loadingArticles={loadingArticles}
                promotedArticles={promotedArticles}
                userType={userType}
                status={status}
            />
            {userType !== USER && (
                <footer className="helpCenter-footer">
                    <Separator spacing="large" className="helpCenter-separator" />
                    <Paragraph size="large" className="u-mb-4">
                        {trans('helpCenter.contact.label')}
                        {userType === HOST && getLocaleShortform(localeConfig) !== 'fr' && (
                            <div>
                                {trans('helpCenter.contact.label.support.contact')}
                                <Link
                                    variant="primary"
                                    href={`mailto:${localeConfig.supportEmail}`}
                                    data-bypass="true"
                                >
                                    {localeConfig.supportEmail}
                                </Link>
                            </div>
                        )}
                    </Paragraph>
                    <HelpCenterContactCard
                        userType={userType}
                        onMessage={props.onMessage}
                        {...supportContact}
                    />
                </footer>
            )}
        </div>
    );
};

HelpCenter.propTypes = {
    supportContact: PropTypes.func,
    searchValue: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    onMessage: PropTypes.func.isRequired,
    loadingArticles: PropTypes.func.isRequired,
    articles: PropTypes.array.isRequired,
    promotedArticles: PropTypes.array.isRequired,
    userType: PropTypes.string.isRequired,
    status: statusPropType,
};
export default HelpCenter;
