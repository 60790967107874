import React from 'react';
import SaleBanner from './SaleBanner.jsx';

const ChristmasBanner = ({ isSmallWidth }) => {
    const text = {
        smartphone: {
            title: 'christmas.banner.smartphone.title',
            text: 'christmas.banner.smartphone.text',
        },
        desktop: {
            title: 'christmas.banner.desktop.title',
            text: 'christmas.banner.desktop.text',
        },
    };

    return (
        <SaleBanner
            url="/giftcards"
            isSmallWidth={isSmallWidth}
            illustration="/assets/images/illustrations/ChristmasCard.svg"
            backgroundImg="/assets/images/banners/christmas-banner.svg"
            color="#fff"
            text={text}
        />
    );
};

export default ChristmasBanner;
