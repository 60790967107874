import React from 'react';
import PropTypes from 'prop-types';
import connectToI18n from 'modules/i18n/connectToI18n';
import { computeFees, isZeroFee } from 'modules/orders/fees';
import Price from 'components/Price.jsx';
import useI18n from 'hooks/I18n/useI18n.js';

const DeliveryOptionPrice = ({ deliveryOption, totalPrice }) => {
    const { trans } = useI18n();

    if (deliveryOption?.fee?.rate > 0) {
        if (totalPrice) {
            return <Price price={computeFees(totalPrice, deliveryOption.fee)} />;
        }
        return trans('global.fees', { '%rate%': `${deliveryOption.fee.rate * 100}%` });
    }

    if (deliveryOption.fee && !isZeroFee(deliveryOption.fee)) {
        return <Price price={computeFees(null, deliveryOption.fee)} />;
    }

    if (deliveryOption.type === 'deliveryOffer' && deliveryOption.isPromotion) {
        return (
            <div className="promoted-price-container">
                <div className="promoted-price">
                    <Price
                        price={deliveryOption.originalPrice}
                        className="u-lineThrough u-font-weight-normal u-mr-1"
                    />
                    <Price price={deliveryOption.price} className="promotionPrice" />
                </div>
            </div>
        );
    }

    if (deliveryOption.price && deliveryOption.price.amount > 0) {
        return <Price price={deliveryOption.price} />;
    }

    return trans('global.free');
};

DeliveryOptionPrice.propTypes = {
    deliveryOption: PropTypes.object.isRequired,
    totalPrice: PropTypes.object,
    trans: PropTypes.func.isRequired,
};

export default connectToI18n(DeliveryOptionPrice);
