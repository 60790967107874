import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import connectToI18n from 'modules/i18n/connectToI18n';
import FormGroup from './FormGroup.jsx';
import FormSelect from './FormSelect.jsx';
import InputErrors from './InputErrors.jsx';

export class FormSelectGroup extends React.Component {
    static propTypes = {
        block: PropTypes.bool,
        children: PropTypes.element,
        className: PropTypes.string,
        clientErrors: PropTypes.array,
        emptyOption: PropTypes.string,
        isDisabled: PropTypes.bool,
        isRequired: PropTypes.bool,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string,
                options: PropTypes.array,
            })
        ).isRequired,
        inputVariant: PropTypes.oneOf(['inverse', 'productIdentity']),
        serverErrors: PropTypes.array,
        showErrors: PropTypes.bool,
        trans: PropTypes.func.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        noMargin: PropTypes.bool,
    };

    static defaultProps = {
        inputVariant: null,
        block: true,
    };

    render() {
        const {
            block,
            name,
            label,
            emptyOption,
            options,
            value,
            showErrors,
            clientErrors,
            serverErrors,
            isRequired,
            isDisabled,
            onChange,
            inputVariant,
            trans,
            noMargin,
        } = this.props;
        const isProductIdentityVariant = inputVariant === 'productIdentity';

        return (
            <FormGroup
                className={classnames('formSelectGroup', this.props.className)}
                noMargin={noMargin}
            >
                <label
                    htmlFor={name}
                    className={classnames({
                        'fa-input--disabled': isDisabled && !isProductIdentityVariant,
                        'nd-label': isProductIdentityVariant,
                    })}
                >
                    {label}
                    {isRequired && (
                        <span className="required-label">{trans('global.required')}</span>
                    )}
                </label>
                <FormSelect
                    block={block}
                    emptyOption={emptyOption}
                    hasError={showErrors && (!!clientErrors || !!serverErrors)}
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={isDisabled}
                    variant={inputVariant}
                    options={options}
                />
                <InputErrors
                    isVisible={showErrors}
                    clientErrors={clientErrors}
                    serverErrors={serverErrors}
                />
                {this.props.children}
            </FormGroup>
        );
    }
}

export default connectToI18n(FormSelectGroup);
