import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createDiscussionInAssembly, getAssemblyDiscussions } from 'api/discussions';
import * as R from 'ramda';
import ToastsContainer from 'components/ToastsContainer.jsx';
import EmptyLastDiscussions from './EmptyLastDiscussions.jsx';
import LastDiscussionsList from './LastDiscussionsList.jsx';
import { INITIAL_LOADING, LOADING_MORE, READY } from '../../../modules/utils/ajaxStatuses';
import LastDiscussionsHeader from './LastDiscussionsHeader.jsx';
import NewDiscussion from '../Discussions/NewDiscussion.jsx';

const LastDiscussions = ({ assembly, maximumCount, user, userIsMember }) => {
    const [discussions, setDiscussions] = useState([]);
    const [currentPaginationCount, setCurrentPaginationCount] = useState(1);
    const [buttonStatus, setButtonStatus] = useState(INITIAL_LOADING);
    const [isLimitedDiscussions, setIsLimitedDiscussions] = useState(true);
    const [isLastDiscussion, setIsLastDiscussion] = useState(false);
    const { id: assemblyId } = assembly;

    useEffect(() => {
        if (userIsMember) {
            getAssemblyDiscussions(assemblyId).then(newDiscussions => {
                if (newDiscussions.length <= maximumCount) {
                    setIsLastDiscussion(true);
                }
                setDiscussions(newDiscussions);
            });
        }
    }, [assemblyId, maximumCount, userIsMember]);

    const handleShowMoreDiscussions = () => {
        if (isLimitedDiscussions) {
            setIsLimitedDiscussions(false);
            return;
        }
        setButtonStatus(LOADING_MORE);
        getAssemblyDiscussions(assemblyId, currentPaginationCount + 1).then(newDiscussions => {
            if (newDiscussions.length === 0) {
                setIsLastDiscussion(true);
                return;
            }
            setDiscussions(previousDiscussions => [...previousDiscussions, ...newDiscussions]);
            setCurrentPaginationCount(oldCurrentPage => oldCurrentPage + 1);
            setButtonStatus(READY);
        });
    };

    const saveNewDiscussion = (content, title) => {
        createDiscussionInAssembly(assemblyId, content, title).then(newDiscussion => {
            setDiscussions(R.prepend(newDiscussion));
        });
    };

    return (
        <Fragment>
            <ToastsContainer
                toastClassName="conversation-toast"
                className="conversation-toasts-container"
            />
            <LastDiscussionsHeader assemblyId={assemblyId} userIsMember={userIsMember} />
            <NewDiscussion
                author={user}
                saveNewDiscussion={saveNewDiscussion}
                status={buttonStatus}
            />
            {R.isEmpty(discussions) ? (
                <EmptyLastDiscussions />
            ) : (
                <LastDiscussionsList
                    assemblyId={assemblyId}
                    buttonStatus={buttonStatus}
                    discussions={
                        isLimitedDiscussions ? R.take(maximumCount, discussions) : discussions
                    }
                    handleShowMoreDiscussions={handleShowMoreDiscussions}
                    isLastDiscussion={isLastDiscussion}
                    maximumCount={maximumCount}
                    onChangeDiscussions={setDiscussions}
                    user={user}
                    userIsMember={userIsMember}
                />
            )}
        </Fragment>
    );
};

LastDiscussions.propTypes = {
    assembly: PropTypes.object.isRequired,
    maximumCount: PropTypes.number,
    user: PropTypes.object.isRequired,
    userIsMember: PropTypes.bool,
};

LastDiscussions.defaultProps = {
    maximumCount: 2,
};

export default LastDiscussions;
