import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const FOOD_ANIMATION_LOADERS = [
    'fromage',
    // 'jambon',
    'jus',
    'noix',
    'oignon',
    'pain',
    // 'patate',
    'patate2',
    // 'poisson',
    'pomme',
    // 'roti',
];

export class LoadingAnimation extends React.Component {
    static propTypes = {
        type: PropTypes.oneOf(['food', 'spinner', 'pi-spinner', 'payment-spinner']),
        classname: PropTypes.string,
        alt: PropTypes.string,
    };

    static defaultProps = {
        type: 'food',
    };

    getLoaderImageUrl = fileName => {
        return `${process.env.CDN_ROOT}/assets/images/loaderanims/${fileName}`;
    };

    render() {
        const animationImage = { size: null, src: null };

        if (this.props.type === 'food') {
            const imageName =
                FOOD_ANIMATION_LOADERS[Math.floor(Math.random() * FOOD_ANIMATION_LOADERS.length)];
            animationImage.src = this.getLoaderImageUrl(`loader-${imageName}.gif`);
        } else if (this.props.type === 'pi-spinner') {
            animationImage.src = this.getLoaderImageUrl('loader-pi-white.gif');
            animationImage.size = 60;
        } else if (this.props.type === 'payment-spinner') {
            animationImage.src = this.getLoaderImageUrl('loader-payment.gif');
            animationImage.size = 80;
        } else {
            animationImage.src = this.getLoaderImageUrl('loader-white.gif');
            animationImage.size = 48;
        }

        return (
            <div className={classnames(this.props.classname, 'loading-anim-box')}>
                <img
                    className={classnames({ 'loadingAnimation-image': this.props.type === 'food' })}
                    src={animationImage.src}
                    width={animationImage.size}
                    height={animationImage.size}
                    aria-busy="true"
                    alt={this.props.alt}
                />
            </div>
        );
    }
}

export default LoadingAnimation;
