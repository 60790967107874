import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const BIG_SIZE = 'big';
export const SMALL_SIZE = 'small';

export const THEME_SELECTED_GREEN = 'selectedGreen';
export const THEME_SELECTED_GREY = 'selectedGrey';

const Tag = ({ label, className, selected, noBorder, size, readOnly, onClick, theme }) => {
    return (
        <div
            onClick={onClick}
            className={classnames('nd-tag', className, {
                selected,
                'no-border': noBorder,
                [size]: size,
                [theme]: selected && theme,
                'read-only': readOnly,
            })}
        >
            {label}
        </div>
    );
};

Tag.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    className: PropTypes.string,
    selected: PropTypes.bool,
    noBorder: PropTypes.bool,
    size: PropTypes.oneOf([BIG_SIZE]),
    readOnly: PropTypes.bool,
    onClick: PropTypes.func,
    theme: PropTypes.oneOf([THEME_SELECTED_GREEN, THEME_SELECTED_GREY]),
};
Tag.defaultProps = {
    theme: THEME_SELECTED_GREEN,
    size: SMALL_SIZE,
};

export default Tag;
