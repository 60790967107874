import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import classnames from 'classnames';
import Text from 'components/Text.jsx';
import { totalAvailableStock } from 'modules/stockUtils.js';

export class AddTocart extends PureComponent {
    static propTypes = {
        trans: PropTypes.func.isRequired,
        quantity: PropTypes.number,
        onAdd: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        isStockExhausted: PropTypes.bool,
        isLoading: PropTypes.bool,
        offers: PropTypes.array,
    };

    onAdd = e => {
        e.stopPropagation();
        if (this.props.isLoading) {
            return;
        }
        this.props.onAdd();
    };

    onRemove = e => {
        e.stopPropagation();
        if (this.props.isLoading) {
            return;
        }
        this.props.onRemove();
    };

    render() {
        const { quantity, isStockExhausted, isLoading, offers } = this.props;

        if (isStockExhausted) {
            return (
                <span className="addToCart-noPurchase">
                    {this.props.trans('offer.unavailable')}
                </span>
            );
        }

        const hasQuantityInBasket = quantity > 0;

        const sumStocks = totalAvailableStock({
            offers,
        });

        const isStockEmpty = quantity >= sumStocks;

        return (
            <div className="u-flexible-row addToCart-buttonContainer">
                {hasQuantityInBasket && (
                    <Fragment>
                        <button
                            className={classnames('addToCart-button', { disabled: isLoading })}
                            onClick={this.onRemove}
                            aria-label={this.props.trans('salePage.removeAnItemToBasket')}
                        >
                            -
                        </button>
                        <Text className="addToCart-quantity" size="large">
                            {isLoading ? (
                                <div className="spinner shown spinner--white spinner--md addToCart-quantity" />
                            ) : (
                                quantity
                            )}
                        </Text>
                    </Fragment>
                )}

                <button
                    className={classnames('addToCart-button', 'addToCart-add', {
                        disabled: isLoading || isStockEmpty,
                        'addToCart-add--standAlone': !hasQuantityInBasket,
                    })}
                    onClick={this.onAdd}
                    disabled={isStockEmpty}
                    aria-label={this.props.trans('salePage.addAnItemToBasket')}
                >
                    +
                </button>
            </div>
        );
    }
}

export default connectToI18n(AddTocart);
