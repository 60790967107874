import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const RedirectToLogin = ({ redirectUrl }) => {
    const loginUrl = redirectUrl ? `/login?redirectUrl=${redirectUrl}` : '/login';
    return <Redirect to={loginUrl} />;
};

RedirectToLogin.propTypes = {
    redirectUrl: PropTypes.string,
};

export default RedirectToLogin;
