/* eslint-disable react/prop-types */
import React, { lazy, Suspense } from 'react';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import UserFarmIdProvider from 'components/UserFarmIdProvider.jsx';
import FeatureService, { FEATURES } from '../../models/features';
const FinalizeDistributionContainer = lazy(() =>
    import('components/FinalizeDistribution/V2/FinalizeDistributionContainer.jsx')
);

const AssemblyConfigurationContainer = lazy(() =>
    import('components/AssemblyConfiguration/AssemblyConfigurationContainer.jsx')
);
const ConstructionSpaceContainer = lazy(() =>
    import('components/HostSpace/ConstructionSpaceContainer.jsx')
);
const AssemblyPresentationContainer = lazy(() =>
    import('components/AssemblyPresentationContainer.jsx')
);
const HostProgressContainer = lazy(() => import('components/HostProgressContainer.jsx'));
const ProposalsContainer = lazy(() => import('components/FindFarms/ProposalsContainer.jsx'));
const AssemblyVenueContainer = lazy(() =>
    import('components/AssemblyConfiguration/AssemblyVenueContainer.jsx')
);
const AssemblyShippingAddressContainer = lazy(() =>
    import('components/AssemblyConfiguration/AssemblyShippingAddressContainer.jsx')
);
const AssemblyThemeContainer = lazy(() =>
    import('components/AssemblyTheme/AssemblyThemeContainer.jsx')
);
const AssemblyTimeSlotsContainer = lazy(() =>
    import('components/AssemblyConfiguration/AssemblyTimeSlotsContainer.jsx')
);
const FindFarmsContainer = lazy(() => import('components/FindFarms/FindFarmsContainer.jsx'));
const HighlightClustersArchivesContainer = lazy(() =>
    import('components/FindFarms/HighlightClustersArchivesContainer.jsx')
);
const FeedbackOnProducerContainer = lazy(() =>
    import('components/FeedbackOnProducer/FeedbackOnProducerContainer.jsx')
);
const AssemblyArchivesContainer = lazy(() =>
    import('components/AssemblyArchives/AssemblyArchivesContainer.jsx')
);
const HostSalesRouter = lazy(() => import('components/HostSales/HostSalesRouter.jsx'));
const FinancesContainer = lazy(() => import('components/HostSales/Finances/FinancesContainer.jsx'));
const HostSalePreparationContainer = lazy(() =>
    import('components/HostSales/Prepare/HostSalePreparationContainer.jsx')
);
const PrepareDistributionFarmPage = lazy(() =>
    import('components/PrepareDistributionFarm/PrepareDistributionFarmPage.jsx')
);
const HostFollowSaleContainer = lazy(() =>
    import('components/HostSales/Follow/HostFollowSaleContainer.jsx')
);
const DistributionSummaryContainer = lazy(() =>
    import('components/FinalizeDistribution/DistributionSummaryContainer.jsx')
);
const HostInChargeForDistributionContainer = lazy(() =>
    import('components/FinalizeDistribution/HostInChargeForDistributionContainer.jsx')
);
const PrivacyCharter = lazy(() => import('components/Community/PrivacyCharter.jsx'));
const FirstOrderSurveyResultsContainer = lazy(() =>
    import('components/HostDashboard/FirstOrderSurveyResultsContainer.jsx')
);
const HostDashboardStatsContainer = lazy(() =>
    import('components/HostDashboard/HostDashboardStatsContainer.jsx')
);
const HostDashboardOffersContainer = lazy(() =>
    import('components/HostDashboard/HostDashboardOffersContainer.jsx')
);
const HostDashboardFeedbacksContainer = lazy(() =>
    import('components/HostDashboard/HostDashboardFeedbacksContainer.jsx')
);
const MembersListContainer = lazy(() => import('components/MembersList/MembersListContainer.jsx'));
const FarmProfileAssembliesContainer = lazy(() =>
    import('components/FarmProfile/FarmProfileAssembliesContainer.jsx')
);
const MiniRuchesContainer = lazy(() =>
    import('components/MiniRuchesList/MiniRuchesListContainer.jsx')
);
const SaleMessageContainer = lazy(() =>
    import('components/CustomSaleMessages/SaleMessageContainer.jsx')
);
const HighlightsAndAnnouncementContainer = lazy(() =>
    import('components/HostSales/Launch/HighlightsAndAnnouncementContainer.jsx')
);
const DeliveryCheckingContainer = lazy(() =>
    import('components/FinalizeDistribution/DeliveryCheckingContainer.jsx')
);

export const hostRoutes = [
    {
        definition: '/assemblies/:assemblyId/preconstruction-space',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.leader.configuration',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <AssemblyConfigurationContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                    preconstructionStep
                />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/construction-space',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.leader.construction',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ConstructionSpaceContainer assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/configuration',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.leader.configuration',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <AssemblyConfigurationContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/presentation',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.leader.configuration',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <AssemblyPresentationContainer assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/progress',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.progression',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <HostProgressContainer assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/proposals',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.farms',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ProposalsContainer assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/venue',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.leader.configuration',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <AssemblyVenueContainer assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/shipping',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.leader.configuration',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <AssemblyShippingAddressContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/theme',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.leader.configuration',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <AssemblyThemeContainer assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/collection',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.leader.configuration',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <AssemblyTimeSlotsContainer assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/producers/find:searchQuery?',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.farms',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <FindFarmsContainer assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/suggestions',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.farms',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <HighlightClustersArchivesContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/producers/:farmId/feedback',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.farms',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <FeedbackOnProducerContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                    farmId={parseInt(match.params.farmId, 10)}
                />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/archives',
        conditions: ['isAuthenticated', 'hiveHasTimeslot', 'isLeaderOfHive'],
        activeLink: 'menu.distributions',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <AssemblyArchivesContainer assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/collections',
        conditions: ['isAuthenticated', 'hiveHasTimeslot', 'isLeaderOfHive'],
        activeLink: 'menu.distributions',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <HostSalesRouter assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/collections/financial',
        conditions: ['isAuthenticated', 'hiveHasTimeslot', 'isLeaderOfHive'],
        activeLink: 'menu.distributions',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <FinancesContainer assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/collections/upcoming',
        conditions: ['isAuthenticated', 'hiveHasTimeslot', 'isLeaderOfHive'],
        activeLink: 'menu.distributions',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <HostSalesRouter assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/collections/:distributionId/prepare',
        conditions: ['isAuthenticated', 'hiveHasTimeslot', 'isLeaderOfHive'],
        activeLink: 'menu.distributions',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <HostSalePreparationContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                    distributionId={parseInt(match.params.distributionId, 10)}
                />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/collections/:distributionId/farms/:farmId',
        conditions: ['isAuthenticated', 'hiveHasTimeslot', 'isLeaderOfHive'],
        activeLink: 'menu.distributions',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <PrepareDistributionFarmPage
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                    distributionId={parseInt(match.params.distributionId, 10)}
                    farmId={parseInt(match.params.farmId, 10)}
                />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/collections/:distributionId/follow',
        conditions: ['isAuthenticated', 'hiveHasTimeslot', 'isLeaderOfHive'],
        activeLink: 'menu.distributions',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <HostFollowSaleContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                    distributionId={parseInt(match.params.distributionId, 10)}
                />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/collections/:distributionId/finalize',
        conditions: ['isAuthenticated', 'hiveHasTimeslot', 'isLeaderOfHive'],
        activeLink: 'menu.distributions',
        context: 'hive',
        exact: true,
        render: ({ match, currentUser }) => {
            const assemblyId = parseInt(match.params.assemblyId, 10);
            const assembly = currentUser.hivesAsLeader.find(
                assemblyItem => assemblyId === assemblyItem.id
            );
            let DistributionFinalizationComponent = DeliveryCheckingContainer;

            if (FeatureService.isFeatureActive(FEATURES.PARTIAL_REFUND, assembly)) {
                DistributionFinalizationComponent = FinalizeDistributionContainer;
            }

            return (
                <Suspense fallback={<LoadingAnimation type="spinner" />}>
                    <DistributionFinalizationComponent
                        assemblyId={assemblyId}
                        distributionId={parseInt(match.params.distributionId, 10)}
                    />
                </Suspense>
            );
        },
    },
    {
        definition: '/assemblies/:assemblyId/collections/:distributionId/distributionSummary',
        conditions: ['isAuthenticated', 'hiveHasTimeslot', 'isLeaderOfHive'],
        activeLink: 'menu.distributions',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <DistributionSummaryContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                    distributionId={parseInt(match.params.distributionId, 10)}
                />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/collections/:distributionId/host',
        conditions: ['isAuthenticated', 'hiveHasTimeslot', 'isLeaderOfHive'],
        activeLink: 'menu.distributions',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <HostInChargeForDistributionContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                    distributionId={parseInt(match.params.distributionId, 10)}
                />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/privacy-charter',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.members',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <PrivacyCharter assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/surveys/first-order/results',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.statistics',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <FirstOrderSurveyResultsContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/dashboard/stats',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.statistics',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <HostDashboardStatsContainer assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/dashboard/offers',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.statistics',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <HostDashboardOffersContainer assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/dashboard/feedbacks',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.statistics',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <HostDashboardFeedbacksContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/members:filters?',
        conditions: ['isAuthenticated', 'isMemberOfHive'],
        activeLink: 'menu.members',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <MembersListContainer assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/producers/:farmId/assemblies:filters?',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: '',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <UserFarmIdProvider>
                    {({ farmId }) => (
                        <FarmProfileAssembliesContainer
                            assemblyId={parseInt(match.params.assemblyId, 10)}
                            farmId={parseInt(match.params.farmId, 10)}
                            userIsOwnerOfFarm={farmId === parseInt(match.params.farmId, 10)}
                        />
                    )}
                </UserFarmIdProvider>
            </Suspense>
        ),
    },
    {
        definition: '/assemblies/:assemblyId/mini-ruches',
        conditions: ['isAuthenticated', 'isLeaderOfHive'],
        activeLink: 'menu.members',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <MiniRuchesContainer assemblyId={parseInt(match.params.assemblyId, 10)} />
            </Suspense>
        ),
    },
    {
        definition:
            '/assemblies/:assemblyId/collections/:distributionId/sale-messages/:messageType',
        conditions: ['isAuthenticated', 'hiveHasTimeslot', 'isLeaderOfHive'],
        activeLink: 'menu.distributions',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <SaleMessageContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                    distributionId={parseInt(match.params.distributionId, 10)}
                    messageType={match.params.messageType}
                    withHeader
                />
            </Suspense>
        ),
    },
    {
        definition:
            '/assemblies/:assemblyId/collections/:distributionId/highlights-and-announcement',
        conditions: ['isAuthenticated', 'hiveHasTimeslot', 'isLeaderOfHive'],
        activeLink: 'menu.distributions',
        context: 'hive',
        exact: true,
        render: ({ match }) => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <HighlightsAndAnnouncementContainer
                    assemblyId={parseInt(match.params.assemblyId, 10)}
                    distributionId={parseInt(match.params.distributionId, 10)}
                />
            </Suspense>
        ),
    },
];
