import React from 'react';

function getDisplayName(Component) {
    return Component.displayName || Component.name || 'Component';
}

export function createResponsiveComponent(Component, { maxWidth = 767 } = {}) {
    return class extends React.Component {
        static displayName = `ResponsiveComponent(${getDisplayName(Component)})`;

        constructor(props) {
            super(props);
            if (___BROWSER___) {
                this.state = {
                    isSmallWidth: window.matchMedia(`(max-width: ${maxWidth}px)`).matches,
                };

                return;
            }
            this.state = { isSmallWidth: true };
        }

        // this will only be called when running in the browser
        componentDidMount() {
            this._smallWidthQuery = window.matchMedia(`(max-width: ${maxWidth}px)`);
            this._smallWidthQuery.addListener(this.handleMediaQueryChange);
            this.handleMediaQueryChange();
        }

        componentWillUnmount() {
            this._smallWidthQuery.removeListener(this.handleMediaQueryChange);
        }

        handleMediaQueryChange = () => {
            const isSmallWidth = this._smallWidthQuery.matches;
            if (this.state.isSmallWidth !== isSmallWidth) {
                this.setState({
                    isSmallWidth,
                });
            }
        };

        render() {
            return <Component {...this.state} {...this.props} />;
        }
    };
}

export default createResponsiveComponent;
