import './globalize.culture.de-CH';

import moment from 'moment';
moment.defineLocale('de-CH', { parentLocale: 'de' });

export default {
    code: 'de-CH',

    // bcode is here for the "backCode" ... the code as seen/sent by the back :O
    bCode: 'de_CH',

    name: 'Deutsch (Schweiz)',
    supportEmail: 'hilfe@marktschwaermer.ch',
    privacyEmail: 'privacy@marktschwaermer.ch',

    trainingSite: {
        url:
            'https://hilfe.marktschwaermer.de/hc/de/categories/4421717703953-GASTGEBER-INNEN-HANDBUCH',
        password: '2021-markt',
        email: 'gg-handbuch@marktschwaermer.de',
    },

    appStores: {
        apple: 'https://itunes.apple.com/de/app/marktschw%C3%A4rmer/id1052198033?mt=8',
        google: 'https://play.google.com/store/apps/details?id=com.lrqdo&hl=de',
    },

    appleAppStore: 'https://itunes.apple.com/fr/app/la-ruche-qui-dit-oui/id1052198033?mt=8',
    googleAppStore: 'https://play.google.com/store/apps/details?id=com.lrqdo&hl=fr',

    prodEssential: {
        url:
            'https://hilfe.marktschwaermer.de/hc/de/articles/360019714398-Die-wichtigsten-Informationen-f%C3%BCr-Sie-zusammengefasst',
    },

    supportSite: {
        widgetDomain: 'thefoodassemblyde.zendesk.com',
        homepage: 'https://marktschwaermerde.zendesk.com/hc/de',
        host: 'https://marktschwaermerde.zendesk.com/hc/de/categories/200127571-F%C3%9CR-GASTGEBER',
        farmer:
            'https://marktschwaermerde.zendesk.com/hc/de/categories/200129412-F%C3%9CR-ERZEUGER',
        member:
            'https://marktschwaermerde.zendesk.com/hc/de/categories/200127561-F%C3%9CR-MITGLIEDER',
        kycInformation: 'https://marktschwaermerde.zendesk.com/hc/de/articles/115001187549',
    },

    logos: {
        brown: '/assets/images/logos/brown/logo-marktschwarmer.svg',
        turquoise: '/assets/images/logos/turquoise/logo-de.svg',
        white: '/assets/images/logos/white/logo-marktschwarmer.svg',
        whiteOutlined: '/assets/images/logos/whiteOutlined/logo-de.svg',
        blue: '/assets/images/logos/blue/logo-de.svg',
        christmas: '/assets/images/logos/christmas/logo-de.svg',
        whiteChristmas: '/assets/images/logos/christmas/white/logo-de.svg',
    },

    social: {
        facebook: 'https://www.facebook.com/TheFoodAssembly.de',
        twitter: 'https://twitter.com/foodassembly_de',
        blog: 'http://blog.marktschwaermer.de/',
        instagram: 'https://www.instagram.com/marktschwaermer/',
        youtube: 'https://www.youtube.com/channel/UC_1G-Sj94AkFsG8H51y6XVg',
        linkedin: 'https://www.linkedin.com/company/marktschw%C3%A4rmer',
    },

    links: {
        about: 'https://wirsind.marktschwaermer.ch/',
        press: 'https://wirsind.marktschwaermer.ch/presse/',
        joinUs: 'https://wirsind.marktschwaermer.ch/unser-team/',
        order: 'https://marktschwaermer.ch/de-CH/assemblies',
        giftCards: 'https://marktschwaermer.ch/de-CH/giftcards',
        openRuche: 'https://marktschwaermer.ch/de-CH/p/open',
        provideRuche: 'https://marktschwaermer.ch/de-CH/p/provide',
        proposeLocal: '',
    },

    proposeLocalForm:
        'https://docs.google.com/forms/d/e/1FAIpQLSfZW3VNFKswqDzT5kHDEebgrOe-ksSi_5Mr-WR55xUkv_uQ_A/viewform',

    dateFormats: {
        d: 'DD.MM.YYYY',
        D: 'dddd, DD. MMMM YYYY',
        I: 'dddd, DD. MMMM',
        i: 'DD. MMMM YYYY',
        t: 'HH:mm',
        T: 'HH:mm:ss',
        f: 'dddd, DD. MMMM YYYY HH:mm',
        F: 'dddd, DD. MMMM YYYY HH:mm:ss',
        M: 'DD MMMM',
        Y: 'MMMM YYYY',
        shortDayDate: 'ddd, DD. MMMM',
        dateWithoutYear: 'DD.MM.',
    },

    inlineLogo: require('./logo.svg'),
    zendeskLocale: 'de',
    zendeskSearch: 'https://marktschwaermerde.zendesk.com/api/v2/help_center/',
    zendeskCategories: {
        farmer: '200129412',
        member: '200127561',
        user: '200127561',
        host: '200127571',
    },

    cguPage: 'https://marktschwaermer.ch/de-CH/p/terms',
    homepage: 'https://marktschwaermer.ch',
    minirucheHelpCenter:
        'https://hilfe.marktschwaermer.de/hc/de/articles/360009977217-Was-sind-Abholpunkte-und-wie-funktionieren-sie-',
};
