import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import InlineSVG from 'svg-inline-react';
import Link, { DISPLAY_MODES } from 'src/components/atoms/Link/Link.jsx';

import { closeAll as closeAllPanels } from 'modules/visiblePanel';

import Text from 'components/ProductIdentity/Text.jsx';

const LinkItem = ({ text, icon, url }) => {
    const dispatch = useDispatch();

    return (
        <li>
            <InlineSVG src={icon} />
            <Link
                href={url}
                displayMode={DISPLAY_MODES.BLACK_LINK}
                bypassRouter
                onClick={() => dispatch(closeAllPanels())}
            >
                <Text size="16px" lineHeight="24px">
                    {text}
                </Text>
            </Link>
        </li>
    );
};

LinkItem.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    url: PropTypes.string,
};

export default LinkItem;
