import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useForm } from 'react-hook-form';

import useI18n from 'hooks/I18n/useI18n';

import { post } from 'modules/api';
import { reactFormValidators } from 'modules/validation';

import Text from 'components/ProductIdentity/Text.jsx';
import Input, { ERROR_MODE } from 'src/components/atoms/Input/Input.jsx';
import Button, { XSMALL_SIZE } from 'src/components/atoms/Button/Button.jsx';
import ValidateIcon from 'app/assets/new-design/images/icons/validate-icon.svg';
import Icon from 'components/Icon.jsx';

const Handhover = ({ hiveId }) => {
    const { trans } = useI18n();
    const {
        handleSubmit,
        register,
        watch,
        formState: { isSubmitSuccessful, errors },
    } = useForm();

    const email = watch('mail-input');
    const { assertValidEmail } = useMemo(() => reactFormValidators(trans), [trans]);

    const submit = () => post('handover/candidate', { email, hive: hiveId });

    const renderSuccessfulMessage = useMemo(
        () => (
            <div className="pi-handhover-form-success">
                <Icon size="large" src={ValidateIcon}></Icon>
                <Text
                    size="14px"
                    lineHeight="20px"
                    color="gray5"
                    dangerouslySetInnerHTML={{ __html: trans('map.handhover.form.success') }}
                />
            </div>
        ),
        [trans]
    );
    return (
        <form className="pi-handhover-map" onSubmit={handleSubmit(submit)}>
            <Text size="14px" lineHeight="20px" weight={600} color="grey8">
                {trans('map.handhover.title')}
            </Text>
            {isSubmitSuccessful ? (
                renderSuccessfulMessage
            ) : (
                <>
                    <Text
                        size="14px"
                        lineHeight="20px"
                        color="gray5"
                        dangerouslySetInnerHTML={{
                            __html: trans('map.handhover.description'),
                        }}
                    />
                    <Input
                        error={R.path(['mail-input', 'message'], errors)}
                        mode={errors['mail-input'] && ERROR_MODE}
                        {...register('mail-input', { validate: assertValidEmail('mail-input') })}
                        placeholder={trans('global.label.email')}
                    />
                    <Button type="submit" size={XSMALL_SIZE} disabled={!email}>
                        {trans('global.send')}
                    </Button>
                </>
            )}
        </form>
    );
};

Handhover.propTypes = {
    hiveId: PropTypes.number,
};
export default Handhover;
