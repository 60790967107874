import PropTypes from 'prop-types';
import React from 'react';
import RadioButton from 'components/RadioButton.jsx';
import classnames from 'classnames';

export class RadioBlock extends React.Component {
    static propTypes = {
        isSelected: PropTypes.bool.isRequired,
        className: PropTypes.string,
        disabled: PropTypes.string,
    };

    render() {
        const { isSelected, className, disabled } = this.props;

        return (
            <RadioButton
                {...this.props}
                variant="circle"
                className={classnames(
                    'radioBlock',
                    { 'is-selected': isSelected },
                    { 'radioBlock--disabled': disabled },
                    className
                )}
            />
        );
    }
}

export default RadioBlock;
