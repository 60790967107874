import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

import { getLocalizedDateWithMonthDayWithoutYear } from 'modules/utils/dateAndTime';
import { capitalizeFirstLetter } from 'modules/utils/string';
import { isLastDay, getSaleEndDateInfo } from 'models/distributions';
import useI18n from 'hooks/I18n/useI18n';
import LimitOverflowingContentWithEllipsis from 'components/ProductIdentity/LimitOverflowingContentWithEllipsis.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import Link, { DISPLAY_MODES } from 'src/components/atoms/Link/Link.jsx';
import Tag from 'src/components/atoms/Tag/Tag.jsx';

const Distribution = ({ distribution, isMember }) => {
    const { i18n, trans } = useI18n();
    const dateInfo = getSaleEndDateInfo(distribution);
    const handleOverflowingContentReduce = contentContainerRef => {
        const boundingBox = contentContainerRef.getBoundingClientRect();
        const isInView = boundingBox.top >= 0 && boundingBox.bottom <= window.innerHeight;

        if (!isInView) {
            window.scrollTo({ top: boundingBox.top + window.scrollY });
        }
    };
    return (
        <div className="pi-sales-block-distribution">
            <figure className="img-container">
                <img src={distribution.illustrationImage} />
            </figure>
            <div className="text-container">
                <div className="heading-container">
                    <Text size="20px" lineHeight="28px" weight="600">
                        {capitalizeFirstLetter(
                            getLocalizedDateWithMonthDayWithoutYear(
                                i18n,
                                moment(distribution.distributionDate).toDate()
                            )
                        )}
                    </Text>
                    {isLastDay(distribution) && (
                        <Tag size="small" label={trans('global.lastDay')} readOnly />
                    )}
                </div>
                <div className="text-body">
                    <Text size="16px" lineHeight="24px">
                        {dateInfo.isLastDay
                            ? trans('distributions.closingAt', {
                                  '%date%': isMember
                                      ? dateInfo.closingInMember
                                      : dateInfo.closingInReal,
                              })
                            : trans('hiveHome.distribution.saleOpenUntil', {
                                  '%date%': getLocalizedDateWithMonthDayWithoutYear(
                                      i18n,
                                      moment(dateInfo.saleEndDay).toDate()
                                  ),
                              })}
                    </Text>
                    {/* TODO: add this information on API => <Text size="16px" lineHeight="24px">{trans('hiveHome.distribution.deliveryIsAvailable')}</Text> */}
                </div>
                <Link
                    href={`/${i18n}/assemblies/${distribution.hiveId}/collections/${distribution.id}/products`}
                    bypassRouter
                    displayMode={DISPLAY_MODES.BUTTON}
                    className="distribution-cta"
                >
                    {trans('distributions.chooseProducts')}
                </Link>
            </div>
            {distribution.saleOpenMessage && (
                <LimitOverflowingContentWithEllipsis
                    className="distribution-description"
                    reduceKey="global.collapse"
                    onReduce={handleOverflowingContentReduce}
                >
                    {distribution.saleOpenMessage.replace(/(<p>&nbsp;<\/p>)/gi, '')}
                </LimitOverflowingContentWithEllipsis>
            )}
        </div>
    );
};

Distribution.propTypes = {
    distribution: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    isMember: PropTypes.bool,
};
Distribution.defaultProps = {
    isMember: false,
};

export default Distribution;
