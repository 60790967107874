import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { currentAssemblySelector } from 'modules/navigation';
import useI18n from 'hooks/I18n/useI18n';
import {
    USER_PANEL,
    toggle as togglePanel,
    showImmediately as showPanelImmediately,
    getVisiblePanel,
} from 'modules/visiblePanel';
import Icon from 'components/Icon.jsx';
import Text from 'components/Text.jsx';
import PIText from 'components/ProductIdentity/Text.jsx';
import AnonymousUserPanel from 'components/Navigation/AnonymousUserPanel.jsx';
import ProfilePanel from 'components/Navigation/ProductIdentity/ProfilePanel/ProfilePanel.jsx';
import AnonymousProfilePanel from 'components/Navigation/ProductIdentity/ProfilePanel/AnonymousProfilePanel.jsx';
import StructuredSidePanel from 'components/StructuredSidePanel.jsx';
import UserPanel from 'components/Navigation/UserPanel.jsx';
import { formatFullName } from 'models/users';
import { userSelector } from 'modules/currentUser';

import userIcon from 'app/assets/new-design/images/icons/user.svg';
import { useDispatch, useSelector } from 'react-redux';
const profileIcon = '/assets/images/icons/profile.svg';

const AccountButton = props => {
    const { isSmallWidth, isOnHeader, shouldShowProductIdentity } = props;
    const visiblePanel = useSelector(getVisiblePanel);
    const displayUserPanel = visiblePanel === USER_PANEL;

    const dispatch = useDispatch();
    const { trans } = useI18n();
    const openMenu = (e, panel) => {
        e.preventDefault();
        dispatch(showPanelImmediately(panel));
    };
    const openUserMenu = e => openMenu(e, USER_PANEL);
    const currentUser = useSelector(userSelector);
    const currentAssembly = useSelector(currentAssemblySelector);
    const renderPanel = useMemo(() => {
        const assemblyId = currentAssembly && currentAssembly.id;

        if (shouldShowProductIdentity) {
            if (currentUser.anonymous) {
                return <AnonymousProfilePanel onClose={() => dispatch(togglePanel(USER_PANEL))} />;
            }

            return <ProfilePanel onClose={() => dispatch(togglePanel(USER_PANEL))} />;
        }

        if (currentUser.anonymous) {
            return (
                <StructuredSidePanel isOpened isNewNavigationLayout>
                    <AnonymousUserPanel assemblyId={assemblyId} />
                </StructuredSidePanel>
            );
        }

        return (
            <StructuredSidePanel isOpened title={formatFullName(currentUser)} isNewNavigationLayout>
                <UserPanel currentUser={currentUser} />
            </StructuredSidePanel>
        );
    }, [currentAssembly, currentUser, shouldShowProductIdentity, dispatch]);

    return (
        <>
            <button
                aria-label={isSmallWidth ? trans('navigation.member.connexion') : undefined}
                className={classnames('', {
                    'simpleMemberTopRightMenu-panel simpleMemberTopNavigation-button': isOnHeader,
                    'u-mr-2': !shouldShowProductIdentity,
                    'pi-saleSmallScreenNavigation-button--enabled': displayUserPanel,
                })}
                onClick={openUserMenu}
            >
                {!shouldShowProductIdentity && isSmallWidth && (
                    <img alt={trans('navigation.member.connexion')} src={profileIcon} />
                )}
                {shouldShowProductIdentity && (!isSmallWidth || (isSmallWidth && isOnHeader)) && (
                    <Icon src={userIcon} color="white" size="xx-large" />
                )}
                {shouldShowProductIdentity && isSmallWidth && !isOnHeader && (
                    <>
                        <Icon color="gray2" src={userIcon} size="xx-large" />
                        <PIText size="12px" lineHeight="14px">
                            {trans('navigation.user.account')}
                        </PIText>
                    </>
                )}
                {!shouldShowProductIdentity && !isSmallWidth && (
                    <Text className="simpleMemberTopRightMenu-userInfo">
                        {!currentUser.anonymous ? currentUser.firstName : trans('login.login')}
                    </Text>
                )}
            </button>
            {displayUserPanel && renderPanel}
        </>
    );
};

AccountButton.propTypes = {
    isSmallWidth: PropTypes.bool,
    shouldShowProductIdentity: PropTypes.bool,
    isOnHeader: PropTypes.bool,
};

export default AccountButton;
