/* eslint-disable react/prop-types */
import React, { lazy, Suspense } from 'react';
import LoadingAnimation from 'components/LoadingAnimation.jsx';

const HostProfileContainer = lazy(() => import('components/HostProfile/HostProfileContainer.jsx'));
const ProgressContainer = lazy(() => import('components/HostProfile/ProgressContainer.jsx'));

export const hostProfileRoutes = [
    {
        definition: '/progress',
        conditions: ['hasLeaderProfile'],
        activeLink: 'menu.progression',
        context: 'questionnaire',
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <ProgressContainer />
            </Suspense>
        ),
    },
    {
        definition: '/questionnaire',
        conditions: ['isAuthenticated'],
        activeLink: 'menu.questionnaire',
        context: 'questionnaire',
        render: () => (
            <Suspense fallback={<LoadingAnimation type="spinner" />}>
                <HostProfileContainer />
            </Suspense>
        ),
    },
];
