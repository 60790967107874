import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import connectToI18n from 'modules/i18n/connectToI18n';
import DateInput from './DateInput.jsx';
import DateInputOneField from './DateInputOneField.jsx';
import FormGroup from './FormGroup.jsx';
import InputErrors from './InputErrors.jsx';

export class DateInputGroup extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        isRequired: PropTypes.bool,
        placeholder: PropTypes.string,
        showErrors: PropTypes.bool,
        clientErrors: PropTypes.array,
        serverErrors: PropTypes.array,
        className: PropTypes.string,
        children: PropTypes.element,
        onChange: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool,
        inputVariant: PropTypes.oneOf(['inverse']),
        trans: PropTypes.func.isRequired,
        noMargin: PropTypes.bool,
        oneField: PropTypes.bool,
    };

    static defaultProps = {
        inputVariant: null,
    };

    handleChange = value => {
        this.props.onChange(this.props.name, value);
    };

    render() {
        const {
            name,
            label,
            value,
            showErrors,
            clientErrors,
            serverErrors,
            isRequired,
            placeholder,
            isDisabled,
            inputVariant,
            trans,
            noMargin,
            oneField,
        } = this.props;
        const productIdentity = inputVariant === 'productIdentity';
        const hasError =
            showErrors &&
            ((clientErrors && clientErrors.length > 0) ||
                (serverErrors && serverErrors.length > 0));

        return (
            <FormGroup
                noMargin={noMargin}
                className={classnames('dateInputGroup-new', this.props.className)}
            >
                <label
                    htmlFor={name}
                    className={classnames({
                        'fa-input--disabled': !productIdentity && isDisabled,
                        'nd-label': productIdentity,
                    })}
                >
                    {label}
                    {isRequired && (
                        <span className="required-label">{trans('global.required')}</span>
                    )}
                </label>
                {oneField ? (
                    <DateInputOneField
                        name={name}
                        value={value}
                        onChange={this.handleChange}
                        hasError={hasError}
                        inputVariant={inputVariant}
                        disabled={isDisabled}
                        placeholder={placeholder}
                    />
                ) : (
                    <DateInput
                        name={name}
                        value={value}
                        onChange={this.handleChange}
                        hasError={hasError}
                        inputVariant={inputVariant}
                        disabled={isDisabled}
                        placeholder={placeholder}
                    />
                )}
                <InputErrors
                    isVisible={showErrors}
                    clientErrors={clientErrors}
                    serverErrors={serverErrors}
                />
                {this.props.children}
            </FormGroup>
        );
    }
}

export default connectToI18n(DateInputGroup);
