import AppChannel from 'modules/channels/App.js';
import { currentAssemblySelector } from 'modules/navigation/index.js';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import * as R from 'ramda';
import connectToI18n from 'modules/i18n/connectToI18n';
import { hasAvailableStock } from 'modules/stockUtils.js';
import MultiOffersModal from 'components/OffersModal/MultiOffersModal.jsx';
import { isGermanLanguage } from 'modules/utils/locales';
import AddToCart from 'components/AddToCart.jsx';
import { titleCase, capitalizeFirstLetter, toLowerCase } from 'modules/utils/string';
import { getFormattedQuantity } from 'models/offers';
import { join } from 'modules/utils/string';
import { connect } from 'react-redux';
import { getQuantityInBasketByOffers, isOrderItemLoading } from 'modules/orders';
import HorizontalProductCard from './HorizontalProductCard.jsx';
import VerticalProductCard from './VerticalProductCard.jsx';
import CompactProductCard from './CompactProductCard.jsx';
import DetailedProductCard from './DetailedProductCard.jsx';
import { withRouter } from 'react-router';

export class ProductCard extends React.Component {
    static propTypes = {
        trans: PropTypes.func.isRequired,
        i18n: PropTypes.string.isRequired,
        product: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string,
            offers: PropTypes.array,
            photoId: PropTypes.string,
            type: PropTypes.shape({
                id: PropTypes.number.isRequired,
                quantityStrategy: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        allProductTypesIndexedById: PropTypes.object.isRequired,
        addOfferToBasket: PropTypes.func.isRequired,
        quantityInBasketByOffers: PropTypes.array.isRequired,
        distributionId: PropTypes.number.isRequired,
        isLabelDescriptionAvailable: PropTypes.bool,
        noImageBackgroundColor: PropTypes.bool,
        isSingleOfferLoading: PropTypes.bool,
        variant: PropTypes.oneOf(['horizontal', 'vertical', 'compact', 'detailed']),
        size: PropTypes.oneOf(['large', 'small']),
        displayProducer: PropTypes.bool,
        isAlreadyBoughtCategory: PropTypes.bool,
        actionOrigin: PropTypes.string,
        isSmallWidth: PropTypes.bool,
        distributionDate: PropTypes.string,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        onClick: PropTypes.func,
        assembly: PropTypes.object.isRequired,
    };

    static defaultProps = {
        displayProducer: false,
        variant: 'horizontal',
        size: 'large',
        isAlreadyBoughtCategory: false,
    };

    state = {
        addToCartModalIsOpen: false,
    };

    componentDidMount = () => {
        // cf. https://github.com/ReactTraining/history#usage
        this.unlisten = this.props.history.listen((location, action) => {
            if (action === 'POP') {
                this.setState({
                    addToCartModalIsOpen: false,
                });
            }
        });
    };

    componentWillUnmount = () => {
        this.unlisten();
    };

    isMultiOffers = this.props.product.offers.length > 1;

    toggleAddToCartModal = e => {
        if (e) {
            e.stopPropagation();
        }
        this.setState(state => {
            if (!state.addToCartModalIsOpen) {
                this.props.history.push({
                    search: this.props.location.search,
                    hash: '#add',
                });
            } else {
                this.props.history.replace({
                    search: this.props.location.search,
                });
            }

            return {
                addToCartModalIsOpen: !state.addToCartModalIsOpen,
            };
        });
    };

    addToBasket = () => {
        if (this.isMultiOffers) {
            this.toggleAddToCartModal();
        } else {
            this.props.addOfferToBasket(
                this.props.product.offers[0].id,
                this.props.quantityInBasketByOffers[0].quantity + 1,
                this.props.actionOrigin
            );

            AppChannel.vent.trigger(
                'analytics:cart:add',
                this.props.product,
                this.props.product.offers[0],
                this.props.assembly
            );
        }
    };

    removeFromBasket = () => {
        if (this.isMultiOffers) {
            const offersWithQuantityInBasket = R.filter(
                R.propSatisfies(x => x > 0, 'quantity'),
                this.props.quantityInBasketByOffers
            );
            const onlyOneOfTheOffersIsInBasket = offersWithQuantityInBasket.length === 1;
            if (onlyOneOfTheOffersIsInBasket) {
                this.props.addOfferToBasket(
                    offersWithQuantityInBasket[0].id,
                    offersWithQuantityInBasket[0].quantity - 1,
                    this.props.actionOrigin
                );
            } else {
                this.toggleAddToCartModal();
            }
        } else {
            this.props.addOfferToBasket(
                this.props.product.offers[0].id,
                this.props.quantityInBasketByOffers[0].quantity - 1,
                this.props.actionOrigin
            );
        }
    };

    render() {
        const {
            trans,
            i18n,
            product,
            allProductTypesIndexedById,
            addOfferToBasket,
            displayProducer,
            distributionId,
            noImageBackgroundColor,
            isLabelDescriptionAvailable,
            quantityInBasketByOffers,
            isSingleOfferLoading,
            variant,
            isAlreadyBoughtCategory,
            size,
            actionOrigin,
            distributionDate,
            onClick,
        } = this.props;

        const isPromoted = product.offers[0].hasOwnProperty('promotion');
        const offersQuantities = product.offers.map(offer =>
            getFormattedQuantity(offer, {
                strategy: product.type.quantityStrategy,
                locale: i18n,
            })
        );
        const formattedQuantities = join(offersQuantities, {
            separator: ', ',
            lastSeparator: ` ${trans('global.or')} `,
        });

        const totalQuantityInBasket = R.sum(R.pluck('quantity', quantityInBasketByOffers));

        const isStockExhausted = !hasAvailableStock(product);

        const renderMultiOffersModal = () =>
            this.state.addToCartModalIsOpen && (
                <MultiOffersModal
                    addOfferToBasket={addOfferToBasket}
                    allProductTypesIndexedById={allProductTypesIndexedById}
                    closeModal={this.toggleAddToCartModal}
                    distributionId={distributionId}
                    isLabelDescriptionAvailable={isLabelDescriptionAvailable}
                    noImageBackgroundColor={noImageBackgroundColor}
                    product={product}
                    actionOrigin={actionOrigin}
                />
            );

        const productName = isGermanLanguage(i18n)
            ? product.name
            : capitalizeFirstLetter(toLowerCase(product.name));

        const addToCartButton = (
            <AddToCart
                offers={product.offers}
                toggleModal={this.toggleAddToCartModal}
                quantity={totalQuantityInBasket}
                onAdd={this.addToBasket}
                onRemove={this.removeFromBasket}
                isStockExhausted={isStockExhausted}
                isLoading={isSingleOfferLoading}
            />
        );

        const productCardProps = {
            product,
            isMultiOffers: this.isMultiOffers,
            formattedQuantities,
            productName,
            addToCartButton,
            allProductTypesIndexedById,
            isPromoted,
            distributionDate,
            onClick,
        };

        const renderVerticalCard = () => (
            <VerticalProductCard
                {...productCardProps}
                displayProducer={displayProducer}
                size={size}
                farmName={titleCase(toLowerCase(product.farm.name))}
            />
        );

        const renderHorizontalCard = () => (
            <HorizontalProductCard
                {...productCardProps}
                hideTag={displayProducer && this.props.isSmallWidth}
                displayProducer={displayProducer}
                totalQuantityInBasket={totalQuantityInBasket}
                isAlreadyBoughtCategory={isAlreadyBoughtCategory}
            />
        );

        const renderDetailedCard = () => (
            <DetailedProductCard
                {...productCardProps}
                displayProducer={displayProducer}
                farmName={titleCase(toLowerCase(product.farm.name))}
                addOfferToBasket={addOfferToBasket}
                distributionId={distributionId}
            />
        );

        return (
            <Fragment>
                {variant === 'vertical' && renderVerticalCard()}
                {variant === 'horizontal' && renderHorizontalCard()}
                {variant === 'compact' && <CompactProductCard {...productCardProps} />}
                {variant === 'detailed' && renderDetailedCard()}
                {renderMultiOffersModal()}
            </Fragment>
        );
    }
}

function mapStateToProps(state, { distributionId, product }) {
    return {
        quantityInBasketByOffers: getQuantityInBasketByOffers(state, {
            distributionId,
            offersId: R.pluck('id')(product.offers),
        }),
        isSingleOfferLoading:
            product.offers.length < 2 &&
            isOrderItemLoading(state, {
                distributionId,
                offerId: product.offers[0].id,
            }),
        assembly: currentAssemblySelector(state),
    };
}

export default withRouter(connect(mapStateToProps)(connectToI18n(ProductCard)));
