import * as R from 'ramda';

import {
    fetchAnonymousBasket,
    addBasketOffer,
    updateBasketOfferQuantity,
    fetchAnonymousBaskets,
} from 'api/baskets';

import { post } from 'modules/api';
import * as localstorage from 'modules/localstorage';

import { createBasketRequestSent, getBasketOfDistribution } from './index';

const getAnonymousBasketUuids = () => {
    const uuids = localstorage.getItem('basket.anonymous');

    if (!uuids) {
        return {};
    }

    try {
        return JSON.parse(uuids);
    } catch (e) {
        return {};
    }
};

const getAnonymousBasketUuid = distributionId => {
    const uuids = getAnonymousBasketUuids();

    return uuids[distributionId] || null;
};

const storeAnonymousBasket = basket => {
    if (!basket.anonymous) {
        return basket;
    }

    const distributionId = basket.distributionId;
    const uuids = getAnonymousBasketUuids();

    if (uuids[distributionId] === basket.uuid) {
        return basket;
    }

    uuids[distributionId] = basket.uuid;
    localstorage.setItem('basket.anonymous', JSON.stringify(uuids));

    return basket;
};

const dismissAnonymousBasketUuid = (distributionId, anonymousBasketUuid) => {
    const uuids = getAnonymousBasketUuids();

    if (uuids[distributionId] === anonymousBasketUuid) {
        delete uuids[distributionId];
    }

    if (R.isEmpty(uuids)) {
        localstorage.removeItem('basket.anonymous');
    } else {
        localstorage.setItem('basket.anonymous', JSON.stringify(uuids));
    }
};

const fetchAnonymousBasketSuccess = (anonymousBasketUuid, distributionId) => basket => {
    if (anonymousBasketUuid !== basket.uuid) {
        dismissAnonymousBasketUuid(distributionId, anonymousBasketUuid);
    }
    return basket;
};

const fetchAnonymousBasketFailure = (anonymousBasketUuid, distributionId) => error => {
    if (error.status === 404) {
        dismissAnonymousBasketUuid(distributionId, anonymousBasketUuid);
    }
    throw error;
};

export const addOfferAnonymous = (
    dispatch,
    state,
    distributionId,
    offerId,
    quantity,
    searchQueryId
) => {
    const shouldCreateAnonymousBasket = getAnonymousBasketUuid(distributionId) === null;

    const getOrCreateBasket = () => {
        if (shouldCreateAnonymousBasket) {
            dispatch(createBasketRequestSent());
            return post(`distributions/${distributionId}/baskets`);
        }
        return Promise.resolve(getBasketOfDistribution(state, { distributionId }));
    };

    return getOrCreateBasket()
        .then(basket =>
            addBasketOffer(basket.uuid, {
                offerId,
                quantity,
                searchQueryId,
            })
        )
        .then(storeAnonymousBasket);
};

export const getBasketAnonymous = distributionId => {
    const anonymousBasketUuid = getAnonymousBasketUuid(distributionId);

    if (!anonymousBasketUuid) {
        return Promise.reject(new Error('No anonymous basket found on client.'));
    }

    return fetchAnonymousBasket(anonymousBasketUuid).then(
        fetchAnonymousBasketSuccess(anonymousBasketUuid, distributionId),
        fetchAnonymousBasketFailure(anonymousBasketUuid, distributionId)
    );
};

export const getOrdersAnonymous = () => {
    const anonymousBasketUuids = Object.values(getAnonymousBasketUuids());
    return anonymousBasketUuids.length !== 0
        ? fetchAnonymousBaskets(anonymousBasketUuids)
        : Promise.resolve({});
};

export const patchItemAnonymous = (state, distributionId, offerId, quantity, searchQueryId) => {
    const basket = getBasketOfDistribution(state, { distributionId });
    if (!basket || !basket.anonymous) {
        throw new Error('Expected an anonymous basket but could not find one.');
    }
    const anonymousBasketUuid = basket.uuid;

    return updateBasketOfferQuantity(anonymousBasketUuid, offerId, {
        quantity,
        searchQueryId,
    }).then(storeAnonymousBasket);
};
