import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import classnames from 'classnames';
import ProductImage from 'components/Sale/ProductImage.jsx';
import Offer from 'components/OffersModal/Offer.jsx';
import { Heading, Text } from 'components/ui';
import connectToI18n from 'modules/i18n/connectToI18n';
import Tag from 'components/Sale/Tag.jsx';
import { totalAvailableStock } from 'modules/stockUtils.js';
import { PRODUCT_PAGE } from 'modules/originUtils.js';

export class DetailedProductCard extends React.Component {
    static propTypes = {
        i18n: PropTypes.string.isRequired,
        product: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string,
            offers: PropTypes.array,
            photoId: PropTypes.string,
            type: PropTypes.shape({
                id: PropTypes.number.isRequired,
                quantityStrategy: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        productName: PropTypes.string.isRequired,
        allProductTypesIndexedById: PropTypes.object.isRequired,
        addOfferToBasket: PropTypes.func.isRequired,
        farmName: PropTypes.string.isRequired,
        distributionId: PropTypes.number.isRequired,
        isMultiOffers: PropTypes.bool,
        isPromoted: PropTypes.bool,
    };

    render() {
        const {
            product,
            allProductTypesIndexedById,
            productName,
            addOfferToBasket,
            farmName,
            i18n,
            distributionId,
            isMultiOffers,
            isPromoted,
        } = this.props;

        const availableStock = totalAvailableStock(product);

        return (
            <div className={classnames('productCard', 'detailedProductCard')}>
                <ProductImage
                    className="detailedProductCard-image"
                    productCategoryId={R.prop(
                        'categoryId',
                        allProductTypesIndexedById[product.type.id]
                    )}
                    photoId={product.photoId}
                    labels={product.labels}
                    productTypeId={product.type.id}
                    size="full"
                    productName={product.name}
                />
                <div>
                    <div className="detailedProductCard-container">
                        <div className="detailedProductCard-heading u-mb-3">
                            <Tag
                                isMultiOffers={isMultiOffers}
                                availableStock={availableStock}
                                alreadyBought={product.alreadyBought}
                                isNewInAssembly={product.isNewInAssembly}
                                promotionType={isPromoted ? product.offers[0].promotion.type : null}
                                className="u-mb-2"
                                size="medium"
                            />
                            <Heading className="detailedProductCard-name" size={1} rank={1}>
                                {productName}
                            </Heading>
                            <Text color="info">{farmName}</Text>
                        </div>
                        <div className="detailedProductCard-body">
                            <div className="detailedProductCard-price-container">
                                {product.offers.map(offer => {
                                    return (
                                        <div
                                            key={offer.id}
                                            className="detailedProductCard-productOffer"
                                        >
                                            <Offer
                                                displayPricePerQuantity
                                                distributionId={distributionId}
                                                i18n={i18n}
                                                offer={offer}
                                                addOfferToBasket={addOfferToBasket}
                                                product={product}
                                                actionOrigin={PRODUCT_PAGE}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connectToI18n(DetailedProductCard);
