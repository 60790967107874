import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';

import ArrowRight from 'app/assets/new-design/images/icons/Icon-right.svg';
import PIProductCard from 'components/Sale/ProductIdentity/ProductCard.jsx';

const ProductsCarousel = props => {
    const {
        products,
        addOfferToBasket,
        distributionId,
        actionOrigin,
        distributionDate,
        onClickOnProduct,
    } = props;
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const containerRef = useRef();

    const getFullWidth = useCallback(() => {
        if (containerRef.current?.children?.length < 2) {
            return 0;
        }
        const items = containerRef.current.children;
        const itemWidth = items.item(0).clientWidth;
        const spaceBetween =
            items.item(1)?.getBoundingClientRect()?.left -
            items.item(0)?.getBoundingClientRect()?.right;

        return (
            itemWidth * items.length +
            spaceBetween * (items.length - 1) -
            containerRef.current.clientWidth
        );
    }, []);

    const onScroll = useCallback(() => {
        setShowLeftArrow(!!containerRef.current.scrollLeft);
        setShowRightArrow(getFullWidth() > containerRef.current.scrollLeft);
    }, [getFullWidth]);

    const onArrowClick = useCallback(
        direction => () => {
            const containerStyle = window.getComputedStyle(containerRef.current);
            const paddingLeft = parseInt(containerStyle.paddingLeft, 10);

            const moveTo =
                direction === 'left'
                    ? -(containerRef.current.clientWidth - paddingLeft)
                    : containerRef.current.clientWidth - paddingLeft;
            const actualPos = containerRef.current.scrollLeft;
            containerRef.current.scroll({ left: actualPos + moveTo, behavior: 'smooth' });
        },
        []
    );

    useEffect(() => onScroll(), [onScroll]);

    return (
        <div className="pi-products-carousel">
            {showLeftArrow && (
                <InlineSVG
                    data-direction="left"
                    className="pi-products-carousel-arrow-left"
                    alt="Button to display the previous products in carousel"
                    src={ArrowRight}
                    onClick={onArrowClick('left')}
                />
            )}
            <div ref={containerRef} className="pi-products-carousel-container" onScroll={onScroll}>
                {products.map(product => (
                    <PIProductCard
                        mode="large"
                        key={product.id}
                        product={product}
                        addOfferToBasket={addOfferToBasket}
                        distributionId={distributionId}
                        displayProducer
                        actionOrigin={actionOrigin}
                        distributionDate={distributionDate}
                        onClick={() => {
                            onClickOnProduct(product.id);
                        }}
                    />
                ))}
            </div>
            {showRightArrow && (
                <InlineSVG
                    className="pi-products-carousel-arrow-right"
                    alt="Button to display the previous products in carousel"
                    src={ArrowRight}
                    onClick={onArrowClick('right')}
                />
            )}
        </div>
    );
};

ProductsCarousel.propTypes = {
    addOfferToBasket: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    distributionId: PropTypes.number.isRequired,
    actionOrigin: PropTypes.string.isRequired,
    distributionDate: PropTypes.string,
    onClickOnProduct: PropTypes.func.isRequired,
};
export default ProductsCarousel;
