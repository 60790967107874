import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const Wrapper = ({ children, className, size = 'large', margin = 'bottom', xsPadding = false }) => {
    const classNames = classnames(
        'wrapper',
        {
            [`wrapper--${size}`]: size,
            [`wrapper--topMargin`]: margin === 'top' || margin === 'both',
            [`wrapper--bottomMargin`]: margin === 'bottom' || margin === 'both',
            [`wrapper--xsPadding`]: xsPadding,
        },
        className
    );

    return <div className={classNames}>{children}</div>;
};

Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    size: PropTypes.oneOf(['large', 'medium', 'small', 'xsmall']),
    margin: PropTypes.oneOf(['top', 'bottom', 'both', 'none']),
    xsPadding: PropTypes.bool,
};

export default Wrapper;
