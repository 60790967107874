import * as R from 'ramda';
import * as localstorage from '../modules/localstorage';

export const deliveryProductTypeId = 755;

export const isDeliveryProduct = R.pathEq(['type', 'root_id'], deliveryProductTypeId);

export const getOptionValue = (option, distributionId) => {
    if (option.type === 'assembly') {
        return option.ref
            ? `${distributionId}_${option.type}_${option.ref}`
            : `${distributionId}_${option.type}`;
    }

    return option.ref
        ? `${distributionId}_${option.type}_${option.name}_${option.ref}`
        : `${distributionId}_${option.type}_${option.name}`;
};

export const getDeliveryOptionInfo = deliveryOption => {
    const [distributionId, type, name, ref] = deliveryOption.split('_');
    return { distributionId, type, name, ref };
};

export const isOptionAvailable = (deliveryOption, availableOptions) => {
    const { type, ref } = getDeliveryOptionInfo(deliveryOption);
    if (type === undefined || (type !== 'assembly' && ref === undefined)) {
        return false;
    }
    if (type === 'assembly') {
        return R.any(R.propEq('type', 'assembly'), availableOptions);
    }
    if (type === 'pickup') {
        return R.any(R.whereEq({ type, ref }), availableOptions);
    }
    return R.any(R.whereEq({ type, ref: parseInt(ref, 10) }), availableOptions);
};

export const getDefaultOption = (distributionId, availableOptions) => {
    const firstAvailableOption = availableOptions[0];
    if (firstAvailableOption.type === 'assembly') {
        return `${distributionId}_assembly`;
    }
    return `${distributionId}_${firstAvailableOption.type}_${firstAvailableOption.name}_${firstAvailableOption.ref}`;
};

export const storeOptionInLocalStorage = (distributionId, deliveryOption) => {
    localstorage.setItem(`delivery.option_${distributionId}`, deliveryOption);
};
