import { get, post } from 'modules/api';
import Utils from 'modules/utils';

const { apiLinkTo } = Utils;

/**
 * Get history of distribution between 2 dates.
 *
 * @param {Number} farmId
 * @param {Number} page
 * @returns
 */
export const getProducerHistory = (farmId, start, end, perPage = 10) =>
    get(`farms/${farmId}/distributions/archive`, { start, end, per_page: perPage });

/**
 * Download .zip of invoices between start date and end date.
 *
 * @param {Number} farmId
 * @param {Number} _.month
 * @param {Number} _.year
 * @param {Object} params
 * @returns
 */
export const downloadInvoices = (farmId, { month, year }, params) =>
    apiLinkTo(`farms/${farmId}/invoices_${month}_${year}.zip{?q*}`, {
        hash: {
            q: params,
        },
    });

export const getProducerYearMonthArchiveHistory = farmId =>
    get(`farms/${farmId}/distributions/finalized_years_and_month`);

export const createFarm = () => post('farms/');

export const getInvitations = farmId => get(`farms/${farmId}/invitations/pending`);
