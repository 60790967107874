/* global __APP_CHANNEL__ */
import Marionette from 'backbone.marionette';
import ReactDOM from 'react-dom';

export default Marionette.LayoutView.extend({
    template: () => '',

    onAttach() {
        ReactDOM.render(this.options.makeContainer(), this.el);
        if (module.hot) {
            const React = require('react');
            const deepForceUpdate = require('react-deep-force-update')(React);
            __APP_CHANNEL__.vent.on('HOT_RELOADING', () => {
                const instance = ReactDOM.render(this.options.makeContainer(), this.el);
                deepForceUpdate(instance);
            });
        }
    },

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.el);
        if (module.hot) {
            __APP_CHANNEL__.vent.off('HOT_RELOADING');
        }
    },
});
