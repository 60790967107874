import URI from 'URIjs';
import environment from 'modules/utils/environment';
import * as localstorage from 'modules/localstorage';
import {
    SANDBOX_MINIRUCHE_GA_APPID,
    PRODUCTION_MINIRUCHE_GA_APPID,
} from 'modules/analytics/miniRucheGTM';

const PRODUCTION_WEBAPP_GA_APPID = 'UA-19024647-3';
const PRODUCTION_ANDROIDAPP_GA_APPID = 'UA-19024647-20';
const SANDBOX_WEBAPP_GA_APPID = 'UA-19024647-5';
const SANDBOX_ANDROIDAPP_GA_APPID = 'UA-19024647-21';

let isMiniRuche = false;

// Store to local storage to handle page refresh
function storeContext() {
    const uri = new URI();
    const parseAsMap = true;
    const query = uri.search(parseAsMap);
    if (query.androidWebview) {
        localstorage.setItem('androidWebview', localstorage.LOCAL_STORAGE_TRUE);
    }
    isMiniRuche = query.app === 'miniruche';
}

function isAndroidWebview() {
    return localstorage.getItem('androidWebview') === localstorage.LOCAL_STORAGE_TRUE;
}

function getAppId() {
    if (isMiniRuche) {
        return environment === 'production'
            ? PRODUCTION_MINIRUCHE_GA_APPID
            : SANDBOX_MINIRUCHE_GA_APPID;
    }
    if (isAndroidWebview()) {
        return environment === 'production'
            ? PRODUCTION_ANDROIDAPP_GA_APPID
            : SANDBOX_ANDROIDAPP_GA_APPID;
    }
    return environment === 'production' ? PRODUCTION_WEBAPP_GA_APPID : SANDBOX_WEBAPP_GA_APPID;
}

storeContext();

export { getAppId, isAndroidWebview };
