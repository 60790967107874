import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useI18n from 'hooks/I18n/useI18n';
import Heading from 'components/Heading.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import Button, { LINK_MODE, SECONDARY_MODE } from 'src/components/atoms/Button/Button.jsx';
import NewsMessage from './NewsMessage.jsx';
import NewsMessageForm from './NewsMessageForm.jsx';

const POSTS_TO_SHOW_IN_LIMITED_MODE = 2;

const NewsSection = ({
    discussions,
    onNewCommentFormSubmit,
    onNewDiscussionFormSubmit,
    onDeleteComment,
    onDeleteDiscussion,
    onLoadMoreClick,
    canLoadMoreDiscussions,
    userIsMember,
}) => {
    const { trans } = useI18n();
    const [isNewPostFormOpen, setIsNewPostFormOpen] = useState(false);
    const [isLimitedDiscussions, setIsLimitedDiscussions] = useState(true);

    const handleShowMoreDiscussionsClick = () => {
        if (isLimitedDiscussions) {
            setIsLimitedDiscussions(false);
            return;
        }

        onLoadMoreClick();
    };

    const handleNewDiscussionFormSubmit = (title, content) => {
        onNewDiscussionFormSubmit(title, content, () => setIsNewPostFormOpen(false));
    };

    const discussionsToDisplay = isLimitedDiscussions
        ? discussions.slice(0, POSTS_TO_SHOW_IN_LIMITED_MODE)
        : discussions;
    const shouldShowLoadMoreButton =
        discussions.length > discussionsToDisplay.length || canLoadMoreDiscussions;

    return (
        <div className="pi-assembly-news-section">
            <Heading size={4} productIdentity>
                {trans('hiveHome.newsSection.title')}
            </Heading>
            <Text size="16px" lineHeight="24px" className="pi-assembly-news-text">
                {trans('hiveHome.newsSection.text')}
            </Text>
            {!isNewPostFormOpen && userIsMember && (
                <div className="pi-assembly-news-actions">
                    <Button mode={SECONDARY_MODE} onClick={() => setIsNewPostFormOpen(true)}>
                        {trans('hives.discussions.start')}
                    </Button>
                </div>
            )}
            {isNewPostFormOpen && (
                <NewsMessageForm
                    saveButtonLabel={trans('global.toPost')}
                    onSave={handleNewDiscussionFormSubmit}
                    onCancel={() => setIsNewPostFormOpen(false)}
                    placeholder={trans('hiveHome.newsSection.form.messageEditor.placeholder')}
                    withTitle
                />
            )}
            <div className="pi-assembly-news-message-container">
                {discussionsToDisplay.map(discussion => (
                    <NewsMessage
                        key={discussion.id}
                        discussion={discussion}
                        onNewCommentFormSubmit={onNewCommentFormSubmit}
                        onDeleteComment={onDeleteComment}
                        onDelete={onDeleteDiscussion}
                        userIsMember={userIsMember}
                    />
                ))}
            </div>
            {shouldShowLoadMoreButton && (
                <Button
                    mode={LINK_MODE}
                    className="pi-assembly-news-show-more-btn"
                    onClick={handleShowMoreDiscussionsClick}
                >
                    <Text size="16px" lineHeight="24px" weight={500} color="grey8">
                        {trans('global.showMoreMessages')}
                    </Text>
                </Button>
            )}
        </div>
    );
};

NewsSection.propTypes = {
    discussions: PropTypes.array.isRequired,
    onNewCommentFormSubmit: PropTypes.func.isRequired,
    onNewDiscussionFormSubmit: PropTypes.func.isRequired,
    onDeleteComment: PropTypes.func.isRequired,
    onDeleteDiscussion: PropTypes.func.isRequired,
    onLoadMoreClick: PropTypes.func.isRequired,
    canLoadMoreDiscussions: PropTypes.bool.isRequired,
    userIsMember: PropTypes.bool.isRequired,
};

export default NewsSection;
