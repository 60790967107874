import { get } from 'modules/api';
import * as R from 'ramda';
import geodist from 'geodist';

const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export function getAllPickups() {
    return get('pickups?new=true');
}

// @TODO manage multiple deliveryTimeSlot
const assemblyDistributionDay = R.compose(R.subtract(R.__, 1), R.path(['deliveryTimeSlot', 'day']));
const matchesFilters = R.curry((filters, assembly) => {
    const statusMatches = assembly.status === 'open' || filters.construct;
    const dayMatches = filters.days[assemblyDistributionDay(assembly)];
    return dayMatches && statusMatches;
});

export function filterAssemblies(assemblies, filters) {
    return assemblies.filter(matchesFilters(filters));
}

export function filterWithOpenDistributionsAssemblies(assemblies) {
    return assemblies.filter(assembly => assembly.openedDistributions !== undefined);
}

export function filterPickups(pickups, filters) {
    return pickups.filter(pickup => {
        return R.any(timeSlot => {
            const dayIndex = daysOfWeek.indexOf(timeSlot.dayOfWeek);
            return !!filters.days[dayIndex];
        }, pickup.timeSlots);
    });
}

export function sortByCityName(assemblies) {
    return R.sortBy(R.path(['place', 'address', 'city', 'name']), assemblies);
}

export function formatFullAddress(assembly) {
    return `${R.path(['place', 'address', 'street'], assembly)} ${R.path(
        ['place', 'address', 'city', 'zipCode'],
        assembly
    )} ${R.path(['place', 'address', 'city', 'name'], assembly)} - ${R.path(
        ['place', 'address', 'country', 'name'],
        assembly
    )}`;
}

export const getNearbyAssemblies = (assembly, assemblies, limit = 6) => {
    const distanceToAssembly = R.memoizeWith(R.prop('id'), hive =>
        geodist(assembly.place.address.coordinates, hive.place.address.coordinates, {
            unit: 'meters',
        })
    );
    return R.pipe(
        R.sortBy(distanceToAssembly),
        R.reject(R.propEq('id', assembly.id)),
        R.take(limit)
    )(assemblies);
};
