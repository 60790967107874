import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import { getFormattedPricePerQuantity } from 'models/offers';

export class PricePerQuantity extends React.Component {
    static propTypes = {
        offer: PropTypes.object.isRequired,
        strategy: PropTypes.string.isRequired,
        i18n: PropTypes.string.isRequired,
        className: PropTypes.string,
    };

    render() {
        const { offer, strategy, i18n, className } = this.props;

        const quantityEquivalent = getFormattedPricePerQuantity(offer, {
            strategy,
            locale: i18n,
        });

        if (!quantityEquivalent) {
            return null;
        }

        return <span className={className}>{quantityEquivalent}</span>;
    }
}

export default connectToI18n(PricePerQuantity);
