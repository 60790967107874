import React from 'react';
import { Grid, Heading, Paragraph } from 'components/ui';
import useI18n from 'hooks/I18n/useI18n';

const LastDiscussionsHeader = () => {
    const { trans } = useI18n();
    return (
        <Grid sm="12|4" xs="12|12" className="u-mb-2">
            <div className="u-mb-4">
                <div className="u-flexible-row u-mb-2">
                    <Heading rank={2} size={3} className="u-mr-2">
                        {trans('hives.page.discussionsTitle')}
                    </Heading>
                </div>
                <Paragraph>{trans('hives.page.discussionsBody')}</Paragraph>
            </div>
        </Grid>
    );
};
export default LastDiscussionsHeader;
