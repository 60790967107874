import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import * as R from 'ramda';

import connectToI18n from 'modules/i18n/connectToI18n';
import { get } from 'modules/api';
import Utils from 'modules/utils';

import { postPayment } from 'api/onsite-sales';

import Box from 'components/Box.jsx';
import BoxSection from 'components/BoxSection.jsx';
import Button from 'components/Button.jsx';
import Grid from 'components/Grid.jsx';
import Note from 'components/Note.jsx';
import PageHeader from 'components/PageHeader.jsx';
import Text from 'components/Text.jsx';
import TextInputGroup from 'components/TextInputGroup.jsx';
import StructuredSidePanel from 'components/StructuredSidePanel.jsx';

const { getCurrencySymbol } = Utils;

const formatCents = R.pipe(
    v => v.toString().padStart(3, '0'),
    R.reverse,
    R.insert(2, ','),
    R.reverse,
    R.join('')
);

class PaymentRequestForm extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        farmId: PropTypes.number.isRequired,
        farmCurrency: PropTypes.string.isRequired,
        trans: PropTypes.func.isRequired,
    };

    state = {
        amount: 0,
        orders: [],
        loadingOrders: true,
        orderNumber: null,
        isPhoneNumberEditable: false,
        phoneNumber: null,
        sendingRequest: false,
        redirectToSuccess: false,
    };

    componentDidMount() {
        get(`distributions/${this.props.match.params.id}/orders_numbers`).then(orders => {
            this.setState({
                orders,
                loadingOrders: false,
            });
        });
    }

    onAmountChange = (name, value) => {
        const newValue = Number(value.replace(',', ''));
        if (isNaN(newValue)) {
            return;
        }
        this.setState({
            amount: newValue,
        });
    };

    onOrderNumberChange = (name, value) => {
        const newValue = Number(value);
        if (isNaN(newValue)) {
            return;
        }
        this.setState({
            orderNumber: newValue,
        });
    };

    onEditPhoneNumberClicked = () => {
        this.setState({
            isPhoneNumberEditable: true,
        });
    };

    onDoneEditingPhoneNumber = () => {
        this.setState({
            isPhoneNumberEditable: false,
        });
    };

    onPhoneNumberChanged = (name, value) => {
        this.setState({
            phoneNumber: value,
        });
    };

    sendPaymentRequest = () => {
        const { phoneNumber, orderNumber, orders, amount } = this.state;
        const order = R.find(R.propEq('number', orderNumber), orders);
        const memberId = order.member.id;
        const displayedPhoneNumber = phoneNumber || order.member.phone;
        const distributionId = order.distributionId;
        const farmId = this.props.farmId;

        this.setState({ sendingRequest: true });
        postPayment({
            memberId,
            phoneNumber: displayedPhoneNumber,
            farmId,
            amount,
            distributionId,
        }).then(() => {
            this.setState({
                sendingRequest: false,
                redirectToSuccess: true,
            });
        });
    };

    renderSelectedOrder() {
        const { orders, loadingOrders, orderNumber, sendingRequest } = this.state;

        if (R.isNil(orderNumber)) {
            return null;
        }

        if (loadingOrders) {
            return <div>loading orders</div>;
        }

        const order = R.head(orders.filter(R.propEq('number', orderNumber)));

        if (R.isNil(order)) {
            return (
                <div>
                    <div>Numéro de commande non trouvé</div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <BoxSection>{this.renderPhoneInput(order.member)}</BoxSection>
                <BoxSection>
                    <Note>{this.props.trans('onsite_sales.payment_request_form.note_2')}</Note>
                </BoxSection>
                <BoxSection>
                    <Button
                        onClick={this.sendPaymentRequest}
                        block
                        variant="primary"
                        loading={sendingRequest}
                    >
                        {this.props.trans('onsite_sales.payment_request_form.submit_2')}
                    </Button>
                </BoxSection>
            </React.Fragment>
        );
    }

    renderPhoneInput(member) {
        const { phoneNumber, isPhoneNumberEditable } = this.state;

        const displayedPhoneNumber = phoneNumber || member.phone;

        if (!isPhoneNumberEditable) {
            return (
                <Grid xs="6|6">
                    <div>
                        <div>
                            {member.firstName} {member.lastName}
                        </div>
                        <Text weight="bold">{displayedPhoneNumber}</Text>
                    </div>
                    <Button onClick={this.onEditPhoneNumberClicked}>
                        {this.props.trans(
                            'onsite_sales.payment_request_form.button.edit_phone_number'
                        )}
                    </Button>
                </Grid>
            );
        }

        return (
            <StructuredSidePanel
                isOpened={isPhoneNumberEditable}
                onClose={this.onDoneEditingPhoneNumber}
            >
                <div>
                    <TextInputGroup
                        inputMode="numeric"
                        type="tel"
                        label={this.props.trans(
                            'onsite_sales.payment_request_form.side_panel.label'
                        )}
                        name="phoneNumber"
                        value={displayedPhoneNumber}
                        onChange={this.onPhoneNumberChanged}
                    />
                </div>
                <Button block variant="primary" onClick={this.onDoneEditingPhoneNumber}>
                    {this.props.trans('onsite_sales.payment_request_form.side_panel.submit')}
                </Button>
            </StructuredSidePanel>
        );
    }

    renderContent() {
        const { amount, orderNumber } = this.state;

        return (
            <React.Fragment>
                <BoxSection>
                    <Grid xs="11|1" align="middle">
                        <TextInputGroup
                            label={this.props.trans('onsite_sales.payment_request_form.amount')}
                            inputMode="numeric"
                            type="tel"
                            onChange={this.onAmountChange}
                            name="amount"
                            value={formatCents(amount)}
                        />
                        <div>{getCurrencySymbol(this.props.farmCurrency)}</div>
                    </Grid>
                </BoxSection>
                <BoxSection>
                    <TextInputGroup
                        label={this.props.trans('onsite_sales.payment_request_form.recipient')}
                        inputMode="numeric"
                        type="tel"
                        onChange={this.onOrderNumberChange}
                        onBlur={this.onOrderNumberBlur}
                        name="orderNumber"
                        value={orderNumber}
                        placeholder={this.props.trans(
                            'onsite_sales.payment_request_form.order_number'
                        )}
                    />
                </BoxSection>
                {this.renderSelectedOrder()}
            </React.Fragment>
        );
    }

    render() {
        if (this.state.redirectToSuccess) {
            return <Redirect push to="/request" />;
        }

        return (
            <div>
                <PageHeader
                    title={this.props.trans('onsite_sales.payment_request_form.title')}
                    background="default"
                />
                <Box>{this.renderContent()}</Box>
            </div>
        );
    }
}

export default connectToI18n(PaymentRequestForm);
