import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Text = React.forwardRef(
    (
        {
            children,
            className,
            size,
            italic = false,
            bold = false,
            underline = false,
            color,
            family,
            lineHeight,
            weight,
            tagName,
            ...props
        },
        ref
    ) => {
        const cls = classnames(
            'pi-typo',
            {
                'pi-typo--italic': italic,
                'pi-typo--bold': bold,
                'pi-typo--underline': underline,
                [`pi-typo--${color}`]: color,
                [`pi-typo-${family}-${size}`]: size,
                [`pi-typo-${family}-${size}-lh-${lineHeight}`]: lineHeight && size,
                [`pi-typo-fw-${weight}`]: weight,
            },
            className
        );
        const Tag = tagName || 'div';
        return (
            <Tag ref={ref} {...props} className={cls}>
                {children}
            </Tag>
        );
    }
);

Text.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.oneOf([
        'turquoise2',
        'gray2',
        'gray4',
        'gray5',
        'gray9',
        'error',
        'green6',
        'red4',
        'grey8',
        'white',
        null,
    ]),
    italic: PropTypes.bool,
    size: PropTypes.oneOf([
        '10px',
        '12px',
        '14px',
        '16px',
        '18px',
        '20px',
        '21px',
        '24px',
        '32px',
        '36px',
        '40px',
    ]),
    bold: PropTypes.bool,
    underline: PropTypes.bool,
    family: PropTypes.oneOf(['sui', 'inter', 'mr']),
    lineHeight: PropTypes.oneOf([
        '12px',
        '14px',
        '16px',
        '18px',
        '20px',
        '21px',
        '24px',
        '26px',
        '27px',
        '28px',
        '30px',
        '32px',
        '48px',
    ]),
    weight: PropTypes.oneOf([400, 500, 600, 700]),
    tagName: PropTypes.string,
};

Text.defaultProps = {
    family: 'inter',
    lineHeight: null,
    color: null,
};
export default Text;
