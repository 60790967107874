import PropTypes from 'prop-types';
import React from 'react';
import { filerPhoto } from 'modules/utils/filer';
import classnames from 'classnames';

const SIZE_TO_FILER_SIZE = {
    xsmall: 'small',
    small: 'small',
    medium: 'medium',
    xmedium: 'medium',
    large: 'medium',
};

class Avatar extends React.Component {
    static propTypes = {
        photoId: PropTypes.string,
        fallbackImg: PropTypes.string,
        className: PropTypes.string,
        responsive: PropTypes.bool,
        rounded: PropTypes.bool,
        size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'xmedium', 'large']),
        bordered: PropTypes.bool,
        src: PropTypes.string,
    };

    static defaultProps = {
        size: 'medium',
        fallbackImg: 'user',
    };

    getFilerSize = () => {
        return SIZE_TO_FILER_SIZE[this.props.size];
    };

    render() {
        const { photoId, fallbackImg, className, size, responsive, rounded, bordered } = this.props;

        return (
            <div
                className={classnames('avatar', className, `avatar--${size}`, {
                    'avatar--responsive': responsive,
                    'avatar--rounded': rounded,
                    'avatar--bordered': bordered,
                })}
            >
                <img
                    className="avatar-image"
                    src={this.props.src || filerPhoto(photoId, this.getFilerSize(), fallbackImg)}
                    alt=""
                />
            </div>
        );
    }
}

export default Avatar;
