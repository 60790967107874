import {
    FETCH_NEWSFEED,
    FETCH_NEWSFEED_SUCCESS,
    FETCH_NEWSFEED_ERROR,
    FETCH_UNREAD_NEWSFEED_COUNT,
    READ_NEWSFEED,
} from './actions';

export const newsfeedInitialState = {
    news: [],
    isFetching: false,
    unread: 0,
};

export const newsfeedReducer = (
    state = {
        ...newsfeedInitialState,
    },
    { type, payload }
) => {
    switch (type) {
        case FETCH_NEWSFEED:
            return {
                ...state,
                isFetching: true,
            };
        case FETCH_NEWSFEED_SUCCESS:
            return {
                ...state,
                isFetching: false,
                news: payload,
            };
        case FETCH_NEWSFEED_ERROR:
            return {
                ...state,
                isFetching: false,
            };
        case FETCH_UNREAD_NEWSFEED_COUNT:
            return {
                ...state,
                unread: payload,
            };
        case READ_NEWSFEED:
            return {
                ...state,
                unread: 0,
            };
        default:
            return state;
    }
};
