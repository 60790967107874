import { send } from 'modules/http';
import { getLocaleByCode } from 'modules/utils/locales';

export function getArticles(searchValue, userType, localeCode) {
    const { zendeskSearch, zendeskCategories, zendeskLocale } = getLocaleByCode(localeCode);
    return send({
        method: 'GET',
        url: `${zendeskSearch}articles/search.json?query=${searchValue}&category=${zendeskCategories[userType]}&per_page=4&locale=${zendeskLocale}`,
    });
}

export function getPromotedArticles(userType, localeCode) {
    const { zendeskSearch, zendeskCategories, zendeskLocale } = getLocaleByCode(localeCode);
    // this request will work for help centers articles with "important" labels manualy added
    const getImportantArticles = send({
        method: 'GET',
        url: `${zendeskSearch}${zendeskLocale}/categories/${zendeskCategories[userType]}/articles.json?label_names=important&per_page=4`,
    });
    // this request is a fallback, promoted=true should get starred articles but doesn't work (API choice)
    const getOtherArticles = send({
        method: 'GET',
        url: `${zendeskSearch}${zendeskLocale}/categories/${zendeskCategories[userType]}/articles.json?promoted=true&per_page=4`,
    });
    return Promise.all([getImportantArticles, getOtherArticles]).then(
        ([importantArticles, otherArticles]) => {
            const data =
                importantArticles.articles.length > 0
                    ? importantArticles.articles
                    : otherArticles.articles;
            return data;
        }
    );
}
