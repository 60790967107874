import { useSelector } from 'react-redux';
import { farmIdSelector } from 'modules/currentUser';

const UserFarmIdProvider = ({ children }) => {
    const farmId = useSelector(farmIdSelector);

    return children({ farmId });
};

export default UserFarmIdProvider;
