import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InlineSVG from 'svg-inline-react';

import useI18n from 'hooks/I18n/useI18n';

import { getIsoWeekday, getLocalizedDay, getLocalizedTime } from 'modules/utils/dateAndTime';
import { formatPrice } from 'modules/utils/currency';
import { get } from 'modules/api';
import {
    validateOrder as validateOrderFn,
    itemsByFarm as itemsByFarmSelector,
    closeStockAlertModal as closeStockAlertModalFn,
} from 'modules/orders';
import { sanitizeStock } from 'modules/stockUtils';
import * as localstorage from 'modules/localstorage';
import { INITIAL_LOADING, READY, FAILED } from 'modules/utils/ajaxStatuses';
import { getSelectedDeliveryOption, computeBasketPrices } from 'modules/basketUtils';

import {
    getDefaultOption,
    isOptionAvailable,
    storeOptionInLocalStorage,
    getOptionValue,
} from 'models/delivery';
import { isBasket as isBasketFn } from 'models/orders';
import { getFormattedQuantity } from 'models/offers';

import Button, { LINK_MODE, SMALL_SIZE } from 'src/components/atoms/Button/Button.jsx';
import BasketChangedModal from 'components/Baskets/ProductIdentity/BasketPricesQuantityChangedModal.jsx';
import { Heading } from 'components/ui';
import BasketFarm from 'components/Baskets/ProductIdentity/BasketFarm.jsx';
import PIText from 'components/ProductIdentity/Text.jsx';
import BasketOrderStatus from 'components/Baskets/ProductIdentity/BasketOrderStatus.jsx';
import { Notice } from 'components/ui';
import LoadingAnimation from 'components/LoadingAnimation.jsx';
import Price from 'components/Price.jsx';

import Caret from 'app/assets/new-design/images/icons/caret.svg';

const isPending = state => state === 3;
const isCancelled = state => state === 2 || state === 7 || state === 9;
const isValidated = state => state === 4 || state === 5;

const outOfStock = x => sanitizeStock(x.availableStock) <= 0;

const BasketOrder = props => {
    const {
        distribution,
        order,
        hasDeliveryOptions,
        getQueryParameters,
        openCancelOrderModal,
        openResetToCartOrderModal,
    } = props;
    const { id: distributionId, uuid: distributionUuid } = distribution;
    const [deliveryOptions, setDeliveryOptions] = useState([]);
    const [loadingDeliveryOptions, setLoadingDeliveryOptions] = useState(INITIAL_LOADING);
    const history = useHistory();

    const { trans, i18n, transChoice } = useI18n();
    const dispatch = useDispatch();

    const selectedPickup = useMemo(() => {
        if (loadingDeliveryOptions !== READY) return null;

        let _selectedPickup = localstorage.getItem(`delivery.option_${distributionId}`);
        const pickupItem = R.find(R.propEq('ref', order.deliveryOption.ref), deliveryOptions);
        if (pickupItem) {
            _selectedPickup = getOptionValue(pickupItem, distributionId);
            // Store pickup information in localStorage only for order not finish, it's use on delivery page
            // We do that because when we have multi order for the same distribution, the pickup key in localstorage was erase by basket/order af the same distribution
            isBasketFn(order) && storeOptionInLocalStorage(distributionId, _selectedPickup);
        }

        if (R.isNil(_selectedPickup) || !isOptionAvailable(_selectedPickup, deliveryOptions)) {
            _selectedPickup = getDefaultOption(distributionId, deliveryOptions);
            // store the new selected option in local storage
            // allows to share the choice with checkout funnel, and with sale page
            storeOptionInLocalStorage(distributionId, _selectedPickup);
        }
        return getSelectedDeliveryOption({ selectedOption: _selectedPickup, deliveryOptions });
    }, [deliveryOptions, distributionId, loadingDeliveryOptions, order]);

    const { shippingFees } = useMemo(
        () =>
            loadingDeliveryOptions === READY &&
            computeBasketPrices(
                getOptionValue(selectedPickup, distributionId),
                order.totalPrice,
                deliveryOptions
            ),
        [loadingDeliveryOptions, order, deliveryOptions, distributionId, selectedPickup]
    );

    const areDeliveryOptionsAvailable = useMemo(() => {
        const pickups = R.filter(R.propEq('type', 'pickup'), deliveryOptions);
        const deliveryOffers = R.filter(R.propEq('type', 'deliveryOffer'), deliveryOptions);
        const availableDeliveryOffers = R.reject(outOfStock, deliveryOffers);
        return pickups.length > 0 || availableDeliveryOffers.length > 0;
    }, [deliveryOptions]);

    const validateOrder = useCallback(
        () => dispatch(validateOrderFn(order.id, hasDeliveryOptions)),
        [order, hasDeliveryOptions, dispatch]
    );

    const closeStockAlertModal = useCallback(() => dispatch(closeStockAlertModalFn(order.id)), [
        order,
        dispatch,
    ]);

    const handleOpenCancelOrderModal = useCallback(() => openCancelOrderModal(order.id), [
        order,
        openCancelOrderModal,
    ]);
    const handleOpenResetToCartOrderModal = useCallback(() => openResetToCartOrderModal(order.id), [
        order,
        openResetToCartOrderModal,
    ]);
    const itemsByFarm = useSelector(state => itemsByFarmSelector(state, { orderId: order.id }));
    const removedItems = useMemo(() => {
        if (order.removedItems && order.removedItems.length) {
            const basketItems = R.flatten(itemsByFarm);

            return (
                R.pipe(
                    R.propOr([], 'removedItems'),
                    R.map(_removedItem => {
                        const itemStillInBasket = R.find(
                            item =>
                                item.product.id === _removedItem.product.id &&
                                item.offer.id === _removedItem.offer.id,
                            basketItems
                        );

                        const removedItem = {
                            id: _removedItem.offer.id, // for keys
                            name: _removedItem.product.name,
                            farmName: _removedItem.farmName,
                            formattedQuantity: getFormattedQuantity(_removedItem.offer, {
                                strategy: _removedItem.quantityStrategy,
                                locale: i18n,
                            }),
                            offer: _removedItem.offer,
                            previousQuantity: _removedItem.quantity,
                            totalPrice: _removedItem.totalPrice,
                            newQuantity: 0,
                            productPhotoId: _removedItem.product.photo
                                ? _removedItem.product.photo.id
                                : null,
                            quantityStrategy: _removedItem.quantityStrategy,
                        };

                        if (itemStillInBasket) {
                            removedItem.previousQuantity =
                                itemStillInBasket.quantity + _removedItem.quantity;
                            removedItem.newQuantity = itemStillInBasket.quantity;
                        }

                        return removedItem;
                    })
                )(order) || []
            );
        }
        return [];
    }, [i18n, order, itemsByFarm]);

    useEffect(() => {
        get(`distributions/${distributionUuid}/pickup-and-delivery-options`)
            .then(_deliveryOptions => {
                setDeliveryOptions(_deliveryOptions);
                setLoadingDeliveryOptions(READY);
            })
            .catch(() => {
                setLoadingDeliveryOptions(FAILED);
            });
    }, [distributionUuid]);

    if (loadingDeliveryOptions === FAILED) {
        return (
            <Notice type="error" title={trans('errors.internal.title')}>
                {trans('global.error.retryMessage')}
            </Notice>
        );
    }

    if (loadingDeliveryOptions !== READY) {
        return <LoadingAnimation type="pi-spinner" />;
    }

    return (
        <div
            className={classnames(`basket-container`, {
                'pending-order': isPending(order.state),
                'canceled-order': isCancelled(order.state),
            })}
        >
            <div
                className={classnames('basket-hive-summary', {
                    isCancelled: isCancelled(order.state),
                })}
            >
                <Heading
                    className={classnames(
                        'basket-order-title',
                        `color-${distribution.assemblyTheme}`
                    )}
                    rank={2}
                    size={4}
                    productIdentity
                >
                    {distribution.assemblyCity}
                </Heading>
                {!isCancelled(order.state) && (
                    <Button
                        size={SMALL_SIZE}
                        className="pi-basket-order-back-button"
                        mode={LINK_MODE}
                        onClick={() => {
                            history.push(
                                `/assemblies/${distribution.hiveId}/collections/${distribution.id}/products`
                            );
                        }}
                    >
                        <>
                            <InlineSVG width="16px" height="16px" raw src={Caret} />
                            <PIText family="inter" color="gray2">
                                {isValidated(order.state)
                                    ? trans('basket.makeNewOrder')
                                    : trans('basket.keepShopping')}
                            </PIText>
                        </>
                    </Button>
                )}
                <div
                    className={classnames('basket-order-total', {
                        isCancelled: isCancelled(order.state),
                    })}
                >
                    <PIText size="16px" lineHeight="24px" family="inter" color="gray2">{`${
                        order.countItems
                    } ${transChoice('basket.count.items', order.countItems)}`}</PIText>
                    <PIText size="16px" lineHeight="24px" family="inter" color="gray2">{`${trans(
                        'order.total'
                    )} :`}</PIText>
                    <PIText size="16px" lineHeight="24px" family="inter" bold color="gray2">
                        {formatPrice(
                            i18n,
                            order.purchasedPrice.amount / 100,
                            order.purchasedPrice.currency
                        )}
                    </PIText>
                </div>
                <div className="basket-order-farms-container">
                    {itemsByFarm.map(items => (
                        <BasketFarm
                            key={items[0].farmName}
                            order={order}
                            isBasket={isBasketFn(order)}
                            items={items}
                        />
                    ))}
                </div>
                {selectedPickup && (
                    <div className="basket-order-hive-delivery-container">
                        <PIText
                            className="basket-order-hive-delivery-title"
                            size="16px"
                            lineHeight="24px"
                            color="gray2"
                        >
                            {trans('selectDelivery.fee')}
                        </PIText>
                        <div className="basket-order-hive-delivery-fees">
                            <PIText size="14px" lineHeight="20px" color="gray2">
                                {selectedPickup.name}
                            </PIText>
                            <PIText size="16px" lineHeight="20px" color="gray2" bold>
                                <Price className="value" price={shippingFees} />
                            </PIText>
                        </div>
                        {selectedPickup.timeSlots &&
                            selectedPickup.timeSlots.map(({ dayOfWeek, timeStart, timeEnd }) => (
                                <PIText
                                    key={dayOfWeek + timeStart + timeEnd}
                                    size="14px"
                                    lineHeight="20px"
                                    color="gray9"
                                >
                                    {trans('selectDelivery.timeSlot', {
                                        '%day%': getLocalizedDay(i18n, getIsoWeekday(dayOfWeek)),
                                        '%start%': getLocalizedTime(i18n, timeStart),
                                        '%end%': getLocalizedTime(i18n, timeEnd),
                                    })}
                                </PIText>
                            ))}
                    </div>
                )}
            </div>
            <BasketOrderStatus
                className="basket-hive-status"
                order={order}
                distribution={distribution}
                getQueryParameters={getQueryParameters}
                hasDeliveryOptions={areDeliveryOptionsAvailable}
                deliveryOptions={deliveryOptions}
                openCancelOrderModal={handleOpenCancelOrderModal}
                openResetToCartOrderModal={handleOpenResetToCartOrderModal}
            />
            {/* modal d'alert stock */}
            {order.replacementItems?.length || removedItems.length ? (
                <BasketChangedModal
                    isOpened
                    closeModal={closeStockAlertModal}
                    replacementItems={order.replacementItems}
                    removedItems={removedItems}
                    validateOrder={validateOrder}
                    hasDeliveryOptions={false}
                    orderId={order.id}
                />
            ) : null}
        </div>
    );
};

BasketOrder.propTypes = {
    order: PropTypes.object.isRequired,
    distribution: PropTypes.object.isRequired,
    hasDeliveryOptions: PropTypes.bool,
    getQueryParameters: PropTypes.func,
    openCancelOrderModal: PropTypes.func,
    openResetToCartOrderModal: PropTypes.func,
};
export default BasketOrder;
