import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatPrice } from 'modules/utils/currency';

import useI18n from 'hooks/I18n/useI18n';

import Tooltip, { TRIGGER_VISIBLE_TOOLTIP } from 'src/components/atoms/Tooltip/Tooltip.jsx';
import Badge from 'src/components/atoms/Badge/Badge.jsx';
import Text from 'components/ProductIdentity/Text.jsx';
import Icon from 'components/Icon.jsx';

import basketIcon from 'app/assets/new-design/images/icons/basket.svg';

export const LIGHT_MODE = 'light';
export const DARK_MODE = 'dark';

const BasketButton = React.forwardRef(
    ({ quantity, price, tooltipOrientation, onClick, className, mode }, ref) => {
        const { i18n, trans } = useI18n();
        return (
            <button
                ref={ref}
                onClick={onClick}
                type="button"
                className={classNames(className, 'pi-basket-button', mode)}
            >
                <Tooltip
                    content={
                        price?.amount ? (
                            <Text size="12px" lineHeight="14px" color="white">
                                {formatPrice(i18n, price.amount / 100, price.currency)}
                            </Text>
                        ) : null
                    }
                    orientation={tooltipOrientation}
                    trigger={TRIGGER_VISIBLE_TOOLTIP}
                >
                    <Badge count={quantity} hide={quantity < 1}>
                        <Icon
                            className="pi-basket-button-icon"
                            src={basketIcon}
                            useColorStyle
                            color="white"
                            size="xx-large"
                        />
                    </Badge>
                    <Text
                        className="pi-basket-button-label"
                        size="12px"
                        lineHeight="14px"
                        color="grey2"
                    >
                        {trans('menu.basket')}
                    </Text>
                </Tooltip>
            </button>
        );
    }
);

BasketButton.propTypes = {
    quantity: PropTypes.number,
    price: PropTypes.shape({
        amount: PropTypes.number,
        currency: PropTypes.string,
    }).isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    tooltipOrientation: PropTypes.string,
    mode: PropTypes.oneOf([LIGHT_MODE, DARK_MODE]),
};

BasketButton.defaultProps = {
    quantity: 0,
    className: '',
    mode: DARK_MODE,
};

export default BasketButton;
