import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';

import Utils from 'modules/utils';

import OverlayTrigger from 'components/OverlayTrigger.jsx';
import Tooltip from 'components/Tooltip.jsx';
import Heading from 'components/Heading.jsx';
import Text from 'components/Text.jsx';
import PIText from 'components/ProductIdentity/Text.jsx';

const { filerPhoto } = Utils;

export const BIG_SIZE = 'big';
export const MEDIUM_SIZE = 'medium';

const ProductLabel = props => {
    const { name, description, photoId, className, count, size } = props;

    if (R.isNil(photoId) && R.isNil(count)) {
        return null;
    }

    if (!R.isNil(count)) {
        return (
            <div className={classNames('product-label-image count-label', className, size)}>
                <PIText color="white" size="10px" lineHeight="12px">
                    +{count}
                </PIText>
            </div>
        );
    }
    const icon = (
        <img
            className={classNames('product-label-image', className, size)}
            src={filerPhoto(photoId, 'small')}
            alt={name}
        />
    );

    if (!description) {
        return icon;
    }

    return (
        <OverlayTrigger
            pointer="top"
            overlay={
                <Tooltip closeButton>
                    <Heading size={3} className="u-mb-2">
                        {name}
                    </Heading>
                    <div className="u-mb-3">
                        <Text inline size="large">
                            {description}
                        </Text>
                    </div>
                </Tooltip>
            }
        >
            {icon}
        </OverlayTrigger>
    );
};
ProductLabel.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    photoId: PropTypes.string.isRequired,
    className: PropTypes.string,
    size: PropTypes.string,
    count: PropTypes.number,
};

export default ProductLabel;
