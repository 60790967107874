import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from 'components/Text.jsx';
import DeliveryOptionPrice from 'components/DeliveryOptionPrice.jsx';
import RadioButton from 'components/RadioButton.jsx';
import RadioBlock from 'components/RadioBlock.jsx';

const DeliveryOption = ({
    disabled,
    title,
    className,
    children,
    value,
    onChange,
    isSelected,
    deliveryOption,
    totalPrice,
    variant = 'simple',
}) => {
    if (variant === 'block') {
        return (
            <RadioBlock
                className={classnames('deliveryOption', className)}
                disabled={disabled}
                name="deliveryOption"
                inputId={value}
                value={value}
                onChange={onChange}
                variant="circle"
                inverse
                isSelected={isSelected}
            >
                <div className="deliveryOption--content">
                    <div className="deliveryOption--heading">
                        <div className="deliveryOption--title">
                            <Text weight="bold">{title}</Text>
                        </div>
                        <div className="deliveryOption--price">
                            <DeliveryOptionPrice
                                deliveryOption={deliveryOption}
                                totalPrice={totalPrice}
                            />
                        </div>
                    </div>
                    {children}
                </div>
            </RadioBlock>
        );
    }

    return (
        <RadioButton
            className="deliveryOption"
            disabled={disabled}
            name="deliveryOption"
            inputId={value}
            value={value}
            onChange={onChange}
            variant="circle"
            inverse
            isSelected={isSelected}
        >
            <div className="deliveryOption--content">
                <div className="deliveryOption--heading">
                    <div className="deliveryOption--title">
                        <Text size="large" weight="bold">
                            {title}
                        </Text>
                    </div>
                    <div className="deliveryOption--price">
                        <Text size="large" weight="bold">
                            <DeliveryOptionPrice
                                deliveryOption={deliveryOption}
                                totalPrice={totalPrice}
                            />
                        </Text>
                    </div>
                </div>
                {children}
            </div>
        </RadioButton>
    );
};

DeliveryOption.propTypes = {
    disabled: PropTypes.bool,
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
    deliveryOption: PropTypes.object.isRequired,
    totalPrice: PropTypes.object,
    variant: PropTypes.oneOf(['simple', 'block']),
};

export default DeliveryOption;
