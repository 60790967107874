import React from 'react';
import PropTypes from 'prop-types';
import { getLocalizedDay, getLocalizedTime } from 'modules/utils/dateAndTime';
import useI18n from 'hooks/I18n/useI18n';
import Heading from 'components/Heading.jsx';
import Text from 'components/ProductIdentity/Text.jsx';

const Header = ({ assembly }) => {
    const { i18n, trans } = useI18n();

    const {
        place: {
            address: { street, city },
        },
        deliveryTimeSlot,
        extraDeliveryTimeSlot: extraSlot,
    } = assembly;
    const { day, starting, ending } = deliveryTimeSlot || {};

    const hasExtraDeliveryTimeSlot = !!extraSlot;

    return (
        <div className="pi-assembly-header">
            <div className="pi-assembly-header-content">
                <Heading
                    size={1}
                    productIdentity
                    family="inter"
                    dangerouslySetInnerHTML={{
                        __html: trans('seo.hivePage.h1', {
                            '%city%': city.name,
                            '%assembly%': assembly.name,
                        }),
                    }}
                />
                <Text size="16px" lineHeight="24px" className="pi-assembly-header-assembly-address">
                    {street}, {city.zipCode} {city.name}
                </Text>
                {!hasExtraDeliveryTimeSlot && deliveryTimeSlot && (
                    <Text size="16px" lineHeight="24px" className="distribution-open-at-message">
                        {trans('hiveHome.header.openedSlot', {
                            '%weekday%': getLocalizedDay(i18n, day),
                            '%startingHour%': getLocalizedTime(i18n, starting),
                            '%endingHour%': getLocalizedTime(i18n, ending),
                        })}
                    </Text>
                )}
                {hasExtraDeliveryTimeSlot && deliveryTimeSlot && (
                    <div className="distribution-open-at-message">
                        <Text size="16px" lineHeight="24px">
                            {trans('global.distribution')} :
                        </Text>
                        <ul>
                            <li>
                                <Text size="16px" lineHeight="24px">
                                    {trans('hiveHome.header.openedSlots.item', {
                                        '%weekday%': getLocalizedDay(i18n, day),
                                        '%startingHour%': getLocalizedTime(i18n, starting),
                                        '%endingHour%': getLocalizedTime(i18n, ending),
                                    })}
                                </Text>
                            </li>
                            <li>
                                <Text size="16px" lineHeight="24px">
                                    {trans('hiveHome.header.openedSlots.item', {
                                        '%weekday%': getLocalizedDay(i18n, extraSlot.day),
                                        '%startingHour%': getLocalizedTime(
                                            i18n,
                                            extraSlot.starting
                                        ),
                                        '%endingHour%': getLocalizedTime(i18n, extraSlot.ending),
                                    })}
                                </Text>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

Header.propTypes = {
    assembly: PropTypes.shape({
        name: PropTypes.string.isRequired,
        extraDeliveryTimeSlot: PropTypes.shape({
            id: PropTypes.number.isRequired,
            day: PropTypes.number.isRequired,
            starting: PropTypes.string.isRequired,
            ending: PropTypes.string.isRequired,
        }),
        place: PropTypes.shape({
            address: PropTypes.shape({
                city: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    zipCode: PropTypes.string.isRequired,
                }).isRequired,
                street: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

export default Header;
