import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useI18n from 'hooks/I18n/useI18n.js';

import Text from 'components/ProductIdentity/Text.jsx';

const IncrementButton = React.forwardRef((props, ref) => {
    const { count, onRemove, onAdd, large, label, className } = props;
    const { trans } = useI18n();

    return (
        <div ref={ref} className={classNames('nd-increment-button', className)}>
            <div
                className={classNames('button-container', {
                    open: count,
                    'full-width': large,
                    'has-label': label,
                    'sold-out': !onAdd && !count,
                })}
            >
                {onAdd || count > 0 ? (
                    <>
                        <div className="remove-container">
                            <button
                                disabled={!count}
                                className="remove"
                                onClick={onRemove}
                            ></button>
                        </div>
                        <div className="count">{count}</div>
                        <div className="add-container">
                            <button disabled={!onAdd} className="add" onClick={onAdd}>
                                {!count && label}
                            </button>
                        </div>
                    </>
                ) : (
                    <Text color="grey8" size="12px" lineHeight="20px" weight="500">
                        {trans('offer.unavailable')}
                    </Text>
                )}
            </div>
        </div>
    );
});

IncrementButton.propTypes = {
    count: PropTypes.number,
    onRemove: PropTypes.func,
    onAdd: PropTypes.func,
    large: PropTypes.bool,
    label: PropTypes.string,
    className: PropTypes.string,
};
IncrementButton.defaultProps = {
    count: 0,
};

export default IncrementButton;
