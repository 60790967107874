import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { Heading } from 'components/ui';
import { Portal } from 'react-portal';
import onClickOutside from 'react-onclickoutside';
import * as Backdrop from 'modules/utils/backdrop';
import CloseButton from 'components/CloseButton.jsx';
import { connect } from 'react-redux';
import FocusTrap from 'focus-trap-react';
import { closeAll as closeAllPanels } from 'modules/visiblePanel';

const KEYCODES = {
    ESCAPE: 27,
};

/**
 * This code look weird because it's a merge of 2 components
 *  - SidePanel
 *  - StructuedSidePanel
 */

export class StructuredSidePanel extends React.Component {
    static propTypes = {
        isOpened: PropTypes.bool,
        title: PropTypes.node,
        onClose: PropTypes.func,
        actions: PropTypes.node,
        children: PropTypes.node,
        className: PropTypes.string,
        isClosingOnEsc: PropTypes.bool,
        closeAllPanels: PropTypes.func.isRequired,
        isNewNavigationLayout: PropTypes.bool,
        variant: PropTypes.oneOf(['right', 'left']),
    };

    static defaultProps = {
        isOpened: false,
        variant: 'right',
        isClosingOnEsc: true,
    };

    componentDidMount() {
        if (this.props.isOpened) {
            Backdrop.show();
        }
        if (this.props.isClosingOnEsc) {
            document.addEventListener('keydown', this.handleKeydown);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpened === prevProps.isOpened) {
            return;
        }
        if (this.props.isOpened) {
            Backdrop.show();
        } else {
            Backdrop.hide();
        }
    }

    componentWillUnmount() {
        Backdrop.hide();
        if (this.props.isClosingOnEsc) {
            document.removeEventListener('keydown', this.handleKeydown);
        }
    }

    onClickInside = e => {
        e.stopPropagation();
    };

    closePanel = e => {
        if (e) {
            e.stopPropagation();
        }
        this.props.closeAllPanels();
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    handleKeydown = e => {
        if (e.keyCode === KEYCODES.ESCAPE && this.props.isOpened) {
            this.closePanel();
        }
    };

    handleClickOutside = () => {
        return;
    };

    render() {
        const {
            children,
            isOpened,
            actions,
            title,
            className,
            isNewNavigationLayout,
            variant,
        } = this.props;

        if (!isOpened) {
            return null;
        }

        const a11yProps = {
            role: 'dialog',
            ['aria-modal']: true,
        };
        if (title) {
            a11yProps['aria-labelledby'] = 'panel-title';
        }

        return (
            <Portal>
                <div
                    onClick={this.closePanel}
                    className={classnames('structuredSidePanel', className, {
                        'structuredSidePanel--newTopNavigation': isNewNavigationLayout,
                    })}
                >
                    <FocusTrap
                        focusTrapOptions={{
                            clickOutsideDeactivates: true,
                            fallbackFocus: '.structuredSidePanel-root',
                        }}
                    >
                        <div
                            {...a11yProps}
                            className={classnames(
                                'structuredSidePanel-root',
                                {
                                    'structuredSidePanel-root--newTopNavigation': isNewNavigationLayout,
                                },
                                { 'structuredSidePanel--left': variant === 'left' },
                                { 'structuredSidePanel--right': variant === 'right' }
                            )}
                            onClick={this.onClickInside}
                        >
                            <CloseButton onClick={this.closePanel} />
                            <div
                                className={classnames('structuredSidePanel-wrapper', {
                                    'structuredSidePanel-wrapper--with-actions': actions,
                                    'structuredSidePanel-wrapper--newTopNavigation': isNewNavigationLayout,
                                })}
                            >
                                <div
                                    className={classnames('structuredSidePanel-content', {
                                        'structuredSidePanel-content--with-actions': actions,
                                    })}
                                >
                                    {title && (
                                        <Heading
                                            size={2}
                                            rank={1}
                                            className="u-mb-4"
                                            id="panel-title"
                                        >
                                            {title}
                                        </Heading>
                                    )}
                                    {children}
                                </div>
                                {actions && (
                                    <div className="structuredSidePanel-actions">{actions}</div>
                                )}
                            </div>
                        </div>
                    </FocusTrap>
                </div>
            </Portal>
        );
    }
}

const StructuredSidePanelNoRedux = onClickOutside(StructuredSidePanel);

const StructuredSidePanelRedux = connect(undefined, {
    closeAllPanels,
})(StructuredSidePanelNoRedux);

export { StructuredSidePanelNoRedux, StructuredSidePanelRedux as default };
