import environment from 'modules/utils/environment';

// Sentry
// It should be loaded after all external dependencies, but before all lrqdo code.
// https://docs.sentry.io/error-reporting/quickstart/?platform=browsernpm
let Sentry;

if (___BROWSER___) {
    Sentry = require('@sentry/browser');
} else {
    // Provide a fake Sentry module when running in Node.js
    const noop = () => {};
    Sentry = {
        init: noop,
        setTag: noop,
        captureException: noop,
        setUser: noop,
    };
}

export function enableReporting() {
    if (environment === 'production') {
        Sentry.init({
            dsn: 'https://3d795a1220b940f399956bf4ced52a9a@app.getsentry.com/83820',
            release: ___VERSION___,
            ignoreErrors: [
                'TypeError: Load failed',
                'TypeError: Failed to fetch',
                'ReferenceError: rdt is not defined',
                'ReferenceError: _tfa is not defined',
                'ReferenceError: fbq is not defined',
            ],
            allowUrls: [
                /https?:\/\/((assets|www|payment)\.)?((test|beta)\.)?(laruchequiditoui\.be|boerenenburen\.be|marktschwaermer\.de|thefoodassembly\.com|boerenenburen\.nl|laruchequiditoui\.fr|alvearechedicesi\.it|lacolmenaquedicesi\.es|marktschwaermer\.ch|foodassembly\.com|lacolmenaquedicesi\.cat|ruchequiditoui\.ch)/,
                /https?:\/\/(miniruche\.(fr|com))/,
            ],
        });

        // Always send the environment to make it easier to test in development
        Sentry.setTag('environment', environment);
    }
}

export function setBundle(bundleName) {
    Sentry.setTag('bundle', bundleName);
}

/**
 * Set user context, calling this with null remove user context (logout ?)
 * @param {object | null}
 */
export function setUser(data) {
    Sentry.setUser(data);
}

export function captureException(exception, context = {}) {
    const exception_ = exception instanceof Error ? exception : new Error(exception);
    Sentry.captureException(exception_, context);
}

/*
The unhandledrejection event is fired when a JavaScript Promise is rejected
but there is no rejection handler to deal with the rejection.
https://developer.mozilla.org/en-US/docs/Web/Events/unhandledrejection

This event is not yet implemented by all browsers or libraries
https://bugs.webkit.org/show_bug.cgi?id=150358
https://bugzilla.mozilla.org/show_bug.cgi?id=1179244

The polyfill we are using is only activated
when the environment does not support native promises
and it's the one from core-js, and it DOES support unhandledrejection
*/
if (___BROWSER___) {
    window.addEventListener('unhandledrejection', function(evt) {
        Sentry.captureException(evt.reason);
    });
}
