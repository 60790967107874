import React from 'react';
import propTypes from 'prop-types';
import SVGinline from 'svg-inline-react';
import classNames from 'classnames';

import Text from 'components/ProductIdentity/Text.jsx';

import HeartSolidIcon from 'app/assets/new-design/images/icons/heart-solid.svg';

const Favourite = ({ label, small }) => (
    <div className={classNames('product-favourite', { small })}>
        <SVGinline raw src={HeartSolidIcon}></SVGinline>
        <Text family="inter" size="12px" lineHeight="20px" weight="500" color="turquoise2">
            {label}
        </Text>
    </div>
);

Favourite.propTypes = {
    label: propTypes.string,
    small: propTypes.bool,
};
Favourite.defaultProps = {
    label: '',
    small: false,
};
export default Favourite;
