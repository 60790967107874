import PropTypes from 'prop-types';
import React from 'react';
import Utils from 'modules/utils';
const { linkTo, filerPhoto } = Utils;
import classNames from 'classnames';
import { connect } from 'react-redux';
import { toggle as togglePanelAction } from 'modules/visiblePanel';
import Caret from './Caret.jsx';
import UserPanel from './UserPanel.jsx';
import { USER_PANEL } from 'modules/visiblePanel.js';
import StructuredSidePanel from 'components/StructuredSidePanel.jsx';
import { formatFullName } from 'models/users';

const UserDropdown = ({ visiblePanel, togglePanel, currentUser }) => {
    const showUserPanel = e => {
        e.preventDefault();
        togglePanel(USER_PANEL);
    };

    const isUserPanelActive = visiblePanel === USER_PANEL;
    const profilePicture = filerPhoto(currentUser.photo, 'small', 'user');

    return (
        <div className="topNavigation-panelContainer">
            <a
                onClick={showUserPanel}
                href={linkTo('configuration/account/identity')}
                className={classNames('userDropdown bypass ignore-react-onclickoutside', {
                    'is-active': isUserPanelActive,
                })}
            >
                <img
                    className="userDropdown-profilePicture"
                    src={profilePicture}
                    height="30"
                    width="30"
                    alt=""
                />
                <span className="userDropdown-userName">
                    {currentUser.firstName} {currentUser.lastName}
                </span>
                <Caret />
            </a>

            <StructuredSidePanel
                isOpened={isUserPanelActive}
                title={formatFullName(currentUser)}
                isNewNavigationLayout
            >
                <UserPanel currentUser={currentUser} />
            </StructuredSidePanel>
        </div>
    );
};

UserDropdown.propTypes = {
    visiblePanel: PropTypes.string,
    togglePanel: PropTypes.func.isRequired,
    currentUser: PropTypes.object,
};

export default connect(undefined, {
    togglePanel: togglePanelAction,
})(UserDropdown);
