import React from 'react';
import PropTypes from 'prop-types';

import { getCurrentSeasonName } from 'modules/utils/season';

import useResponsive from 'hooks/Navigation/useResponsive';

import AverageDistanceTraveledContent from 'components/Sale/ProductIdentity/AverageDistanceTraveled/AverageDistanceTraveledContent.jsx';

const AverageDistanceTraveled = ({ distance }) => {
    const isSmallWidth = useResponsive();
    const season = getCurrentSeasonName();

    return (
        <>
            <div
                className={`pi-average-distance-traveled pi-average-distance-traveled-background--${season}`}
            >
                {!isSmallWidth && (
                    <AverageDistanceTraveledContent season={season} distance={distance} />
                )}
            </div>
            {isSmallWidth && <AverageDistanceTraveledContent season={season} distance={distance} />}
        </>
    );
};

AverageDistanceTraveled.propTypes = {
    distance: PropTypes.number,
};
export default AverageDistanceTraveled;
