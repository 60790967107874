import { SET_HISTORY } from './actions';

export const initialState = {};

export const routerHistoryReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case SET_HISTORY:
            return { ...state, ...payload };

        default:
            return state;
    }
};
