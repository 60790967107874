import { get, post } from 'modules/api';
import {
    transformBooleansAndNumbersValuesToStrings,
    formQuestionsToPostData,
} from 'models/formQuestions';
import { setVisitedOnDefaultValue } from 'models/farms';

export const sendFeedback = ({ farmId, assemblyId, feedback }) =>
    post(`feedbacks/farms/${farmId}/assembly/${assemblyId}`, formQuestionsToPostData(feedback));

export const sendVisitFeedback = ({ farmId, visited, visitMonth, visitYear }) =>
    post(`feedbacks/farms/${farmId}`, {
        visited,
        visitedOn: visitMonth ? `${visitYear}-${visitMonth}` : null,
    });

export const getFeedback = ({ forFarm, fromAssembly }) =>
    get(`feedbacks/farms/${forFarm}/assembly/${fromAssembly}`)
        .then(feedback => {
            feedback.sections.forEach(section => {
                section.questions.forEach(transformBooleansAndNumbersValuesToStrings);
            });
            return feedback;
        })
        .then(setVisitedOnDefaultValue);

export const getFeedbackSuggestions = () => get('feedbacks/farms/suggestions');
