import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export class MediaObject extends React.Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,
        media: PropTypes.element.isRequired,
        valignCenter: PropTypes.bool,
        valignBottom: PropTypes.bool,
        contentEllipsis: PropTypes.bool,
        size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'xmedium', 'large']),
    };

    static defaultProps = {
        size: 'medium',
    };

    render() {
        const { size, valignCenter, contentEllipsis, className, valignBottom } = this.props;

        return (
            <div
                className={classnames(
                    'mediaObject',
                    { 'mediaObject--center': valignCenter },
                    { 'mediaObject--bottom': valignBottom },
                    className
                )}
            >
                <div
                    className={classnames('mediaObject-media', {
                        [`mediaObject-media--${size}`]: size,
                    })}
                >
                    {this.props.media}
                </div>
                <div
                    className={classnames('mediaObject-content', {
                        'u-text-ellipsis': contentEllipsis,
                    })}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default MediaObject;
