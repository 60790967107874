/* eslint-disable no-return-assign */
import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from 'components/Checkbox.jsx';
import FormGroup from 'components/FormGroup.jsx';
import InputErrors from 'components/InputErrors.jsx';

export class CheckboxGroup extends React.Component {
    static propTypes = {
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        isChecked: PropTypes.bool.isRequired,
        indeterminate: PropTypes.bool,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        ariaLabel: PropTypes.string,
        children: PropTypes.node,
        inverse: PropTypes.bool,
        className: PropTypes.string,
        variant: PropTypes.oneOf(['pill', 'cross', 'productIdentity']),
        clientErrors: PropTypes.array,
        serverErrors: PropTypes.array,
        showErrors: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        indeterminate: false,
        inverse: false,
    };

    render() {
        return (
            <FormGroup>
                <Checkbox {...this.props} />
                <InputErrors
                    clientErrors={this.props.clientErrors}
                    isVisible={this.props.showErrors}
                    serverErrors={this.props.serverErrors}
                />
            </FormGroup>
        );
    }
}

export default CheckboxGroup;
