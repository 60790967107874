import PropTypes from 'prop-types';
import React from 'react';
import InlineSVG from 'svg-inline-react';
import classnames from 'classnames';

export default class Icon extends React.Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
        className: PropTypes.string,
        color: PropTypes.oneOf([
            'info',
            'maroon',
            'danger',
            'success',
            'warning',
            'lavanda',
            'alpha',
            'white',
            'turquoise2',
            'gray2',
            'gray4',
            'grey8',
            'pi-grey6',
        ]),
        size: PropTypes.oneOf([
            'xxxxx-large',
            'xxxxs-large',
            'xxxx-large',
            'xxx-large',
            'xx-large',
            'x-large',
            'large',
            'medium',
            'small',
        ]),
        verticalAlign: PropTypes.oneOf(['top', 'middle']),
        useColorStyle: PropTypes.bool,
    };

    static defaultProps = {
        size: 'medium',
        verticalAlign: 'middle',
    };

    render() {
        const { src, className, color, size, verticalAlign, useColorStyle } = this.props;

        const classNames = classnames(
            `icon--${verticalAlign}`,
            `icon--${size}`,
            {
                [`icon--${color}`]: color,
                [`icon--fill--${color}`]: !useColorStyle && color,
            },
            className
        );

        const isSVGIcon = src.slice(0, 4) === '<svg';
        if (isSVGIcon) {
            return <InlineSVG className={`svgIcon ${classNames}`} src={src} />;
        }

        return <i className={`icon icon-${src} ${classNames}`} aria-hidden="true" />;
    }
}
