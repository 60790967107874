import React from 'react';
import PropTypes from 'prop-types';
import LoadingAnimation from 'components/LoadingAnimation.jsx';

const LeafletMap = React.lazy(() => import('./LeafletMap.jsx'));

export class SimpleMap extends React.Component {
    static propTypes = {
        places: PropTypes.array.isRequired,
        defaultZoom: PropTypes.number,
        className: PropTypes.string,
    };

    render() {
        const { places, defaultZoom, className } = this.props;

        return (
            <div className={className}>
                <React.Suspense fallback={<LoadingAnimation type="spinner" />}>
                    <LeafletMap places={places} defaultZoom={defaultZoom} />
                </React.Suspense>
            </div>
        );
    }
}

export default SimpleMap;
