import PropTypes from 'prop-types';
import React from 'react';
import connectToI18n from 'modules/i18n/connectToI18n';
import Badge from 'components/Badge.jsx';
import { connect } from 'react-redux';
import { toggle as togglePanelAction } from 'modules/visiblePanel';
import Utils from 'modules/utils';
const { linkTo } = Utils;
import classNames from 'classnames';
import UserBottomPanel from './UserBottomPanel.jsx';
import { USER_PANEL } from 'modules/visiblePanel.js';
import * as R from 'ramda';
import StructuredSidePanel from 'components/StructuredSidePanel.jsx';
import { formatFullName } from 'models/users';

const UserBottomDropdown = ({
    visiblePanel,
    userAssemblies,
    hostAssemblies,
    currentUser,
    farm,
    trans,
    togglePanel,
    newsfeedUnreadCount,
    invitationsCount,
}) => {
    const showExtraPanel = e => {
        e.preventDefault();
        togglePanel(USER_PANEL);
    };

    const isUserPanelActive = visiblePanel === USER_PANEL;
    const hasPendingLeaderProfile =
        R.path(['leaderProfile', 'status'], currentUser) !== 'validated';

    const totalNotifications = newsfeedUnreadCount + invitationsCount;

    return (
        <div>
            <a
                onClick={showExtraPanel}
                href={linkTo('configuration/account/identity')}
                className={classNames('bottomNavigation-link bypass ignore-react-onclickoutside', {
                    'is-active': isUserPanelActive,
                })}
            >
                <span className="icon icon-angle-up" />
                {totalNotifications > 0 && (
                    <Badge className="bottomNavigationLink-badge">{totalNotifications}</Badge>
                )}
                {trans('navigation.bottom.more')}
            </a>
            <StructuredSidePanel
                isOpened={isUserPanelActive}
                variant="left"
                title={formatFullName(currentUser)}
            >
                <UserBottomPanel
                    currentUser={currentUser}
                    userAssemblies={userAssemblies}
                    hostAssemblies={hostAssemblies}
                    farm={farm}
                    hasPendingLeaderProfile={hasPendingLeaderProfile}
                    newsfeedUnreadCount={newsfeedUnreadCount}
                    invitationsCount={invitationsCount}
                />
            </StructuredSidePanel>
        </div>
    );
};

UserBottomDropdown.propTypes = {
    visiblePanel: PropTypes.string,
    userAssemblies: PropTypes.array,
    hostAssemblies: PropTypes.array,
    currentUser: PropTypes.object,
    farm: PropTypes.object,
    trans: PropTypes.func.isRequired,
    togglePanel: PropTypes.func.isRequired,
    newsfeedUnreadCount: PropTypes.number,
    invitationsCount: PropTypes.number,
};

UserBottomDropdown.defaultProps = {
    newsfeedUnreadCount: 0,
    invitationsCount: 0,
};

export default connect(undefined, {
    togglePanel: togglePanelAction,
})(connectToI18n(UserBottomDropdown));
